@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.select-wrapper {
  width: 100%;
  .MuiSelect-select.MuiSelect-outlined {
    padding: 9px 14px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}
.twitter-select-menu-body {
  .MuiList-root {
    .MuiMenuItem-root {
      gap: 10px;

      .MuiTypography-body1 {
        font-size: 14px !important;
        line-height: 150%;
        letter-spacing: 0.15px;
      }
    }
  }
}
.dropdown-section {
  .dropdown-label {
    font-size: 14px;
    color: #0f172a;
    margin-top: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 8px;
    svg {
      color: #bdbdbd;
      width: 15px;
      height: auto;
    }
  }
  .select-wrapper-public-twitter {
    .MuiSelect-select {
      width: 463px;
      padding: 16.5px 14px;
      font-size: 14px;
      box-sizing: border-box;
      line-height: 15px;
    }
  }
  .select-wrapper-languages,
  .select-wrapper-countries {
    .MuiSelect-select {
      width: 458px;
      padding: 16.5px 14px;
      box-sizing: border-box;
    }
  }
  &.section-box-data-keyword-setting {
    .dropdown-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #1e293b;
      margin: 0;
    }
  }

  .monitor-autocomplete-wrapper {
    .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        pointer-events: none;
        cursor: pointer;
      }
    }
    &.default-wrapper {
      width: 100%;
    }
  }
  .select-wrapper-public-twitter {
    .monitor-autocomplete-input {
      width: 463px;
      input {
        height: 48px;
        font-size: 14px;
        box-sizing: border-box;
        line-height: 15px;
      }
    }
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
      &:not(.Mui-focused) {
        @include en {
          transform: translate(14px, 15px) scale(1);
        }
        @include ar {
          transform: translate(-14px, 15px) scale(1);
          right: 0;
          left: auto;
        }
      }
    }
  }
  .select-wrapper-languages,
  .select-wrapper-countries {
    .monitor-autocomplete-input {
      width: 458px;
      input {
        height: 53.13px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 400;
      }
      @include ar {
        width: 464px;
      }
    }
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
      &:not(.Mui-focused) {
        @include en {
          transform: translate(14px, 17.4px) scale(1);
        }
        @include ar {
          transform: translate(-14px, 17.4px) scale(1);
          right: 0;
          left: auto;
        }
      }
    }
  }
}

.monitor-autocomplete-wrapper {
  .monitor-autocomplete-select {
    width: 100% !important;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    @include ar {
      padding-right: 6px;
      padding-left: 30px;
    }
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
    padding: 0;
  }
  .monitor-autocomplete-input {
    input {
      font-size: 13px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      height: 40px;
      padding: 0px 15px !important;
    }
    &.selected {
      label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  .MuiAutocomplete-endAdornment {
    .MuiButtonBase-root {
      cursor: pointer;
    }
    @include ar {
      left: 9px !important;
      right: auto;
    }
    .MuiAutocomplete-popupIndicatorOpen {
      @include ar {
        transform: rotatex(180deg) !important;
      }
    }
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    @include ar {
      text-align: right;
    }
  }
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    font-size: 13px;
    font-weight: 400;
    &:not(.Mui-focused) {
      @include en {
        transform: translate(14px, 11px) scale(1);
      }
      @include ar {
        transform: translate(-14px, 11px) scale(1);
        right: 0;
        left: auto;
      }
    }
    &.Mui-focused {
      @include ar {
        right: 15px;
        left: auto;
      }
    }
  }
}

.monitor-autocomplete-paper-menu {
  .MuiAutocomplete-noOptions {
    color: rgba(0, 0, 0, 0.38);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
  .monitor-dropdown-placeholder {
    display: flex;
    padding: 2px 11px;
    align-items: flex-start;
    align-self: stretch;
    color: rgba(0, 0, 0, 0.38);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
}
.MuiAutocomplete-option.monitor-select-option-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  gap: 8px;

  .monitor-select-option-image {
    width: 30px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  .monitor-select-option-title {
    display: flex;
    flex: 1 1 0;
    align-items: center;
    gap: 8px;
  }
  p {
    color: rgba(0, 0, 0, 0.87);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.15px;
  }
  span {
    font-size: 14px !important;
    line-height: 150%;
    letter-spacing: 0.15px;
    font-weight: 500;
  }
}
