//please don't add any style here we should add styles in specific file.scss

@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
// Start varaiables section
$app-font-family-english: "Figtree", sans-serif;
$app-font-family-arabic: "IBM Plex Sans Arabic", sans-serif;
$base-color: #456eb2;
$black-color: #000000;
$white-color: #ffffff;
$secondary-title-color: #29426b;
$gray-color: #64748b;
$seperator-color: #cbd5e1;
// page header
// main title
$page-header-font-size: 22px;
$page-header-top-margin: 40px;
$page-header-bottom-margin: 30px;
// secondary title
$secondary-title-font-size: 18px;
$secondary-header-top-margin: 40px;
$secondary-header-bottom-margin: 20px;
// End varaiables section
body {
  padding: 0 !important;
  background-color: #f8fafc;
  font-family: $app-font-family-english;
  overflow-x: hidden !important;
  padding-inline-end: 6px;
  @include ar {
    font-family: $app-font-family-arabic;
    direction: rtl;
    overflow-x: hidden;
  }
  min-height: calc(100vh - 45px);
  padding-bottom: 45px;
  position: relative;
}

.modal-open-body {
  max-height: 100vh;
}

div,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: $app-font-family-english !important;
  @include ar {
    font-family: $app-font-family-arabic !important;
  }
}

//button default style to remove border and BG
%button-style {
  outline: none;
  background-color: transparent;
  border: none;
}

button {
  font-family: $app-font-family-english !important;
  text-transform: capitalize !important;
  @include ar {
    font-family: $app-font-family-arabic !important;
  }
}
a {
  color: unset;
  text-decoration: none;
}

//we keep it as used in all pages.
.preloader-msg {
  color: #6d737a;
  margin-right: 7px;
  @include ar {
    margin-left: 8;
  }
}
.preloader-box {
  margin-bottom: 20px;
}
.preloader-msg-text {
  color: "#6D737A";
  font-style: "italic";
}
.circular-loading {
  position: relative;
  top: 200px;
  text-align: center;
}
#circular-progress-component {
  color: $base-color;
}
// Headers
#main-pages-header,
#main-pages-header-subtitles,
#main-pages-header-with-breadcrumb {
  color: $black-color !important;
  font-size: $page-header-font-size !important;
  font-weight: 700;
}
#main-pages-header {
  margin-top: $page-header-top-margin !important;
  margin-bottom: $page-header-bottom-margin !important;
}
#main-pages-header-subtitles {
  margin-top: $page-header-top-margin !important;
  margin-bottom: 10px !important;
}
#main-pages-header-with-breadcrumb {
  margin-bottom: 10px;
  margin-top: 0px;
}

.title-header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px !important;
  #title-button-box-secondary {
    margin-top: 40px;
  }
}
// General Material UI Styles
.Mui-checked {
  color: $base-color;
}
.input-form-control,
.MuiInputBase-input,
.MuiInputBase-input.Mui-focused {
  &:focus {
    border-color: $base-color;
  }
}
.Mui-focused {
  fieldset {
    border-color: $base-color;
    color: $base-color;
  }
}
.MuiInputLabel-outlined.Mui-focused {
  color: $base-color;
}
#general-btn {
  &:hover {
    background-color: $base-color;
    border-color: #456eb2;
    color: #456eb2;
  }
}
.MuiInputLabel-outlined.Mui-focused {
  color: #456eb2;
}

.MuiDateRangePickerDay-root {
  .MuiDateRangePickerDay-day {
    color: #1a174f;
  }
  .Mui-selected {
    color: #fff;
  }
}
.Mui-selected {
  color: $white-color;
}
.social-media-icon {
  display: flex;
  margin-inline-end: 5px;
  svg {
    font-size: 16px;
  }
  img {
    width: 16px;
    height: 16px;
  }
  .facebook {
    color: #4267b2;
  }
  .instagram {
    color: #e1306c;
  }
  .intercom {
    color: #0057ff;
  }
  .linkedin {
    color: #0a66c2;
  }
  .twitter {
    color: #1da1f2;
  }
  .gmail {
    color: #ea4335;
  }
  .whats {
    color: #25d366;
  }
}

#breadcrumb-style,
#breadcrumb-style-monitors {
  color: $gray-color;
  font-size: 12px;
  text-transform: capitalize !important;
}
#breadcrumb-style-monitors {
  margin-top: 40px !important;
}
.MuiBreadcrumbs-separator {
  color: #cbd5e1;
}

body #hubspot-messages-iframe-container.widget-align-right {
  bottom: 95px !important;
  right: 26px !important;
  z-index: 1000 !important;
}
#hubspot-messages-iframe-container {
  bottom: 95px !important;
  right: 26px !important;
}
.circular-dotted-loading.MuiBox-root {
  padding: 20px;
  text-align: center;
  svg {
    margin-inline: 8px;
  }
}

.page-header-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;

  .page-header-text {
    flex-grow: 1;
  }
}