@import "../../utils/colors.scss";

@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.statistic {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: $statisticsBoxShadow;
  margin-top: 0px;
  margin-right: 2%;
  flex: 1 1 14%;
  position: relative;
  padding: 20px;
  text-align: center;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    color: #6d737a;
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 18px;
    height: 20px;
    border-bottom: 1px dashed #cbd5e1;
  }

  &:first-child {
    @include ar {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:last-child {
    @include en {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:first-child {
    @include ar {
      margin-right: 0;
    }
  }
}

.statistic__tooltip {
  border-radius: 50%;
  background-color: #efefef;
  color: #c4c4c4;

  position: absolute;
  top: 12px;
  left: 12px;
  width: 24px;
  height: 24px;
}

.statistic__icon {
  border-radius: 50%;
  box-shadow: 0px 5px 16px #fba8004d;
  color: #fff;
  width: 60px;
  height: 60px;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg.svg-inline--fa {
    width: 22px;
    height: 22px;
  }
}

.statistic__icon--yellow {
  background: transparent
    linear-gradient(134deg, #fad100 0%, #dfbb02 50%, #c4a504 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 5px 16px #e2bd0a4d;
}

.statistic__icon--grey {
  background: transparent linear-gradient(135deg, #d0d0d0 0%, #a3a3a3 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #9191914d;
}

.statistic__icon--orange {
  background: transparent linear-gradient(139deg, #f9a700 0%, #ec6f00 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #fba8004d;
}

.statistic__icon--black {
  background: transparent linear-gradient(135deg, #6d737a 0%, #2c2e31 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #6d737a57;
}
.statistic__icon--darkBlue {
  background: transparent linear-gradient(180deg, #5e7cf5 0%, #133abd 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #6d737a57;
}
.statistic__icon--blackQuick {
  background: #475569;
  box-shadow: 0px 0px 0px #475569;
}
.statistic__icon--blueQuick {
  background: #5692d7;
  box-shadow: 0px 0px 0px #5692d7;
}
.statistic__icon--green {
  background: transparent linear-gradient(135deg, #b9ce00 0%, #88bd13 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #94c3004d;
}

.statistic__icon--blue {
  background: transparent linear-gradient(135deg, #46c9ff 0%, #0083bc 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #009ae24d;
}

.statistic__icon--darkblue {
  background: transparent linear-gradient(135deg, #5e7cf5 0%, #133abd 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #cbd5ff;
}
.statistic__icon--red {
  background: transparent linear-gradient(135deg, #fa5775 0%, #e50c35 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #db65634d;
}

.statistic__icon--pink {
  background: transparent linear-gradient(135deg, #ff8e8e 0%, #b94646 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #db65634d;
}

.statistic__icon--purple {
  background: transparent linear-gradient(135deg, #ee8eff 0%, #7b1cb7 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #ebbbfd;
}
//
.statistic__icon--darkgreen {
  background: transparent linear-gradient(135deg, #57e3a8 0%, #13bda9 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #cbd5ff;
}
.statistic__value {
  color: #0c192a;
  font-size: 23px;
  font-weight: 600;

  span {
    color: #aab1ba;
    font-size: 18px;
    padding: 0px 5px;
  }
}

.dashoard-statistic-table {
  width: 100%;
  height: 175px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 4px #e3e3e3;
  border-radius: 5px;
  text-align: center;
  border-collapse: collapse;
  th {
    border-right: 1px solid #e3e3e3;
  }
  .dashoard-statistic-table__value {
    color: #0c192a;
    font-size: 23px;
    font-weight: 600;
  }
  .dashoard-statistic-table__title {
    color: #6d737a;
    font-size: 12px;
    font-weight: bold;
    margin: 10px;
  }
  .dashoard-statistic-table__icon {
    border-radius: 50%;
    color: #fff;
    width: 50px;
    height: 50px;
    margin: 0 auto 18px;
    padding-top: 16px;
    svg.svg-inline--fa {
      width: 22px;
      height: 18px;
    }
  }
}

//People Page Statistics
.statistic-people {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 4px #e3e3e3;
  border-radius: 5px;
  padding: 20px 20px 19px;
  flex: 1 1 14%;
  margin-right: 2%;
  position: relative;

  p {
    margin-bottom: 0;
  }

  &:last-child {
    @include en {
      margin: 0;
    }
  }

  &:first-child {
    @include ar {
      margin: 0;
    }
  }

  @include ar {
    text-align: right;
  }
}

.statistic-people__icon {
  border-radius: 50%;
  box-shadow: 0 5px 16px #fba8004d;
  color: #fff;
  width: 51px;
  height: 51px;
  margin: 0 10px 0 0;
  padding-top: 19px;
  .fa-comment {
    width: 18px;
    height: 18px;
  }
  .fa-smile {
    width: 23px;
    height: 23px;
    margin-top: -3px;
  }
  .fa-comments {
    width: 24px;
    height: 21px;
    margin-top: -3px;
  }
  .fa-question-circle {
    width: 24px;
    height: 21px;
    margin-top: -3px;
  }
  .fa-frown {
    width: 24px;
    height: 21px;
    margin-top: -3px;
  }
  .fa-meh,
  .svg-inline--fa {
    width: 24px;
    height: 21px;
    margin-top: -3px;
  }
}

.statistic-people__icon--orange {
  width: 51px;
  height: 51px;
  background: transparent
    linear-gradient(139deg, var(--unnamed-color-f9a700) 0%, #ec6f00 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(139deg, #f9a700 0%, #ec6f00 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #fba8004d;
  border-radius: 29px;
  float: left;
  text-align: center;
  margin: 0px 10px 0px 10px;

  @include ar {
    float: right;
  }
}

.statistic-people__icon--black {
  background: transparent linear-gradient(135deg, #6d737a 0%, #2c2e31 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #6d737a57;
  float: left;
  text-align: center;
  padding-top: 16px;
}

.statistic-people__icon--grey {
  background: transparent linear-gradient(135deg, #d0d0d0 0%, #a3a3a3 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #9191914d;
  float: left;
  text-align: center;
  padding-top: 16px;
}

.statistic-people__icon--blue {
  width: 51px;
  height: 51px;
  background: transparent linear-gradient(135deg, #46c9ff 0%, #0083bc 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #009ae24d;
  border-radius: 29px;
  float: left;
  text-align: center;
  margin: 0px 10px 0px 10px;

  @include ar {
    float: right;
  }
}
.statistic-people__icon--green {
  width: 51px;
  height: 51px;
  background: transparent linear-gradient(137deg, #b9ce00 0%, #88bd13 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #94c3004d;
  border-radius: 29px;
  float: left;
  text-align: center;
  margin: 0px 10px 0px 10px;

  @include ar {
    float: right;
  }
}

.statistic-people__icon--yellow {
  width: 51px;
  height: 51px;
  background: transparent
    linear-gradient(134deg, #fad100 0%, #dfbb02 50%, #c4a504 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 5px 16px #94c3004d;
  border-radius: 29px;
  float: left;
  text-align: center;
  margin: 0px 10px 0px 10px;

  @include ar {
    float: right;
  }
}
.statistic-people__icon--red {
  width: 51px;
  height: 51px;
  background: transparent linear-gradient(135deg, #fa5775 0%, #e50c35 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #e50c354d;
  border-radius: 29px;
  float: left;
  text-align: center;
  margin: 0px 10px 0px 10px;

  @include ar {
    float: right;
  }
}
.statistic-poeple__value {
  color: #0c192a;
  font-size: 27px;
  font-weight: 600;
  text-transform: capitalize;
}
.d-flex {
  display: flex;
  position: relative;
}

.statistics-empty-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #6d737a;
}
.genesys-statistic-value {
  @include ar {
    direction: ltr;
  }
}
