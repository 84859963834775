@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.questions-box {
  height: 78px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 20px;

  @include ar {
    padding: 0px 22px;
  }
}

.questions-dropdown {
  padding: 0px;
  position: absolute;
  width: fit-content;
  height: 38px;
  margin-left: 20px;
  margin-top: 20px;
  min-width: 350px;
  max-width: 350px;

  svg {
    @include ar {
      right: 315px !important;
    }
  }

  span {
    overflow: hidden;
    width: 300px;
    text-overflow: ellipsis;
  }
}

.answers-box {
  height: fit-content;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  border-radius: 4px;
  padding: 30px;
}
.answers-box {
  .answers-title {
    margin-bottom: 10px;
    margin-inline-start: 5px;
    font-weight: 500;
  }
}
.survey-insights-divider {
  margin-bottom: 30px !important;
}
.answers-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.87);
}

.box-scroll {
  overflow: auto;
  max-height: 330px;
}

.answers-header {
  height: 38px;
  display: inline-flex;
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000de;
}

.single-text-answer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 28px;
  gap: 10px;
  height: fit-content;
  left: 25px;
  top: 83px;
  margin: 10px 20px;
  background: #f1f5f9;
  border-radius: 4px;
}

.questions-tabs {
  background-color: #ffffff;
  height: 48px;
  button {
    color: #64748b;
    font-size: 14px;
    height: 48px;
    &.selected {
      color: #233759;
      border-bottom: 3px solid #456eb2;
    }
  }
}

.survey-insight-pagination {
  @include ar {
    margin-left: 65px !important;
  }

  @include en {
    margin-right: 65px !important;
  }

  svg {
    @include ar {
      transform: scale(-1, 1);
    }
  }
}

.question-accordion {
  margin: 20px 25px;

  .question-source {
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 4px 4px 0px 0px !important;
    margin: 0 !important;
  }

  .accounts-number {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    top: 10px;
    color: #3b873e;
    position: relative;
  }

  .account-datasource {
    width: 90%;
    flex-shrink: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    bottom: 10px;

    img {
      top: 10px;
      position: relative;
      width: 34px;
      margin-inline-end: 10px;
    }
  }
}

.accordion-details-body {
  padding: 8px 25px 16px !important;
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #334155;
  background: #ffffff;
  border: 0.9px solid #f1f5f9;
  border-radius: 4px;
  margin: 10px;
}

.questions-panel {
  padding: 24px 0px 24px 0px !important;
}

.fetching-questions {
  position: relative;
  top: 50%;
  right: 0;
  margin: 80px;
  height: 100px;
}

.pie-chart-question {
  width: 950px;
  max-width: -webkit-fill-available;
  margin: auto;
  margin-top: 29px;
}
.nps-item-parant {
  position: relative;
  .nps-item {
    position: absolute;
    z-index: 11;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 98px;
    text-align: center;
    p,
    h4 {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      line-height: 143%;
      white-space: nowrap;
      text-align: center;
    }

    h4 {
      font-weight: 500;
    }
  }
}
.nps-question {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  .nps-item {
    top: 45%;
    @include en {
      left: 53.5%;
    }
  }
  .item-nps {
    width: 40%;
    text-align: start;
  }
  .item-nps-chart {
    width: 60%;
    > div {
      padding: 0;
    }
    .chart {
      canvas {
        height: 310px !important;
      }
    }
  }
  .item-scale-chart {
    width: 100%;
    > div {
      padding: 0;
    }
  }

  .chart {
    box-shadow: none;
    width: 100%;
    padding: 0;
    padding-inline-start: 20px;
  }
}
@media (max-width: 1920px) {
  .nps-question .nps-item {
    @include ar {
      left: 47%;
    }
  }
}
@media (max-width: 1500px) {
  .nps-question .nps-item {
    @include ar {
      left: 46%;
    }
  }
}
@media (max-width: 1400px) {
  .nps-question .nps-item {
    @include ar {
      left: 45%;
    }
  }
}

.answer-nps-questions {
  background-color: #f8fafc;
  .item-answer {
    margin-bottom: 10px;
    padding: 40px 30px 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    background-color: #ffffff;
  }
}
.answer-box-nps {
  overflow: hidden;
  .answers-header {
    display: flex;
    padding-inline-start: 0;
    align-items: flex-end;
    justify-content: flex-start;
    color: #0c192a;
    font-weight: 500;
    font-size: 15px;
  }
  > :first-child {
    width: 100%;
    background-color: #ffffff;
    height: auto;
  }
  .radio-item-individual {
    border-bottom: 1px solid #cbd5e1;
  }
}
h6.survey-title {
  color: #64748b;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  margin: 29px 0 23px;
}
.linkedin-title {
  color: #475569 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin: 60px 0 0 !important;
}
.insight-footer {
  display: flex;
  justify-content: center;
  margin: 60px 0;
  color: #64748b;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.survey-sentiment-categories-themes-container {
  margin-top: 20px;
}

.single-text-answers-parant {
  padding-top: 20px;
  padding-bottom: 16px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding-inline-start: 20px;
  padding-inline-end: 35px;
  margin-bottom: 10px;
  margin-inline-end: 10px;
  .single-text-answers {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #334155;
    margin-bottom: 20px;
  }
}

.single-text-answers-parant:nth-child(1) {
  margin-top: 10px;
}
.values-text {
  display: flex;
  justify-content: space-between;
  @include ar {
    flex-direction: row-reverse;
  }
}
.MuiTypography-root.questions-statistics {
  font-size: 14px;
  font-weight: 500;
  color: #64748b;
  margin: 40px 0 14px;
  line-height: 16.8px;
  letter-spacing: 0.12em;
}
.section-name-insights {
  padding: 30px;
  background-color: #ffffff;
  border-top: 5px solid #456eb2;
  border-radius: 4px;
  margin-top: 50px;
  color: #000000de;
  font-weight: 700;
  font-size: 18px;

  &:first-child {
    margin: 0;
  }
}
.question-direction-rtl {
  direction: rtl;
  .nps-question {
    .nps-item {
      @include en {
        left: 46.5%;
      }
    }
  }
  .single-header-options {
    .MuiOutlinedInput-root {
      direction: ltr;
    }
  }
  .flag-container {
    @include ar {
      direction: rtl;
    }
  }
  .phoneInput.insights {
    right: 86px;
    text-align: right;
  }
}
