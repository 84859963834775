.multiple-languages-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .align-box {
    display: flex;
    direction: ltr;
    #align-right-btn,
    #align-left-btn {
      padding: 6px;
      height: max-content;
      min-width: max-content;
      cursor: pointer;
      border: 1px solid #cbd5e1;
      color: #00000099;
    }
    #align-right-btn.active,
    #align-left-btn.active {
      border-width: 1px;
      border-color: #456eb2;
      border-style: solid;
      color: #456eb2;
      background: #f1f5f9;
    }
    #align-right-btn {
      border-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    #align-left-btn {
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  label.multiple-responses.MuiFormControlLabel-root {
    margin: 0;

    span.MuiTypography-root {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 400;
      line-height: 143%;
    }
  }
}
.section-direction-ltr {
  .multiple-languages-container {
    #align-right-btn {
      border-inline-start: none;
    }
  }
}
.section-direction-rtl {
  .multiple-languages-container {
    #align-right-btn {
      border-inline-end: none;
    }
    #align-left-btn {
      border-inline-end: none;
    }
  }
}
