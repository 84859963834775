.pdf-cover-news-letter {
  .main-title-pdf-cover-news-letter {
    margin-bottom: 77px;
    .title {
      font-size: 44px;
      font-weight: 700;
      line-height: 62.92px;
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
    }
  }

  .details-active-monitor-report {
    list-style: none;
    li {
      margin-bottom: 14px;
      span {
        &:first-child {
          font-weight: 700;
          text-transform: capitalize;
        }
        &:last-child {
          font-weight: 500;
        }
      }
    }
  }
}
