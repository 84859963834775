#settings-save-changes-footer {
  background: #ffffff;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 60px;
  bottom: 0;
  right: 0;
  z-index: 2;
  box-shadow: none;
  .save-changes-footer-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-inline-start: 260px;
    .btns-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
  }
}
