@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.theme-tab {
  display: inline-block;
  cursor: pointer;
  clear: both;

  @include en {
    text-align: left;
  }

  @include ar {
    text-align: right;
  }

  border-bottom: 1px solid #eaeaea;

  span {
    color: #2c2e31;
    display: inline-block;
    font-size: 12px;
    padding: 13px;
    font-weight: 500;

    &.active,
    &:hover {
      color: #009cdc;
      border-bottom: 2px solid #009cdc !important;
    }
  }
}

.postsColumn_chart {
  box-shadow: 0 4px 4px #e3e3e3;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.posts-stats-wrapper,
.interactions-stats-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}
.posts-stats-wrapper {
  margin-top: 30px;
}

.grid-of-two {
  grid-template-columns: repeat(2, 1fr);
  padding: 0px 42px 0px 40px;
}

.display-in-end {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.view-all-interactions {
  font-size: 12px;
  color: #2aaae2;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.posts_stats {
  color: #334155;
  font-weight: 500;
  font-size: 18px;
  border: 2px solid #f1f5f9;
  border-radius: 5px;
  height: 40px;
  padding: 15px;

  span {
    color: #64748b;
    font-size: 12px;
    font-weight: 400;
    margin: 0px 10px;
  }
}

.posts_stats_name {
  color: #8b9aaf;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;

  @include ar {
    margin-top: 0px;
  }
}
.chart-details-date-on-date-format {
  margin-inline-end: 0px;
  margin-inline-start: 10px;
  margin: 0px 0px !important;
}
.view-interactions-icons {
  @include ar {
    transform: rotate(180deg);
  }
}
