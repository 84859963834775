@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.user-profile-header {
  margin: 30px 0 40px 0;
  border-bottom: 1px solid #cbd5e1;
  .user-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    line-height: 75px;
  }
  .user-profile-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .back-audience {
    font-weight: 500;
    font-size: 15px;
    color: #80868c;
    cursor: pointer;
  }
  .audience-profile {
    font-weight: 500;
    font-size: 20px;
    color: #0c192a;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .profile-icon {
    margin-inline-end: 5px;
  }
  @include en {
    .profile-icon {
      transform: rotate(0deg);
    }
  }

  @include ar {
    .profile-icon {
      transform: rotate(180deg);
    }
  }
}
