@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.item-slide-get-started {
  height: 309px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #f8f8f8;
  }
  .img-box {
    overflow: hidden;
    width: 100%;
    .get-started-img {
      height: auto !important;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
  }
  .get-started-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 0px 15px 15px 15px;
  }
  .item-slide-get-started {
    height: 100px;
  }
  .get-started-type {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .get-started-message {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: start;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 60px;
  }
  .get-started-btn {
    display: flex;
    min-width: 70px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    color: #456eb2;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: capitalize;
    &:hover {
      background: transparent;
    }
  }
}
.get-stared {
  .skeleton-img-box {
    padding: 15px;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .skeleton-parant {
    height: 311px;
    width: 100% !important;
    gap: 0;
    padding: 0;
    .skeleton-img {
      flex-shrink: 0;
    }
    .skeleton-head {
      flex-direction: column;
      align-items: start;
      gap: 4px;
    }
  }
}
.get-started-dialog {
  padding: 0 30px 30px;
  gap: 8px;
  .get-started-dialog-content {
    position: relative;
    padding: 0;
    img {
      height: 250px;
      width: 600px;
    }
    .get-started-information-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
      padding: 24px;
      max-height: 200px;
      overflow: auto;
      .item-title {
        font-weight: 500;
      }
      .item-description {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }
      .desc-points {
        padding-top: 10px;
        .desc-point-box {
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
          font-size: 14px;
          span.desc-point {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 18px;
            min-height: 18px;
            color: #1a174f;
            border: 1px solid #1a174f;
            border-radius: 50%;
            font-size: 12px;
            @include en {
              margin-right: 10px;
            }
            @include ar {
              margin-left: 10px;
            }
          }
          .desc-point-icon {
            margin: 0 4px;
          }
          .desc-point-link {
            font-weight: 500;
            color: #456eb2;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .notes {
        .title {
          font-size: 14px;
          font-weight: 500;
        }
        ul li {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  .get-started-dialog-action {
    padding: 20px 16px;
    gap: 10px;
  }
}
