@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.survey-builder-display-settings-tab-wrapper {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 24px 20px;
}

p.display-settings-options-subtitle {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  margin: 0 0 10px 0;
}

.display-settings-color-picker-field {
  width: 100%;
  height: 32px;
  font-size: 12px;

  .MuiInputBase-root.MuiOutlinedInput-root {
    padding-inline: 8px;
    @include ar {
      padding-inline: 8px;
    }
  }
  > div,
  > div > div {
    width: 100%;
    height: 32px;
    font-size: 12px;
  }

  input {
    @include ar {
      direction: ltr;
      text-align: end;
      padding-inline: 4px;
    }
  }

  .selected-color-preview-square {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    cursor: pointer;
  }
}

#survey-display-settings-color-picker-modal {
  > .MuiBackdrop-root.MuiModal-backdrop {
    display: none;
  }
}

#display-settings-options-accordion {
  box-shadow: none;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin: 0 0 12px 0px;
  &::before {
    display: none;
  }
  .display-settings-options-accordion-header {
    min-height: unset;
    &.Mui-expanded {
      border-bottom: 1px solid #dddddd;
      min-height: unset;
    }
    .MuiAccordionSummary-content,
    .MuiAccordionSummary-content.Mui-expanded {
      height: 34px;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .options-accordion-title-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;

      p,
      svg {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87);
      }

      .options-accordion-title-wrapper {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-inline-end: 5px;
      }
    }
  }

  .display-settings-options-accordion-body-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 16px 12px;

    .display-settings-options-subtitle {
      color: rgba(0, 0, 0, 0.87);
      font-size: 12px;
      margin: 0 0 10px 0;
    }
  }
}

#display-settings-options-switch-accordion {
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin: 0 0 12px 0px;

  .display-settings-switch-accordion-header-container {
    width: 100%;
    min-height: 32px;
    box-sizing: border-box;
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    .display-settings-options-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      flex-grow: 1;
      .display-settings-options-title,
      svg {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87);
      }
      .display-settings-options-title {
        flex-grow: 1;
      }
    }
  }

  .display-settings-switch-accordion-content-container {
    padding: 6px 16px;
    border-top: 1px solid #dddddd;
  }
}

#display-settings-options-select-menu-item {
  font-family: inherit;
  font-size: 12px;

  .display-settings-options-select-checkbox {
    padding: 5px;
  }

  &.fonts-select {
    @include ar {
      direction: ltr;
      padding-inline-start: 20px;
    }
  }
}

.display-settings-options-accordion-body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 16px 12px;

  .embedded-survey-display-settings-text-field {
    width: 100%;
    height: 32px;
    > div {
      width: 100%;
      height: 32px;
      font-size: 12px;
    }

    .display-settings-adornment p {
      color: rgba(0, 0, 0, 0.38);
      font-size: 12px;
    }

    &.opacity-text-field {
      > div {
        padding-inline: 0px 14px;
        @include ar {
          padding-inline-end: 14px;
        }
      }
    }

    &.opening-after-text-field {
      > div {
        padding-inline: 10px;
        > input {
          padding-inline: 0 3px;
        }
      }
    }

    input {
      padding-inline: 14px;
    }
  }

  p#embedded-survey-display-settings-text-field-helper-text {
    margin: 4px 0px 0px 0px;
    font-size: 12px;
  }

  .display-settings-options-select {
    width: 100%;
    height: 32px;
    font-size: 12px;

    .MuiSelect-select {
      padding-inline: 14px;
      @include ar {
        padding-inline: 14px;
      }
    }
    > div {
      height: 32px;
      font-size: 12px;
    }
  }

  .display-settings-opening-after-select {
    flex-grow: 1;
    width: auto;
  }

  .display-settings-options-accordion-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    // ********** Embed Type Options Styles **********
    .embed-type-wrapper {
      width: 100%;
      .embed-type-options-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        width: 100%;
        .embed-type-option {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          .embed-type-option-img-wrapper {
            padding: 5px;
            border-radius: 8px;
          }
          img {
            width: 100%;
            height: 100%;
          }
          p {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.87);
          }
          &.active {
            .embed-type-option-img-wrapper {
              padding: 0px;
              border: 5px solid #456eb2;
            }
            p {
              color: #456eb2;
              font-weight: 500;
            }
          }
        }
      }
    }

    // ********** Embed Size Options Styles **********
    .embed-size-wrapper {
      width: 100%;
      .embed-size-options-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        border: 1px solid rgba(0, 0, 0, 0.38);
        border-radius: 4px;
        .embed-size-option {
          width: 100%;
          font-size: 12px;
          text-align: center;
          color: rgba(0, 0, 0, 0.6);
          padding: 7px 0;
          cursor: pointer;
          p {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
            width: 100%;
          }
          &.active {
            outline: 2px solid #456eb2;
            border-radius: 4px;
            p {
              color: rgba(0, 0, 0, 0.87);
            }
            &:nth-child(1) {
              &.active {
                border-radius: 4px 0 0 4px;
                @include ar {
                  border-radius: 0 4px 4px 0;
                }
              }
            }
            &:nth-last-child(1) {
              &.active {
                border-radius: 0 4px 4px 0;
                @include ar {
                  border-radius: 4px 0 0 4px;
                }
              }
            }
          }
        }
      }
    }

    // ********** Embed Position Options Styles **********
    .embed-position-wrapper {
      width: 100%;
      .embed-position-options-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 10px;
        width: 110px;
        margin: 0 auto;
        direction: ltr;
        .embed-position-option {
          width: 30px;
          height: 30px;
          border: 1px solid #d9d9d9;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          cursor: pointer;
          &.active {
            background: #456eb2;
            color: #ffffff;
          }
          &.disabled {
            background: #d9d9d9;
            cursor: default;
          }
          &:nth-child(1) {
            border-start-start-radius: 4px;
          }
          &:nth-child(3) {
            border-start-end-radius: 4px;
          }
          &:nth-child(7) {
            border-end-start-radius: 4px;
          }
          &:nth-child(9) {
            border-end-end-radius: 4px;
          }
        }
      }
    }

    .survey-alignment-wrapper {
      width: 100%;
      .display-settings-alignment-option-switch {
        width: 100%;
        .align-box {
          width: 100%;
          > div {
            width: 50%;
            > button {
              width: 100%;
              &#align-right-btn,
              &#align-left-btn {
                padding: 8px 0px;
              }
            }
          }
        }
      }
    }

    .display-settings-options-accordion-content-option-wrapper {
      width: 100%;
      p.display-settings-warning-message {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        margin: 0 0 10px 0;
        word-wrap: break-word;
        margin: 8px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;
      }
      .display-settings-warning-icon {
        font-size: 14px;
        margin-top: 2px;
        @include ar {
          margin-top: 3px;
        }
      }
    }

    div#display-settings-font-type-select {
      font-family: var(--embedded-popup-font) !important;
    }

    div#display-settings-button-font-type-select {
      font-family: var(--embedded-button-font) !important;
    }

    .display-settings-opening-after-select-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .embedded-survey-display-settings-opening-after-form-control {
        width: 60px;
      }
    }
  }
}

.progress-bar-options-wrapper {
  padding: 6px 0px;
}
