@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.alert__dropdown__style {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.alert__outlined__box {
  font-size: 14px;
  height: 40px;
}

.alert__checkbox {
  @include ar {
    margin-left: 10px;
  }
  @include en {
    margin-right: 10px;
  }
}

.table--wrapper {
  min-height: 450px;
}

.table__head__wrapper {
  border-bottom: 1.5px solid #cbd5e1;
  tr {
    display: flex !important;
    align-items: center !important;
  }
}
.monitor__name {
  width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.linkedin__icon {
  background-color: #0a66c2;
}
.head__alert__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alert__main__head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .MuiOutlinedInput-root {
    background-color: #fff !important;
  }
}
.grayCircle {
  background: rgba(109, 115, 122, 0.2);
  width: 24px;
  height: 24px;
  border-radius: 12px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark.comma-seprate {
  display: flex;
}
// modalStyle
.modal__wrapper {
  min-width: 550px;
}
.optional__wrapper {
  display: flex;
  align-items: center;
}
.optional__wrapper {
  p {
    font-style: italic;
    font-size: 14px;
  }
}
.input__btn__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table__head__wrapper {
  border-bottom: 1.5px solid #cbd5e1;
}
.monitor__name {
  width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.css-13abfr3-MuiTableCell-root.MuiTableCell-body {
  // padding: 5px;
}
.a_btn {
  background: #f1f5f9;
  border: 1px solid #cbd5e1;
  box-sizing: border-box;
  padding: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-right: 0px;
}
html[lang="ar"] .a_btn {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-left: 0px;
}
.keyword_label {
  display: inline-block;
  font-weight: 500;
  padding: 1px 4px 0;
  line-height: 1.5384616;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1px;
  border: none !important;
  background: rgba(0, 154, 226, 0.1) !important;
  color: #009ae2 !important;
  padding: 5px !important;
  margin: 5px;
  span {
    margin: 0 5px;
  }
}

.custom__input {
  border-radius: 0px !important;
  ::placeholder {
    font-size: 12px;
  }
  input {
    border-right: transparent !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
html[lang="ar"] .custom__input {
  input {
    border-left: transparent !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}
.placeholder__select {
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 400 !important;
  top: -4px !important;
}
.handle__err {
  color: #ff0000;
  font-size: 12px;
}
.alert__btns {
  color: #475569 !important;
  font-size: 14px !important;
  svg {
    color: #cbd5e1 !important;
  }
}
html[lang="ar"] .alert__btns {
  font-family: "Dubai" !important;
}
html[lang="ar"] .placeholder__select {
  font-family: "Dubai" !important;
  right: 50px;
  top: -5px;
}
html[lang="ar"] .table__head {
  font-family: "Dubai" !important;
}

.alert__lables {
  color: #0c192a;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding-bottom: 5px;
  p {
    color: #80868c;
  }
}
.add__btn {
  font-size: 14px !important;
  svg {
    font-size: 14px !important;
  }
  border-left: transparent !important;
}
html[lang="ar"] .add__btn {
  svg {
    padding-left: 5px;
  }
  border-right: transparent;
  border-left: 1px solid #cbd5e1 !important;
}
html[lang="ar"] .popup__rightbtn {
  margin-left: 10px;
  margin-right: 10px;
}

.select__filters {
}
.edit__btns {
  svg {
    margin: 0px 10px;
    padding-bottom: 5px;
  }
}
.delete__snack {
  position: absolute !important;
  top: 95px !important;
  right: 200px !important;
}
html[lang="ar"] .delete__snack {
  right: auto !important;
  left: 200px;
}
.no__alerts {
  color: #64748b;
  font-size: 18px;
  text-align: center;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected__item {
  .MuiTypography-root {
    color: #475569 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  &.Mui-selected {
    background-color: transparent !important;
    color: #334155 !important;
    font-weight: 500 !important;
  }
  svg {
    font-size: 15px !important;
  }
}
