@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

$marginSection: 40px;

#newsletter-report {
  margin-bottom: 120px;
  * {
    //for loom extension with Google chrome
    letter-spacing: normal;
  }
  .view-report-no-data-container {
    padding-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  //  Header Report
  .header-title-and-export-newsletter-report {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 10px;
    margin-bottom: $marginSection;
  }

  // Statistic report cards
  .view-report-card {
    border: none;
    padding: 0;
    margin-bottom: $marginSection;

    .report-detail {
      .MuiTypography-subtitle1,
      .MuiTypography-subtitle2 {
        font-weight: 500;
      }
    }
  }

  // show monitor tabs
  .tab-swiper-monitors-report {
    margin-bottom: $marginSection;
    .title-tab-swiper {
      color: #64748b;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 8px;
    }

    .tab-swiper-monitors {
      .swiper-button-next,
      .swiper-button-prev {
        background-color: #f8fafc;
        border-radius: 0;
        color: #64748b;
        height: 100%;
        top: calc(50% - 5px);
        border: none;
        &:hover:after {
          background-color: #e2e8f0;
          color: #000000de;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
      }
      .swiper-button-next {
        right: 0;
        left: auto;
        @include ar {
          right: auto;
          left: 0;
        }
      }
      .swiper-button-prev {
        left: 0;
        right: auto;
        @include ar {
          left: auto;
          right: 0;
        }
      }

      .swiper-slide {
        display: flex;
        background-color: transparent;
        max-width: fit-content;
        padding: 14px 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19.2px;
        color: #64748b;
        border-bottom: 1px solid #f1f5f9;
        cursor: pointer;
        .count-articles-monitor {
          background-color: #64748b;
          min-width: 24px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 600;
          margin-inline-start: 6px;
          .MuiChip-label {
            padding-inline: 5px;
          }
        }

        &.active-monitor {
          border-bottom: 3px solid #456eb2;
          color: #233759;
          font-weight: 600;
          .count-articles-monitor {
            background-color: #456eb2;
          }
        }
      }
    }
  }

  // button to redirect specific monitor
  .open-in-monitor-btn-newsletter {
    text-align: center;
  }

  .chart-no-data {
    text-align: center;
  }
}
