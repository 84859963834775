@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.MuiStepper-root.MuiStepper-horizontal {
  .MuiStepLabel-label {
    color: #888888;
    font-size: 13px;
    width: max-content;

    &.Mui-active {
      color: #555555;
    }
  }
}
.card-dashboard-heading {
  display: flex;
  align-items: center;
  padding: 0px 0px 12px 0px;
  font-weight: 410;
  font-size: 16px;
  line-height: 24px;
  color: #475569;
}

.custom-coming-soon {
  font-size: 12px;
  font-weight: 400;
  @include en {
    padding-left: 5px;
  }
  @include ar {
    padding-right: 5px;
  }
}
#custom-text {
  opacity: 0.5;
}

span.dashboard-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #232b34;
  letter-spacing: 0.03333em;
  padding-top: 0px;
  padding-bottom: 20px;
}
.dashboard-name-container {
  padding-bottom: 5px;
}
#dashboard-divider,
#dashboard-divider-info {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #e4e4e4;
  height: 1px;
  border: none;
}
#dashboard-divider {
  margin-right: 70px;
  margin-left: 100px;
}

.dashboard-error-name {
  &.MuiBox-root {
    color: #f44336;
  }
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
}
.dashboard-star {
  color: #f44336;
}
.dashboard-sub-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  .dashboard-sub-title {
    color: #80868c;
    font-size: 13px !important;
  }
}
.dashboard-sub-title-note {
  padding-bottom: 4px;
}
.icons-dashboard-list {
  margin-top: 8px;
  width: auto;
  height: 27px;
  display: inline-flex;
  @include en {
    padding-right: 5px;
  }
  @include ar {
    padding-left: 5px;
  }
  .twitter-icon {
    font-size: 18px;
    margin-top: -2px;
  }
  .instagram-icon {
    font-size: 18px;
    margin-top: -3px;
  }
  .whatsapp-datasource-icon {
    font-size: 19px;
    margin-top: -18px;
    margin-right: 4px;
    margin-left: 0px;
  }

  #gmb-datasource-icon {
    top: -3px;
  }
  .gmail-datasource-icon {
    margin-top: 2px;
  }

  .intercom-datasource-icon {
    font-size: 18px;
    position: relative;
    top: 0px;
    @include en {
      margin-right: 5px;
    }
    @include ar {
      margin-left: 5px;
    }
  }
}
.monitor-tooltip {
  vertical-align: middle;
  padding-top: 8px;
  @include en {
    margin-left: 0px;
  }
  @include ar {
    margin-right: 0px;
  }
  svg {
    margin-top: 5px;
  }
}

.dashboard-monitors-list {
  margin-top: 10px !important;
}
.dashboard-monitors-checkbox {
  margin-top: -26px !important;
  @include en {
    margin-right: 0px !important;
  }
  @include ar {
    margin-left: 0px !important;
  }
}
.MuiListItemText-root span {
  font-size: 14px;
  font-weight: 500;
}
.list-item-text-style.MuiListItemText-root {
  span {
    margin: 0px 0px;
  }
}

.dashboard-monitor-icon {
  color: #ec8623;
  font-size: 16px !important;

  @include en {
    margin-left: 10px;
  }
  @include ar {
    margin-right: 10px;
  }
}
.dashboard-monitor-name {
  @include en {
    margin-left: 0px;
  }
  @include ar {
    margin-right: 10px;
  }
}
.dashboard-name-tooltip {
  display: flex;
  flex-direction: row;
}
.monitor-card-item {
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  padding: 8px 10px !important;
  height: 80px;
}

.monitor-card-selected {
  border: 2px solid #456eb2;
  border-radius: 8px;
  .dashboard-monitors-checkbox {
    cursor: pointer !important;
  }
}
.list-item-text-style {
  margin-top: 3px !important;
  margin-bottom: -6px !important;
  @include ar {
    text-align: right;
  }
}

#dashboard-show-less-monitors-btn,
#dashboard-show-all-monitors-btn {
  margin: 0 auto;
  margin-top: 40px;
}

#dashboard-empty-monitors-list {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.dashboard-empty-monitors-list-title {
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 15px;
}
.dashboard-empty-monitors-list-text {
  font-size: 14px;
  color: #80868c;
}
#dashboard-field {
  max-width: 54% !important;
}
.monitor-card-change-cursor {
  cursor: default !important;
}
.card-dashboard-heading-disabled {
  cursor: default;
}

.dashboard-container {
  .MuiStepConnector-root {
    margin: 0 28px;
    @include ar {
      margin: 0 0px 0px 14px;
    }
  }
}
.news-blogs-datasource-icon {
  svg {
    color: #64748b;
    font-size: 16px;
  }
}
.MuiFormControl-root.form-control-dashboard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  span.MuiButtonBase-root.Mui-checked ~ span .card-container {
    border: 1px solid #456eb2;
    svg {
      color: #456eb2;
    }
  }
  span.MuiButtonBase-root.MuiRadio-root {
    display: none;
  }
  .card-container {
    border: 1px solid #e2e8f0;
    padding: 18px 18px 24px;
    border-radius: 4px;
    div.card-dashboard-heading {
      color: #475569;
      font-family: Figtree;
      font-size: 16px;
      font-weight: 500;
    }
    .card-sub-text {
      @include en {
        min-height: 60px;
      }
    }
    svg {
      color: #00000061;
      margin-inline-end: 10px;
      font-size: 22px;
    }
  }
  .dashboard-form-control-label {
    > span {
      width: 100%;
    }
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
.dashboard-name-container {
  padding: 0 100px;
}
.dashboard-monitor-type {
  #dashboard-divider {
    margin: 24px 100px;
  }
}
.dashboard-type-container {
  padding: 0 100px;
  label {
    margin: 0;
    > span.MuiFormControlLabel-root.MuiFormControlLabel-label {
      width: 100%;
    }
  }
}

#create-dashboard-alert-msg {
  font-weight: 400;
  margin-bottom: 20px;
  &.aggregate-alert,
  &.dashboards-limit-reached-alert {
    .MuiAlert-message {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      a {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
  &.aggregate-alert {
    background-color: #e8f4fe;
    color: #0d3c61;
    margin-top: 20px;
    a {
      color: #0d3c61;
    }
  }
  &.dashboards-limit-reached-alert {
    color: #5f2b01;
    a {
      color: #5f2b01;
      line-height: normal;
    }
    .btns-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      #custom-dashboard-limit-reach-alert-close-btn {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
