@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.select {
  width: 150px;
  height: 40px;
  color: #9d9d9d !important;
  padding: 0px !important;

  #demo-simple-select {
    @include ar {
      padding-right: 10px;
    }
  }
  svg {
    @include ar {
      right: 110px;
    }
  }
}

.groupby_clientagent {
  position: relative;
  padding-right: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  @include ar {
    padding-left: 20px;
  }
  &::after {
    position: absolute;
    content: "";
    right: 0;
    top: 10px;
    bottom: 0;
    width: 2px;
    height: 25px;
    background-color: #e2e8f0;
    @include ar {
      right: unset;
      left: 0;
      top: 5px;
    }
  }
}
#channel_activity {
  .echarts-for-react {
    z-index: 1;
  }
}
.groupby_wrapper {
  display: flex;
  align-items: center;
  text-align: justify;
}
.groupby_title {
  font-size: 11px;
  margin: 0px 10px;
}

.dropdown-field {
  width: 120px;
}
.dropdown-field{
  .MuiInputLabel-outlined.Mui-focused {
    color: rgba(0, 0, 0, 0.6) ;
  }
  .Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
}