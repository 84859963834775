@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.accounts-select-publish-items-dm {
  .MuiSelect-select {
    padding: 10px 10px !important;
    font-size: 14px;
    font-weight: 400;
  }
}

.recevier-account-content {
  .MuiButtonBase-root {
    font-size: 17px;
    padding: 0px;
  }
  .MuiInputBase-root {
    height: 45px;
  }
  &.MuiFormControl-root {
    width: 100%;
  }
}
.publish-pop-up-main-content {
  .MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
  }
}
.new-conv-recveier-acc {
  padding: 0px 20px;
  margin-bottom: 20px;
  position: relative;
  .MuiInputBase-input{
    font-size: 14px;
    font-weight: 400;
  }
  .publish-new-post-accounts-title {
    &.MuiTypography-root {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 6px;
    }
  }
}
.reciver-accounts-wrapper {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  top: 75px;
  left: 20px;
  right: 0;
  max-height: 140px;
  border-radius: 4px;
  width: calc(100% - 40px);
  overflow-y: auto;
  box-shadow: 0px 6px 28px 5px rgba(0, 0, 0, 0.12),
    0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 8px 9px -5px rgba(0, 0, 0, 0.2);
  .accounts-publish-inside-item {
    &:hover {
      background-color: rgba(250, 250, 250, 1);
      cursor: pointer;
    }
    padding: 6px 16px 6px 16px;
  }
  @include ar{
    left: 0px;
    right: 20px;
  }
}
.reciver-accounts-wrapper-no-data{
  @extend .reciver-accounts-wrapper;
  height: 45px;
  @include ar{
    left: 0px;
    right: 20px;
    overflow: hidden;
  }
}
.receiver-load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.recevier-account-alert{
  .MuiPaper-root{
    margin: 0px 20px;
    padding: 0px 6px !important;
    border-radius: 4px;
    border: 1px solid rgba(213, 240, 252, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 20.02px;
    margin-bottom: 20px;
  }
}
.receiver-no-users{
  color: #696D72;
  font-size: 12px;
  font-weight: 400;
  padding: 15px;
}