.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  font-family: "Graphik" !important;
  .not-found-empty-box{
    width: 330px;
    text-align: center;
    a{
      margin-inline-start: 4px;
    }
  }
  img{
    margin-bottom: 20px;
  }
  h1 {
    font-weight: bold;
    font-size: 123px;
    letter-spacing: 0.03em;
    color: #475569;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    color: #475569;
  }
  p {
    font-size: 18px;
    color: #475569;
    font-weight: 400;
  }
  a{
    color: #456EB2 ;
    font-weight: 500;
  }
}
