.apply-parant {
  padding: 0px 24px;
  .head-title-apply {
    margin-bottom: 30px;
    color: #666 !important;
    font-size: 14px;
    font-weight: 500;
  }
  .insights-accordion {
    box-shadow: none !important;
    margin-bottom: 20px;
    &::before {
      display: none !important;
    }
    .insights-accordion-summary {
      background-color: #f8fafc;
      border: 1px solid #e2e8f0;
      border-radius: 5px;
      &:hover {
        background-color: #f1f5f9;
      }
      &.Mui-expanded {
        border-radius: 5px 5px 0px 0px;
        min-height: 48px !important;
        > div {
          margin: 0 !important;
        }
      }
    }
    .insights-accordion-details {
      padding: 0px !important;
    }
  }
  .apply-items {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    background: #fff;
    margin-bottom: 15px;
    width: 100%;
    &.first-question-btn {
      border-top: none;
      border-radius: 0px 0px 5px 5px;
    }
    &:hover {
      background-color: #fafafa;
    }
    span {
      color: #676767 !important;
    }
  }
  p {
    font-size: 16px;
    color: #212121 !important;
    font-weight: 500;
  }
}
