#progreess-and-details-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .progress-wrapper {
    position: relative;
    display: inline-flex;
    .progress-unused-track {
      position: absolute;
      color: #e2e8f0;
    }
    .progress-used-track {
      stroke-linecap: round;
    }
    .progress-value-container {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      > p {
        color: #456eb2;
        font-weight: 700;
        font-size: 14px;
        &.max-value-reached {
          color: #f44336;
        }
      }
    }
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    h6 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 600;
      font-size: 16px;
      > svg {
        color: rgba(0, 0, 0, 0.6);
      }
    }
    p {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      > span {
        margin-inline: 3px;
      }
    }
  }
}

#progress-title-tooltip {
  font-weight: 500;
  font-size: 12px;
  padding: 8px 10px;
  min-width: 150px;
  text-align: start;
}
