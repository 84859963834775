@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.theme_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-themes-theme-trand.select {
  width: auto;
  @include ar {
    svg {
      right: 80px;
    }
  }
}

.btn-themes-theme-trand {
  label {
    @include en {
      font-size: 14px;
    }
    @include ar {
      background: #ffffff;
      padding: 0 8px;
    }
  }
  height: 40px;
  display: none;
  cursor: pointer;
  > div {
    font-size: 14px;
    height: 40px;
    width: fit-content;
    div {
      padding: 0;
    }
  }
}
.btn-group-by {
  label {
    @include en {
      margin-left: -4px;
    }
  }
}
.select__container > div > div > div {
  @include ar {
    padding: 9px 20px 7px 30px;
  }
  @include en {
    padding: 9px 35px 7px 10px;
  }
}

.icons-wrapper .select__container {
  @include ar {
    svg {
      right: auto !important;
      left: 0px !important;
    }
  }
}
