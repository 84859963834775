@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.manage-user-account-list-create-button {
  .create-list-button {
    width: 100%;
    @include en {
      justify-content: left;
      padding-left: 0px;
    }
    @include ar {
      justify-content: right;
      padding-right: 0px;
    }
  }
  .empty-list-message {
    @include en {
      margin-left: 15px;
    }
    @include ar {
      margin-right: 15px;
    }
  }
}
