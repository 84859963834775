@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.select-wrapper {
  width: 100%;

  .MuiSelect-select.MuiSelect-outlined {
    padding: 9px 14px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}

.autocomplete-dropdown-container {
  width: 100%;
  .autocomplete-dropdown-label {
    font-size: 14px;
    color: #0f172a;
    margin-top: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 8px;

    svg {
      color: #bdbdbd;
      width: 15px;
      height: auto;
    }
  }

  &.section-box-data-keyword-setting {
    .autocomplete-dropdown-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #1e293b;
      margin: 0;
    }
  }

  .autocomplete-dropdown-wrapper {
    .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        pointer-events: none;
        cursor: pointer;
      }
    }

    &.default-wrapper {
      width: 100%;
    }
  }
}

.autocomplete-dropdown-controll-wrapper {
  width: 100% !important;
  .autocomplete-dropdown-controll-select {
    width: 100% !important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    @include ar {
      padding-right: 0px;
      padding-left: 30px;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
    padding: 0;
  }

  .autocomplete-controll-dropdown-input {
    // handle legend
    @include ar {
      label {
        padding-inline: 3px;
        &.MuiInputLabel-shrink,
        &.Mui-focused,
        &.MuiFormLabel-filled {
          background-color: #ffffff;
        }
      }
      fieldset {
        legend {
          span {
            display: none;
          }
        }
      }
    }

    input {
      height: 40px;
      padding: 0px 14px !important;
      color: #000000de;
      // styling for placeholder
      &::placeholder {
        color: #00000099;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px !important;
        letter-spacing: 0.15px;
        opacity: 1;
      }
    }
    .MuiFormHelperText-root.Mui-error {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #f44336;
      @include ar() {
        text-align: right;
      }
    }
  }

  .MuiAutocomplete-endAdornment {
    .MuiButtonBase-root {
      cursor: pointer;
    }

    @include ar {
      left: 9px !important;
      right: auto;
    }

    .MuiAutocomplete-popupIndicatorOpen {
      @include ar {
        transform: rotatex(180deg) !important;
      }
    }
  }
  &.with-label {
    fieldset.MuiOutlinedInput-notchedOutline {
      legend {
        font-size: calc(14px * 0.75);
        font-weight: 400;
        letter-spacing: 0.15px;
        line-height: 24px;
      }
    }
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    @include ar {
      text-align: right;
    }
  }

  .autocomplete-select-controll-tags {
    padding: 0 14px;
    color: #000000de;
    pointer-events: none;
  }

  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl,
  .MuiOutlinedInput-root input,
  .autocomplete-select-tags {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: #00000099;

    &.Mui-error {
      color: #f44336;
    }

    &:not(.Mui-focused) {
      @include ar {
        right: 30px;
        left: auto;
      }
    }

    &.Mui-focused {
      @include ar {
        right: 0;
        left: auto;
      }
    }

    &.MuiInputLabel-shrink {
      @include ar {
        right: 17.5px !important;
        left: auto;
        transform: translate(5px, -9px) scale(0.75);
        transform-origin: top right;
      }
    }
  }
}

.autocomplete-dropdown-controll-paper-menu {
  .MuiAutocomplete-noOptions {
    color: #00000099;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .autocomplete-controll-dropdown-placeholder {
    display: flex;
    padding: 2px 11px;
    align-items: flex-start;
    align-self: stretch;
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.MuiAutocomplete-option.autocomplete-select-controll-option-item {
  padding: 6px 16px !important;
  gap: 8px;
  &.Mui-focused {
    background-color: #fff !important;
  }
  &:hover {
    background-color: #f5f5f5 !important;
  }
  .autocomplete-select-controll-option-title {
    display: flex;
    flex: 1 1 0;
    align-items: center;
    gap: 8px;
  }

  p {
    color: #000000de;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
  }

  span {
    font-size: 14px !important;
    line-height: 150%;
    letter-spacing: 0.15px;
    font-weight: 500;
  }
}

.autocomplete-dropdown-menu-listbox .MuiAutocomplete-option {
  padding: 6px 16px !important;
  gap: 8px;
  color: #000000de;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}
