@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.teams-multi-select-dropdown {
  margin-bottom: 24px;
}
.tabs-section-container {
  border-top: 1px solid #e2e8f0;
  margin-top: 20px;
  padding: 24px 0;
  padding-bottom: 0;
  margin-bottom: 24px;

  .tabs-section-header {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .tabs-section-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #475569;
      margin: 0;
    }

    .tabs-section-description {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #00000099;
      margin: 0;
    }
  }

  .user-tabs-container {
    margin-top: 16px;

    .user-tabs-header {
      border-bottom: 1px solid #f1f5f9;
      display: inline-flex;
      .user-tabs-item {
        padding: 11px 12px;
        border-bottom: 3px solid transparent;
        padding-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: #00000099;
        font-weight: 600;
        &.Mui-selected {
          color: #000000de;
          border-bottom: 3px solid #456eb2;
        }

        &.user-tabs-item-error {
          color: #f44336;
          border-bottom: 3px solid #f44336;
        }
      }
    }

    .user-tab-content-wrapper {
      margin-top: 16px;
    }

    .user-tabs-content-select-msg {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #000000de;

      &.auto-comp-empty {
        @include ar {
          margin-right: 14px;
        }

        @include en {
          margin-left: 14px;
        }
      }
    }

    .selectable-tab-content-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .selectable-tab-content-title {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        color: #00000099;
      }

      .selectable-tab-content-stack {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .selectable-tab-content-description {
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        color: #00000099;
        text-transform: uppercase;
      }

      .selectable-tab-content-days {
        display: flex;
        align-items: center;
        gap: 10px;

        &-item {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #e2e8f0;
          color: #64748b;
          border: 1px solid #e2e8f0;
          cursor: pointer;

          &-description {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            color: inherit;
          }

          &:hover {
            background: #e2e8f0;
            color: #456eb2;
            border: 1px solid #456eb2;
          }

          &.days-item-active {
            background: #456eb2;
            color: #fff;
            border: 1px solid #456eb2;
          }
          &.days-item-disabled {
            background: #dae2f0;
            color: #fff;
            border: none;
            cursor: not-allowed;
          }
          &.days-item-disabled-not-selected {
            background: #f0f0f0;
            color: #fff;
            border: none;
            cursor: not-allowed;
          }
        }
      }

      .selectable-tab-content-hours {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .selectable-tab-content-hours-empty {
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          color: #00000061;

          &:before {
            content: "•";
            color: #00000061;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;

            @include ar {
              margin-left: 5px;
              margin-right: 6px;
            }

            @include en {
              margin-left: 6px;
              margin-right: 5px;
            }
          }
        }

        &-row {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 506px;
          width: 100%;

          &-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #000000de;
            flex: 1;
          }

          &-inputs {
            display: flex;
            gap: 20px;

            .selectable-tab-content-hours-row-input.select-error {
              label.MuiFormLabel-root.MuiInputLabel-root {
                color: #f44336;
              }
            }

            .icon-tab-button {
              height: 32px !important;
              margin-top: 4px;
            }

            &-wrapper {
              display: flex;
              flex-direction: column;
              gap: 5px;
            }

            &-helper {
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.4px;
              color: #f44336;

              @include ar {
                padding-right: 12px;
              }

              @include en {
                padding-left: 12px;
              }
            }

            .user-tabs-select-dropdown {
              width: 116px;
              @include ar {
                label {
                  padding-inline: 3px;
                  &.Mui-focused,
                  &.MuiFormLabel-filled {
                    background-color: #ffffff;
                  }
                }
                fieldset {
                  legend {
                    span {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .icon-tab-button {
        height: 40px !important;
      }
    }

    .selectable-tab-content-row {
      display: flex;
      gap: 24px;

      &.multi-select-row {
        width: 50%;
      }

      .autocomplete-dropdown-section,
      .user-tabs-select-dropdown {
        width: 100%;

        .autocomplete-dropdown {
          width: 100%;
        }
      }
    }
  }
}

.user-tab-add-skill-popup {
  .MuiDialogContent-root {
    padding: 0;
    min-width: 500px;
  }

  .add-skill-popup-header {
    padding: 18px 20px;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-skill-popup-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      color: #0f172a;
    }

    .add-skill-popup-close {
      color: #0f172a;
      cursor: pointer;
      font-size: 18px;
    }
  }

  .add-skill-popup-content {
    padding: 18px 20px;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .MuiFormHelperText-root.Mui-error {
      color: #f44336 !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 20px;
      letter-spacing: 0.4px;
    }

    .add-skill-popup-desc-count {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
      display: flex;
      justify-content: flex-end;
      color: #00000099;
      margin-top: 4px;

      &.desc-error {
        color: #f44336;
      }

      span {
        color: #000000de;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
    }
  }

  .add-skill-popup-footer {
    padding: 18px 20px;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}

.user-tabs-selectable-tab-content-render-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .render-item-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000de;

    .render-item-sub-title {
      color: #00000099;

      @include ar {
        margin-right: 4px;
      }

      @include en {
        margin-left: 4px;
      }
    }
  }

  .render-item-icons {
    display: flex;
    align-items: center;
    gap: 9px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img,
    svg {
      width: 13.33px;
      height: auto;
    }
  }
}

.user-tabs-select-dropdown-item {
  padding: 6px 16px !important;
  gap: 8px;
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    width: 18px;
    height: auto;
  }

  span {
    color: #000000de;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

li.user-tabs-select-dropdown-empty-message {
  padding: 6px 16px !important;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #00000099;
  background-color: #fff !important;
  opacity: 1 !important;
  &.empty-loading {
    color: #00000099;
  }
}

.user-tabs-select-dropdown-list
  .MuiAutocomplete-option.autocomplete-select-option-item {
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    width: 18px;
    height: auto;
  }

  span {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.selectable-tab-content-hours-row-inputs-menu {
  ul > li > span.MuiTypography-root.MuiTypography-body1 {
    direction: ltr;
  }
}
.tab-skills-paper-box {
  .MuiAutocomplete-noOptions {
    padding: 8px 0;
  }
  .add-skill-tab-item-empty-case {
    .tab-add-new-skill-option {
      cursor: pointer;
      color: #456eb2;
      display: flex;
      align-items: center;
      gap: 4px;

      svg {
        font-size: 10px;
      }

      span.add-skill {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.15px;
      }
    }
    .empty-list-message {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #00000099;
      padding: 6px 16px;
    }
  }
}
.selectable-tab-content-hours-row-input {
  @include ar {
    label {
      &.MuiInputLabel-shrink,
      &.Mui-focused,
      &.MuiFormLabel-filled {
        margin-top: 2px;
      }
    }
  }
}

.MuiAutocomplete-listbox.autocomplete-dropdown-menu {
  .MuiAutocomplete-option {
    &.Mui-focused {
      background-color: #fff !important;
    }
    &:hover {
      background-color: #f5f5f5 !important;
    }
  }
}
