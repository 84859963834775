@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.aduience-widget-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  .audience-widget-image img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .audience-widget-name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    font-weight: 500;
  }
  .audience-widget-name {
    margin-inline-start: 20px;
  }
}
.audience-body {
  flex-wrap: wrap;
}
.interaction-list__footer.audience-body > * {
  margin-bottom: 2px;
}
.interaction-list__footer.audience-body > div {
  padding: 0 10px;
}
.main-audience {
  padding: 20px;
  .wedget-title {
    font-weight: 500;
    font-size: 14px;
    color: #334155;
    margin-inline-end: 8px;
  }
  .wedget-subtitle {
    font-weight: 400;
    font-size: 14px;
    color: #475569;
    &.themes {
      margin-inline-end: 6px;
    }
  }
  .widget-location {
    margin-inline-start: 5px;
  }
}

.audience-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}
