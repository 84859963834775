@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#custom-dashboard-view-widgets-container {
  margin-top: 80px;
  padding: 20px 60px;
  background-color: rgba(248, 250, 252, 1);
  .custom-dashboard-widget-grid-item {
    padding: 20px;
    *{
      letter-spacing: normal;
    }
  }
  .chart,
  .sentiment_chart,
  .sentiment-analysis-widget,
  .dialects-sub-dialects-auto-height {
    height: auto !important;
    
  }
  .chart{
    min-height: 415px ;
  }

}
#custom-dashboard-control-widget-sidebar {
  .sentiment_chart,
  .sentiment-analysis-widget,
  .dialects-sub-dialects-auto-height {
    height: auto !important;
  }
}

#empty-widgets-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  text-align: center;
  padding-top: 160px;
  .chart-no-data {
    margin-bottom: 10px;
  }
  .empty-data-search {
    padding: 0;
  }
}

#custom-dashboard-comparison-widget-tabs {
  margin: 0px 0px 20px 0px;
  .tab-button {
    border-bottom: 1px solid #f1f5f9;
    &.selected {
      border-bottom: 3px solid #456eb2;
    }
  }
}
