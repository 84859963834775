@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.audience-field {
  margin-bottom: 10px;
  input {
    height: 40px;
    padding: 0px 12px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
  }
  @include ar {
    .Mui-focused {
      padding: 0px;
    }
  }
  .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-style: dashed;
  }
}

.interaction-page-list .Mui-error {
  display: flex;
}

.audience-label {
  font-weight: 400;
  font-size: 14px;
  color: #0f172a;
  margin-bottom: 6px;
}

.interaction-page-list {
  .audience-main-title {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #1e293b;
    margin-bottom: 10px;
  }
}

.MuiOutlinedInput-root .MuiInputAdornment-root {
  .audience-btn-field {
    display: none;
  }
}
.Mui-focused .MuiInputAdornment-root {
  .audience-btn-field {
    display: block;
    margin-right: -14px !important;
  }
}

.audience-btn-field {
  &.MuiButton-root {
    font-size: 14px;
    font-weight: 500;
    color: #456EB2 ;
    border-radius: 0;
    line-height: 2 !important;
    svg {
      font-size: 10px;
      padding: 0px 5px;
    }
    @include en {
      border-left: 2px solid #456EB2 ;
      margin-left: 10px;
    }
    @include ar {
      border-right: 2px solid #456EB2 ;
    }
  }
}

.audience-field {
  .MuiOutlinedInput-root {
    &.Mui-error {
      .audience-btn-field {
        color: #f44336;
        @include en {
          border-left: 2px solid #f44336;
        }
        @include ar {
          border-right: 2px solid #f44336;
        }
      }
    }
  }
}

.audience-warning,
.audience-warning-msg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 14px;
  .MuiSvgIcon-root {
    margin-inline-end: 5px;
    font-size: 19px;
  }
}

.chip-style.editable {
  color: rgba(0, 0, 0, 0.38) !important;
  border-color: rgba(0, 0, 0, 0.38) !important;
  span {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}
