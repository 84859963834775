@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.select-wrapper {
  width: 100%;

  .MuiSelect-select.MuiSelect-outlined {
    padding: 9px 14px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}

.engagement-analytics-dropdown-container {
  width: 441px;
  .engagement-analytics-dropdown-label {
    font-size: 14px;
    color: #0f172a;
    margin-top: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 8px;

    svg {
      color: #bdbdbd;
      width: 15px;
      height: auto;
    }
  }

  &.section-box-data-keyword-setting {
    .engagement-analytics-dropdown-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #1e293b;
      margin: 0;
    }
  }

  .engagement-analytics-dropdown-wrapper {
    .MuiAutocomplete-endAdornment {
      .MuiButtonBase-root {
        pointer-events: none;
        cursor: pointer;
      }
    }

    &.default-wrapper {
      width: 100%;
    }
  }
}

div.engagement-analytics-dropdown-controll-wrapper {
  width: 100%;
  .engagement-analytics-dropdown-controll-select {
    width: 100%;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    @include ar {
      padding-right: 0px;
      padding-left: 30px;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
    padding: 0;
  }

  .engagement-analytics-controll-dropdown-input {
    background: #fff;
    // handle legend
    @include ar {
      label {
        padding-inline: 3px;
        &.MuiInputLabel-shrink,
        &.Mui-focused,
        &.MuiFormLabel-filled {
          background-color: #ffffff;
        }
      }
      fieldset {
        legend {
          span {
            display: none;
          }
        }
      }
    }

    input {
      height: 40px;
      padding: 0px 14px !important;
      color: #000000de;
      background-color: #ffffff;
      // styling for placeholder
      &::placeholder {
        color: #00000099;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px !important;
        letter-spacing: 0.15px;
        opacity: 1;
      }
    }
    .MuiFormHelperText-root.Mui-error {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #f44336;
      @include ar() {
        text-align: right;
      }
    }
  }

  .MuiAutocomplete-endAdornment {
    .MuiButtonBase-root {
      cursor: pointer;
    }

    @include ar {
      left: 9px !important;
      right: auto;
    }

    .MuiAutocomplete-popupIndicatorOpen {
      @include ar {
        transform: rotatex(180deg) !important;
      }
    }
  }
  &.with-label {
    fieldset.MuiOutlinedInput-notchedOutline {
      legend {
        font-size: calc(14px * 0.75);
        font-weight: 400;
        letter-spacing: 0.15px;
        line-height: 24px;
      }
    }
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    @include ar {
      text-align: right;
    }
  }

  .engagement-analytics-select-controll-tags {
    padding: 0 14px;
    color: #000000de;
    pointer-events: none;
  }

  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl,
  .MuiOutlinedInput-root input,
  .engagement-analytics-select-tags {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: #00000099;

    &.Mui-error {
      color: #f44336;
    }

    &:not(.Mui-focused) {
      @include ar {
        right: 30px;
        left: auto;
      }
    }

    &.Mui-focused {
      @include ar {
        right: 0;
        left: auto;
      }
    }

    &.MuiInputLabel-shrink {
      @include ar {
        right: 17.5px !important;
        left: auto;
        transform: translate(5px, -9px) scale(0.75);
        transform-origin: top right;
      }
    }
  }
}

.engagement-analytics-dropdown-controll-paper {
  .engagement-analytics-option-title {
    max-width: 344px;
    > p {
      @include ar {
        text-align: right;
      }
      &.arabic-name {
        direction: rtl;
        text-align: left;
        @include ar {
          text-align: right;
        }
      }
    }
  }
  padding: 8px 0;
  &.with-options {
    padding-bottom: 0;
    .MuiAutocomplete-noOptions {
      padding-bottom: 14px;
    }
  }
  .MuiAutocomplete-noOptions {
    padding: 6px 16px 6px 16px;
  }
  .engagement-analytics-option-apply {
    height: 56px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    border-top: 1px solid #e2e8f0;
    button {
      width: 100%;
    }
  }
  .engagement-analytics-dropdown-menu-listbox {
    li {
      padding: 6px 16px !important;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      height: 34px;
      box-sizing: border-box;
      &.Mui-focused {
        background-color: #fff !important;
      }
      &:hover {
        background-color: #f5f5f5 !important;
      }
      &[aria-selected="true"] {
        background-color: rgba(69, 110, 178, 0.12) !important;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #000000de;
      }
      span {
        font-size: 14px !important;
        line-height: 150%;
        letter-spacing: 0.15px;
        font-weight: 500;

        svg {
          width: 18px;
          height: auto;
        }
      }
    }
  }
}
