@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

p.empty-members-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #64748b;
  margin-bottom: 8px;
}

.team-information-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .team-information-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .team-information-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #475569;
  }
  .team-members-container-item {
    display: flex;
    flex-direction: column;
    .team-selectable-card {
      flex: 1;
    }
  }

  .team-information-form {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .select-with-shrink-label,
    .product-select,
    .team-name-input {
      width: 100%;
      label.MuiFormLabel-root.MuiInputLabel-root {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.15px;
        margin-bottom: 10px;
        color: rgba(0, 0, 0, 0.8705882353);
        @include ar {
          text-align: right;
        }
      }
      .MuiInputBase-input {
        padding: 8px 12px;
      }
    }

    label.MuiFormLabel-root.MuiInputLabel-root.input-user-settings-label {
      font-size: 14px;
    }
  }
}
div.team-selectable-card {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  box-shadow: none;

  &.selectable {
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 8px 0px #0000000d;
      .team-selectable-card-check-icon {
        visibility: visible;
      }
    }
  }

  &.checked {
    border: 1px solid #456eb2;
    box-shadow: 0px 4px 8px 0px #0000000d;
    .team-selectable-card-check-icon {
      visibility: visible;
      color: #456eb2;
    }
  }
  .team-selectable-card-check-icon {
    width: 20px;
    height: auto;
    color: #e2e8f0;
    visibility: hidden;
  }
  .team-selectable-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 16px;

    .member-card-info {
      display: flex;
      gap: 8px;
      align-items: center;

      .member-card-info-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1;
        .member-card-name {
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0px;
          color: #000000de;
        }

        .member-card-email {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0px;
          color: #00000099;
          @include ar {
            text-align: right;
            direction: ltr;
          }
        }

        .member-card-teams {
          display: flex;
          gap: 4px;
          flex-wrap: wrap;

          .member-card-team {
            padding: 4px 8px 4px 8px;
            border-radius: 4px;
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0px;
            color: #000000de;
            background: #f1f5f9;
            direction: ltr;
          }
        }
      }
    }

    .account-card-info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .account-card-info-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #1018281a;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .account-card-info-text {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .account-card-name {
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0px;
          color: #000000de;
        }

        .account-card-handle {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0px;
          color: #00000099;
        }
      }
    }
  }
  .monitor-card-info {
    .monitor-card-name {
      font-size: 12px;
      font-weight: 500;
      line-height: 14.4px;
      letter-spacing: 0px;
      color: #000000de;
      direction: ltr;
      display: inline-block;
    }

    .monitor-card-list {
      list-style-type: disc;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;

        &:before {
          content: "•";
          color: #00000099;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0px;
          @include ar {
            margin-left: 5px;
            margin-right: 6px;
          }
          @include en {
            margin-left: 6px;
            margin-right: 5px;
          }
        }
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 3px;
      }
    }

    .monitor-card-datasources {
      display: flex;
      align-items: center;
      gap: 9px;
      .social-media-icon {
        margin: 0;
        img {
          width: 13.33px;
          height: auto;
        }
        svg {
          width: 13.33px;
          height: auto;
        }
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .monitor-card-type {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0px;
      color: #00000099;

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color: #000000de;
      }
    }
  }
}

.team-show-more-footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-social-media-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .team-social-media-block {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .team-social-meida-header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .team-social-media-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #475569;
    }

    .team-social-media-description {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      color: #000000de;
    }
  }
}

.team-accounts-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .team-accounts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    .team-account-brand {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      .social-media-icon {
        margin: 0;
      }
      img {
        width: 13.33px;
        height: auto;
      }

      .team-accounts-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #000000de;
      }
    }

    .team-accounts-header-connector {
      flex: 1 1 auto;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);

      span {
        border-color: #e9e9e9;
        display: block;
        border-top-style: solid;
        border-top-width: 1px;
        width: 100%;
      }
    }
    .add-user-link {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      text-transform: capitalize;
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #3e63a0;
      }
      svg {
        path {
          fill: #3e63a0;
        }
      }
    }
  }
}

.team-members-section-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
