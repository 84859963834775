@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin flexbox(
  $display: flex,
  $direction: row,
  $placeContent: null,
  $placeItems: null,
  $wrap: nowrap,
  $shrink: 0,
  $grow: 0,
  $alignContent: null,
  $justifyContent: null,
  $alignItems: null,
  $justifyItems: null
) {
  display: $display;
  flex-direction: $direction;

  @if $placeContent == null {
    @if $alignContent {
      align-content: $alignContent;
    }
    @if $justifyContent {
      justify-content: $justifyContent;
    }
  } @else {
    place-content: $placeContent;
  }

  @if $placeItems == null {
    @if $alignItems {
      align-items: $alignItems;
    }
    @if $justifyItems {
      justify-items: $justifyItems;
    }
  } @else {
    place-items: $placeItems;
  }

  flex-wrap: $wrap;
  flex-shrink: $shrink;
  flex-grow: $grow;
}
/////////////////////////////////////////////
.dashboard-sidebar {
  @include ar {
    left: 0;
    width: 240px !important;
    border-left: 1px solid #e2e8f0;
  }
  @include en {
    left: auto;
    width: 230px !important;
    border-right: 1px solid #e2e8f0;
  }
}
.dashboard-sidebar div {
  border-right: none;
}
.sidebar-list {
  width: 98%;
  padding-bottom: 48px !important;
}
.dashboard-name {
  @include flexbox($placeItems: center);
  margin-bottom: 18px;
  span {
    color: #0f172a;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    width: 140px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .aggregate-dashboard-btn {
    background-color: #f1f5f9;
    width: 24px;
    height: 25px;
    padding-left: 2px;
    padding-bottom: 0.4px;
    @include flexbox($justifyContent: center);
    border-radius: 3px;
    margin-left: 20px;
    @include ar {
      margin-left: 0px;
      margin-right: 20px;
    }
  }
  .aggregate-btn {
    border: 1px solid #e2e8f0;
    background-color: #f1f5f9;
    width: 18px;
    height: 25px;
    padding: 8px 12px;
    @include flexbox($justifyContent: center);
    border-radius: 3px;
    @include en {
      margin-right: 8px;
    }
    @include ar {
      margin-left: 8px;
    }

    img {
      width: 20px;
    }
  }
  button {
    color: #64748b;
    padding: 0;
    min-height: 0;
    min-width: 0;
    @include flexbox($justifyContent: center);

    height: 24px;
    &:hover {
      background-color: transparent;
    }
  }
}
.dashboard-datasources-style {
  color: #696d72;
  font-size: 11px;
  font-weight: 500;
}

.datasources-heading {
  @include en {
    margin-right: 6px;
  }
  @include ar {
    margin-left: 6px;
  }
}

.dashboard-details {
  padding: 0px 0px;
  padding-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}
.dashboard-details + hr.dashboard-divider {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
}

.dashboard-divider {
  background: #cbd5e1;
  width: 100%;
}
.monitor_pages_head {
  @include flexbox($justifyContent: center, $alignItems: center);
}

.sidebar-titles {
  @include flexbox($direction: row, $alignItems: center);
  color: #64748b;
  text-transform: uppercase;
  margin-bottom: 20px;
  span {
    font-size: 12px;
    font-weight: 600;
    padding: 0px 10px;
    letter-spacing: 1px;
  }
  svg {
    font-size: 14px;
  }
}

.sidebar-tabs {
  @include flexbox($direction: column, $justifyContent: center);
  margin-bottom: 20px;
  border-left: 1px solid #e2e8f0;
  cursor: pointer;
  @include en {
    padding-left: 10px;
  }
  @include ar {
    border-left: none;
    border-right: 1px solid #e2e8f0;
    padding-right: 10px;
  }

  p {
    color: #475569;
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    height: 30px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin-bottom: -5px;
    &:hover {
      transition: all 0.5s ease;
      background-color: #f1f5f9;
    }
  }
}

.disable-sidebar-tabs {
  p {
    color: #737a84;
    &:hover {
      pointer-events: none;
    }
  }
}
.first-tab {
  margin-top: 0px;
}
.data-source-accordion {
  padding-top: 0px !important;
}

.icons-dashboard-page {
  margin-top: 8px;
  width: auto;
  height: 22px;
  display: inline-flex;
  @include en {
    padding-right: 5px;
  }
  @include ar {
    padding-left: 5px;
  }
  .twitter-icon {
    font-size: 14px;
    @include ar {
      margin-top: -3px;
    }
  }
  .instagram-icon {
    font-size: 16px;
    margin-top: -5px;
    @include ar {
      margin-right: -1px;
    }
    @include en {
      margin-left: -1px;
    }
  }
  .whatsapp-datasource-icon {
    font-size: 19px;
    margin-top: -18px;
    margin-right: 4px;
    margin-left: 0px;
  }

  #gmb-datasource-icon {
    top: -3px;
  }
  .gmail-datasource-icon {
    margin-top: 2px;
  }

  .intercom-datasource-icon {
    font-size: 18px;
    position: relative;
    top: 0px;
    @include en {
      margin-right: 5px;
    }
    @include ar {
      margin-left: 5px;
    }
  }

  .insta-social-icon {
    width: 22px !important;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .talkwalker-icon img {
    height: 16px;
    margin-top: -1px;
  }
}
