@import "../../../surveyBuilder.scss";

@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.survey-builder-question-card {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  box-shadow: none;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  @include popupSize("lg") {
    margin-top: 0px;
    padding: 20px;
    gap: 20px;
  }
  @include popupSize("md") {
    margin-top: 0px;
    padding: 16px;
    gap: 16px;
  }
  @include popupSize("sm") {
    margin-top: 0px;
    padding: 12px;
    gap: 12px;
  }

  &.selected-question {
    border: 2px solid var(--survey-primary-color);
  }
  &.error-question {
    border: 2px solid #e31b0c;
  }

  input {
    color: var(--survey-font-color);
  }

  .survey-builder-question-email-input,
  .survey-builder-question-free-text-input,
  .survey-builder-question-name-input,
  .survey-builder-question-number-input,
  .survey-builder-question-platform-input,
  .survey-builder-question-mcq-choice-input,
  .survey-builder-question-mcq-other-input,
  .survey-builder-question-dropdown-input {
    .MuiInput-underline:before {
      border-bottom-color: var(--survey-primary-color);
    }
    &:hover .MuiInput-underline:before {
      border-bottom-color: var(--survey-primary-color);
    }
    .MuiInput-underline:after {
      border-bottom-color: var(--survey-primary-color);
    }
  }
}

// ************************************************************************
// *************************** Questions Styles ***************************
// ************************************************************************

// ******************** FreeText, Email, Number Questions Styles ********************
.survey-builder-question-free-text,
.survey-builder-question-email,
.survey-builder-question-number {
  width: 100%;
  color: var(--survey-font-color);
  .survey-builder-question-free-text-input,
  .survey-builder-question-email-input,
  .survey-builder-question-number-input {
    color: var(--survey-font-color);
    width: 50%;
    @include popupSize("all") {
      width: 100%;
    }
    @include popupSize("lg") {
      font-size: 18px;
    }
    @include popupSize("md") {
      font-size: 16px;
    }
    @include popupSize("sm") {
      font-size: 14px;
    }
  }
}

// ******************** Name Question Styles ********************
.survey-builder-question-name {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @include popupSize("all") {
    width: 100%;
  }
  .survey-builder-question-name-input {
    flex-grow: 1;
    color: var(--survey-font-color);
  }
  input {
    @include popupSize("lg") {
      font-size: 18px;
    }
    @include popupSize("md") {
      font-size: 16px;
    }
    @include popupSize("sm") {
      font-size: 14px;
    }
  }
}

// ******************** NPS, Scale Question Styles ********************
.survey-builder-question-nps-scale {
  width: 100%;
  overflow-x: auto;
  .question-nps-scale-items-container {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nps-scale-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      color: var(--survey-primary-color);
      border: 2px solid var(--survey-primary-color);
      border-radius: 50%;
      cursor: pointer;
      @include popupSize("lg") {
        font-size: 18px;
        width: 36px;
        height: 36px;
      }
      @include popupSize("md") {
        font-size: 16px;
        width: 32px;
        height: 32px;
      }
      @include popupSize("sm") {
        font-size: 14px;
        width: 30px;
        height: 30px;
      }

      &:hover {
        background-color: rgba(69, 110, 178, 0.08);
      }
      &.selected-nps-scale-item {
        background-color: var(--survey-primary-color);
        color: #ffffff;
      }
      &.red {
        color: #f44336;
        border: 2px solid #f44336;
        &:hover {
          color: #f44336;
          border: 2px solid #f44336;
          background-color: rgba(244, 67, 54, 0.08);
        }
        &.selected-nps-scale-item {
          background-color: #f44336;
          color: #ffffff;
        }
      }
      &.yellow {
        color: #ffb400;
        border: 2px solid #ffb400;
        &:hover {
          color: #ffb400;
          border: 2px solid #ffb400;
          background-color: rgba(255, 180, 0, 0.08);
        }
        &.selected-nps-scale-item {
          background-color: #ffb400;
          color: #ffffff;
        }
      }
      &.green {
        color: #4caf50;
        border: 2px solid #4caf50;
        &:hover {
          color: #4caf50;
          border: 2px solid #4caf50;
          background-color: rgba(76, 175, 80, 0.08);
        }
        &.selected-nps-scale-item {
          background-color: #4caf50;
          color: #ffffff;
        }
      }
    }
  }
  .question-nps-scale-items-text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    .nps-scale-item-text {
      color: var(--survey-font-color);
      @include popupSize("lg") {
        font-size: 18px;
      }
      @include popupSize("md") {
        font-size: 16px;
      }
      @include popupSize("sm") {
        font-size: 14px;
      }
    }
  }
}

// ******************** Phone Number Question Styles ********************
.survey-builder-question-phone-number {
  width: 50%;
  margin: 0;
  @include popupSize("all") {
    width: 100%;
  }
  input {
    color: var(--survey-font-color);
    @include popupSize("lg") {
      font-size: 18px;
    }
    @include popupSize("md") {
      font-size: 16px;
    }
    @include popupSize("sm") {
      font-size: 14px;
    }
  }

  input.form-control.react-tel-input.phoneInput,
  .selected-flag {
    border-color: var(--survey-primary-color) !important;
    &:hover,
    &:focus {
      border-bottom: 2px solid var(--survey-primary-color) !important;
    }
  }

  &.phone-number-question.editor-question .separate-dial-code.intl-tel-input {
    input.form-control.react-tel-input.phoneInput {
      padding-left: 10px;
      color: var(--survey-font-color);
    }
  }
  .selected-dial-code {
    color: var(--survey-font-color);
  }
}

// ******************** Yes/No Question Styles ********************
.survey-builder-question-yes-no {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  .answer-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .yes-no-answer {
      font-family: var(--embedded-popup-font);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      color: var(--survey-font-color);
      border: 1px solid var(--survey-primary-color);
      border-radius: 4px;
      height: auto;
      min-width: 200px;
      padding: 10px;
      @include popupSize("lg") {
        font-size: 18px;
      }
      @include popupSize("md") {
        font-size: 16px;
      }
      @include popupSize("sm") {
        font-size: 14px;
      }

      &.preview {
        border: none;
      }
      &.selected {
        color: var(--survey-primary-color);
      }
    }
  }
  .proceed-select-container {
    flex-grow: 1;
    overflow: hidden;
    direction: ltr;
    @include ar {
      direction: rtl;
    }
  }
}

// ******************** Social Media Question Styles ********************
.survey-builder-social-media-question {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  .platform-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    width: 100%;
    .platform-input-container {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding: 15px;
      width: 70%;
      @include popupSize("all") {
        width: 100%;
      }
      &.preview {
        padding: 0;
        border: none;
      }
      &.platform-added {
        border: 1px dashed #a1aeab;
        border-radius: 4px;
      }
      img.platform-logo {
        width: 34px;
        height: 34px;
        @include popupSize("lg") {
          width: 24px;
          height: 24px;
        }
        @include popupSize("md") {
          width: 22px;
          height: 22px;
        }
        @include popupSize("sm") {
          width: 20px;
          height: 20px;
        }
      }
      .survey-builder-question-platform-input {
        flex-grow: 1;
        input {
          color: var(--survey-font-color);
          @include popupSize("lg") {
            font-size: 18px;
          }
          @include popupSize("md") {
            font-size: 16px;
          }
          @include popupSize("sm") {
            font-size: 14px;
          }
        }
      }
      .remove-platform-icon {
        position: absolute;
        font-size: 20px;
        top: -10px;
        right: -10px;
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  button#survey-builder-question-platform-add-btn.added-btn {
    opacity: 1;
    color: #4caf50;
  }
}

// ******************** Dropdown Question Styles ********************
.survey-builder-question-dropdown {
  width: 100%;
  .survey-builder-question-dropdown-preview-select-form-control {
    width: 50%;
    font-family: var(--embedded-popup-font);
    color: var(--survey-font-color);
    legend {
      @include ar {
        text-align: right;
      }
    }

    &:hover,
    &:active,
    &:focus-visible,
    &:focus-within,
    &:focus,
    .Mui-focused {
      fieldset {
        border-color: var(--survey-primary-color);
      }
    }
  }
  label#survey-builder-question-dropdown-preview-select-label {
    font-family: var(--embedded-popup-font);
    color: var(--survey-font-color);
    @include ar {
      right: 30px;
      left: auto;
      text-align: right;
    }
    &.MuiInputLabel-shrink {
      @include ar {
        left: auto;
        right: 22px;
        text-align: right;
      }
    }
  }
  div#survey-builder-question-dropdown-preview-select-label {
    font-family: var(--embedded-popup-font);
    color: var(--survey-font-color);
    @include ar {
      text-align: right;
    }
  }

  .editor-options-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .answer-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      width: 100%;
      .choice-textfield {
        width: 100%;
        &.proceed-select-container {
          width: 50%;
          min-width: 50%;
        }

        input {
          color: var(--survey-font-color);
          @include popupSize("lg") {
            font-size: 18px;
          }
          @include popupSize("md") {
            font-size: 16px;
          }
          @include popupSize("sm") {
            font-size: 14px;
          }
        }
      }
      .proceed-select-container {
        flex-grow: 1;
        width: 50%;
        min-width: 50%;
        overflow: hidden;
        direction: ltr;
        @include ar {
          direction: rtl;
        }
      }
    }
    .add-option-btn-wrapper {
      width: 100%;
      button {
        color: var(--survey-primary-color);
      }
    }
  }
}

#survey-builder-question-dropdown-preview-select-item {
  font-family: var(--embedded-popup-font);
  color: var(--survey-font-color);
}

// ******************** MCQ Question Styles ********************
.survey-builder-mcq-question {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  .preview-choice-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    label {
      color: var(--survey-font-color);
      span span {
        color: var(--survey-primary-color);
      }
    }
    .radio-btn-box {
      display: flex;
      align-items: center;
      gap: 5px;
      label span {
        margin: 0;
      }
    }
    .other-choice-text-field-wrapper {
      width: 100%;
      .other-choice-text-field {
        width: 50%;
      }
    }
  }

  .editor-choices-wrapper {
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    .choice-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      width: 100%;
      svg {
        inset-inline-end: 0;
        cursor: pointer;
      }
      svg.svg-inline--fa {
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
      }
      &:hover {
        svg.svg-inline--fa {
          visibility: visible;
          opacity: 1;
        }
      }
      .choice-answer-wrapper {
        min-width: 50%;
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        input {
          @include popupSize("lg") {
            font-size: 18px;
          }
          @include popupSize("md") {
            font-size: 16px;
          }
          @include popupSize("sm") {
            font-size: 14px;
          }
        }
      }

      .proceed-select-container {
        flex-grow: 1;
        width: 50%;
        overflow: hidden;
        direction: ltr;
        @include ar {
          direction: rtl;
        }
      }
    }
    .other-choice-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
    }
  }

  .add-choice-btn-wrapper {
    width: 100%;
    button {
      color: var(--survey-primary-color);
    }
  }
}

.section-direction-rtl {
  .survey-builder-question-phone-number {
    input {
      text-align: right;
    }
    &.phone-number-question.editor-question .separate-dial-code.intl-tel-input {
      @include ar {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
