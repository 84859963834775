@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.luci-inactive-header {
  padding: 30px 40px;
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}
.luci-inactive-ques-wrapper {
  margin-bottom: 30px;
}
