.hashtags-title-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  
}
.hashtags-limit-wrapper{
  padding: 14px 16px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid #FCEAC5;
  background-color: #fdf0e5;
  .limit-text-wrapper{
    flex-grow: 1;
    margin: 0;
  }
  .limit-text{
    color: #5f2b01;
    font-size: 14px;
    font-weight: 400;
    line-height:  20px;
    max-width: 690px;
    display: inline-block;
    a{
      font-weight: 500;
      text-decoration: underline;
    }
  }
}
.luc-Button.MuiButton-outlinedPrimary.limit-btn{
  border-color: #ED6C02 ;
  color: #ED6C02;
  &:hover{
    color:white;
    background-color: #ED6C02;
  }
}
.statistics-wrapper{
  display: flex;
  gap: 16px;
  margin: 24px 0;
}
.statistic-box{
  width: calc(100%/6);
  border-radius: 4px;
  padding: 15px 20px;
  border: 1px solid #E2E8F0;
  background: #FFFFFF;
  .statistic-box-num{
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #1A174F;
  }
  .statistic-box-name{
    margin: 0;
    color: #475569;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    span{
      display: block;
    }
  }
  .active-hashtags-statistic-num{
    color: #4CAF50;
  }
  .inactive-hashtags-statistic-num{
    color: #ED1846;
  }
  .registering-hashtags-statistic-num{
    color: #ED6C02;
  }
  .de-registering-hashtags-statistic-num{
    color: #80868C;
  }
}