@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.publish-sidebar-container {
  width: 264px;
  height: 100%;
  border: 1px solid #e2e8f0;
  flex-shrink: 0;
  .accounts-sections-container {
    padding: 22px 20px 0px 20px;
    .accounts-publish-title {
      font-weight: 500;
      font-size: 16px;
    }
    .accounts-sections-content {
      margin-top: 10px;
      .accounts-publish-avatar {
        width: 20px;
        height: 20px;
        border-radius: 16px;
        position: relative;
        top: 6px;
        border: 0.55px solid #cbd5e1;
      }
      .account-publish-name {
        font-weight: 500;
        font-size: 14px;
      }
      .accounts-datasource-icon {
        width: 13px;
        height: 13px;
        position: relative;
        @include en {
          top: 10px;
          left: -10px;
        }
        @include ar {
          top: 9px;
          right: -10px;
        }
      }
      .MuiFormControlLabel-root {
        @include en {
          margin-right: 2px;
        }
        @include ar {
          margin-left: 0;
          margin-right: -10px;
        }
      }
      .publish-verify-icon {
        margin-inline: 4px;
        margin-bottom: -2px;
      }
    }
  }
  .publish-manage-accounts-container {
    text-align: center;
    .publish-manage-accounts {
      margin: 10px auto 0;
      color: #456eb2;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 15px 10px 14px;
      display: block;
      max-width: 139px;
      background: #fff;
      &:hover {
        background: #e6f3fc80;
        border-radius: 2px;
        cursor: pointer;
      }
    }
  }
  .refresh-setting-container {
    margin-inline: -10px;
    gap: 0;
  }
  .empty-accounts-container {
    text-align: center;
    margin-top: 60px;
    .accounts-publish-desription {
      font-size: 14px;
      font-weight: 400;
      padding: 24px 45px 10px 45px;
      color: #00000099;
    }
  }

  .empty-manage-accounts-content.MuiBox-root {
    text-align: center;
    padding: 0 !important;
    margin-top: -28px;
  }
  .publish-sidebar-main-content {
    height: calc(100% - 72px);
    overflow-y: scroll;

    &::-webkit-scrollbar-thumb {
      display: none;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
      box-shadow: none;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        display: block;
      }
    }
    .publish-sidebar-divider {
      padding: 15px 20px 0px 20px;
      margin: 30px 15px 2px 15px;
      border-color: #e2e8f0 !important;
    }
    .publish-sidebar-divider-empty {
      padding: 15px 20px 0px 20px;
      margin: 37px 15px 2px 15px !important;
      border-color: #e2e8f0 !important;
    }
    .upcoming-publish-title {
      color: #00000061;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 15px;
    }
    .upcoming-publish-container {
      margin-top: 25px;
    }
  }
  .upcoming-publish-container {
    .upcomming-accounts-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .upcoming-datasource-accounts,
    .upcoming-schedule-content {
      display: flex;
      align-items: center;
    }
    .accounts-datasource-icon {
      width: 20px;
      height: 20px;
    }
    .upcoming-accounts-title,
    .upcoming-accounts-time {
      font-weight: 500;
      font-size: 14px;
      color: #000000de;
    }
    .upcoming-accounts-time {
      @include ar {
        direction: ltr;
      }
    }
    .upcoming-accounts-title {
      margin-inline: 8px;
    }
    .upcomming-accounts-content {
      margin-bottom: 8px;
      padding: 6px 3px 6px 3px;
      border-radius: 6px;
      margin-inline: -2px;
      &:hover {
        background: #0000000a;
        cursor: pointer;
      }
    }
    .upcoming-schedule-icon {
      font-size: 18px;
      color: #ed6c02;
    }
  }
  .empty-upcoming-container {
    text-align: center;
    margin-top: 50px;
    .upcoming-publish-empty-title {
      font-size: 16px;
      font-weight: 500;
      padding: 15px 50px 5px 40px;
      color: #000000de;
      width: 145px;
      margin: auto;
    }
    .upcoming-publish-empty-desc {
      color: #00000099;
      font-size: 14px;
      font-weight: 400;
      padding: 0px 45px 10px 35px;
    }
  }
}
.tooltip-account-content {
  padding: 7px;
  .tooltip-account-content-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .content-account-list {
    font-size: 12px;
    font-weight: 400;
  }
}
#upcoming-tooltip-section {
  .MuiTooltip-arrow {
    @include ar {
      right: 1px !important;
    }
  }
}

.accounts-sections-container-loading {
  height: 42%;
}
