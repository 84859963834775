
.header-Buttons {
  background-color: #fff;
  border: 1px solid #cbd5e1;
  text-align: center;
  padding: 14px 20px;
}

.guidelines-Buttons {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  margin: auto 1rem;
}
