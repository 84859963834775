@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin text-ellipsis($lines: 1) {
  text-overflow: ellipsis;
  overflow: hidden;
  @if ($lines > 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  } @else {
    white-space: nowrap;
  }
}

.ai-agents-content-container {
  width: calc(100% - 329px);
  overflow: auto;
}

.ai-agents-content-empty-wrapper {
  min-height: calc(100% - 40px);
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;

  .ai-agents-content-empty {
    max-width: 483px;
    text-align: center;
  }

  .ai-agents-empty-heading {
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    color: #1e293b;
    margin: 16px 0 20px;
  }

  .ai-agents-empty-description {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: #475569;
    margin: 0;
  }
}

.ai-agents-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ai-agents-form-header {
  padding: 9px 20px;
  gap: 14px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;

  .ai-agents-header-name {
    font-size: 16px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
  }

  .form-header-action {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    margin-right: -11px;
  }

  .MuiDivider-root {
    width: 1px;
    height: 19px;
    background-color: #94a3b8;
  }
}
.luc-Button.ai-agents-publish-btn {
  background-color: #4caf50;
  gap: 8px;
  &:hover {
    background-color: #4caf50;
  }
  &.Mui-disabled {
    background-color: #4caf50;
  }
}

.ai-agents-form-content {
  flex-grow: 1;
  overflow: auto;
  padding: 30px;
}

.btn-ai-agent-action {
  .MuiButtonBase-root.MuiIconButton-root {
    border-radius: 2px;
  }
}

.menu-ai-agent {
  .MuiPaper-root {
    min-width: 205px;
  }

  li {
    padding: 10px 20px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);

    svg {
      font-size: 14px;
      padding: 0 !important;
      margin: 0;
      margin-inline-end: 10px;
    }
  }

  .drop-down-btn-ai-agents-delete {
    color: #e31b0c;
  }

  .drop-down-btn-ai-agents-id {
    border-top: 1px solid #e2e8f0;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: auto;

    .ai-agents-id-content {
      flex-grow: 1;
      color: #456eb2;
    }

    .ai-agents-id-icon {
      width: 25px;
      height: 25px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        margin: 0;
        width: 15px;
        height: 15px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.ai-agents-tooltip-title {
  font-size: 12px;
  line-height: 22px;
  padding: 0 2px;
  display: block;
}

.ai-agents-id-tooltip-title {
  font-size: 12px;
  line-height: 22px;
  padding: 0 2px;
  display: block;
}

.ai-agents-tooltip-description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: block;
  max-width: 172px;
  padding: 7px 2px 11px;
}

.ai-agent-popupModal {
  width: 594px;
  max-height: calc(100vh - 120px);

  .ai-agent-modal-hint {
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;

    span {
      font-weight: 600;
      display: block;
    }
  }

  .routing-select-team-and-slas {
    margin-bottom: 24px;
  }

  .routing-select-team-and-slas-title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  .team-and-slas-section {
    margin: 24px 0;

    .routing-auto-assign-member-cards {
      gap: 20px;
    }
  }

  .ai-agents-form-group {
    margin: 24px 0;
  }

  .ai-agent-modal-hint.ai-agent-resume-modal-hint {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.ai-agent-paused-hint-box {
  padding: 13px 16px;
  display: flex;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid #d5f0fc;
  background-color: rgba(33, 150, 243, 0.13);

  svg {
    color: #2196f3;
    font-size: 18px;
    transform: rotate(180deg);
  }

  .ai-agent-modal-hint {
    max-width: 400px;
    font-size: 14px;
    line-height: 20px;
  }
}

.ai-agents-form-box-wrapper {
  margin-bottom: 40px;

  .ai-agents-form-box-title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 15px;
  }
}

.ai-agents-form-box {
  padding: 40px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 0px #0000000d;

  &.ai-agent-resources-wrapper {
    display: flex;
    flex-direction: column;
  }

  .ai-agents-form-box-description {
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    max-width: 299px;
  }

  .use-luci-fill-btn {
    margin-bottom: 40px;
    display: inline-block;
  }

  .routing-select-team-and-slas {
    margin-bottom: 20px;
  }

  .routing-select-team-and-slas-title {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  .team-algorithm-group {
    margin: 20px 0;
  }
}

.ai-use-luci-tooltip {
  max-width: 182px;
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  padding: 9px 5px;
}

.ai-agents-form-group {
  margin-bottom: 20px;
}

.ai-agents-form-label.MuiTypography-root {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  &.text-underlined {
    border-bottom: 1px solid rgba(148, 163, 184, 1);
    max-width: fit-content;
    border-bottom-style: dashed;
  }
}

.ai-agents-form-hint-label {
  color: rgba(0, 0, 0, 0.6);
  margin-inline-start: 4px;
}

.ai-agents-form-input {
  width: 100%;

  .MuiInputBase-root {
    font-size: 14px;
  }

  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
}

.ai-agents-form-textarea {
  width: 100%;

  .MuiInputBase-root {
    font-size: 14px;
  }

  .MuiInputBase-inputMultiline {
    min-height: 107px;
  }
}

.ai-agents-form-radio {
  .MuiFormControlLabel-label {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  .MuiRadio-root {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.ai-agents-form-switch {
  .MuiFormControlLabel-label {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
  }
}

.ai-agents-form-radio-horizontal.MuiRadioGroup-root {
  flex-direction: row;
  gap: 20px;
}

.ai-agents-form-fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .ai-agents-form-group {
    margin-bottom: 20px;
    width: calc(50% - 10px);
  }
}

.ai-agents-form-divider.MuiDivider-root {
  margin: 40px 0 60px;
}

.ai-agents-form-box-container {
  margin-bottom: 40px;
}

.timeline-connector.MuiTimelineConnector-root {
  background-color: transparent;
  height: 14px;

  &.not-first {
    background-color: #e2e8f0;
  }
}

.timeline-dot.MuiTimelineDot-root {
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ai-agent-timeline-connector-long.MuiTimelineConnector-root {
  height: 40px;
  background-color: transparent;

  &.not-last {
    background-color: #e2e8f0;
  }
}

.ai-agent-timeline-content.MuiTypography-root {
  padding: 0;
  padding-inline-start: 16px;
}

.ai-agent-typography-title.MuiTypography-root {
  margin-top: 11px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  text-align: start;
}

.ai-agent-typography-info.MuiTypography-root {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.ai-agent-typography-info-direction.MuiTypography-root {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  direction: ltr;
}

.ai-agent-drawer-box {
  width: 393px;
}

.ai-agent-drawer-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 60px;
  margin-bottom: 20px;
  background-color: #fff;
  z-index: 100;
  padding: 15px 24px;
  border-bottom: 1px solid #e2e8f0;
}

.ai-agent-drawer-drawer-header-typography.MuiTypography-root {
  color: black;
  font-weight: 700;
  font-size: 16px;
}

.ai-agent-drawer-exit {
  font-size: 16px;
  color: #757575;
}

.ai-agent-drawer-drawer-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  padding-top: 84px;
}

.ai-agent-drawer-divider-stack {
  width: 100%;
  margin: 8px 0 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.ai-agent-drawer-divider {
  width: 100%;

  &::before,
  &::after {
    border-color: #e2e8f0;
  }
}

.ai-agent-drawer-divider-chip.MuiChip-root {
  padding: 5px 0;
  background-color: #f1f5f9;
  font-weight: 400;
  font-size: 14px;
  height: 27px;
  line-height: 16px;
}

.ai-agent-drawer-timeline.MuiTimeline-root {
  padding: 0;
  margin: -20px 0 0;

  & .MuiTimelineItem-root:before {
    flex: 0;
    padding: 0;
  }
}

// from here start
.ai-agent-chat-modal {
  position: relative;
  padding: 20px;
}

.ai-agent-chat-header {
  position: sticky;
  background-color: white;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  padding-top: 19px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 11;
}

.ai-agent-chat-header-stack {
  background-color: white;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.ai-agent-chat-header-title.MuiTypography-root {
  font-size: 20px;
  font-weight: 500;
}

.ai-agent-chat-loading-container {
  display: flex;
  height: calc(100% - 70px);
  justify-content: center;
  align-items: center;
}

.ai-agent-chat-relative-box {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ai-agent-chat-full-width {
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: white;
}

.ai-agent-chat-textfield-container {
  padding: 20px;
}

.ai-agent-chat-position-relative {
  min-height: 25px;
  position: relative;
  border: 1px solid #cbd5e1;
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 15px 60px;

  &:focus-within {
    border-color: #64748b;
  }
}

.ai-agent-chat-textfield {
  .MuiOutlinedInput-notchedOutline {
    border: unset;
  }

  .MuiInputBase-root {
    padding: 0;
  }

  .MuiInputBase-input {
    font-size: 14px;
    min-height: 20px;
  }
}

.ai-agent-chat-action-row {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 20px;
  width: calc(100% - 35px);
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ai-agent-chat-action-row-stack {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.ai-agent-chat-action-row-typography.MuiTypography-root {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-weight: 400;
}

.ai-agent-chat-send-button {
  width: 77px;
}

.ai-agent-chat-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 517px;
  background-color: white;
  box-shadow: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  outline: 0;
  padding: 0;
}

.ai-agent-chat-message-stack {
  margin-bottom: 20px;
  align-items: center;

  @include ar {
    direction: rtl;
  }

  @include en {
    direction: ltr;
  }
}

.ai-agent-chat-message-stack-sent {
  align-self: flex-end;

  .ai-agent-chat-message-typography {
    text-align: end;
  }

  .ai-agent-chat-message-bubble {
    background-color: #e9f2fb;
    border-start-end-radius: 0;
    word-break: break-word;
  }
}

.ai-agent-chat-message-stack-received {
  align-self: flex-start;

  .ai-agent-chat-message-bubble {
    background-color: #f6f8fa;
    border-start-start-radius: 0;
  }
}

.ai-agent-chat-message-avatar.MuiAvatar-root {
  width: 34px;
  height: 34px;
  align-self: self-start;
}

.ai-agent-chat-message-placeholder {
  max-width: 34px;
  min-width: 34px;
  max-height: 34px;
  min-height: 34px;
  align-self: self-start;
  border-radius: 50%;
  background-color: transparent;
}

.ai-agent-chat-message-box {
  margin-inline-start: 14px;
  margin-inline-end: 14px;
  max-width: 83%;
}

.ai-agent-chat-message-typography.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}

.ai-agent-chat-message-bubble {
  padding: 15px;
  word-wrap: break-word;
  display: inline-block;
  border-radius: 10px;
}

.ai-agent-chat-message-text.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  display: inline;

  &.arabic-message {
    direction: rtl;
  }

  &.english-message {
    direction: ltr;
  }
}

.ai-agent-chat-content-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  padding-bottom: 0;
  box-sizing: border-box;
  flex: 1;
}

.ai-agent-chat-loading-stack {
  align-items: center;
}

.ai-agent-chat-loading-text-center.MuiTypography-root {
  text-align: center;
  font-size: 16px;
}

.ai-agent-chat-loading-text-bold.MuiTypography-root {
  margin-top: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}

.ai-agent-chat-loading-text-normal.MuiTypography-root {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.ai-agent-chat-thinking-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.ai-agent-chat-thinking-container {
  padding: 3.75px, 5.25px, 4.5px, 5.25px;
  border-radius: 30.88px;
}

.ai-agent-first-message {
  margin-top: 20px;

  &:first-of-type {
    margin-top: 0;
  }
}

.ai-agent-chat-message-wrapper {
  margin-inline-start: 14px;
  margin-inline-end: 14px;
  max-width: 83%;
}

.ai-agent-chat-header-info-icon {
  color: #64748b;
}

.ai-agent-chat-send-icon {
  width: 15px;
  height: 13px;
  color: white;
}

.ai-agent-chat-send-button {
  .ai-agent-chat-send-icon {
    @include ar {
      transform: rotate(180deg);
    }
  }
}

.ai-agent-form-switch-label.MuiTypography-root {
  font-weight: 400;
  font-size: 14px;
}

.ai-agents-form-hidden-divider {
  margin: 40px 0 60px;
}

.ai-agents-form-chip.MuiChip-root {
  height: 22px;
  padding: 2px 3px;
  background-color: #c77700;
  color: #ffffff;
  margin-inline-start: 4px;
  font-size: 14px;

  .MuiChip-label {
    padding-left: 7px;
    padding-right: 7px;
  }
}

.ai-agents-form-upload {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23BEBFC2FF' stroke-width='2' stroke-dasharray='10%2c 14' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  padding: 12px;
  background-color: #f8fafc;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ai-agents-form-upload-avatar.MuiAvatar-root {
  width: 56px;
  height: 56px;
}

.ai-agents-form-upload-button.MuiButtonBase-root {
  text-transform: none;
}

.ai-agents-form-upload-another.MuiButtonBase-root {
  border: 1px dashed #bebfc2;
  border-radius: 4px;
  padding: 12px;
  background-color: #f1f5f9;
  text-transform: none;
}

.ai-agents-form-upload-stack {
  display: flex;
  align-items: center;
}

.ai-agents-form-upload-stack-gap {
  gap: 15px;
}

.ai-agents-form-radio-stack {
  display: flex;
  align-items: center;
}

.ai-agents-form-avatar-name-row {
  display: flex;
  align-items: center;
  gap: 15px;
}

.ai-agents-form-switch-chip-row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ai-agent-form-radio-button {
  .MuiFormControlLabel-asterisk {
    color: red;
  }
}

.ai-agents-end-conversation-label.MuiFormLabel-root {
  font-size: 14px;
  font-weight: 400;
  color: #000000de;

  & .MuiInputLabel-asterisk {
    color: #e31b0c;
  }
}

.ai-agents-end-conversation-form-box-description.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  color: #00000061;
  margin: 0;
}

.ai-agent-end-conversation-spacer {
  margin-bottom: 40px;
}

.ai-agent-published-drawer-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 60px;
  margin-bottom: 20px;
  background-color: #fff;
  z-index: 100;
  padding: 15px 24px;
  border-bottom: 1px solid #e2e8f0;
}

.ai-agent-published-drawer-drawer-header-typography.MuiTypography-root {
  color: #000000de;
  font-weight: 700;
  font-size: 16px;
}

.ai-agent-published-drawer-drawer-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 61px;
  padding: 24px;
}

.ai-agent-version-typography-info.MuiTypography-root {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4caf50;
  margin: 0;
  padding: 0;
}

.ai-agent-version-date-typography-info.MuiTypography-root {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000de;
  margin: 0;
  padding: 0;
  direction: ltr;
}

.ai-agent-version-changed-by-typography-info.MuiTypography-root {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: #00000099;
  margin: 0;
  padding: 0;
}

.ai-agent-published-divider.MuiDivider-root {
  margin: 36px 0;
  color: red;
}

.ai-agent-published-version-item-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ai-agent-version-info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ai-agent-published-version-icon-info-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.ai-agent-version-changed-by-avatar-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ai-agent-published-version-drawer-exit {
  font-size: 16px;
  color: #757575;
}

.knowledge-base-table {
  box-shadow: 0px 4px 8px 0px #00000014 !important;

  .name-text-ar {
    display: flex;
    flex-direction: row-reverse;
  }

  .name-text {
    display: flex;
    align-items: center;

    @include ar {
      flex-direction: row-reverse;
    }
  }

  table.MuiTable-root thead {
    border-bottom: 1px solid #d4d8db;

    .MuiTableRow-head {
      background-color: #f1f5f9;
    }
  }

  thead {
    tr {
      height: 56px;

      th {
        font-size: 16px;
        color: #475569;
      }
    }
  }

  tr {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);

    &:nth-of-type(even) {
      background: #f8fafc;
    }

    &:nth-of-type(odd) {
      background: #fff;
    }

    td {
      padding: 10px 16px;
      color: #000;
      font-size: 14px;

      &.monitor-name,
      &.manage-account-name {
        font-weight: 600;
        color: #456eb2 !important;

        .monitor-text {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            .MuiBox-root {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.empty-knowledge-base-box {
  .chart-no-data {
    .empty-data-search {
      .no-data-date {
        h3 {
          font-weight: 600 !important;
          font-size: 16px !important;
          line-height: 22px;
        }

        .empty-data-box {
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
}

.url-knowledge-base-hint {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.url-knowledge-base-hint-box-wrapper {
  background-color: #d5f0fc;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid rgba(213, 240, 252, 1);

  svg {
    color: #2196f3;
    font-size: 18px;
  }
}

.url-knowledge-base-body-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ai-agent-reset-margin {
  margin: 0px !important;
}

.add-knowledge-base-note-row {
  .add-knowledge-base-note-title.MuiTypography-root,
  .add-knowledge-base-note-body.MuiTypography-root {
    line-height: 24px;
    letter-spacing: 0.15px;
    font-size: 14px;
    color: #000000de;
  }

  .add-knowledge-base-note-title.MuiTypography-root {
    display: inline;
    font-weight: 600;
  }

  .add-knowledge-base-note-body.MuiTypography-root {
    display: inline;
    font-weight: 400;
  }
}

.add-knowledge-base-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-knowledge-base-description.MuiTypography-root {
  max-width: 299px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.15px;
  color: #000000de;
}

.knowledge-base-table-name.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  color: #000000de;
  text-overflow: ellipsis;
  width: 328px;
  overflow: hidden;
  white-space: nowrap;

  &.knowledge-base-table-name-link {
    color: #456eb2;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &.knowledge-base-table-name-normal {
    font-size: 14px;
    font-weight: 400;
    color: #000000de;
  }
}

.knowledge-base-table-metadata.MuiTypography-root {
  font-size: 12px;
  font-weight: 400;
  color: #80868c;
}

.knowledge-base-table-image {
  width: 25px;
  height: 25px;
}

.knowledge-base-options-menu-item {
  display: flex;
  align-items: center;
  gap: 10px;

  .knowledge-base-options-menu-item-title.MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    color: #000000de;
  }

  svg {
    margin: 0;
  }
}

.files-upload-knowledge-base-hint-box-wrapper {
  background-color: #d5f0fc;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 13px 16px;
  border-radius: 4px;
  border: 1px solid rgba(213, 240, 252, 1);

  svg {
    color: #2196f3;
    font-size: 18px;
  }
}

.files-upload-knowledge-base-hint {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #00000099;
  letter-spacing: 0.15px;
}

.knowledge-base-file-upload {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.knowledge-base-drop-zone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23BEBFC2FF' stroke-width='2' stroke-dasharray='10%2c 14' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  padding: 12px;
  background-color: #f8fafc;
  padding: 26px 20px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    width: 40px;
    height: 40px;
  }

  .knowledge-base-drop-zone-row {
    .knowledge-base-drop-zone-title.MuiTypography-root,
    .knowledge-base-drop-zone-browse.MuiTypography-root {
      line-height: 24px;
      letter-spacing: 0.15px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }

    .knowledge-base-drop-zone-title.MuiTypography-root {
      display: inline;
      color: #00000099 !important;
    }

    .knowledge-base-drop-zone-browse.MuiTypography-root {
      display: inline;
      color: #456eb2 !important;
    }
  }
}

.uploaded-resource-file-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 2px 4px;
}

.uploaded-resource-file-info-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  flex: 1.7;
}

.uploaded-resource-file-image {
  width: 25px;
  height: 25px;
}

.uploaded-resource-file-info-column {
  .uploaded-resource-file-name.MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    color: #000000de;
    overflow-wrap: anywhere;
  }

  .uploaded-resource-file-size.MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: #80868c;
  }
}

.uploaded-files-delete-icon {
  color: #f44336;
  width: 10px;
  height: 26px;
}

.uploaded-files-delete-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;
}

.files-progress-bar-wrapper {
  flex-grow: 1;
}

.files-progress-bar-container {
  width: 100%;
}

.uploaded-resource-file-progress-bar-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-grow: 1;
}

.files-progress-bar.MuiLinearProgress-root > * {
  background-color: #4caf50;
  border-radius: 40px;
  height: 5px;
}

.files-progress-bar.MuiLinearProgress-root {
  background-color: #e2e8f0;
  border-radius: 40px;
  height: 5px;
}

.uploaded-files-divider.MuiDivider-root {
  background-color: #e2e8f0;
  margin-top: 6px;
}

.knowledge-base-view-endpoints-modal-body.MuiDialogContent-root {
  padding: 0;
}

.search-knowledge-base-input .MuiOutlinedInput-root {
  border: none;
  border-top: 1px solid #e2e8f0;
  box-shadow: 0px 9px 18px 0px #0000000d;
  padding-inline: 18px 24px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.knowledge-base-view-endpoints-container {
  padding: 24px;
  max-height: 350px;
  overflow-y: auto;
}

.swagger-endpoints-divider.MuiDivider-root {
  margin: 20px 0;
  color: #e2e8f0;
}

.knowledge-base-endpoint-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.knowledge-base-endpoint-info-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.knowledge-base-endpoint-name-type-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.knowledge-base-endpoint-name.MuiTypography-root {
  font-size: 16px;
  font-weight: 600;
  color: #000000de !important;
  line-height: 20px;
}

.knowledge-base-endpoint-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 1px 6px;

  &.get {
    background-color: #4ba4ff;
  }

  &.post {
    background-color: #33c381;
  }

  &.put {
    background-color: #fca12f;
  }

  &.delete {
    background-color: #ec5050;
  }

  &.patch {
    background-color: #bc76de;
  }

  .knowledge-base-endpoint-chip-label.MuiTypography-root {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff !important;
    text-transform: uppercase;
    line-height: 18px;
  }
}

.knowledge-base-endpoint-description.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  color: #00000099 !important;
  max-width: 423px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 16.8px;
}

.knowledge-base-endpoint-item-actions-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.delete-endpoint-menu-item {
  color: #e31b0c !important;
  font-size: 14px;
  font-weight: 400;

  svg {
    color: #e31b0c;
  }
}

.edit-swagger-endpoint-container {
  display: flex;
  flex-direction: column;
  padding-inline-end: 2px;
  gap: 20px;
  border: 1px 0px 1px 0px;
  max-height: 560px;
  overflow-y: auto;
}

.reset-margin {
  margin: 0px !important;
}

.edit-swagger-endpoint-description {
  padding: 8px 12px;
}

.edit-endpoint-field-label.MuiTypography-root {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);

  &.remove-margin {
    margin-bottom: 0;
  }

  &.with-underline-dashed {
    border-bottom: 1px dashed #94a3b8;
    padding-bottom: 2px;
    width: fit-content;
  }
}

.add-knowledge-base-files-popup-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ai-agent-download-file-sample {
  color: #456eb2;
}

.ai-agent-uploaded-files-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ai-agent-add-source-button {
  padding: 5px 12px;
}

.ai-agent-knowledge-base-name-cell-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.ai-agent-swagger-method-description-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.edit-endpoint-parameters-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.edit-endpoint-parameters-key-value-container {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.edit-swagger-endpoint-select-method {
  width: 125px;
}

.ai-agent-edit-swagger-endpoint-add-field-array-button {
  margin-top: 10px;
}

.ai-agent-knowledge-base-swagger-modal-title-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.ai-agent-search-input-adornment-container {
  display: flex;
  gap: 2px;
}

.ai-agent-knowledge-base-swagger-modal-footer-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px 24px 24px;
}

#shared-table {
  .ai-agent-knowledge-base-align-cell.MuiTableCell-head {
    color: #475569 !important;
    font-size: 13px !important;

    @include ar {
      text-align: right !important;
    }
  }

  .ai-agent-knowledge-base-text-transform-letter.MuiTableCell-head {
    text-transform: capitalize !important;
  }
}

.edit-endpoint-remove-icon {
  color: #e31b0c;
}

.ai-agents-form-footer-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 19px 24px;
  box-shadow: 0px 4px 8px 0px #0000000d;
  border-top: 1px solid #e2e8f0;
  background-color: #ffffff;
  margin-top: -80px;
  z-index: 10;
}

.ai-agents-form-select {
  width: 100%;

  .MuiOutlinedInput-input {
    padding: 9px 14px;
    font-size: 14px;
    @include ar {
      padding-left: 32px;
      padding-right: 14px;
    }
  }
}

.ai-agents-select-placehoder.MuiButtonBase-root.MuiMenuItem-root.Mui-disabled {
  display: none;
}

.ai-agents-select {
  color: rgba(0, 0, 0, 0.4);
}

.ai-agents-survey-request-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 5px;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;

  .survey-request-text-wrapper {
    padding: 16px;
    padding-inline-end: 0;
    flex-grow: 1;

    .survey-request-text {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      line-height: 20px;
      margin: 15px 0;
      font-weight: 400;

      span {
        font-weight: 700;
      }
    }
  }

  .survey-request-image-wrapper {
    width: 277px;
    min-width: 277px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.ai-agents-select-checkbox.MuiButtonBase-root.MuiCheckbox-root {
  padding: 0;
  margin-inline-end: 5px;
}

.ai-agents-select-item.MuiButtonBase-root.MuiMenuItem-root {
  font-size: 14px;
}

.autocomplete-select-controll-option-ai-agents {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .single-option.MuiTypography-root {
    font-size: 14px;
  }
}

.ai-agents-auto-complete {
  width: 100%;

  @include ar {
    .MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
      .MuiAutocomplete-endAdornment {
        right: unset;
        left: 9px;
      }
    }
  }

  .MuiInputBase-root.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
    padding: 10px 14px;
  }

  .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiInputBase-inputAdornedEnd {
    padding: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
  }
}

.ai-agents-auto-complete-list {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.routing-select-ai-agents-autocomplete-item.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters {
  color: #456eb2;
  display: flex;
  align-content: center;
  gap: 10px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  svg {
    font-size: 14px;
  }
}

.ai-agents-empty-list-wrapper {
  margin: -4px -16px;

  .ai-agents-empty-list-message.MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.6);
    padding: 4px 16px;
  }
}

.pagination-resources-list {
  display: flex;
  align-self: center;
  margin-top: 30px;
}

.knowledge-base-view-endpoints-loading-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-agent-swagger-endpoint-actions {
  font-size: 15px;
}
.ai-agents-form-helper-text.MuiFormHelperText-root,
.ai-agents-form-helper-text.MuiBox-root {
  color: #f44336;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  display: block;
  font-weight: 400;
  font-size: 12px;
}
.ai-agent-delete-image-icon {
  color: #f44336;
  font-size: 10px;
}
.ai-agent-form-disabled-container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px 16px;
  border: 1px solid #d5f0fc;
  background-color: #f4f9fc;
  border-radius: 4px;
  background-color: #e8f5ff;
}
.ai-agent-form-disabled-text.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  color: #00000099;
}
.published-ai-agent-details {
  display: flex;
  align-items: center;
  gap: 10px;
}
.published-ai-agents-header-name {
  font-size: 16px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
}
.published-ai-agent-published-by-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.published-ai-agent-actions-container {
  display: flex;
  align-items: center;
  gap: 15px;
}
.published-ai-agent-header-info-text.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #00000099;
}
.edit-published-ai-agent-modal-body.MuiTypography-root {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000de;

  span:nth-of-type(2) {
    font-weight: 700 !important;
  }

  span:nth-of-type(3) {
    display: block;
    margin-top: 16px !important;
  }
}

.ai-agent-delete-image-icon-container {
  margin-inline-end: 12px;
  .ai-agent-delete-image-icon {
    font-size: 12px;
    color: #f44336;
    &.ai-agent-delete-image-icon-disabled {
      color: #00000042;
    }
  }
}

.ai-agent-swagger-file-consent-container {
  width: 100%;
  padding: 4px 4px 14px 4px;
  display: flex;
  gap: 10px;
  background-color: #f1f5f9;
}
.ai-agent-swagger-file-consent-text.MuiTypography-root {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 16.8px;
  color: #000000de !important;
  margin-top: 8px !important;
}
.ai-agent-paginated-circular-progress-container {
  display: flex;
  justify-content: center;
  margin: 8px 0px;
}

.ai-agent-activity-log-skeleton-container {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 20px;
  .action-type-circle.MuiSkeleton-circular {
    width: 30px;
    height: 30px;
  }
  .column-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .action-title.MuiSkeleton-text {
    width: 210px;
  }
  .action-details-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .author-image.MuiSkeleton-circular {
    width: 16px;
    height: 16px;
  }
  .author-details.MuiSkeleton-text {
    width: 210px;
  }
}
.ai-agents-form-group-small {
  margin-top: 5px;
  margin-inline-start: 32px;
}

.ai-agent-drawer-empty-box {
  align-self: center;
}

.ai-agent-identity-container {
  margin-top: 30px;
}

.ai-agent-response-length-item-wrapper {
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  height: 43px;
  cursor: pointer;

  &.tone-of-voice-wrapper {
    height: 57px !important;
  }

  &.selected {
    border: 1px solid #456eb2 !important;
    box-shadow: 0px 6.1px 17.37px 0px rgba(0, 0, 0, 0.08);
  }
}

.ai-agent-emoji-item-wrapper {
  padding: 16px 12px;
  display: flex;
  gap: 13px;
  border: 1px solid #e2e8f0;
  border-radius: 2px;
  cursor: pointer;
  &.selected {
    border: 1px solid #456eb2 !important;
    box-shadow: 0px 6.1px 17.37px 0px rgba(0, 0, 0, 0.08);
  }
  &.formal-semi-formal-disable {
    cursor: not-allowed;
  }
}
.ai-agent-response-length-item-title.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  line-height: 9.39px;
  color: #456eb2;
  letter-spacing: 0.07px;
}

.ai-agent-response-length-item-description.MuiTypography-root {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  color: #00000099;
  @include text-ellipsis($lines: 3);
}

.ai-agent-emoji-item-title.MuiTypography-root {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  color: rgba(0, 0, 0, 0.87);
}

.ai-agent-emoji-item-description.MuiTypography-root {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.64px;
  letter-spacing: 0.15px;
  color: #00000099;
  margin-top: 4px;
  @include text-ellipsis($lines: 3);
}

.ai-agent-advanced-settings-wrapper.MuiGrid-item {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.ai-agent-seasonal-date-picker-wrapper {
  width: 100%;
  margin-bottom: 20px;
}

.ai-agent-date-picker-icon {
  @include ar {
    margin-left: 9px;
  }
}

.ai-agents-select-clear-end-adornment {
  margin-inline-end: 12px;
}

.ai-agents-select-clear-icon {
  width: 28px;
  height: 28px;
}
