// navigation sidebar
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.conainer-engagement-inbox-opened {
  background: #f0f0f0;
  cursor: pointer;
}
.conainer-engagement-inbox {
  display: flex;
  @include en {
    padding: 6px 0px 6px 12px;
  }
  @include ar {
    padding: 6px 15px 6px 3px;
  }
  border-radius: 4px;
  &.have-access:hover {
    background: #f0f0f0;
    cursor: pointer;
  }

  .inbox-content-engagement {
    display: flex;
    svg {
      margin-top: 3px;
      font-size: 24px;
    }
  }
  .inbox-wrapper-engagement {
    margin-inline: 15px;
    display: flex;
    align-items: center;
  }
  .engagement-header-inbox {
    font-weight: 500;
    font-size: 12px;
    color: #00000099;
    @include en {
      line-height: 0.6;
    }

    @include ar {
      line-height: 1.3;
    }
  }
  .inbox-type-enagement {
    font-weight: 700;
    font-size: 20px;
  }
}
.switch-navigation-container {
  position: fixed;
  z-index: 11;
  width: 349px;
  left: 0px;
  @include ar {
    left: unset;
    right: 0px;
  }
  .switch-navigation-content.MuiBox-root {
    border-radius: 4px;
    padding: 8px 0px 8px 0px;
    background: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 0 20px;
  }
}
.navigation-content-types-main {
  padding: 6px 16px 6px 16px;
  display: flex;
  flex: 1;
}
.switch-navigation-inbox {
  .switch-to-engagement {
    color: #00000099;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 14px;
    padding: 6px 16px 0px 16px;
    margin-bottom: 5px;
  }
  .publish-icon-container {
    border-radius: 6px;
    background: #eeeeee;
    height: 34px;
    width: 34px;
    margin-top: 3px;
    img {
      padding: 5px;
    }
  }
  .navigation-content-types {
    display: flex;
    cursor: pointer;
    .arrow-icon-navigate-wrapper {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      &-icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .arrow-icon-navigate {
      visibility: hidden;
      position: relative;
      width: 16px;
      height: 16px;
      color: #0f172a;

      @include ar {
        rotate: 180deg;
      }
    }
    &:hover {
      background-color: #0000000a;
      .arrow-icon-navigate {
        visibility: visible;
      }
    }
  }
  .switch-type-wrapper {
    margin-inline: 15px;
  }
  .switch-type-enagement {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }
  .switch-desc-enagement {
    color: #00000099;
    font-weight: 400;
    font-size: 12px;
  }
  .switch-navigation-divider {
    height: 1px;
    background: #e2e8f0;
    margin: 6px 16px;
  }
  .switch-navigation-inbox-dm {
    padding: 6px 16px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      .switch-navigation-inbox-dm-arrow {
        visibility: visible;
      }
    }
    &-arrow {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      visibility: hidden;
      svg {
        width: 16px;
        height: 16px;
        color: #0f172a;
        @include ar {
          rotate: 180deg;
        }
      }
    }
    &-content {
      display: flex;
      align-items: center;
      gap: 15px;
      &-icon {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 16px;
          height: 21px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      &-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.15;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}
.publish-icon-inbox {
  width: 37px;
  height: 43px;
}
.comment-icon-engagement.MuiSvgIcon-root {
  color: #52af8d;
  margin-top: 5px;
  font-size: 37px;
}
.comment-icon-inbox.MuiSvgIcon-root {
  padding: 2px 4px;
  font-size: 25px;
}
//Adding new converstion
.add-new-conv-btn {
  &.MuiButtonBase-root {
    background-color: #fff;
    color: #64748b;
    min-width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 2px;
    border: 1px solid #cbd5e1;
    svg {
      width: 16px;
      height: 16px;
    }
    &:hover {
      background-color: #f1f5f9;
      box-shadow: unset;
    }
  }
}
.switch-navigation-inbox-img {
  background: #eeeeee;
  border-radius: 6px;
  img {
    width: 19px;
    height: 19px;
  }
}
.switch-navigation-inbox-dm-content-desc.MuiTypography-root {
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}
