@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.dashboard-warning-icon {
  color: #ec8623;
  @include en {
    margin-right: 6px;
  }
  @include ar {
    margin-left: 6px;
  }
}

.dashboard-tooltip {
  font-size: 12px;
  font-weight: 400;
}

.tooltip-name {
  text-transform: capitalize;
  @include en {
    margin-right: 3px;
  }
  @include ar {
    margin-left: 3px;
  }
}

.dashboard-tooltip-ul {
  margin-block-start: 3px;
  margin-block-end: 3px;
  padding-inline-start: 0px;
}

.dashboard-tooltip-li {
  position: relative;
  padding-left: 10px;
  margin: 0;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 400;
  @include en {
    padding-left: 10px;
  }
  @include ar {
    padding-right: 10px;
  }
}
.dashboard-tooltip-li::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 50%;
  @include en {
    top: 4px;
    left: 0px;
  }
  @include ar {
    top: 8px;
    right: 0px;
  }
}

.icons-dashboard-table {
  margin-top: 8px;
  width: auto;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  @include en {
    padding-right: 5px;
  }
  @include ar {
    padding-left: 5px;
  }
  .twitter-icon {
    font-size: 18px;
    margin-top: -2px;
  }
  .instagram-icon {
    font-size: 18px;
    margin-top: -3px;
    @include ar {
      margin-right: -1px;
    }
    @include en {
      margin-left: -1px;
    }
  }
  .whatsapp-datasource-icon {
    font-size: 19px;
    margin-top: -18px;
    margin-right: 4px;
    margin-left: 0px;
  }

  #gmb-datasource-icon {
    top: -3px;
  }
  .gmail-datasource-icon {
    margin-top: 2px;
  }

  .intercom-datasource-icon {
    font-size: 18px;
    position: relative;
    top: 0px;
    @include en {
      margin-right: 5px;
    }
    @include ar {
      margin-left: 5px;
    }
  }

  .insta-social-icon {
    width: 22px !important;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .talkwalker-icon img {
    height: 18px;
  }
}
.dashboard-head {
  &.MuiBox-root {
    display: flex;
    align-items: center;
  }
}
.table-dashboard-list {
  .monitor-name {
    .monitor-text,
    td.monitor-or-channel,
    td.dashboard-type {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      div {
        @extend .dashboard-type;
      }
    }
  }
}
.search-dashboard-box {
  div.search-box {
    width: 322px;
    div.search-field-width {
      label {
        @include en {
          right: 66px;
        }
        @include ar {
          left: 66px;
        }
      }
    }
    div.search-icon-container.search-icon-position {
      @include en {
        left: 86%;
      }
      @include ar {
        right: 86%;
      }
    }
    div.search-field-dashboard {
      width: 322px;
    }
  }
}
