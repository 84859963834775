
.lucidya-apis-snackbar-wrapper{
  max-width: 320px;
}
.lucidya-apis-snackbar-heading{
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.lucidya-apis-snackbar-description{
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
}
.api-documentation-link {
  color: #456EB2;
}