@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.settings-container {
  margin-bottom: 60px;
  max-width: 100% !important;
}
.monitor-setting-header {
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  button {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #80868c;
  }
  svg {
    font-size: 18px;
    @include ar {
      transform: scale(-1, 1);
    }
  }
}
.info-box {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
  border-radius: 5px;
  width: 100%;
  height: 95px;
  padding: 18px;
  @include ar {
    &:last-child {
      margin-right: 0px;
    }
    margin-left: 16px;
  }
  @include en {
    &:last-child {
      margin-right: 0px;
    }
    margin-right: 16px;
  }
  div {
    display: flex;
    justify-content: space-between;
  }
  p {
    &.MuiTypography-root {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #64748b;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-decoration-line: underline;
    color: #456eb2;
  }
}
.info-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section-box {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 4px rgba(203, 213, 225, 0.17);
  border-radius: 4px;
  .MuiInputLabel-root {
    top: 0px;
    @include ar {
      font-family: "Dubai" !important;
    }
    &.MuiInputLabel-shrink {
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      top: 3px;
      @include ar {
        font-weight: 500;
      }
    }
    &.Mui-focused {
      color: #456eb2;
      border-color: #456eb2;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiInputLabel-root {
    &[data-shrink="false"] {
      font-size: 13px;
    }
  }
  .MuiSelect-select {
    font-size: 14px;
    font-weight: 400;
  }
  .section-box-data {
    margin: 20px;
    &.section-box-ignore-tweets {
      @include ar {
        label {
          transform: translate(25px, 13px) scale(1);
          &.Mui-focused,
          &.MuiFormLabel-filled {
            transform: translate(100px, -10px) scale(0.75);
          }
        }
      }
    }
    textarea {
      &.MuiOutlinedInput-input {
        width: 430px;
        height: 10px;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .select-owner {
      width: 457px;
      height: 45px;
      menuItem {
        div {
          left: 122px !important;
        }
        ul {
          width: 10px !important;
        }
      }
    }
    input {
      height: 15px;
      width: 430px;
      font-size: 14px;
      &::placeholder {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .include-articles-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 460px;
      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        padding: 5px 0px;
      }
      .description {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.main-titles {
  &.MuiTypography-root,
  &.MuiBox-root {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1e293b;
    &.underlined {
      border-bottom: 1px dashed rgba(100, 116, 139, 0.5);
    }
  }
  .required {
    color: #e31b0c;
    margin-inline-start: 2px;
  }
}
.save-footer {
  position: fixed !important;
  top: auto !important;
  bottom: 0;
  background-color: #ffffff !important;
  div {
    background-color: #ffffff !important;
    padding: 0px 100px;
  }
  .MuiTypography-root {
    font-size: 15px;
    font-weight: 400;
  }
}

.save-btn {
  background-color: #456eb2 !important;
  border-radius: 4px;
  color: #ffffff !important;
  height: 40px;
}

.cancel-btn {
  margin: 0px 10px !important;
}
.reauth-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
  p {
    margin-inline-end: 45px;
    font-family: "Graphik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
  button {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #64748b;
  }
}
.posts-used {
  &.MuiTypography-root {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #475569;
    margin-bottom: 15px;
  }
}

.status {
  padding: 6px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  width: auto;
  font-size: 13px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  text-transform: capitalize;
  &.collecting {
    color: #3b873e;
    border-color: rgba(76, 175, 80, 0.5);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      #4caf50;
  }
  &.paused {
    color: #616161;
    background: rgba(0, 0, 0, 0.09);
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
  &.unapproved {
    color: #ed6c02;
    background: rgba(237, 108, 2, 0.08);
    border: 1px solid rgba(237, 108, 2, 0.5);
  }
  &.reـauthenticated {
    color: #ed6c02;
    background: rgba(237, 108, 2, 0.08);
    border: 1px solid rgba(237, 108, 2, 0.5);
  }
}

.spam-keywords-area {
  &.MuiBox-root {
    justify-content: initial;
    width: 455px;
    flex-wrap: wrap;
  }
  &.excluded-keywords-wrapper {
    width: auto;
  }
}
.inline-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section-header {
  &.MuiTypography-root {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #475569;
  }
}

.err-msg-text {
  color: #f44336;
  font-size: 12px;
  width: 452px !important;
  margin-top: -5px;
}

.note-limit-msg {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  width: 452px !important;
  margin-top: 5px;
  &.error-limit-pages {
    color: #f44336;
  }
}

.error-msg-create-excluded {
  margin-top: 10px;
  margin-bottom: 0px;
}
.sources-radio-btn {
  flex-direction: row !important;
  position: relative;
  margin: 0 15px;
  @include en {
    right: 170px;
  }
  @include ar {
    left: 225px;
  }
  .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
  }
}

.status {
  padding: 6px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  width: auto;
  font-size: 13px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  text-transform: capitalize;
  &.collecting {
    color: #3b873e;
    border-color: rgba(76, 175, 80, 0.5);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      #4caf50;
  }
  &.paused {
    color: #616161;
    background: rgba(0, 0, 0, 0.09);
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
  &.unapproved {
    color: #ed6c02;
    background: rgba(237, 108, 2, 0.08);
    border: 1px solid rgba(237, 108, 2, 0.5);
  }
  &.reـauthenticated {
    color: #ed6c02;
    background: rgba(237, 108, 2, 0.08);
    border: 1px solid rgba(237, 108, 2, 0.5);
  }
}

.inline-flex {
  display: inline-flex;
}

.data-source-setting {
  .keywords-counter-setting {
    color: #3b873e;
    font-size: 13px;
    border-radius: 4px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include ar {
      margin-left: 20px;
      margin-right: 0px;
    }
  }
}
.section-box-chip-setting {
  margin: 0px 20px;
}
.chip-keywords-wrapper {
  &.MuiBox-root {
    margin: 0px 20px 20px;
  }
}
.error-msg-keyword {
  &.MuiBox-root {
    margin: 0px 20px;
  }
}
.section-box-data-keyword {
  .MuiOutlinedInput-input {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;
    &::placeholder {
      text-align: right;
    }
  }
  &.MuiBox-root {
    margin: 35px 20px 0px;
  }
}
.section-box-data-keyword-setting {
  .MuiOutlinedInput-input {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;
    &.MuiInputLabel-shrink {
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      top: 3px;
      @include ar {
        font-weight: 500;
      }
    }
  }
  &.MuiBox-root {
    margin: 20px 20px 0px;
  }
  .required {
    color: #e31b0c;
    margin-inline-start: 2px;
  }
}
.section-box-data-setting {
  margin: 10px 20px;
}

.add-btn-plus-setting {
  padding: 11px !important;
  line-height: 2.1 !important;
}

.input-field-edit {
  @include ar {
    label {
      left: auto !important;
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
  }
}

.input-field-excluded-source {
  @include ar {
    label {
      transform: translate(160px, 15px) scale(1);

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(180px, -11px) scale(0.75);
        background: #ffffff;
        padding: 3px;
      }
    }
  }
}
.input-field-spam,
.input-keyword-field {
  @include ar {
    label {
      transform: translate(85px, 15px) scale(1);

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(140px, -11px) scale(0.75);
        background: #ffffff;
        padding: 3px;
      }
    }
  }
}
.input-transform-label-collect_tweets,
.input-transform-label-ignore_tweets {
  @include ar {
    label {
      transform: translate(25px, 13px) scale(1);

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(100px, -10px) scale(0.75);
      }
    }
  }
}

.spam-text-field {
  .MuiOutlinedInput-root input {
    width: 430px;
    @include ar {
      padding-left: 20px;
    }
  }
}

.input-field-edit {
  .MuiInputLabel-root.Mui-error {
    color: #f44336;
  }
  .MuiOutlinedInput-root {
    &.Mui-focused {
      input {
        @include en {
          width: 370px;
        }
        @include ar {
          width: 378px;
        }
      }
    }
    &.Mui-error {
      .add-btn-plus-setting {
        color: #f44336;
        @include en {
          border-left: 2px solid #f44336;
        }
        @include ar {
          border-right: 2px solid #f44336;
        }
      }
    }
  }

  .MuiOutlinedInput-root .MuiInputAdornment-root {
    .add-btn-plus-setting {
      display: none;
    }
  }
  .Mui-focused .MuiInputAdornment-root {
    .add-btn-plus-setting {
      display: block;
      margin-right: -14px !important;
      line-height: 2.1 !important;
    }
  }
}

.monitor-name-edit {
  @include ar {
    label {
      transform: translate(360px, 15px) scale(1);
      &.Mui-focused,
      &.MuiFormLabel-filled,
      &.MuiInputLabel-shrink {
        transform: translate(365px, -11px) scale(0.75);
        background: #ffffff;
        padding: 3px;
      }
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
  }
}

.monitor-desc-edit {
  @include ar {
    label {
      transform: translate(410px, 15px) scale(1);

      &.Mui-focused,
      &.MuiFormLabel-filled,
      &.MuiInputLabel-shrink {
        transform: translate(410px, -11px) scale(0.75);
        background: #ffffff;
        padding: 3px;
      }
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
  }
}

.account-name-input {
  @include ar {
    label {
      transform: translate(360px, 15px) scale(1);
      display: none;

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(350px, -11px) scale(0.75);
        background: #ffffff;
        padding: 3px;
        display: block;
      }
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
  }
  .MuiOutlinedInput-root input {
    width: 412px;
  }
}

.account-name-input-tw {
  &.Mui-focused,
  &.MuiFormLabel-filled {
    transform: translate(390px, -11px) scale(0.75);
    background: #ffffff;
    padding: 3px;
    display: block;
  }
}

.account-analysis-input {
  .MuiOutlinedInput-root input {
    @include en {
      width: 435px;
    }
  }
  @include ar {
    label {
      transform: translate(100px, 15px) scale(1);

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(150px, -11px) scale(0.75);
        background: #ffffff;
        padding: 3px;
      }
    }
  }
}

.dropdown-list-nb-edit,
.owner-input-field {
  .MuiSvgIcon-root {
    @include ar {
      transform: translate(-420px, 0px) scale(1.1);
    }
  }
}
.owner-input-field {
  label {
    background: #ffffff;
    @include ar {
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(372px, -10px) scale(0.8);
        padding: 3px;
      }
    }
  }
}

.owner-name-item {
  @include ar {
    direction: ltr;
  }
}

.keywords-error-msg {
  margin-top: 5px;
  margin-bottom: 0px;
}
.error-msg-edit-excluded,
.spam-error-msg {
  margin-top: -10px;
  margin-bottom: 0px;
}
.exclude-sources-field,
.spam-keyword-container {
  margin-top: -15px;
}

.input-field-excluded-source
  .Mui-focused
  .MuiInputAdornment-root
  .add-btn-plus-setting {
  line-height: 1.85 !important;
}

.deactive-reauth {
  .btn-deactive {
    padding: 8px 12px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.26);
  }
}

.section-box-data.section-box-data-customer {
  display: flex;
  justify-content: space-between;
  .customer-care-input {
    @include ar {
      direction: rtl;
    }
  }
  label {
    transform: translate(9px, 10px) scale(1);
    &.Mui-focused,
    &.MuiFormLabel-filled {
      transform: translate(15px, -9px) scale(0.75);
    }
  }
  @include ar {
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
    label {
      transform: translate(386px, 10px) scale(1);
      &.Mui-focused,
      &.MuiFormLabel-filled {
        padding: 0 5px;
        transform: translate(386px, -10px) scale(0.75);
        background: #ffffff;
        display: block;
      }
    }
  }
  .username-label {
    color: rgb(244, 67, 54);
  }
  h3 {
    display: flex;
    align-items: center;
    color: #0c192a;
    font-size: 14px;
    font-weight: 500;
    span {
      display: inline-flex;
    }
  }
  .shared-customer-care-container {
    width: 463px;
    margin-bottom: 0;
    .MuiInputBase-root.MuiOutlinedInput-root {
      width: 100%;
      input {
        padding: 0 12px;
        &::placeholder {
          color: rgba(0, 0, 0, 0.6);
          opacity: 0.6;
        }
      }
    }
  }
}
.setting-hashtags-limit-wrapper {
  margin: 0px 20px 20px;
  .hashtags-limit-wrapper {
    width: 100%;
    .limit-text {
      max-width: 100%;
    }
  }
}
.instagram-setting-manager {
  align-items: center;
}
.vertical-line-hashtags-manager {
  color: #d1d1d1;
  width: 1px !important;
  height: 28px !important;
  margin: 0px 10px !important;
}
.setting-inactive-hashtags-wrapper {
  margin: 40px 0;
  .hashtags-limit-wrapper {
    .limit-text {
      max-width: 870px;
    }
  }
}
.instagram-hashtags-tooltip {
  padding: 13px;
  font-size: 12px;
  line-height: 16px;
}

.keyword-switch-box-form-edit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  width: 460px;
  label.MuiFormControlLabel-root {
    @include ar {
      margin-right: 0;
    }
  }
  .keyword-switch-box-label {
    font-weight: 400;
    flex-direction: column;
    align-items: flex-start;

    .keyword-switch-label {
      font-size: 14px;
      line-height: 20.02px;
      color: #000000de;
    }
    .keyword-switch-description-label {
      font-size: 12px;
      line-height: 17.16px;
      color: #80868c;
    }
  }
}

.sources-inputs-fields-higher-wrapper {
  justify-content: initial;
  width: 477px;
  flex-wrap: wrap;
}

.monitor-setting-custom-sources-container {
  &.MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;
    margin-inline-end: 20px;
  }
}
