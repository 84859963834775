@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.keyword-modal-length {
  &.MuiBox-root {
    color: #80868c;
    font-size: 14px;
    font-weight: 400;
  }
}
.keyword-popup-data-source {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 20px;
  span {
    color: #80868c;
    font-size: 15px;
    font-weight: 400;
    padding: 0px 10px;
  }
}

.test-keyword-content {
  .testkeywordDialogContent {
    max-height: calc(100vh - 300px);
    margin-bottom: 10px;
  }
}

.show-all-btn {
  &.MuiButton-root {
    background: rgba(69, 110, 178, 0.15);
    border-radius: 16px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
    color: #456eb2;
    border: none;
    margin-top: 12px;
    &:hover {
      background: rgba(69, 110, 178, 0.15);
    }
  }
  @include ar {
    margin-right: 6px;
  }
}
.keyword-spam-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
