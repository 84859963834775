@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.main-perms-countainer {
  margin-top: 30px;
  .MuiPaper-elevation.MuiPaper-rounded {
    box-shadow: none;
    background-color: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 0;
    margin: 15px 0px;
    &::before {
      display: none;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0;
    border-top: 1px solid #e2e8f0;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    svg {
      color: #616161;
      width: 30px;
    }
  }
  .MuiAccordionSummary-root {
    padding: 0 10px;
  }
  .main-collapse-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 36px;
    }
    .collapse-header-det {
      display: flex;
      align-items: center;
      gap: 10px;
      .collapse-header-title {
        display: flex;
        gap: 5px;
        font-size: 16px;
        color: #0f172a;
        align-items: center;
        .header-title-engag {
          color: #64748b;
          font-size: 14px;
        }
      }
      .collapse-header-peag {
        color: #00000099;
        font-size: 13px;
        padding-top: 5px;
      }
    }
    .collapse-header-state {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: flex-end;
      gap: 5px;
      margin-inline-end: 5px;
      padding: 4px 10px;
      border-radius: 3px;
      svg {
        width: 18px;
        height: 20px;
      }
      &.green_state {
        color: #3b873e;
        background-color: #4caf5014;
      }
      &.viwer {
        color: #ed6c02;
        background-color: #ed6c0214;
      }
      &.manager {
        color: #456eb2;
        background-color: #e6f3fc;
      }
      &.no_acc {
        color: #64748b;
        background-color: #e2e8f0;
      }
    }
  }
  .perm-table-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
    &.last {
      border: none;
    }
    .perm-table-cell {
      padding: 0 15px;
      font-size: 14px;
      border-inline-start: 1px solid #e2e8f0;
      height: 50px;
      display: flex;
      align-items: center;
      width: calc(70% / 3 - 3px);
      background-color: #fff;
      .cell-acc {
        display: flex;
        align-items: center;
        gap: 5px;
        svg {
          width: 18px;
          height: 18px;
        }
        &.correct {
          svg {
            color: #4caf50;
          }
        }
        &.worng {
          svg {
            color: #f44336;
          }
        }
      }
      label {
        margin: 0;
      }
      span {
        font-size: 14px;
        padding: 0;
        margin-inline-end: 5px;
        &.MuiFormControlLabel-label {
          margin-bottom: -3px;
        }
        &.MuiRadio-root:hover {
          background-color: #fff;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
      &.first {
        font-size: 14px;
        font-weight: 500;
        border: none;
        width: 30%;
        background-color: #fbfcfd;
        &.perm-header {
          color: #00000099;
        }
      }
      .perm-view {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 5px;
        color: #000000de;
        svg {
          color: #3b873e;
          width: 18px;
          height: 20px;
        }
      }
    }
  }
}
