.single-intr-wrapper {
  .topics-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #a4a9af;
    margin: 40px 0px 4px 0px;
    letter-spacing: 0.12em;
  }

  .topics-collected {
    font-style: normal;
    font-size: 14px;
    color: #a4a9af;
    margin-bottom: 20px;
    margin-right: 4px;
  }
}
