@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin md {
  @media only screen and (max-width: 1002px) {
    @content;
  }
}
.home-page-contaner {
  padding: 50px 0 75px;
  background: #f8fafc;
  min-width: 1300px;
  max-width: 1440px;
  margin: auto;
  .MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight {
    width: 320px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 0;
    margin-bottom: 50px;
    border: none;
  }
  .title-box-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    h3 {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px !important;
      font-weight: 600 !important;
      line-height: 20px;
    }
    .title-box {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px !important;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 20px !important;
      span {
        display: flex;
        justify-content: center;
        margin: 0;
        margin-inline-start: 5px;
      }
      svg {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-style: normal;
      }
    }
  }
  .welcome-box {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    .user-img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .user-info-avatar-placeholder {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #e2e8f0;
    }
    h1 {
      color: rgba(0, 0, 0, 0.87);
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    p {
      color: rgba(0, 0, 0, 0.6);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .company-select-box {
    .select-company,
    .company-dropdown {
      width: 220px;
      height: auto;
      @include ar {
        .MuiAutocomplete-inputRoot {
          display: flex;
          justify-content: flex-end;
        }
        label {
          transform: translate(174px, -9px) scale(0.75);
          background: #f8fafc;
          padding: 0 8px;
        }
        fieldset {
          legend {
            span {
              display: none;
            }
          }
        }
      }
      .MuiAutocomplete-inputRoot {
        padding-top: 2px;
        padding-bottom: 0px;
      }
      #controlled-company-label {
        top: 0px;
      }
    }
  }
  .utilities-section {
    margin-bottom: 40px;
    border-radius: 8px;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: linear-gradient(
      -90deg,
      #1f2249 -12.87%,
      #262d56 9.11%,
      #394c7a 50.08%,
      #4e6d9f 87.05%
    );
    @include ar {
      background: linear-gradient(
        90deg,
        #1f2249 -12.87%,
        #262d56 9.11%,
        #394c7a 50.08%,
        #4e6d9f 87.05%
      );
    }
    .utilities-content {
      h1 {
        color: #f8fafc;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        color: #f8fafc;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.15px;
        max-width: 587px;
      }
    }
    a {
      display: flex;
      min-width: 70px;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      border: 1px solid #fff;
      background-color: #fff;
      color: #1a174f;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      text-transform: capitalize;
      transition: 0.3s;
      &:hover {
        color: #c7c8c9;
      }
    }
  }
  .all-sections {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 30px;
  }
}
.slide-continer {
  flex-shrink: 0;
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  gap: 20px;
  .section-slide {
    padding: 10px;
    border-radius: 5px;
    border: 10px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  }
}

.slider-container {
  position: relative;
  margin-top: 20px;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}
@include md {
  #mainHeader {
    .navbar-setting {
      button.notificationBtn,
      .resource-center-icon {
        display: none;
      }
    }
  }
}
