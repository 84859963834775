@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.media-tabs-dashboard {
  border-bottom: 0;
  .datasource-media-tab-dash {
    border-bottom: 0.5px solid #e2e8f0 !important;
  }

  .datasource-media-tab-dash[aria-selected="true"] {
    border-bottom: 2px solid #456EB2  !important;
  }
  .tap-text-media-dash {
    font-size: 14px;
  }
}
.social-posts-tabs {
  .media-tabs-dashboard {
    background-color: white;
    padding-top: 4px;
  }
}
.dashboard-icons-widgets {
  margin-top: 4px;
  @include en {
    margin-left: 5px;
  }
  @include ar {
    margin-right: 5px;
  }
}
