.item-slide-notifications {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  transition: 0.3s;
  height: 200px;
  max-width: 100%;
  .notification-type-parent {
    max-width: 100%;
    overflow: hidden;
    text-align: start;
  }
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #f8f8f8;
  }
  .notification-type {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    svg {
      margin-inline-end: 8px;
    }
  }
  .notification-head {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    margin-bottom: 20px;

    .type-td {
      width: 30px;
      height: 30px;
    }
  }
  .notification-message {
    height: 78px;
    text-align: start;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    b {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      overflow: hidden !important;
      display: inline;
    }
  }
  .action-td {
    cursor: pointer;
    p.MuiTypography-root {
      display: flex;
      min-width: max-content;
      padding: 8px 12px;
      justify-content: start;
      align-items: center;
      color: #456eb2;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      text-transform: capitalize;
      &:hover {
        background: transparent;
      }
    }
  }
}
.skeleton-parant {
  border-radius: 4px;
  background: linear-gradient(
      121deg,
      rgba(255, 255, 255, 0) 17.72%,
      rgba(255, 255, 255, 0.73) 47.7%,
      #f5f5f5 80.59%
    ),
    rgba(0, 0, 0, 0.04);
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
  min-height: 200px;
  .skeleton-head {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .skeleton-head-need {
    width: 100%;
  }
}
.no-data-notifications {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
