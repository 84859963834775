@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.report-list-search-field {
  width: 254px;
  height: 38px;
  input {
    padding: 7px 12px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  button {
    padding: 9px;
    background: #7f7f7f;
    @include ar {
      border-radius: 4px 0px 0px 4px;
      margin-left: -8px;
      margin-right: 0;
    }
    @include en {
      border-radius: 0px 4px 4px 0px;
      margin-right: -14px;
      margin-left: 0;
    }
    svg {
      width: 18px;
      height: 18px;
      color: #ffffff;
    }
  }
  .Mui-focused {
    button {
      background: #456eb2 !important;
    }
  }
}

.report-table {
  .report-name-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
  .date-direction {
    direction: ltr;
  }
  .MuiTableCell-body {
    box-shadow: none;
    @include ar {
      font-family: "Dubai" !important;
    }
    &.report-name-td {
      h6 {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: #456eb2;
      }
    }
  }
}

.report-filter-menu-item {
  padding: 7px 19px !important;
  .MuiCheckbox-root {
    padding: 0px !important;
  }
  .MuiListItemText-root {
    span {
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      @include en {
        margin-left: 11px;
      }
      @include ar {
        font-family: "Dubai" !important;
        margin-right: 11px;
      }
    }
  }
}

#report-create-new-show-report-text-btn.MuiButtonBase-root {
  color: #456eb2;
}
.report-name-container {
  display: flex;
  p {
    font-size: 14px;
  }
  .MuiTypography-root,
  .MuiButton-root {
    font-size: 14px;
    font-weight: 400;
  }
  .MuiButton-root {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.tooltip-content {
  display: flex;
}

.tooltip-content-count {
  font-weight: 600;
  font-size: 13px;
  color: #696d72;
  cursor: default;
}
.tooltip-content-count.MuiTypography-root {
  margin-top: -4px;
  @include ar {
    direction: ltr;
  }
}
.reports-social-icons {
  .facebook-public-icon {
    width: 16px;
    height: 16px;
  }
}

#warning-tooltip-report {
  .MuiTooltip-tooltip {
    margin-inline: 18px;
  }
}
.twitter-prvate-icon {
  color: #1da1f2;
}
.facebook-prvate-icon {
  color: #4267b2;
}
.instagram-prvate-icon {
  color: #e1306c;
}
