@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.segment-delete-pop-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 400;
  @include en {
    .question {
      transform: rotate(0) scaleX(1);
    }
  }

  @include ar {
    .question {
      transform: rotate(360deg) scaleX(-1);
    }
  }
}

.segment-delete-popup-second-title {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.segment-num-deleted-users {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0px 5px;
}
#manage-audience-menu-dropdown {
  margin-top: 6px;
  li {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    svg {
      font-size: 15px;
      font-weight: 400;
      margin-right: 10px;
      @include ar {
        margin-left: 10px;
        margin-right: unset;
      }
    }
  }
}

html[lang="en"] #segments-options-ddl {
  .MuiMenu-paper {
    -webkit-transform: translate(-62%, 0px) !important;
    transform: translate(-62%, 0px) !important;
  }
}

.export-title-modal {
  width: 600px;
  #alert-dialog-title {
    font-weight: 500;
  }
}
.export-body-modal {
  .MuiDialogActions-root {
    margin: 10px;
  }
}

.segment-delete-popup {
  padding: 15px;
}

#audience-manage-audience-profile-long-button.MuiButtonBase-root {
  border-radius: 4px;
  &[aria-expanded="true"] {
    background: #cbd5e1;
    .ellipsis-icon {
      color: #757575;
    }
  }
}
