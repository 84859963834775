@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.accounts-list-box-input-fields {
  .accounts-lists-username-input-label {
    border-bottom: 1px dashed rgba(100, 116, 139, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    padding-bottom: 2px;
    display: inline-block;
  }
  .accounts-list-input-startlogo {
    color: #456eb2;
  }
  .accounts-list-error-msg-form {
    color: #f44336;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-top: 5px;
    padding-inline-start: 12px;
    ul {
      list-style-type: disc;
    }
    @include ar {
      text-align: right;
    }
  }
  .required {
    color: #e31b0c;
    margin-inline-start: 2px;
  }
  .accounts-list-head-title {
    display: inline-block;
    margin-bottom: 5px;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f9f9f9;
    box-shadow: inset 1px 0px 0px #dedede;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .accounts-list-spam-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .accountsList-list-wrapper {
    display: flex;
    align-items: center;
    .username-list-trash {
      cursor: pointer;
      @include ar {
        margin-right: 15px;
      }
      @include en {
        margin-left: 15px;
      }
      margin-top: 12px;
      .tooltipIcon {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .accounts-list button {
    background-color: white;
    padding: 0px;
  }
  .accounts-list-field {
    width: 100%;
    input {
      font-size: 13px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      height: 40px;
      padding: 0px 15px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
        @include ar {
          text-align: right;
        }
      }
    }
    &.accounts-list-input-field {
      @include en {
        padding-right: 0px;
      }
      @include ar {
        padding-left: 0px;
      }
      &.Mui-error {
        .accounts-list-input-startlogo {
          color: #f44336;
        }
        .add-btn-with-plus {
          color: #f44336;
          @include en {
            border-left: 2px solid #f44336;
          }
          @include ar {
            border-right: 2px solid #f44336;
          }
        }
      }
    }
  }
  .trash-icon {
    @include ar {
      margin-top: 5px;
    }
    @include en {
      margin-top: 3px;
    }
    color: #cbd5e1;
  }
}
