@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#custom-dashboard-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 32px;
  background-color: #ffffff;
  border-bottom: 1px solid #94a3b8;
  background-color: #ffffff;
  .breadcrumb {
    font-weight: 400;
    font-size: 14px;
    color: #64748b;
    p {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-weight: 500;
    font-size: 20px;
    color: #0c192a;
  }
  .title-skelton {
    font-size: 24px;
    width: 160px;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.19) 0%,
      rgba(102, 102, 102, 0.076) 100%
    );
  }
  .editor-btns-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}
.custom-dashboard-view-btns-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  .live-data-switch {
    margin: 0;
    border-radius: 20px;
    padding: 0px 10px;
    background: #efefef;
    > .MuiFormControlLabel-label {
      color: #535353;
      font-weight: 600;
    }
    &.active {
      background: #edf7ed;
      > .MuiFormControlLabel-label {
        color: #3b873e;
      }
    }
  }
  #custom-dashboard-export-button,
  #custom-dashboard-view-date-picker {
    padding-inline-start: 12px;
    border-inline-start: 1px solid #e2e8f0;
    &.hidden {
      display: none;
    }
    .MuiInputBase-root {
      margin: 0;
    }
    .date-field svg {
      top: 49%;
      left: 12px;
      @include ar {
        right: 12px !important;
      }
    }
  }
  
}
