@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.free-ques {
  margin-bottom: -30px;
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 0;
  .ques-input {
    width: 100%;
    .css-291si7-MuiInputBase-root-MuiInput-root:hover:not(
        .Mui-disabled,
        .Mui-error
      ):before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }

    > div {
      padding: 20px 0px 10px 0px;
      display: flex;
      align-items: flex-end;
      line-height: 24px;
      letter-spacing: 0.15px;
      > div {
        height: 0;
        max-height: 0;
      }
    }
    button {
      margin-inline-start: 20px;
      margin-bottom: 45px;
      padding: 13px;
      background-color: #456eb2;
      color: #fff;
      min-width: 0px;
      font-size: 15px;
    }
  }
  .ques-progress {
    display: flex;
    flex-direction: row-reverse;
    padding: 20px 0px 20px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #676767;
    border: none !important;
    .MuiTypography-root {
      font-size: 14px;
    }
  }
  .MuiInputBase-root {
    padding-bottom: 20px !important;
  }

  .MuiInputBase-root {
    border-bottom: 1px solid #c0c0c0;
    .MuiInputBase-input {
      margin-inline: 20px !important;
    }
  }
  .MuiInputBase-root.Mui-focused {
    border-bottom: 1px solid #456eb2 !important;
  }
}

#send-luci-request.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.2);
}
