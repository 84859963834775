$skeltonGradient: linear-gradient(
  90deg,
  rgba(0, 0, 0, 0.19) 0%,
  rgba(102, 102, 102, 0.076) 100%
);

.monitor-card-container {
  margin-bottom: 16px;
  .custom-monitor-card {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
    margin: 0;
    > h4 {
      font-size: 12px;
      font-weight: 500;
      line-height: 14.4px;
    }
    > div {
      display: flex;
      align-items: center;
    }
    > span {
      width: 100%;
      svg {
        font-size: 16px;
        line-height: 24px;
        color: #456eb2;
      }
    }
  }
  .selected-card {
    border: 1px solid #456eb2;
  }
  .checkbox-item {
    display: none;
  }

  .card-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    span > .item-card-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .social-media-icon {
        img {
          width: 14px;
          height: 14px;
        }
      }
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color: #000000de;
      }
      span.account-name {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: start;
      }
      ul {
        padding: 0;
        padding-inline-start: 16px;
        margin: 0;
        li {
          font-size: 12px;
          font-weight: 400;
          color: #00000099;
          display: flex;
          align-items: center;
          gap: 3px;
        }
      }
    }
    span > .item-card-box-account {
      ul {
        padding: 0;
        padding-inline-start: 0px;
      }
    }
  }
  .platform-item {
    .card-group {
      border-inline-start: 1px solid #e2e8f0;
      padding-inline-start: 16px;
    }
  }
}
.btn-show-more-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.select-product-radio {
  display: flex;
}
.dashboard-snackbar-box {
  .common-snackbar {
    max-width: 438px;
  }
}
.tooltip-custom-dash {
  width: max-content;
  margin-inline-start: 5px;
  h4 {
    color: #00000099;
    font-size: 14px;
  }
}
h5.dashboard-platform-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  margin-top: 24px;
  color: #00000099;
  margin-bottom: 16px;
}
.dashboard-sub-title-container.no-dashboard-data {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.custom-monitor-card.skeleton-cxm {
  display: flex;
  align-items: center;
  gap: 10px;
  span.MuiSkeleton-root.MuiSkeleton-rectangular {
    margin: 0;
  }
}
.custom-dashboard-skelton-gradient {
  background: $skeltonGradient;
}
