@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.section-slide.home-page-dashboard {
  .skeleton-parant {
    min-height: max-content !important;
  }
  .dashbard-name {
    font-size: 14px;
    font-weight: 600;
    color: #000000de;
    @include en {
      text-align: left;
    }
    @include ar {
      text-align: right;
    }
  }
  .dashbard-type-icon {
    background: rgba(69, 110, 178, 0.1);
    width: 30px;
    height: 30px;
    padding: 6px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #456eb2;
      width: 24px;
      height: 24px;
    }
  }
  .item-slide-notifications.slide-dashbard {
    height: 30px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: start;
    flex-direction: row;
    &.disabled {
      cursor: auto;
    }
  }
  .new-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 51px;
    height: 24px;
    padding: 3px 4px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    background: #4caf50;
  }
  .no-data-home-page-dashboard {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #00000099;
    .empty-link {
      color: #456eb2;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
