.luci-thinking-wrapper,
.luci-thinking-conainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.luci-thinking-desc {
  width: 244px;
  text-align-last: center;
}
.luci-thinking-wrapper {
  margin-top: -120px;
  .gretting-logo.MuiBox-root {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    img {
      animation: unset;
    }
  }
}
.luci-thinking-conainer {
  margin-top: -55px;
  .luci-thinking-title.MuiTypography-root {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #212121;
  }
  .luci-thinking-desc.MuiTypography-root {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #676767;
    margin-top: 10px;
  }
}
