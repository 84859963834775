@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.twitter-reply-to {
  &-root {
    position: relative;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 10px;
    margin-bottom: -3px;
    margin-top: -5px;
  }
  &-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    &.hidden-replay-to {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
    p.twitter-reply-to-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      color: #00000099;
      text-wrap: nowrap;
    }
  }

  &-users-list {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  &-user-chip {
    padding: 4px 6px 4px 6px;
    gap: 4px;
    border-radius: 16px;
    align-items: center;
    display: flex;
    background: #f1f5f9;
    flex-wrap: nowrap;
    @include ar {
      flex-direction: row-reverse;
    }
    .user-chip-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 14.4px;
      color: #000000de;
      text-wrap: nowrap;
    }
    svg {
      width: 13.33px;
      height: 13.33px;
      color: #94a3b8;
      cursor: pointer;
      &:hover {
        color: #475569;
      }
    }
  }
  &-menu {
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    cursor: pointer;
    &:hover {
      background-color: #f1f5f9;
    }
    svg {
      width: 16px;
      height: 16px;
      color: #000000de;
    }
  }

  &-hidden-users {
    font-size: 12px;
    font-weight: 500;
    color: #000000de;
    width: 30px;
    box-sizing: border-box;
    padding: 4px 6px 4px 6px;
    background: #f1f5f9;
    border-radius: 16px;
    flex-grow: 1;
    flex: 1;
  }
}

.twitter-reply-to-menu-list-paper {
  .MuiList-root {
    padding-top: 0px;
    padding-bottom: 8px;
  }
  .twitter-reply-to-menu {
    &-divider {
      margin: 8px 16px;
      border-color: #e2e8f0 !important;
    }
    &-title {
      padding: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #00000099;
      height: 41px;
      box-sizing: border-box;
    }
    &-item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 16px;
      height: 59px;
      box-sizing: border-box;
      &.item-reply-disabled {
        cursor: default;
        &:hover {
          background-color: transparent;
        }
      }
      &-checkbox {
        svg {
          height: 18px;
          width: 18px;
        }
        &.Mui-disabled {
          color: #456eb2;
          opacity: 0.38;
        }
      }
      &-profile-img {
        box-sizing: border-box;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid #eeeeee;
      }
      &-profile {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 171px;
        &-name {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: #000000de;
          flex: 1;
          @include ar {
            direction: rtl;
          }
        }
        &-username {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #696d72;
          flex: 1;
          @include ar {
            direction: rtl;
          }
        }
      }
    }
  }
}
