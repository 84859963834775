.MuiDrawer-root.MuiDrawer-docked.sidebar-inner.normal {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    margin-top: 0px;
    transition: all 0.2s ease;
  }
}
.MuiDrawer-root.MuiDrawer-docked.sidebar-inner.top {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    margin-top: 60px;
    transition: all 0.2s ease;
  }
}

.MuiButtonBase-root-MuiListItem-root:hover {
  background-color: transparent !important;
}
.social__insights {
  padding: 0 !important;
  width: 34px;
  height: 30px !important;
  margin: 5px 0;
  border: 2px solid transparent;
}
.facebbok__channel-insights {
  svg {
    color: #3b5998;
    font-size: 18px;
  }
}
.linkedIn__channel-insights {
  svg {
    color: #0a66c2;
    font-size: 18px;
  }
}
.instagram__channel-insights {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  svg {
    // color: #e4405f;
    border-radius: 4px;
    font-size: 18px;
  }
}
.twitter__channel-insights {
  svg {
    color: #55acee;
    font-size: 18px;
  }
}
.chat__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.whatsapp__icon {
  color: #25d366;
  font-size: 20px;
}
.intercom__icon {
  color: #326bff;
  font-size: 20px;
}
.phone__icon {
  color: #80868c;
  font-size: 16px;
}
.social__box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px 0;
  a {
    width: 34px;
    display: flex;
  }
}
.svg__icons {
  font-size: 25px !important;
}
.icon__active {
  background-color: #d5f0fc;
  border: 2px solid #2aaae2;
  border-radius: 4px !important;
}
.insights-sidemenu {
  height: 70px !important;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.white-blank-box {
  box-shadow: 0 4px 4px #e3e3e3;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  height: 435px;
  margin-top: 90px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.empty-page-text {
  font-size: 16px;
  font-weight: 400;
  color: #64748b;
}

.category-tab {
  .Mui-expanded {
    .category-tab-link {
      pointer-events: none;
    }
  }
  .category-tab-box.Mui-expanded {
    cursor: default;
  }
}

.icon-category {
  background-color: #f1f5f9;
  border-radius: 5px;
  padding: 4px;
}

.channel-analytics-sidemenu {
  z-index: 10;
  > div {
    z-index: 11;
  }
}

.insights-data-sources-icons {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  gap: 3px;
}
.empty-box-insights-page {
  border-top: 1px solid #e2e8f0;
  background-color: #f8fafc;
  padding: 20px;
  min-height: 435px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
