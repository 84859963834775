$primary-color: #456eb2;
$primary-hover-color: #304d7d;
$primary-hover-outline-color: #e6f3fc;
$Secondary-color: #64748b;
$Secondary-hover-outline-BG-color: #f1f5f9;
$error-color: #e50035;
$error-hover-color: #900021;
$error-hover-outline-color: #ffd8e0;
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin PositionIcon($startEnd, $mLeft, $mRight) {
  .MuiButton-#{$startEnd}Icon {
    margin-left: $mLeft;
    margin-right: $mRight;
    @include ar {
      margin-left: $mRight;
      margin-right: $mLeft;
    }
  }
}

@mixin sizeButton($size, $fontSize, $paddingY, $minHight ,$font-size-Icon) {
  &.MuiButton-size#{$size} {
    font-size: $fontSize !important;
    line-height: 26px;
    padding: $paddingY 12px !important;
    height: $minHight !important;

    .MuiButton-iconSize#{$size} svg , .content-Loading svg  {
      font-size: $font-size-Icon !important;
      font-weight: 400;
    }

    .content-Loading span {
      font-size: $fontSize !important;
    }

    @include PositionIcon("start", 0, $paddingY);

    @include PositionIcon("end", $paddingY, 0);
  }

  
}

@mixin containedColor($type, $color, $hover-color) {
  &.MuiButton-contained#{$type} {
    background-color: $color;

    &:hover {
      background-color: $hover-color;
    }

    &.Mui-disabled {
      background-color: $color;
      color: #ffffff;
      // cursor: not-allowed;
    }

    .MuiCircularProgress-root {
      //style for loading
      color: #ffffff;
    }
  }
}

@mixin outlineFlatColor($type, $color, $hover-color) {
  &.MuiButton-outlined#{$type},
  &.MuiButton-text#{$type} {
    color: $color;
    border-color: $color;

    &:hover {
      background-color: $hover-color;
    }

    &.Mui-disabled {
      color: $color;
      border-color: $color;
      // cursor: not-allowed;
    }

    .MuiCircularProgress-root {
      //style for loading
      color: $color;
    }
  }
}

.luc-Button {
  border-radius: 2px !important;
  box-shadow: none !important;
  letter-spacing: 0.46px !important;
  position: relative;

  .content-Loading {
    display: flex !important;
    visibility: hidden;
    flex-wrap: nowrap;
  }
  .MuiCircularProgress-root {
    //style for loading
    width: 18px !important;
    height: 18px !important;
    position: absolute;
  }

  &:hover {
    box-shadow: none !important;
  }


  @include sizeButton("Large", 14px, 8px, 42px , 15px);

  @include sizeButton("Small", 13px, 5px, 36px , 13px);

  //Primary with filled(contained)
  @include containedColor("Primary", $primary-color, $primary-hover-color);

  //Primary with outline(outlined) and flat(text)
  @include outlineFlatColor(
    "Primary",
    $primary-color,
    $primary-hover-outline-color
  );

  //error with filled(contained)
  @include containedColor("Error", $error-color, $error-hover-color);

  //error with outline(outlined) and flat(text)
  @include outlineFlatColor("Error", $error-color, $error-hover-outline-color);

  //Secondary with outline(outlined) and flat(text)
  @include outlineFlatColor(
    "Secondary",
    $Secondary-color,
    $Secondary-hover-outline-BG-color
  );
}
