@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.report-delivery-selected-emails-wrapper {
  margin-top: 10px;
  button#report-selected-email-chip {
    text-transform: lowercase !important;
    border-radius: 20px;
    margin: 4px 4px 4px 0;
    @include ar {
      margin: 4px 0 4px 4px;
    }
    p {
      font-size: 13px;
    }
    svg {
      font-size: 14px;
    }
    .MuiButton-icon.MuiButton-endIcon {
      margin-inline-start: 3px;
      margin-inline-end: 0;
    }
  }
}

.report-date-select-input-wrapper {
  .report-date-select-input-label {
    color: #0f172a;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
    @include ar {
      font-family: "Dubai";
      font-size: 16px;
    }
  }

  #report-date-select-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #000000;
    border: 1px solid #c4c4c4;
    padding-inline: 14px;
  }
}

.report-date-select-dialog-wrapper {
  .MuiDialogActions-root {
    display: none;
  }

  .report-date-select-dialog-body {
    direction: ltr;
    @include ar {
      direction: ltr;
    }

    .report-date-select-dialog-body-content {
      width: 100%;
    }

    .report-date-select-dialog-action-btns-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

label.report-monthly-recurrence-select-input-label {
  color: #0f172a;
  font-weight: 400;
  font-size: 14px;
  @include ar {
    font-family: "Dubai";
    font-size: 16px;
  }
}

label#radio-button-on-spec-day {
  margin-inline: 0;
}
label#radio-button-every {
  margin-inline: 0;
}
