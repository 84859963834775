.faqs-list {
  margin-bottom: 60px;

  .Mui-expanded {
    margin: 0;
  }
  .MuiAccordion-rounded::before {
    display: none;
  }
  .MuiAccordion-root {
    margin-bottom: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  .MuiCollapse-wrapper {
    border-top: 1px solid #e2e8f0;
  }

  .MuiAccordionSummary-root {
    height: 67px;
    background: #ffffff;
  }

  .MuiAccordionSummary-contentGutters .MuiTypography-subtitle2 {
    color: #334155;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.15px;
  }

  .Mui-expanded.MuiAccordionSummary-contentGutters .MuiTypography-subtitle2 {
    color: #456EB2 ;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
  .MuiAccordionDetails-root .complex-faq {
    padding: 0px 26px 26px 26px;
  }

  .MuiAccordionDetails-root .simple-faq {
    padding: 14px 26px 20px 26px;
  }

  .MuiAccordionDetails-root .MuiTypography-h6 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .MuiAccordionDetails-root .MuiTypography-subtitle2 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .MuiAccordionDetails-root .MuiTypography-body2 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    line-height: 21px;
    letter-spacing: 0.15px;
  }
}
@mixin ar {
  html[lang="ar"] & {
    .faqs-list {
      .Mui-expanded.MuiAccordionSummary-contentGutters .MuiTypography-subtitle2 {
        font-weight: 700;
      }
    }
  }
}
