@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

$MainBorderColor: #dcdcdc;

#company-branding {
  .company-branding-header {
    #main-pages-header {
      margin-bottom: 8px;
    }
    .description {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.6);
      span {
        font-size: 14px;
      }
    }
  }

  .branding-items-container {
    padding: 40px;
    border: 1px solid #e2e8f0;
    .container-contant {
      display: flex;
      gap: 20px;
      margin-bottom: 30px;
      .branding-item {
        &:not(:last-child) {
          padding-bottom: 16px;
          margin-bottom: 16px;
          border-bottom: 1px solid #f1f5f9;
        }
        .item-content {
          width: 341px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          gap: 20px;
          &.colors-item {
            align-items: flex-start;
          }

          .logo-image-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 341px;
            box-sizing: border-box;
            border: 1px dashed #cbd5e1;
            border-radius: 4px;
            padding: 12px;
            .branding-logo-info {
              display: flex;
              align-items: center;
              gap: 16px;
              > div:first-child {
                width: 40px;
                height: auto;
                .logo-image {
                  height: auto;
                  width: auto;
                }
              }
              .logo-info {
                .file-name {
                  font-size: 14px;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.87);
                  margin: 0;
                }
                .file-size {
                  font-size: 12px;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.6);
                  margin: 0;
                }
              }
            }

            .luc-Button.MuiButton-sizeSmall svg {
              font-size: 16px;
            }
          }
          .dropzone {
            width: 341px;
            box-sizing: border-box;
            border: 1px dashed #cbd5e1;
            border-radius: 5px;
            padding: 20px;
            margin-bottom: 5px;
            cursor: pointer;
            &:hover {
              background-color: rgba(241, 245, 249, 1);
            }
            .image-upload {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              color: #456eb2;
              font-weight: 500;
              font-size: 13px;
            }
          }
          .logo-error {
            color: #e50035;
            font-weight: 400;
            font-size: 12px;
            margin-top: 10px;
          }
          .fonts-select {
            width: 100%;
            > div {
              @include ar {
                padding-left: 42px;
                padding-right: 15px;
              }
            }
            .fonts-rendered-value {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: var(--branding-font-family) !important;
              @include ar {
                flex-direction: row-reverse;
              }
            }
          }
          .color-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            &:last-child {
              align-items: flex-start;
            }
            .title {
              display: flex;
              flex-direction: column;
              font-weight: 400;
              font-size: 14px;
            }
            .color-picker-container {
              border: 1px solid $MainBorderColor;
              border-radius: 6px;
              width: 184px;
              .MuiInputBase-root {
                padding: 0px;
                @include ar {
                  flex-direction: row-reverse;
                }
                .MuiInputAdornment-root {
                  margin: 0px;
                }
                > input {
                  direction: ltr;
                  font-size: 14px;
                }
              }
              .color-input {
                border-radius: 0px;
                padding: 8px;
                cursor: default;
                .color-rectangle {
                  > div {
                    cursor: pointer;
                    border: 1px solid $MainBorderColor;
                    right: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 2px;
                  }
                }
              }
              fieldset {
                border: none;
              }
            }
            .color-opacity-boxs-select {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0 8px 8px;
              padding-top: 8px;
              border-top: 1px solid $MainBorderColor;

              .color-opacity-box {
                padding: 5px;
                border-radius: 6px;
                border: 1px solid $MainBorderColor;
                cursor: pointer;
                div {
                  width: 20px;
                  height: 20px;
                  border: 1px solid $MainBorderColor;
                  border-radius: 2px;
                }
                &.checked {
                  border: 2px solid #456eb2;
                }
              }
            }
          }
        }
      }

      .item-heading {
        margin-bottom: 8px;
        .branding-head {
          color: #000000de;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .action-branding-items {
        width: fit-content;
      }

      .preview-branding-item {
        width: 100%;
        padding: 12px 20px 32px;
        background-color: #f8fafc;
        border: 1px solid #f1f5f9;
        .item-heading {
          margin-bottom: 24px;
        }
        .slide-container {
          border: 1px solid #cbd5e1;
          border-radius: 4px;
          .slide {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;
            padding: 20px;
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              border: none;
              background: none;
              .logo {
                display: flex;
                align-items: center;
                width: 110px;
                height: 30px;
                img {
                  max-width: 110px;
                  max-height: 30px;
                  &.no-logo {
                    visibility: hidden;
                  }
                }
              }
              .title {
                font-family: inherit !important;
                font-weight: 900;
                font-size: 17px;
                @include ar {
                  font-family: var(--branding-font-family) !important;
                }
              }
              .date {
                font-family: inherit !important;
                font-size: 8px;
                color: #000;
                width: 110px;
                text-align: end;
                @include ar {
                  font-family: var(--branding-font-family) !important;
                }
              }
            }
            .sub-header {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 30px;
              .sub-header-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
                margin: 0px;
                width: auto;
                .number {
                  font-family: inherit !important;
                  font-weight: 900;
                  font-size: 14px;
                  @include ar {
                    font-family: var(--branding-font-family) !important;
                  }
                }
                .description {
                  font-family: inherit !important;
                  font-size: 10px;
                  font-weight: 400;
                  text-wrap: nowrap;
                  @include ar {
                    font-family: var(--branding-font-family) !important;
                  }
                }
              }
            }
            .slide-chart {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
    }

    .container-footer {
      border-top: 1px solid #e2e8f0;
      padding-top: 24px;
    }
  }

  h3 {
    margin-block-end: inherit;
  }
}
.remove-logo-popup .MuiPaper-root {
  min-width: 600px;
}

#branding-font-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include en {
    padding-right: 20px;
  }
  @include ar {
    flex-direction: row-reverse;
    padding-left: 25px;
  }
  > span {
    font-family: inherit !important;
  }
}
