@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.manage-audience-title {
  margin: 50px 0px;
  h3 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  span {
    color: #696d72;
    font-size: 15px;
    font-weight: 400;
  }
}

.manage-audience-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .back-audience-manage {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    color: #80868c;
    cursor: pointer;
    padding: 0;
  }
  .profile-icon {
    margin-inline-end: 5px;
  }
  @include en {
    .profile-icon {
      transform: rotate(0deg);
    }
  }

  @include ar {
    .profile-icon {
      transform: rotate(180deg);
    }
  }
}
