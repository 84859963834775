.deleted-alert-view-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  inset: 0;
  svg {
    font-size: 64px;
    font-weight: 300;
    color: #cbd5e1;
  }
  .MuiTypography-root {
    margin: 15px 0px;
    color: #475569;
    font-size: 24px;
    font-weight: 600;
  }
}
.deleted-alert-desc {
  font-size: 20px;
  font-weight: 400;
  color: #475569;
  text-align: center;
  .alerts-link {
    color: #456eb2;
  }
}
.deleted-survey-alert-desc {
  font-size: 20px;
  text-align: center;

  .deleted-survey-alert-span {
    font-weight: 400;
    color: #475569;
  }

  .alerts-link {
    color: #456eb2;
    display: inline;
  }
}