@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.model-buttons {
  margin: 0px 5px 0px 5px !important;

  button {
    margin: 0px 0px 10px 0px !important;
    margin-inline-end: 8px !important;
  }
}

.menu-list {
  overflow-y: auto !important;
  .menu-items {
    min-width: 24px;
    height: 24px;
    padding: 15px 8px !important;
  }
  .ellipsis-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
  .open-button {
    background-color: #cbd5e1;
    border-radius: 4px;
    .ellipsis-icon {
      color: #757575;
    }
  }
  .disable-drop-menu {
    cursor: not-allowed;
  }
}
#alert-dialog.model-resume {
  .MuiDialog-container {
    > .MuiPaper-elevation {
      width: 100%;
    }
  }
}
#alert-dialog-description {
  p {
    font-size: 14px;
    color: #000;
    margin-bottom: 0;
    margin-top: 0;
    + p {
      margin-top: 5px;
    }
  }
  .subText {
    color: #64748b;
  }
}

#monitor-menu,
#monitor-menu-dropdown,
#audience-menu-dropdown {
  @include en {
    .MuiMenu-paper {
      transform: translate(-72%, 4px) !important;
    }
  }
  @include ar {
    .MuiMenu-paper {
      transform: translate(0, 4px) !important;
    }
  }

  li {
    font-size: 16px;
    color: #212121;
    &:hover {
      background-color: #fafafa !important;
    }
    svg {
      margin-inline-end: 10px;
      color: #212121;
    }
  }
}

.manage-channels-dropdown {
  li {
    font-size: 16px;
    color: #212121;
    font-weight: 400;
    svg {
      margin-inline-end: 10px;
      color: #212121;
    }
  }
  @include en {
    .MuiMenu-paper {
      transform: translate(-47%, 4px) !important;
    }
  }
  @include ar {
    .MuiMenu-paper {
      transform: translate(0, 4px) !important;
    }
  }
}
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-root.monitor-menu-live-dashboard {
  padding-inline-start: 23px;
}
