@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
#user_management {
  .user-management-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #settings-user-managment-table-add-user-btn {
    svg {
      color: #fff;
    }
  }

  .upgrade-snackbar-alert {
    &.MuiPaper-root {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #2196f3;
      border: 1px solid #cbd5e1;
      border-radius: 4px;
      margin-bottom: 20px;
      .MuiAlert-message {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        a {
          color: #2aaae2;
          text-decoration: underline;
        }
      }
    }
  }

  .user-mange-profile-img {
    border: 1px solid #456eb2;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .user-info-style {
    @include ar {
      position: relative;
      right: 15px;
    }
  }
  .user-management-head {
    span,
    p {
      color: #456eb2;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .user-management-email {
    span,
    p.MuiTypography-root {
      color: rgba(128, 134, 140, 1);
      font-size: 12px;
      font-weight: 400;
    }
  }

  .main-permissions-products {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px 0;
    align-items: center;
    .permissions-product {
      padding: 3px 2px;
      display: flex;
      align-items: center;
      gap: 4px;
      color: #334155;
      font-size: 11px;
      font-weight: 500;
      border-radius: 2px;
      background-color: #f8fafc;
      img {
        max-height: 16px;
      }
      .permissions-product-role {
        color: #64748b;
      }
    }
  }

  .users-teams {
    display: flex;
    color: #000000de;
    font-size: 14px;
    span,
    div {
      display: flex;
      color: #000000de;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .empty-users-teams {
    color: #00000099;
    font-size: 14px;
    font-weight: 400;
  }

  .error-message-actions-user-page {
    font-weight: 500;
    font-size: 18px;
  }

  .empty-data-box {
    max-width: 500px;
  }

  h3 {
    font-weight: 502;
  }
  td {
    padding: 8px 1px;
    font-size: 11px;
  }

  th p {
    margin-block: auto;
    font-weight: 500;
    font-size: 12px;
  }
  th span {
    font-weight: inherit;
    font-size: inherit;
  }

  #no-data {
    height: 398px;
    text-align: center;
    position: relative;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-weight: 502;
    }
  }

  span {
    font-weight: 100;
    color: #475569;
  }

  .perm-name::after {
    content: "|";
    margin: 2px;
  }
  .perm-name:last-child::after {
    content: "";
  }
  h4 {
    margin-block: auto;
    color: #1e293b;
    font-weight: 400;
    font-size: 12px;
  }

  .users-search-input {
    .MuiOutlinedInput-root {
      padding: 0;
    }

    .MuiOutlinedInput-input {
      height: 40px;
      box-sizing: border-box;
      width: 282px;
      background-color: transparent;
      color: #64748b;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.15px;

      &::placeholder {
        color: #00000099;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.15px;
        margin: 10;
      }
      @include ar {
        padding-right: 12px;
      }
    }
    .MuiInputAdornment-root {
      margin: 0;
    }
    .MuiButtonBase-root {
      color: white;
      border-radius: 4px;
      box-shadow: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding: 0px 0px;
      color: #cbd5e1;
      background-color: #7f7f7f;
      height: 40px;
      box-sizing: border-box;
      width: 40px;

      &:hover {
        background-color: #7f7f7f;
      }

      @include ar {
        left: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12);

        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.26);
        }
      }

      svg {
        color: #fff;
      }
    }
  }
}

.user-management-options-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.user-management-avatar-wrapper {
  img,
  .user-avatar-default-img {
    border: 1px solid #64748b;
    min-width: 32px;
  }
}
