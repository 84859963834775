@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.main-step-three {
  .main-step-tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .teams-subtitle {
      margin-top: 26px;
    }

    .no-teams-subtitle {
      margin-bottom: 20px;
      color: #64748b;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
