.all-applied-filters {
  .applied-filter-item {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-top: 2px;
  }
  .filter-name.MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;
    color: #676767;
    text-wrap: nowrap;
  }
  .filter-value.MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;
    color: #212121;
    text-align: end;
  }
}
#filters-content-tooltip {
  z-index: 9999999 !important;
  > div {
    padding: 13px 13px 18px !important;
    background-color: rgba(97, 97, 97, 0.9) !important;
  }
}

.filters-title-tooltip.MuiTypography-root {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  margin-bottom: 10px;
}
.filters-title-val-tooltip.MuiTypography-root {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
.all-applied-filters .filter-value.MuiTypography-root.question-val {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 70%;
  overflow: hidden;
  text-align: end;
}
