@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.view-team-container {
  padding: 0 !important;

  .view-team-header {
    margin-bottom: 19px;

    .view-team-header-description {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    #main-pages-header-with-breadcrumb {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
      color: #0c192a;
    }

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .view-team-header-breadcrumb {
      display: flex;
      flex-direction: column;
      gap: 9px;
    }
  }

  .view-team-main-container {
    margin-top: 20px;
    margin-bottom: 40px;

    .view-team-details-cards {
      .view-team-details-item {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .view-team-card {
        padding: 24px;
        border-radius: 5px;
        border: 1px;
        gap: 11px;
        border: 1px solid #e2e8f0;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        flex: 1;
        box-shadow: 0px 4px 8px 0px #00000014;

        .view-team-card-title {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0.06em;
          color: #64748b;
          text-transform: uppercase;
        }

        .view-team-card-content {
          font-size: 16px;
          font-weight: 500;
          line-height: 30px;
          color: #1e293b;
          direction: ltr;
          @include ar {
            text-align: right;
          }
          &.content-turncate {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0;
          }
        }

        .view-team-card-desc {
          display: flex;
          gap: 8px;
          align-items: center;

          .view-team-card-info {
            flex: 1;
            display: flex;
            flex-direction: column;

            .view-team-card-info-name {
              font-size: 16px;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 0px;
              color: #000000de;
            }

            .view-team-card-info-email {
              color: #00000099;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0px;
            }
          }

          .view-team-card-img {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            border: 1px solid #1018281a;
            overflow: hidden;
            box-sizing: border-box;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    .view-team-details {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .view-team-details-section {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      &-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.085em;
        color: #475569;
        text-transform: uppercase;
      }

      &-content {
        border: 1px solid #e2e8f0;
        background: #fff;
        box-shadow: 0px 4px 8px 0px #00000014;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        &-empty {
          height: 260px;
          display: flex;
          width: 220px;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          gap: 20px;
          margin: 0 auto;
          .team-group-icon {
            width: 67.5px;
            height: 47.25px;
            path {
              fill: #d1d1d1;
            }
          }
          &-text {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: #00000099;
            text-align: center;
          }
          &-link {
            color: #456eb2;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.delete-team-modal {
  width: 600px;
  h2.MuiTypography-root {
    padding: 24px 24px 15px 24px;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: #000000de;
  }
  &-body-message {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000de;
  }
  div.MuiDialogContent-root {
    padding: 0px 24px 10px 24px;
  }

  div.MuiDialogActions-root {
    padding: 15px 24px 24px 24px;
  }
}
.view-team-working-hours-container {
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.0784313725);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.delete-team-with-ai-agents-confirm {
  font-weight: 600;
  margin: 0;
  color: #000000de;
  font-size: 16px;
}
