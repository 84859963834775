@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin falcon-env-en {
  html[env="falcon"][lang="en"] & {
    @content;
  }
}
@mixin falcon-env-ar {
  html[env="falcon"][lang="ar"] & {
    @content;
  }
}
.add-summary-wrapper {
  padding: 20px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #e8eaf3;
  background-color: #ffffff;
  z-index: 1;
  &::after {
    position: absolute;
    inset-inline-end: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    width: 323px;
    height: 160px;
    z-index: -1;
    @include ar {
      background-image: url("../../../../../../images/summary-widget/add-summary-bg-ar.svg");
    }
    @include en {
      background-image: url("../../../../../../images/summary-widget/add-summary-bg-en.svg");
    }
    @include falcon-env-en {
      background-image: url("../../../../../../images/falcon/add-summary-bg-en-falcon.svg");
    }
    @include falcon-env-ar {
      background-image: url("../../../../../../images/falcon/add-summary-bg-ar-falcon.svg");
    }
  }
}

.add-summary-heading {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  .add-heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #000000de;
    margin: 0;
  }
}
.add-summary-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  color: #00000099;
  margin: 0 0 10px;
  span {
    display: block;
  }
}
.add-summary-hint {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  .add-hint {
    color: #00000099;
  }
  .no-hint {
    color: #000000de;
  }
  img {
    width: 13px;
    height: 13px;
  }
}
.add-summary-tooltip {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: inline-block;
  padding: 10px 13px;
  max-width: 242px;
}
.main-add-summary-wrapper {
  padding-bottom: 50px;
}
.main-settings-heading {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #475569;
  margin: 10px 0;
  text-transform: uppercase;
}
.main-add-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #000000de;
  margin: 20px 0;
}
