@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.single-custom-dateRange {
  width: 320px;
  .data-range-label {
    .label,
    svg {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .required {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: #f44336;
    }
    svg {
      color: #757575;
      padding-inline-start: 10px;
    }
  }

  .single-input-dateRange-picker {
    position: relative;
    .MuiFormControl-root {
      border: 1px solid #0000003b;
      border-radius: 4px;
      cursor: pointer;
      &:has(.Mui-focused) {
        border: 2px solid #456eb2;
      }
      .MuiInputBase-formControl {
        input {
          padding: 8px 0px 8px 36px;
          color: #000000de;
          @include ar {
            padding: 8px 24px 8px 0px;
          }
        }
      }
    }
    fieldset {
      border: none;
    }
    svg {
      position: absolute;
      color: #000000de;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      top: 57%;
      left: 12px;
      right: auto;
      z-index: 999;
      transform: translateY(-50%);
      @include ar {
        right: 12px;
        left: auto;
      }
    }
  }
  &:has(.single-input-dateRange-picker-error) {
    .MuiFormControl-root,
    .MuiFormControl-root:has(.Mui-focused) {
      border-color: #f44336;
    }
  }
  .single-input-dateRange-picker-error {
    color: #f44336;
    padding-inline-start: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
  }
}
.MuiDateRangeCalendar-root {
  @include ar {
    direction: ltr;
  }
}
