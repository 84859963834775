.not-found-custom-dashboard-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  .not-found-empty-box{
    width: 435px;
    line-height: 22px;
    text-align: center;
    a{
      margin-inline-start: 4px;
    }
  }
  img{
    margin-bottom: 20px;
  }
  .not-found-custom-dashbourd-page-header {
    font-weight: 600;
    font-size: 20px;
    color: #000000DE;
  }
  .try-access-anther-custom-dashbourd {
    font-size: 15px;
    color: #64748B;
    font-weight: 400;
  }
  a{
    color: #456EB2 ;
    font-weight: 500;
  }
}
