@mixin en {
    html[lang="en"] & {
      @content;
    }
  }
  @mixin ar {
    html[lang="ar"] & {
      @content;
    }
  }

#pdf-content-parent-custom-dashboard {
    z-index: -99;
    opacity: 0;
    position: absolute;
    top: -1000%;
    left: -1000%;
    
    * {
        //for loom extension with Google chrome
        letter-spacing: normal;
      }
    
    .main-pdf-contant-dashboard{
        width: 210mm; /* A4 width */
        min-height: 297mm; /* A4 height 1122px*/
        background-color: #fff;
        padding: 40px 48px 110px;
        position: relative;
        

        .main-pdf-header-text{
            margin-bottom: 74px;
        }

        .main-title-pdf-dashboard{
            margin-bottom: 89px;
            .title{
                font-size: 44px;
                font-weight: 700;
                margin: 74px 0px 0px 0px;
                line-height: 62.92px;
            }
            .description{
                font-size: 16px;
                font-weight: 400;
                width: 535px;
                line-height: 19.2px;
                @include ar{
                    line-height: 24px;
                }
            }
        }

        .sub-section-pdf{
            font-size: 15px;
            font-weight: 500;
            line-height: 21.45px;
            margin-bottom: 92px;
            .section-title-dashboard{
                margin-bottom: 17px;
            }
            .details-section{
               font-weight: 400;
               .special-operator-between-monitors {
                font-weight: 600;
                margin-inline: 8px;
                &.from-keyword{
                    margin-inline-start: 0;
                }
               }

               
               li.details-section-monitor {
                margin-bottom: 6px;
                list-style: none;
                
                span {
                    display: inline-block;
                    margin-inline-end: 24px;
                }
                .monitor-name-dashboard{
                    width:200px;
                    position: relative;
                    padding-inline-start: 15px;
                    &::after{
                        content:"";
                        position: absolute;
                        top:calc(50% - 2px);
                        left:0;
                        width:4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: #000;
                        @include ar{
                            right:0;
                        }

                    }
                }
                .monitor-type-dashboard{
                    width:160px;
                }
               }
            }

        }
    }

    #main-pdf-contant-dashboard-widgets-page{
        max-width: 210mm;  /* A4 width */
        min-height: 297mm; /* A4 height 1122px*/
        padding: 40px 48px;
        position: relative;

        .main-pdf-header-text{
            margin-bottom: 40px;
        }

        #main-pdf-contant-dashboard-widgets{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;
            & > div {
                width: 100%;
                border: 1px solid #E2E8F0;
                box-shadow: 0px 4px 8px 0px #00000014;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .half-width {
                width: calc(50% - 15px);
            }
        }
        
        
        
        
    }

    .main-pdf-header-text{
        height: fit-content;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #232152;
        font-size: 13px;
        font-weight: 400;
        line-height: 19.59px;
        border-bottom: 6px solid #232152;
        margin-bottom: 74px;
        padding-bottom: 9px;
        img {
            width: 100px;
        }
        .pdf-header-text-dashboard{
            line-height: 18.59px;
            margin: 0;
        }
    }

    .fixed-border-bottom {
        width: calc(100% - 96px);
        height: 6px;
        background-color: #232152;
        position: absolute;
        bottom: 40px;
    }
  }
  