@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin flexbox(
  $display: flex,
  $direction: row,
  $placeContent: null,
  $placeItems: null,
  $wrap: nowrap,
  $shrink: 0,
  $grow: 0,
  $alignContent: null,
  $justifyContent: null,
  $alignItems: null,
  $justifyItems: null
) {
  display: $display;
  flex-direction: $direction;

  @if $placeContent == null {
    @if $alignContent {
      align-content: $alignContent;
    }
    @if $justifyContent {
      justify-content: $justifyContent;
    }
  } @else {
    place-content: $placeContent;
  }

  @if $placeItems == null {
    @if $alignItems {
      align-items: $alignItems;
    }
    @if $justifyItems {
      justify-items: $justifyItems;
    }
  } @else {
    place-items: $placeItems;
  }

  flex-wrap: $wrap;
  flex-shrink: $shrink;
  flex-grow: $grow;
}
///////////////////////////
.luci-request-header,
.luci-request-token,
.luci-request-tokens {
  @include flexbox($alignItems: center, $justifyContent: space-between);
}
.luci-request-token {
  padding-inline-end: 10px;
  font-size: 14px;
  color: #212121;
  font-weight: 400;
  img {
    padding-inline-start: 3px;
  }
}
.luci-request-tokens {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
}
.luci-request-wrapper {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  padding: 20px;
  margin: 15px 20px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background: #fafafa;
    border: 1px solid #e9e9e9;
  }
}
.luci-request-img {
  @include flexbox($alignItems: center, $justifyContent: center);
  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
}
.luci-request-date {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  margin-inline-start: 8px;
  @include ar {
    direction: ltr;
    margin-right: 8px;
  }
}
.luci-request-desc {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 15px;
}
.luci-request-product {
  color: #676767;
  font-size: 14px;
  font-weight: 400;
}
////request status colors
.luci-request-status {
  font-size: 12px;
  font-weight: 400;
  border-radius: 3px;
  padding: 2px 4px;
  &.luci_done {
    color: #439e34;
    background: rgba(90, 173, 76, 0.05);
  }
  &.luci_in_progress {
    color: #eb8f24;
    background: rgba(235, 143, 36, 0.05);
  }
  &.luci_failed {
    color: #eb3a3a;
    background: rgba(235, 58, 58, 0.05);
  }
}
.luci-img-username {
  .luci-img-char {
    width: 20px;
    height: 20px;
    background: linear-gradient(0deg, #ba8fff, #ba8fff);
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.luci-text-arabic {
  text-align: right;
}
