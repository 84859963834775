@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.rateing-container {
  display: flex;
  align-items: center;
  .rate-title {
    color: #456eb2;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    @include ar {
      margin-inline-end: 8px;
    }
  }
  .MuiRating-icon.MuiRating-iconFilled {
    svg {
      width: 20px;
      height: 20px;
      color: rgb(42, 170, 226);
    }
  }
  .MuiRating-icon.MuiRating-iconEmpty {
    svg {
      width: 20px;
      height: 20px;
      color: rgba(42, 170, 226, 0.26);
    }
  }
  .rated-text {
    svg {
      @include ar {
        transform: rotate(180deg);
      }
    }
  }
  .rated-text.rated-color-1 {
    color: #e31b0c;
    svg {
      stroke: #f88078;
    }
  }
  .rated-text.rated-color-2 {
    color: #f88078;
    svg {
      stroke: #f88078;
    }
  }
  .rated-text.rated-color-3 {
    color: #f9a700;
    svg {
      stroke: #fceac5;
    }
  }
  .rated-text.rated-color-4 {
    color: #4caf50;
    svg {
      stroke: #4caf50;
    }
  }
  .rated-text.rated-color-5 {
    color: #3b873e;
    svg {
      fill: #7bc67e;
      stroke: #7bc67e;
    }
  }

  .rated-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    svg {
      margin-inline-end: 6px;
    }
  }
  .rate-d-none {
    display: none;
  }
  .rate-d-flex {
    display: flex;
    align-items: center;
  }
}
