.live-dashboard-dialog{
  .MuiDialogContent-root.live-dashboard-dialog-content{
    padding: 0;
    position: relative;
  }
  .live-dashboard-dialog-action{
    padding: 0 30px 30px;
    gap: 8px;
  }
  .live-dashboard-close{
    position: absolute;
    top: 14px;
    inset-inline-end: 20px;
    color: #CBD5E1;
    font-size: 20px;
    cursor: pointer;
  }
  .live-dashboard-img{
    width: 100%;
    height: auto;
  }
  .live-dashboard-box{
    display: flex;
    justify-content: space-between;
    padding: 30px;
    .live-dashboard-heading{
      width: 33%;
      color: rgba(0, 0, 0, 0.87);
      font-size: 22px;
      font-weight: 800;
      margin: 0;
    }
    .live-dashboard-description-box{
      width: 49%;
      .live-dashboard-description{
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.87);
        margin: 0 0 20px;
      }
      .live-dashboard-list{
        padding-inline-start: 0;
        list-style: none;
        li{
          font-size: 14px;
          color: rgba(0, 0, 0, 0.87);
          display: flex;
          gap: 8px;
          margin-bottom: 6px;
          svg{
            color: #4CAF50;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
.live-dashboard-snackbar-wrapper{
  max-width: 254px;
}
.live-dashboard-snackbar-heading{
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.live-dashboard-snackbar-description{
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
}