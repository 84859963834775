@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.manage-audience-tab {
  &.MuiButtonBase-root {
    &.Mui-selected {
      font-weight: 700;
      color: #000000de;
      border-bottom: 3px solid #456eb2;
    }
  }
}

.manage-audience-tabs {
  margin-bottom: 20px;
}

.audience-table-head {
  > th {
    text-transform: uppercase !important;
  }
}

.segment-empty-item {
  color: #64748b;
  height: 441px;
  background: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    margin-bottom: 10px;
  }
  span {
    font-size: 16px;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0px;
  }
}

.action-btn-wrapper {
  &.MuiMenuItem-root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    &:first-child {
      margin-top: 5px;
    }
  }
  &.manage-audience {
    padding-top: 15px;
    border-top: 1px solid #d4d7db;
  }
  .MuiButton-root {
    svg {
      color: rgba(0, 0, 0, 0.87);
      font-size: 15px;
      padding-bottom: 15px;
    }
    &:hover {
      background-color: transparent;
    }
  }
}
.manage-audience-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    padding-bottom: 10px;
  }
}
.actions-header-btn {
  &.MuiButton-root {
    padding: 5px 0px;
  }
}
.audience-action-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  padding: 0px 10px;
  line-height: 1.4;
  span {
    color: #696d72;
    font-size: 13px;
  }
}

.add-segment-btn {
  &.MuiButton-root {
    margin-left: auto;
    padding: 0px 15px;
    @include ar {
      margin-right: auto;
      margin-left: 0;
    }
  }
}

.audience-name-btn {
  font-weight: 500 !important;
  color: #456eb2 !important;
  .audience-name-text {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.import-table {
  .table-head {
    background: #f1f5f9;
    th {
      color: #475569;
      font-weight: 500;
      font-size: 16px !important;
      @include ar {
        font-size: 18px !important;
        font-family: "IBM Plex Sans Arabic" !important;
      }
    }
  }
  tbody {
    .MuiTableRow-root:nth-child(odd) {
      background: #ffffff;
    }
    .MuiTableRow-root:nth-child(even) {
      background: #f1f5f9;
    }
  }
  > div {
    box-shadow: none;
  }
  td,
  th {
    font-size: 14px !important;
    height: 63px;
    text-align: start;
  }
  td {
    color: rgba(0, 0, 0, 0.87);
  }
  td p {
    margin: 0;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.remove-pop-up {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Figtree" !important;
  strong {
    display: inline-block;
  }
}
.tb-status {
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  width: fit-content;
  padding: 0 12px;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &.tb-status-success {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      #4caf50;
    border: 1px solid rgba(76, 175, 80, 0.5);
    letter-spacing: 0.16px;
    color: #3b873e;
  }
  &.tb-status-failed,
  &.tb-status-cancelled {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      #f44336;
    border: 1px solid rgba(244, 67, 54, 0.5);
    text-align: center;
    color: #f44336;
  }
  &.tb-status-in_progress {
    background: rgba(237, 108, 2, 0.08);
    border: 1px solid rgba(237, 108, 2, 0.5);
    text-align: center;
    color: #ed6c02;
  }
  &.tb-status-processing {
    background: rgba(237, 108, 2, 0.08);
    border: 1px solid rgba(237, 108, 2, 0.5);
    text-align: center;
    color: #ed6c02;
  }
}

.btn-menu-list-import {
  background-color: transparent;
  text-align: center;
  width: 35px;
  height: 35px;
  button[aria-expanded="true"] {
    background: #cbd5e1;
  }
  button {
    width: 35px;
    height: 35px;

    border-radius: 4px;
    :focus,
    :active {
      background: #cbd5e1;
    }
  }
}
.collapce-toggle-parent {
  padding: 0 20px;
  .collapce-toggle {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 160%;
    color: #00000099 !important;
  }
  > .show-div {
    transition: 0.3s;
    overflow: hidden;
    &:hover {
      text-decoration: underline;
    }
  }
}
.view-num-errors {
  .file-name {
    display: flex;
    align-items: center;
    height: 40px;
    padding-inline-start: 12px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    margin-bottom: 20px !important;
    margin-top: 4px;
  }
  .file-name-container {
    margin-top: 12px;
  }
}
.pop-up-view-num-errors {
  min-width: 600px;
  .MuiDialogContent-root {
    border-top: 1px solid rgba(0, 0, 0, 0.23);
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    .MuiTypography-root {
      margin-bottom: 16px;
    }
  }
  .MuiDialogActions-root {
    padding: 16px 24px;
  }
  > div {
    padding: 0;
  }
  .collapce-toggle {
    transition: 0.5s;
  }
  .show-less-parent {
    border-bottom: 1px solid #cbd5e1;
    padding-bottom: 10px;
    margin-bottom: 25px;
  }
  .show-div {
    font-weight: 500;
    font-size: 13px;
    line-height: 160%;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6) !important;

    cursor: pointer;
    .show-less {
      transform: rotate(0deg) !important;
      display: inline-block;
    }
    svg {
      margin-inline-start: 8px;
      transition: 0.5s;
      transform: rotate(-180deg) !important;
    }
  }
  #alert-dialog-description {
    .text-view-num.MuiTypography-root {
      font-weight: 400;
      font-size: 16px;
      color: #000000de;
    }
    .num-view-num.MuiTypography-root {
      font-style: normal;
      font-weight: 400;
      @include en {
        direction: ltr;
      }
      color: #000000de;
      span {
        margin-inline-start: 3px;
      }
    }
  }
  .content-view-num-errors {
    padding: 0px 24px;
  }
}
.content-view-num-errors {
  .item-view-num-errors {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 16px;
    height: 52px;
    > p {
      margin: 0 !important;
    }
    .circle-icon {
      font-size: 12px;
      margin-inline-start: 3px;
    }
    &:nth-child(odd) {
      background: #ffffff;
    }
    &:nth-child(even) {
      background: #f8fafc;
    }
  }
}

#cdp-import-options-ddl {
  margin-top: 6px;
  border-radius: 4px;
  &[aria-expanded="true"] {
    background: #cbd5e1;
    .ellipsis-icon {
      color: #757575;
    }
  }

  text-align: start;
  ul {
    li {
      font-family: "Figtree";
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
.icon-drop-import {
  margin-inline-end: 10px;
  font-size: 14px;
  height: 21px;
}
.pop-up-delete-errors {
  button {
    border: none !important;
  }
}
.remove-pop {
  h2 {
    padding: 24px 24px 15px;
  }
  .remove-pop-up {
    padding: 0px;
    > strong {
      font-size: 15px;
      margin: 0;
    }
  }
  .MuiDialogActions-root {
    padding: 0 24px 24px;
    button,
    .MuiButtonBase-root {
      min-width: 70px;
      height: 42px;
      padding: 0;
      margin: 0;
    }
    button {
      margin-inline-start: 10px;
    }
  }
}
.btn-import.MuiButton-root {
  margin-inline-start: auto;
  padding: 8px 12px;
  width: 94px;
  height: 42px;
  background: #456eb2;
  font-size: 14px;
  border-radius: 2px;
  svg {
    font-size: 15px;
  }
}
#import-audience-btn.MuiButtonBase-root {
  &:hover {
    background-color: #456eb2;
  }
}

#cdp-import-options-ddl {
  border-radius: 4px;
  &[aria-expanded="true"] {
    background: #cbd5e1;

    .ellipsis-icon {
      color: #757575;
    }
  }
}

.audience-actions-menu {
  .menu-list {
    @include en {
      text-align: right !important;
    }
    @include ar {
      text-align: left !important;
    }
  }
}
