@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.inbound-routing-general-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .inbound-routing-general-info-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    color: #000000de;
  }
  &-form {
    display: flex;
    gap: 24px;
  }
}

.inbound-routing-conditions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .inbound-routing-conditions-desc {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      color: #000000de;
    }
    &-text {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #00000099;
    }
  }
  .inbound-routing-conditions-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-divider {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      &-line {
        flex: 1;
        height: 1px;
        background: #dadada;
      }
      &-text {
        padding: 3px 10px;
        border-radius: 16px;
        background: #e2e8f0;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.15px;
        color: #000000de;
      }
    }
    &-row {
      display: flex;
      gap: 15px;
    }
    &-item {
      flex: 1;
      &-helper-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #00000099;
        margin: 5px 0 0 12px;
        @include ar {
          margin: 5px 12px 0 0;
        }
      }
    }
    .icon-tab-button {
      height: 40px !important;
    }
    .inbound-routing-select-dropdown-input-placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #000000de;
      &.auto-comp-empty {
        @include ar {
          margin-right: 14px;
        }

        @include en {
          margin-left: 14px;
        }
      }
    }

    .input-field-with-shrink-label {
      .MuiInputBase-root.Mui-error {
        .add-btn-with-plus.MuiButton-root {
          border-color: #f44336;
          color: #f44336 !important;
        }
      }
    }
  }
}

.inbound-routing-select-dropdown-list-item {
  cursor: pointer;
  span {
    color: #000000de;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  svg {
    width: 18px;
    height: auto;
  }
  &.autocomplete-select-controll {
    display: flex;
    gap: 14px;
    align-items: center;
    padding: 6px 16px;
    &.languages-countries-autocomplete {
      gap: 10px;
    }
  }

  .inbound-routing-select-dropdown-list-social {
    display: flex;
    gap: 10px;
    align-items: center;
    &.platform-wrapper {
      gap: 0;
    }
    &-icon {
      margin-right: 10px;
      margin-left: 5px;
      @include ar {
        margin-right: 5px;
        margin-left: 10px;
      }
      &.facebook_private {
        margin-right: 7px;
        margin-left: 4px;
        @include ar {
          margin-right: 4px;
          margin-left: 7px;
        }
      }
    }
    .social-media-icon {
      margin: 0;

      .instagram-prvate-icon {
        color: #000000;
      }
      img.facebook-dm-icon {
        width: 20px;
        height: 20px;
      }
    }
    .acount-img-container {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      border: 1px solid #94a3b8;

      box-sizing: border-box;
      position: relative;
      .account-img-icon {
        position: absolute;
        bottom: -3px;
        right: -3px;
        width: 18px;
        height: 18px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .social-media-icon {
          svg.whatsapp {
            width: 16px;
            height: 16px;
          }
          img {
            width: 12px;
            height: auto;
            overflow: visible;
          }
          svg {
            width: 12px;
            height: auto;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .account-description-box {
      display: flex;
      flex-direction: column;
      &-handle {
        display: flex;
        gap: 5px;
        align-items: center;
        .verified-icon {
          margin-left: 4px;
          font-weight: 900;
          font-size: 12px;
          color: #5692d7;
        }
      }
      &-name {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.15;
        color: #000000de;
      }
      &-type {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #696d72;
        letter-spacing: 0.15;
      }
    }
  }
}
.team-and-slas-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .routing-select-team-and-slas {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 469px;
    width: 100%;
    &-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      color: #000000de;
    }
  }
  .routing-auto-assign-member {
    display: flex;
    flex-direction: column;
    gap: 28px;
    &-divider {
      height: 1px;
      background: #e2e8f0;
    }
    &-header {
      display: flex;
      align-items: end;
    }
    &-group {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1;
      .routing-auto-assign-member-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        color: #00000099;
      }
      .routing-auto-assign-member-checkbox {
        margin: 0;
        gap: 6px;
        .MuiTypography-root {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          color: #000000de;
        }
        &.disabled-label {
          .MuiTypography-root {
            color: #00000061;
          }
        }
      }
    }
    &-cards {
      display: flex;
      gap: 28px;
      &-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 18px 18px 24px 18px;
        border-radius: 4px;
        border: 1px solid #e2e8f0;
        background-color: #ffffff;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          border-color: #456eb2;
        }
        &.selected {
          border-color: #456eb2;
          .routing-auto-assign-member-cards-item-title {
            color: #456eb2;
          }
        }
        &.disabled {
          opacity: 0.5;
        }
        &-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #000000de;
        }
        &-desc {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          color: #00000099;
        }
      }
    }
  }
}
.routing-select-team-and-slas-autocomplet-paper {
  padding: 8px 0;
  .MuiAutocomplete-noOptions {
    padding: 0;
  }
  .routing-select-team-and-slas-autocomplet-listbox {
    &.routing-select-team-empty-box {
      .empty-list-message {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #00000061;
        padding: 6px 16px !important;
      }
    }
    li {
      padding: 6px 16px !important;
      cursor: pointer;
      &.Mui-focused {
        background-color: #fff !important;
      }
      &:hover {
        background-color: #f5f5f5 !important;
      }
      &[aria-selected="true"] {
        background-color: rgba(69, 110, 178, 0.12) !important;
      }
      &.add-new-team-option {
        display: flex;
        align-items: center;
        gap: 10px;
        .add-new-team-option-title {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0.15px;
          color: #456eb2;
        }
        svg {
          color: #456eb2;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      }
    }
  }
}

.team-and-slas-empty-list-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  svg {
    font-size: 20px;
    line-height: 27px;
    color: #00000099;
  }
}
