@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.monitor_filter__head {
  color: #456eb2;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-top: 7px;
  @include en {
    margin-left: -2px;
  }
  @include ar {
    margin-right: -2px;
  }
}
.footer__wrapper {
  box-sizing: border-box;
  position: fixed;
  bottom: 1px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  width: 340px;
  margin-inline-start: -20px;
  padding: 15px 10px 10px 20px;
  button {
    margin-right: 10px;
  }
}

.filterbody__wrapper {
  background-color: #fdfdfd;
  margin-bottom: 120px;
}

.monitor_filter__label {
  color: #525252 !important;
  font-weight: 400 !important;
  font-size: 13px !important;

  .Mui-focused {
    color: #696d72 !important;
  }
}

.monitor-filter-count {
  color: #5692d7;
  margin-inline-start: 5px;
}

.redux-tooltip-text {
  font-size: 14px;
  font-weight: 500;
  padding: 6px;
}
.monitor-header-filter-menu-icon {
  width: 18px !important;
  height: 16px !important;
  margin-inline-end: 6px;
  line-height: 18px;
}
html[lang="ar"] .filter__btn {
  svg {
    position: relative !important;
    left: 10px !important;
  }
}
.new__channel__filter {
  border-radius: 0 !important;
  padding-right: 14px;
  padding-left: 1px;
  svg {
    color: #456eb2 !important;
    padding-bottom: 5px;
  }
  &:hover {
    background-color: transparent !important;
  }
  &:disabled {
    color: #00000061 !important;
    font-weight: 600 !important;
    border-style: none;
    border-right: none !important;
    border-radius: 4px !important;

    svg {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
}
.new-channel-filter-disabled-hover {
  background: #0000001f !important;
  color: #00000061 !important;
  font-weight: 600 !important;
  border-style: none;
  border-right: none !important;
  border-radius: 4px !important;
  svg {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}
.seperator {
  border-left: 1px solid #9f9f9f;
  height: 35px;
}

.new-channel-span {
  @include en {
    margin-right: 18px;
  }
  @include ar {
    margin-left: 18px;
  }
}
html[lang="ar"] .new__channel__filter {
  border-right: 0 !important;
  svg {
    padding-left: 10px !important;
  }
}
html[lang="ar"] .apply__date {
  margin-right: 30px;
}
.filter_sort {
  color: #585858;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
}

.label-style {
  color: rgba(0, 0, 0, 0.6) !important;
}
.textfield-label label {
  top: -8% !important;
}
.textfield-label label.label-error {
  color: #f44336;
}

.textfield-label .Mui-focused {
  top: 0% !important;
}

.filter_line {
  color: #585858;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 30px;
  border-bottom: 1px solid #cbd5e1;
  @include en {
    margin-left: -20px !important;
    width: 340px !important;
  }
  @include ar {
    width: 340px !important;
    margin-right: -20px !important;
  }
}
.filter_dropdown {
  span {
    margin: 0px;
  }
  .MuiInputBase-input {
    padding: 10px;
  }
  .MuiSelect-select {
    font-weight: 400;
    font-size: 13px;
    color: #8d8d8d;
    padding: 8px;
  }
  .MuiSelect-select.MuiSelect-outlined {
    border: none !important;
  }
}

.check_box {
  position: relative;
  left: 5%;
  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }
  .MuiSvgIcon-root {
    font-size: 20px;
  }
}
.box-margin {
  margin: -20px 0px 40px 0px;
}
.monitor-filter-item {
  &.monitor-filter-item-analytics-container {
    fieldset.MuiOutlinedInput-notchedOutline {
      legend {
        span {
          @include ar {
            width: 101px;
          }
        }
      }
    }
  }
  .engagement-analytics-monitors {
    &-item-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 3px 0;
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &-type {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #696d72;
    }
    &-separator {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #696d72;
    }
    &-status {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #4caf50;
      text-transform: capitalize;
      &.paused {
        color: #64748b;
      }
    }
  }
  .monitor-filter-item-text-container {
    flex: 1;
    max-width: 240px;
  }
  .monitor-filter-item-text {
    font-size: 14px;
    color: rgb(51, 65, 85);
    margin: 0px;
    flex: 1;
  }
  .MuiInputLabel-root {
    @include en {
      transform-origin: bottom left !important;
    }
    @include ar {
      transform-origin: bottom right !important;
      right: 30px;
    }
  }
  @include ar {
    direction: rtl;
  }
  fieldset {
    min-width: 100px;

    @include ar {
      text-align: right;
    }
  }
  .MuiSelect-select {
    @include ar {
      padding-right: 10px;
      padding-left: 32px;
    }
  }
}

.filterbody__wrapper .filter_dropdown {
  .MuiList-root {
    @include ar {
      direction: rtl;
    }
  }
  @include ar {
    direction: rtl;
  }
  & + p {
    @include ar {
      text-align: right;
    }
  }
}
.filter-header-grid {
  background-color: transparent !important;
}
#insights-box {
  margin-top: 34px;
}

.inapplicable-filters-sidemenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f8fafc;
  border-radius: 4px;
  padding: 6px 16px;
  margin-bottom: 20px;
  .filter-sidemenu {
    span,
    svg {
      color: #64748b !important;
    }
    margin-bottom: 8px;
    border: 1px dashed #64748b !important;
    padding: 6px 11px !important;
  }
  .inapplicable-items {
    color: #64748b;
    font-weight: 400;
    font-size: 14px;
    display: block;
    .inapplicable-names {
      margin-inline-end: 5px;
      margin-bottom: 5px;
    }
  }
}

.empty-box {
  .white-blank-box {
    margin: 0px !important;
  }
}

.explore-text-filed-container {
  &.like_count {
    span {
      @include en {
        top: 15px;
      }
      @include ar {
        top: 17px;
      }
    }
  }
  &.retweet_count {
    span {
      @include en {
        top: 45px;
      }
      @include ar {
        top: 50px;
      }
    }
  }
  &.reply_count {
    span {
      @include en {
        top: 15px;
      }
      @include ar {
        top: 17px;
      }
    }
  }
  span {
    &.MuiTypography-root {
      position: absolute;
      transform: translateY(-50%);
      inset-inline-end: 35px;
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.explore-input-label {
  &.MuiTypography-root {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

.explore-text-field-input {
  .MuiInputBase-input {
    padding: 5px 12px;
    height: 25px;
    line-height: 32px;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 0px;
  }
  width: 100%;
}

.explore-text-field-input {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: textfield !important;
    opacity: 1;
  }
}
.retweet-count-title {
  margin-bottom: 10px;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 16px;
  color: rgba(82, 82, 82, 1);
}
.explore-tw-authors {
  margin-top: 30px;
  color: #525252;
  font-size: 16px;
  font-weight: 400;
}
.explore-engagement {
  color: rgba(88, 88, 88, 1);
  font-size: 13px;
  font-weight: 500;
}
.publish-text-filed-container {
  .publish-text-field-input {
    .MuiInputBase-root {
      height: 40px;
    }
    label {
      top: -7px;
    }
  }
  .MuiFormControl-root {
    width: 100%;
  }
}

span#active-filte-count.active-filter-change-count {
  font-weight: 700;
  color: #456eb2;
  @include en {
    margin-left: 4px;
  }
  @include ar {
    margin-right: 4px;
  }
}

.engagement-analytics-monitors-icons {
  display: flex;
  align-items: center;
  gap: 7px;
  justify-content: flex-end;
  flex: 1;

  .social-media-icon {
    margin: 0;
    .instagram-prvate-icon {
      color: #000000;
    }
    img.facebook-dm-icon {
      width: 20px;
      height: 20px;
      @include en {
        margin-right: -2px;
      }
      @include ar {
        margin-left: -2px;
      }
    }
  }
}

div.general-filters-monitors-tooltip {
  border-radius: 4px;
  max-width: 200px;
  text-wrap: initial;
  white-space: initial;
}

.engagement-analytics-monitors-info {
  .status-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    &.available {
      background-color: #4caf50;
    }
    &.not-available {
      background-color: #94a3b8;
    }
  }
}

.post-parent-id-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.post-parent-id-title.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 24px;
  letter-spacing: 0.15px;
}

.post-parent-id-helper-text.MuiTypography-root {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 14.4px;
  letter-spacing: 0.4px;
  margin-top: 10px;
}
