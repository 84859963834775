@mixin en {
    html[lang="en"] & {
      @content;
    }
  }
  @mixin ar {
    html[lang="ar"] & {
      @content;
    }
  }
  .full-text-tweet-container{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1999;
    height: calc(100vh - 60px);
    top: 60px;
  }

.full-text-tweet-popup{
    position: fixed;
    top: 60px;
    left: unset;
    right: 0;
    background-color: #fff;
    width: 380px;
    height: calc(100vh - 60px);
    overflow: auto;
    box-sizing: border-box;
    z-index: 2000;
    transform: none;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    @include ar {
        left: 0;
        right: unset;
    }
    .full-text-tweet-header{
        background-color: #F8FAFC;
        padding: 20px;
        border-bottom: 1px solid #E9E9E9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .full-text-tweet-header-title{
          color: #212121;
          font-weight: 600;
          line-height: 28px;
          margin: 0;
          padding: 0;
        }
        svg {
            color: #00000099;
            font-size: 24px;
            font-weight: 300;
            cursor: pointer;
        }
    }
    .full-text-tweet-body{
      margin: 20px;
      .full-text-tweet-contant{
        word-break: break-word;
        margin-block: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.45px;
        color: #000000DE;
      }
      .full-text-tweet-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #334155;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        padding: 5px;
        .full-text-tweet-statistics-icons{
          display: flex;
          align-items: center;
          .tweet-statistic{
            display: flex;
            margin-inline-end: 20px;
            svg{
              margin-inline-end: 3px;
            }
          }
          svg {
            color:#9E9E9E;
            font-size: 15px;
            font-weight: 900;
          }
        }
        hr{
          color: #E2E8F0;
        }
        .footer-full-text-tweet-date{
          display: flex;
          gap: 5px;
        }
      }
    }
    
}
