@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.chat-media-previewer-popup-outer {
  .MuiDialog-paperWidthSm.MuiPaper-root.MuiPaper-elevation {
    top: 24px;
    max-width: calc(100vw - 400px);
  }

  .chat-media-previewer-popup-inner {
    padding: 0;
    .chat-media-previewer-popup-content {
      display: flex;
      flex-direction: column;
      width: calc(100vw - 400px);
      height: calc(100vh - 200px);
      padding: 24px;
      gap: 16px;
      box-sizing: border-box;
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-close {
          cursor: pointer;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: #0000000a;
          }
          svg {
            font-size: 16px;
            color: #838383;
          }
        }
        &-title {
          color: #000000de;
          font-size: 16px;
          font-weight: 700;
          line-height: 12.64px;
        }
      }
      &-body {
        flex: 1;
        overflow: hidden;
        background: #eff1f3;
        border-radius: 4px;
        .chat-media-previewer-swiper {
          width: 100%;
          height: 100%;
          .swiper-button-next,
          .swiper-button-prev {
            @include ar {
              transform: rotate(180deg);
            }
            width: 35px;
            height: 35px;
          }
          .swiper-button-next {
            &::after {
              content: none;
            }
          }
          .swiper-button-prev {
            &::after {
              content: none;
            }
          }
          .chat-media-previewer-image,
          .chat-media-previewer-video {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background: #eff1f3;
          }
          .chat-media-previewer-video-container {
            position: relative;
            width: 100%;
            height: 100%;
            .play-button {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 50px;
              height: 50px;
              background: #00000073;
              transform: translate(-50%, -50%);
              border: none;
              color: white;
              cursor: pointer;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                width: 13.84px;
                height: 16.15px;
              }
              &:hover {
                background: #00000099;
              }
            }
          }
        }
      }
      &-footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow-x: auto;
        gap: 8px; /* Optional: to add space between items */
        width: 100%;
        // make the size of the scrollbar 6px

        &::-webkit-scrollbar {
          height: 6px;
        }
        .chat-media-previewer-video-container {
          position: relative;
          width: 100%;
          height: 100%;
          .play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            background: #00000073;
            transform: translate(-50%, -50%);
            border: none;
            color: white;
            cursor: pointer;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              width: 5.42px;
              height: 6.33px;
            }
          }
        }
        .chat-media-previewer-list-item {
          width: 49.58px;
          height: 69.58px;
          flex: 0 0 auto;
          border-radius: 2px;
          opacity: 0.5;
          overflow: hidden;
          border: 2px solid transparent;
          box-sizing: border-box;
          box-shadow: 0px 4px 8px 0px #0000000d;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          &.selected {
            opacity: 1;
            border: 2px solid #456eb2;
            img,
            video {
              border-radius: 0;
            }
          }
          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 2px;
          }
        }
      }
      p.post-content {
        overflow: hidden;
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding: 0;
      }
      p.post-content-ar {
        font-weight: 600;
        font-family: "Figtree";
      }
    }
  }
}
.chat-media-previewer-popup-content-footer {
  .chat-media-previewer-video {
    height: 100%;
    width: 100%;
  }
}
.play-button-video {
  position: absolute;
  top: 20%;
  left: 70%;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.4509803922);
  transform: translate(-50%, -50%);
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
