@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.report-input-wrapper {
  width: 100%;
  .report-input-label {
    color: #0f172a;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
    @include ar {
      font-family: "Dubai";
      font-size: 16px;
    }
  }

  .edit-report-text-input {
    .Mui-disabled {
      fieldset {
        border: 1px dashed rgba(0, 0, 0, 0.23);
      }
    }
  }

  .MuiFormHelperText-root {
    @include ar {
      direction: rtl;
      text-align: start;
    }
  }
}
