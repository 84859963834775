@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.arrow-icon {
  transform: rotate(90deg);
  color: rgba(0, 0, 0, 0.54);
}
.author-dropdown {
  height: 50px;
  .MuiOutlinedInput-root {
    height: 45px;
    width: 197px;
    background-color: #fff;
  }
}
.option-value {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px !important;
  padding-top: 2px;
  padding-left: 25px;
}
.authors-item {
  border: 1px solid #e2e8f0;
  margin-top: 27px;
  .authors-link {
    height: fit-content;
    .post-title-date {
      box-sizing: border-box;
      margin: -6px;
      border-radius: 4px;
      border: 6px solid transparent;
      .post-name-authors {
        direction: ltr;
      }
      &:hover {
        background: #0000000a;
        .post-name-authors {
          text-decoration-line: underline;
        }
      }
    }
  }
  .authors-user-img {
    position: relative;
    .authors-img {
      border-radius: 50%;
      display: block;
      img {
        object-fit: cover;
        border: 1px solid #e2e8f0;
        width: 38px;
        height: 38px;
        border-radius: 50%;
      }
    }
    .authors-icon {
      width: 18px;
      height: 18px;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 23px;
      border-radius: 50%;
      border: 2px solid #000;
      background-color: #000;
      display: flex;
      @include ar {
        left: auto;
        right: 23px;
      }
      .x-platform-img {
        max-width: 14px;
        max-height: 14px;
      }
    }
  }
}
.author-title {
  font-size: 15px;
  line-height: 22px;
  color: #334155;
}
.author-divider {
  border-bottom-width: 12px !important;
  margin: 0 5px !important;
  display: inline;
}
.response_data {
  // border-top: 1px dashed #cbd5e1;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  padding-top: 8px;
  color: #2aaae2;
}

.date_format {
  font-style: normal;
  color: #9e9e9e;
  padding-top: 2px;
  font-size: 12px !important;
}
.customer-report {
  display: flex;
  justify-content: space-between;
}
.response_header {
  display: flex;
  gap: 12px;
}

.response_colon {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  padding: 0px 7px 0px 7px;
  color: #80868c;
}

.response_title {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #64748b;
  border-bottom: 1px dashed #cbd5e1;
}

.author-font-icons {
  color: rgba(100, 116, 139, 0.6);
}
.author-body-subtitle-tooltip {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 13px;
  width: 100px;
  color: #64748b;
  border-bottom: 1px dashed rgba(100, 116, 139, 0.4);
}
.author-body-subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px !important;
  color: #00000099;

  @extend .author-body-subtitle-tooltip;
  border-bottom: none;
}
.author-body-title {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #334155;
}
.author-icons {
  background: #f1f5f9;
  border-radius: 4px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #000;
  }
  img {
    filter: brightness(0%);
  }
}
.authors-card {
  padding: 15px;
  margin: 0px 48px;
  width: 80%;
  border: 1px solid #e2e8f0;
  border-radius: 3px;
}

.fetching-data-style {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.authorsCard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: 500;
  color: #334155;
}

.author-header-icon {
  position: absolute;
  margin-left: 8px;
  margin-top: 8px;
  z-index: 1;
  @include ar {
    right: 5px;
  }
}

.total-interactions-style {
  display: flex;
  grid-auto-flow: column;
  margin-bottom: 25px;
}

.post-top-icons .menu-style {
  color: #64748b !important;
}

.authors-header-style {
  padding: 8px 0px 8px 8px;
}

.authors-margin {
  margin-top: 18px;
}
.author-wrapper {
  position: relative;
}
.author-details {
  padding-left: 10px;
  @include ar {
    padding-right: 10px;
  }
}

.select-monitor {
  @include ar {
    .MuiSelect-select {
      display: flex;
      justify-content: flex-start;
    }
    .MuiSvgIcon-root {
      right: auto;
      left: 5px;
    }
    label {
      transform: translate(105px, -9px) scale(0.75);
      background: #f8fafc;
      padding: 0 8px;
    }
    fieldset {
      legend {
        text-align: right;
        span {
          display: none;
        }
      }
    }
  }
  .MuiSelect-select {
    padding: 8.3px 11px;
  }
}
.menu-style {
  border-radius: 4px;
  padding: 2px;
}
.menu-style-open {
  background: rgba(0, 0, 0, 0.08);
}

.custom-dropdown {
  background-color: #fff;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 0px 8px 0px;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 43px;
  right: 8px;
  min-width: 185px;
  z-index: 99;
  .MuiListItemIcon-root {
    min-width: 34px;
  }
  @include ar {
    right: unset;
    left: 0;
  }
}

.custom-dropdown-items {
  padding: 6px 17px;
  margin-bottom: 6px;
}

.custom-dropdown-items:hover {
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.flex-Stylee {
  display: flex;
  align-items: center;
}

.flex-style-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#author-post-choices-icon-btn {
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: #00000099;
  }

  &:hover {
    background-color: #f1f5f9;
    svg {
      color: #00000099;
    }
  }
  &:active {
    background: #f1f5f9;
    svg {
      color: #456eb2 !important;
      background: transparent;
    }
  }
  &.author-post-open {
    background: #f1f5f9;
    svg {
      color: #456eb2 !important;
      background: transparent;
    }
  }
}
#author-item-list-tooltip {
  display: none;
}
