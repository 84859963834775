@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.author-img-box {
  max-width: fit-content;
  display: flex;
  align-items: flex-start;
  &.hover-profile {
    margin-right: 6px;
    margin-left: 6px;

    border: 6px solid transparent;
    &:hover {
      background: #0000000a;
      border-radius: 4px;
      cursor: pointer;
      .user_name {
        text-decoration-line: underline;
      }
    }
  }
  .icon-after-title.facebook {
    height: 16px;
  }
}
.author-profile-img {
  img {
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    width: 38px !important;
    height: 38px !important;
  }
}
.heading-engager-influencer {
  font-size: 16;
  font-weight: 500;
}
.authore-name-box {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  justify-content: center;
}
.engager-influencer-verified-name {
  font-weight: 500;
  margin-bottom: 5px;
}
.engager-influencer-verified-id {
  font-weight: 500;
  font-size: 14;
  color: "#64748B";
}
.number-post {
  padding: 0 16px;
  text-align: center;
  width: 100px;
  font-weight: 500;
  font-size: 16;
}
.tr-bg:nth-of-type(odd) {
  background: transparent !important;
}

.ar-postuser-heading {
  @include ar {
    text-align: right;
    font-family: "IBM Plex Sans Arabic", sans-serif !important;
  }
}
.ar-postuser-heading1 {
  @include ar {
    text-align: right;
    font-family: "IBM Plex Sans Arabic", sans-serif !important;
    font-size: 15;
    font-weight: 500;
    text-transform: "capitalize";
    color: #64748b;
    width: 270px;
  }
}
.author-profile-img {
  position: relative;
  margin-right: 8px;
  margin-left: 0;
  @include ar {
    margin-right: 0;
    margin-left: 8px;
  }
  .agent-profile-active {
    width: 8px;
    height: 8px;
    position: absolute;
    top: 27px;
    right: 0px;
    border-radius: 50%;
    border: 3px solid #fff;
    @include ar {
      right: unset;
      left: 0;
    }
    &.available {
      background-color: #4caf50;
    }
    &.un-available {
      background-color: #ed6c02;
    }
  }
}

.tablemargin {
  margin-bottom: 12px;
}
.tr-bg {
  &.hover-profile-tr {
    td {
      &:nth-child(1) {
        padding: 0;
      }
    }
  }
}
.tr-bg td {
  padding: 5px 16px;
  @include ar {
    text-align: right;
  }
}
.rowid-name {
  position: relative;
}
.border-none {
  border: none !important;
}
.intl1 {
  font-size: 12px;
  font-weight: 400;
  border-bottom: none;
  button {
    float: left;
  }
  p {
    direction: initial;
  }
  .MuiTablePagination-actions {
    margin-left: 0px !important;
  }
}
.tabledata-right thead th {
  @include ar {
    text-align: right;
  }
}
.tabledata-right thead tr:first-child th:last-child {
  @include ar {
    text-align: left;
  }
}
.tabledata-right tbody th {
  @include ar {
    text-align: right;
  }
}
.tabledata-right tbody td {
  @include ar {
    text-align: right;
  }
}

.intl1 .MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled {
  visibility: visible !important;
}
.text-row {
  word-break: break-all;
  position: relative;
  .lexisnexis-img {
    width: 16px !important;
    height: 16px !important;
    position: absolute;
    bottom: 16px;
    &:hover {
      cursor: pointer;
    }
  }

  .rowid-name {
    margin-inline-start: 20px;
  }
}
.authore-circular-container {
  .user_name {
    font-weight: 600;
    line-height: 22px;
    color: #000000de;
  }
}

.user_name {
  font-style: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.user_id {
  margin-top: 3px;
  font-weight: 400;
  line-height: 14px;
  font-size: 12px;
  color: #00000099;
}
.table-widget {
  height: 441px !important;
}

#most_engaged_contacts,
#most_influential_contacts,
#verified_contacts {
  .authore-name-box {
    @include ar {
      min-width: 100px;
      .user_id {
        direction: ltr;
      }
    }
  }
  .widget-box .MuiBox-root .MuiGrid-root .MuiGrid-item {
    display: contents !important;
  }
}
#verified_contacts {
  .posts_stats {
    width: 155px;
    .posts-stats-value,
    .posts_stats_name {
      display: flex;
      justify-content: center;
    }
  }
  .posts-stats-wrapper {
    margin-bottom: 0 !important;
  }
}

.rowid-name-quick-inspect {
  cursor: pointer;
}

.table-verified-icon {
  @include en {
    margin-left: 5px;
  }
  @include ar {
    margin-right: 5px;
  }
}

.table-cell-keyword {
  width: 50%;
}

.automatedHeight {
  height: auto;
}

.pagination-table-style {
  position: absolute;
  bottom: 0px;
  @include en {
    right: 0px;
  }
  @include ar {
    left: 0px;
  }
  tr td {
    border: 0;
  }
}
#total-number-of-engagements-tooltip {
  > div {
    height: auto;
  }
  div.tooltip-custom-new {
    padding: 8px 10px;
  }
}

.table-word-cloud-hidden-wrapper-container {
  position: absolute;
  left: 99999px;
  width: 100%;
  height: 100%;
}
.tr-bg {
  td {
    &.custom-row-style {
      text-align: initial;
    }
    &.last-row {
      border-bottom: none;
    }
  }
}
