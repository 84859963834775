@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.create-accounts-list {
  margin-bottom: 58px;
  .create-accounts-list-header {
    margin: 40px 0 30px 0;
    h6 {
      font-weight: 500;
      letter-spacing: -0.015em;
      color: #0c192a;
    }
    .MuiTypography-h5 {
      font-size: 24px;
      line-height: 22px;
    }
  }
  .create-accounts-list-card {
    filter: drop-shadow(0px 4px 4px #e3e3e3);
    box-shadow: none;
    border-radius: 10px;
  }
  .edit-mode-divider {
    margin-bottom: 30px;
    background-color: grey;
  }

  .create-accounts-list-body {
    padding: 35px 70px 35px 70px;
    min-height: 250px;
    .text-field {
      margin-top: 20px;
      margin-bottom: 10px;
      div.MuiFormControl-root.MuiFormControl-fullWidth {
        > div {
          max-height: 40px;
        }
      }
      .required-asterisk {
        color: red;
        margin-inline-start: 2px;
      }
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #0f172a;
        margin-bottom: 10px;
        @include ar {
          font-size: 16px;
          line-height: 27px;
          font-family: "Dubai";
          &.MuiFormControlLabel-root {
            margin-left: 16px;
            margin-right: -11px;
          }
        }
      }

      .MuiOutlinedInput-root.Mui-error {
        border-color: #f44336;
      }

      .MuiFormHelperText-root.Mui-error {
        margin-left: 12px;
        @include ar {
          text-align: right;
          margin-left: 0;
          margin-right: 13px;
        }
      }

      input,
      .MuiSelect-select {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.6);
        padding: 8.5px 12px;
      }
      fieldset {
        border: 1px solid rgba(0, 0, 0, 0.23);
      }
      .MuiOutlinedInput-root {
        &.Mui-error {
          fieldset {
            border-color: #f44336;
          }
        }
      }
      @include ar {
        .MuiSelect-icon {
          right: auto;
          left: 7px;
        }
      }
    }

    .add-accounts-label {
      font-size: 15px;
    }
  }
  .accounts-list-step-footer {
    padding: 25px 70px 25px 70px;
    border-top: 1px solid #efefef;
    display: flex;
    flex-direction: row;

    .cancel-btn {
      color: #64748b;
      margin-inline-end: 8px;
    }
    .accounts-list-btn-create {
      flex: 2 2 auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @include ar {
        // float: right;
        justify-content: flex-end;
      }
      @include en {
        // float: left;
        justify-content: flex-end;
      }
    }
    .accounts-list-btn-edit {
      flex: 2 2 auto;
      width: 100%;
    }
  }
  .create-edit-accounts-lists-form-grid {
    .create-edit-accounts-lists-import-container {
      display: flex;
      .create-edit-accounts-lists-import-msg-container {
        flex-grow: 1;
      }
    }
  }
}
