.permission-wrapper-container{
  border-top: 1px solid rgba(0, 0, 0, 0.23);
}
.permission-wrapper{
  max-width: 555px;
  padding: 80px 0;
  margin: auto;
  text-align: center;
  .permission-description{
    margin: 30px 0 18px;
    font-size: 16px;
    line-height: 24px;
    color:rgba(0, 0, 0, 0.87);
  }
  .permission-hint{
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    margin: 40px 0 0 0;
    a{
      color: #456EB2;
    }
  }
}
.permission-button.MuiButton-root {
  height: 42px;
  font-size: 14px;
  padding-right: 12px;
  padding-left: 12px;
  &:hover {
    background-color: #456eb2;
  }
}