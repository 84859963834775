@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@keyframes ripple-effect {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

#embedded-add-questions-continer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}
#add-questions-toggle-wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  .add-start-end-line {
    flex-grow: 1;
    border-bottom: 1px dashed #94a3b8;
    &.expanded {
      border-bottom: 1px solid #94a3b8;
    }
  }
  .add-questions-toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    width: 24px;
    height: 24px;
    margin: 10px;
    border-radius: 50%;
    cursor: pointer;
    background: #456eb2;
    color: #ffffff;
    position: relative;
    overflow: visible;
  }

  .add-questions-toggle-btn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(69, 110, 178, 0.2);
    transform: translate(-50%, -50%) scale(0);
    animation: ripple-effect 1.5s infinite ease-out;
    z-index: 0;
  }
  .add-questions-toggle-btn:hover::before {
    animation: none;
  }
  .add-questions-toggle-btn > * {
    position: relative;
    z-index: 1;
  }
}
.add-questions-list {
  .add-questions-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    padding: 8px 0px;
    cursor: pointer;
    &:hover {
      background: #f8f8f9;
    }
    .add-questions-icon {
      background: #f8f8f9;
      color: #64748b;
      border-radius: 6px;
      font-size: 12px;
      padding: 8px;
    }
    .add-questions-title {
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
    }
  }
}
