.main-add-user {
  padding: 40px 100px;
  .add-user-bread {
    display: flex;
    gap: 5px;
    color: #64748b;
    font-size: 14px;
  }
  .add-user-tit {
    font-size: 20px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .main-step-contant {
    background-color: #fff;
    min-height: 350px;
    padding: 50px 100px;
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px -89px 0px -146px inset,
      rgba(0, 0, 0, 0.3) 0px 10px 15px -20px inset;
    .main-step-tit {
      font-size: 15px;
      font-weight: 500;
      color: #232b34;
    }
  }
  .main-steps-footer {
    padding: 24px 30px;
    background-color: #fff;
    border-top: 1px solid #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .steps-cancel-btn {
      margin-inline-end: 10px;
    }
  }
}
