@mixin en {
    html[lang="en"] & {
      @content;
    }
  }
  @mixin ar {
    html[lang="ar"] & {
      @content;
    }
  }
  
  .alert-filter-formControl {
    width: 300px;
    height: 40px;
  
    .MuiInputLabel-root {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: -3px;
      @include ar {
        left: auto;
        right: 30px;
        margin-top: -5px;
        font-weight: 500;
        font-family: "Dubai" !important;
        &.Mui-focused, &.filter-selected-label {
          right: 12px;
          margin-top: -3px;
        }
      }
    }
    .MuiSelect-select {
      padding: 11px 14px;
    }
  
    .MuiOutlinedInput-root {
      fieldset {
        border-color: rgba(0, 0, 0, 0.23);
      }
      &:hover {
        fieldset {
          border-color: #456EB2 ;
        }
      }
    }
    @include ar {
      fieldset {
        legend {
          text-align: right;
        }
      }
  
      .MuiSvgIcon-root {
        right: auto;
        left: 7px;
      }
    }
  }
  
  .alert-filter-menuItem {
    .MuiListItemText-root {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #000000;
      span{
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  
    .MuiListItemText-root span {
      margin: 0px;
      margin-left: 6px;
    }
  
    .MuiCheckbox-root {
      padding: 5px;
    }
  }
  