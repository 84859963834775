@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.explore-quick-search-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 100px;
  //
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 10;
}
.quick-search-left-head {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  font-size: 22px;
}
.quick-search-right-head {
  .MuiButton-root {
    &.explore-create-monitor {
      font-size: 14px;
      font-weight: 500;
      margin-right: 10px;
      @include ar {
        margin-right: 0px;
        margin-left: 10px;
      }
    }
    &.quick-search-filters {
      font-size: 14px;
      border-radius: 4px;
      border: 0.5px solid #8fa8d1;
      background-color: #fff;
      color: #696d72;
      font-weight: 500;
      svg {
        padding-inline-end: 5px;
      }
    }
  }
}
