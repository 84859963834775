@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.accounts-list-delete-dialog-container {
  .MuiDialog-paper {
    max-width: 700px;
    margin: auto;
  }
  #alert-dialog-title {
    padding-bottom: 0px;
  }
  .accounts-list-delete-dialog {
    min-width: 60px;
    .shared-dialog-body {
      width: min-content;
    }
    .MuiPaper-root {
      width: 600px;
    }
    .MuiDialogContent-root {
      padding: 0px 24px;
      .MuiDialogContentText-root {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    .accounts-list-delete-dialog-alert {
      margin: 12px 0;
      max-width: 100%;
      padding-inline-start: 16px;
      box-sizing: border-box;
      overflow: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      div.MuiTypography-root {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
      }
    }
    .shared-dialog-body {
      > p {
        line-height: 24px;
        font-size: 15px;
      }
    }
  }
}
