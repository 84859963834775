.add-team-container {
  // the container padding has important in the outer style
  padding: 0 !important;

  .step-content {
    width: auto;
    background-color: white;
    padding: 50px 0;
  }
  .add-team-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .add-team-title {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #232b34;
    }
  }
  .stepper-end {
    display: flex;
    gap: 15px;
  }

  // every mui button
}
.tabs-section-container {
  &.tabs-divider {
    border-bottom: 1px solid #e2e8f0;
    padding: 40px 0px;
  }

  .user-tabs-content {
    .create-team-working-hours-container {
      flex: 1;
    }
    &.create-team-working-hours-row-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // gap: 76px;
      align-items: center;
    }
    .team-auto-complete-column-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .create-team-auto-reply-text-area-label.MuiTypography-root {
      font-size: 12px;
      font-weight: 600;
      color: #00000099;
    }
    .create-team-auto-reply-text-area {
      border-radius: 4px;
      &.MuiInputBase-input {
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 400;
        color: #00000099;
      }
    }
  }
}
.create-team-working-hours-extra-title.MuiTypography-root {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
}

.create-team-working-hours-time-zone-title.MuiTypography-root {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: #00000099;
  &.view-settings {
    cursor: pointer;
    color: #456eb2;
    text-decoration: underline;
  }
}
