@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#edit-report-page-container {
  .edit-report-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .edit-report-header-title {
      color: #000000;
      font-weight: 700;
      font-size: 22px;
    }
    .edit-report-header-text-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 3px;
      p {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
        font-size: 14px;
        &.important-text {
          color: #456eb2;
          font-weight: 500;
        }
      }
    }
  }

  #edit-report-content-container {
    background: #fff;
    box-shadow: none;
    filter: drop-shadow(0 4px 4px #e3e3e3);
    margin-bottom: 50px;

    .edit-report-step-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 40px;
      padding: 40px 100px;
    }

    .edit-report-step-wrapper {
      width: 100%;
      box-sizing: border-box;

      .edit-report-step-title {
        color: #232b34;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 20px;
      }

      .edit-report-selected-monitors-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
        margin-top: 8px;
        .edit-report-selected-monitor-chip {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 15px;
          padding: 3px 10px;
          p {
            color: rgba(0, 0, 0, 0.26);
            font-weight: 400;
            font-size: 13px;
          }
          svg {
            font-size: 15px;
          }
          .MuiButton-icon.MuiButton-endIcon {
            margin-inline-start: 3px;
            margin-inline-end: 0;
          }
          @include ar {
            direction: rtl;
          }
        }
      }
    }

    .edit-report-footer-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      border-top: 1px solid #efefef;
      padding: 25px 30px;
      box-sizing: border-box;
    }
  }
}
