@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.hashtags-filter-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.hashtags-search-wrapper.search-box{
  width: 322px;
  .search-field{
    @include ar {
      label {
        transform: translate(205px, 9px) scale(1);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(215px, -9px) scale(0.75);
          background: #f8fafc;
        }
      }
    }
  }
}
.hashtags-status-wrapper{
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined {
    top: -6px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    @include ar {
      transform: translate(157px, 16px) scale(1);
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(160px, -1px) scale(0.75)
      }
    }
  }
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input{
    width: 172px;
    padding: 8.5px 13px;
    font-size: 14px;
  }
  fieldset{
    legend{
      @include ar {
        text-align: right;
      }
    }
  }
}
.hashtags-status-item.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters{
  padding: 4px 16px;
  display: flex;
  gap: 10px;
  .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0;
    padding-inline-end: 10px;
  }
}
