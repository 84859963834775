@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#embedded-survey-popup-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  * {
    font-family: var(--embedded-popup-font);
  }

  &.preview-mode {
    * {
      font-family: var(--embedded-popup-font) !important;
    }
  }

  &.rounded {
    border-radius: 4px 4px 8px 8px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
  }

  &.top-left {
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
  &.top-right {
    top: 0;
    left: auto;
    right: 0;
    transform: translate(0, 0);
  }
  &.top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &.middle-left {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  &.middle-right {
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);
  }
  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.bottom-left {
    top: 100%;
    left: 0;
    transform: translate(0, -100%);
  }
  &.bottom-right {
    top: 100%;
    left: 100%;
    transform: translate(-100%, -100%);
  }
  &.bottom-center {
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  .embedded-survey-popup {
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(80vh - 180px);

    &::-webkit-scrollbar {
      width: 13px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f9f9f9;
      @include en {
        box-shadow: 1px 0px 0px 0px #0000001f inset;
      }
      @include ar {
        box-shadow: -1px 0px 0px 0px #0000001f inset;
      }
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--survey-primary-color);
      background-clip: padding-box;
      border: 3px solid transparent;
      border-radius: 14px;
    }

    .embedded-survey-popup-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .embedded-popup-body {
        width: 100%;
      }

      &.progress-bar {
        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 4px;
          background-color: #cbd5e1;
          top: 0;
          border-radius: 8px 8px 0 0;
        }
        &::after {
          position: absolute;
          content: "";
          height: 4px;
          top: 0;
          border-radius: 8px 8px 8px 0;
        }
        &.ltr {
          &::before,
          &::after {
            left: 0;
          }
        }
        &.rtl {
          &::before,
          &::after {
            right: 0;
          }
        }
      }
    }
  }
}

.embedded-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  #embedded-popup-title-control {
    width: 70%;
    max-width: 390px;
    ::before {
      border-bottom: 2px solid transparent;
    }
    #embedded-popup-title-input {
      color: var(--survey-font-color);
      font-weight: 500;
      &:focus {
        border-bottom: 2px solid #456eb2;
      }
    }
  }

  .survey-popup-preview-title {
    color: var(--survey-font-color);
    font-weight: 500;
  }
  button#embedded-popup-close-btn {
    color: #475569;
    font-size: 14px;
  }

  &.ltr,
  &.ltr input {
    direction: ltr;
  }
  &.rtl,
  &.rtl input {
    direction: rtl;
  }
}

.embedded-popup-empty-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 40px 20px;
  box-sizing: border-box;
  &.expanded-add-question {
    padding: 20px;
  }
}

.embedded-popup-empty-survey {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  .empty-survey-title {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-size: 16px;
  }
  .empty-survey-desc {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    text-align: center;
  }
}

// ******************** SMALL Popup Styles ********************
.embedded-small-popup {
  .embedded-survey-popup {
    width: 508px;
    max-width: 508px;
  }
  .embedded-survey-popup-content {
    padding: 16px;
    gap: 16px;
  }
  #embedded-popup-title-input {
    font-size: 16px;
  }
  .survey-popup-preview-title {
    font-size: 16px;
  }
}

// ******************** MEDIUM Popup Styles ********************
.embedded-medium-popup {
  .embedded-survey-popup {
    width: 548px;
    max-width: 548px;
  }
  .embedded-survey-popup-content {
    padding: 20px;
    gap: 20px;
  }
  #embedded-popup-title-input {
    font-size: 18px;
  }
  .survey-popup-preview-title {
    font-size: 18px;
  }
}

// ******************** LARGE Popup Styles ********************
.embedded-large-popup {
  .embedded-survey-popup {
    width: 588px;
    max-width: 588px;
  }
  .embedded-survey-popup-content {
    padding: 24px;
    gap: 24px;
  }
  #embedded-popup-title-input {
    font-size: 20px;
  }
  .survey-popup-preview-title {
    font-size: 20px;
  }
}

#survey-embedded-submitted-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin: 20px 0;
  svg {
    font-size: 48px;
    color: var(--survey-primary-color);
  }
  h4 {
    color: var(--survey-font-color);
    font-weight: 600;
    font-size: 18px;
  }
}
