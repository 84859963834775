.content-guide-parant {
  margin: 0 auto;
  text-align: start;
  padding-top: 50px;
  .main-title-guide {
    margin-bottom: 55px;
    color: #000;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px; /* 100% */
    letter-spacing: -0.33px;
    width: 100%;
  }
  .group-btns {
    display: flex;
    align-items: center;

    .facebook-icon {
      background-color: #1877f2;
      font-size: 20px;
    }
    .instagram-icon {
      background-color: #e1306c;
      font-size: 17.5px;
    }
    .twitter-icon {
      background-color: #000000;
      font-size: 17.5px;
    }
    .icon {
      margin-inline-end: 10px;
      color: #fff;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      flex-shrink: 0;
    }
  }
  .accordion {
    width: 100%;
    .accordion-item {
      max-width: 100%;
      width: auto;
      display: flex;
      flex-direction: column;
      background: #fff;
      margin-bottom: 10px;
      padding: 20px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    }
  }
  .content-head {
    width: 100%;
    h3 {
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }
    .item-description {
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }
  }
  .item-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    svg.rotate {
      rotate: -180deg;
    }
    svg {
      transition: 0.5s;
      color: rgba(0, 0, 0, 0.6);
      font-size: 16px;
      font-weight: 900;
      line-height: 6.756px; /* 42.227% */
      letter-spacing: -0.24px;
    }
  }
  .item-content {
    height: 0;
    transition: all 0.4s !important;
    transition: height 1s ease !important;
    overflow: hidden;
    li {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 8px;
      &:last-child {
        margin: 0;
      }
    }
  }
  .item-content.show {
    height: max-content;
    overflow: auto;
    margin-top: 30px;
  }
}
.container-guide {
  width: 100%;
}
