//functions sass
@mixin display-flex-function($flex, $item-center, $justify) {
  display: $flex;
  align-items: $item-center;
  justify-content: $justify;
}
//@include display-flex-function(flex, center, flex-start);
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.answer-parant {
  @include display-flex-function(flex, center, flex-start);
  flex-direction: column;
  padding: 20px 0px 70px;
  .answer-date {
    @include display-flex-function(flex, center, center);
    padding: 0px 10px;
    height: 24px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 26px;
    font-weight: 400;
    font-size: 12px;
    color: #676767;
    margin-bottom: 30px;
    direction: initial;
  }
  .answer-items {
    width: calc(100% - 40px);

    margin: 0px 20px;
    @include display-flex-function(flex, flex-start, space-between);
    .logo-luci-answer {
      flex-shrink: 0;
      background: #ffffff;
      border: 1px solid #f2f2f2;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      @include display-flex-function(flex, center, center);
      margin-inline-end: 20px;
      img {
        width: 27.74px;
        height: 24px;
      }
    }
  }
}
.data-details-parant {
  padding: 20px;
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  .head-details {
    padding-bottom: 15px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 15px;
    @include display-flex-function(flex, center, space-between);
    span {
      font-weight: 500;
      font-size: 14px;
      color: #212121;
    }
    .answer-quota {
      @include display-flex-function(flex, center, space-between);
      font-weight: 500;
      font-size: 14px;
      color: #212121;

      img {
        margin-inline-start: 3px;
      }
    }
  }
  .sub-title-answer {
    font-weight: 400;
    font-size: 12px;
    color: #676767;
    margin-bottom: 15px;
    @include en {
      font-family: "Figtree" !important;
    }
    display: inline-block;
  }
  .item-details {
    @include display-flex-function(flex, center, space-between);
    margin-bottom: 5px;
    .name-details,
    .value-details {
      font-weight: 400;
      font-size: 12px;
      line-height: 143%;
    }
    .name-details {
      color: #676767;
    }
    .value-details {
      color: #212121;
    }
  }
}
.user-question-parant {
  margin: 40px 0px;
  padding: 20px 0;
  padding-inline-end: 20px;
  padding-inline-start: 82px;
  background: #fafafa;
  border-radius: 2px;
  width: 378px;
  .luci-img-username {
    width: 42px;
    height: 42px;
    margin: 0;
    img {
      width: 42px;
      height: 42px;
      object-fit: cover;
    }
  }
  @include display-flex-function(flex, center, space-between);
  .user-question {
    font-weight: 400;
    font-size: 14px;
    @include en {
      font-family: "Figtree" !important;
    }
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #212121;
    width: 268px;
  }
  span {
    .luci-img-username {
      .luci-img-char {
        width: 42px;
        padding: 0;
        height: 42px;
        @include display-flex-function(flex, center, center);
      }
    }

    margin-inline-start: 40px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
}
.answer-items {
  .answer-user {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;

    text-align: start;
    color: #000000;
    width: 100%;
    padding-top: 13px;
    position: relative;
    margin-bottom: 50px;
    @include en {
      font-family: "Figtree" !important;
    }
    .answer-val-item {
      font-weight: 400;
      letter-spacing: 0.02em;
      font-size: 14px;
      line-height: 20px;
      text-align: start;
      color: #000000;
      @include en {
        font-family: "Figtree" !important;
      }
    }
    .answer-val-item:last-child {
      &::after {
        content: "";
        display: inline-block;
        background-color: #212121;
        width: 6px;
        height: 20px;
        margin-top: -3px;
        position: absolute;
        bottom: -2px;
        margin-inline-start: 3px;
      }
    }

    .answer-val-item {
      display: block;
    }
    p {
      @include display-flex-function(flex, center, flex-start);
      @include en {
        font-family: "Figtree" !important;
      }
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #666666;
      width: 21.5ch;
      white-space: nowrap;
      overflow: hidden;
      border-inline-start: 5px solid #212121;
      animation: cursor 1s step-start, text 3s steps(18) alternate infinite;
      padding-inline-start: 4px;
    }
  }
  .answer-val-item:last-child {
    &::after {
      display: none;
    }
  }
}
#luci-ask-again {
  width: 378px;
  height: 42px;
  border: 1px solid #456eb2;
  border-radius: 2px;
  margin-inline-start: auto;
  margin-inline-end: 20px;
  @include en {
    font-family: "Figtree" !important;
  }
}
@keyframes cursor {
  0%,
  100% {
    border-color: #212121;
  }
}

@keyframes text {
  0% {
    width: 0.3ch;
  }
  100% {
    width: 21.5ch;
  }
}

#tooltip-anwers-section {
  z-index: 999999 !important;
  > div {
    padding: 10px 10px 15px 10px;
    z-index: 999999 !important;
    max-width: 240px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #ffffff;
    background: rgba(97, 97, 97, 0.9);
    border-radius: 4px;
  }
  .hover-tooltip {
    cursor: pointer;
  }
}
