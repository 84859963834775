@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#settings-page {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
}

#settings-page-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  background: #f8fafc;
  min-height: calc(100vh - 60px);
  width: min-content;
  overflow-x: auto;
  @include en {
    border-left: 1px solid #e2e8f0;
  }
  @include ar {
    border-right: 1px solid #e2e8f0;
  }

  > .settings-page-content {
    flex-grow: 1;
    width: -webkit-fill-available;
    padding: 0px 40px 40px 40px;
  }

  .settings-page-content-pagination {
    margin-bottom: 0px;
  }
}

h6.settings-section-title {
  color: #475569;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 12px;
  @include en {
    letter-spacing: 0.12rem;
  }
}
