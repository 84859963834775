@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.monitor-type-name-topic {
  padding-inline: 8px;
}
#setting-topic-user-view-tooltip {
  .MuiTooltip-tooltip {
    color: var(--Common-White, #fff);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    max-width: 510px;
    padding: 10px;
  }
}
.view-user-container {
  padding: 0 !important;
  margin-bottom: 20px;

  button.MuiButtonBase-root.MuiButton-root {
  }

  .view-user-header {
    margin-bottom: 19px;
    .edit-user-header-link {
      &.link-disabled {
        pointer-events: none;
        cursor: auto;
      }
    }
    .view-user-header-description {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .view-user-header-breadcrumb {
      display: flex;
      flex-direction: column;
      gap: 9px;
      #main-pages-header-with-breadcrumb {
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
}

.delete-user-modal {
  h2.MuiTypography-root {
    padding: 24px 24px 15px 24px;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: #000000de;
  }

  div.MuiDialogContent-root {
    padding: 0px 24px 10px 24px;
  }

  div.MuiDialogActions-root {
    padding: 15px 24px 24px 24px;
  }
}

.main-view-user {
  .main-edit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #94a3b8;
  }

  .main-edit-header-tittle {
    color: #0c192a;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 19px;
  }
  .main-edit-user-card {
    .view-info-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 6px;
      margin-left: 24px;
      color: var(--neutral-600, #475569);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @include ar {
        margin-left: 0;
        margin-right: 24px;
      }
    }
    .view-lang-box {
      margin-left: auto;
      display: flex;
      width: 315px;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      border-radius: 5px;
      border: 1px solid #e2e8f0;
      @include ar {
        margin-left: 0;
        margin-right: auto;
      }
      .lang-box-header {
        color: var(--neutral-500, #64748b);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.72px;
        text-transform: uppercase;
      }
      .lang-box-sub {
        color: var(--neutral-700, #334155);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .acc-box {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: var(--neutral-100, #f1f5f9);
      color: var(--Text-Primary, rgba(0, 0, 0, 0.87));
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .view-lang-mini-box {
      max-width: 489px;
      display: flex;
      flex-direction: row;
      gap: 6px;
      flex-wrap: wrap;
      color: var(--Text-Primary, rgba(0, 0, 0, 0.87));
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.4px;
      &.user-tab-lang-box {
        width: 611px;
        align-items: flex-start;
        align-content: flex-start;
        gap: 12px;
      }
    }
    .user-header-state {
      align-items: center;
      font-size: 14px;
      margin-top: 6px;
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      height: 24px;
      display: flex;
      gap: 5px;
      margin-inline-end: 5px;
      padding: 4px 10px;
      border-radius: 3px;
      border: 1px solid;
      &.active {
        color: #3b873e;
        background-color: #4caf5014;
        border: 1px solid
          var(--Success-States-Outlined-Resting-Border, rgba(76, 175, 80, 0.5));
      }
      &.deactivated {
        border-radius: 4px;
        color: var(--Error-Main, #f44336);
        border: 1px solid
          var(--Error-States-Outlined-Resting-Border, rgba(244, 67, 54, 0.5));
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.9) 100%
          ),
          #f44336;
      }
      &.invited {
        color: #ed6c02;
        background-color: #ed6c0214;
        border-color: #ed6c02;
      }
    }
    .info-text-header {
      color: var(--neutral-800, #1e293b);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .info-text-icon {
      display: flex;
      align-items: center;
      gap: 10px;
      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }
      .view-phone,
      .view-email {
        @include ar {
          direction: ltr;
        }
      }
    }
    .lang-box {
      display: flex;
      padding: 6px 10px;
      justify-content: center;
      align-items: flex-start;
      gap: 5px;
      border-radius: 2px;
      border: 1px solid var(--neutral-200, #e2e8f0);
      background: var(--neutral-50, #f8fafc);
      color: var(--neutral-800, #1e293b);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &.en-box {
        @include ar {
          direction: ltr;
        }
      }
      .lang-box-pro {
        direction: ltr;
        color: var(--neutral-500, #64748b);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .edit-sec-tit {
      color: var(--neutral-600, #475569);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      letter-spacing: 1.19px;
      text-transform: uppercase;
      margin-top: 30px;
      margin-bottom: 16px;
    }

    .edit-sec-container {
      min-height: 70px;
      padding: 24px;
      border-radius: 5px;
      border: 1px solid #e2e8f0;
      background-color: #fff;
      box-shadow: 0px 4px 8px 0px #00000014;
      .main-perms-countainer {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 0px;
        .MuiPaper-elevation.MuiPaper-rounded {
          margin: 0px;
        }
      }
    }
  }
  .edit-user-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    img {
      border: 1px solid #c5c5c5;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      box-sizing: border-box;
    }
  }
  .edit-user-img {
    display: flex;
    align-items: center;
  }
  .view-user-tabs {
    padding: 0 8px;
    .MuiTab-root {
      padding: 11px 12px;
      border-bottom: 3px solid transparent;
      padding-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #00000099;
    }
    .Mui-selected {
      color: #000000de;
      border-bottom: 3px solid #456eb2;
    }
  }
}

.delete-user-modal.view-user-page {
  width: 600px;
  .delete-user-modal-warn-message {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000de;
  }
}
