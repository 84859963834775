@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.table-cell-item-header.MuiTableCell-root {
  color: #475569;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
}
.table-cell-item-head.MuiTableCell-root {
  width: 30%;
}
.table-cell-item-sub-head.MuiTableCell-root {
  width: auto;
}
.table-chart-content {
  box-shadow: 0 4px 4px #e3e3e3;
  background-color: #fff;
  padding: 20px 0 0 0;
  border-radius: 5px;
  .widget-box {
    .MuiGrid-root.MuiGrid-container {
      padding: 0 20px 20px 20px;
    }
  }
  height: auto;
}

.table-widget-header-wrapper {
  border-top: 1px solid #e2e8f0;
  color: #475569;
}

.text-row-content-widget.MuiTableCell-root {
  word-break: break-all;
  padding-top: 0px;
}
.table-widget-genesys-wrapper {
  .MuiTableCell-root {
    padding: 20px;
  }
}
.sentiment-widget-value.MuiTypography-root {
  padding-inline: 5px;
  font-size: 14px;
}
.table-head-title-wrapper {
  display: flex;
  p {
    @include en {
      margin-top: 7px;
    }
    @include ar {
      margin-top: 12px;
    }
    border: 3px solid;
    width: 20px;
    margin-inline: 10px;
  }
}
.title-name-widget {
  color: #475569;
}

.genesys-top-keywords.MuiGrid-root {
  #genesys_top_keywords {
    height: 100%;
  }
}
.table-cell-item-head-first {
  width: 30%;
}
.table-cell-item-head {
  width: auto;
}

.table-head-title-wrap {
  text-transform: capitalize;
}
