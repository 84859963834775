// Custom Spinner for Download widget as image OR pdf!
// Don't Repeat/remove or update this file !
.moderspinner {
  width: 20px;
  height: 20px;
  border: dotted 3px #64748b;
  border-radius: 100%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-top-color: transparent;
    border-right-color: transparent;
  }
  50% {
    transform: rotate(180deg);
    border-color: #64748b;
    border-top-color: transparent;
    border-right-color: transparent;
  }
  100% {
    transform: rotate(360deg);
    border-color: #64748b;
    border-top-color: transparent;
    border-right-color: transparent;
  }
}
