.pageStyle {
  max-width: 100%;
  border-top: 3px solid #456eb2;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 27px 27px 30px;
  font-family: "Figtree" !important;
  display: flex;
  align-items: center;
  gap: 15px;
}
.pageStyle .nameSection {
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}
.sectionName {
  border-top: 5px solid #456eb2;
}

.pageStyle hr:first-child {
  border-color: #456eb2 !important;
}

.imgStyle {
  max-width: 100px;
  min-width: 88px;
}

.subDivider {
  margin-top: 13px !important;
}

.mainHeader {
  font-size: 24px;
  color: #0c192a;
  font-family: "Figtree";
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 7px;
}

.subText {
  font-size: 14px;
  color: #80868c;
  font-family: "Figtree";
  font-weight: 400;
  line-height: 20px;
}

@media only screen and (orientation: landscape) {
  @media (min-width: 730px) and (max-width: 1024px) {
    .mainHeader {
      font-size: 22px;
    }
    .subText {
      font-size: 13px;
    }
  }

  @media (min-width: 280px) and (max-width: 729px) {
    .pageStyle {
      padding-bottom: 10.48px;
    }
    .imgStyle {
      width: 48px;
      padding-left: 10px;
    }
    .mainHeader {
      font-size: 16px;
      padding-left: 10px;
    }
    .subText {
      font-size: 12px;
      padding-left: 10px;
    }
  }
}

@media only screen and (orientation: portrait) {
  @media (min-width: 730px) and (max-width: 1024px) {
    .pageStyle {
      padding-bottom: 30px;
    }
    .subDivider {
      display: none;
    }
    .mainHeader {
      font-size: 22px;
    }
    .subText {
      font-size: 13px;
    }
  }

  @media (min-width: 280px) and (max-width: 729px) {
    .imgStyle {
      width: 48px;
      padding-left: 10px;
    }
    .mainHeader {
      font-size: 16px;
      padding-left: 10px;
    }
    .subText {
      font-size: 12px;
      padding-left: 10px;
    }
  }
  .pageStyle {
    border-top: 4px solid #456eb2;
  }
  .sectionName {
    border-top: 3px solid #456eb2;
  }
  .pageStyle .nameSection {
    font-size: 15px;
    font-weight: 500;
  }
}
.headerRtl {
  direction: rtl;
}
.headerLtr {
  direction: ltr;
}
