@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#custom-themes-page-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  .custom-themes-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sub-title {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .table-cell {
    font-size: 14px;
    &.drag-icon {
      color: #757575;
    }
    &.color-box {
      width: 16px;
      height: 16px;
      border-radius: 2px;
    }
  }
  #themes-page-reached-limit-alert {
    > .MuiAlert-message {
      font-weight: 500;
    }
  }
  #themes-page-table-wrapper {
    margin-bottom: 80px;
  }
}

#themes-table-more-options-edit-btn,
#themes-table-more-options-delete-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

#delete-theme-popup-body {
  .text-line-one,
  .text-line-two {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
  .text-line-two {
    font-weight: 500;
  }
}

.theme-dialog-popup {
  > #alert-dialog-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  > .actions-buttons-shared {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}

#theme-dialog-body {
  display: flex;
  justify-content: center;
  padding: 15px 0px;
  .theme-dialog-input-label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .max-char-counter {
      color: rgba(0, 0, 0, 0.6);
      &.reached {
        color: #f44336;
      }
    }
  }
  .theme-dialog-form-control {
    width: 100%;
    .MuiFormHelperText-root.Mui-error,
    .error-helper-text {
      color: #e31b0c;
      font-size: 12px;
    }
    #theme-form-colors-select {
      @include en {
        padding-left: 5px;
      }
      @include ar {
        padding-right: 15px;
      }
    }
  }
  .theme-dialog-input-label {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    > span.required {
      color: #e31b0c;
    }
    > span.description-info-icon {
      color: rgba(0, 0, 0, 0.6);
      margin-inline: 5px;
    }
    > span.maximum-keywords {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      margin: 0px 4px;
    }
  }
}
li.Mui-disabled.theme-color-select-item {
  opacity: 1;
}
.select-color-box {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin: 2px 0px 2px 10px;
  &.used {
    opacity: 0.5;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        135deg,
        transparent 49%,
        white 49%,
        white 51%,
        transparent 51%
      );
    }
  }
}
.theme-dialog-content {
  height: 365px;
  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    @include en {
      box-shadow: 1px 0px 0px 0px #0000001f inset;
    }
    @include ar {
      box-shadow: -1px 0px 0px 0px #0000001f inset;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 14px;
  }
}
