@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.main-alert-view {
  .chart-no-data {
    height: 80%;
  }
  .try-adjust-date {
    display: none;
  }
}
.new-posts-countiner {
  h6 {
    margin: 70px 0 0 0 !important;
  }
}
.dialects-countainer {
  #dialects_subdialects {
    height: 441px !important;
  }
}

.survey-date-title {
  .date {
    margin-left: 5px;
    @include ar {
      direction: ltr;
      margin-right: 5px;
    }
  }
  span {
    display: flex;
    font-size: 14px;
    color: #696d72;
    font-weight: 400;
  }
}
.pagination-alert-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 60px;
  .MuiPagination-ul {
    @include ar {
      li {
        &:first-of-type {
          transform: scaleX(-1);
        }
        &:last-of-type {
          transform: scaleX(-1);
        }
      }
    }
  }
  .MuiButtonBase-root {
    background-color: #fff;
  }
}
.gmail-alert-view {
  #alert_sentiment_analysis {
    height: 440px;
  }
}
