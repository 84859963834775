@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

//active filters
.active-filters-container {
  border-bottom: 1px solid #cbd5e1;
  box-sizing: border-box;
  height: auto;
  display: flex;
  // justify-content: space-between;
  padding-bottom: 13px;
}
.all-active-filters {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.active-filter-title {
  padding: 8px 11px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0c192a;
  margin: 2px 0px;
  @include ar {
    margin-top: -2px;
  }
}
.active-filter-params {
  border-color: #199cdc !important;
  margin-bottom: 11px !important;
  margin-left: 8px !important;
  span {
    color: #475569;
    font-size: 12px;
    font-weight: 500;
  }
  svg {
    font-size: 15px !important;
    margin-bottom: 1px !important;
    @include ar {
      margin-left: 5px;
    }
  }
}
.show-hidden-filters {
  border-color: rgb(42, 170, 226) !important;
  margin: 0px 8px !important;
  span {
    text-decoration: underline;
    color: rgb(42, 170, 226);
  }
}
.show-less-filters {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: #696d72;
  margin-right: 10px;
  margin-left: 10px;
}
.filter-params-count {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: #456EB2 ;
  cursor: pointer;
}
.clear-btn {
  color: #64748b;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  text-decoration-line: underline;
  margin: 12px 12px;
  cursor: pointer;
}

.show-active-filters {
  height: 43px;
  overflow: hidden;
}
.more-option {
  width: 150px;
  min-width: 140px;
  margin-top: 7px;
}
.close-filter {
  font-weight: 500;
  font-size: 14px;
  color: #9ba7b9;
  cursor: pointer;
  @include en {
    margin-left: 10px;
  }
  @include ar {
    margin-right: 10px;
  }
}
