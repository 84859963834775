@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.empty-item.empty-item-hashtags {
  background-color: transparent;
  .empty-data-box {
    max-width: 490px;
    @include ar{
    max-width: 400px;

    }
  }

}
