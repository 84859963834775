@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

//mui do not render Dropdown  options in the body
.options-value-teams {
  margin-left: 15px;
}

.teams-multi-select-dropdown {
  &-wrapper {
    width: 306px;
  }
  .add-teams-empty-member-state {
    .add-teams-empty-member-state-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #64748b;
      margin-bottom: 8px;
    }
  }
  .user-all-teams-content-select-msg {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000de;
    @include ar {
      margin-right: 14px;
    }
    @include en {
      margin-left: 14px;
    }
  }

  .teams-subtitle {
    margin-bottom: 16px;
    color: #475569;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
}

.list-team-item-select.MuiListItem-root {
  padding-bottom: 0;
  padding-top: 0;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
  .MuiTypography-root {
    @include ar {
      text-align: right !important;
      margin-right: 4px;
    }
  }
}
