.item-slide {
  display: flex;
  height: 168px;
  padding: 15px;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  border-radius: 5px;
  border: 1px solid var(--warning-50, #fceac5);
  background: #fcf7ed;
  gap: 20px;
  p {
    text-align: start;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    min-height: 130px;
  }
  h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    svg {
      margin-inline-end: 15px;
      font-size: 16px;
      font-weight: 900;
      color: #ed6c02;
    }
  }
}
.needs-attention {
  .skeleton-parant {
    min-height: 170px !important;
  }
}
.needs-attention-item {
  transition: 0.3s;
  &:hover {
    border: 1px solid var(--warning-100, #f9a700);
    background: #faf2e1;
    cursor: pointer;
  }
  strong {
    font-weight: 600 !important;
  }
  &.disabled-item {
    cursor: auto;
  }
}
.swiper-slide {
  width: fit-content;
}
.no-data-needs-attention {
  border-radius: 5px;
  border: 1px solid #ecf5da;
  padding: 15px;
  background: rgba(137, 187, 42, 0.05);
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  svg {
    font-size: 16px;
    color: #30bf25;

    margin-inline-end: 4px;
  }
}
