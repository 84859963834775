@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.explore-results-container {
  margin-top: 100px;
}
.explore-container {
  .expore-keywords-content {
    position: relative;
    .chip-style-arabic.MuiChip-root{
      font-size: 13px;
      font-weight: 400;
      color: #456EB2;
      border: 1px solid #456EB2;
      svg{
        color: #456EB2 !important;
      }
    }
    #outlined-email-input{
      &.MuiOutlinedInput-input{
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .explore-header-container.MuiBox-root {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 55px;
  }
  .explore-title-head.MuiTypography-root {
    font-weight: 800;
    font-size: 40px;
    color: #0f172a;
  }
  #explore-icon {
    margin-inline: 10px;
    position: relative;
    top: 5px;
  }
  .explore-fields-container.MuiGrid-root,
  .explore-saved-results-container.MuiBox-root {
    box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    margin: 0;
    width: 100%;
    .MuiChip-label {
      padding-bottom: 3px;
    }
  }
  .explore-fields-container.MuiGrid-root,
  .saved-results-list.MuiBox-root {
    padding: 40px;
    .pagination-monitor-list {
      margin-bottom: 0 !important;
    }
  }

  .saved-ressult-content-wrapper.MuiBox-root,
  .saved-results-name.MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .saved-results-name.MuiTypography-root {
    margin-bottom: 5px;
    @include ar {
      margin-left: 10px;
    }
  }
  .explore-saved-results-container.MuiBox-root {
    margin-top: 40px;
  }
  .saved-results-title.MuiTypography-root {
    font-size: 18px;
    font-weight: 600;
  }
  .saved-results-title-no-empty.MuiTypography-root {
    margin-bottom: 20px;
  }
  .explore-saved-results-loading.MuiBox-root {
    height: 200px;
    .circular-loading {
      top: 55px !important;
    }
  }
  .saved-results-content-data.MuiBox-root {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
  .saved-results-grid.MuiBox-root {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #fff;
    padding: 15px;
    cursor: pointer;
    .saved-result-delete-icon {
      color: #757575;
      margin-top: 10px;
      font-size: 14px;
    }

    .saved-results-name.MuiTypography-root {
      font-size: 16px;
      font-weight: 500;
      color: #456eb2;
    }
    .saved-results-count-posts.MuiTypography-root {
      font-size: 12px;
      font-weight: 400;
      color: #6d737a;
    }
  }
  .saved-result-content.MuiBox-root {
    display: flex;
    justify-content: space-between;
  }
  .explore-keywords-field.MuiFormControl-root {
    width: 100%;
  }
  .explore-keywords-box.MuiGrid-root,
  .explore-datasources-box.MuiGrid-root {
    @include en {
      padding-left: 0;
    }
  }
  .explore-keywords-field {
    .MuiOutlinedInput-root {
      .add-explore-plus-btn.Mui-disabled {
        @include en {
          border-left: 1px solid rgba(0, 0, 0, 0.26);
        }
        @include ar {
          border-right: 1px solid rgba(0, 0, 0, 0.26);
        }
      }
    }
    .MuiOutlinedInput-root.Mui-error {
      .add-explore-plus-btn {
        color: #f44336;
        @include en {
          border-left: 2px solid #f44336;
        }
        @include ar {
          border-right: 2px solid #f44336;
        }
      }
    }
  }

  .datasources-select-items.MuiOutlinedInput-root {
    height: 40px;
    .MuiInputBase-input{
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: 400;
      @include ar{
        padding-right: 20px;
      }
    }
  }

  .explore-keywords-field {
    width: 100%;
    .MuiOutlinedInput-root {
      height: 40px;
      padding-inline-end: 0;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }
    @include en {
      label {
        transform: translate(15px, 13px) scale(1);

        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(15px, -11px) scale(1);
          background: #ffffff;
          padding: 3px;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    @include ar {
      label {
        transform: translate(-17px, 11px) scale(1);

        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(-17px, -11px) scale(1);
          background: #ffffff;
          padding: 3px;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    @include en {
      top: 8px;
    }
    @include ar {
      label {
        left: auto !important;
        font-family: "IBM Plex Sans Arabic", sans-serif !important;
      }
      fieldset {
        legend {
          span {
            display: none;
          }
        }
      }
    }
  }
  .explore-date-picker {
    display: flex;
    margin-top: 24px;
    #date-range-picker-id {
      svg {
        @include ar {
          right: 10px !important;
          top: 21px;
        }
        @include en {
          left: 33px !important;
          top: 19px;
        }
      }
      width: 100%;
      .dateRange .MuiInputBase-formControl fieldset {
        box-shadow: none !important;
      }
      .dateRange.MuiTextField-root {
        width: 100%;
        input {
          width: 170px;
          color: rgba(0, 0, 0, 0.6);
          font-size: 14px;
          font-weight: 400;
          @include ar {
            font-family: "IBM Plex Sans Arabic", sans-serif !important;
          }
        }
        fieldset {
          border: none;
        }
        .MuiOutlinedInput-root {
          margin-right: 0;
          margin-left: 24px;
          border: 1px solid #c4c4c4;
          height: 40px;
        }
      }
    }
  }
  .add-explore-plus-btn {
    &.MuiButton-root {
      line-height: 2 !important;
      margin: 0 !important;
    }
  }

  .explore-datasources-box {
    @include en {
      label {
        transform: translate(15px, 9px) scale(1);
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(15px, -11px) scale(1);
          background: #ffffff;
          padding: 3px;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    @include ar {
      label {
        transform: translate(-11px, 10px) scale(1);
        right: 0;
        left: auto;
        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(-11px, -11px) scale(1);
          background: #ffffff;
          padding: 3px;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
    .explore-datasource-details {
      color: #334155;
      font-weight: 400;
      font-size: 12px;

      position: relative;
      top: 5px;
    }
  }

  .explore-steppers-containter.MuiBox-root {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    margin-top: 40px;
  }
  .explore-steppers-content.MuiBox-root {
    display: flex;
    @include ar {
      left: 22px;
      position: relative;
    }
  }

  
  .keywords-content-recent.MuiBox-root {
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: absolute;
    z-index: 999;
    .chip-keyword.MuiChip-root {
      border-radius: 16px;
      color: rgba(0, 0, 0, 0.87);
      border: 1px solid rgba(0, 0, 0, 0.26);
      background-color: white;
      margin-right: 5px;
      margin-top: 12px;
      height: auto;
      line-height: 15px;
      padding: 3px 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
    }
    .trending-topics-title.MuiTypography-root {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 600;
    }
    .trending-topics-container.MuiBox-root,
    .recent-search-container.MuiBox-root {
      padding: 20px;
    }
    .horizontal-keywords-divider.MuiDivider-root {
      width: 100%;
      border-color: #0000001f;
    }
  }
  .spinner-icon-explore {
    padding: 20px;
  }
  .explore-err-msg {
    margin-top: 0 !important;
  }

  .tooltip-explore-limit.MuiBox-root {
    cursor: pointer;
  }
  .add-explore-plus-btn.MuiButton-root {
    @include en {
      margin-left: 10px !important;
    }
  }
}
.list-item-explore-datasource {
  span {
    margin: 0 !important;
  }
}

.explore-alert.MuiAlert-root {
  border: 1px solid #fceac5;
  margin-top: -10px;
  margin-bottom: 20px;
}

.explore-datasource-item.MuiMenuItem-root {
  padding: 0;
}
.explore-create-monitor.MuiButton-root {
  &:hover {
    background-color: transparent !important;
  }
}

.empty-saved-results.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  color: #64748b;
}
.delete-saved-result-head.MuiTypography-root {
  font-weight: 700;
}
#delete-tooltip-icon-explore {
  .MuiTooltip-tooltip {
    margin-top: 12px !important;
  }
}
.delete-explore-saved-container.MuiBox-root {
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
  &:hover {
    border-radius: 3px;
    background: #eee;
  }
}

.explore-initial-container {
  margin-bottom: 50px;
}
