.genesys-regions-form-select.MuiInputBase-root,
.gensys-client-id-field .MuiInputBase-root {
  width: 268px;
  height: 40px;
}
.genesys-pop-up-title.MuiFormLabel-root {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  color: #525252;
  .MuiTypography-root {
    color: #f44336;
  }
}

.region-field-info.Mui-disabled {
  display: none;
}

.genesys-pop-up {
  .circular-loading {
    top: 15px !important;
    min-height: 160px;
  }
}
