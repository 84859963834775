.inbound-routing-popup-create-sla {
  .body-new-sla-popup {
    .description-new-sla-popup {
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15px;
      margin-bottom: 28px;
    }
    .sla-box-divider {
      height: 1px;
      background: #e2e8f0;
      margin: 24px 0;
    }
    .input-form-control,
    .input-time-form {
      .MuiInputLabel-root {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
        &.label-sla-name {
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.15px;
        }
        & span {
          font-style: italic;
          color: #80868c;
        }
        & .required {
          font-style: normal;
          color: #ef3636;
        }
      }

      .MuiInputBase-input {
        padding: 5px 12px;
        height: 30px;
        line-height: 32px;
        font-size: 16px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
      }

      p {
        color: #f44336 !important;
      }
    }
    .select-time-case-title {
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.15px;
    }
  }
}

.inbound-routing-popup-sla {
  .MuiDialog-paperWidthSm.MuiPaper-root.MuiPaper-elevation {
    top: 24px;
  }

  .inbound-routing-popup-form-body-sla {
    padding: 0;
    .inbound-routing-popup-content {
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 100px);
      overflow: hidden;
      &-header {
        padding: 24px 24px 15px 24px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.23);
        .inbound-routing-popup-title {
          font-family: Figtree;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      &-body {
        flex: 1;
        padding: 24px;
        overflow: auto;
        .sla-value-container {
          display: flex;
          flex-direction: column;
          gap: 28px;
          .sla-value-form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .MuiGrid-root.MuiGrid-container {
              align-items: center;
            }
          }
        }
      }
      &-footer {
        padding: 18px 20px;
        display: flex;
        justify-content: flex-end;
        gap: 15px;
      }
    }
  }
}
