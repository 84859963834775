@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.alert-form-view {
  .circular-loading {
    top: 245px;
  }
  .MuiPaper-root {
    min-width: 600px;
    top: 30px;
    height: 580px;
  }

  .MuiFormHelperText-root.Mui-error {
    svg {
      margin-inline-end: 4px;
    }
    @include en {
      margin-left: 12px;
    }
    @include ar {
      margin-right: 12px;
      text-align: right;
    }
  }

  #create-alerts-dialog-title.MuiDialogTitle-root {
    line-height: 32px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }
  #create-alert-dialog-content.MuiDialogContent-root {
    padding: 16px 24px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .MuiDialogActions-root {
    padding: 16px 24px;
  }
  .MuiGrid-container {
    width: calc(100% + 10px);
    margin-left: -10px;
  }

  .MuiGrid-item {
    padding-top: 21px;
    padding-left: 10px;
  }
  .MuiFormControlLabel-root {
    .MuiTypography-root {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
      font-size: 14px;
    }
  }
  .tags {
    border-color: #456eb2;
    background: #ffffff;
    border-radius: 16px;
    padding: 3px 10px;
    font-size: 13px;
    line-height: 18px;
    margin-top: 9px;
    margin-right: 5px;
    p {
      padding: 0;
      margin: 0;
      direction: ltr;
    }
    p,
    svg {
      color: #456eb2;
    }

    @include ar {
      .MuiButton-endIcon {
        margin-right: 7px;
        margin-left: 0;
        svg {
          margin-left: -5px !important;
        }
      }
    }
  }

  .MuiTooltip-tooltip {
    padding: 15px;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }

  .plus-add-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 30px;
    border-left: 1px solid #c4c4c4;
    border-radius: 0;
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 30px;

    @include ar {
      right: auto;
      left: 0;
      border-left: 0;
      border-right: 1px solid #c4c4c4;
      direction: ltr;
    }
  }

  .at-btn {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 10px;
    border-radius: 0;
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 30px;
    min-width: 50px;
    @include ar {
      left: auto;
      right: 0;
    }
  }
  .hash-btn {
    color: #80868c !important;
  }

  .input-form-control {
    .MuiInputLabel-root {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 10px;
      & span {
        font-style: italic;
        color: #80868c;
        margin-inline-start: 5px;
      }
      & .required {
        font-style: normal;
        color: #ef3636;
        margin-inline-start: 2px;
      }
    }

    .MuiInputBase-input {
      padding: 5px 12px;
      height: 30px;
      line-height: 32px;
      font-size: 14px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 400;
      @include ar {
        font-family: "IBM Plex Sans Arabic" !important;
        margin-left: 10px;
      }
    }

    .collect-field {
      & .MuiInputBase-input {
        @include ar {
          padding-right: 65px !important;
        }
        @include en {
          padding-left: 65px !important;
        }
      }
    }

    .number_text {
      .MuiInputBase-input {
        color: rgba(0, 0, 0, 0.87);
      }
    }

    .at-input-field {
      & .MuiInputBase-input {
        color: rgba(0, 0, 0, 0.87);
        @include ar {
          padding-right: 40px !important;
        }
        @include en {
          padding-left: 40px !important;
        }
      }
    }
    .hash-input-field {
      & .MuiInputBase-input {
        color: rgba(0, 0, 0, 0.87);
        @include ar {
          padding-right: 10px !important;
        }
        @include en {
          padding-left: 10px !important;
        }
      }
    }
  }

  .threshold-option-label.MuiFormLabel-root {
    margin-right: 20px;
    color: rgba(0, 0, 0, 0.87);
    @include ar {
      font-weight: 500;
    }
  }

  @include ar {
    label {
      font-family: "IBM Plex Sans Arabic" !important;
    }

    .MuiDialogActions-spacing {
      .MuiButton-root:last-child {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    .form-select {
      .MuiInputBase-input {
        padding-left: 32px;
        padding-right: 12px;
      }
      .MuiSvgIcon-root {
        right: auto;
        left: 7px;
      }
    }
  }
  ::-webkit-inner-spin-button {
    opacity: 1;
  }
}

.alert-dubai-text {
  @include ar {
    font-family: "IBM Plex Sans Arabic" !important;
  }
}

.alert-monitors-menuItem {
  padding: 0 10px !important;
  .MuiListItemText-root {
    span {
      margin: 0;
    }
  }
}

.alert-priority-tooltip {
  padding: 0 5px 5px;
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }
  table {
    padding-left: 5px;
    th,
    td {
      padding: 2px 5px;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
    th {
      font-weight: 500;
    }
  }
}

.btn-alert-form.MuiButtonBase-root {
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
  padding: 8px 22px;
}
.cancel-alert-btn.MuiButtonBase-root {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  border: none;
}
.alert-field-info.Mui-disabled {
  display: none;
}

.alert-recieve-every-field,
.alert-minimum-no-follower-field {
  width: 100%;
}

.alert-recieve-every-field,
.alert-manaul-increase-rate-field,
.alert-minimum-no-follower-field {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: textfield !important;
  }
}

.alert-threshold-input-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  .MuiButtonBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}
.alert-threshold-container {
  margin-top: 15px;
}
.alert-manaul-increase-rate {
  .MuiInputBase-root,
  .MuiInputBase-input {
    @include en {
      padding-left: 0 !important;
    }
  }
  .MuiInputAdornment-root {
    padding: 19px 15px;
    background-color: rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-inline: none;
    @include ar {
      margin-right: 0px;
    }
  }
}
.alerts-divider.MuiTypography-root {
  border: 1px solid #0000003b;
  width: 100%;
  margin: 27px 1px 12px 17px;
}
.alert-advanced-title.MuiTypography-root {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
}
.alerts-checked-email-notification.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.alerts-form-group {
  position: "relative";
}

.alerts-keywords-field {
  width: 100%;
  .MuiInputBase-root {
    height: 40px;
  }
  .Mui-error {
    color: #f44336;
  }
}

.MuiOutlinedInput-root .MuiInputAdornment-root {
  .alert-add-btn-keyword {
    display: none;
  }
}
.Mui-focused .MuiInputAdornment-root {
  .alert-add-btn-keyword {
    display: block;
    margin-right: -14px !important;
    line-height: 2 !important;
  }
}
.alert-form-select {
  .MuiSelect-select.MuiSelect-outlined {
    padding-right: 12px !important;
  }
}

.recieve-alert-field-container {
  span {
    position: relative;
    transform: translateY(-50%);
    inset-inline-end: 35px;
    font-weight: 400;
    font-size: 13px;
    color: #696d72;
    @include en {
      top: -30px;
      left: 73%;
    }
    @include ar {
      top: -33px;
      right: -85px;
    }
  }
}
.alert-type-survey-field {
  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
  }
}
.max-number-emails {
  input {
    width: 100%;
    height: 40px;
    padding: 0;
    padding-inline: 12px;
    color: rgba(0, 0, 0, 0.7);
  }
  p {
    margin-top: 10px;
    margin-inline-start: 12px;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);

    line-height: 20px;
  }

  #email-label-content.MuiFormLabel-root {
    margin-bottom: 0;
  }
  .alert-email-label-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .icon-alert-tooltip {
    width: 190px;
    height: 58px;
    padding: 8px 10px 8px 10px;

    border-radius: 4px 0px 0px 0px;
  }
  svg {
    color: rgba(0, 0, 0, 0.6);
  }
}
.alert-advanced-description.MuiTypography-root {
  font-size: 16px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
}
.Webhooks-wrapper {
  border-radius: 4px;
  border: 1px solid #e8eaf3;
  padding: 15px;
  .heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 16px;
  }
}
.alert-advanced-sub-title.MuiTypography-root {
  color: #525252;
  font-size: 15px;
  font-weight: 600;
}

.webhooks-delete.MuiButton-root {
  border-color: #f44336;
  color: #f44336;
  font-size: 12px;
  padding: 9px;
  min-width: auto;
  &:hover {
    border-color: #f44336;
  }
}
.MuiFormHelperText-root.Mui-error.msg-success {
  color: #4caf50;
}
.MuiFormHelperText-root.Mui-error.msg-warning {
  color: #c77700;
}
.MuiFormControl-root.msg-success {
  .MuiOutlinedInput-notchedOutline {
    border-color: #4caf50;
  }
}
.MuiFormControl-root.msg-warning {
  .MuiOutlinedInput-notchedOutline {
    border-color: #c77700;
  }
}
.MuiFormGroup-root.Checkbox-wrapper {
  display: block;
}
.alert-form-view .input-form-control .MuiInputLabel-root .info-icon {
  color: #9e9e9e;
  margin-inline-start: 2px;
  font-size: 14px;
}
.circular-progress-outlined {
  color: rgba(0, 0, 0, 0.26) !important;
}
.circular-progress-error {
  color: rgba(229, 0, 53, 0.26) !important;
}
.method-tooltip {
  display: inline-block;
  max-width: 202px;
}
.alert-sources-menu-title.MuiButtonBase-root {
  pointer-events: none;
  background-color: unset;
  padding: 6px 16px !important;
  font-size: 14px;
  font-weight: 500;
  color: #00000099;
  .Mui-selected {
    background-color: unset !important;
  }
}
.alert-sources-engagement-menu-Item {
  .MuiListItemText-root {
    max-width: fit-content !important;

    @include ar {
      .MuiTypography-root {
        direction: ltr;
      }
    }
  }

  .social-icons-alerts-engagement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 10px;
    .facebook-dm-icon {
      width: 20px;
      height: 20px;
    }
    .instagram-prvate-icon {
      color: #000000 !important;
    }
  }
}
.empty-design-monitors-engagement {
  color: #00000061;
  font-size: 14px;
  font-weight: 500;
  max-width: 255px;
  padding: 0 16px 6px 16px;
  margin-bottom: 3px;
  line-height: 18px;
  pointer-events: none !important;
}

.input-title-label-field.MuiFormLabel-root {
  margin-bottom: 5px !important;
}
.alert-text-field-item {
  &.MuiFormControl-root,
  &.MuiInputBase-root {
    margin-bottom: 5px;
  }
}
.input-form-control {
  .MuiInputLabel-root.body-input-label {
    font-size: 16px;
    font-weight: 400;
    color: #000000de;
    line-height: 20px;

    &.show-red-icon {
      margin-bottom: 8px;

      &::after {
        content: "*";
        color: #f44336;
        position: relative;
        font-size: 16px;
      }
    }
  }
}
#alert-tooltip {
  .icon-alert-tooltip {
    width: 163px;
    padding: 3px 6px;

    border-radius: 4px 0px 0px 0px;
  }
}

.alert-x-top-trend-auto-complete {
  margin-top: 5px;
  width: 100%;
  .MuiAutocomplete-inputRoot {
    height: 40px;
  }
  @include ar {
    .MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
      .MuiAutocomplete-endAdornment {
        right: unset;
        left: 9px;
      }
    }
  }
  .MuiInputBase-root.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
    padding: 8px 12px;
  }
  .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiInputBase-inputAdornedEnd {
    padding: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
  &.x-top-trends-has-data {
    .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiInputBase-inputAdornedEnd {
      &::placeholder {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
        font-size: 14px;
        opacity: 1;
      }
    }
  }
}
.alert-x-top-trend-world-wide-title.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  line-height: 21px;
  letter-spacing: 0.15px;
}
.alert-x-top-trend-world-wide-description.MuiTypography-root {
  font-size: 11px;
  font-weight: 400;
  color: rgba(105, 109, 114, 1);
  line-height: 16.5px;
  letter-spacing: 0.15px;
}
.x-top-trends-title-icon {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-inline-start: 4px;
}
.alert-x-top-trend-li {
  padding: 6px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.alert-x-top-trend-checkbox {
  margin-inline-end: 10px;
}
