.alert-title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.alert-date {
  color: #64748b;
  font-size: 14px;
}
.alert-view-title {
  color: #0c192a;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  .tikTok-icon {
    width: 22px;
    height: 25px;
  }
}
.title-type {
  &.MuiBox-root {
    font-weight: 700;
  }
}
.twitter-alert-head {
  color: #456eb2;
}
.alert-date {
  font-weight: 400;
  font-style: normal;
}
.alert-type-header {
  font-size: 12px;
  font-weight: 500;
  color: #0c192a;
  padding-inline: 35px;
}
