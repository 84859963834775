@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin flexbox(
  $display: flex,
  $direction: row,
  $placeContent: null,
  $placeItems: null,
  $wrap: nowrap,
  $shrink: 0,
  $grow: 0,
  $alignContent: null,
  $justifyContent: null,
  $alignItems: null,
  $justifyItems: null
) {
  display: $display;
  flex-direction: $direction;

  @if $placeContent == null {
    @if $alignContent {
      align-content: $alignContent;
    }
    @if $justifyContent {
      justify-content: $justifyContent;
    }
  } @else {
    place-content: $placeContent;
  }

  @if $placeItems == null {
    @if $alignItems {
      align-items: $alignItems;
    }
    @if $justifyItems {
      justify-items: $justifyItems;
    }
  } @else {
    place-items: $placeItems;
  }

  flex-wrap: $wrap;
  flex-shrink: $shrink;
  flex-grow: $grow;
}
///------------------------------------------///
.engager-widget-head {
  color: #a4a9af;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.personal-info-wrapper {
  .grid-width {
    width: 150%;
  }
  .user-info-box {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 20px;
    overflow: hidden;
  }
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 20px;
}
.ai-insights {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  @include flexbox($alignItems: center);
  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    @include ar {
      margin-left: 5px;
      margin-right: unset;
    }
  }
}
.profile-user-name {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 20px;
}
.discover-deep {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-top: 10px;
  text-align: center;
  @include flexbox(
    $justifyContent: center,
    $alignItems: center,
    $direction: column
  );
}
.discover-deep-wrapper {
  img {
    width: 45px;
    height: 45px;
  }
  @include flexbox(
    $justifyContent: center,
    $alignItems: center,
    $direction: column !important
  );
}
.profile-detail {
  color: #334155;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  display: flex;
  span {
    font-weight: 500;
    width: 110px;
  }
}
.personal-info-stats {
  @include flexbox($alignItems: flex-start);
  position: relative;
}
.account-data {
  @include flexbox(
    $justifyContent: center,
    $alignItems: center,
    $shrink: unset
  );
  .personal-info-name-wrapper {
    margin: 0px 30px;
  }
  .personal-info-style {
    margin: 0px 50px 0px 20px;
  }
}

.grid-style {
  text-indent: 5px;
}

.personal-info-img {
  position: relative;
  img {
    width: 75px;
    height: 75px;
    border-radius: 5px;
  }
}
.user-bio {
  margin-top: 10px;
  color: #334155;
  font-weight: 600;
  font-size: 14px;
  div {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 2;
  }
}
.personal-info {
  color: #334155;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 4px;
  @include flexbox($alignItems: center, $shrink: unset);
  svg {
    margin-right: 10px;
    color: rgba(100, 116, 139, 0.7);
    font-size: 13px;
    @include ar {
      margin-right: unset;
      margin-left: 10px;
    }
  }
  span {
    font-weight: 600;
  }
}
.personal-info-name {
  margin-bottom: 15px;
  @include flexbox($alignItems: center);
}

.personal-info-name {
  .screen-name {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }
  svg {
    font-size: 15px;
    font-weight: 400;
    margin: 0px 3px;
  }

  span {
    color: #80868c;
    font-size: 13px;
    font-weight: 400;
  }
}
.author-twitter-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #000000;
  position: absolute;
  left: -15px;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  @include ar {
    right: -15px;
    left: unset;
  }
  img {
    width: 16px;
    height: 16px;
  }
  svg {
    color: #fff;
    font-size: 13px;
  }
}
.analyze-profile-btn {
  &.MuiButton-root {
    background: #2aaae2;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 15px;
    height: 40px;
    cursor: pointer;
    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
    @include flexbox(
      $justifyContent: center,
      $alignItems: center,
      $shrink: unset
    );
    &:hover {
      background-color: #2aaae2;
    }
  }
}
.external-link {
  position: absolute;
  right: 0;
  top: 0;
  color: rgba(100, 116, 139, 0.6);
  font-size: 15px;
  @include ar {
    left: 0;
    right: unset;
  }
}
.monitor-engagement {
  background-color: #fff;
  .chart {
    box-shadow: unset;
  }
}
.chart.survey-insights-chart {
  padding: 50px 20px;
}
.engagement-right {
  color: #6d737a;
  font-size: 15px;
  font-weight: 500;
  width: max-content;
  &.rate {
    margin-top: 30px;
  }
  div {
    font-weight: 600;
    color: #2aaae2;
    font-size: 18px;
  }
  svg {
    font-size: 12px;
    padding-bottom: 5px;
    @include ar {
      padding-right: 5px;
    }
    @include en {
      padding-left: 5px;
    }
  }
}
@media (max-width: 1400px) {
  .engagement-right {
    font-size: 13px;
  }
}
.rate-tooltip {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  color: rgba(100, 116, 139, 0.7);
  font-size: 14px;
}
.personal-joined-date {
  margin: 0px 0px 0px 10px;
}
.verified-icon {
  color: #2aaae2;
}

//deep insights modal
.deep-insights-modal {
  .deep-number {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 500;
  }
  .deep-posts-limit {
    &.MuiBox-root {
      font-weight: 400;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.87);
      margin-top: 10px;
    }
  }
  .deep-section-note {
    color: rgba(0, 0, 0, 0.6);
    margin: 10px 0px;
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .deep-posts-remainig {
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    font-weight: 400;
    margin: 20px 0px 10px;

    svg {
      color: rgba(0, 0, 0, 0.54);
      font-size: 15px;
      margin-inline-start: 10px;
    }
  }
}
.step-footer {
  &.deep-insight {
    margin-top: 20px;
    button {
      width: unset;
      height: unset;
      &.cancel-btn {
        &.MuiButton-root {
          border: none !important;
        }
      }
    }
  }
}
.deep-insight-validation {
  margin-bottom: 25px !important;
  &.MuiFormHelperText-root {
    color: #e31b0c !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    @include ar {
      text-align: right;
    }
  }
}

.deep-number-loader {
  color: rgba(0, 0, 0, 0.38) !important;
  font-size: 15px;
  font-weight: 500;
}
//custimze for posts analysis &  deep insights
.deep-posts-remainig-content {
  font-size: 16px;
  font-weight: 400;
  color: #000000de;
  .deep-number {
    font-weight: 600;
  }
  margin: 20px 0px 10px;
  svg {
    font-size: 16px;
    margin-inline-start: 6px;
  }
}
.deep-insights-content-modal.MuiBox-root {
  .create-historical-calendar {
    .label-container-calendar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .post-publish-date {
        font-weight: 400;
        font-size: 12px;
        color: #00000099;
      }
    }
  }
  .step-footer {
    display: flex;
    justify-content: flex-end;
    #cancel-btn-deep-insight {
      margin-inline: 10px;
    }
  }
}
.single-tweet-qouta-content.MuiBox-root {
  font-size: 14px;
  font-weight: 400;
  color: #00000099;
  strong {
    color: #00000099;
    font-weight: 600;
  }
}
