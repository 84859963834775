@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.details-side-bar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @include en {
    padding: 17.5px 20px;
  }
  @include ar {
    padding: 14px 20px;
  }
  border-bottom: 1px solid #e2e8f0;
  .side-bar-name-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .side-bar-close-wrapper {
    font-size: 24px;
    cursor: pointer;
    color: #757575;
  }
  .side-bar-name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .user-source {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: #ffffff;
    &.facebook-icon {
      background-color: #1877f2;
    }
    &.twitter-icon {
      background-color: #000000;
    }
    &.instagram-icon {
      background: -webkit-radial-gradient(
        30% 107%,
        circle,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
    }
    &.whatsapp-icon {
      svg {
        width: 25px;
        height: 25px;
        color: #25d366;
      }
    }
  }
}
