.loading-overlay,
.marked-spam-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
}
.loading-overlay {
  background-color: #d8dee650;
  backdrop-filter: blur(3px);
}
.marked-spam-overlay {
  background-color: #1a174f80;
  backdrop-filter: blur(10px);
}
.overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  & > .loading-btn {
    width: 25px !important;
    height: 25px !important;
    color: #00000060;
  }

  & > .marked-spam-icon {
    font-size: 40px;
    color: #fff;
  }
  & > h3 {
    font-weight: 500;
    margin-bottom: 0px;
  }
  & > p {
    width: 60%;
    text-align: center;
    color: #c5cae2;
  }
  & > .undo-btn {
    color: #fff;
    border-color: #fff;
    padding: 12px 20px;
    &:hover {
      background-color: #e6f3fc40;
      border-color: #fff;
    }
  }
}
