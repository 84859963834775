@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#main-pdf-container-page {
  z-index: -99;
  opacity: 0;
  position: absolute;
  top: -1000%;
  left: -1000%;

  * {
    //for loom extension with Google chrome
    letter-spacing: normal;
  }

  #main-pdf-content-page,
  #main-pdf-content-cover {
    max-width: 210mm; /* A4 width */
    min-height: 297mm; /* A4 height 1122px*/
    padding: 40px 48px;
    position: relative;

    #main-pdf-content-widgets {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 40px;
      & > div {
        width: 100%;
        border: 1px solid #e2e8f0;
        box-shadow: 0px 4px 8px 0px #00000014;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .main-pdf-header-text {
      height: fit-content;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #232152;
      font-size: 13px;
      font-weight: 400;
      line-height: 19.59px;
      border-bottom: 6px solid #232152;
      margin-bottom: 74px;
      padding-bottom: 9px;
      img {
        width: 100px;
      }
      .pdf-header-text-dashboard {
        line-height: 18.59px;
        margin: 0;
      }
    }

    .fixed-border-bottom {
      width: calc(100% - 96px);
      height: 6px;
      background-color: #232152;
      position: absolute;
      bottom: 40px;
    }
  }
}
