@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

// Topics Index File styles

.topics-settings {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #232b34;
  letter-spacing: 0.03333em;
  display: inline-block;
  padding-top: 0px !important;
  margin-top: -12px;
}


#add-topic-btn-settings {
  @include en {
    margin-left: 18px;
  }
  @include ar {
    margin-right: 18px;
  }
}

#import-file-btn {
  @include en {
    margin-right: 10px;
  }
  @include ar {
    margin-left: 20px;
  }
}

#add-topic-btn {
  @include en {
    margin-left: 10px;
  }
}

#disabled-add-topic-btn {
  @include en {
    margin-left: 10px;
  }
}

#disabled-import-file-btn {
  @include en {
    margin-right: 10px;
  }
  @include ar {
    margin-left: 20px;
  }
}

#disabled-add-topic-btn-settings {
  @include en {
    margin-left: 18px;
  }
  @include ar {
    margin-right: 18px;
  }
}

// Topic Card File Styles
.topic-card-number {
  font-size: 14px;
  color: #0f172a;
  font-weight: 400;
}

#topic-label-field {
  font-size: 16px;
  margin-top: 50px;
  font-weight: 500;
  @include en {
    margin-left: 15px;
  }
  @include ar {
    margin-right: 15px;
  }
}

.topic-text-field-title {
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 20px;
}

.topic-text-field-input {
  height: 12px;
  width: -webkit-fill-available !important;
  @include en {
    margin-right: 20px !important;
  }
  @include ar {
    margin-left: 20px !important;
  }
}

.topic-text-field-input {
  .MuiOutlinedInput-root {
    height: 50px;
  }
}

.topic-box {
  margin-bottom: 30px !important;
}
.topic-keywords-box {
  margin-bottom: 5px !important;
  margin-top: 0 !important;
}
.topic-keywords-div {
  margin-bottom: 5px;
}

.remove-topic-icon {
  color: #f44336;
  font-size: 25px;
}

.remove-topic-position {
  margin-bottom: -40px;
  background-color: white;
  border: 1px solid white;
  border-radius: 4px;
  color: #f44336;
  position: relative;
  top: 165px;

  &:hover {
    background-color: #fde8ec;
    border: 1px solid #ea758c;
  }
  @include en {
    float: right;
    left: 60px;
    padding: 6px 3px 2px 7px;
  }
  @include ar {
    float: left;
    right: 60px;
    padding: 6px 10px 0px 2px;
  }
}
.remove-icon-setting {
  background-color: #f8fafc;
  border: 1px solid #f8fafc;
}

.keywords-label-box {
  display: flex;
  flex-direction: row;
}

#keywords-label-info-icon {
  top: 24px;
  color: #cbd5e1;
  background-color: #fff;
  margin-top: 50px;
  @include en {
    padding-left: 8px;
  }
  @include ar {
    padding-right: 8px;
  }
}

.social-media-field {
  padding: 0px;
  position: static;
  width: 90%;
  height: 40px;
  border-radius: 4px;
  margin: 5px 0px;
  @include ar {
    ::placeholder {
      float: right;
    }
  }
}
.topics-err-msg {
  color: #f44336;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 14px;
  font-weight: 400;
  width: 452px !important;
  @include ar {
    text-align: right;
  }
}

.topics-tree {
  margin-top: 16px;
  li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      border-bottom: 1px solid #ddd;
      width: 15px;
      border-radius: 3px;
      @include en {
        top: -35px;
        left: -20px;
        border-left: 1px solid #ddd;
        height: 40px;
      }
      @include ar {
        top: -40px;
        right: -20px;
        border-right: 1px solid #ddd;
        height: 55px;
      }
    }
    &:after {
      position: absolute;
      content: "";
      border-top: 1px solid #ddd;
      width: 15px;
      height: 100%;
      @include en {
        top: 5px;
        left: -20px;
        border-left: 1px solid #ddd;
      }
      @include ar {
        top: 15px;
        right: -20px;
        border-right: 1px solid #ddd;
      }
    }
    &:last-child:after {
      display: none;
    }
  }

  .empty-li-tree-style {
    &:before {
      @include ar {
        top: -25px;
        right: -20px;
        border-right: 1px solid #ddd;
        height: 40px;
      }
    }
  }
}

.remove-tree-style {
  list-style-type: none;
  @include en {
    margin-top: -5px;
    margin-left: -40px;
  }
  @include ar {
    // margin-top: -10px;
    margin-top: -18px;
    margin-right: -40px;
  }
}
.topics-keywords-field {
  top: 0px !important;
  margin-bottom: 10px !important;
  .add-btn-with-plus {
    margin-right: -12px;
    display: none;
  }
  &.Mui-focused {
    .add-btn-with-plus {
      display: block;
    }
  }
}

.MuiOutlinedInput-root .MuiInputAdornment-root {
  .add-btn-edit-topic {
    display: none;
  }
}
.Mui-focused .MuiInputAdornment-root {
  .add-btn-edit-topic {
    display: block;
    margin-right: -14px !important;
    line-height: 2.1 !important;
  }
}

.topics-settings-title {
  color: #475569;
  text-transform: uppercase;
  @include ar {
    margin-bottom: 32px;
  }
}
.topics-settings-sub-title {
  color: #64748b;
  font-size: 13px;
  font-weight: 400;
  @include en {
    margin-left: 6px;
    margin-bottom: 23px;
  }
  @include ar {
    margin-right: 6px;
    margin-top: 4px;
  }
}

.buttons-case {
  margin-top: "20px";
}

.buttons-box {
  @include ar {
    margin-left: 10px;
  }
}

.import-icon {
  font-size: 12px;
}

.monitor-settings-card-box {
  background-color: #fff;
}

.topic-card-shape {
  border-radius: 5px;
}

.topic-style {
  display: flex;
  flex-direction: column;
}
.topic-style.customer-care-style {
  padding: 0;
}
.topics-settings-box,
.monitors-settings-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.monitors-settings-box {
  margin-top: 40px;
}
.subscription_topic {
  color: #456eb2;
}
.manage-topic-alert {
  .MuiAlert-message {
    display: flex;
    flex-direction: row;
  }
  #monitor-options-manage-topic-alert-btn {
    color: #1e293b;
    margin-right: 10px;
    width: 108px;
    height: 42px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #cbd5e1;
    @include en {
      margin-left: 75px;
    }
    @include ar {
      margin-right: 243px;
    }
  }
}
.manage-topic-text-msg {
  @include ar {
    margin-right: 5px;
  }
}
.manage-topics-alert-msg {
  width: 95.5%;
  @include en {
    margin-left: 50px;
  }
  @include ar {
    margin-right: 50px;
  }
}

#manage-topics-alert {
  border-radius: 5px;
  padding: 3px 16px;
  border: 1px solid #cbd5e1;
  margin-bottom: 8px;
}
.topic-field-create,
.topic-field-edit {
  width: 95%;
  .MuiOutlinedInput-root {
    height: 40px;
    margin-top: 6px;
  }
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-input {
      @include ar {
        padding-left: 25px;
      }
    }
  }
}

.topic-field-edit {
  width: 468px;
  @include en {
    margin-right: 15px;
  }
  @include ar {
    margin-left: 15px;
  }
}

.topic-field-edit {
  label {
    font-size: 13px;
  }
  @include en {
    top: 8px;
  }
  @include ar {
    label {
      left: auto !important;
      font-family: "Dubai" !important;
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
  }
}

.topic-name-input,
.topic-keywords-input {
  @include en {
    label {
      &.Mui-focused,
      &.MuiFormLabel-filled {
        top: 7px;
      }
    }
  }
  @include ar {
    label {
      transform: translate(-10px, 15px) scale(1);

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(3px, -3px) scale(0.75);
        background: #ffffff;
        padding: 3px;
      }
    }
  }
}

.topic-box-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topic-keywords-edit-container {
  margin-bottom: 20px;
  .keyword-spam-wrapper {
    width: 468px;
  }
}

.input-label-topic-keyword {
  border-bottom: 1px dashed rgba(100, 116, 139, 0.5);
  position: relative;
  position: relative;
  top: -70px;
  // top: -50px;
}

// .topic-keywords-tooltip {
//   @include en {
//     margin-right: 100px;
//   }
// }
// .topic-name-box-edit {
//   margin-bottom: 15px !important;
// }

.topic-name-label {
  margin-top: 20px !important;
}
.topic-name-label-more {
  margin-top: 25px !important;
}

.topic-name-box-edit {
  .topics-err-msg {
    @include en {
      margin-top: 20px;
    }
  }
}
.topic-name-input {
  @include ar {
    margin-top: 7px;
  }
}

.optional-style {
  color: #64748b;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  @include en {
    margin-left: 6px;
  }
  @include ar {
    margin-right: 6px;
  }
}
