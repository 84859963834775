@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.audience-import-pop-up-container {
  margin-top: 70px;
}
.audience-import-pop-up {
  width: 600px;
  min-width: 600px;
  overflow: hidden;
  #audience-import-dialog-title {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    line-height: unset;
  }
  #dialog-content-import-popup.MuiDialogContent-root {
    border-top: 1px solid #0000001f;
    border-bottom: 1px solid #0000001f;
    padding-top: 18px;
    &.dialog-content-import-uploaded {
      height: 415px;
      overflow-y: auto;
    }
  }
  .audience-import-desc {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
  .upload-file-title,
  .download-file-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
  }
  .audience-upload-file-container {
    margin-top: 20px;
  }
  .upload-file-card {
    margin-top: 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 5px;
    background: #f8fafc;
    border: 2px dashed rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    &:hover {
      border: 2px dashed #64748b;
      cursor: pointer;
    }
  }
  .upload-error-message {
    border-color: #f44336;
  }
  .upload-file-data {
    text-align: center;
    padding: 25px 20px;
  }
  .import-upload-icon {
    color: #64748b;
    font-size: 35px;
  }
  .import-browse-file {
    cursor: pointer;
    color: rgba(69, 110, 178, 1);
    font-size: 18px;
    font-weight: 400;
  }
  .import-cancel-pop-up {
    border: none;
    color: rgba(0, 0, 0, 0.6);
    margin-inline: 5px;
    &:hover {
      border: none;
    }
  }

  .upload-accepted-formats-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 5px;
    margin-bottom: -8px;
  }
  .import-divider {
    border: 1px solid #cbd5e1;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .enable-upload-box {
    @include ar {
      margin-right: -14px;
    }
    .MuiTypography-root {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .upload-file-header {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 18px;
    margin-inline-end: 3px;
  }
  .download-file-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-inline: 12px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    margin-top: 8px;
  }
  .import-download-icon {
    padding: 8px 12px;
    border-radius: 2px;
    &:hover {
      background-color: #f1f5f9;
    }
    svg {
      color: #00000099;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .download-file-header,
  .excel-file-name-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
  .manage-import-file-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #456eb2;
  }
  .import-action-container {
    display: flex;
    justify-content: space-between;
    padding-inline: 20px;
  }
  .excel-file-container {
    background: #f8fafc;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 16px;
  }
  .excel-file-container {
    display: flex;
    justify-content: space-between;
  }
  .close-upload-file {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid rgba(244, 67, 54, 0.3);
    border-radius: 2px;
    &:hover {
      background: rgba(244, 67, 54, 0.1);
    }
    cursor: pointer;
    svg {
      color: #f44336;
      font-weight: 400;
      line-height: 26px;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .drop-file-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .excel-file-content {
    display: flex;
    justify-content: center;
  }
  .uploaded-file-content {
    margin-inline-start: 15px;
  }
  .excel-file-icon {
    width: 35px;
  }
  .upload-file-error-msg {
    margin-inline: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
  }
  .download-icon-fail {
    pointer-events: none;
  }
  .manage-import-action-container {
    justify-content: end;
  }
}
.audience-import-manage {
  text-decoration: underline;
  font-weight: 500;
}
