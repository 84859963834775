@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.add-user-form {
  margin: 20px;
  .input-user-settings-label {
    color: #000000de;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .phone-number-users-label {
    @include en {
      fieldset {
        legend {
          span {
            padding-left: 10px !important;
            padding-right: 0 !important;
          }
        }
      }
    }
  }

  .add-user-header {
    color: #232b34;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
  }

  .add-user-subtitle {
    color: #475569;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    @include ar {
      text-align: right;
    }
  }

  label.MuiFormLabel-root.MuiInputLabel-root {
    @include ar {
      text-align: right;
    }

    &.MuiInputLabel-shrink {
      @include ar {
        right: 17px !important;
        left: auto;
        transform: translate(5px, -9px) scale(0.75);
        transform-origin: top right;
      }
    }
  }

  .MuiOutlinedInput-root {
    input {
      color: #000000de;
      height: 40px;
      box-sizing: border-box;
    }
  }
}
