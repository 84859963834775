@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.phone-number-container {
  .phone-number-input-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    fieldset {
      display: none;
    }
    label {
      padding-inline: 3px;
      &.MuiInputLabel-shrink,
      &.Mui-focused,
      &.MuiFormLabel-filled {
        background-color: #ffffff;
      }
    }
    &:hover {
      .phone-number-details {
        border-color: rgba(0, 0, 0, 87);
      }
      .MuiOutlinedInput-root {
        border-color: rgba(0, 0, 0, 87);
      }
      input {
        border-color: rgba(0, 0, 0, 87);
      }
    }
    &.rtl {
      flex-direction: row-reverse;
    }
    &.wrapper-focused {
      .phone-number-details {
        border-color: #456eb2;
        border-width: 2px;
        padding-right: 16px !important;
      }
      .MuiOutlinedInput-root {
        border-color: #456eb2;
        border-width: 2px;
      }
    }
    &.wrapper-opened {
      .phone-number-details {
        border-color: #456eb2;
        border-width: 2px;
        padding-right: 16px !important;
        .phone-number-country {
          color: #456eb2;
        }
        svg {
          fill: #456eb2;
        }
      }
      input,
      .MuiOutlinedInput-root {
        border-color: #456eb2 !important;
        border-width: 2px;
      }
    }
    .phone-number-details {
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      padding: 0 15px;
      border: 1px solid #0000003b;
      border-right: 0;
      border-radius: 4px 0 0 4px;
      box-sizing: border-box;
      min-width: 100px;
      text-align: center;
      justify-content: center;
      &.rtl {
        flex-direction: row-reverse;
      }
      > * {
        box-sizing: border-box;
      }
      svg {
        fill: #00000099;
      }
      .phone-number-country {
        text-wrap: nowrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
        text-align: left;
        color: #00000099;
        white-space: nowrap;
      }
    }
    .MuiOutlinedInput-root {
      border-style: solid;
      border-width: 1px;
      border-color: #0000003b;
      border-radius: 0 4px 4px 0;
      box-sizing: border-box;
      height: 40px;
    }
    .MuiOutlinedInput-root {
      &.Mui-focused {
        input {
          border-color: #456eb2 !important;
        }
        border-color: #456eb2 !important;
      }
    }
    .phone-number-country-select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
    }
  }
}
.phone-number-country-select-menu {
  direction: ltr;
  li {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
    color: #000000de;
  }
}
