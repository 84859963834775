$paddingSection: 24px;
.inbound-routing-popup-footer-agent-capacity {
  .MuiPaper-root.MuiPaper-elevation {
    top: 24px;
  }
}
.inbound-routing-popup-create-agent-capacity {
  max-height: calc(100vh - 100px);
  min-width: 600px;
  display: flex;
  flex-direction: column;
  .loading-agent-capacity-popup {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 10;
    .circular-loading {
      position: initial;
    }
  }

  .inbound-routing-popup-footer-agent-capacity {
    padding-bottom: 20px;
    padding-inline-end: 20px;
  }
  .inbound-routing-popup-form-body-agent-capacity {
    position: relative;
    max-height: 450px;
    &.loading {
      overflow: hidden;
    }
  }
  h2 {
    border-bottom: solid 1px rgba(0, 0, 0, 0.23);
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    .define-agent-capacity-icon {
      margin-inline-start: 8px;
      .method-tooltip {
        display: inline-block;
        max-width: 202px;
      }
    }
  }

  .body-create-agent-capacity-popup {
    .description-agent-capacity-popup {
      padding-top: 16px;
      h4,
      span {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.15px;
        margin: 0;
        &.define-agent-capacity {
          color: rgba(0, 0, 0, 0.87);
        }
        &.setting-agent-capacity {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .switch-distribute-according-capacity,
    .check-customize-per-agent {
      margin-block: $paddingSection;
      .MuiFormControlLabel-root {
        margin-right: 0;
      }
      .MuiFormControlLabel-label {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.87);
      }
    }

    .agent-engagements-count-section {
      border-top: 1px solid rgba(226, 232, 240, 1);
      padding: $paddingSection 0 0 0;
      .agent-capacity-main-count {
        label {
          border-bottom: 1px dashed #94a3b8;
        }
        .MuiInputLabel-root {
          margin-bottom: 10px;
        }
      }
    }
  }
  .agent-capacity-count-users {
    .MuiInputLabel-root {
      margin-bottom: 5px;
    }
  }
  .input-form-control {
    .MuiInputLabel-root {
      max-width: max-content;
      font-size: 16px;
      line-height: 18px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.87);
      & span {
        font-style: italic;
        color: #80868c;
      }
      & .required {
        font-style: normal;
        color: #ef3636;
      }
    }

    .MuiInputBase-input {
      padding: 5px 12px;
      height: 30px;
      line-height: 32px;
      font-size: 16px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }
    .method-tooltip {
      display: inline-block;
      max-width: 202px;
    }
  }
  .disabled-field {
    .MuiTypography-root.MuiTypography-body1 {
      color: #00000061;
    }
    .MuiButtonBase-root.MuiSwitch-switchBase {
      color: #f5f5f5;
    }
  }
}
