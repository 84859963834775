@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.popup-details-container {
  .publish-new-post-title {
    text-transform: capitalize;
  }
  .popup-details-loading {
    width: 100%;
    height: 530px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .publish-body {
    height: calc(530px - 60px);
    overflow-y: auto;
  }
  .action-btns {
    padding: 20px 20px 30px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative !important;

    div {
      justify-content: center;
      align-items: center;
      gap: 8px;
      display: flex;
      > button {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
        display: flex;
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        text-transform: capitalize;
        gap: 8px;
        min-height: 36px;
        @include ar {
          font-size: 13px;
        }
        svg {
          font-size: 15px;
        }
        svg.data-sources-icon-instagram {
          color: #e1306c;
        }
        svg.data-sources-icon-facebook {
          color: #1877f2;
        }
        svg.data-sources-icon-twitter {
          color: #1da1f2;
        }
      }
    }
    > button {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 5px 10px;
      min-width: auto;
      justify-content: center;
      align-items: center;
      display: flex;
      display: flex;
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      min-height: 36px;
      min-width: 36px;
    }
  }
  .content-box {
    padding: 30px 20px;
    .content-title,
    .description {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 15px;
    }
    .description {
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0px;
      margin-bottom: 15px;
    }
  }
  .item-publish-detils {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 30px 0;
    &:last-child {
      padding-bottom: 0;
    }
    .publish-detils-start,
    .publish-detils-end {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .secondary-title {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
      }
      > p {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.16px;
      }
    }
  }
  .user-details {
    display: flex;
    align-items: center;
    span.user-img-char {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 0.706px solid #cbd5e1;
      background: #e0e0e0;
      color: rgba(0, 0, 0, 0.6);
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-inline-end: 8px;
    }
  }
  span.main-user-img {
    display: inline-block;
    overflow: hidden;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 0.706px solid #cbd5e1;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .user-box {
    display: flex;
    align-items: center;
    p {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }
  .img-accounts-publish {
    position: relative;
    width: max-content;
    margin-inline-end: 5px;
    > img {
      width: 12px;
      height: 12px;
      position: absolute;
      bottom: 1px;
      inset-inline-end: 1px;
    }
  }
  .external-link-accounts-publish {
    width: 16px;
    height: 16px;
    color: #00000099;

    margin-inline-start: 6px;
    cursor: pointer;
    @include ar {
      transform: rotate(270deg);
    }
  }
  .attachments-img-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 30px;
    .attachments-img {
      width: 66px;
      height: 46px;
      overflow: hidden;
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      border: 0.663px solid #cbd5e1;
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        object-fit: cover;
      }
    }
  }
  .icon-time {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin-inline-end: 8px;
  }
  .status-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 900;
    line-height: normal;
    margin-inline-end: 8px;
    svg {
      font-size: 14px;
      font-weight: 900;
      line-height: normal;
      margin-inline-end: 8px;
    }
  }
  .status-box.scheduled {
    svg {
      color: #ed6c02;
    }
  }
  .status-box.published {
    svg {
      color: #3b873e;
    }
  }
  .status-box.failed {
    svg {
      color: #e50035;
    }
  }
  .btn-copy-id {
    position: relative;
  }
  .snakbar-box {
    position: absolute;
    bottom: -40px;
    transform: translateX(-50%);
    @include en {
      inset-inline-start: 50%;
    }
    @include ar {
      inset-inline-end: 50%;
    }
    display: flex;
    padding: 8px 10px;
    border-radius: 4px;
    background: #3b873e;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    width: max-content;
  }
  .del-btn-menu {
    border: 1px solid #0000003b;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    &.open {
      background-color: rgba(0, 0, 0, 0.04);
    }
    svg {
      color: #000000de;
    }
  }
}

#del-from-calender {
  display: flex;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #000000de;
  align-items: center;
  svg {
    height: 19px;
    width: 18px;
    color: #000000de;
  }
}
#del-from-calender-munu {
  .MuiPaper-root.MuiPaper-elevation {
    right: 42px !important;
    left: unset !important;
    margin-top: 10px;
    @include ar {
      left: 42px !important;
      right: unset !important;
    }
  }
}
#main-del-popup-calendar {
  .del-popup-contant {
    font-weight: 600;
    .about-del {
      font-weight: 400;
    }
  }
  button.del-rep-btn {
    min-width: 100px;
  }
  button.cancel-rep-btn {
    border: none;
    &:hover {
      background-color: #fff;
    }
  }
}
.popup-details-container .attachments-img-box{flex-wrap: wrap}
