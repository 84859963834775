@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.time-span {
  display: block;
  color: #80868c;
  font-size: 12px;
}
.lucidya-apis-list {
  .MuiPaper-root {
    margin-top: 5px;
    box-shadow:
      0px 3px 14px 2px rgba(0, 0, 0, 0.12),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 5px 5px -3px rgba(0, 0, 0, 0.2);
    @include ar {
      margin-left: 0px;
    }
  }
}
#settings-dropdown-lucidya-apis-long-button {
  &.MuiButtonBase-root {
    width: 30px;
    height: 30px;
    &:hover {
      background-color: #f1f5f9;
      color: #757575;
    }
    &[aria-expanded="true"] {
      background-color: #cbd5e1;
      border-radius: 4px;
      color: #757575;
    }
  }
}
.lucidya-apis-list {
  .MuiMenuItem-root {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 5px;
    padding-top: 5px;
    svg {
      width: 18px !important;
    }
  }
}
