@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.steps-btn-parant {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  button {
    height: 42px;
    margin-top: 35px;
  }
  .MuiButton-root.puplish-btn {
    border: 1px solid #456eb2 !important;
    color: #3e63a0;
    padding: 0 12px;
    &:hover {
      background: rgba(230, 243, 252, 0.5);
    }
  }
  .Mui-disabled {
    opacity: 0.2;
  }
}
.survey-publish-btn {
  @include ar {
    margin-right: 10px !important;
  }
}
