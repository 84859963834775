@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.luci-no-request-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-inline: 50px;
  margin-top: 300px;
}
.luci-no-request {
  color: #212121;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.luci-get-started {
  color: #676767;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.luci-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 250px;
  .MuiCircularProgress-root {
    width: 30px !important;
    height: 30px !important;
    color: #cbcbcb;
  }
}
.luci-requests-pagination {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  .MuiButtonBase-root {
    .MuiSvgIcon-root {
      @include ar {
        transform: rotate(180deg);
      }
    }
  }
}
