@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#sentiment-categories-themes-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  .btns-divider {
    height: 20px;
  }
  button {
    border-radius: 6px !important;
    width: max-content;
    > svg {
      margin: 0px;
    }
  }
}

// Sentiments Menu
#sentiments-menu {
  li.sentiments-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
}

// Categories Menu
#categories-menu {
  li.categories-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    > span {
      padding: 2px 10px;
    }
  }
}

.themes-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

// Themes Menu
#themes-menu {
  max-height: 320px;
  .circular-loading-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 275px;
    > .circular-loading.MuiBox-root.css-0 {
      position: absolute;
      padding: 0px;
      margin: 0px;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  li.themes-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    @include en {
      padding: 7px 20px 7px 10px;
    }
    @include ar {
      padding: 7px 10px 7px 20px;
    }
    > span {
      padding: 2px 10px;
    }
  }
}

.rendered-value-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  .theme-count-box {
    width: 25px;
    height: 18px;
    border-radius: 34px;
    background-color: #64748b;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.theme-color-box {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-inline-end: 10px;
  &.rendered-value {
    width: 14px;
    height: 14px;
    margin-inline-end: 0px;
  }
}

a#customized-themes-link-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 15px 25px;
  color: #456eb2;
}
