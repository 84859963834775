.edit-image-branding-modal {
  height: 600px;
  width: 600px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  .header-edit-image-branding-modal {
    padding: 24px 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
    }
  }

  .container-image-cropper {
    height: 73%;
    .cropper {
      height: 90%;
      position: relative;
      .reactEasyCrop_CropArea {
        max-height: calc(100% - 40px) !important;
      }
    }

    .slider {
      height: 10%;
      display: flex;
      align-items: center;
      margin: auto;
      width: 60%;
    }

    .app {
      height: 100%;
      .dropzone {
        width: 100%;
        height: 98%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f8fafc;
        cursor: pointer;
        &:hover {
          background-color: rgba(241, 245, 249, 1);
        }
        .image-upload {
          text-align: center;
          color: #456eb2;
          font-weight: 600;
          line-height: 28px;
          font-size: 18px;
          svg {
            font-size: 40px;
            line-height: 28px;
            font-weight: 400;
            margin-bottom: 16px;
          }
        }
      }
      .logo-error {
        color: #e50035;
        font-weight: 400;
        font-size: 12px;
        margin-top: 10px;
        display: block;
        text-align: center;
      }
    }
  }

  .edit-modal-container-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    .edit-modal-submit .cancel {
      margin-inline-end: 8px;
    }
  }
}
