.notfound-notification-view-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    inset: 0;
    svg {
      font-size: 64px;
      font-weight: 300;
      color: #cbd5e1;

    }
    .MuiTypography-root {
      margin: 15px 0px;
      color: #000000DE;
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
    }
  }
  .notfound-notification-desc {
    font-size: 15px;
    font-weight: 400;
    max-width: 30%;
    color: #64748B;
    text-align: center;
    .notifications-link {
      font-size: 500;
      color: #456eb2;
      text-decoration: underline;
    }
  }
  