@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.avgerage-stats-widget {
  .avgerage-stats-widget-fetching {
    padding: 50px;
  }
  border: 1px solid #e2e8f0;
  box-shadow: 0px 4px 8px 0px #00000014;
  background-color: #ffffff;
  border-radius: 5px;
  .MuiTypography-root {
    text-wrap: nowrap;
    white-space: nowrap;
  }

  .sentiment-legends-container {
    display: none;
  }
  .widget-box {
    .widget-header-container-dashboard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      box-sizing: border-box;
      padding: 20px;
      .MuiGrid-item {
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  .average-stats {
    &-card {
      padding: 20px;

      &-container {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid transparent;
        cursor: pointer;
        &:hover {
          border-radius: 8px;
          background: #f8fafc;
          border: 1px solid #f1f5f9;
        }
        &.menu-open {
          border-radius: 8px;
          background: #f8fafc;
          border: 1px solid #f1f5f9;
        }
        &.not-clickable {
          cursor: auto;
          &:hover {
            border-radius: 0;
            background: #ffffff;
            border: 1px solid transparent;
          }
        }
      }
      &-icon {
        min-width: 70px;
        width: 70px;
        min-height: 70px;
        height: 70px;
        border-radius: 4px;
        background: #f1f5f9;
        display: flex;
        justify-content: center;
        align-items: center;
        .custom-stats-icon {
          width: 35px;
          height: 35px;
          color: #456eb2;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 10px;
      }
      &-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #000000de;
        border-bottom: 1px dashed #94a3b8;
        text-wrap: nowrap;
        white-space: nowrap;
      }
      &-info {
        display: flex;
        align-items: center;
        gap: 10px;
        &-value {
          font-size: 16px;
          font-weight: 600;
          line-height: 19.2px;
          color: #456eb2;
          text-wrap: nowrap;
          white-space: nowrap;
        }
        &-avg {
          display: flex;
          align-items: center;
          gap: 4px;
          @include ar {
            flex-direction: row-reverse;
          }
          &.descend {
            color: #3b873e;
          }
          &.ascend {
            color: #e31b0c;
          }
          &.constant {
            color: #64748b;
          }
          svg {
            width: 10px;
            color: inherit;
          }
          &-percentage {
            font-size: 14px;
            font-weight: 400;
            line-height: 20.02px;
            text-wrap: nowrap;
            white-space: nowrap;
            direction: ltr;
          }
        }
      }
    }
  }
}
.average-stats-menu-root {
  .average-stats-menu-paper {
    min-width: 390px;
    box-shadow: 0px 5px 25px 0px #0000001a;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .MuiList-root {
      padding: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .average-stats-menu {
      &-container {
        flex: 1px;
        padding: 20px;
        gap: 20px;
        display: flex;
        flex-direction: column;
      }
      &-header {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: #00000099;
      }
      &-desc {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        color: #00000099;
      }
      &-divider {
        height: 1px;
        background: #e2e8f0;
      }
      &-content {
        display: flex;
        gap: 20px;
        &-cell {
          display: flex;
          flex-direction: column;
          gap: 12px;
          align-items: start;
          &.cell-icon {
            gap: 10px;
            svg {
              @include ar {
                transform: rotate(180deg);
              }
            }
          }
          &-title {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            color: #000000de;
            border-bottom: 1px dashed #94a3b8;
          }
          &-value {
            font-size: 14px;
            font-weight: 600;
            line-height: 16.8px;
            color: #456eb2;
          }
        }
      }
      &-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-title {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: #00000099;
        }
        &-avg {
          display: flex;
          align-items: center;
          gap: 4px;
          @include ar {
            flex-direction: row-reverse;
          }
          &.descend {
            color: #3b873e;
          }
          &.ascend {
            color: #e31b0c;
          }
          &.constant {
            color: #64748b;
          }
          svg {
            width: 10px;
            color: inherit;
          }
          &-percentage {
            font-size: 14px;
            font-weight: 400;
            line-height: 20.02px;
            text-wrap: nowrap;
            white-space: nowrap;
            direction: ltr;
          }
        }
      }
    }
  }
}

div.average-stats-card-name-tooltip {
  padding: 13px 13px 18px 13px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
