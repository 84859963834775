@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.segment-btns {
  margin: 10px 15px 0;
  button {
    padding: 8px 12px;
    border: none;
    &:hover {
      border: none;
    }
  }
}
.segment-pop {
  .segment-pop-title {
    p {
      font-size: 15px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      letter-spacing: 0.15px !important;
      color: rgba(0, 0, 0, 0.87) !important;
      margin: 15px 0 !important;
    }
  }

  .segment-input {
    input {
      padding: 9px 8px;
    }
    p {
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 20px !important;
      letter-spacing: 0.4px !important;
      color: #f44336 !important;
      margin-top: 3px !important;
    }

    @include ar {
      p {
        text-align: right;
      }
      label.Mui-focused,
      label.MuiFormLabel-filled {
        -webkit-transform: translate(-14px, -10px) scale(1);
        -moz-transform: translate(-14px, -10px) scale(1);
        -ms-transform: translate(-14px, -10px) scale(1);
        transform: translate(-14px, -10px) scale(1);
      }
      label {
        transform-origin: top right;
        right: 0;
        top: 0;
        -webkit-transform: translate(-10px, 10px) scale(1);
        -moz-transform: translate(-10px, 10px) scale(1);
        -ms-transform: translate(-10px, 10px) scale(1);
        transform: translate(-10px, 10px) scale(1);
      }
      fieldset {
        text-align: right;
      }
    }

    @include en {
      label.Mui-focused,
      label.MuiFormLabel-filled {
        -webkit-transform: translate(11px, -9px) scale(0.8);
        -moz-transform: translate(11px, -9px) scale(0.8);
        -ms-transform: translate(11px, -9px) scale(0.8);
        transform: translate(11px, -9px) scale(0.8);
      }
      label {
        -webkit-transform: translate(10px, 10px) scale(0.8);
        -moz-transform: translate(10px, 10px) scale(0.8);
        -ms-transform: translate(10px, 10px) scale(0.8);
        transform: translate(10px, 10px) scale(0.8);
      }
    }
  }

  .segment-pop-checkbox {
    label {
      margin: 0;
      margin-top: 20px;
      .MuiTypography-root {
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(0, 0, 0, 0.87);
      }
      @include ar {
        .MuiButtonBase-root {
          padding-right: 0;
        }
      }
      @include en {
        .MuiButtonBase-root {
          padding-left: 0;
        }
      }
    }
  }
}

.segment-popup-wrapper {
  padding: 15px 0px;
}
