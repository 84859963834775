.details-side-bar-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  padding: 20px;
  .profile-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 0.8px solid #e2e8f0;
  }
  .profile-user-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .profile-side-bar-subname {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      letter-spacing: 0.15px;
      color: #00000099;
    }
  }
  .profile-side-bar-name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
  }
  .profile-side-bar-user-name {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0 0;
  }
  .link-copy {
    position: absolute;
    top: 20px;
    inset-inline-start: 20px;
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0.7px solid rgba(0, 0, 0, 0.23);
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .Visit-btn {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.content-side-bar-heading {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px 0;
  color: rgba(0, 0, 0, 0.87);
}
.content-side-bar-heading.no-margin {
  margin-bottom: 0;
}
.profile-side-bar-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .content-side-bar-lines {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .content-box {
    flex-grow: 1;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }
  .title-box {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      font-size: 16px;
      width: 20px;
    }
  }
  .value-box {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
  }
  .show-total {
    text-decoration: underline;
    color: #456eb2;
    font-weight: 600;
    cursor: pointer;
  }
  .content-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    .single-hint {
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 3px 10px;
      .hint-color {
        width: 10px;
        height: 10px;
        border-radius: 2px;
        display: inline-block;
      }
      .hint-text {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.profile-social-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 20px;
  .social-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .profile-social-icon {
    width: 29px;
    height: 29px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    img {
      width: 16px;
      height: 12px;
    }
  }
  .facebook-icon-profile {
    color: #1877f2;
  }
  .twitter-icon-profile {
    color: #1da1f2;
  }
  .instagram-icon-profile {
    color: #e1306c;
  }
  .intercom-icon-profile {
    color: #0057ff;
  }
  .whatsapp-icon-profile {
    color: #25d366;
  }
  .social-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
  }
  .social-link {
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    cursor: pointer;
    display: none;
  }
  &:hover {
    background-color: #fafafa;
    .social-link {
      display: block;
    }
  }
}
.profile-side-bar-social {
  padding: 20px 0 10px 0;
  .content-side-bar-heading {
    padding: 0 20px;
  }
}
.profile-side-bar-cdp {
  margin: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .cdp-text {
    text-align: center;
  }
  .star-img {
    margin-bottom: 10px;
  }
  .cdp-heading {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 600;
    max-width: 172px;
    margin: 0 auto 20px;
  }
  .cdp-description {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  .cdp-content {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 13px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .cdp-box {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      font-size: 15px;
      color: #456eb2;
    }
    .box-text {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
  }
  .demo-btn {
    width: 100%;
    font-size: 14px;
  }
}
.cdp-snackbar-wrapper {
  .cdp-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: block;
  }
  .cdp-description {
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    display: block;
    max-width: 282px;
  }
}
#copied-tooltip {
  .MuiTooltip-tooltip {
    background-color: #3b873e;
  }
}
.details-side-bar-content {
  max-height: calc(100vh - 185px);
  overflow: auto;
  &-empty {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: -50px;
    .details-side-bar-content-empty-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: #00000099;
      width: 184.73px;
    }
  }
}
.profile-side-bar-loader {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-height: calc(100vh - 225px);
  overflow: auto;
  .profile-side-bar-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .profile-side-bar-lines {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiDivider-root.MuiDivider-vertical {
    background: rgba(0, 0, 0, 0.12);
    height: 1px;
  }
  .lines-wrap {
    display: flex;
    gap: 10px;
  }
  .MuiSkeleton-pulse.MuiSkeleton-text {
    border-radius: 15px;
  }
}
.profile-side-bar-content-empty {
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.38);
  margin: 0;
}
.social-content-empty {
  padding: 0 20px;
}
