@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.sentiment-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  padding: 0 8px !important;
  width: auto;
  height: 34px;
  text-transform: capitalize !important;

  svg {
    &:first-child {
      margin: 0;
      margin-inline-end: 5px;
      font-size: 18px !important;
    }
  }

  .icon-angle {
    margin: 0;
    color: #475569;
    font-size: 25px;
    transition: 0.5s;
  }

  .positive {
    color: #8cc63f;
  }

  .negative {
    color: #ed1846;
  }

  .neutral {
    color: #f5b819;
  }
}

.sentiment-btn[aria-expanded="true"] {
  background-color: #f1f5f9;

  .icon-angle {
    rotate: -180deg;
  }
}

.ul-sentiment {
  padding: 8px !important;
  width: 150px;
  transition: 0.5s;

  svg {
    margin-inline-end: 10px;
  }
}

.sentiment-btn.none {
  color: #64748b;
}

.footer-divider {
  height: 20px !important;
  margin-right: 10px !important;
  margin-left: 5px !important;
  &.no-margin {
    margin: 0 !important;
  }
}

//check if tweet contain Arabic Character
.tweetTextRtl {
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.card-more-insights {
  justify-content: space-between;
}

.post-time {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #334155;
  font-size: 13px;
  font-weight: 400;
  margin: 0px 3px;
}

.ltr-direction {
  direction: ltr;
}
.card-footer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.question-theme {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.theme-box {
  display: flex;
  gap: 12px;
  padding: 10px 14px;
  border-radius: 4px;
  background-color: #f1f5f9;
  font-size: 14px;
  margin-top: 10px;
  .theme-heaading {
    font-weight: 500;
    color: #334155;
  }
  .theme-text {
    font-weight: 400;
    color: #475569;
  }
}
.main-box-color {
  display: flex;
  align-items: center;
}
.box-color.color-theme {
  width: 12px !important;
  height: 12px !important;
}
.reply-icon {
  color: #3e63a0 !important;
  font-size: 15px;
  @include en {
    margin-right: 8px;
  }
  @include ar {
    margin-left: 8px;
  }
}

.reply-button {
  background-color: #f0f9ff;
  border-radius: 4px;
  color: #3e63a0;
  padding: 8px 5px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.46px;
  cursor: pointer;
  border: 1.5px solid transparent;
  @include en {
    margin-left: 6px;
  }
  @include ar {
    margin-right: 6px;
  }
}

.reply-button-active {
  border: 1.5px solid #3e63a0;
}

.reply-divider {
  height: 45% !important;
  @include en {
    margin: 0px 4px 0px 8px !important;
  }
  @include ar {
    margin: 0px 8px 0px 4px !important;
  }
}
.reply-divider-edit-delete {
  height: 45% !important;
}

.replied-button {
  background-color: #ebf9ec;
  border-radius: 4px;
  color: #4caf50;
  padding: 8px 5px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.46px;
  cursor: pointer;
  border: 1.5px solid transparent;
  @include en {
    margin-left: 6px;
  }
  @include ar {
    margin-right: 6px;
  }
}

.replied-icon {
  color: #4caf50 !important;
  font-size: 15px;
  @include en {
    margin-right: 8px;
  }
  @include ar {
    margin-left: 8px;
  }
}

.replied-button-active {
  border: 1.5px solid #4caf50;
}

.drop-down-parant {
  max-height: 100%;
  .preloading.MuiBox-root {
    position: absolute;
    width: 100%;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }
  .themes-selected-item {
    .themes-selected-count {
      margin-inline-start: 2px;
    }
  }
}
.popup-body-container {
  padding: 23px 30px 0;
  display: flex;
  align-items: start;
  overflow: hidden;
  gap: 12px;
  .content-box {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    span {
      margin-top: 30px;
      display: flex;
      padding: 5.5px 6px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      border-radius: 2px;
      background: #3e63a0;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      width: max-content;
      margin-bottom: 6px;
    }
    h2 {
      color: #456eb2;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 12px;
      width: 203.1px;
      @include ar {
        width: 224px;
      }
    }
    p {
      color: rgba(102, 118, 140, 0.6) !important;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 211.762px;
      @include ar {
        width: 192px;
      }
    }
  }
  .img-box {
    width: 199px;
    height: 266px;
  }

  .post-question-img {
    position: absolute;
    inset-inline-end: 0;
    top: 3.5px;
  }
}
.post-qustion-popup {
  overflow: hidden;
  > div {
    overflow: hidden;
    padding: 0 !important;
  }
  .close-icon {
    position: absolute;
    inset-inline-end: 25.5px;
    top: 20.257px;
    z-index: 1;
    color: #475569;
    font-size: 20px;
    font-weight: 300;
    cursor: pointer;
  }
}

.lexisnexis-img {
  width: 16px !important;
  height: 16px !important;
  margin-inline-end: 4px;
  &:hover {
    cursor: pointer;
  }
}

.image-container-card-body {
  position: relative;
  max-height: 105px;
  overflow: hidden;
  border-radius: 4px;
  img {
    width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    z-index: 1;
    transition: transform 200ms;
    &:hover {
      transform: scale(1.1);
    }
  }
  .video-icon {
    cursor: pointer;
    color: #ffffff;
    width: 21.722px;
    height: 21.722px;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    border-radius: 50%;
    z-index: 1;
    svg {
      font-size: 7.823px;
    }
  }
  .image-container-card-body-overlay {
    cursor: pointer;
    background-color: #0000004d;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
