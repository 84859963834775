@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.widget-control-btn {
  @include en {
    margin-left: 5px;
  }
  @include ar {
    margin-right: 5px;
  }
  > .control-wiget-icon {
    font-size: 16px;
  }
}
#custom-dashboard-control-widget-sidebar {
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 100%;
  .widget-control-sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #e9e9e9;
    .widget-control-sidebar-close-btn-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .widget-control-sidebar-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    border-top: 1px solid #e9e9e9;
  }
  .custom-dashboard-widget-grid-item {
    > div {
      box-shadow: none;
      border: 1px solid #e2e8f0;
    }
  }
  .widget-control-sidebar-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    .widget-view-wrapper {
      margin-bottom: 24px;
    }
    .section-box-title {
      font-weight: 600;
      font-size: 14px;
      color: #212121;
      margin: 5px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .monitors-info-icon {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.6);
        margin: 0px 3px;
      }
    }
    #control-widget-view-type-control {
      width: 100%;
      #control-widget-view-type-radio-grooup {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        .view-type-checkbox {
          padding: 0px;
        }
        .view-type-form-control-label {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: flex-start;
          border: 1px solid #f1f5f9;
          border-radius: 4px;
          padding: 15px;
          margin: 10px 0px;
          font-weight: 400;
          color: #000000;
          &.selected {
            border: 1px solid #456eb2;
          }
          .type-title-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            font-size: 14px;
            .type-icon {
              color: #456eb2;
            }
          }
          .view-type-desc {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 5px;
          }
        }
        #custom-dashboard-control-widget-monitors-select {
          width: 100%;
        }
      }
    }
    .control-wdget-select-monitors-wrapper {
      margin-top: 10px;
      > .select-form-control {
        width: 100%;
      }
    }
  }
}
#custom-dashboard-widget-control-monitors-select {
  .MuiAutocomplete-endAdornment {
    @include ar {
      right: 91% !important;
    }
  }
  .MuiInputLabel-formControl.MuiInputLabel-formControl {
    @include ar {
      right: 40px;
    }
  }
}
.custom-popper .MuiAutocomplete-listbox {
  max-height: 220px;
  overflow-y: auto;
  border: 1px solid #f1f5f9;
  > li {
    padding-top: 0px;
    padding-bottom: 0px;
    gap: 10px;
    .control-widget-monitors-list-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      width: 100%;
      &.disabled {
        opacity: 0.5;
        cursor: auto;
        .monitor-name-title-text {
          cursor: default;
        }
      }
      .monitor-name-title-text {
        font-weight: 400;
        font-size: 14px;
        width: 100%;
      }
      img {
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }
    }
  }
}
#control-widget-monitors-list-item {
  height: 10px !important;
  padding: 0px !important;
}
.custom-dashboard-dialog-content {
  > p {
    color: rgba(0, 0, 0, 0.87);
  }
}
.MuiDialogActions-spacing.custom-dashboard-dialog-actions {
  padding: 4px 24px 24px 24px;
}
