@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.formcloud-live-dashboard-public {
  height: 100vh;
  .formcloud-live-dashboard-public-header {
    padding: 13px 100px;
  }

  .formcloud-live-dashboard-body {
    height: calc(100% - 115px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../images/live-dashboard-images/background-auth-public.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;

    .formcloud-live-dashboard-box {
      box-sizing: border-box;
      max-width: 596px;
      max-height: 514px;
      padding: 80px;
      background-color: #ffffff;
      box-shadow: 0px 4px 94px 0px #0000000d;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      text-align: center;
      .verifying-live-dashboard-public {
        font-size: 25px;
        font-weight: 700;
        line-height: 34.8px;
        color: #000000de;
      }
      .icon-formcloud-live-dashboard-error {
        svg {
          color: #e2e8f0;
          font-weight: 900;
          font-size: 90px;
          line-height: 90px;
        }
      }
      .visitors-limit-reached-description {
        color: #64748b;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;

        a {
          color: #456eb2;
          text-decoration: underline;
        }
      }
    }
  }
  @include ar {
    direction: rtl;
  }
}

@media screen and (max-width: 834px) {
  .formcloud-live-dashboard-public {
    .formcloud-live-dashboard-public-header {
      padding: 7.89px 60.7px;
    }

    .formcloud-live-dashboard-body {
      height: calc(100% - 40px);
      .formcloud-live-dashboard-box {
        padding: 60px;
        max-width: 441px;
        max-height: 380px;
        border-radius: 7.5px;
        .verifying-live-dashboard-public {
          font-size: 22px;
          line-height: 33px;
        }
        .icon-formcloud-live-dashboard-error {
          svg {
            font-weight: 700;
            font-size: 70px;
            line-height: 70px;
          }
        }
      }
    }
    .app-footer {
      display: none;
    }
  }
}

@media screen and (max-width: 540px) {
  .formcloud-live-dashboard-public {
    .formcloud-live-dashboard-body {
      .formcloud-live-dashboard-box {
        padding: 45px;
        max-width: 332px;
        max-height: 286px;
        border-radius: 5.5px;
        .verifying-live-dashboard-public {
          font-size: 18px;
          line-height: 27px;
        }
        .icon-formcloud-live-dashboard-error {
          svg {
            font-size: 60px;
            line-height: 60px;
          }
        }
        .visitors-limit-reached-description {
          font-size: 12px;
        }
      }
    }
  }
}
