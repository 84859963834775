@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#company-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#company-page {
  width: 685px;
  padding-bottom: 20px;
  .company-page-sections-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    p.Mui-error {
      @include ar {
        text-align: start;
      }
    }
    .company-page-section-box {
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #e2e8f0;
      border-radius: 6px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .section-description {
        font-size: 14px;
        color: #0009;
      }
      .input-label {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
        font-size: 14px;
        margin: 10px 0px;
      }
      &.customer-success-manager {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;
        .manager-details {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .manager-name {
            color: rgba(0, 0, 0, 0.87);
            font-weight: 600;
          }
          .manager-email-container {
            display: flex;
            align-items: center;
            gap: 24px;
            .manager-email,
            .manager-phone {
              display: flex;
              align-items: center;
              gap: 5px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.6);
              @include ar {
                direction: ltr;
              }
            }
          }
        }
      }
    }
    #company-timezone-autocomplete {
      direction: ltr;
    }
  }
}
