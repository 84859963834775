@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.story {
  &-wrapper {
    .replied-to {
      @include en {
        text-align: left;
      }
      @include ar {
        text-align: right;
      }
    }
  }
  &-preview {
    width: 116px;
    height: 199px;
    border-radius: 5px;
    position: relative;
    margin-top: 8px;
  }
  &-preview-image {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
  &-preview-video {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
  &-preview-placeholder {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #9a9a9a;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
  }
}

.deleted-message{
  display: flex;
  gap:8px;
  align-items: center;
  justify-content: center;
  @include en {
   flex-direction: row;
  }
  @include ar {
    flex-direction: row-reverse;
  }
  .deleted-message-text{
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 23px;
    color: #0D0C28;
  }
  svg {
    width: 14px;
    height: auto;
    fill: #0D0C28;
  }
}