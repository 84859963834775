@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.custom-pagination-container {
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
  margin: 10px 4px 60px;
  align-items: center;

  .custom-pagination {
    li {
      button.MuiPaginationItem-root,
      .MuiPaginationItem-ellipsis {
        background-color: #ffffff;
        width: 32px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        padding: 10px;
        line-height: 14.4px;
        font-weight: 600;
        font-size: 12px;
        &:hover {
          border-color: #456eb2;
        }
      }
      .MuiPaginationItem-root.Mui-selected {
        background-color: #456eb2;
      }
    }

    @include ar() {
      direction: rtl;
      li {
        button.MuiPaginationItem-firstLast,
        button.MuiPaginationItem-previousNext {
          transform: rotate(180deg);
        }
      }
    }
  }

  .pagination-input-container {
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;
  }

  .pagination-input .MuiInputBase-input {
    padding: 8px 12px;
    max-height: 16px;
    min-width: 46px;
  }

  .pagination-actions-hidden .MuiTablePagination-actions {
    display: none;
  }

  .pagination-rows-indicator {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);

    .pagination-rows-count {
      font-weight: 400;
    }
  }
}
