@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.pop-up-model-shared-content {
  #alert-dialog-description.MuiTypography-root {
    .shared-dialog-body {
      p {
        color: #000000de;
        font-size: 16px;
        font-weight: 400;
        &.sub-text {
          color: rgba(0, 0, 0, 0.6);
          font-style: italic;
          margin-top: 4px;
        }
        &.sub-text-not-italic {
          color: #00000099;
        }
      }
      .sub-text-desc {
        font-weight: 600;
        margin: 0;
        color: #000000de;
        font-size: 16px;
      }
    }

    .body-input-label {
      font-size: 16px;
      font-weight: 400;
      color: #000000de;
      line-height: unset;
      margin-top: 7px;
      &.show-red-icon {
        margin-bottom: 8px;
        &::after {
          content: "*";
          color: #f44336;
          position: relative;
          font-size: 16px;
        }
      }
    }
  }

  #alert-dialog-title {
    font-size: 20px;
    font-weight: 500;
    color: #000000de;
    text-transform: capitalize;
  }
  .back-btn-wrapper {
    flex-grow: 1;
  }
}
.actions-buttons-shared {
  .MuiDialogActions-root {
    padding: 16px 24px 24px 24px;
  }
}
.cancel-popup-btn {
  margin-inline: 10px;
}
