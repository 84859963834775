.overview-analytics-search {
  .postsColumn_chart {
    border: 1px solid #e2e8f0;
    box-shadow: none;
    padding: 24px 18px 0px;
  }
  .sentiment-analysis-widget {
    border: 1px solid #e2e8f0;
    box-shadow: none;
    padding: 24px 18px 0px;
    height: 480px;
    .widget-title {
      color: #334155;
      font-size: 16px;
    }
  }
  .postsColumn_chart {
    height: 480px;
  }
  .sentiment-labels .sentiment-label-name{
    margin: 0px 20px;
  }
}
.overview-section-head {
  color: #64748b;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.overview-section-search {
  background: #fff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
  padding: 25px;
  margin-top: 20px;
  border-radius: 10px;
  .hide-show-analytics {
    &.MuiButton-root {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      color: #456eb2;
      border: none;
      font-size: 15px;
      font-weight: 500;
      margin-top: 20px;
      &:hover {
        background-color: rgba(230, 243, 252, 0.5);
        transition: 0.5s ease;
      }
    }
  }
  .component {
    .d-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .statistic {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #e2e8f0;
      flex: unset;
      background: #fff;
      box-shadow: 0px 0px 0px 1px #e0e0e0;
      p {
        font-size: 14px;
        font-weight: 500;
        border-bottom: unset;
        pointer-events: none;
      }
    }
  }
}
.overview-search-stats {
  display: flex;
  align-content: center;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 0px 0px 1px #e0e0e0;
  padding: 23px 24px;
}
.overview-search-value {
  font-size: 23px;
  font-weight: 700;
  color: #0c192a;
  margin-bottom: 5px;
}
.overview-search-title-val {
  padding-inline-start: 23px;
  color: #6d737a;
  font-size: 14px;
  font-weight: 500;
}
.overview-search-icon {
  width: 55px;
  height: 55px;
  background-color: #475569;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  &.total-mention {
    background-color: #5692d7;
  }
  svg {
    font-size: 18px;
  }
}
.overview-section-wrapper {
  margin: 10px 0px 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.sentiment-btn-quick-search {
  width: 55px;
  height: 55px;
  background-color: #475569;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #fff !important;
    font-size: 18px;
  }
  &.quick_positive {
    background-color: #89bb2a;
  }
  &.quick_negative {
    background-color: #ed1846;
  }
  &.quick_neutral {
    background-color: #f9a700;
  }
}
.arrow-analytic-icon {
}
