@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.reports-page-header-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reports-page-header-search-filter-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

#reports-list-options-dropdown-menu {
  .MuiPaper-root {
    border-radius: 6px;
    margin-top: 8px;
    color: rgb(55, 65, 81);
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    .MuiMenuItem-root {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      svg {
        font-size: 18px;
        color: #212121;
        margin: 0;
      }
      p {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.87);
        margin: 0;
      }
    }
  }
}

#delete-report-modal-body,
#pause-resume-report-modal {
  p {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
}

// Old Code Before Reffactor
.report-filter-menu-item {
  padding: 7px 19px !important;
  .MuiCheckbox-root {
    padding: 0px !important;
  }
  .MuiListItemText-root {
    span {
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      @include en {
        margin-left: 11px;
      }
      @include ar {
        font-family: "Dubai" !important;
        margin-right: 11px;
      }
    }
  }
}
#report-create-new-show-report-text-btn.MuiButtonBase-root {
  color: #456eb2;
}
.report-name-container {
  display: flex;
  p {
    font-size: 14px;
  }
  .MuiTypography-root,
  .MuiButton-root {
    font-size: 14px;
    font-weight: 400;
  }
  .MuiButton-root {
    &:hover {
      background-color: transparent !important;
    }
  }
}
.tooltip-content {
  display: flex;
}
.tooltip-content-count {
  font-weight: 600;
  font-size: 13px;
  color: #696d72;
  cursor: default;
}
.tooltip-content-count.MuiTypography-root {
  margin-top: -4px;
  @include ar {
    direction: ltr;
  }
}
.reports-social-icons {
  .facebook-public-icon {
    width: 16px;
    height: 16px;
  }
}
#warning-tooltip-report {
  .MuiTooltip-tooltip {
    margin-inline: 18px;
  }
}
.twitter-prvate-icon {
  color: #1da1f2;
}
.facebook-prvate-icon {
  color: #4267b2;
}
.instagram-prvate-icon {
  color: #e1306c;
}
