@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.edit-team-container {
  padding: 0 !important;

  .edit-team-header {
    margin-bottom: 19px;
    #main-pages-header-with-breadcrumb {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
      color: #0c192a;
    }

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .edit-team-header-breadcrumb {
      display: flex;
      flex-direction: column;
      gap: 9px;
    }

    .edit-team-header-btn {
      padding: 8px 12px 8px 12px;
      border-radius: 2px;
      gap: 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      color: #64748b;

      svg {
        width: 20px;
        height: auto;
        fill: #64748b;

        // reverse in RTL
        @include ar {
          transform: rotate(180deg);
        }
      }
    }
  }

  .edit-team-main-container {
    margin-top: 20px;
    margin-bottom: 100px;
    .edit-team-details-section {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .edit-team-details {
      display: flex;
      flex-direction: column;
      gap: 30px;

      &-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.08em;
        color: #475569;
        text-transform: uppercase;
      }

      &-content {
        border: 1px solid #e2e8f0;
        background: #fff;
        box-shadow: 0px 4px 8px 0px #00000014;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
}
header.edit-team-footer {
  position: fixed !important;
  top: auto !important;
  bottom: 0;
  background-color: #ffffff !important;
  height: 80px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  .edit-team-footer-toolbar {
    .edit-team-footer-counter {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #80868c;
    }
    padding: 0px 100px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-btns {
      display: flex;
      gap: 15px;
      align-items: center;
    }
  }
}
.working-hours-days-title.MuiTypography-root {
  font-size: 16px;
  font-weight: 500;
  color: #475569;
  margin-bottom: 0;
  line-height: 22px;
}
.tabs-section-container {
  all: unset;
  .user-tabs-content {
    .create-team-working-hours-container {
      width: 50% !important;
    }
    &.create-team-working-hours-row-container {
      width: 100%;
      display: flex;
      gap: 67px;
      justify-content: space-between;
      align-items: center;
    }
    .team-auto-complete-column-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1;
    }
    .create-team-auto-reply-text-area-label.MuiTypography-root {
      font-size: 12px;
      font-weight: 600;
      color: #00000099;
    }
    .create-team-auto-reply-text-area {
      border-radius: 4px;
      &.MuiInputBase-input {
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 400;
        color: #00000099;
      }
    }
  }
}
.create-team-working-hours-extra-title.MuiTypography-root {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
}
