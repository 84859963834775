@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.dasboard-list {
  text-align: right !important;
  @include ar {
    text-align: left !important;
  }
}
.menu-list {
  overflow-y: auto !important;

  .ellipsis-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
  .open-menu {
    background-color: #cbd5e1;
    border-radius: 4px;
    .ellipsis-icon {
      color: #fff;
    }
  }
  .disable-drop-menu {
    cursor: not-allowed;
  }
}

#dashboard-menu-dropdown {
  @include en {
    .MuiMenu-paper {
      transform: translate(-72%, 4px) !important;
    }
  }
  @include ar {
    .MuiMenu-paper {
      transform: translate(0, 4px) !important;
    }
  }

  li {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    &:hover {
      background-color: rgba(250, 250, 250, 1);
    }
    svg {
      margin-inline-end: 10px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.dashboard-menu-text {
  color: rgba(0, 0, 0, 0.87);
}
