.request-wrapper{
  max-width: 555px;
  margin: 80px auto;
  text-align: center;
  .request-description{
    margin: 30px 0 18px;
    font-size: 16px;
    line-height: 24px;
    color:#000000;
  }
}