@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.date-list {
  width: 191px !important;

  ul {
    padding: 0 !important;
  }
  li {
    .MuiListItemText-root span {
      padding: 0px 10px;
      text-wrap: wrap;
    }
  }
}

.dateRange {
  .MuiInputBase-formControl {
    input {
      padding-inline-start: 30px;
      width: 150px;
    }
    fieldset {
      box-shadow: 0px 2px 6px 0px rgb(129 129 129 / 14%);
      border: 1px solid #e2e8f0;
    }
  }
  .MuiOutlinedInput-input.MuiInputBase-input.Mui-disabled {
    cursor: default !important;
  }
}

.date-field {
  position: relative;
  z-index: 0;
  cursor: default;
  &.active-date {
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
  svg {
    position: absolute;
    // left: 205px;
    top: 45%;
    left: 30px;
    z-index: 999;
    transform: translateY(-50%);
    color: rgb(177, 177, 177);
    @include ar {
      // right: 46% !important;
      right: 12% !important;
    }
  }
}

.luc-date-picker .MuiBox-root .MuiBox-root > div {
  @include ar {
    direction: ltr;
  }
}
// .luc-date-picker
//   .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 {
//   @include ar {
//     left: 254px !important;
//   }
// }

.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
  button {
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
  button {
    color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiDateRangePickerDay-day.MuiDateRangePickerDay-dayOutsideRangeInterval {
  color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

// .MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled {
//   visibility: hidden !important;
// }

.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.MuiDateRangePickerDay-day.MuiDateRangePickerDay-notSelectedDate.MuiDateRangePickerDay-dayOutsideRangeInterval {
  text-decoration: line-through;
}

.luc-date-picker {
  .MuiTypography-caption {
    &:nth-child(1)::after {
      content: "u";
    }
    &:nth-child(2)::after {
      content: "o";
    }
    &:nth-child(3)::after {
      content: "u";
    }
    &:nth-child(4)::after {
      content: "e";
    }
    &:nth-child(5)::after {
      content: "h";
    }
    &:nth-child(6)::after {
      content: "r";
    }
    &:nth-child(7)::after {
      content: "a";
    }
  }
}
.survey-insights-datepicker-position {

  @include ar {
    left: 105px !important;
  }
}
.explore-datepicker-position{
  @include en {
    left: -23.2vw !important ;
  }
}
.insights-datepicker-position {
  @include ar {
    left: 250px !important;
  }
}

.monitorheader-datepicker-position {
  @include ar {
    left: 125px !important;
  }
}
.notifications-datepicker-position {
  @include en {
    left: 468px !important;
  }
  &.luc-date-picker .MuiBox-root .MuiBox-root > div {
    @include en {
      margin-right: 10px;
    }
  }
}
