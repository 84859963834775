@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#scale-proceed-popup-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 13px;
  padding-top: 10px;
  p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
  }
  hr {
    width: 100%;
  }
  .sec-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    padding: 0px;
  }
  .scale-proceed-popup-body-grid {
    width: 100%;
  }
  .scale-proceed-inputs-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
  }
}

ul:has(#scale-items-list-menu-item) {
  padding: 0px;
}

.MuiDialogContent-root.scale-proceed-modal-content {
  height: 350px;
  overflow: auto;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fafafa;
    @include en {
      box-shadow: 1px 0px 0px 0px #0000001f inset;
    }
    @include ar {
      box-shadow: -1px 0px 0px 0px #0000001f inset;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 14px;
  }
}

.scale-proceed-modal-btns-container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.scale-select-form-control {
  .MuiInputLabel-root {
    @include ar {
      right: 20px;
      left: auto;
      padding: 0px;
      margin-right: 4px;
    }
  }
  @include ar {
    fieldset {
      legend {
        text-align: right;
      }
    }
  }
}

.scale-constraints-wrapper {
  > hr {
    margin: 20px;
  }
}

#scale-go-to-input {
  @include ar {
    padding-right: 10px;
    padding-left: 36px;
  }
}

.scale-select-item {
  width: -webkit-fill-available;
  > span.text-box {
    width: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

#scale-items-list-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;
}
