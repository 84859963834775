// miXin for Locals [ ar & en ] .
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.top-media {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.top-media__image {
  position: relative;
  // margin-bottom: 12px;
  img {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  video {
    display: block;
    width: 100%;
    height: auto;
  }
}

.top-media__text {
  border: 1px solid #e8e8e8;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    &:nth-child(2) {
      margin: 0px 5px;
    }
  }
  .statistics-icons {
    svg {
      color: #cbd5e1;
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
  }
}

.top-media__link {
  border-radius: 2px;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);

  color: #00000099;
  &:hover {
    background-color: #f1f5f9;
    transition: all 0.5s ease;
  }

  .svg-inline--fa {
    width: 16px;
  }
}

.top-media__icon {
  float: right;
  color: #cbcbcb;
  font-size: 14px;
}

.top-media__overlay {
  background: rgba(65, 65, 65, 0.75);
  border-radius: 4px;
  color: #fff;
  padding-top: 6px;
  position: absolute;
  bottom: 8px;
  left: 10px;
  right: 10px;
  height: 39px;

  text-align: center;

  span {
    display: inline-block;
    font-size: 10px;
    margin: 0 11px;

    svg {
      margin-right: 6px;
    }
  }
}

.top-media__text--tw {
  svg.svg-inline--fa {
    color: #a5aeb4;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    vertical-align: middle;
  }
}

.top-media__text--insta {
  svg.svg-inline--fa {
    color: #e56464;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    vertical-align: middle;
    @include ar {
      flex-direction: row-reverse;
      margin-left: 10px;
    }
  }
}
.media_span {
  margin: 0px 3px;
}

//////-- Top media Wrapper --//////
.chart_media {
  box-shadow: 0 4px 4px #e3e3e3;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.topmedia-wrapper {
  overflow: hidden;
  padding: 20px 10px 10px;
  display: flex;
  overflow-x: auto;
}
.topmedia-monitor-wrapper {
  gap: 40px;
  overflow: hidden;
  padding: 0px 10px 10px 0px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-auto-flow: column;
  grid-auto-columns: minmax(350px, 1fr);
  overflow-x: auto;
  .Header {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}
.mediaitem-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 5px;
  &.tiktok {
    iframe {
      border-left: 1px solid #e3e3e4;
      @include ar {
        border-right: 1px solid #e3e3e4;
      }
    }
    .details-tiktok {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 44px;
      background-color: #f1f5f9;
      border: 1px solid #e2e8f0;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 600;
      color: #456eb2;
      max-width: 325px;
      .details-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .details-label {
          font-size: 9px;
          font-weight: 500;
          color: #64748b;
        }
      }
    }
  }
}

.top-media__item {
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  min-width: 230px;
  max-width: 230px;
}
.top-media-insights {
  color: #456eb2;
  font-size: 14px;
  border: 1px solid #cbd5e1;
  border-radius: 0 0 5px 5px;
  padding: 10px;
  font-weight: 400;
  border-top-width: 0;
  cursor: pointer;
  svg {
    margin: 0px 10px;
  }
}

.top-media-insights-resp {
  font-size: 14px;
  border: 1px solid #cbd5e1;
  border-radius: 0 0 5px 5px;
  padding: 4px;
  border-top-width: 0;
  cursor: pointer;
  margin-top: 0px !important;
}

.circular-progress {
  height: auto !important;
  svg {
    margin: -3px 11px;
  }
}

.image-linkedin {
  height: 280px;
}

.linkedin-icon {
  color: #0a66c2;
  font-size: 18px;
}

.top-linkedin-media {
  display: flex;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f5f9;
  }
  .top-media__item {
    margin-inline-end: 10px;
    overflow: auto;
  }
  .top-media-item {
    border-radius: 4px;
    display: inline-grid;
    margin-inline-end: 10px;
    position: relative;
    margin-bottom: 15px;
  }
  .top-media__image {
    width: 300px;
  }
  .video-linkedin {
    height: 280px;
    width: 100%;
    object-fit: cover;
  }
  .video-linkedin,
  .image-linkedin img {
    border-radius: 6px 6px 0px 0px;
  }
}

.topmedia-monitor-wrapper,
.topmedia-wrapper {
  &::-webkit-scrollbar {
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #646464;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f5f9;
  }
}
.media-instagram-wrapper {
  iframe {
    margin: 0 !important;
  }
}
