@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.select-dropdown-controll {
  width: 100%;
  @include ar {
    label {
      padding-inline: 3px;
      &.MuiInputLabel-shrink,
      &.Mui-focused,
      &.MuiFormLabel-filled {
        background-color: #ffffff;
      }
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
  }

  .MuiSelect-select {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #000000de;
    @include ar {
      padding-right: 14px !important;
    }
  }
  span.MuiTypography-root.MuiTypography-body1 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #000000de;
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    legend {
      font-size: calc(14px * 0.75);
      font-weight: 400;
      letter-spacing: 0.15px;
      line-height: 24px;
    }
  }
  label.MuiFormLabel-root.MuiInputLabel-root {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #00000099;
    line-height: 24px;
    &.Mui-error {
      color: #f44336;
    }
    @include ar {
      text-align: right;
    }

    &.Mui-focused {
      @include ar {
        right: 0px;
        left: auto;
      }
    }

    // not focused and not shrinked
    &:not(.Mui-focused) {
      @include ar {
        right: 30px;
        left: auto;
      }
    }

    &.MuiInputLabel-shrink {
      @include ar {
        right: 17.5px !important;
        left: auto;
        transform: translate(5px, -9px) scale(0.75);
        transform-origin: top right;
      }
    }
  }
}
.select-dropdown-field-controll-list {
  .select-dropdown-field-controll-item {
    padding: 6px 16px !important;
    gap: 10px;
    color: #000000de;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .select-dropdown-field-controll-list-label {
    color: #000000de;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}
