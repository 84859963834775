@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.analytics-section-search {
  background-color: #fff;
  .sentiment-legends-container{
    padding: 0 15px;
  }
  .overview-section-head {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
.explore-top-posts {
  background: #fff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
  padding: 25px;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 100px;
  .authors-margin {
    margin-top: 0px;
  }
  .author-title {
    color: #64748b;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .topposts-wrapper {
    gap: 15px;
    margin-top: 25px;
    .footer-divider {
      margin-right: 0px !important;
      margin-left: 10px !important;
      height: 32px !important;
      @include ar{
        margin-right: 10px !important;
        margin-left: 0px !important;
      }
    }
    .sentiment-btn {
      pointer-events: none;
      border: 1px solid #e2e8f0;
      margin-inline-start: 5px;
      font-size: 14px;
      svg {
        font-size: 15px !important;
      }
    }
    .sentiment-btn .icon-angle {
      display: none;
    }
  }
  .pagination-monitor-list {
    margin-bottom: 0px;
    margin-top: 30px;
  }
  .author-dropdown .MuiOutlinedInput-root {
    height: 40px;
  }
}
