@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#view-report-page-container {
  .view-report-page-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    .view-report-header-title {
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.87);
      font-size: 22px;
      font-weight: 700;
      .view-report-header-frequency-chip {
        background: #64748b;
        color: #f8fafc;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.16px;
        border-radius: 43px;
        margin-inline: 10px;
        padding: 4px 8px;
      }
    }
  }

  #view-report-warning-alert {
    .MuiAlert-message {
      width: 100%;
    }
    .view-report-warning-msg-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
      &.deleted-monitors,
      &.luci-quota {
        justify-content: space-between;
        > a {
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 0.46px;
        }
      }
      p,
      a {
        font-size: 14px;
      }
    }
    .create-report-link {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .report-date-empty-box {
    background-color: #ffffff;
    margin-top: 20px;
    padding: 16px 20px;
    .MuiTypography-subtitle2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: #334155;
    }
  }

  .view-report-page-records-table-wrapper {
    margin-top: 20px;
    .list-card {
      background: #ffffff;
      border: 1px solid #e2e8f0;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
      border-radius: 4px;
      padding: 12px 22px 12px 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h6 {
        font-weight: 400;
        font-size: 13px;
        line-height: 25px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
      }
      button {
        padding: 0;
        svg {
          color: rgba(0, 0, 0, 0.54);
        }
      }
      .date-card {
        font-weight: 600;
      }
    }
    > div.MuiGrid-root.MuiGrid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: 0;
      padding: 0;
      gap: 32px;
      width: 100%;
      margin-bottom: 32px;
    }
  }
}

.view-report-card {
  gap: 40px;
  overflow: hidden;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  overflow-x: auto;
  border-top: 1px solid #cbd5e1;
  padding-top: 25px;
  margin-top: 25px;
  box-shadow: none !important;
  filter: drop-shadow(0px 3px 4px rgba(66, 89, 104, 0.051));
  .report-detail {
    padding: 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    .MuiTypography-subtitle2 {
      color: #64748b;
      font-size: 16px;
      font-weight: 400;
    }
    .MuiTypography-subtitle1 {
      color: #1e293b;
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;
      display: flex;
    }
  }
}
