@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.engag-analytics-main {
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .engagement-analytics-header {
    height: 68px;
    background-color: #ffffff;
    border-bottom: 1px solid #e2e8f0;
    z-index: 10;
    position: fixed;
    left: 0;
    right: 0;
    top: 60px;
    padding: 5px 20px 5px 5px;
    box-sizing: border-box;
    @include ar {
      padding: 5px 5px 5px 20px;
    }
    .conainer-engagement-inbox {
      align-items: center;
      height: 52px;
      margin-top: 2px;
      box-sizing: border-box;
    }
    .analytics-engagement-icon {
      width: 25px;
      height: 33px;
      color: #000000de;
      &-container {
        width: 37px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
      }
    }
    .engagement-analytics-header-switch-menu {
      z-index: 999;
      top: 122px;
    }
    &.disabled-header {
      #date-range-picker-id {
        pointer-events: none;
        opacity: 0.3;
      }
    }
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-back {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 8px 12px;

      &-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.46px;
        color: #64748b;
      }
      svg {
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.46px;
        color: #64748b;
        width: 14px;
        height: 26px;
        @include en {
          rotate: 180deg;
        }
      }
    }
    &-btns {
      gap: 8px;
      display: flex;
      align-items: center;
      .MuiDivider-root {
        width: 1px;
        height: 19px;
        color: #94a3b8;
        margin: 0 5px;
      }
      .luc-Button.MuiButton-sizeLarge {
        height: 36px !important;
      }
      .luc-Button.MuiButton-sizeSmall {
        border: 1px solid #cbd5e1;
      }
      .dateRange {
        height: auto;
      }
      #date-range-picker-id {
        svg {
          top: 44%;
          left: 10px;
          @include ar {
            right: 10px !important;
          }
        }
        div.MuiInputBase-root.MuiOutlinedInput-root {
          border: 1px solid #cbd5e1;
          height: 36px;
          margin: 0;
          font-size: 14px;
          border-radius: 2px;
          input {
            width: 166px;
          }
          fieldset {
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }
  .analytics-main-contant {
    flex: 1;
    display: flex;
    flex-direction: column;
    .analytics-sidebar {
      height: calc(100vh - 128px);
      background-color: #fff;
      width: 192px;
      border-inline-end: 1px solid #e2e8f0;
      position: fixed;
      top: 128px;
      .menu-analytics-sidebar {
        padding: 21px 10px;
        padding-inline-start: 20px;
        font-size: 16px;
        font-weight: 700;
        color: #000000de;
      }
      .menu-analytics-sidebar-item {
        cursor: pointer;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .main-tab-details {
          display: flex;
          gap: 10px;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #000000de;
          svg {
            width: 17px;
            height: 14px;
            color: #64748b;
          }
        }
        &.disabled {
          cursor: default;
          .main-tab-details {
            color: #00000061;
            svg {
              color: #00000061;
            }
          }
          .soon-tab {
            background-color: #ed6c0214;
            width: 45px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            color: #a64c01;
            font-size: 12px;
            font-weight: 500;
          }
        }
        &:hover {
          background-color: #f8fafc;
        }
        &.selected-analytics-item {
          background-color: #f1f5f9;
          color: #456eb2;
          border-inline-start: 5px solid #456eb2;
          padding-inline-start: 15px;
          .main-tab-details {
            color: #456eb2;
          }
          svg {
            color: #456eb2;
          }
        }
      }
    }
    .engag-analytics-contant {
      margin-inline-start: 234px;
      margin-inline-end: 40px;
      margin-top: 108px;
      flex: 1;
      display: flex;
      flex-direction: column;
      .app-footer {
        > .MuiContainer-root {
          padding: 0 20px !important;
        }
      }
      .engag-analytics-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 22px;
        font-weight: 700;
      }
      .engag-dashboard-widgets-container {
        padding-top: 30px;
        padding-bottom: 60px;
        flex: 1;
      }
    }
  }

  .agent-performance-table-header {
    &.with-border {
      border-bottom: 1px dashed #94a3b8;
      display: inline-block;
    }
  }
  .big-table-agent-column {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    padding: 16px 4px;

    &-image {
      width: 34px;
      height: 34px;
      img {
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid #94a3b8;
        width: 34px;
        height: 34px;
        box-sizing: border-box;
      }
    }
    &-details {
      display: flex;
      flex-direction: column;
    }

    &-name {
      font-family: Figtree;
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      color: #456eb2;
    }
    &-status {
      font-size: 12px;
      font-weight: 400;
      line-height: 17.16px;
      color: #80868c;
    }
  }

  .big-table-sla-column {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px 4px;
    &-name {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      color: #000000de;
    }
    p.big-table-sla-column-values {
      font-size: 12px;
      font-weight: 400;
      line-height: 18.36px;
      color: #80868c;
      white-space: normal;
      text-wrap: wrap;
    }
  }
  // custom styles for the big table
  .big-table-normal-column {
    padding: 16px 4px;
  }
  .big-table-percentage-column {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    padding: 16px 4px;
    &-icon {
      width: 24px;
      height: 24px;
      img {
        object-fit: cover;
        width: 24px;
        height: 24px;
        box-sizing: border-box;
      }
    }
    &-value {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.02px;
      color: #000000de;
    }
    &-rate {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      border-bottom: 1px dashed #94a3b8;
      gap: 3px;
      &-value {
        font-size: 14px;
        font-weight: 400;
        line-height: 20.02px;
      }

      &.descend {
        color: #3b873e;
      }
      &.ascend {
        color: #e31b0c;
      }
      &.constant {
        color: #64748b;
      }
    }
  }
}

.engagegment-analytics-filter-container {
  .footer__wrapper button {
    flex: 1;
  }
}

div.agent-performance-table-tooltip {
  max-width: 200px;
  padding: 13px 13px 13px 18px;
}
