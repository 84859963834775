@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.audience-survey-box {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.audience-survey-title {
  h5 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  span {
    color: #696d72;
    font-size: 13px;
    font-weight: 400;
    display: flex;
  }
  .audience-survey-date {
    margin-left: 5px;
    @include ar {
      direction: ltr;
      margin-right: 5px;
    }
  }
}

.show-btn {
  font-size: 14px;
  color: #456eb2;
  font-weight: 400;
  @include ar {
    .MuiSvgIcon-root {
      margin-inline-start: 8px;
    }
  }
}
.question-audience-box.question-direction-rtl {
  direction: rtl;

  .questionStylee textarea,
  p.questionStyle.audience {
    direction: rtl;
  }
  .phone-number-question {
    .phoneInput {
      left: -86px;
      direction: rtl;
    }
  }
  @include ar {
    .textFieldStyle.audience {
      width: max-content;
      .phoneInput {
        left: -82px;
        min-width: 450px;
      }
    }

    .textFieldStyle.audience {
      .intl-tel-input.allow-dropdown {
        .flag-container {
          left: 0;
          direction: rtl;
        }
      }
    }
  }
}
div.question-audience-box.question-direction-ltr {
  direction: ltr;

  @include ar {
    .textFieldStyle.audience {
      .phoneInput {
        left: 86px;
      }
    }
    .textFieldStyle.audience {
      .intl-tel-input.allow-dropdown {
        .flag-container {
          left: 0px;
        }
      }
    }
  }
}
