@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.engagement-header-translation {
  .translation-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #F0F0F0;
    }
    img {
      height: 20px;
      width: 25px;
    }
    svg {
      color: #64748B;
      &.active-transelation{
        color: #456EB2;
      }
      font-size: 1.24rem;
    }
  }
}
.engagement-translation-menu {
  padding: 20px;
  width: 290px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .translation-menu-heading {
    color: #0f172a;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
  }
}
.translation-select-wrapper {
  display: flex;
  justify-content: space-between;
  .translation-input-field {
    width: calc(50% - 6px);
  }
}
.translation-input-field {
  label {
    background: #ffffff;
    top: -6px;
    @include ar {
      transform: translate(111px, 16px) scale(1);
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(111px, -10px) scale(0.8);
        padding: 3px;
      }
    }
  }
  label.MuiFormLabel-filled,
  label.Mui-focused {
    top: 0;
  }
}
.translation-owner.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  height: 40px;
  font-size: 14px;
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    @include ar {
      padding-right: 14px;
      padding-left: 32px;
    }
  }
}
.translation-name-item.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-root {
  font-size: 14px;
}
.engagement-translation-loading {
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.translation-snackbar-heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: block;
}
.translation-snackbar-description {
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
  display: block;
  max-width: 250px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.translation-menu-button {
  &:hover {
    background-color: #456eb2;
  }
}
