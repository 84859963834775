:root {
  --swiper-navigation-size: 44px;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  border: 1px solid transparent;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  transition: 0.3s;
}

.swiper-container:hover .swiper-button-next,
.swiper-container:hover .swiper-button-prev {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.swiper-container:hover .swiper-button-next:hover,
.swiper-container:hover .swiper-button-prev:hover {
  background: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
}
/* .swiper-button-next:hover,
.swiper-button-prev:hover {
  background: rgba(0, 0, 0, 0.87);
} */
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  display: none;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 14px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "prev";
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "next";
}
.swiper-button-next.swiper-button-white,
.swiper-button-prev.swiper-button-white {
  --swiper-navigation-color: #ffffff;
}
.swiper-button-next.swiper-button-black,
.swiper-button-prev.swiper-button-black {
  --swiper-navigation-color: #000000;
}
.swiper-button-lock {
  display: none;
}
