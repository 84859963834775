@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.luci-questions-wrapper {
  .list-questions-container {
    padding: 20px;
    .luci-question-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      .question-item-data {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #212121;
      }
      .question-arrow-icon {
        width: 22px;
        color: #212121;
      }
      &:hover {
        background: #fafafa;
        cursor: pointer;
      }
      border-bottom: 1px solid #ededed;
    }
  }

  .circular-loading.MuiBox-root {
    top: 0px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
}

.question-arrow-icon {
  @include ar {
    transform: rotate(180deg);
  }
}

.show-prev-requests-btn {
  &.MuiButton-root {
    svg {
      padding-inline-end: 8px;
    }
    font-size: 14px;
    font-weight: 500;
    color: #456eb2;
    margin-inline: 20px;
    padding: 8px 12px;
    min-width: unset;
    margin-bottom: 30px;
  }
}
.luci-question-incoming-answer {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #4caf50;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 25px;
  cursor: pointer;
  svg {
    width: 22px;
    color: #212121;
  }
  .luci-incoming-icon {
    padding-inline-end: 10px;
    color: #4caf50;
  }
  &.failed {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      #f44336;
  }
}
.question-item-data {
  &.failed {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .luci-failed-msgs {
    padding-inline-start: 13px;
    .luci-msg-header {
      font-size: 16px;
      font-weight: 500;
    }
    .luci-msg-body {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .luci-failed-icon {
    color: #f44336;
  }
}
.luci-failed-close-icon {
  &.MuiButton-root {
    padding-inline-end: 24px;
    svg {
      color: #535353;
    }
    &:hover {
      background-color: transparent;
    }
  }
}
//// recieved popup
.luci-recived-popup {
  background: #ffffff;
  box-shadow: 0px 2px 130px rgba(0, 0, 0, 0.16), 0px 1px 6px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  position: fixed;
  z-index: 99;
  inset-inline-end: 42px;
  bottom: 120px;
  padding: 20px;
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  &.success {
    svg {
      color: #4caf50;
      padding-inline-end: 20px;
    }
  }
  &.failed {
    svg {
      color: #f44336;
      padding-inline-end: 20px;
    }
  }
}
