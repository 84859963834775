@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.need-help-faq {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
.monitor-card-help {
  display: inline-block;
  position: relative;
  left: 9px;
  @include ar {
    right: 9px;
  }
}
.faq-link {
  color: #456EB2 ;
  text-decoration: underline;
}
