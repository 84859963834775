@mixin en {
    html[lang="en"] & {
      @content;
    }
  }
  @mixin ar {
    html[lang="ar"] & {
      @content;
    }
  }

.MuiAlert-message{
    @include ar {
    margin-right: 5px;
    }
}
.MuiAlert-action{
    @include ar {
    margin-left: -8px;
    margin-right: auto;
}
}
.alert-message-Id-with-link{
  color: #456EB2 ;
  text-decoration: underline; 
}