.create-inbound-routing-container {
  // the container padding has important in the outer style
  padding: 0 !important;
  .steppers-width {
    width: auto;
    flex: 1;
    .MuiStepper-root.MuiStepper-horizontal .MuiStepLabel-label {
      width: auto !important;
    }
    .MuiStepper-root.MuiStepper-horizontal {
      width: auto;
      margin: 0 200px;
    }
    .MuiStepConnector-line {
      width: 100% !important;
    }
  }
  .card-header-heading-create-screen {
    font-weight: 500 !important;
  }
  .inbound-routing-main-container {
    margin-top: 30px;
    .MuiStepper-root.MuiStepper-horizontal .MuiStepConnector-root {
      margin: 0 8px;
    }
    .stepper-end {
      display: flex;
      gap: 15px;
    }
    .steppers-buttons {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  .inbound-routing-steps-container {
    width: auto;
    background-color: white;
    padding: 50px 0;
  }
  .create-route-details {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &-content {
      display: flex;
      flex-direction: column;
      gap: 80px;
    }
    .create-route-details-title {
      font-size: 15px;
      font-weight: 600;
      line-height: 18px;
      color: #232b34;
    }
  }
  .team-and-slas-details {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &-title {
      font-size: 15px;
      font-weight: 600;
      line-height: 18px;
      color: #232b34;
    }
    &-desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      color: #00000099;
    }
  }
}
