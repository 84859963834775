@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.chart-body {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.widget-header {
  padding: 20px 18px 0px 18px;
}

.box {
  width: 50px;
  height: 50px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #2196f3;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.response_data {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  padding-top: 8px;
  color: #2aaae2;
}

.date_format {
  font-style: normal;
  color: #9e9e9e;
  padding-top: 2px;
  font-size: 12px !important;
}
.customer-report {
  display: flex;
  justify-content: space-between;
}
.response_header {
  display: flex;
  gap: 12px;
}

.response_colon {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 20px !important;

  padding: 0px 7px 0px 7px;
  color: #80868c;
}

.response_title {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #64748b;
  border-bottom: 1px dashed #cbd5e1;
}

@mixin flexbox(
  $display: flex,
  $direction: row,
  $placeContent: null,
  $placeItems: null,
  $wrap: nowrap,
  $shrink: 0,
  $grow: 0,
  $alignContent: null,
  $justifyContent: null,
  $alignItems: null,
  $justifyItems: null
) {
  display: $display;
  flex-direction: $direction;

  @if $placeContent == null {
    @if $alignContent {
      align-content: $alignContent;
    }
    @if $justifyContent {
      justify-content: $justifyContent;
    }
  } @else {
    place-content: $placeContent;
  }

  @if $placeItems == null {
    @if $alignItems {
      align-items: $alignItems;
    }
    @if $justifyItems {
      justify-items: $justifyItems;
    }
  } @else {
    place-items: $placeItems;
  }

  flex-wrap: $wrap;
  flex-shrink: $shrink;
  flex-grow: $grow;
}
/////////////////////////////////////////////

.post-head {
  @include flexbox($justifyContent: space-between);
}
.post-title-date {
  &.hover-show {
    outline: 8px solid transparent;
    width: auto;
    &:hover {
      background: #0000000a;
      outline: 8px solid #0000000a;
      border-radius: 4px;
      cursor: pointer;
      .post-name {
        text-decoration-line: underline;
      }
    }
  }

  @include flexbox($justifyContent: center, $alignItems: center);
  img {
    width: 40px;
    height: 40px;
  }
}
.post-top-icons {
  display: flex;
  align-items: center;
  @include en {
    margin-bottom: 18px;
  }
  @include ar {
    margin-bottom: 28px;
  }
  // .chart-icon {
  //   margin: 0px 20px;
  // }
  .more-insights-disable {
    color: #cbd5e1;
    padding: 5px;
  }
  svg {
    color: rgba(100, 116, 139, 0.6);
    font-size: 14px;
  }
}
.more-vert-icon {
  position: relative;
}

.more-insights {
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  color: #9e9e9e;
  &:hover {
    color: #2aaae2;
    background-color: #f1f5f9;
  }
}

.reply-text {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.reply-text-style {
  color: #80868c;
  @include en {
    margin-left: 5px;
  }
  @include ar {
    margin-right: 5px;
  }
}
.post-title {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  margin: 0px 12px;
  width: 86%;
  svg {
    font-size: 15px;
    font-weight: 400;
    margin: 0px 3px;
  }
  .twitter_icon {
    color: #1da1f2;
  }
  .instagram_icon {
    color: #e1306c;
  }
  .facebook_icon {
    color: #4267b2;
  }
}

.post-name {
  display: flex;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1500px) {
  .post-title {
    svg {
      margin: 0px 3px 0px 5px;
    }
  }
}

.instagram-icon-style {
  color: #e1306c;
  margin-left: 5px;
  width: 16px;
}

.facebook-icon-style {
  color: #4267b2;
  @include en {
    margin-left: 5px;
  }
  @include ar {
    margin-right: 5px;
  }
}

.post-date {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #334155;
  font-size: 13px;
  font-weight: 400;
  margin: 0px 3px;
}
.question-content {
  padding: 10px 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}
.question-content-wrapper {
  @extend .question-content;
  padding: 20px 0;
  height: auto;
}
.statistics-icons {
  color: #334155;
  font-size: 12px;
  font-weight: 400;
  div {
    &:nth-child(4) {
      margin: 0px 10px;
    }
  }
  @include flexbox($alignItems: center);
  svg {
    color: #9e9e9e;
    font-size: 14px;
    margin: 0px 5px;
  }
}
.top-posts-head {
  @include flexbox($justifyContent: space-between);
  margin-top: 60px;
  margin-bottom: 20px;
  color: #0c192a;
  font-size: 18px;
  font-weight: 500;
  .top-posts-all {
    height: 30px;
    border: 1px solid #456eb2;
    border-radius: 4px;
    color: #456eb2 !important;
    font-size: 13px;
    font-weight: 500;
    padding: 0px 10px;
    min-width: fit-content !important;

    @include flexbox($justifyContent: center, $alignItems: center);
  }
}

.chart-heading {
  color: #80868c;
  border-bottom: 1px dashed #cbd5e1;
  width: fit-content;
}
.spam-tweet-icon {
  cursor: pointer;
  padding: 5px;
  height: 28px;
  border-radius: 3px;
  background-color: transparent;
  &:hover {
    color: #2aaae2;
    background-color: #f1f5f9;
  }
}

.spam-tweet-sync {
  padding: 0px 5px;
}

.divider-style {
  height: 45% !important;
  margin: 0px 9px !important;
  border-color: #e2e8f0 !important;
}

.post-user-name {
  margin-top: 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #00000099;
  @include ar {
    display: flex;
    direction: ltr;
    justify-content: flex-end;
  }
}

.smiliesStyle {
  font-size: 16px !important;
}

.sentimentStyle {
  font-size: 13px;
  font-weight: 500;
}

.neutral {
  color: #f9a700 !important;
}

.positive {
  color: #8cc63f !important;
}

.negative {
  color: #ed1846 !important;
}

.paddingStyle {
  padding: 0px 18px;
}

.margin-style-insta {
  width: 100%;
  word-break: break-all;
}

.customer_media {
  width: 130px;
  height: 92px;
}
.customer-media-nb {
  @extend .customer_media;
  display: flex;
  gap: 10px;
}

@media (max-width: 1440px) {
  .post-title {
    font-size: 14px;
    margin: 0px 10px;
  }
  .divider-style {
    @include en {
      margin: 0px 2px 0px 5px !important;
    }
    @include ar {
      margin: 0px 5px 0px 2px !important;
    }
  }
  .post-top-icons {
    .chart-icon {
      margin: 0px 16px;
      margin: 5px;
    }
  }
}

.external-link-icon {
  padding-bottom: 3px;
  @include en {
    margin: 0px 0px 0px 5px;
  }
  @include ar {
    margin: 0px 5px 0px 0px !important;
  }
}

.no-data-found-question {
  margin-bottom: 5% !important;
}

.topposts-wrapper {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
  .question-card-items {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  .question-card-footer {
    display: flex;
    justify-content: space-between;
    width: 92%;

    .more-insights {
      width: 13px;
    }
  }
  .question-content {
    display: flex;
    flex-direction: column;
  }
  .card-tweet-text {
    overflow: hidden;
  }
  .question-instagram {
    flex-direction: column;
    gap: 20px;
  }
  .margin-style {
    width: 100%;
    word-break: break-all;
  }
  .post-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.topposts-wrapper.topposts-wrapper-fb {
  grid-template-columns: repeat(2, 1fr);
}

.reactions-style {
  @include en {
    margin-right: 3px;
  }
  @include ar {
    margin-left: 3px;
  }
}

.img-style {
  width: 15px;
  border: 1.5px solid #ffffff;
  margin-left: -6px;
  position: relative;
  border-radius: 50%;
  background-color: #fff;
}

.img-style:first-child {
  margin-left: 0;
  z-index: 6;
  @include ar {
    margin-left: -6px;
    margin-right: 0;
  }
}

.img-style:nth-child(2) {
  z-index: 5;
}

.img-style:nth-child(3) {
  z-index: 4;
}

.img-style:nth-child(4) {
  z-index: 3;
}

.img-style:nth-child(5) {
  z-index: 2;
}

.img-style:last-child {
  margin-right: 4px;
  @include ar {
    margin-left: 4px;
    margin-right: 0px;
  }
}

.display-in-row {
  display: flex;
  align-items: center;
}
.post-title-icon-end {
  margin-inline-end: 5px !important;
}
.post-title-icon-start {
  margin-inline-start: 5px !important;
}
.customer-care-alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #d5f0fc;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #2196f3;
  .start-box,
  .end-box {
    display: flex;
    align-items: center;
  }
  .start-box {
    svg {
      width: 22px;
      margin-inline-end: 12px;
      color: #2196f3;
    }
    p {
      overflow: hidden;
      color: #334155;
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 143%;
      letter-spacing: 0.15px;
    }
  }
  .end-box {
    gap: 5px;
    svg {
      width: 20px;
      cursor: pointer;
    }
    button {
      color: #334155;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.46px;
      text-transform: uppercase !important;
      padding: 4px 5px !important;
    }
  }
}
.customer-care-alert.margin-b {
  margin-top: 11px;
}
.customer-care-alert.margin-bottom {
  margin-bottom: 24px;
}
.top-posts-header-icon {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  cursor: pointer;
  &:hover {
    background-color: #f1f5f9;
    button {
      background-color: #f1f5f9;
    }
  }
  svg {
    color: #00000099;
    height: 16px;
    width: 16px;
  }
}
