@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.luci-header-icon {
  &.MuiButton-root {
    background: #f7f7f7;
    padding: 0px 16px;
    height: 40px;
    min-width: unset;
    svg {
      font-size: 15px;
      color: #212121;
      @include ar {
        transform: rotate(180deg);
      }
    }
    .luci-header-back {
      color: #535353;
      font-size: 14px;
      font-weight: 500;
      padding-inline: 8px;
    }
  }
}
.luci-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
}
.luci-header-section-name {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #212121;
}
