@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.report-input-wrapper {
  width: 100%;
  .report-input-label {
    color: #0f172a;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
    @include ar {
      font-family: "Dubai";
      font-size: 16px;
    }
  }

  .MuiSelect-select.MuiInputBase-input {
    @include ar {
      padding-inline: 14px;
    }
  }

  .edit-report-select-input {
    &.Mui-disabled {
      fieldset {
        border: 1px dashed rgba(0, 0, 0, 0.23);
      }
    }
  }

  .MuiFormHelperText-root {
    @include ar {
      direction: rtl;
      text-align: start;
    }
  }

  .report-select-input-chips-container {
    margin-top: 10px;
    button#report-select-input-chip {
      text-transform: lowercase !important;
      border-radius: 20px;
      margin: 4px 4px 4px 0;
      @include ar {
        margin: 4px 0 4px 4px;
      }
      p {
        font-size: 13px;
      }
      svg {
        font-size: 14px;
      }
      .MuiButton-icon.MuiButton-endIcon {
        margin-inline-start: 3px;
        margin-inline-end: 0;
      }
    }
  }
}
