@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.edit-delete-buttons {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  // opacity: 0;
  // visibility: hidden;
  transition: all 0.5s ease;
  @include ar {
    left: 10px;
    right: unset;
  }
}
.edit-delete-buttons-link {
  @extend .edit-delete-buttons;
  right: 35px;
  @include ar {
    left: 35px;
    right: unset;
    top: 32px;
  }
}
.delete-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  &:hover {
    border-radius: 2px;
    background: #e6f3fc;
    transition: all 0.5s ease;
    svg {
      color: #456eb2;
    }
  }
}
div.disable-not-auth-edit-delete {
  color: rgba(0, 0, 0, 0.26);
  cursor: unset;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    svg {
      color: rgba(0, 0, 0, 0.26);
    }
  }
  .disable-not-auth-edit-delete-icons {
    color: rgba(0, 0, 0, 0.26);
  }
}
.disable-not-auth-edit-delete-not-complete-case {
  pointer-events: none;
}
.edit-icon-wrapper {
  cursor: pointer;
  @extend .delete-icon-wrapper;
}

.delete-engagement-buttons {
  margin: 0px 14px 14px;
}
.delete-post-body-modal {
  font-size: 15px;
  p {
    font-weight: 400;
  }
  span {
    &.MuiBox-root {
      font-weight: 500;
    }
  }
}
.topposts-wrapper {
  .delete-msg-comments {
    font-size: 14px;
    margin-top: 0px;
    direction: ltr;
  }
  .edit-delete-buttons {
    position: relative;
    right: 0px;
    @include ar {
      left: 0px;
    }
  }
  .delete-icon-wrapper {
    &:hover {
      background-color: rgba(128, 134, 140, 0.1);
      svg {
        color: rgba(100, 116, 139, 0.6);
      }
    }
  }
}
.disable-del-edit {
  pointer-events: none;
}
