@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.view-hashtag-detail {
  margin-top: 30px;
  margin-bottom: 80px;

  .view-hashtag-header {
    hr {
      height: 20px;
      margin: 0px 10px;
    }
    .twitter-link {
      color: #456eb2;
      cursor: pointer;
      svg {
        margin: 0px 5px;
      }
      @include ar {
        direction: ltr;
      }
      @include en {
        direction: rtl;
      }
    }
    .twitter-link-icon {
      margin-inline-end: 5px;
      color: #456eb2;
    }
    .hashtags-view-container {
      margin-top: 0px !important;
      @include ar {
        direction: ltr;
      }
    }
    .MuiBreadcrumbs-separator {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #cbd5e1;
    }

    .MuiBox-root {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 9px;
      h6 {
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #0c192a;
      }
      p {
        display: flex;
        align-items: center;

        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.015em;
        color: #80868c;
        cursor: pointer;
        svg {
          font-size: 15px;
          color: #80868c;
          @include en {
            margin-left: 4px;
          }
          @include ar {
            transform: matrix(-1, 0, 0, 1, 0, 0);
            margin-right: 4px;
          }
        }
      }
    }
  }
  .view-hashtag-filter {
    margin-top: 20px;
    .MuiPaper-root {
      background: #ffffff;
      border: 1px solid #e2e8f0;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      .MuiCardContent-root {
        padding: 30px;
        p {
          font-weight: 400;
          font-size: 17px;
          line-height: 24px;
          color: #334155;
          b {
            font-weight: 500;
          }
        }
        .filter-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 25px;
          .filter-field {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .MuiFormControl-root {
              width: 300px;

              .MuiInputLabel-root {
                top: -7px;
                @include ar {
                  left: auto;
                  right: 26px;
                }
                &.MuiInputLabel-shrink {
                  @include ar {
                    right: 10px;
                  }
                }
              }

              .MuiSelect-select {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: rgba(0, 0, 0, 0.6);

                @include en {
                  padding: 8px 44px 8px 12px;
                }

                @include ar {
                  padding: 8px 12px 8px 44px;
                }
              }
            }

            .reset-btn {
              border: 1px solid #80868c;
              padding: 7px 22px;

              font-weight: 500;
              font-size: 15px;
              line-height: 26px;
              color: #80868c;
              @include en {
                margin-left: 10px;
              }
              @include ar {
                margin-right: 10px;
              }
            }
          }

          .create-monitor-btn {
            padding: 8px 22px;
            font-weight: 500;
            font-size: 15px;
            line-height: 26px;
            letter-spacing: 0.46px;
            color: #456eb2;
            border: 1px solid #456eb2;
            @include en {
              margin-right: 6px;
            }

            @include ar {
              margin-right: 6px;
            }

            .MuiButton-startIcon {
              margin: 0px;
              margin-right: 13px;
              @include ar {
                order: 1;
              }
            }
          }
        }
      }
    }
  }
  .view-hashtag-data {
    .card-table {
      background: #ffffff;
      border: 1px solid #e2e8f0;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      padding: 20px;
      height: 470px;

      h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #334155;
        margin: 10px 0px 20px 0px;
      }

      .MuiTableHead-root {
        border-bottom: 0;
      }
      .MuiTableRow-head {
        height: 56px !important;

        .MuiTableCell-head {
          background: rgba(255, 255, 255, 0.002);
          text-transform: unset;

          @include en {
            padding: 16px 0px 16px 16px;
          }
          @include ar {
            padding: 16px 16px 16px 0px;
          }
          .MuiBox-root {
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.17px;
            color: rgba(0, 0, 0, 0.87);
            @include ar {
              font-family: "Dubai" !important;
              text-align: right;
            }
          }
        }
      }

      .MuiTableRow-root {
        height: 49px;
        .MuiTableCell-body {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.15px;
          color: rgba(0, 0, 0, 0.87);

          @include ar {
            font-family: "Dubai" !important;
            text-align: right;
            padding: 0px;
          }

          &:first-child {
            @include en {
              padding-left: 24px !important;
            }
            @include ar {
              padding-right: 24px !important;
            }
          }
        }
      }
      .MuiTableBody-root > :nth-of-type(odd) {
        background: #f8fafc;
      }
      .MuiTableBody-root > :nth-of-type(even) {
        background: rgba(255, 255, 255, 0.002);
      }

      .MuiTablePagination-root {
        background-color: #fff;
        .MuiToolbar-root {
          padding: 0px;
          min-height: 48px;
          background-color: #fff;
        }
        .MuiTablePagination-selectLabel {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: rgba(0, 0, 0, 0.6);
          @include ar {
            font-weight: 500;
          }
        }
        .MuiSelect-root {
          margin-left: 7px;
          margin-right: 8px;

          .MuiTablePagination-select {
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            letter-spacing: 0.3px;
            color: rgba(0, 0, 0, 0.87);
            @include ar {
              padding-left: 14px;
              padding-right: 4px;
            }
          }
          @include ar {
            svg {
              left: 0;
              right: auto;
            }
          }
        }
        .MuiTablePagination-displayedRows {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.4px;
          color: rgba(0, 0, 0, 0.87);
          margin: 0 26px;
          @include ar {
            font-weight: 500;
          }
        }
        .MuiTablePagination-actions {
          margin: 0;
          .MuiButtonBase-root {
            @include en {
              padding: 0px 20px;
            }
            @include ar {
              padding: 0px 12px;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .card-graph {
      padding: 23px 32px 35px 21px;
      h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #334155;
        margin-bottom: 30px;
      }
    }
  }
}

.hashtag-detail-period-menu-item {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  @include ar {
    font-family: "Dubai" !important;
  }
}
.trending-rank-column {
  width: 30px;
}
.trending-region-column {
  width: 270px;
}
.top-hashtag-bread-crumb.MuiTypography-root {
  cursor: pointer;
}
.top-hashtag-detail-bread-crumb.MuiTypography-root {
  text-decoration: none;
}
