@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin flexbox(
  $display: flex,
  $direction: row,
  $placeContent: null,
  $placeItems: null,
  $wrap: nowrap,
  $shrink: 0,
  $grow: 0,
  $alignContent: null,
  $justifyContent: null,
  $alignItems: null,
  $justifyItems: null
) {
  display: $display;
  flex-direction: $direction;

  @if $placeContent == null {
    @if $alignContent {
      align-content: $alignContent;
    }
    @if $justifyContent {
      justify-content: $justifyContent;
    }
  } @else {
    place-content: $placeContent;
  }

  @if $placeItems == null {
    @if $alignItems {
      align-items: $alignItems;
    }
    @if $justifyItems {
      justify-items: $justifyItems;
    }
  } @else {
    place-items: $placeItems;
  }

  flex-wrap: $wrap;
  flex-shrink: $shrink;
  flex-grow: $grow;
}
///------------------------------------------///
.deep-insight-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 20px;
  .user-bio {
    font-weight: 400;
  }
  &.min-height {
    min-height: 400px;
  }
}
.back-to-btn {
  color: #475569;
  font-size: 14px;
  font-weight: 400;
  svg {
    margin: 0px 5px;
  }
}
.deep-insight-content {
  color: #334155;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}
.deep-section-head {
  color: #334155;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
}
.deep-content-details {
  padding: 0;
  padding-left: 30px;
  li {
    margin-bottom: 10px;
  }
}
.deep-insight-stats {
  color: #334155;
  font-size: 14px;
  font-weight: 500;
  @include flexbox($alignItems: center, $justifyContent: space-between);
}
.deep-insight-body {
  .chart {
    height: 370px;
    .MuiTableFooter-root {
      .MuiTableCell-footer {
        border: none !important;
      }
    }
  }
}
.user-info-box {
  &.personality {
    @include flexbox($alignItems: center, $justifyContent: space-between);
  }
}
.deep-progress-wrapper {
  width: 100%;
  margin-bottom: 10px;
}
.deep-progress-value-wrapper {
  @include flexbox($alignItems: center, $justifyContent: center);
}
.deep-progress-line {
  .MuiLinearProgress-root {
    background: #2aaae2;
    border-radius: 0px 4px 4px 0px;
    height: 20px;
  }
}
.deep-progress-value {
  color: #64748b;
  font-size: 12px;
  font-weight: 500;
}
.deep-progress-name {
  p {
    color: #334155;
    font-size: 12px;
    font-weight: 400;
  }
}

.user-bio {
  &.deep-insights {
    color: #1e293b;
    font-weight: 400;
    font-size: 14px;
  }
}
