.sidebar-container {
  max-width: 270px;
  width: 270px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .reports-box,
  .need-help {
    display: flex;
    max-width: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    border-radius: 5px;
    border: 10px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  }
  .report-skeleton {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
  }
  .head {
    h2 {
      font-weight: 600;
      line-height: 20px;
    }
  }
  .reports-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 11.5px 0;
    width: 100%;
    svg {
      color: #1a174f;
      font-size: 40px;
      font-weight: 900;
      line-height: normal;
      margin-bottom: 20px;
    }
    h2 {
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 5px;
    }
    p {
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 12px;
      min-height: 40px;
      max-width: 203px;
    }
    button {
      display: flex;
      padding: 2px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      color: #456eb2;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      text-transform: capitalize;
      text-decoration-line: underline;
      &:hover {
        background: transparent;
      }
    }
    button.Mui-disabled {
      opacity: 0.25;
    }
  }
  .need-help {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    .item-content {
      width: calc(100% - 40px);
      &:nth-child(2) {
        margin-top: 10px;
      }
      display: flex;
      align-items: center;
      padding: 13px 18px;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: #fff;
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      text-transform: capitalize;
      cursor: pointer;
      &:hover {
        color: rgba(0, 0, 0, 0.87);
        background-color: #f8f8f8;
      }
      svg {
        color: #64748b;
        text-align: center;
        font-size: 16px;
      }
    }
  }
}
.need-help-skeleton {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  .item-need-skeleton {
    border-radius: 4px;
    height: 54px;
    background: linear-gradient(
        121deg,
        rgba(255, 255, 255, 0) 17.72%,
        rgba(255, 255, 255, 0.73) 47.7%,
        #f5f5f5 80.59%
      ),
      rgba(0, 0, 0, 0.04);
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
  }
}
