.popup-share-link-live-dashboard {
  min-width: 300px;
  .MuiDialogContent-root {
    border-top: 1px solid #0000001f;
    border-bottom: 1px solid #0000001f;
    overflow: auto;
    max-height: 344px;
  }
  .popup-body-share-link {
    min-width: 550px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    .text-customize-redirected-edit-the-live-dashboard {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: #00000099;
      letter-spacing: 0.15;
      .link-customize-redirected-edit-the-live-dashboard {
        font-weight: 600;
        color: #456eb2;
      }
    }
    .label-popup-live-dashboard {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      display: block;
      margin-bottom: 6px;
      color: #000000de;
      svg {
        margin-inline-start: 6px;
      }
    }
    hr {
      color: #cfcfcf;
      margin-block: 25px;
    }
    .copy-share-link-field-live-dashboard {
      width: 100%;
      height: 42px;
      padding: 0px;
      margin-bottom: 12px;
      input {
        padding: 7px 0px 7px 12px;
        font-size: 14px;
        line-height: 24px;
        color: #000000de;
      }
      fieldset {
        border-color: #94a3b8;
      }
    }
  }
  .date-range-popup-live-dashboed {
    font-weight: 600;
    font-size: 14px;
    line-height: 20.02px;
    color: #000000de;
  }
  .alert-genetate-public-link {
    margin-top: 25px;
    color: #1a174f;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.02px;
    svg {
      font-size: 16px;
      font-weight: 900;
      line-height: 22.88px;
      color: #456eb2;
    }
    .link-customize-redirected-edit-the-live-dashboard {
      color: #456eb2;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

.copy-share-link-tooltip.MuiTooltip-popper {
  margin-top: 10px !important;
  &.link-copied .MuiTooltip-tooltip {
    background-color: #3b873e;
  }
  .MuiTooltip-arrow {
    display: none;
  }
}
