@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.audience-segments {
  .segment-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #2aaae2;
    border: 1px solid rgba(42, 170, 226, 0.5);
    border-radius: 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
    padding: 3px 10px;
    margin-right: 12px;
    margin-bottom: 20px;
    @include ar {
      margin-left: 12px;
      margin-right: unset;
    }
  }
  .audience-main-title {
    margin-bottom: 20px;
  }
}

.segments-profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audience-segments-empty-msg {
  &.MuiTypography-root {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }
}

.delete-segment-profile {
  padding-left: 8px;
  color: #2aaae2;
  @include ar {
    padding-right: 8px;
    padding-left: unset;
  }
}

.segment-delete-pop-title {
  &.user-profile {
    display: block;
  }
}
