@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap");

@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.add-channel {
  max-width: 1300px;
  .add-channel-steps {
    .MuiStepLabel-iconContainer {
      @include ar {
        margin-left: 18px;
      }
    }
  }
  .add-channel-msg-auth-genesys.MuiBox-root {
    .MuiAlert-icon {
      color: #37588e !important;
    }
    .MuiAlert-message {
      color: #304d7d !important;
    }
  }

  .add-channel-msg-auth {
    .MuiAlert-icon {
      color: #456eb2 !important;
    }
  }
  .radio-btn {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }

  .text-area-style {
    margin-top: 12px;
  }

  .tooltipIcon {
    svg {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500 !important;
      font-size: 15px !important;
      line-height: 14px !important;
      color: #bdbdbd !important;
      position: relative;
      bottom: 1px;
    }
    width: 0;
    position: relative;
    left: 5px;
  }

  .select-acc {
    font-size: 14px;
    line-height: 15px;
    color: #232b34;
  }

  .auth-acc-msg {
    font-size: 14px;
    line-height: 15px;
    color: #64748b;
    font-style: italic;
  }

  .panel {
    width: "-webkit-fill-available";
    background: #ffffff;
    height: 60px;
  }

  .panel-header {
    height: 30px;
    box-shadow: 0px 3px 10px 0px #e3e3e3b3;
    z-index: 6;
    position: relative;
    display: flex;
    border-radius: 5px 5px 0 0;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .panel-body {
    box-shadow: 0px 0px 5px 0px #e3e3e3;
    min-height: 165px;
    border-radius: 0px;
    padding: 30px 100px 30px 100px;
    p {
      text-align: initial;
      font-size: 14px !important;
    }
    p.whatsapp-auth-title {
      text-align: initial;
      font-size: 14px;
      margin-bottom: 20px;
      color: #232b34;
      font-weight: 600;
    }
  }

  .panel-footer {
    max-height: 60px;
    padding: 18px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 4px 5px 0px #e3e3e3;
    border-radius: 0;
    border-top: 1px solid #efefef;

    div {
      text-align: end;
      flex: 1 1 auto;
    }
  }

  .category-item {
    width: 100%;
    height: 68px;
    background: #ffffff;
    border: 2px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    padding: 10px;
    color: #475569;
    justify-content: left;
    word-break: break-word;

    &:hover {
      background-color: rgba(69, 110, 178, 0.08);
      border-color: 2px solid #456eb2;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    }

    &:active {
      background-color: rgba(69, 110, 178, 0.08);
      border: 2px solid #456eb2;
      color: #456eb2;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    }

    &:focus {
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
      background-color: #d5f0fc;
      border: 2px solid #456eb2;
      color: #456eb2;
    }

    span {
      width: fit-content;
      height: 10px;
      color: #ffffff;
      right: 0px;
      position: absolute;
      top: 0px;
      font-size: 9px;
      padding: 5px;
      margin-bottom: 1px;

      @include ar {
        left: 0;
        right: auto;
      }
    }

    @include ar {
      justify-content: flex-start;
    }
  }

  .upgrade {
    background: #f5b819;
  }

  .comming_soon {
    background: #80868c;
    font-size: 10px;

    svg {
      margin-inline-end: 5px;
    }
  }

  .category-item-upgrade {
    border: 1px solid #f5b819;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .category-item-commingSoon {
    border: 1px solid #80868c;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .account-item {
    li {
      text-align: initial;

      .MuiTypography-root {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &:hover {
      background-color: #d5f0fc;
      border-color: 2px solid #456eb2;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    }

    &:focus {
      background-color: #d5f0fc;
      border: 2px solid #456eb2;
      color: #456eb2;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    }
    .whatsapp-list-content-card.MuiListItemText-root {
      span {
        margin: 0;
      }
      p {
        @include ar {
          direction: ltr;
          width: fit-content;
        }
      }
    }
  }
  .whatsapp-auth-step-wrapper {
    .whatsapp-auth-step-one {
      margin-bottom: 30px;
      p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .whatsapp-auth-step-one-wrapper {
        max-width: 550px;
        width: 100%;
        box-sizing: border-box;
        border: 2px solid #e2e8f0;
        padding: 16px;
        border-radius: 4px;
        &.step-two {
          background-color: rgba(248, 250, 252, 1);
          p {
            margin: 0;
            font-weight: 400;
          }
        }
        .whatsapp-auth-step-one-content {
          img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            border: 1px solid rgba(226, 232, 240, 1);
          }
          h6 {
            font-size: 16px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.87);
          }
          span {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            direction: ltr;
          }
        }
        .whatsapp-auth-step-one-authinticated-msg {
          color: #3b873e;
          @include ar {
            direction: ltr;
          }
          span {
            font-size: 15px;
            font-weight: 600;
            @include ar {
              font-size: 14px;
            }
          }
          svg {
            font-size: 17px;
            font-weight: 900;
          }
        }
      }
    }
  }
}

.add-private-steps {
  width: 800px !important;
}

.manage-menu {
  li {
    color: #212121;
    font-size: 14px;
    font-weight: 500;
  }

  svg {
    color: #212121;
    opacity: inherit;
    @include en {
      padding-right: 5px;
    }
    @include ar {
      padding-left: 5px;
    }
  }
  svg.head-icon {
    width: 24px;
  }
}
.tap-box {
  display: flex;
}
.twitter-tab {
  background: #f8fafc !important;

  &:hover {
    color: #000000 !important;
  }

  color: #000000;

  img {
    width: 14px;
    height: 14px;
    opacity: 0.6;
  }
  &.Mui-selected {
    color: #fff !important;
    background: #000000 !important;
    img {
      opacity: 1;
      filter: brightness(0) invert(1);
    }
  }
}

.facebook-tab {
  background: #f8fafc !important;
  color: #000000;

  &:hover {
    color: #3b5998 !important;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #3b5998 !important;
  }
}

.linkedin-tab {
  background: #f8fafc !important;
  color: #000000;

  &:hover {
    color: #0a66c2 !important;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #0a66c2 !important;
  }
}
.gmail-tab {
  background: #f8fafc !important;
  color: #000000;

  &:hover {
    color: #4285f4 !important;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #4285f4 !important;
  }
}

.google-my-business-tab {
  background: #f8fafc !important;
  color: #000000;

  &:hover {
    color: #4285f4 !important;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #4780e8 !important;
  }
}

.googleMyBusinessImage {
  margin-bottom: -1px;
  @include en {
    margin-right: 5px;
  }
  @include ar {
    margin-left: 5px;
  }
}

.intercom-tab {
  background: #f8fafc !important;
  color: #000000;

  &:hover {
    color: #326bff !important;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #326bff !important;
  }
}

.whatsapp-tab {
  background: #f8fafc !important;
  color: #000000;

  svg {
    font-size: 17px;
    color: #25d366;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #25d366 !important;

    svg {
      color: #fff;
    }
  }
}

.intercom-tab {
  background: #f8fafc !important;
  color: #000000;

  svg {
    font-size: 17px;
    color: #326bff;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #326bff !important;

    svg {
      color: #fff;
    }
  }
}

.genesys-tab {
  background: #f8fafc !important;
  color: #000000;

  &:hover {
    color: #0a66c2 !important;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #ff4f1f !important;
  }
}

.tiktok-tab {
  background: #f8fafc !important;
  color: #000000;

  &:hover {
    color: #000000 !important;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #000000 !important;
  }
}

.authorized-accounts-msg {
  display: flex;
  color: #64748b;
  font-size: 14px;
}

.MuiTabs-indicator {
  display: none;
}

.MuiButtonBase-root.MuiTab-root {
  flex-direction: row;
  justify-content: center;
  min-height: 40px;

  svg,
  img {
    margin-inline-end: 10px;
    margin-bottom: 0;
  }

  .tap-text {
    font-size: 12px;
    margin-top: 2px;
  }
}

.MuiTabs-root.css-1ujnqem-MuiTabs-root {
  min-height: 36px;
}

.instagram-tab {
  background: #f8fafc !important;
  color: #000000;

  &:hover {
    color: #e4405f !important;
  }

  &.Mui-selected {
    color: #fff !important;
    background: #e4405f !important;
  }
}

.MuiStepper-root.MuiStepper-horizontal {
  width: 410px;
  margin: 0 auto;

  .MuiStepConnector-root {
    margin: 0 28px;

    span {
      border-color: #e9e9e9;
    }
  }

  .MuiSvgIcon-root.Mui-completed {
    color: #8cc63f;
  }

  .MuiSvgIcon-root {
    color: #ededed;

    .MuiStepIcon-text {
      fill: #888888;
    }

    &.Mui-active {
      color: #28aae1;

      .MuiStepIcon-text {
        fill: #ffffff;
      }
    }
  }

  .MuiStepLabel-label {
    color: #888888;
    font-size: 13px;

    &.Mui-active {
      color: #555555;
    }
  }
}

.MuiStepper-root.MuiStepper-horizontal {
  width: 410px;
  margin: 0 auto;

  .MuiStepConnector-root {
    margin: 0 28px;

    span {
      border-color: #e9e9e9;
    }
  }

  .MuiSvgIcon-root {
    color: #ededed;

    .MuiStepIcon-text {
      fill: #888888;
    }

    &.Mui-active {
      color: #456eb2;

      .MuiStepIcon-text {
        fill: #ffffff;
      }
    }
  }

  .MuiStepLabel-label {
    color: #888888;
    font-size: 13px;

    &.Mui-active {
      color: #555555;
    }
  }
}

.account-item {
  padding: 0 !important;
}

.selected-account {
  width: 26px;
  height: 26px;
  background-color: #456eb2;
  position: absolute;
  top: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @include en {
    right: 0px;
  }
  @include ar {
    left: 0px;
  }
  svg {
    color: #fff;
    font-size: 12px;
  }

  margin: 0px 10px;
}

#whatsapp-auth {
  padding: 24px;
  width: 328px;

  .whatsapp-success {
    font-size: 19px;
    text-align: center;
    padding: 0;
    margin: 0 0 24px 0;
    font-weight: 500;
  }

  .whatsapp-success-msg {
    font-size: 14px;
    line-height: 20px;
    max-width: 280px;
    text-align: center;
    margin: 0 auto 24px auto;
    line-height: 20px;
    color: rgba(21, 25, 32, 0.5);
  }

  .css-hlj6pa-MuiDialogActions-root,
  .css-7u6ec5-MuiDialogContent-root {
    padding: 0px;
    position: unset;
  }

  .whatsapp-success-btn {
    width: 100%;
    height: 42px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
}

.MuiButton-startIcon {
  @include ar {
    margin-left: 7px;
  }
}

//whatsApp MSG
.whatsapp-msg {
  color: #64748b;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  display: flex;
  margin-bottom: 10px;
}

.whatsapp-statues {
  color: #232b34;
  font-style: normal;
}

.whatsapp-status-pending {
  padding: 0px 10px;
  color: #ec8623;
  font-size: 15px;
  font-weight: 500;
}

.whatsapp-status-declined {
  padding: 0px 10px;
  color: #e31b0c;
  font-size: 15px;
  font-weight: 500;
}

.manage-account-name {
  @include ar {
    direction: ltr;
  }
  .monitor-error {
    @include ar {
      float: right;
    }
  }
}

.add-channel {
  .bot-configuration {
    text-align: left;
    box-shadow: 0px 0px 5px 0px #e3e3e3;
    min-height: 200px;
    border-radius: 0px;
    padding: 30px 100px 30px 100px;
  }

  .form-control-typo {
    width: 100%;
  }

  .radiobuttons {
    padding-left: 15px;
    margin-bottom: 30px;
  }

  .bot-textarea-head {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
  }

  .textarea {
    height: 100px;
    border: solid 1px rgba(0, 0, 0, 0.23);
    padding: 14px 15px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    resize: none;
    width: 820px;
    font-family: "Graphik";
    box-sizing: border-box;
    &:focus-within {
      outline-color: #4cabe1;
    }
  }

  .textarea-configuration {
    height: 100px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    resize: none;
    width: 820px;
    font-family: "Graphik";
    box-sizing: border-box;
    &:focus-within {
      outline-color: #4cabe1;
    }
  }
  .whatsapp-auth-step {
    text-align: left;
    box-shadow: 0px 0px 5px 0px #e3e3e3;
    min-height: 200px;
    border-radius: 0px;
    padding: 30px 100px 30px 100px;
  }
}
.edit-textarea-configuration {
  display: block !important;
  width: 100% !important;
}

.tag-box {
  width: 600px;
}
.edit-tagbox {
  width: 560px;
}
.bot-heading {
  font-size: 15px !important;
  margin-bottom: 30px !important;
  color: #232b34 !important;
  font-weight: 500 !important;
  margin-top: 20px !important;
}

.select-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 167px !important;
}

.session-body {
  box-shadow: 0px 0px 5px 0px #e3e3e3;
  min-height: 200px;
  border-radius: 0px;
  padding: 30px 100px 30px 100px;
}
.edit-session-configuration {
  border-radius: 0px;
  box-shadow: none;
  padding: 0px !important;
  .session-heading {
    margin-bottom: 0px !important;
  }
}

.session-heading {
  font-size: 15px !important;
  margin-bottom: 20px !important;
  color: #232b34 !important;
  font-weight: 500 !important;
}

.session-textarea {
  font-size: 14px !important;
  color: #232b34 !important;
  font-weight: 400 !important;
  margin-top: 42px !important;
}
.select-textfield-heading {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 400 !important;
  margin: revert !important;
}
.select-text {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 400 !important;
}
.edit-select-text.MuiTypography-root {
  font-size: 16px;
  font-weight: 500;
  color: #000000de;
}
.select-box {
  display: flex;
  gap: 15px;
}
.main-select-box {
  width: 920px !important;
}
.outlinedbox-select > div {
  width: 100%;
}
.col-6-full {
  padding-right: 80px;
}

@media (max-width: 1200px) {
  .main-select-box {
    width: 100% !important;
  }
  .add-channel {
    .textarea {
      width: 100% !important;
    }
  }
  .col-6-full {
    padding-right: 0px;
  }
  .session-body {
    padding: 30px 30px 30px 30px;
  }
  .col-6-full {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-bottom: 15px;
  }
}
.remove-arrow {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.tooltip-custom {
  white-space: pre-line;
  padding: 13px 13px 18px 13px;
  line-height: 14px;
  font-weight: 400px;
  font-size: 10px;
  font-family: Graphik;
}
.dailog_box {
  width: 100%;
  .bot-configuration .text-area-style {
    .textarea {
      width: 100%;
      &:focus-within {
        outline-color: #4cabe1;
      }
    }
  }
  .subscribe_head_main .session-body {
    .textarea {
      width: 100%;
      &:focus-within {
        outline-color: #4cabe1;
      }
    }
  }
}
.subscribe_head {
  display: flex;
  justify-content: space-between;
}
.gray-number {
  color: #475569;
  @include en {
    float: right;
  }
  @include ar {
    float: left;
  }
}

.subscribe-head.MuiTypography-root {
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .edit-dm-config-title {
    font-size: 20px;
    font-weight: 500;
    color: #000000de;
  }
  .step-dm-config {
    font-size: 14px;
    font-weight: 400;
    color: #00000099;
  }
}
.edit-dm-bot-heading.MuiTypography-root {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000000de;
  margin: 12px 0px 4px 0px;
}
.radio-dm-config-btn {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  padding-inline: 5px;
}
.edit-title-dm-config {
  font-size: 14px;
  font-weight: 400;
  color: #00000099;
  padding-bottom: 4px;
}
.select-box-session {
  .select-textfield-heading-dm {
    font-size: 14px;
    font-weight: 400;
    color: #000000de;
    margin: revert;
  }
}

.subscribe_head_main > div {
  box-shadow: none;
}
.bot-configuration .text-area-style {
  margin-top: 15px;
  .textarea {
    height: 100px;
    border: solid 1px rgba(0, 0, 0, 0.23);
    padding: 14px 15px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    resize: none;
    font-family: "Graphik";
    box-sizing: border-box;
  }
}
.dailog-box .MuiPaper-elevation.MuiDialog-paperScrollPaper {
  max-width: 600px;
  width: 100%;
  max-height: calc(90% - 64px) !important;
  .tag-box {
    width: 100%;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormControl-root {
    width: 100%;
  }
}
.subscribe_head_main {
  .session-body {
    padding: 0px 0px 30px 0px;
    .main-select-box {
      width: 80% !important;
      .MuiGrid-grid-md-6 {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    .textarea {
      height: 100px;
      border: solid 1px rgba(0, 0, 0, 0.23);
      padding: 14px 15px;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      resize: none;
      width: 100%;
      font-family: "Graphik";
      box-sizing: border-box;
    }
    .tag-box {
      width: 100%;
      .chip-style {
        position: relative;
        padding-right: 20px;
        min-height: 34px;
        svg {
          position: absolute;
          top: 5px;
          right: 0px;
        }
      }
    }
  }
  .tool-tip-icon {
    svg {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500 !important;
      font-size: 15px !important;
      line-height: 14px !important;
      color: #bdbdbd !important;
      position: relative;
      bottom: 1px;
      @include ar {
        margin-right: 15px;
      }
    }
    width: 0;
    position: relative;
    left: 5px;
  }
  .session-heading {
    margin-bottom: 5px !important;
  }
}
.btnflex {
  justify-content: space-between !important;
  padding-right: 24px !important;
  padding-bottom: 20px !important;
}
.config-button {
  background-color: #456eb2 !important;
  color: white !important;
}
.cancel-button {
  color: rgba(0, 0, 0, 0.6) !important;
}
.edit-title {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 12px !important;
  line-height: 20px;
}

.add-channel .bot-configuration {
  @include ar {
    text-align: right;
  }
}
.add-channel .tooltipIcon svg {
  @include ar {
    margin-right: 15px;
  }
}
.col-6-full {
  @include ar {
    padding-right: 0px;
    padding-left: 98px;
  }
}

.bot-configuration .text-area-style {
  @include ar {
    margin-top: 15px;
  }
  .textarea {
    @include ar {
      height: 100px;
      border: solid 1px rgba(0, 0, 0, 0.23);
      padding: 14px 15px;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      resize: none;
      width: 100%;
      font-family: "Dubai";
      box-sizing: border-box;
      font-weight: 400;
    }
  }
}
.outlinedbox-select {
  @include ar {
    order: 2;
  }
  input {
    @include ar {
      text-align: right;
    }
  }
}
.subscribe_head_main .tooltipIcon svg {
  @include ar {
    margin-right: 15px;
  }
}
.graynumber {
  @include ar {
    direction: initial;
  }
}
.bot-configuration .text-area-style .textarea {
  @include ar {
    height: 116px !important;
  }
}

.bot-heading {
  margin-top: 0px !important;
  @include ar {
    text-align: right;
  }
}
.subscribe_head {
  padding-bottom: 0px !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}
.edit-title {
  margin-bottom: 15px;
  font-weight: 400 !important;
  font-size: 12px !important;
}
.channel-table {
  .social {
    margin-left: 0px !important;
  }
}
.add-channel-icon {
  color: #64748b;
  margin-top: 10px;
}
.messenger-icon {
  font-size: 1.1rem;
}
.public-icon,
.mail-icon {
  font-size: 1.2rem !important;
}

.bot-heading-edit {
  font-size: 13px !important;
  margin-bottom: 10px !important;
}
.edit-dailog-box {
  max-height: calc(100% - 100px);
  min-width: 700px;
  margin-top: 50px;
}
.dialog-subscribe {
  height: 358;
  max-width: 600px;
  .MuiDialogActions-root {
    display: flex;
    padding: 16px 24px;
  }
  .btn-popup-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
.edit-main-box {
  flex-direction: column !important;
  width: 600px !important;
}
.session-select-box {
  width: 30% !important;
  @include ar {
    .MuiSelect-icon {
      left: 7px;
      right: auto !important;
    }
  }
}
.edit-bot-configuration,
.edit-session-configuration {
  border-radius: 0px !important;
  box-shadow: none !important;
}

.chip-style {
  color: #456eb2 !important;
  border: 1px solid #456eb2 !important;
  background-color: white !important;
  margin-right: 5px !important;
  margin-top: 12px !important;
  max-width: 370px;
  height: auto !important;
  line-height: 15px !important;
  padding-top: 6px !important;
  padding-bottom: 5px !important;

  span {
    white-space: break-spaces;
  }

  p {
    line-height: 15px;
  }

  svg {
    color: #456eb2 !important;

    @include ar {
      left: 10px;
      position: relative;
    }
  }

  span {
    color: #456eb2 !important;
    direction: initial;
  }
}
.MuiStep-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.add-channel {
  max-width: 1300px;
  .account-item {
    li {
      padding: 12px 28px 12px 10px;
    }
  }
}
.note-edit-session-configuration {
  margin-top: 18px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.note-edit-word {
  font-weight: bold;
}
.ar-select-box {
  direction: ltr;
  @include ar {
    .MuiSelect-icon {
      left: 7px;
      right: auto !important;
    }
    .MuiSelect-select {
      text-align: end;
    }
  }
}
.session-ar-err-msg {
  text-align: right;
  margin-left: 0px !important;
}
.bord {
  border: 2px solid #456eb2;
}
.add-channel {
  .bread-crumb {
    margin-top: 20px;
  }
}
.add-channel-loading {
  .circular-loading {
    top: 25px !important;
  }
}
.loader-box {
  .circular-loading {
    top: 60px !important;
  }
}
.bot-configuration-loading {
  .circular-loading {
    top: 15px !important;
  }
}

.add-channel-msg-auth {
  margin-bottom: 20px;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
}
.error-msg-auth,
.add-channel-msg-auth {
  .MuiAlert-icon {
    @include ar {
      margin-left: 12px;
    }
  }
}
.gmb-info-messages {
  @include en {
    margin-left: -25px;
    margin-top: 3px;
  }
  @include ar {
    margin-right: -18px;
    margin-top: 3px;
  }
}

.google-icon-account-icon,
.google-icon-sign-in {
  height: 30px;
  width: 30px;
}
.google-icon-account-icon {
  margin-bottom: -5px;
}

.google-icon-sign-in {
  @include en {
    margin-right: 24px;
    margin-left: 8px;
  }
  @include ar {
    margin-left: 24px;
    margin-right: 8px;
  }
}

.google-sign-in-text {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Roboto" !important;
  color: #898989;
  @include en {
    margin-right: 30px;
    margin-top: 0px;
  }
  @include ar {
    margin-left: 30px;
    margin-top: 4px;
  }
}
.add-channel-msg-auth {
  .MuiAlert-standard {
    background-color: rgba(69, 110, 178, 0.08);
  }
}
#snackbar-linkedin-auth-alert-msg {
  width: 320px;
  margin-inline-end: 5px;
}

.genesys-add-channel-icon {
  margin-inline: 5px;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  width: 11px;
  margin-top: 3px;
}
.cancel-popup-btn.MuiButtonBase-root {
  margin-right: 10px;
}

.add-channel-form-create-mergin.MuiGrid-item {
  margin-top: 20px;
}
