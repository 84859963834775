@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin falcon-env-en {
  html[env="falcon"][lang="en"] & {
    @content;
  }
}
@mixin falcon-env-ar {
  html[env="falcon"][lang="ar"] & {
    @content;
  }
}
.login-wrapper {
  display: flex;
  flex-grow: 1;
  .form-wrapper {
    width: 640px;
    min-width: 640px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    padding: 50px 50px 20px;
  }
  .background-wrapper {
    flex-grow: 1;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 70px);
    padding: 50px 50px 20px;
    background-color: #1a174f;
    position: relative;
    z-index: 1;
    @include falcon-env-en {
      background-image: url("../../images/falcon/logo-favicon-falcon.svg");
      background-position: left;
    }
    @include falcon-env-ar {
      background-image: url("../../images/falcon/logo-favicon-falcon.svg");
      background-position: right;
    }
    @include en {
      background-image: url("../../images/log-in-page-english.png");
      background-position: left;
    }
    @include ar {
      background-image: url("../../images/log-in-page-arabic.png");
      background-position: right;
    }
    &::after {
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 500px;
      height: 100%;
      content: "";
      z-index: -1;
      @include en {
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0)
        );
      }
      @include falcon-env-en {
        background: none !important; // Override with linear gradient
      }
      @include ar {
        background: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0)
        );
      }
      @include falcon-env-ar {
        background: none !important; // Override with linear gradient
      }
    }
  }
}

@media (max-width: 642px) {
  .login-wrapper {
    .form-wrapper {
      width: calc(100% - 40px);
      min-width: calc(100% - 40px);
    }
    .background-wrapper {
      display: none;
    }
  }
}
@media (max-width: 1770px) {
  .login-wrapper {
    .form-wrapper {
      padding: 20px 20px 20px;
      height: calc(100vh - 40px);
    }
    .background-wrapper {
      padding: 20px 20px 20px;
      height: calc(100vh - 40px);
    }
  }
}
@media (max-width: 1050px) {
  .login-wrapper {
    .background-wrapper {
      background-size: cover;
      &::after {
        width: 100%;
      }
    }
  }
}
