.twitter-icon-authors {
  color: #1da1f2;
  width: 16px !important;
  height: 16px !important;
}
.user-name-author-dash {
  text-decoration: none;
}
.author-comments-container {
  padding-top: 16px;
}

#author-post-choices-icon-btn {
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: #00000099;
  }

  &:hover {
    background-color: #f1f5f9;
    svg {
      color: #00000099;
    }
  }
  &:active {
    background: #f1f5f9;
    svg {
      color: #456eb2 !important;
      background: transparent;
    }
  }
  &.author-post-open {
    background: #f1f5f9;
    svg {
      color: #456eb2 !important;
      background: transparent;
    }
  }
}
#author-item-list-tooltip {
  display: none;
}
