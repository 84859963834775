@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.interaction-list {
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 20px #0b182a05;
  border-radius: 3px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px 20px 11px;
  &.interaction-list-main {
    border: 1px solid #e2e8f0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    .posts_stats {
      padding: 25px;
    }
    .interaction-divider {
      height: 24px;
    }
    .posts-stats-value {
      .stats-value-time {
        text-transform: capitalize;
        span {
          span {
            font-weight: 600 !important;
          }
          display: inline-block !important;
        }
        @include ar {
          span {
            direction: ltr;
          }
        }
      }
    }

    .label-name {
      font-weight: 600;
      font-size: 16px;

      color: #000000de;
      @include ar {
        direction: ltr;
      }
    }
    .label-user-name {
      @include ar {
        margin-right: 10px;
      }
    }
    .label-username {
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      margin-top: -8px;
      color: #9b9b9b;
      @include ar {
        direction: ltr;
      }
    }

    .interaction-list__footer {
      margin-top: -5px;
      @include ar {
        margin-left: -15px;
      }
      @include en {
        margin-right: -15px;
      }
    }
  }
}
.latest-interaction-page,
.interaction-page-list {
  .main-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &.hover-show {
      outline: 8px solid transparent;
      align-items: center;
      &:hover {
        background: #0000000a;
        outline: 8px solid #0000000a;
        border-radius: 4px;
        cursor: pointer;
        .label {
          text-decoration-line: underline;
        }
      }
    }
    .interaction-list-user-data p {
      margin: 0 0 0 0;
    }
  }
  .interaction-list-user-data {
    display: inline-block;
    vertical-align: middle;
    @include ar {
      text-align: right;
    }
    a {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0;
      color: #000000;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      color: #00000099;

      margin: 0px 0px 0px 0px;
      &.label {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.015em;
        color: #000000de;

        @include ar {
          direction: ltr;
        }
      }
      &.phoneNumber {
        font-size: 11px;
        direction: ltr;
      }
    }
    .sub-name {
      @include ar {
        direction: ltr;
        margin-top: 0px !important;
      }
    }
  }
}
.interaction-list-header {
  margin-bottom: 10px;

  @include ar {
    text-align: right;
  }
}

.interaction-list__title {
  color: #6d737a;
  font-size: 14px;
  font-weight: 700;
  padding: 12px;
}
.interaction-list-userdata {
  display: inline-block;
  vertical-align: middle;
  @include ar {
    text-align: right;
  }
  a {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    color: #000000;
  }
  p {
    font-size: 14px;
    letter-spacing: 0;
    color: #737a84;
    margin: 0px 0px 0px;
    padding-left: 7px;
    &.label {
      font-weight: 500;
      font-size: 15px;
      color: #000000;

      @include ar {
        direction: ltr;
      }
    }
    &.phoneNumber {
      font-size: 11px;
      direction: ltr;
    }
  }
}
.interaction-list__icon {
  width: 39px;
  height: 39px;
  border-radius: 5px;
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
  font-size: 25px;
  margin: 0 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  @include ar {
    margin: 0 0 0 12px;
  }
}

.interaction-list__icon--twitter {
  background: #000000 0% 0% no-repeat padding-box;
}
.interaction-list__icon--googlemap {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  img {
    width: 40px;
    height: 40px;
  }
}
.interaction-list__icon--intercom {
  background: #0088f5 0% 0% no-repeat padding-box;
}
.interaction-list__icon--facebook {
  background: #1877f2;
  &.single-private {
    background: #1877f2;
  }

  img {
    width: 24px;
  }
}
.interaction-list__icon--linkedin {
  background: #0a66c2 0% 0% no-repeat padding-box;
}
.interaction-list__icon--tiktok {
  background-color: #000;
}
.interaction-list__icon--genesys {
  background: #ff4f1f 0% 0% no-repeat padding-box;
}
.interaction-list__icon--insta {
  background: #f1f5f9;
  img {
    width: 22px;
  }
}
.interaction-list__icon--foursquare {
  background: #f82c76 0% 0% no-repeat padding-box;
}
.interaction-list__icon--gmail {
  background: #f5f5f5 0% 0% no-repeat padding-box;
}
.interaction-list__icon--outlook {
  background: #1194f7 0% 0% no-repeat padding-box;
}
.interaction-list__icon--Whatsapp {
  background: #25d366;
}
.interaction-list__icon--zapier-email {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  color: rgba(123, 123, 123, 0.74);
}

.box-color {
  width: 10px;
  height: 10px !important;
  border-radius: 2px;
  display: inline-block;
  @include en {
    margin-right: 4px;
  }
  @include ar {
    margin-left: 4px;
  }
}
.redbg {
  background-color: #b44031;
}
.bluebg {
  background-color: #00bfff;
}

.interactions-list-card-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .date-time-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > .date-format,
    > .time-zone-format {
      font-size: 14px;
      color: #64748b;
    }
  }
}

.interaction-list__footer {
  display: flex;
  margin: 11px 0 0;
  align-items: center;
  .MuiOutlinedInput-root {
    padding: 0 !important;
  }
  div {
    font-size: 11px;
    letter-spacing: 0;
    color: #606873;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  @include ar {
    p svg {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  p.positive {
    color: #89bb2a;
  }
  p.neutral {
    color: #f9a700;
  }
  p.negative {
    color: #e50035;
  }
  h5 {
    font-size: 13px;
    letter-spacing: 0;
    color: #626262;
    margin: 5px;
  }
  .date-format {
    display: "inline";
    @include ar {
      direction: ltr;
    }
    .MuiTypography-root {
      font-size: 11px;
      margin: 0px 3px;
      line-height: inherit;
    }
  }
  .time-zone-format {
    display: "inline";
    @include ar {
      position: relative;
      left: 95%;
      margin-left: -28%;
    }
  }
  .themes-box {
    margin-inline-start: 8px;
  }
}
.single-int {
  display: inline-flex;
  @include ar {
    float: left;
  }

  @include en {
    float: right;
  }
  &.tiktok-no-margin {
    margin: 0;
  }
  .MuiOutlinedInput-root {
    min-width: 160px !important;
    .MuiSelect-select.MuiSelect-outlined {
      @include en {
        margin-right: 5px;
      }
    }
  }
}
.vertical-line {
  background: #e0e1e3;
  margin: 0px 10px 0px 10px;
  width: 2px;
  height: 20px !important;
}
.intraction-list__dropdown {
  .selectbox {
    width: 40px;
    height: 40px;
    background: #f2f2f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #0b182a12;
    border-radius: 5px;
    border: transparent;
    font-weight: bold;
    color: #a0a0a0;
  }
  .form-group {
    margin-bottom: 0px;
    font-size: 14px;
    color: #2b2622;
  }
  .dropdown-menu.show {
    display: block;
    transform: none;
    width: 248px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #0b182a12;
    border-radius: 5px;
    padding: 0 30px 30px 30px;
    left: -207px;
    @include ar {
      text-align: right;
    }
  }
  .divider {
    letter-spacing: 0;
    color: #9d9d9d;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
  }
  .positive,
  .positive.hover {
    font-size: 14px;
    letter-spacing: 0;
    color: #89bb2a;
    text-transform: capitalize;
    text-decoration: none;
  }
  .negative,
  .negative.hover {
    font-size: 14px;
    letter-spacing: 0;
    color: #e50035;
    text-transform: capitalize;
    text-decoration: none;
  }
  .neutral,
  .neutral.hover {
    font-size: 14px;
    letter-spacing: 0;
    color: #f9a700;
    text-transform: capitalize;
    text-decoration: none;
  }
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0px;
    width: 17px;
    height: 17px;
    border: 1px solid #b9b9b9;
    border-radius: 3px;
    vertical-align: middle;
    margin: 0px 14px 0 0;
  }

  .dropdown-item {
    cursor: pointer;
    padding: 0.25rem 0;

    label {
      color: #2b2622;
      font-size: 14px;
      text-transform: capitalize;
      margin-bottom: 0;

      svg {
        margin-right: 6px;
      }
    }
    &:hover,
    &:focus {
      background: transparent;
      border: 0;
      outline: 0;
    }
  }

  .dropdown-header {
    color: #9d9d9d;
    padding: 0.5rem 0;
    text-transform: uppercase;
    margin-top: 30px;
  }

  .btn-secondary {
    width: 40px;
    height: 40px;
    background: #f2f2f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #0b182a12;
    border-radius: 5px;
    border: transparent;
    font-weight: bold;
    color: #a0a0a0;
  }

  @include en {
    text-align: right;
    float: right;
  }
  @include ar {
    text-align: left;
    float: left;

    input[type="checkbox"] {
      margin: 0 0 0 14px;
    }

    .dropdown-item label svg {
      margin-left: 6px;
      margin-right: 0;
    }
  }
}
/*********interaction page style******/
.interaction-li .css-37jdci-MuiTypography-root {
  color: #a4a9af !important;
  text-transform: uppercase;
  font-weight: 400 !important;
  letter-spacing: 0.12em;
}

.interaction-page-list {
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 20px #0b182a05;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 20px 20px 11px;
  border: 1px solid #e2e8f0;
}

.header-interaction {
  display: flex;
  justify-content: space-between;
  .user-single-header {
    display: flex;
    align-items: center;

    &.hover-show {
      align-items: center;
      .label-username {
        text-decoration-line: none !important;
      }
      display: flex;
      outline: 8px solid transparent;
      .label-name {
        margin: 0 !important;
      }
      .label-username {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        color: #00000099;
        margin: 3px 0 0 0;
      }
      &:hover {
        background: #0000000a;
        outline: 8px solid #0000000a;
        border-radius: 4px;
        cursor: pointer;
        .label-username {
          text-decoration-line: none !important;
        }
        .label {
          text-decoration-line: underline;
        }
      }
    }
    .genesys-user-data {
      .label-name {
        margin: 0;
      }
      .label-username {
        margin: 3px 0 0 0;
      }
    }
  }
}
// whatsapp Styles
.view-more {
  width: 120px;
  height: 42px;
  background: #456eb2;
  border-radius: 4px;
  border: 0;
  color: #ffffff;
  margin: auto;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-date {
  direction: ltr;
  span {
    color: #737a84;
    font-weight: 400;
    font-size: 10px;
  }
}
.user-profile-whatsap {
  display: flex;
  flex-direction: column;
  span {
    &:nth-child(1) {
      color: #000;
      font-size: 15px;
      font-weight: 500;
      text-decoration: underline;
      margin: 0;
    }
    &:nth-child(2) {
      color: #737a84;
      font-size: 11px;
      font-weight: 400;
      direction: ltr;
    }
  }
}
.whatsapp-msg {
  background: #f8fafc;
  border-radius: 6px 6px 6px 0px;
  padding: 20px;
  margin: 15px 0px;
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;
}

.interaction-audio {
  width: 100%;
}
.interaction_vid {
  margin: 0px 20px;
}

//style of audio player
audio::-webkit-media-controls-panel {
  background-color: #fff;
}
audio::-webkit-media-controls-play-button {
  border-radius: 50%;
}
audio::-moz-media-controls-panel {
  background-color: #fff;
}
audio::-moz-media-controls-play-button {
  border-radius: 50%;
}

.pagination-interaction-list {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  li {
    button {
      margin: 0;
      border-radius: 0;
      border: 0;
      opacity: 1;
      border: 1px solid #e2e8f0;
      font-size: 14px;
      color: #64748b;
      height: 38px;
      &.Mui-selected {
        color: #cbd5e1;
        background-color: #fff;
        &:hover {
          color: #64748b;
        }
      }
    }
    &:first-child,
    &:last-child {
      button {
        border: 1px solid #e2e8f0;
        @include ar {
          -webkit-transform: rotateY(180deg);
          -moz-transform: rotateY(180deg);
          -o-transform: rotateY(180deg);
          -ms-transform: rotateY(180deg);
          unicode-bidi: bidi-override;
          direction: rtl;
        }
      }
    }
    &:first-child {
      button {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    &:last-child {
      button {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}
.forwarded-message {
  color: rgba(0, 0, 0, 0.38);
  font-size: 13px;
  padding-bottom: 10px;
  text-decoration: none;
  svg {
    @include en {
      margin-right: 7px;
    }
    @include ar {
      margin-left: 7px;
      transform: rotate(180deg);
    }
  }
}
a.pdfDiv {
  position: relative;
  width: 330px;
  height: 200px;
  display: inline-block;
  background: #f8fafc;
  text-decoration: none;
  iframe {
    overflow: hidden;
    pointer-events: none;
  }
  .pdf-title {
    background: rgba(0, 0, 0, 0.65);
    border-radius: 2px;
    height: 50px;
    line-height: 48px;
    position: absolute;
    bottom: 0;
    width: 310px;
    color: #fff;
    padding: 0 10px;
    font-weight: 500;
    display: flex;
    align-items: center;
    @include ar {
      right: 15px;
    }
    img {
      @include en {
        margin-right: 5px;
      }
      @include ar {
        margin-left: 5px;
      }
    }
  }
}
.contact-div {
  width: 279px;
  background: #f8fafc;
  border-radius: 3px;
  position: relative;
  .contact-details {
    display: flex;
    img {
      @include en {
        margin-right: 10px;
      }
      @include ar {
        margin-left: 10px;
      }
    }
    .contact-info {
      h3 {
        margin: 0;
        font-size: 12px;
        line-height: 13px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 5px;
        font-weight: 500;
      }
      span {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .contact-download {
    position: absolute;
    top: 14px;
    color: #334155;
    @include en {
      right: 20px;
    }
    @include ar {
      left: 20px;
    }
  }
}

.map-container {
  width: 260px;
  height: 210px;
  position: relative;
  background: #f8fafc;
}
.leaflet-container {
  position: absolute;
  top: 10;

  width: 260px;
  height: 170px;
  @include en {
    left: 10;
  }
  @include ar {
    right: 10;
  }
}
.leaflet-touch .leaflet-control-attribution {
  display: none;
}
.loction-info {
  position: absolute;
  bottom: 15px;
  span {
    font-size: 14px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
  }
  svg {
    color: rgba(0, 0, 0, 0.54);
    @include en {
      margin-right: 10px;
    }
    @include ar {
      margin-left: 10px;
    }
  }
}
.interaction-img-div {
  width: fit-content;
  display: flex;
  align-items: center;
  .interaction-img {
    max-width: 260px;
    border-radius: 2px;
    @include en {
      margin-right: 10px;
    }
    @include ar {
      margin-left: 10px;
    }
  }
}

.message-container {
  border-radius: 3px;
  // width: fit-content;
  margin: 15px 0px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 3px;
  padding: 20px;
  &.chat-main {
    padding: 28px;
    border: 2px solid #efefef;
    border-radius: 4px;
  }
  p {
    margin: 0;
    padding: 0;
  }
  &.message-container-text {
    width: auto;
    white-space: pre-line;
  }
}
.single-header-options {
  background: #eef2f7;
  padding-inline-start: 12px !important;
  @include en {
    margin-right: 10px;
  }
  @include ar {
    margin-left: 25px;
  }
  border-radius: 5px;
  .MuiOutlinedInput-root {
    .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input {
      @include ar {
        margin-right: -25px;
        direction: ltr;
        margin-top: 2px;
      }
    }
    svg {
      @include ar {
        position: relative;
        top: 1px;
      }
    }
    svg.MuiSelect-iconOpen {
      visibility: visible;
    }
    &:hover {
      svg {
        visibility: visible;
      }
    }
  }
  .MuiOutlinedInput-root {
    cursor: pointer !important;
  }
}
.single-header-options {
  fieldset {
    border: none;
  }
}
//
.rhap_main-controls {
  @include ar {
    flex-direction: row-reverse !important;
  }
}
.rhap_controls-section {
  button {
    @include ar {
      font-family: none !important;
    }
  }
}
.rhap_volume-button {
  @include ar {
    margin-left: 10px;
  }
}

.vertical-line-list-header {
  color: #cbd5e1;
  width: 1px !important;
  height: 20px !important;
  margin: 0px 10px !important;
}
.interaction-list-type {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 16px;
  padding: 3px 8px;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 18px !important;
}
.message-container-private {
  padding: 0 20px;
  .stats-value {
    span {
      span {
        font-weight: 600 !important;
      }
      display: inline-block !important;
    }
    @include ar {
      span {
        direction: ltr;
      }
    }
  }

  .posts-stats-value {
    p {
      text-transform: lowercase;
    }
    .stats-value-time {
      text-transform: capitalize;
    }
  }
}
.active_status {
  color: #3b873e;
}
.pending_status {
  color: #ed6c02;
}

.box-color-theme {
  width: 14px !important;
  height: 14px !important;
  @include en {
    margin-left: 5px;
  }
}
.themes-sub-title {
  opacity: 1 !important;
  .MuiTypography-root {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.87) !important;
    @include en {
      right: -3%;
    }
    @include ar {
      left: -3%;
    }
  }
}

.themes-items,
.themes-sub-title,
.sub-themes-items {
  padding: 6px 10px !important;
}
.sentiment-item {
  padding: 6px 23px 6px 15px !important;
}
.sub-theme-item {
  margin-right: -40px !important;
  @include ar {
    margin-right: 0 !important;
  }
}
.sub-themes-items {
  @include en {
    width: 230px !important;
  }
}

.sentiment-sub-title {
  padding: 6px 8px !important;
}
.progress-icon-sentiments {
  width: 25px !important;
  height: 25px !important;
  text-align: center;
  margin: 0px 30px;
}

.sub-themes-container {
  margin: 0 10px;
  width: max-content;
}

.chat-main-box {
  max-width: 793px;
  width: fit-content;
  margin-bottom: 20px;
  @media (max-width: 1600px) {
    max-width: 600px;
  }
  @media (max-width: 1200px) {
    max-width: 450px;
  }
  .interaction-list-user-data {
    .label {
      color: #000000de;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      border-bottom: 2px solid;
    }
    p {
      font-size: 11px;
    }
    .label-user-name {
      padding-top: 5px;
      padding-bottom: 10px;
      @include ar {
        direction: ltr;
      }
    }
  }
  .chat-message {
    background: #d5f0fc;
    border-radius: 6px 6px 6px 0px;
    padding: 12px;
    margin-top: 5px;
    color: #1e293b;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 12px;
    @include ar {
      direction: ltr;
    }
    video {
      max-width: 100%;
    }
  }
  .date-chat {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: #737a84;
    @include ar {
      direction: ltr;
    }
    .time-zone-space {
      margin-left: 10px;
    }
  }
}
.chat-main-box-right {
  text-align: right;
  margin-left: auto;
  @include ar {
    text-align: left;
    margin-right: auto;
    margin-left: inherit;
  }
  .chat-message {
    text-align: left;
    background: #f8fafc;
    border-radius: 6px 6px 0px 6px;
    @include ar {
      direction: ltr;
    }
  }
}
.chat-main-box-right .interaction-list-user-data {
  p {
    @include ar {
      direction: ltr;
      text-align: left;
    }
  }
}
.interaction-list-user-data p.name-label {
  font-size: 16px;
  font-weight: 600;
}
.interaction-header-align {
  display: flex;
  align-items: center;
  .interaction-list-user-data {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    .label-name {
      margin: 12px 0 11px 0;
    }
    .label-username {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      color: #00000099;
    }
  }
}

.themes-items,
.sub-themes-items {
  pointer-events: none;
  opacity: 0.37;
}
.check-selected-theme,
.check-selected-theme.Mui-selected,
.themes-items.Mui-selected,
.sub-themes-items.Mui-selected {
  pointer-events: visible;
  opacity: 1;
}

.load-more-btn {
  width: 120px;
  height: 42px;
  background: #456eb2;
  border-radius: 4px !important;
  display: flex !important;
  margin: 0 auto !important;
  margin-bottom: 15px !important;
  margin-top: 30px !important;
}
.interactions-page,
.all-interactions-list {
  .widget-title {
    border-bottom: 0;
    color: #a4a9af !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    letter-spacing: 0.12em;
    font-size: 14px !important;
  }
}
.chat-message-media {
  margin-bottom: 10px;
  video {
    max-width: 100%;
  }
}
.single-interactions-media {
  img {
    max-width: 100%;
  }
}

.interactions-page,
.interaction-chart,
.all-interactions-list {
  .chart-no-data {
    background: white;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.header-user-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #9b9b9b;
  margin-top: 2px;
}

.rating-header {
  @include en {
    margin-left: 12px;
  }
  @include ar {
    margin-right: 12px;
  }
}

.display-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.width-style {
  width: 90%;
}

.gmb-interaction-header {
  margin-bottom: 20px;
}

.gmb-review-img {
  width: 40px;
}

.audience-widget-title {
  color: #475569 !important;
  font-size: 16px;
  font-weight: 500 !important;
  margin-bottom: 15px !important;
  text-transform: capitalize;
  letter-spacing: 0;
  display: block;
}

.story-link {
  @include en {
    margin-left: 3px;
  }
  @include ar {
    margin-right: 3px;
  }
  color: #456eb2;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
}

.replied-to {
  color: #1e293b;
  font-size: 14px;
}

.replied-text {
  margin: 2px 0px 0px 0px;
  @include ar {
    direction: rtl;
  }
}

.link-not-available {
  @include ar {
    text-align: right;
  }
  color: #737a84;
  font-size: 10px;
  margin-top: -2px;
}

.story-link-unavailable {
  @include en {
    margin-left: 3px;
  }
  @include ar {
    margin-right: 3px;
  }
  pointer-events: none;
  color: #80868c;
  font-weight: 500;
  font-size: 14px;
}

.dm-text-style {
  margin-top: 4px;
}
.genesys-interactions-icon {
  filter: brightness(0) invert(1);
  width: 13px;
}

.interaction-list-genesys-wrapper {
  padding: 20px;
  gap: 20px;
  background: #f8fafc;
  border-radius: 3px;
  margin-top: 8px;
}
.interaction-list-genesys-content {
  padding: 15px;
  gap: 20px;
  background: #ffffff;
  border-radius: 3px;
  position: relative;
  .Mui-disabled {
    background-color: white !important;
  }
  .reload-btn {
    position: absolute;
    z-index: 1;
    left: 20px;
    top: 24px;
    width: 35px;
    height: 35px;
    background-color: #eef2f6;
    &:hover {
      background-color: #eef2f6;
    }
    &.firefox {
      top: 20px;
      width: 30px;
      height: 30px;
    }
    &.isSafari {
      left: 50px;
      top: 18px;
      width: 25px;
      height: 25px;
    }
  }
}
.single-genesys-widgets-wrapper {
  overflow: hidden;
}

.genesys-no-audio-section {
  margin-bottom: 20px;
  margin-top: 20px;
  span,
  .date-format-genesys {
    font-size: 14px !important;
    color: #80868c !important;
  }

  .time-zone-genesys {
    margin-inline: 5px;
  }
  svg.MuiSvgIcon-root {
    font-size: 17px;
    position: relative;
    top: 4px;
    @include en {
      margin-left: 18px;
      margin-right: 8px;
    }
    @include ar {
      margin-right: 18px;
      margin-left: 8px;
    }
  }
}

.reply-success-text-channel-analytics {
  background-color: #ebf9ec;
  border-radius: 19px;
  color: #4caf50;
  padding: 3px 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.46px;
  line-height: 18px;
  border: 1.5px solid transparent;
  @include en {
    margin-left: 2px;
  }
  @include ar {
    margin-right: 2px;
  }
}

.not-reply-text-channel-analytics {
  background-color: #f5f5f5;
  border-radius: 19px;
  color: rgba(0, 0, 0, 0.87);
  padding: 3px 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0.46px;
  line-height: 18px;
  border: 1.5px solid transparent;
  @include en {
    margin-left: 2px;
  }
  @include ar {
    margin-right: 2px;
  }
}
.tiktok-main-interaction {
  display: flex;
  gap: 10px;
  margin: 20px 0;
  border: 1px solid #e2e8f0;
  border-radius: 3px;
  padding: 20px;
  .main-interaction-details {
    width: calc(100% - 335px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    border: 1px solid #e2e8f0;
    height: fit-content;
    padding: 14px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    .main-details-text {
      color: #0c192a;
      font-size: 14px;
      font-weight: 400;
    }
    .main-details-reaction {
      display: flex;
      gap: 4px;
      margin-top: 5px;
      color: #334155;
      font-size: 12px;
      font-weight: 400;
      svg {
        color: #9e9e9e;
        width: 15px;
        height: 15px;
      }
    }
  }
}
.hide-sentiment-themes {
  .edit-sentiment-interactions {
    pointer-events: none !important;
    .MuiSvgIcon-root {
      display: none;
    }
  }
}
