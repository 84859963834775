#subscriptions-details-container {
  margin-top: 40px;
}

#subscriptions-details-accodrions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  .subscriptions-details-accordion {
    margin: 0;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow: none;
    &::before {
      display: none;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
      min-height: 86px;
      padding-inline: 24px;
    }

    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters::before {
      content: none;
    }
    .subscriptions-details-accordion-summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      padding-inline-end: 20px;
      .subscriptions-details-accordion-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        .logos-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .details-accordion-title-renewal-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          .details-accordion-title {
            color: rgba(0, 0, 0, 0.87);
            font-weight: 600;
            font-size: 16px;
          }
          .details-accordion-renewal {
            color: rgba(0, 0, 0, 0.6);
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }
    .subscriptions-details-accordion-details {
      padding: 34px;
    }
  }
}
