@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.manager-account-lists-dialog-body {
  .options-with-count {
    display: inline-block;
    margin: 0 3px;
    color: #00000099;
  }
  .tooltip-custom-new {
    padding-inline: 0px;
    padding-bottom: 28px;
  }
  .options-with-count-label {
    display: inline-block;
  }
  .user-info-rectangle {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
    margin: 20px auto;
    background-color: #f1f5f9;
    border-radius: 8px;
    padding: 10px 15px;
  }

  .account-list-chip {
    border-radius: 10px;
    font-size: small;
    color: #c77700;
    font-weight: bold;
    padding: 0px 5px;
    background-color: #fdf0e6;
    margin: 0px 5px;
    display: inline-block;
  }

  .image-section {
    flex-shrink: 0;
    margin-right: 15px;
  }

  .profile-image {
    width: 60px;
    height: 60px;
    border-radius: 3px;
  }

  .content-section {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .rectangle-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 1;
    padding: 0 10px;
    position: relative;

    .section-value {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      letter-spacing: 0.15px;
      color: #00000061;
    }
  }

  .tooltip-custom-new {
    font-size: 18px;
    color: black;
    .accounts-lists-searchable-multi-select-filter {
      margin-top: 15px;
    }
  }

  .rectangle-section::after {
    content: "";
    position: absolute;
    @include ar {
      left: 0;
    }
    @include en {
      right: 0;
    }
    top: 5%;
    bottom: 5%;
    width: 1px;
    background-color: #ccc;
  }

  .rectangle-section:last-child::after {
    display: none;
  }

  .name-section .manage-lists-name {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    overflow-wrap: anywhere;
  }

  .name-section .username {
    font-size: 16px;
    color: #95989a;
    font-weight: 600;
    text-align: center;
    width: 115%;
  }

  .followers-section .label,
  .location-section .label {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }

  .followers-section .value,
  .location-section .value {
    font-size: 16px;
    color: #95989a;
    font-weight: 600;
    width: 53%;
  }
  .manager-account-lists-dialog-body-checkbox {
    margin-right: 2px;
  }
}
.account-lists-dialog-popup-title {
  margin-top: 8px;
  margin-bottom: 8px;
  .account-lists-dialog-popup-username-title {
    font-weight: bold;
    font-size: 20px;
  }
}
