@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.manage-channel-table {
  th {
    color: #0c192a;
    font-size: 14px;
  }
}

.align-cell {
  color: #475569 !important;
  font-size: 13px !important;
  @include ar {
    text-align: right !important;
  }
}
.text-transform-letter {
  text-transform: capitalize !important;
}
.MuiButtonBase-root .align-more-icon {
  transform: rotate(90deg);
}
.channel-table-values .color-cell {
  color: #1e293b !important;
  font-size: 12px !important;
}
#monitorrsListHeade .button-padding-channel {
  @include ar {
    margin-left: 10px;
  }
}
.customer-care-modal {
  h2 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
  }
  div.MuiDialogActions-root {
    padding: 24px !important;
    button {
      width: max-content;
      padding: 8px 12px;
    }
  }
  .customer-Care-Body {
    .helper-msg {
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
    .error-msg.helper-msg {
      color: #f44336;
    }
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;
      > div {
        height: 38px;
      }
    }
    p.MuiTypography-root {
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 22px !important;
    }
  }
}
.shared-customer-care-container-channels {
  margin-bottom: 30px;

  h3 {
    color: #0f172a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    padding: 3px;
    margin-bottom: 10px;
    width: fit-content;
    border-bottom: 1px dashed rgba(100, 116, 139, 0.5);
    .tooltip-btn {
      display: flex;
    }
  }
  .customer-care-input {
    direction: ltr;
    width: 100%;
    input {
      padding: 0 12px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.38);
        font-size: 14px;
      }
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-color: rgba(0, 0, 0, 0.23);
      height: 38px;
      border-width: 1px;
    }
    p {
      text-align: start;
      @include ar {
        direction: rtl;
      }
    }
    .helper-msg {
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
    .error-msg {
      color: #f44336 !important;
      width: 100%;
      max-width: 425px;
      font-size: 12px;
      display: inline-block;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
.social-media-field-grid.keywords-inputs-fields {
  .shared-customer-care-container-channels {
    width: 100%;
    margin-bottom: 0;
    .MuiInputBase-root.MuiOutlinedInput-root {
      padding: 0;
    }
  }
}

#customer-care-info-icon {
  margin-inline-start: 6px;
  color: #bdbdbd;
  width: 18px;
  cursor: pointer;
}
#radio-buttons-box {
  .MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    padding: 8px 11px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    @include ar {
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.manage-channel-addons-success {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.manage-channel-out-team--will-be-in-touch {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.manage-channel-request-failed {
  font-weight: 500;
  font-size: 15px;
  color: #000;
}
.manage-channel-try-again {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
#manage-channels-search-field-label {
  @include ar {
    right: 120px;
    width: fit-content;
  }
}

//popup ca
.pop-up-shared-content-ca {
  #alert-dialog-description.MuiTypography-root {
    .shared-dialog-body {
      p {
        color: #000000de;
        font-size: 16px;
        font-weight: 400;
        &.sub-text {
          color: #64748b;
          font-style: italic;
          margin-top: 4px;
        }
        &.sub-text-not-italic {
          color: #00000099;
        }
      }
      .sub-text-desc {
        font-weight: 600;
        margin: 0;
        color: #000000de;
        font-size: 16px;
      }
    }

    .body-input-label {
      font-size: 16px;
      font-weight: 400;
      color: #000000de;
      line-height: unset;
    }
  }

  #alert-dialog-title {
    font-size: 20px;
    font-weight: 500;
    color: #000000de;
    text-transform: capitalize;
  }
}
