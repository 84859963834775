// mixin SASS
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.interations-chart,
.sentiment-chart,
.pie-chart,
.topkewords_wrapper {
  .chart {
    border: 1px solid #e2e8f0;
  }
  .MuiGrid-grid-md-6 {
    margin-bottom: 5px;
  }
}
.sentiment-chart {
  .MuiTypography-body1 {
    font-size: 12px;
  }
  .MuiGrid-item {
    + .MuiGrid-item {
      padding-top: 0 !important;
    }
  }
  .text-label {
    font-size: 14px;
    font-weight: 600;
  }
}
.overview-chart {
  .statistic {
    border: 1px solid #e2e8f0;
  }
}
.themes-chart {
  header {
    h3 {
      font-weight: 500;
      font-size: 16px;
      color: #334155;
    }
  }
  .chart {
    border: 1px solid #e2e8f0;
  }
  .MuiGrid-item {
    padding-top: 0 !important;
  }
}
.pie-chart {
  .chart {
    height: 360px !important;
    @include ar {
      height: 358px !important;
    }
  }
}
.client-engagement {
  height: 100%;
  // background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  .info-engagement {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    @include ar {
      margin-left: auto;
      margin-right: unset;
    }
  }
  .content-engagement {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    @include ar {
      margin-top: 20px;
    }
  }
  .info {
    position: relative;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    .fake_line {
      background: #2aaae2;
      width: 30px;
      height: 4px;
      border-radius: 100px;
      margin-bottom: 25px;
    }
    .title_wrapper {
      display: flex;
      flex-direction: column;
      margin: 0px 15px;
    }
    p.label {
      color: rgba(0, 0, 0, 0.6);
      font-weight: normal;
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      span {
        color: #80868c;
        font-size: 12px;
      }
    }
  }
  .response-time{
    display: flex;
    b{
      margin:0 2px 0 5px;
    }
  }
}
.channel-activity {
  .chart {
    .echarts-for-react {
      margin-top: 0;
    }
  }
}
