.content-dialg-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}
.applied-filters-title.MuiTypography-root {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #212121;
  padding-bottom: 30px;
}

#btn-close-confirm-dialog.MuiButtonBase-root {
  padding: 2px 10px;
  position: absolute;
  min-width: 30px;
  height: 30px;
  right: 21px;
  top: -40px;
  background: rgba(83, 83, 83, 0.8);
  border-radius: 2px;
  &:hover {
    background: rgba(83, 83, 83, 0.8);
  }
  svg {
    width: 10px;
    height: 26px;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #ffffff;
  }
}
