#survey-live-preview-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .survey-live-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #cdd5e1;
    min-height: 54px;
    width: 100%;
    padding: 0 20px;
    .survey-live-preview-header-title {
      font-size: 18px;
    }
  }

  .linked-survey-live-preview-container {
    max-width: 890px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    .submit-box {
      padding: 30px;
      border-radius: 4px;
      background: #fff;
      border-top: 1px solid #cbd5e1;
      margin-bottom: 148px;
    }
    .submit-box p {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 13px;
    }
  }

  .embedded-survey-live-preview-container {
    background: url("/images/survey/live-preview-website-skelton.svg");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    flex-grow: 1;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

#survey-answering-footer {
  width: 100%;
  .embedded-answering-footer-conetnt-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    .powered-by-container,
    .embedded-survey-answering-btns-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      #embedded-answering-next-btn,
      #embedded-answering-footer-close-btn {
        background-color: var(--survey-primary-color);
      }
      #embedded-answering-back-btn {
        border-color: var(--survey-primary-color);
        color: var(--survey-primary-color);
      }
    }
    .powered-by-container {
      p {
        color: rgba(0, 0, 0, 0.87);
        font-size: 12px;
      }
      img {
        height: 10px;
        cursor: pointer;
      }
    }
  }
}
