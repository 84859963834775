@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.alert-table {
  margin-top: 30px;

  .MuiTableHead-root {
    border-bottom: 0;
  }

  .MuiTableCell-head {
    padding: 16px 24px 16px 16px;
    font-size: 14px;
    line-height: 24px;
    color: #475569;
    background: #f1f5f9;
    text-transform: unset !important;
  }

  .MuiTableBody-root > :nth-of-type(odd) {
    background: #ffffff;
  }

  .MuiTableBody-root > :nth-of-type(even) {
    background: #f8fafc;
  }

  .MuiTableCell-body {
    padding: 16px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 0;
    p {
      padding: 16px;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
      padding: 0px;
      display: inline-block;
    }
    @include ar {
      &:last-child {
        text-align: center;
      }
    }
  }
  .alert-type {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    letter-spacing: 0px !important;
  }

  .alert-name {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 143% !important;
    color: #80868c !important;
  }
  .alert-monitor-td {
    .monitor-name {
      font-size: 13px !important;
    }

    button {
      cursor: unset;
      direction: ltr;
      width: 26px;
      height: 26px;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 13px;
      color: rgba(0, 0, 0, 0.6);
      min-width: auto;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-weight: 400;
      margin-left: 8px;
      margin-right: 0px;
      @include ar {
        margin-right: 15px;
        margin-left: 0px;
      }
    }
  }

  tr > td:first-child {
    direction: ltr;
    button {
      border-radius: 13px;
      color: rgba(0, 0, 0, 0.6);
      min-width: auto;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-weight: 400;
      margin-left: 6px !important;
    }
  }

  .actionBtn {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    &[aria-expanded="true"] {
      background-color: #cbd5e1;
      border-radius: 4px;
      color: #757575;
    }
  }

  .grayCircle {
    background: rgba(109, 115, 122, 0.2);
    width: 24px;
    height: 24px;
    border-radius: 12px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dark.comma-seprate {
    display: flex;
  }

  .alerts-social {
    display: flex;
    align-items: center;

    .icon-after-title {
      font-size: 16px;
      position: relative;
      margin-left: 5px;

      .instagram-icon {
        color: #e1306c;
        width: 16px !important;
        height: 16px !important;
        display: flex;
        position: relative;
      }

      .facebook__icon {
        width: 12px !important;
        height: 12px !important;
        font-size: 12px;
        align-items: center;
        padding: 2px;
        display: flex;
        position: relative;
      }
      .twitter-icon {
        width: 18px !important;
        height: 15px !important;
        display: flex;
        position: relative;
      }

      .intercom-icon {
        color: #0057ff;
        width: 16px;
        height: 16px;
        display: flex;
        position: relative;
      }

      .whatsapp-icon {
        color: #25d366;
        display: flex;
        position: relative;
      }

      .linkedIn-icon {
        color: #0a66c2;
        width: 20px;
        height: 22px;
        top: 6px;
        display: flex;
        position: relative;
      }

      .gmail-icon {
        width: 16px;
        height: 16px;
        top: 1px !important;
        display: flex;
        position: relative;
      }

      .talkwalker-icon {
        width: 18px;
        height: 16px;
        display: flex;
        position: relative;
      }

      top: 3px;

      @include ar {
        margin-right: 5px;
        top: 5px;
      }
    }

    .socialnetworks-icon,
    .talkwalker-icon {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  @include ar {
    th,
    td {
      font-family: "Dubai" !important;
      text-align: right;
    }

    .date-direction {
      direction: ltr;
    }
  }
}

.alert-action-dropdown {
  .MuiPaper-root {
    @include ar {
      transform: translate(60px, -5px) !important;
    }
    border-radius: 6px;
    margin-top: 8px;
    color: rgb(55, 65, 81);
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    .MuiMenu-list {
      padding: 4px 0;
    }
    .MuiMenuItem-root {
      font-size: 16px;
      color: #212121;
      line-height: 24px;
      letter-spacing: 0.15px;

      .MuiSvgIcon-root {
        font-size: 18;
        color: #212121;
        margin-right: 12px;
        @include ar {
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }
  }
}

.alert-modal-delete-btn {
  margin-left: 8px;
  margin-right: 0;
  @include ar {
    margin-right: 8px;
    margin-left: 0;
  }
}

.alert-modal-delete-text-message {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}
.alertboxmessage {
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.02) !important;
}
.alertbox {
  top: 50px !important;
  right: 59px !important;
  margin: 41px;
}
.delete-alert-dialog {
  .MuiPaper-root {
    width: 600px !important;
  }
  h2 {
    @include ar {
      font-size: 18px;
      font-weight: 500;
    }
  }
  p {
    @include ar {
      font-weight: 500;
    }
  }
}
.MuiTypography-root.alert-id{
  display: flex!important;
  gap: 6px;
  align-items: center;
  @include en {
    direction: ltr;
  }
  @include ar {
    direction: rtl;
  }
}
.invalid-tooltip{
  max-width: 177px;
  display: inline-block;
}