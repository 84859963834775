@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.luci-request-filter {
  padding-inline: 20px;
  margin-bottom: 30px;
}

.request-option-wrapper {
  &.MuiMenuItem-root {
    &:hover {
      background-color: #fafafa;
      transition: all 0.5s ease;
    }
  }
}
.request-option-value {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
  padding-inline: 10px;
}

.request-select-option-wrapper {
  width: 100%;
  .request-select-option {
    .MuiMenuItem-root {
      z-index: 99999;
    }
    .MuiSelect-select {
      padding: 8px;
    }
  }
}
