@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.main-survey {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-survey {
  margin-bottom: 0;
}

.survey-date {
  .date {
    margin-left: 5px;
    @include ar {
      direction: ltr;
      margin-right: 5px;
    }
  }
  span {
    display: flex;
    font-size: 14px;
    color: #696d72;
    font-weight: 400;
  }
}
.audience-shadow.question-direction-rtl {
  .survey-date {
    @include ar {
      direction: rtl;
    }
    @include en {
      direction: ltr;
    }
  }
}
