.MuiContainer-root.no-data-details-container {
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.no-data-details-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-grow: 1;
  .pagination-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0;
    svg {
      color: #456eb2;
      width: 36px;
      height: 36px;
    }
  }
  .no-data-text {
    max-width: 480px;
    text-align: center;
  }
  .text-heading {
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    color: #1e293b;
    margin: 16px 0 8px;
  }
  .text-description {
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
  }
}
