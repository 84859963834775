@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
#monitorsListHeader {
  .MuiGrid-item {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    
  }
}
#requestsListHeader {
  margin-top: 50px;
  font-size: 16px;
}

.css-1kpdewa-MuiAutocomplete-root {
  min-width: 200px;
  width: fit-content;
  height: 0;
}
#green {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  position: absolute;
  width: 95px;
  height: 30px;
  margin-top: 12px;
  background: #ecf5da;
  color: #89bb2a;
  border-radius: 4px;
  font-size: 11px;
  text-align: center;
}
#orange {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  position: absolute;
  width: 98px;
  height: 30px;
  margin-top: 12px;
  background: #fceac5;
  color: #f9a700;
  border-radius: 4px;
  font-size: 11px;
  text-align: center;
}
#red {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  position: absolute;
  width: 95px;
  height: 30px;
  margin-top: 12px;
  background: #ffd8e0;
  color: #e50035;
  border-radius: 4px;
  font-size: 11px;
  text-align: center;
}
#grey {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  position: absolute;
  width: 95px;
  height: 30px;
  margin-top: 12px;
  background: #e2e8f0;
  color: #80868c;
  border-radius: 4px;
  font-size: 11px;
  text-align: center;
}
.css-1wnsr1i {
  border: none;
  height: 175px;
  width: 584px;
}
.table--wrapper {
  min-height: 450px;
}
html[lang="ar"] .manage-channel-tabel {
  th,
  td {
    font-family: "Dubai" !important;
  }
}
html[lang="ar"] .manage-menu li {
  font-family: "Dubai" !important;
}

.pagination-monitor-list {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  li {
    .MuiPaginationItem-ellipsis {
      border: 1px solid rgba(0, 0, 0, 0.23);
      min-width: 32px;
      height: 32px;
      padding: 0 6px;
      line-height: 2.2;
    }
  }
  @include ar() {
    direction: rtl;
    li {
      &:first-child,
      &:last-child {
        button.MuiPaginationItem-circular {
          transform: rotate(180deg);
        }
      }
    }
  }
}
.pagination-one-page {
  margin: 30px 0;
}
.tooltip-posts-used {
  @include ar {
    font-size: 12px !important;
  }
}

.box-alert {
  @include ar {
    .MuiSnackbar-root {
      direction: rtl !important;
    }
    .MuiPaper-root {
      direction: rtl !important;
    }
  }

  .MuiAlert-message {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
#manage-channel-btn-tooltip {
  max-width: 243px;
  line-height: 1.5;
}
#insights-main-box {
  display: "flex";
  justify-content: "space-between";
}
