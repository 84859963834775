@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.main-sla-sidebar {
  width: 175px;
  background-color: #fff;
  transition: 0.1s ease-in;
  border-inline-end: 1px solid #e2e8f0;
  position: relative;
  .sla-sidebar-button {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e8eaf3;
    border-radius: 50%;
    top: 135px;
    left: 163px;
    z-index: 1;
    transition: 0.1s ease-in;
    cursor: pointer;
    @include ar {
      left: unset;
      right: 163px;
    }
    &:hover {
      background-color: #f1f5f9;
    }
    svg {
      width: 9px;
      height: 14px;
      color: #64748b;
      @include ar {
        rotate: 180deg;
      }
    }
  }
  &.sla-closed {
    width: 20px;
    .sla-sidebar-button {
      left: 8px;
      @include ar {
        left: unset;
        right: 8px;
      }
    }
  }
  .items-sla-sidebar {
    opacity: 1;
    max-width: 175px; /* Adjust this value as needed */
    &.items-sla-closed {
      opacity: 0;
      max-width: 0;
      overflow: hidden;
      transition:
        max-width 0.3s ease-in,
        opacity 0.2s ease-in;
    }
    .menu-sla-sidebar {
      height: 42px;
      padding: 12px 10px 12px 20px;
      display: flex;
      align-items: center;
      color: #000000de;
      font-size: 16px;
      font-weight: 700;
    }
    .menu-item-sla-sidebar {
      height: 20px;
      padding: 10px 10px 10px 20px;
      display: flex;
      align-items: center;
      color: #000000de;
      font-size: 14px;
      font-weight: 400;
      justify-content: space-between;
      cursor: pointer;
      text-wrap: nowrap;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-wrap: nowrap;
      @include ar {
        padding: 10px 20px 10px 10px;
      }
      &.item-selected {
        padding: 10px 10px 10px 16px;
        @include ar {
          padding: 10px 16px 10px 10px;
        }
        border-inline-start: 4px solid #456eb2;
        background-color: #f1f5f9;
        color: #456eb2;
      }
      &:hover{
        background-color: #F8FAFC;
      }
      svg {
        width: 12px;
        height: 12px;
        margin-inline-start: 5px;
        color: #00000042;
      }
      .menu-item-sla-count {
        background-color: #94a3b8;
        padding: 0px 3px;
        min-width: 8px;
        height: 13px;
        display: flex;
        border-radius: 60px;
        color: #fff;
        font-size: 10px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        direction: ltr;
      }
    }
  }
}
.sla-sidebar-ai-sparkles{
  margin-inline-start: 5px;
}