@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.cxm-core-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: auto;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 24px;
  .core-package-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    .details-data-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      > h6 {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 600;
        font-size: 16px;
      }
      > p {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
      }
    }
  }
  .users-progress-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-inline-end: 140px;
  }
}

#products-addons-container {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  margin-top: 40px;
  padding: 10px 0px;
  .products-addons-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 20px;
    border-bottom: 1px solid #e2e8f0;
    .subscriptions-tab-btn {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 600;
      font-size: 16px;
      padding: 10px 20px 20px 20px;
      &.active {
        color: rgba(0, 0, 0, 0.87);
        border-bottom: 5px solid #456eb2;
      }
      .tab-button-title {
        text-transform: none;
      }
    }
    > p {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      padding-bottom: 10px;
    }
  }
}

.products-addons-item-box {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
  &.inactive {
    > .item-header {
      background-color: #e2e8f0;
    }
    > .details-container {
      background-color: #f8fafc;
    }
  }
  > .item-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 110px;
    background-color: #f8fafc;
    .item-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      background: #ffffff;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      > img {
        width: 45px;
        height: auto;
      }
    }
    > .item-status-icon {
      position: absolute;
      top: 20px;
      right: 30px;
      font-size: 16px;
      color: #64748b;
      &.active {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        background-color: #4caf50;
        border-radius: 50%;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
  > .details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    flex-grow: 1;
    padding: 25px 15px 15px 15px;
    .item-main-title {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 10px;
    }
    .item-sub-title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }
    > .item-btns-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .request-demo-btn,
      .request-upgrade-btn {
        &.requested {
          opacity: 1;
          color: #3b873e;
        }
      }
    }
  }
}
