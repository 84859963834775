@import "App.scss";
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.main-header {
  margin-top: 30px !important;
}
.main-header-create-screen {
  margin-top: 30px !important;
}
.card-header-heading-create-screen {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 22px !important;
  padding-top: 3px;
  color: #0c192a !important;
}

.textStyle {
  color: #64748b !important;
  font-size: 14px !important;
}

.card-header-heading {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 22px !important;
  padding-top: 3px;
  color: #0c192a !important;
}

.main-container {
  border-radius: 6px;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 40px;
}

.monitor-type {
  width: auto;
  background-color: white;
  padding: 50px 0px;
  .circular-loading {
    top: 20px;
  }
}

.grid-cards-Style {
  padding-top: 19px !important;
  padding-left: 19px !important;
}

.select-monitor-style {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #232b34;
  letter-spacing: 0.03333em;
  display: inline-block;
  padding-top: 0px !important;
}

.card-heading {
  padding: 0px 10px;
  font-weight: 410 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #475569 !important;
}

.card-heading-with-icon {
  padding: 0px 0px 10px 0px !important;
}

.card-heading-with-icon:hover {
  background-color: white !important;
}

.card-sub-text {
  font-style: normal;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #64748b !important;
  margin-top: 2px !important;
}

.create-monitor-card {
  height: 152px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;
  min-width: 275px;
  height: 124px;
  cursor: pointer;

  &.selected {
    border: 2px solid #456eb2;
    box-sizing: border-box;
    box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    svg {
      color: #456eb2;
    }
  }
}

.steppers {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 60px;
  width: auto;
  background-color: white;
  filter: drop-shadow(0px 3px 10px rgba(65, 89, 104, 0.122));

  .MuiStepper-root.MuiStepper-horizontal .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .MuiStepper-root.MuiStepper-horizontal .MuiSvgIcon-root.Mui-completed {
    color: #89bb2a !important;
  }

  .MuiStepper-root.MuiStepper-horizontal .MuiSvgIcon-root .MuiStepIcon-text {
    fill: #fff !important;
  }

  .MuiStepper-root.MuiStepper-horizontal .MuiSvgIcon-root.Mui-active {
    color: #456eb2 !important;
  }

  .MuiStepLabel-label {
    width: 100px;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .Mui-active {
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: orange !important;
  }
}

.steppers-width {
  width: 80% !important;
}

.steppers-width .MuiStepConnector-line {
  width: 250px !important;
}

.steppers-buttons {
  width: auto;
  background-color: white;
  border-top: 1px solid #efefef;
  padding: 9px 25px 25px 25px;
}

html[lang="ar"] .steppers .MuiStepLabel-label {
  width: 140px;
  text-align: right;
  margin-right: 10px;
}

.next-step-style {
  margin-right: 40px !important;
  @include ar {
    margin-right: 0px !important;
    margin-left: 40px;
  }
}

.card-padding-class {
  padding: 18px 18px 24px 18px !important;
}
.card-header-error-msg {
  font-weight: 502 !important;
  font-size: 16px !important;
}
