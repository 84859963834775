.quick-box {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 25px 15px 25px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  div {
    margin: 10px;
  }
}

.custom-card-title {
  color: #475569;
}

.custom-card-subtile {
  font-weight: 500;
  font-size: 13px;
  width: max-content;
  margin-left: 5px;
}

#quick-inspect-dialog .MuiDialog-paper {
  max-width: 100%;
  width: 75%;
  margin-top: 90px;
  height: 88%;
}

.inspect-title {
  color: #334155;
  font-size: 18px;
  font-weight: 500;
  padding: 16px 0px;
}

.inspect-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f5f9;
  padding: 4px 24px;
}

.quick-inspect-grid {
  display: grid;
  gap: 10px;
  overflow: hidden;
  margin-top: 16px;
}

.quick-inspect-card {
  margin-top: 12px;
  display: flex;
  justify-content: space-around;
}

.quick-inspect-fetching {
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  margin-left: 5px;
}

.comments-title {
  color: #334155;
  font-size: 14px;
  font-weight: 500;
}

.comments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  color: #64748b;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
