@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.main-engagment-header {
  height: 6%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
  padding: 0 20px;
  background: #fff;
  position: relative;
  .header-left-side {
    display: flex;
    align-items: center;
    .user-avatar {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      border: 1px solid #cbd5e1;
      @include en {
        margin-right: 5px;
      }
      @include ar {
        margin-left: 5px;
      }
    }
    .engagment-header-img {
      position: relative;
      margin-inline-end: 6px;
    }
    .engagment-header-user-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .user-sub-name {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        letter-spacing: 0.15px;
        color: #00000099;
      }
    }
    .user-source {
      position: absolute;
      bottom: 1.5px;
      inset-inline-end: 2px;
      display: flex;
      width: 18px;
      height: 18px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 10px;
      color: #ffffff;
      border: 2px solid white;
      &.whatsapp-icon {
        bottom: -1px;
        inset-inline-end: 0px;
      }
      img {
        width: 10px;
        height: 10px;
      }
      &.facebook-icon {
        background-color: #1877f2;
      }
      &.twitter-icon {
        background-color: #000000;
      }
      &.instagram-icon {
        background: -webkit-radial-gradient(
          30% 107%,
          circle,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
      }
    }
    .user-name {
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.3px;
      @include en {
        margin-right: 5px;
      }
      @include ar {
        margin-left: 5px;
      }
    }
    .user-profile {
      color: #456eb2;
      font-size: 13px;
      letter-spacing: 0.15px;
      text-decoration-line: underline;
    }
  }
  .engagment-header-user {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px 6px 4px 0px;
    position: relative;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .header-right-side {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .translation-box {
      width: 36px;
      height: 36px;
      svg {
        width: 17px;
      }
      &:hover {
        background-color: rgba(241, 245, 249, 1);
      }
    }
    .translation-box-disabled {
      svg {
        color: rgba(0, 0, 0, 0.26);
      }
      &:hover {
        cursor: unset;
        background-color: transparent;
      }
    }
    .header-right-side-inner {
      display: flex;
      align-items: center;
    }
    .monitor-name {
      width: 120px;
      text-align-last: end;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .vertical-divider {
      border-left: 1px solid #e0e0e0;
      height: 18px;
      margin: 0px 5px;
    }
    .custom-copy-id {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      color: #737a84;
      @include en {
        margin-right: 30px;
      }
      @include ar {
        margin-left: 30px;
      }
      .text-id {
        width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        @include en {
          margin-right: 5px;
        }
        @include ar {
          direction: rtl;
          margin-left: 5px;
        }
      }
    }
    .reopen-btn {
      border: none;
      color: #c77700;
      border: 1px solid transparent;
      &:hover {
        color: #c77700;
        background-color: #fdf0e6;
        border: 1px solid rgba(237, 108, 2, 0.5);
      }
    }

    .complete-btn {
      color: #3b873e;
      border: 1px solid transparent;
      &:hover {
        background-color: #edf7ed;
        border: 1px solid rgba(76, 175, 80, 0.5);
      }
      &.Mui-disabled {
        opacity: 0.4;
      }
    }
    .sla-det-tooltip {
      width: 88px;
      height: 36px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 5px 12px;
      box-sizing: border-box;
      cursor: pointer;
      color: rgba(100, 116, 139, 1);
      &:hover {
        background-color: #f1f5f9;
      }
      svg {
        font-size: 14px;
        color: #64748b;
      }
      .engagement-header-details-text {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.engagment-header-user-box {
  position: relative;
  .engagment-header-profile {
    opacity: 0;
    visibility: hidden;
    transform: translate(0px, -25px) scale(0.7);
    transition:
      opacity 282ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 188ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    background: #ffffff;
    box-shadow:
      0px 4px 18px 3px rgba(0, 0, 0, 0.12),
      0px 10px 14px 1px rgba(0, 0, 0, 0.14),
      0px 6px 6px -3px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 45px;
    inset-inline-start: 0;
    z-index: 999;
  }
  &:hover {
    .user-name {
      text-decoration-line: underline;
    }
    .engagment-header-profile {
      opacity: 1;
      visibility: visible;
      transform: translate(0px, 0px) scale(1);
    }
  }
}
.sla-details-engagement-fetched {
  pointer-events: none;
}

#translation-settings {
  max-width: 130px;
  line-height: 14px;
  @include ar {
    max-width: 160px;
  }
}
