.third-party-integrations-item {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e1e2e4;
  height: 100%;
  box-sizing: border-box;

  > .item-logo-wrapper {
    background: #f8f8f9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 6px;
    > img {
      width: 32px;
      height: 32px;
    }
  }
  > .integration-item-title {
    font-weight: 500;
    font-size: 14px;
    color: #0d0c28;
  }
  > .integration-item-desc {
    flex-grow: 1;
    font-weight: 400;
    font-size: 14px;
    color: #74757f;
  }

  .third-party-request-btn {
    &.requested {
      opacity: 1;
      color: #3b873e;
      border-color: #3b873e;
    }
  }
}

#third-party-integrations-configure-body {
  .configure-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .configure-inputs-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    p#configure-input-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 3px;
      font-size: 16px;
      .required {
        color: #f44336;
      }
    }
    .MuiFormHelperText-root {
      color: #f44336;
    }
  }
}
