@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin popupSize($size) {
  $parent-class: null;

  @if $size == "all" {
    $parent-class: ".embedded-survey-popup";
  } @else if $size == "lg" {
    $parent-class: ".embedded-large-popup";
  } @else if $size == "md" {
    $parent-class: ".embedded-medium-popup";
  } @else if $size == "sm" {
    $parent-class: ".embedded-small-popup";
  }

  #{$parent-class} & {
    @content;
  }
}

.survey-builder-container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 159px);
}

.disabledFieldStyle .Mui-disabled:before {
  border-bottom-style: solid !important;
}

.dropdown-question-style label {
  @include ar {
    left: inherit;
    right: 22px;
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    top: -4px;
  }
}

.dropdown-question-style fieldset legend {
  @include ar {
    float: right;
  }
}
.survey-builder-back-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  .builder-header-back-btn {
    svg {
      color: rgba(71, 85, 105, 1);
      font-size: 14px;
    }
  }
  .survey-builder-header-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    svg {
      color: rgba(71, 85, 105, 1);
      font-size: 14px;
      margin-inline-end: 4px;
    }
  }
  .survey-status-chip {
    height: 24px;
    width: auto;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    background-color: #efefef;
    &.active {
      color: #367c3a;
      background-color: #edf7ed;
    }
  }
}
.header {
  background-color: #fff;
  border: 1px solid #cbd5e1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  .survey-builder-header {
    color: #000;
  }
  .header-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    #survey-builder-preview-btn,
    #survey-builder-save-btn {
      width: 36px;
    }
  }
}

.textStyle {
  font-family: Graphik;
  font-size: 14px;
}

.buttonStyle {
  padding: 3.5px 10px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  height: 30px;
  width: 67px;
}

.control-section-container {
  position: relative;
  width: 300px;
  min-width: 300px;
  border-inline-end: 1px solid #cbd5e1;
  border-bottom: 1px solid #cbd5e1;
  background-color: white;
}

.control-section-container .MuiTabs-indicator {
  display: inline;
}

.control-section-container .tab-style.embedded-survey {
  span.MuiTabs-indicator {
    display: none;
  }
}

.redirection-url-wrapper {
  margin: 20px;
  > h1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding: 20px 0;
    font-weight: 500;
    color: #0f172a;
    font-size: 16px;
    > .info-icon {
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
    }
  }
  .redirect-url-input {
    width: 100%;
    direction: ltr;
    &.rtl {
      direction: rtl;
    }
  }
  #redirection-on-completion-input-helper-text {
    @include en {
      text-align: left;
    }
    @include ar {
      text-align: right;
      direction: rtl;
    }
  }
}

.sec-title {
  margin-top: 20px;
  padding: 0px 29px;
  > h1 {
    font-weight: 600;
  }
}
.information-box {
  padding: 0 29px;
  margin-bottom: 20px;
}

.info-header {
  margin: 20px 0px;
  padding: 0px 29px;
}

.info-text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 10px;

  @include en {
    text-align: left !important;
    margin-left: 5px;
  }

  @include ar {
    text-align: right !important;
    margin-right: 5px;
  }
}

.info-field {
  width: 100%;
  margin: auto !important;
}

.info-field input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 45px;
}

.info-field label[data-shrink="false"] {
  top: -4.5px !important;
}

.flexStylee {
  display: flex;
  align-items: center;
  .added-icon {
    margin-inline-end: 13px;
  }
}

.flexStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab-style {
  border-bottom: 1px solid #e2e8f0;
}

.tab-style button {
  width: 50%;
  margin: 0px !important;
  text-transform: none !important;
  font-size: 17px !important;
  color: #00000060 !important;
  font-weight: 600;
}
.tab-style button.Mui-selected {
  color: #000 !important;
  font-weight: 700;
}

#embedded-survey-control-sidebar-tabs {
  button.MuiButtonBase-root {
    width: auto;
    padding-inline: 20px;
  }
}

.listItemText {
  color: #fff;
  background-color: #a8abae;
  border-radius: 4px;
  padding: 5px 9px;
  font-weight: 500;
  font-size: 15px;
  max-width: 48px;
  min-width: 41px;
  text-align: center;

  @include ar {
    margin-left: 5px;
  }

  @include en {
    margin-right: 5px;
  }
}

.quesNotSelected {
  background-color: #64748b !important;
}

.questionStyle span {
  font-size: 14px;
  font-weight: 300;
  margin: 0px 3px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.questionStyle {
  overflow: hidden;
  text-align: initial;
}

.cardStyle {
  width: 100%;
  margin: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  margin-top: 20px;
  padding: 30px;
  font-family: Graphik !important;
  overflow: visible !important;
  box-sizing: border-box;

  .card-body {
    padding: 0px !important;
  }
}

.noError {
  border: 2px solid #456eb2;
}

.error {
  border: 2px solid #e31b0c !important;
}

.questionStylee {
  textarea {
    font-size: 22px !important;
    color: #0c192a !important;
    line-height: 25px !important;
    font-weight: 500 !important;
    width: 100%;
    direction: ltr;
    padding: 0;
    padding-inline-start: 8px;

    @include ar {
      direction: rtl;
    }
  }
}

.question-title-container {
  display: flex;
}

.questionNoStyle {
  font-size: 22px;
  font-weight: 500;
  color: #456eb2;
  display: flex;
  align-items: center;
}

.card-body .css-ahle9a-MuiInputBase-root-MuiInput-root {
  .title-question {
    align-items: center;
  }
}

.subtitle {
  font-size: 18px !important;
  font-style: italic !important;
  font-weight: 300 !important;
  color: #696d72 !important;
  padding: 0px !important;
  width: 100%;
  margin-bottom: 8.5px;
}

.subtitle input {
  direction: ltr;

  @include ar {
    direction: rtl;
  }
}

.subtitle textarea {
  direction: ltr;

  @include ar {
    direction: rtl;
  }
}

.textFieldStyle {
  width: 574px;
}

.textFieldStyle label {
  color: #80868c !important;
}

.textField {
  width: 66%;
  height: 32px;
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid gray;
}

.impQuestion {
  color: red;
  margin-left: 5px;
}

.yes-no-option-container {
  width: 100%;
}
.yes-no-options {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > div {
    &:first-child {
      margin-inline-start: 9px;
      font-weight: 400;
    }

    font-weight: 300;
    font-size: 16px;
  }

  border-radius: 4px;
  width: 240px;
  margin: 7px;
  font-size: 16px;
  cursor: pointer;
  height: 36px;
}

.optionValue {
  font-weight: 400;
  margin-left: 8px;
  color: #80868c;
}

.yesNoValue {
  color: #0c192a;
  font-weight: 300;
  margin-left: 8px;
}

.unSelected {
  border: 1px solid black;
}

.addChoice {
  margin-top: 25px;
  margin-bottom: 25px;
  color: #456eb2;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.page-style {
  width: 100%;
  padding: 22px 0px 0px 0px;

  div {
    @include ar {
      direction: rtl;
    }
  }
}

.header-style {
  width: 100%;
  margin: 25px auto;
  background-color: #ffffff;
  border-radius: 4px;
  padding-bottom: 25px;
  font-family: Graphik !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  textarea {
    padding-inline: 3px;
  }
  .divider-style {
    border: 3px solid #456eb2 !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0px !important;
  }
  .divider-after-title {
    margin-inline: 30px;
    padding-block: 16px;
    margin-bottom: 16px;
  }
  .multiple-languages-container {
    margin-inline: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .align-box {
      #align-right-btn,
      #align-left-btn {
        padding: 6px;
        height: max-content;
        width: max-content;
        min-width: max-content;
        cursor: pointer;
        border: 1px solid #cbd5e1;
        color: #00000099;
      }
      #align-right-btn.active,
      #align-left-btn.active {
        border-width: 1px;
        border-color: #456eb2;
        border-style: solid;
        color: #456eb2;
        background: #f1f5f9;
      }
      #align-right-btn {
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      #align-left-btn {
        border-inline-end: 0px;
        border-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
}
.header-style.header-style-rtl {
  direction: rtl;
  div.MuiInputBase-root.MuiInput-root,
  div.MuiInputBase-root.MuiInput-root {
    direction: rtl;
  }
}
.header-style.header-style-ltr {
  direction: ltr;
  div.MuiInputBase-root.MuiInput-root,
  div.MuiInputBase-root.MuiInput-root {
    direction: ltr;
  }
}

.mainHeader {
  font-size: 20px;
  font-weight: 500;
  color: #0c192a;
}

.subText {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 15px;
  font-style: italic;
  font-size: 18px;
}

.saveBtn {
  width: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
  background: #ffffff !important;
  border: 1px solid #cbd5e1 !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  padding: -5px !important;
  margin: 0px 5px 0px 5px !important;
  color: #0000008a !important;
  cursor: pointer !important;
}

.saveBtn-active {
  background: #199cdc !important;
  color: #ffffff !important;
  border: 2px solid !important;
}

.edit-section-container {
  width: 100%;
  padding: 0px 0px 0px 26px;
  overflow-y: auto;
  height: calc(100vh - 159px);
  &.embedded-survey {
    position: relative;
    padding: 22px 30px;
  }
}

.scroll-bar-style {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dedede;
  padding-right: 21px;
  padding-bottom: 26px;
  min-height: calc(100vh - 190px);
}

.scroll-bar-control-section {
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
}
.multiple-responses-container {
  label.multiple-responses.MuiFormControlLabel-root {
    margin-inline-start: 20px;
  }
}
.multiple-res-lang-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include en {
    direction: ltr;
  }
  @include ar {
    direction: rtl;
  }
}
.header-style-rtl {
  .multiple-res-lang-container {
    direction: ltr;
  }
}

body {
  padding-bottom: 0px !important;
}

.app-footer {
  position: static !important;
  margin-top: auto;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.constraints-section-container {
  background-color: #ffffff;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.constraints-heading {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  padding: 20px 20px;
}

.constraints-sub-heading {
  font-size: 14px !important;
  font-weight: 700 !important;
  padding: 19px 20px;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
}

.constraints-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #0c192a;
  padding: 3px 12px 7px 20px;
}
.linearscale-constraints {
  flex-direction: column !important;
  .linearscale-constraints-container {
    width: 100%;
  }
}
.disabled-proceed-switch {
  cursor: pointer;
}

.questionTypeDropdown div {
  color: rgba(0, 0, 0, 0.6);
  font-style: normal !important;
}

.relativeClass {
  position: relative;
}

.absoluteClass {
  position: relative;
  margin-bottom: 15px;
  top: 9px;

  @include en {
    right: 54px;
  }

  @include ar {
    left: 54px;
  }

  svg {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.54);

    @include en {
      margin-right: 18px;
    }

    @include ar {
      margin-left: 18px;
    }
  }
}

.dropdown-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  .dropdown-choice > div {
    padding-bottom: 5px;
  }
}

.proceed-choice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  .next-section-select {
    margin-inline-start: 15px;
    cursor: pointer;
    svg {
      color: #00000090;
    }
    & > div {
      background: transparent;
      font-weight: 400;
      padding-top: 12px;
      @include ar {
        padding-right: 0;
      }
    }
  }
}

.options_dropdown {
  background-color: #fff;
  width: 528px;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 2px 16px 6px 16px;
  margin-top: 2px;
  max-height: 120px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.87);
}

.dropdown_options {
  margin-top: 12px;
  line-height: 150%;
}

.scroll-bar-control-section ::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar {
  width: 6px;
  margin-right: 10px;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}

.title-header-field {
  width: 90% !important;
  div textarea {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 20px !important;
    color: #000000 !important;
    margin: auto !important;
  }
}

.subtitle-header-field {
  width: 90% !important;
  div textarea {
    font-style: italic !important;
    font-weight: 300 !important;
    font-size: 18px !important;
    line-height: 20px !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.upload-btn {
  border-radius: 0px;
  width: 60px;
  height: 25px;
}

.nameTextFieldStyle {
  width: 270px;
}

.marginStyle {
  margin-inline-end: 25px;
}

@media (min-width: 1400px) {
  .nameTextFieldStyle {
    width: 275px;
  }

  .options_dropdown {
    width: 572px;
  }
}

.nameTextFieldStyle label {
  color: rgba(0, 0, 0, 0.6) !important;
}

.mcq-option {
  div {
    width: 100%;
  }

  fieldset {
    height: 45px;
    box-sizing: border-box;
    flex: none;
    margin: auto;
  }
  fieldset {
    width: 100%;
    border: none;
    border-bottom: 1px solid #00000033;
    border-radius: 0%;
  }
  input {
    @include en {
      padding-left: 6px;
    }

    @include ar {
      padding-right: 6px;
    }
  }

  svg {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    position: absolute;
  }
}
.mcq-option.mcq-option-other {
  padding: 0;
}
.socialMediaIcons {
  margin-top: 22px;
  margin-inline-end: 24px;
}

.imgStyle {
  margin-right: 5px;
}

.widthClass {
  margin-top: 8px !important;
}

.widthClass label {
  color: rgba(0, 0, 0, 0.38) !important;
  font-family: Roboto;
}

.dashedBorder {
  border: 1px dashed #a1aeab;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 0px 5px 10px 20px;
  margin-bottom: 5px;
  margin-top: 15px;
  img {
    width: 34px;
  }
}

.dashedTBorder {
  border: 1px dashed transparent;
  border-radius: 4px;
  margin-bottom: 5px;
  margin-top: 15px;
  img {
    width: 34px;
  }
}

.addBtn {
  font-weight: 500;
  font-size: 15px;
}

.crossStyle {
  top: -12px;
  position: absolute;

  @include en {
    right: -12px;
  }

  @include ar {
    left: -12px;
  }
}

.crossStyle path {
  opacity: 0.6;
  top: -12px;
  right: -12px;
  position: absolute;
}

.dropdownStyle .MuiPaper-elevation {
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.menuDotStyle {
  width: 34px;
  min-height: 28px;
  border-radius: 4px;
  text-align: center;
  padding-top: 3px;
  height: 28px;
}

.menuDotStyleActive {
  background-color: #80868c;
  color: #fff !important;
}

.country-dropdown {
  div {
    width: 240px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-sizing: border-box;
    border-radius: 4px;

    @include en {
      left: 18px;
    }

    @include ar {
      right: 18px;
    }
  }

  svg {
    margin: 5px;
  }
}

.logo-header {
  svg {
    position: relative;
    left: 10px;
    top: 3px;
    font-size: 18px;
    color: #64748b;

    @include ar {
      right: 5px;
      transform: scale(-1, 1);
    }
  }
}

.logo-dialog {
  img {
    width: 350px;
    height: 350px;
    left: 508px;
    top: 153px;
    border-radius: 4px;
  }

  svg {
    cursor: pointer;
  }
}

.min-chars {
  div {
    position: absolute;
    width: 240px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-sizing: border-box;
    border-radius: 4px;

    @include en {
      left: 18px;
    }

    @include ar {
      right: 18px;
    }
  }
}

.max-chars {
  div {
    position: absolute;
    width: 240px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-sizing: border-box;
    border-radius: 4px;

    @include en {
      left: 18px;
    }

    @include ar {
      right: 18px;
    }
  }
}

.max-chars-box {
  margin-bottom: 30px;
  margin-top: 10px;

  p {
    color: #f44336;
    font-size: 12px;
    position: relative;
    top: 25px;
    padding: 0px 22px;
  }
}

.publish-input-filed {
  width: 421px;

  div {
    height: 36px;
    @include en {
      padding-right: 0;
    }
  }
  .share-btn-copied {
    color: #3b873e;
    border: none;
    &:hover {
      border: none;
    }
  }
  input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    @include en {
      padding-right: 2px;
    }
  }
}

.copy-btn {
  top: -17px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  border-radius: 4px;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  position: relative;
  cursor: pointer;

  @include ar {
    right: 335px;
  }

  @include en {
    left: 355px;
  }

  svg {
    font-size: 14px;
    top: 3px;
    position: relative;
    color: rgba(0, 0, 0, 0.6);
  }
}

.publish-survey-modal {
  width: 600px;
  overflow: hidden;

  .tabcontext-parant {
    width: 100%;
    .tab-survey-item {
      padding: 11px 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: rgba(100, 116, 139, 1);
      width: max-content;
    }
    .Mui-selected {
      color: rgba(35, 55, 89, 1);
      text-align: center;
      font-weight: 600;
      border-bottom: 3px solid rgba(69, 110, 178, 1);
    }
    .share-left-side-stp {
      display: flex;
      justify-content: space-between;
    }
    .alert-box {
      padding: 13px 12px;
      margin-bottom: 24px;
      border-radius: 4px;
      border: 1px solid #d5f0fc;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0.9) 100%
        ),
        #2196f3;
      overflow: hidden;
      color: #00000099;
      font-size: 14px;
      font-weight: 400;
      line-height: 143%;
      letter-spacing: 0.15px;
      .exclamation-circle {
        overflow: hidden;
        color: #2196f3;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.15px;
        rotate: 180deg;
        margin-inline-end: 12px;
      }
    }

    .MuiTabPanel-root {
      padding: 0;
    }
    .MuiTabPanel-root.tap-content {
      padding: 24px 0px 0;
    }
  }
  .done-btn {
    position: relative !important;
  }
  #popup-cancelbtn-publish {
    &.MuiButton-root {
      background-color: #456eb2;
      color: #fff !important;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.share-via {
  margin: 30px 0px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 150%;
    color: #696d72 !important;
  }

  img {
    margin-right: 16px;
    width: 33.33px;
    height: 33.33px;
    margin-top: 10px;
  }
}

.scroll-bar-border {
  padding-bottom: 26px;
  position: absolute;
  right: 11px;
  min-height: calc(100vh - 170px);
}

.phone-preview {
  .react-tel-input {
    padding-left: 0px !important;
  }
}

.phone-number-question {
  margin: 20px 0px;

  @include ar {
    left: 105px;
    position: relative;
  }

  .phone-form {
    width: 560px;
  }

  .react-tel-input {
    width: 66%;
    padding-left: 10px;
    font-weight: 400;
    height: 40px;
    border-style: solid;
    border-color: #e5e5e5;
    box-shadow: none;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding-left: 88px;
    background-color: transparent;
  }

  .selected-flag {
    background-color: #fff !important;
    width: 75px !important;
    border-bottom: 1px solid;
    top: 2px;
  }

  .selected-flag,
  .react-tel-input:focus {
    border-color: #1e293b;
    outline: 0;
    background-color: transparent;
    position: relative;
  }

  .selected-dial-code {
    padding-left: 7px;
    font-size: 15px;
    color: #1e293b;
    pointer-events: none;
  }

  .selected-flag .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
  }

  .selected-flag .up,
  .selected-flag .down {
    position: relative;
    top: 0px;
    right: 2px;
    font-size: 0px !important;
  }

  .selected-flag .up {
    top: 5px !important;
  }

  .selected-flag .selected-dial-code {
    padding-left: 0px !important;
  }

  .selected-flag .iti-flag {
    top: 10px;
    position: relative;
    border-radius: 5px;
  }

  .selected-flag .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .selected-flag .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  @media (min-width: 280px) and (max-width: 729px) {
    .react-tel-input {
      width: 100%;
    }
  }

  .phoneInput {
    border: none !important;
    border-bottom: solid 1px #8c8b8b !important;
    padding-bottom: 12px;
    padding-top: 10px;
    outline: none !important;
    border-radius: 0px !important;
    left: 86px;
    top: 1px;
  }
}

.logo-box {
  width: 256px;
  height: 88px;
  position: relative;
  left: 0;
  top: 0;
  border: 0.7px dashed rgba(0, 0, 0, 0.42);
  box-sizing: border-box;
  border-radius: 4px;

  img {
    position: relative;
    width: 80px;
    height: 80px;
    left: 4px;
    top: 2px;
    border: 0.5px solid rgba(0, 0, 0, 0.23);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    display: block;
  }

  svg {
    position: relative;
    bottom: 24px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 20px !important;
    cursor: pointer;

    @include ar {
      left: auto;
      right: 57px;
    }

    @include en {
      left: 57px;
      right: auto;
    }
  }

  p {
    position: relative;
    width: 145px;
    top: -77%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    margin: 0px;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);

    @include ar {
      right: 92px;
    }

    @include en {
      left: 92px;
    }
  }

  .exit-logo-box {
    position: absolute;
    top: 5px;
    bottom: auto;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;

    @include ar {
      left: 5px;
      right: auto;
    }

    @include en {
      right: 5px;
      left: auto;
    }
  }
}

.qr-share {
  width: 100px;
  height: 120px;
  text-align: center;
  background: #fff;
}

.share-survey-container {
  display: flex;
  justify-content: space-between;
  .how-share-survey {
    padding: 24px;
  }
  .manage-domain-btn.successfully-msg {
    display: flex;
    align-items: center;
    justify-content: start;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    bottom: 30px;
    svg {
      color: #3b873e;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin-inline-end: 10px;
    }
  }
}

.share-left-side {
  width: 360px;
}
.share-survey-container {
  .how-share-survey {
    padding: 24px 0;
  }
}
.share-icons {
  margin-top: 10px;
  display: flex;
  gap: 15px;

  span {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;

    button.react-share__ShareButton {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      .main-icon {
        filter: grayscale(1);
        width: 16px;
      }
    }
  }
}

.close-share-btn {
  position: absolute;
  top: 20px;
  cursor: pointer;

  @include en {
    right: 30px;
  }

  @include ar {
    left: 30px;
  }
}

.dashed-line {
  width: 1px;
  height: 136px;
  border-right: 2px dashed rgba(0, 0, 0, 0.23);
  margin-top: 0px;

  @include en {
    margin-left: 45px;
  }

  @include ar {
    margin-right: 45px;
  }
}

.down-qr {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.share-type {
  display: block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
  font-weight: 400;
}
.social-share-icon {
  color: #5f6368;

  &:hover {
    background-color: #f1f5f9;
  }
}

.radio-item {
  display: flex;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item span.choose,
.radio-item span.choose.red,
.radio-item span.choose.yellow,
.radio-item span.choose.green {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #2497ca;
  border-radius: 50%;
  color: #2497ca;
  margin: 0 5px;
  cursor: pointer;
}
.radio-item span.choose:hover,
.radio-item span.choose.red:hover,
.radio-item span.choose.yellow:hover,
.radio-item span.choose.green:hover {
  border: 2px solid #2497ca;
  color: #2497ca;
  background-color: rgba(36, 151, 202, 0.08);
}

.radio-item span.choose.red {
  color: #f44336;
  border: 2px solid #f44336;
}
.radio-item span.choose.red:hover {
  color: #f44336;
  border: 2px solid #f44336;
  background-color: rgba(244, 67, 54, 0.08);
}
.radio-item span.choose.yellow {
  color: #ffb400;
  border: 2px solid #ffb400;
}
.radio-item span.choose.yellow:hover {
  color: #ffb400;
  border: 2px solid #ffb400;
  background-color: rgba(255, 180, 0, 0.08);
}
.radio-item span.choose.green {
  color: #4caf50;
  border: 2px solid #4caf50;
}
.radio-item span.choose.green:hover {
  color: #4caf50;
  border: 2px solid #4caf50;
  background-color: rgba(76, 175, 80, 0.08);
}

.radio-item input:checked + span.choose {
  background-color: #2497ca;
  color: #fff;
}

.radio-item input:checked + span.choose.red {
  background-color: #f44336;
  color: #fff;
}

.radio-item input:checked + span.choose.yellow {
  background-color: #ffb400;
  color: #fff;
}

.radio-item input:checked + span.choose.green {
  background-color: #4caf50;
  color: #fff;
}

span.choose.choose-cdp {
  background-color: #2497ca !important;
  color: #fff !important;
}
.question-head-cdp {
  margin-bottom: 32px;
}
.scaleBox {
  overflow-x: auto;
  padding: 15px 10px;
  margin-top: 10px;
  .scaleFormControl {
    min-width: 100% !important;
    justify-content: start;
    .scaleContainer {
      display: flex;
      justify-content: space-around;
      gap: 10px;
      .valuesText {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.most-like {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 5px 0;

  p {
    font-weight: 400;
    font-size: 13px;
    text-transform: capitalize;
    color: #0c192a;
  }
}

.valuesTitle {
  width: 100%;
  margin-bottom: 10px;
}

.valuesContainer {
  display: flex;
  align-items: center;
  width: 100%;
  .lowValue,
  .highValue {
    flex-grow: 1;
  }
}

.parent-settings {
  .constraints-text {
    div {
      .PrivateSwitchBase-root {
        cursor: default !important;
      }
    }

    &:first-child {
      div {
        color: rgb(0, 0, 0);
      }
    }
  }
}

.cardStyle.cardStyle-edit {
  .questionNoStyle {
    color: rgba(0, 0, 0, 0.6);
  }

  .yes-no-options {
    cursor: default;

    > div {
      color: rgba(0, 0, 0, 0.38);

      &:first-child {
        margin-inline-start: 9px;
      }
    }

    border-radius: 4px;
    width: 240px;
    margin-bottom: 14px;
    font-size: 16px;
    cursor: pointer;
    height: 36px;
  }

  .mcq-option {
    fieldset {
      border-color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  .unSelected {
    border-color: rgba(0, 0, 0, 0.38) !important;
  }

  .MuiInputBase-readOnly ~ .questionNoStyle {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .MuiInputBase-readOnly.css-1g2hdyi-MuiInputBase-root-MuiInput-root::before,
  .MuiInputBase-readOnly.css-1g2hdyi-MuiInputBase-root-MuiInput-root::after,
  .MuiInputBase-readOnly.css-1g2hdyi-MuiInputBase-root-MuiInput-root:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  }

  .MuiInputBase-readOnly {
    textarea {
      color: rgba(0, 0, 0, 0.6) !important;
    }

    .questionStylee {
      textarea {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }
  }
}

.cardStyle {
  .questionNoStyle {
    color: #456eb2 !important;
  }

  .addChoice {
    margin-bottom: 0 !important;
  }
}

.cardStyle {
  .color-num {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.disabled-color {
  color: rgba(0, 0, 0, 0.38) !important;
}

.radio-item {
  display: flex;
  justify-content: space-between;

  label {
    @include ar {
      padding: 0 !important;
    }
  }
}

.survey-footer {
  display: flex;
  justify-content: center;
  margin: 60px 340px 30px;
  color: #64748b;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

//custom domain styles
.publish-step {
  color: #00000099;
  font-size: 14px;
  font-weight: 400;
  cursor: default;
}
.domain-name {
  color: #334155;
  font-size: 16px;
  font-weight: 500;
}
.domain-desc {
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  display: flex;
  align-items: center;
  line-height: 20px;
  color: #00000099;

  a {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #456eb2;
    margin-inline-start: 3px;
    border-bottom: 1px solid #456eb2;
  }
}
.publish-domain-step-one {
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  .publish-step-btn {
    &:first-child {
      margin-bottom: 20px;
    }
    label.MuiFormControlLabel-labelPlacementEnd {
      display: flex;
      align-items: start;
      span {
        padding-top: 4px;
        padding-inline-start: 5px;
        span {
          svg {
            &:last-child {
              padding-inline-start: 5px;
            }
          }
        }
      }

      @include ar {
        margin-right: -11px;
      }
    }
  }
}
.how-share-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 15px;
}
.publish-grid-content {
  &.MuiButton-root {
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 15px 18px;
    width: 100%;
    &:hover {
      border-color: #e2e8f0;
      background: rgba(195, 225, 248, 0.08);
    }
    &.active {
      border: 1px solid #456eb2;
      box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.05);
    }
  }
  &.disabled-custom-domain {
    border: 1px dashed rgba(0, 0, 0, 0.14);
    .domain-desc,
    .domain-name {
      color: rgba(0, 0, 0, 0.35);
    }
  }
}
.puplish-tooltip {
  width: 10px;
}
.survey-buttons {
  margin-bottom: 24px;
  margin-inline: 24px;
  padding: 0 !important;
}
.manage-domain-btn {
  position: absolute;
  bottom: 40px;
  color: #456eb2;
  inset-inline-start: 24px;
  font-size: 14px;
  font-weight: 500;
}
.active-btn-aa {
  background-color: #000;
  border: 3px solid #000;
  color: white;
}
.share-survey-loading {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiCircularProgress-root {
    color: #cbcbcb;
  }
}
#survey-btn-done {
  &.MuiButton-root.popup-send-reques {
    margin: 0;
  }
  &.MuiButton-root {
    border-radius: 2px;
    background: transparent;
    border: none;
    margin-left: 0;

    &.MuiButton-root.puplish-btn {
      border: 1px solid #456eb2 !important;
      color: #3e63a0;
      padding: 6.5px 15px;
      margin-inline-end: 15px;
      &:hover {
        background: rgba(230, 243, 252, 0.5);
      }
    }
    &.Mui-disabled {
      opacity: 0.2;
    }
  }
}
#survey-btn-done.skip-btn {
  background-color: #456eb2;
  height: 42px;
  color: #fff !important;
  &.Mui-disabled {
    opacity: 0.2;
  }
}
#survey-btn-done.close-btn {
  max-width: 70px;
  background-color: #456eb2;
  height: 42px;
  color: #fff !important;
}
//~~~~Survey Panel~~~~~~~
.survey-panel-select-group {
  > div {
    display: grid;
    padding: 20px 0 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  margin-top: 10px;
  width: 100%;
  @include en {
    label {
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(8px, -4px) scale(0.75);
        background: #ffffff;
        padding: 3px;
        display: block;
      }
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
  }
  @include ar {
    label {
      transform: translate(60px, 16px) scale(1);
      min-width: 200px;
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(212px, -5px) scale(0.75);
        background: #ffffff;
        min-width: 60px;
        text-align: center;

        padding: 3px;
        display: block;
      }
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
  }
  .survey-input-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    top: -7px;
    letter-spacing: 0.15px;
  }
}
.MuiMenuItem-root.menu-item-panel {
  padding: 0 12px;
  .MuiTypography-root {
    padding-inline-start: 10px;
    flex-wrap: wrap;
    display: flex;
    max-width: 203px;
    overflow: hidden;
  }
}
.survey-panel-item {
  width: 268px;
}
.survey-panel-select {
  height: 40px;
  .MuiSelect-select.MuiSelect-outlined {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  div.MuiSelect-select.MuiSelect-outlined {
    padding: 0 12px;
    padding-inline-end: 25px;
    width: 100%;
    overflow: hidden;
  }
}
.share-survey-container.share-survey-parant {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  div.MuiFormGroup-root {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 15px;
  }
  .MuiFormControlLabel-labelPlacementEnd {
    margin: 0;
  }
  .switch-panel {
    .MuiSwitch-thumb {
      width: 16px;
      height: 16px;
    }
    .MuiSwitch-track {
      padding: 13.3px 16p;
    }
    .MuiButtonBase-root.MuiSwitch-switchBase {
      padding: 10.5px;
    }
  }
  .survey-Panel-switch {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.15px;
    margin-inline-end: 10px;
  }
  .input-label {
    color: red;
  }
  .MuiButtonBase-root.MuiMenuItem-root {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  span.paid {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 16px;
    background: #4caf50;
    display: flex;
    padding: 4px 8px;
    align-items: center;
    width: max-content;
    height: max-content;
  }
}
.circular-box {
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  span.MuiCircularProgress-root {
    width: 18px !important;
    height: 18px !important;
  }
}
@media (min-width: 280px) and (max-width: 729px) {
  .dropdownStyle {
    max-width: 100%;
  }
  .radio-item {
    flex-wrap: wrap;
    gap: 10px;
  }
  .most-like {
    max-width: 100%;
    margin-top: 10px;
  }
  .cardStyle {
    padding: 8px 4px;
  }
  .flag-container {
    display: flex;
    align-items: center;
  }
  .selected-flag {
    border: none;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.04) !important;
    border: 1px solid rgba(0, 0, 0, 0.04);
    background: #fff;
    padding: 3px 6px;
    height: 40px !important;
  }
  .phone-number-question {
    .selected-dial-code {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin: 0 2px;
    }
  }
  .phone-number-question {
    .phoneInput {
      padding: 3px 12px;

      border: none;
      border-radius: 4px;
      border-color: rgba(0, 0, 0, 0.04) !important;
      border: 1px solid rgba(0, 0, 0, 0.04) !important;
      width: calc(100% - 88px);
      padding: 3px 12px;
    }
  }
  .app-footer {
    > div {
      padding: 0 64px;
      width: 100%;
    }
  }
}
.dropdown-question-style {
  label {
    &.Mui-focused,
    &.MuiFormLabel-filled {
      top: 0 !important;
    }
  }
}
.luc-Button.back-to-survey-btn {
  border: 1px solid #456eb2;
  @include ar {
    svg {
      rotate: 180deg;
    }
  }
}
.luc-Button.next-to-survey-btn {
  @include en {
    svg {
      rotate: 180deg;
    }
  }
}
.proceed-choice.mcq-shoice {
  gap: 0;
  svg.svg-inline--fa {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }
  &:hover {
    svg.svg-inline--fa {
      visibility: visible;
      opacity: 1;
    }
  }
  .mcq-option {
    div {
      width: 100%;
      padding: 0;
    }
    fieldset {
      width: 100%;
      border: none;
      border-bottom: 1px solid #00000033;
      border-radius: 0%;
    }
    svg {
      inset-inline-end: 0;
    }
  }
  label.MuiFormControlLabel-root {
    margin: 0;
  }
}
.all-section-direction-rtl {
  direction: rtl;
}
.section-direction-ltr {
  direction: ltr;
  div.section-header,
  div.section-title {
    @include en {
      direction: ltr;
    }
    @include ar {
      direction: rtl;
    }
  }
  div.section-title {
    input {
      direction: ltr;
      padding-inline: 3px;
    }
  }
  .phone-number-question {
    .phoneInput {
      left: 86px;
    }
    .flag-container {
      direction: ltr;
    }
  }
}
.section-direction-rtl {
  direction: rtl;
  div.section-header,
  div.section-title {
    @include en {
      direction: ltr;
    }
    @include ar {
      direction: rtl;
    }
  }
  div.section-title {
    input {
      direction: rtl;
      padding-inline: 3px;
    }
  }
  .phone-number-question {
    .phoneInput {
      @include en {
        left: -86px;
      }
    }
    .flag-container {
      direction: rtl;
    }
  }
}
.all-section-direction-ltr {
  .Header_sectionName {
    direction: ltr;
  }
}
.all-section-direction-rtl {
  .Header_sectionName {
    direction: rtl;
  }
  @include en {
    .directionRtl {
      direction: ltr;
    }
  }
  @include ar {
    .directionRtl {
      direction: rtl;
    }
  }
}

.show-conditions-btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 0px 25px;
  margin-bottom: 60px;
  > img {
    @include ar {
      transform: scaleX(-1);
    }
  }

  .show-conditions-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    cursor: pointer;
    height: 22px;
    @include en {
      padding: 5px 5px 5px 10px;
    }
    @include ar {
      padding: 5px 10px 5px 5px;
    }
    > h6 {
      display: flex;
      align-items: center;
      height: 100%;
      width: 70%;
      font-weight: 400;
      font-size: 14px;
      color: #456eb2;
    }
    #clear-conditions-btn {
      display: none;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.6);
      &:hover {
        background: rgba(226, 232, 240, 1);
      }
    }
    &:hover {
      background: #f1f5f9;
      #clear-conditions-btn {
        display: flex;
      }
    }
  }
}

.error-msg-survey {
  &.MuiFormHelperText-root,
  .MuiTypography-root {
    color: #f44336;
  }
}
.survey-question-card {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  box-shadow: none;
  margin-top: 20px;
  padding: 30px;
  &.selected-question {
    border: 2px solid #456eb2;
  }
  &.error-question {
    border: 2px solid #e31b0c;
  }
}

.survey-question-free-text-input,
.survey-question-number-input,
.survey-question-email-input {
  width: 50%;
}

.survey-question-name-inputs-wrapper {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  .survey-question-name-input {
    flex-grow: 1;
  }
}

.survey-nps-scale-question {
  overflow-x: auto;
  .nps-scale-question-items-container {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nps-scale-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      color: #456eb2;
      border: 2px solid #456eb2;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background-color: rgba(69, 110, 178, 0.08);
      }
      &.red {
        color: #f44336;
        border: 2px solid #f44336;
        &.red:hover {
          color: #f44336;
          border: 2px solid #f44336;
          background-color: rgba(244, 67, 54, 0.08);
        }
      }
      &.yellow {
        color: #ffb400;
        border: 2px solid #ffb400;
        &.yellow:hover {
          color: #ffb400;
          border: 2px solid #ffb400;
          background-color: rgba(255, 180, 0, 0.08);
        }
      }
      &.green {
        color: #4caf50;
        border: 2px solid #4caf50;
        &.green:hover {
          color: #4caf50;
          border: 2px solid #4caf50;
          background-color: rgba(76, 175, 80, 0.08);
        }
      }
    }
  }
  .nps-scale-question-items-text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
}

.embedded-survey-popup {
  .survey-question-free-text-input,
  .survey-question-number-input,
  .survey-question-email-input,
  .survey-question-name-inputs-wrapper {
    width: 100%;
  }
}

.embedded-large-popup {
  .survey-question-card {
    margin-top: 0px;
  }
  .survey-question-card {
    padding: 20px;
  }

  .survey-nps-scale-question {
    .nps-scale-question-items-container .nps-scale-item {
      font-size: 18px;
      width: 36px;
      height: 36px;
    }

    .nps-scale-question-items-text-container p {
      font-size: 18px;
    }
  }
}

.embedded-medium-popup {
  .survey-question-card {
    margin-top: 0px;
  }
  .survey-question-card {
    padding: 16px;
  }

  .survey-nps-scale-question {
    .nps-scale-question-items-container .nps-scale-item {
      font-size: 16px;
      width: 32px;
      height: 32px;
    }

    .nps-scale-question-items-text-container p {
      font-size: 16px;
    }
  }
}

.embedded-small-popup {
  .survey-question-card {
    margin-top: 0px;
  }
  .survey-question-card {
    padding: 12px;
  }

  .survey-nps-scale-question {
    .nps-scale-question-items-container .nps-scale-item {
      font-size: 14px;
      width: 30px;
      height: 30px;
    }

    .nps-scale-question-items-text-container p {
      font-size: 14px;
    }
  }
}

.linked-survey-powered-by-logo {
  cursor: pointer;
}
