@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.reply-modal-popover {
  .MuiPopover-paper {
    border-radius: 8px;
  }
}

.reply-modal-social-listening {
  padding: 15px;
}

.reply-modal-channel-analytics {
  padding: 15px 0px;
}

.reply-modal-msg {
  padding: 15px 0px 0px 0px;
}
#empty-reply-accounts {
  max-width: 280px;
  font-size: 14px;
  text-wrap: wrap;
}
.reply-modal {
  #demo-simple-select {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 7px;
    @include ar {
      padding-right: 22px !important;
    }
    em {
      font-style: normal !important;
      color: hsl(0, 0%, 30%);
      font-size: 13px;
    }
  }
  .reply-modal-dropdown {
    margin-bottom: 4px;
    li {
      padding: 0px;
    }
    fieldset {
      border: none;
    }
    .MuiSelect-iconOutlined {
      top: -2px;
    }
  }
  .reply-modal-dropdown-icon {
    margin-bottom: 4px;
    display: flex;
    li {
      padding: 0px;
    }
    fieldset {
      border: none;
    }
    .MuiSelect-iconOutlined {
      top: 1px;
    }
    .MuiList-root {
      padding-top: 0px !important;
    }
    .MuiInputBase-root {
      bottom: 2px;
    }
  }
  .MuiSelect-iconOutlined {
    font-size: 30px;
    @include en {
      right: 2px !important;
    }
    @include ar {
      right: -6px !important;
    }
  }
}

.reply-modal-textarea {
  border-color: hsl(0, 0%, 23%);
  width: 320px !important;
  .MuiInputBase-root {
    border-radius: 6px;
    :hover {
      border-color: red;
    }
  }
  textarea {
    font-size: 12px !important;
    margin-top: -10px;
  }
}

.reply-modal-textarea-ca {
  border-color: hsl(0, 0%, 23%);
  width: 100% !important;
  .MuiInputBase-root {
    border-radius: 6px;
    :hover {
      border-color: red;
    }
  }
  textarea {
    font-size: 12px !important;
    margin-top: -10px;
  }
}

.modal-input-style {
  display: none;
}

.reply-modal-footer {
  margin-top: 15px;
  cursor: pointer;
}

.text-reached-limit {
  font-size: 12px !important;
  color: #f44336 !important;
  a {
    text-decoration: auto;
  }
  .MuiBreadcrumbs-separator {
    margin-left: 5px !important;
    margin-right: 5px !important;
    color: #f44336;
  }
}

.reply-modal-breadcrumbs {
  font-size: 12px !important;
  color: hsl(0, 0%, 60%);
  a {
    text-decoration: auto;
  }
  .MuiBreadcrumbs-separator {
    margin-left: 5px !important;
    margin-right: 5px !important;
    color: hsl(0, 0%, 60%);
  }
}

.disabled-icons {
  background-color: #fff;
  padding: 6px 10px;
  svg {
    color: #999999;
    font-size: 17px;
  }
}

.modal-icons {
  background-color: #fff;
  padding: 6px 10px;
  svg {
    color: #999999;
    font-size: 17px;
  }
}

.first-icon {
  background-color: #fff;
  padding: 6px 10px;
}

.first-icon:hover {
  background-color: #e6f3fc;
  padding: 6px 10px;
  border-radius: 2px;
  svg {
    path {
      fill: #456eb2 !important;
      fill-opacity: 1 !important;
    }
  }
}

.modal-icons:hover {
  background-color: #e6f3fc;
  padding: 6px 10px;
  border-radius: 2px;
  svg {
    color: #456eb2;
  }
}

.send-reply-btn {
  border-radius: 2px;
  padding: 6px 12px;
  background-color: rgb(203 203 203);
  color: #999999;
  @include en {
    margin-left: 8px;
  }
  @include ar {
    margin-right: 8px;
  }
}

.send-reply-btn-active {
  border-radius: 2px;
  padding: 6px 12px;
  background-color: #3e63a0;
  color: #fff;
  @include en {
    margin-left: 8px;
  }
  @include ar {
    margin-right: 8px;
  }
}

.loading-style {
  color: #999999;
  min-width: 320px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 25px !important;
    height: 25px !important;
  }
}

.loading-style-saved-replies {
  color: #999999;
  min-width: 290px;
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 25px !important;
    height: 25px !important;
  }
}

.replied-success-modal {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 320px;
  svg {
    color: #4caf50 !important;
    width: 20px;
    height: 20px;
  }
  span {
    font-size: 13px;
    font-weight: 600;
    color: #1e293b;
    line-height: 16px;
    letter-spacing: 1%;
    @include en {
      margin-left: 10px;
    }
    @include ar {
      margin-right: 10px;
    }
  }
  div {
    font-size: 12px;
    color: #64748b;
    line-height: 16px;
    letter-spacing: 1%;
    @include en {
      margin-left: 5px;
    }
    @include ar {
      margin-right: 5px;
    }
  }
}

.success-modal-divider {
  height: 1px;
  margin-bottom: 12px !important;
}

.success-modal-description {
  display: flex;
  align-items: center;
  margin-top: 5px;
  span {
    font-size: 12px;
    font-weight: 500;
    color: #475569;
    line-height: 16px;
  }
  div {
    font-size: 12px;
    color: #64748b;
    line-height: 14.4px;
    @include en {
      margin-left: 3px;
    }
    @include ar {
      margin-right: 3px;
    }
  }
}
.view-engagement-details {
  width: fit-content;
  color: #456eb2;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  font-size: 13px;
  margin-top: 5px;
}
.display-in-row {
  .view-engagement-details {
    margin: 0px 5px;
    margin-bottom: 10px;
  }
}
.replied-error-modal {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 320px;
  svg {
    color: #f44336 !important;
    width: 20px;
    height: 20px;
  }
  span {
    font-size: 13px;
    font-weight: 600;
    color: #1e293b;
    line-height: 16px;
    letter-spacing: 1%;
    @include en {
      margin-left: 10px;
    }
    @include ar {
      margin-right: 10px;
    }
  }
}

.error-modal-description {
  display: flex;
  align-items: center;
  margin-top: 5px;
  span {
    font-size: 12px;
    color: #64748b;
    line-height: 16px;
  }
  div {
    font-size: 12px;
    font-weight: 600;
    color: #456eb2;
    line-height: 14.4px;
    cursor: pointer;
    @include en {
      margin-left: 3px;
    }
    @include ar {
      margin-right: 3px;
    }
  }
}

.account-default-img {
  background-color: #e0e0e0;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  @include en {
    margin-right: 8px;
  }
  @include ar {
    margin-left: 8px;
  }
}

.default-img-content {
  color: rgba(0, 0, 0, 0.6);
  line-height: 14.4px;
  letter-spacing: 0.15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.account-avtar {
  min-width: inherit !important;
  img {
    max-width: 24px;
    max-height: 24px;
    border-radius: 3px;
  }
  .MuiAvatar-root {
    margin-top: -7px;
    margin-left: -6px;
    margin-bottom: -5px;
  }
}

.replied-error-modal-ca {
  width: fit-content;
  margin-bottom: 0px;
  span {
    font-size: 14px;
    @include en {
      margin-left: 5px;
    }
    @include ar {
      margin-right: 5px;
    }
  }
}
.error-modal-description-ca {
  margin-bottom: 6px;
  span {
    font-size: 13px;
  }
  @include en {
    margin-left: 9px;
  }
  @include ar {
    margin-right: 9px;
  }
}

.replied-success-modal-ca {
  width: fit-content;
  span {
    font-size: 14px;
    font-weight: 500;
  }
}

.success-modal-description-ca {
  margin-bottom: 15px;
  @include en {
    margin-left: 9px;
  }
  @include ar {
    margin-right: 9px;
  }
  span {
    font-size: 13px;
    font-weight: 500;
    color: #1e293b;
  }
  div {
    margin-top: 1px;
    font-size: 13px;
    font-weight: 500;
    color: #1e293b;
  }
}

.separator-style {
  margin-bottom: 12px;
  @include en {
    margin-left: 9px;
    margin-right: -5px;
  }
  @include ar {
    margin-right: 9px;
    margin-left: -5px;
  }
}

.success-modal-date {
  margin-bottom: 15px;
  @include en {
    margin-left: 9px;
  }
  @include ar {
    margin-right: 9px;
  }
  span {
    font-size: 13px;
    font-weight: 500;
    color: #1e293b;
  }
  div {
    margin-top: 1px;
    font-size: 12px;
  }
}

.saved-replied-btn {
  color: #456eb2;
  font-size: 11px;
  font-weight: 500;
  background-color: #e6f3fc;
  padding: 9px 12px;
  border-radius: 2px;
  cursor: pointer;
}

.saved-replied-divider {
  color: #cbd5e1;
  height: 21px !important;
  @include en {
    margin-left: 5px;
    margin-right: 4px;
  }
  @include ar {
    margin-right: 5px;
    margin-left: 4px;
  }
}

.saved-replies-popover {
  .MuiPopover-paper {
    border-radius: 4px;
    max-height: 360px;
  }
}

.saved-replies-modal {
  height: 260px;
  width: 250px;
  &.create-no-access {
    width: 265px;
  }
  @include en {
    padding: 20px 0px 20px 18px;
  }
  @include ar {
    padding: 20px 18px 20px 0px;
  }
  .MuiTextField-root {
    width: 230px !important;
    margin-bottom: 5px;
  }
  .MuiInputBase-root {
    height: 28px;
    input {
      font-size: 13px;
      padding: 0px 9px;
    }
    input::placeholder {
      font-size: 13px;
    }
  }
}

.saved-replies-modal-admin {
  height: 320px;
  width: 272px;
  position: relative;
  .MuiInputBase-root {
    width: 254px;
  }
}

.saved-replies-heading {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #282828;
  @include en {
    padding-right: 18px;
  }
  @include ar {
    padding-left: 18px;
  }
}

.saved-replies-content {
  font-size: 13px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 10px;
  color: #282828;
}

.saved-replies-delete-span:hover {
  background-color: rgba(230, 243, 252, 1);
  border-radius: 2px;
  svg {
    color: rgba(69, 110, 178, 1);
  }
}

.saved-replies-delete {
  color: rgba(0, 0, 0, 0.6);
  width: 22px !important;
  cursor: pointer;
  height: 20px !important;
}

.saved-replies-description {
  font-size: 12px;
  margin-bottom: 15px;
  color: #282828;
}

.display-in-start-saved-replies {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  cursor: pointer;
  div {
    width: 228px;
  }
  svg {
    margin-top: 4px;
  }
}

.create-replies-btn {
  text-align: center;
  color: #fff;
  background-color: rgba(69, 110, 178, 1);
  border-radius: 2px;
  margin-top: 15px;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.46px;
  cursor: pointer;
}

.create-replies-btn-disabled {
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin-top: 15px;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.46px;
}

.saved-replies-scroll {
  max-height: 200px;
  overflow: auto;
  @include en {
    padding-right: 18px;
  }
  @include ar {
    padding-left: 18px;
  }
}

.pr-18 {
  position: absolute;
  width: 87%;
  bottom: 20px;
  @include en {
    padding-right: 18px;
  }
  @include ar {
    padding-left: 18px;
  }
}

.delete-modal-content {
  color: rgba(0, 0, 0, 0.87);
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 15px;
  span {
    font-weight: 600;
  }
}

.create-replies-modal {
  padding: 20px 18px;
  .MuiTextField-root {
    width: 304px !important;
  }
  .MuiInputBase-root {
    height: 40px;
    input {
      font-size: 14px;
      padding: 0px 9px;
    }
    input::placeholder {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
  }
}

.create-saved-replies-textfield {
  input {
    color: rgba(244, 67, 54, 1);
  }
}

.create-replies-heading {
  font-size: 17px;
  font-weight: 500;
  line-height: 27.2px;
  letter-spacing: 0.15px;
  margin-bottom: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.create-replies-textfield-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
  margin-top: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.no-replies-found-description {
  font-size: 11px;
  line-height: 13.2px;
  letter-spacing: 0.15px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 1);
}

.no-result-found-container {
  width: 100%;
  height: 100%;
  max-height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @include en {
    margin-left: -15px;
  }
  @include ar {
    margin-right: -15px;
  }
}

.saved-replies-error {
  color: rgba(244, 67, 54, 1);
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 20px;
  letter-spacing: 0.4px;
  @include en {
    margin-left: 10px;
  }
  @include ar {
    margin-right: 10px;
    text-align: right;
  }
}

.unauthenticated-accounts-modal {
  font-size: 16px;
  line-height: 16.16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  svg {
    color: rgba(236, 134, 35, 1);
    font-size: 19px;
    @include en {
      margin-right: 5px;
    }
    @include ar {
      margin-left: 5px;
    }
  }
  span {
    margin-top: 2px;
  }
}

.companies-settings-style {
  font-size: 16px;
  font-weight: 600;
  color: #456eb2;
  line-height: 14.4px;
  cursor: pointer;
  margin-top: 3px;
  @include en {
    margin-left: 5px;
  }
  @include ar {
    margin-right: 5px;
  }
}

.unauthenticated-accounts-modal-heading {
  font-size: 12px;
  font-weight: 700;
  line-height: 16.16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  svg {
    color: rgba(236, 134, 35, 1);
    font-size: 16px;
    @include en {
      margin-right: 5px;
    }
    @include ar {
      margin-left: 5px;
    }
  }
  span {
    margin-top: 1px;
  }
}

.unauthenticated-accounts-modal-sl {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}

.companies-settings-style-sl {
  font-size: 12px;
  font-weight: 600;
  color: #456eb2;
  line-height: 14.4px;
  cursor: pointer;
  margin-left: 5px;
}

.max-width-auth {
  max-width: 320px;
  margin-top: 10px;
}

.remove-attachments {
  position: absolute;
  top: -6px;
  left: 30px;
  width: 15px !important;
  height: 15px !important;
  color: rgba(0, 0, 0, 0.6);
  display: none !important;
}

.attachments-container {
  position: relative;
  display: flex;
  width: 33px;
  height: 33px;
  border-radius: 2px;
  cursor: pointer;
  border-style: none solid solid solid;
  border-radius: 2px;
  border-color: transparent;
  @include en {
    margin-right: 3px;
  }
  @include ar {
    margin-left: 3px;
  }
  .react-thumbnail-generator {
    overflow: hidden !important;
    border: 1px solid #c0c0c0;
  }
}

.attachments-container:hover {
  .remove-attachments {
    display: block !important;
  }
  border-style: none solid solid solid;
  border-radius: 2px;
  border-color: rgba(0, 0, 0, 0.5);
}

.attachments-style {
  overflow: hidden;
  width: 33px;
  border: 1px solid #c0c0c0;
  img {
    object-fit: contain;
  }
}

.textfield-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.textfield-with-attachments {
  width: 100%;
  .MuiInputBase-root {
    padding-bottom: 55px;
  }
}

.attachments-with-textfield {
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 10px;
  display: flex;
  flex-wrap: wrap;
  @include ar {
    left: unset;
    right: 10px;
  }
}
////
.reply-modal-btn {
  &.MuiButton-root {
    width: 42px;
    height: 33px;
    min-width: unset;
    background-color: #3e63a0;
    color: #fff;
    @include en {
      margin-left: 8px;
    }
    @include ar {
      margin-right: 8px;
    }
    &:hover {
      background-color: #3e63a0;
    }
    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
    }
  }
}
