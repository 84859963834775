@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.edit-dashboard-header {
  margin-top: 30px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  button {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #80868c;
  }
  svg {
    font-size: 18px;
    @include ar {
      transform: scale(-1, 1);
    }
  }
  .divider-edit-info {
    margin-top: 15px;
    margin-bottom: -20px;
  }
}

.dasboard-edit-name-field {
  .MuiInputBase-root {
    height: 40px;
  }
}
.select-dashbord-type {
  width: 457px;
  height: 40px;
  @include ar {
    .MuiSelect-select {
      padding-right: 10px !important;
    }
  }
}

.dashboard-name-edit-eror {
  margin-bottom: -10px;
}

.dashboard-name-edit {
  @include en {
    label {
      top: -5px;
    }
  }

  @include ar {
    label {
      transform: translate(415px, 10px) scale(1);
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(415px, -11px) scale(0.75);
        background: #ffffff;
        padding: 3px;
      }
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
  }
}

.dash-type-input-field {
  @include en {
    label {
      background: #ffffff;
    }
  }
  @include ar {
    label {
      background: #ffffff;
      transform: translate(372px, -10px) scale(0.8);

      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(372px, -10px) scale(0.8);
        padding: 3px;
      }
    }
  }
}

.monitor-types-edit {
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 4px rgb(203 213 225 / 17%);
  padding: 18px 0px 60px 0px;
  .monitors-types-dashboard-edit {
    max-width: 2580px !important;
    display: flex;
    justify-content: space-between;
  }
  .monitor-types-container-edit {
    padding: 0px 20px !important;
    .dashboard-title-type-edit {
      position: relative;
      top: 10px;
    }
  }
  #dashboard-divider-info {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.dashboard-types-group-edit {
  position: relative;
  @include en {
    margin-right: 20px;
  }
  @include ar {
    margin-left: 45px;
  }
  .dash-monitor-edit-type {
    @include en {
      margin-right: 40px;
    }
    @include ar {
      margin-left: 10px;
    }
  }
}

.monitor-card-change-cursor {
  cursor: default !important;
}
.section-box-edit-dash .MuiSelect-select {
  font-size: 16px;
}
