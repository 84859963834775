.steppers-buttons {
  background-color: #fff;
}

.steppers-btn-wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  justify-content: space-between;
}

.stepper-cancel-btn {
  margin-inline-end: 20px !important;
}
