@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#settings-sidebar {
  position: relative;
  min-width: 250px;
  ::-webkit-scrollbar {
    width: 14px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f9f9f9;
    @include en {
      box-shadow: 1px 0px 0px 0px #dedede inset;
    }
    @include ar {
      box-shadow: -1px 0px 0px 0px #dedede inset;
    }
  }
  ::-webkit-scrollbar-thumb {
    background-color: #64748b;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 14px;
  }

  .settings-sidebar-content {
    position: fixed;
    min-width: 250px;
    top: 60px;
    height: calc(100% - 60px);
    overflow: auto;
    .settings-sidebar-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      padding: 20px 12px 0px;
    }
    .menu-items-group {
      .group-title {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        line-height: 22px;
        font-weight: 600;
        padding: 10px 20px 5px;
        text-transform: uppercase;
      }
      hr#group-divider {
        margin: 0px 16px;
        border-color: #e2e8f0 !important;
      }
      .group-items-wrapper {
        display: flex;
        flex-direction: column;
        > a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;
          padding: 8px 16px;
          margin-bottom: 4px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.87);
          &:hover {
            background: #f8fafc;
          }
          .menu-item-icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            border-radius: 50%;
            background: #f1f5f9;
          }
        }
        > a.active {
          background: #e6f3fc;
          color: #456eb2;
          font-weight: 600;
          padding-inline-start: 12px;
          @include en {
            border-left: 4px solid #456eb2;
          }
          @include ar {
            border-right: 4px solid #456eb2;
          }
          .menu-item-icon-wrapper {
            background: #ffffff;
            .menu-item-icon {
              color: #456eb2;
            }
          }
        }
      }
    }
  }
}
