@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#embedded-survey-edit-section-container {
  position: relative;
  background-image: url("/images/survey/website-skelton.svg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  flex-grow: 1;
  margin: 20px 30px 0px 30px;
}

.embedded-edit-section-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.embedded-edit-section-content {
  height: inherit;
}

#survey-embedded-feedback-button-wrapper {
  position: absolute;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  button#survey-embedded-feedback-button {
    font-family: var(--embedded-button-font) !important;
    &.small {
      font-size: 12px !important;
    }
    &.medium {
      font-size: 14px !important;
    }
    &.large {
      font-size: 16px !important;
    }
  }

  &.top-right {
    top: 0;
    right: 0;
    transform: translateY(0%) rotate(90deg) translateX(100%);
    transform-origin: top right;
    button#survey-embedded-feedback-button {
      transform: rotate(180deg);
    }
  }
  &.middle-right {
    top: 50%;
    right: 0;
    transform: translateY(-150%) rotate(-90deg) translateX(50%);
    transform-origin: bottom right;
  }
  &.bottom-right {
    top: 100%;
    bottom: 0;
    right: 0;
    transform: translateY(200%) rotate(90deg) translateX(0%);
    transform-origin: bottom right;
    button#survey-embedded-feedback-button {
      transform: rotate(180deg);
    }
  }
  &.top-left {
    top: 0;
    left: 0;
    transform: translateY(-100%) rotate(90deg) translateX(0%);
    transform-origin: bottom left;
    button#survey-embedded-feedback-button {
      transform: rotate(180deg);
    }
  }
  &.middle-left {
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(-90deg) translateX(-50%);
    transform-origin: top left;
  }
  &.bottom-left {
    top: 100%;
    bottom: 0;
    left: 0;
    transform: translateY(-100%) rotate(-450deg) translateX(0%);
    transform-origin: top left;
  }

  &.top-right,
  &.middle-right,
  &.bottom-right {
    button#survey-embedded-feedback-button.rounded {
      border-radius: 8px 8px 0 0 !important;
    }
  }
  &.top-left,
  &.middle-left,
  &.bottom-left {
    button#survey-embedded-feedback-button.rounded {
      border-radius: 0 0 8px 8px !important;
    }
  }
}
