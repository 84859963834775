@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.main-steps-header {
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 200px;
  .main-sutep-comp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .main-steps-line {
      width: calc(100% / 2 - 240px);
      &.two-steps {
        width: calc(100% - 273px);
      }
      margin: 0 5px;
      @include ar {
        width: calc(100% / 2 - 180px);
        &.two-steps {
          width: calc(100% - 230px);
        }
      }
    }
    .main-steps-continer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .main-step {
        display: flex;
        align-items: center;
        gap: 6px;
        min-width: max-content;
        .main-step-icon {
          background-color: #9e9e9e;
          border-radius: 50px;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            color: #fff;
            height: 16px;
            width: 21px;
          }
          .step-num {
            color: #fff;
            font-size: 12px;
          }
        }
        .step-name {
          color: #00000099;
          font-size: 14px;
          font-weight: 400;
          margin-top: -1px;
        }
        &.active-step {
          .step-name {
            color: #000000de;
            font-weight: 500;
          }
          .main-step-icon {
            background-color: #456eb2;
          }
        }
        &.done-step {
          .main-step-icon {
            background-color: #4caf50;
          }
        }
      }
    }
  }
}
