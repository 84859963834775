@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.engagement-analytics-banner {
  &-outer {
    .MuiDialog-paperWidthSm.MuiPaper-root.MuiPaper-elevation {
      top: 28px;
      max-width: 650px;
      max-height: calc(100vh - 78px);
    }
    .MuiDialogContent-root {
      padding: 0;
    }
    #alert-dialog-title {
      display: none;
    }
  }
  &-body {
    width: 650px;
    box-sizing: border-box;
    overflow: hidden;
    &-cover {
      position: relative;
      background: linear-gradient(90deg, #1a174f 0%, #456eb2 100%);
      height: 239px;
      border-radius: 4px 4px 0 0;
      overflow: hidden;
      border-bottom: 1px solid #0000003b;
      box-sizing: border-box;
      &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #1a174f 0%, #456eb2 100%);
        z-index: 1;
        opacity: 0.8;
      }
      &-close {
        position: absolute;
        top: 20px;
        left: 616px;
        cursor: pointer;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        &:hover {
          background-color: #0000000a;
        }
        svg {
          font-size: 20px;
          color: #ffffff;
        }
      }
      svg {
        width: 650px;
        overflow: hidden;
      }
    }
    &-content {
      padding: 30px 30px 40px 30px;
      display: flex;
      justify-content: space-between;
      .engagement-analytics-banner-title {
        font-size: 22px;
        font-weight: 800;
        line-height: 26.4px;
        color: #000000de;
        width: 151px;
      }
      .engagement-analytics-banner-list {
        display: flex;
        gap: 20px;
        flex-direction: column;
        width: 335px;
        &-header {
          font-size: 14px;
          font-weight: 600;
          line-height: 16.8px;
          color: #000000de;
        }
        &-items {
          @include en {
            padding-left: 20px;
          }
          @include ar {
            padding-right: 20px;
          }
        }

        &-item {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          color: #000000de;
          &::before {
            content: "•";
            color: #000000de;
            display: inline-block;
            width: 16px;
            @include en {
              margin-left: -1em;
            }
            @include ar {
              margin-right: -1em;
            }
          }
        }
      }
    }
  }
}
