@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.main-setup-countainer {
  .setup-title {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 38px !important;
    letter-spacing: 0.15000000596046448px !important;
    text-align: center !important;
    margin-top: 20px !important;
    margin-bottom: 5px !important;
  }
  #setup-pragraph {
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0.15000000596046448px !important;
    text-align: center !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }
  .setup-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46000000834465027px;
    text-align: center !important;
    color: #fff;
    padding: 7px;
    border-radius: 2px;
    background-color: #456eb2;
    margin-top: 20px;
    &.Mui-disabled {
      background-color: rgb(224 224 224);
      color: rgb(179 179 179);
    }
    &:hover {
      background-color: #456eb2;
    }
  }
  .btns-countainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .skip-btn {
      color: #456eb2;
      background-color: #fff;
      border: 1px solid #456eb2;
      &:hover {
        background-color: #e6f3fc80;
      }
    }
  }
  .select-setup {
    .select-title {
      margin-top: 0 !important;
      margin-bottom: 30px !important;
      text-align: start !important;
    }
    .select-pragraph {
      text-align: start !important;
    }
    .select-btn-countainer {
      display: flex;
      flex-direction: row-reverse;
    }
    .select-menu {
      width: 100%;
      margin: 10px 0;
      #demo-multiple-checkbox-label {
        font-size: 14px;
        &[data-shrink="true"] {
          @include ar {
            transform: translate(138px, -8px) scale(0.75);
          }
        }
        @include ar {
          right: 20px;
        }
      }
      .select-input {
        height: 50px;
      }
      fieldset {
        @include ar {
          text-align: right;
        }
      }

      legend {
        font-size: 86px;
        @include ar {
          font-size: 50px;
        }
      }
    }
  }
  .welcome-setup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 485px;
    height: 375px;
    @include ar {
      height: 350px;
    }
  }
}
.menu-item-header {
  margin-inline-start: 10px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  letter-spacing: 0.15000000596046448px;
  margin-top: 5px !important;
}
.engagement-menu-item-countainer {
  display: flex;
  align-items: center;
  margin: -10px 0;
  span {
    font-size: 14px;
    font-weight: 600;
  }
  .MuiCheckbox-root {
    padding: 7px 0;
    margin-inline-end: 15px;
  }
  .item-details {
    margin: 0 18px;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 14px;
    color: #00000099;
    gap: 5px;
    .item-details-icons {
      display: flex;
      align-items: center;
      gap: 2px;
    }
    .monitor-state {
      text-transform: capitalize;
      &.paused {
        color: #696d72;
      }
      &.collecting {
        color: #4caf50;
      }
    }
  }
}
