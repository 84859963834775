@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#embedded-survey-publish-modal-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .embedded-survey-publish-modal-title-text {
    font-weight: 500;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
  }

  .embedded-survey-publish-modal-title-steps-count {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
  }
}

div.embedded-publish-modal-body {
  padding: 0;
}

.add-domain-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 0 24px 20px;
  .add-domain-description {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
  }
  .add-domain-form-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    .add-domain-input-label {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      > .required {
        color: #e31b0c;
      }
    }
    #survey-builder-embedded-domain-name-field-helper-text {
      color: #e31b0c;
    }
  }
}

.select-domain-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

  .select-domain-saved-domains-subtitle,
  #select-domain-no-domains-desscription {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    margin-inline: 24px;
  }

  #select-domain-no-domains-desscription {
    margin-top: 10px;
  }

  .select-domain-list {
    width: 100%;
    max-height: 245px;
    overflow: auto;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    @include en {
      padding: 0 0 10px 24px;
    }
    @include ar {
      padding: 0 24px 10px 0;
    }

    &::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      @include en {
        box-shadow: 1px 0px 0px 0px #0000001f inset;
      }
      @include ar {
        box-shadow: -1px 0px 0px 0px #0000001f inset;
      }
    }
    &::-webkit-scrollbar-thumb {
      background-color: #a8a8a8;
      background-clip: padding-box;
      border: 4px solid transparent;
      border-radius: 14px;
    }

    .select-domain-list-group {
      width: 100%;
      .select-domain-list-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        width: 100%;
        padding-inline: 10px;
        box-sizing: border-box;

        .select-domain-list-item-label-count-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;

          .select-domain-list-item-label {
            overflow: hidden;
            @include ar {
              margin-inline: 0 10px;
            }
            > span.MuiFormControlLabel-label {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: pre;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.87);
            }
          }

          .select-domain-list-item-label-count-wrapper {
            width: max-content;
            box-sizing: border-box;
            padding: 0;
            margin: 0;
          }
          .select-domain-list-item-label-count {
            width: inherit;
            background: #f1f5f9;
            color: rgba(0, 0, 0, 0.6);
            font-weight: 400;
            font-size: 12px;
            border-radius: 4px;
            padding: 2px 8px;
          }
        }

        .select-domain-list-item-btns-container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 5px;
          .verified-domain-badge,
          .unverfied-domain-badge {
            font-weight: 500;
            font-size: 12px;
            svg {
              font-size: 12px;
              @include ar {
                margin: 0;
                padding-inline-start: 5px;
              }
            }
          }
          .verified-domain-badge {
            background: #edf7ed;
            color: #3b873e;
            border: 1px solid #3b873e;
            svg {
              color: #3b873e;
            }
          }
          .unverfied-domain-badge {
            background: #fdf0e5;
            color: #9e5f00;
            border: 1px solid #9e5f00;
            svg {
              color: #9e5f00;
            }
          }
          button#embedded-survey-delete-domain-btn svg {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }

  .select-domain-add-domain-btn-container {
    padding-inline: 24px;
  }
}

.embedded-publish-select-display-scope-body {
  max-height: 370px;
  margin-top: 8px;
  padding: 0 24px;

  .select-display-scope-subtitle {
    font-weight: 600;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 8px;
  }

  .selecting-group-wrapper {
    margin-top: 15px;
    margin-bottom: 25px;

    .select-display-scope-group {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 20px;

      .display-scope-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
        flex-grow: 1;
        border: 1px solid rgba(203, 213, 225, 1);
        border-radius: 8px;
        padding: 16px;
        box-sizing: border-box;
        cursor: pointer;

        &.selected {
          border: 2px solid #456eb2;
        }

        .display-scope-item-header {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .display-scope-item-icon-wrapper {
            width: 46px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f8f8f9;
            border-radius: 8px;
            svg {
              width: 20px;
            }
          }
        }

        .display-scope-item-text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 4px;
          .display-scope-item-title {
            font-weight: 500;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.87);
          }
          .display-scope-item-description {
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }

  .select-display-scope-specific-pages-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 12px;

    .select-display-scope-specific-pages-title {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }

    .specific-pages-input-btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 15px;
      .specific-pages-input-field-wrapper {
        width: 100%;
        .specific-pages-input-field {
          padding: 0px 5px;
          @include ar {
            direction: ltr;
          }
        }
        #specific-pages-input-field {
          flex-grow: 1;
          height: 36px;
          box-sizing: border-box;
        }
        #specific-pages-input-field-start-adornment {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: auto;
          .selected-domain-wrapper {
            background-color: #eaeaea;
            padding: 2px 4px;
            border-radius: 4px;
            margin-inline-end: 5px;
            > p {
              font-weight: 400;
              font-size: 14px;
              color: #696969;
            }
          }
          #selected-domain-static-slash {
            font-size: 16px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.87);
          }
        }
        p#specific-pages-input-field-error {
          color: #e31b0c;
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }

    .select-display-scope-specific-pages-chips-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;

      .select-display-scope-specific-pages-chip {
        background-color: #eaeaea;
        color: rgba(105, 105, 105);
        @include ar {
          direction: ltr;
        }

        #specific-pages-chip-label-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 6px;

          #specific-pages-chip-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #696969;
            font-size: 14px;
            font-weight: 400;

            #specific-page-path {
              color: rgba(0, 0, 0, 0.87);
            }
          }
        }

        .specific-pages-chip-delete-icon {
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
          cursor: pointer;
        }
      }
    }
  }
}

.embedded-publish-verify-domain-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0 24px;
  margin-bottom: 8px;

  .verify-domain-title-desc-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    .verify-domain-step-title {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }
    .verify-domain-step-desc {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .verifying-domain-step-one {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }

  .embedded-publish-verify-domain-code-block {
    background-color: #0f172a;
    border-radius: 4px;
    padding: 16px;

    #embedded-publish-verify-domain-script-code {
      color: #ffffff;
      font-weight: 400;
      font-size: 14px;
      word-break: break-word;
      white-space: pre-wrap;
      margin: 0;
      @include ar {
        direction: ltr;
      }
    }

    .copy-code-btn-wrapper {
      margin-top: 16px;
      .copy-code-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        background-color: #ffffff;
        color: #1a174f;
        padding: 5px 12px;
        font-size: 13px;
        font-weight: 500;
        &.copied-code {
          background-color: #3b873e;
          color: #ffffff;
        }
      }
    }
  }

  .verifying-domain-step-two {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    .embedded-publish-verify-domain-input-btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 15px;

      .embedded-publish-verify-domain-input-wrapper {
        flex-grow: 1;
        .embedded-publish-verify-domain-input {
          height: 36px;
          font-weight: 400;
          font-size: 14px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: rgba(0, 0, 0, 0.6);
          box-sizing: border-box;
          padding-inline: 12px;
          border: 1px dashed rgba(0, 0, 0, 0.38);
          background: #f8fafc;
        }
        #embedded-publish-verify-domain-input-helper-text {
          margin: 5px 12px;
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.87);
        }
      }

      button#embedded-publish-verify-domain-verify-btn {
        width: max-content;
        &.embedded-publish-verify-domain-verified-btn {
          border-color: #3b873e;
          color: #3b873e;
        }
      }

      &.verify-domain-error {
        .embedded-publish-verify-domain-input {
          border: 1px dashed #f44336;
          background: #feeceb;
          color: #ab2f26;
        }
        #embedded-publish-verify-domain-input-helper-text {
          color: #ab2f26;
        }
      }

      &.verified-domain {
        .embedded-publish-verify-domain-input {
          border: 1px dashed #3b873e;
          background: #edf7ed;
          color: #3b873e;
        }
        button#embedded-publish-verify-domain-verify-btn {
          background: #edf7ed;
          color: #3b873e;
          border-color: #3b873e;
          opacity: 1;
        }
      }
    }
  }
}

#embedded-survey-active-surveys-tooltip-list {
  margin: 0;
  @include en {
    padding: 4px 6px 4px 18px;
  }
  @include ar {
    padding: 4px 18px 4px 6px;
  }

  li {
    font-weight: 500;
    font-size: 12px;
  }
}
