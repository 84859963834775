@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#reports-list-filters-swipeable-container {
  z-index: 0;
  .MuiPaper-root {
    top: 60px;
    height: calc(100% - 60px);
    width: 376px;
  }

  .reports-filters-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0 27px;
    background: #ffffff;
    filter: drop-shadow(0px 0px 40px rgba(11, 24, 42, 0.071));
    z-index: 1;
    .reports-list-filter-swipeable-header-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p,
      svg {
        font-size: 15px;
        line-height: 15px;
        color: #456eb2;
        margin: 0;
        margin-left: 5px;
      }
    }
  }

  .reports-filters-swipeable-content-container {
    height: calc(100vh - 210px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    overflow: auto;
    padding: 30px 27px;
    box-sizing: border-box;

    .reports-list-page-filter-select-input {
      font-size: 14px;
    }
    .reports-list-page-filter-select-input-label {
      color: #525252;
      font-size: 12px;
      font-weight: 400;
      line-height: 13px;
      margin-bottom: 5px;
      @include ar {
        font-family: Dubai;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .reports-list-filters-swipeable-footer-container {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 0 27px;
    background: #ffffff;
    filter: drop-shadow(-5px -5px 40px rgba(11, 24, 42, 0.071));
    z-index: 1;
    right: 0;
    left: 0;
  }
}
