@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.alert-title {
  font-weight: 500;
}
.alerts-countainer {
  position: relative;
}
.main-header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    position: absolute;
    top: 0px;
    right: 0px;
    @include ar {
      right: unset;
      left: 0;
    } 
  }
  
}
