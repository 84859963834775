@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.ca-head {
  &.MuiTypography-root {
    color: #a4a9af;
    font-size: 14px;
    font-weight: 500;
    margin: 40px 0px 24px;
  }
}
