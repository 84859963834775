@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.engagements-wrapper {
  display: flex;
  overflow: hidden;
  height: calc(100vh - 120px);
  margin-top: 60px;
  &.engagements-wrapper-reduced-height-1 {
    height: calc(100vh - 168px);
  }
  &.engagements-wrapper-reduced-height-2 {
    height: calc(100vh - 216px);
  }
  &.engagements-wrapper-reduced-height-3 {
    height: calc(100vh - 264px);
  }
  .engagements-sideBar-main-container {
    display: flex;
    .engagements-sideBar-wrapper {
      min-width: 336px;
      max-width: 336px;
      background-color: #ffffff;
      border-inline-end: 1px solid #e2e8f0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
      height: 100%;

      .smart-sorting-popup {
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        position: absolute;
        padding: 1rem;
        width: 280px;
        left: 450px;
        top: 30%;
        border-radius: 4px;

        .smart-sorting-title {
          margin-block-end: 1rem;
          font-weight: bold;
          font-size: 1.25rem;
        }

        .smart-sorting-contant {
          margin-block-end: 1rem;
        }

        .smart-sort-btn {
          display: block;
          margin-left: auto;
          @include ar {
            margin-left: 0;
            margin-right: auto;
          }
        }

        @include ar {
          right: 450px;
        }
        &::before {
          content: "";
          position: absolute;
          width: 50px;
          height: 2px;
          left: -50px;
          top: 30%;
          background: rgba(108, 108, 108, 1);
          @include ar {
            right: -50px;
          }
        }
      }
    }
  }

  .engagements-details-wrapper {
    flex-grow: 1;
    min-width: calc(100% - 515px);
    max-width: calc(100% - 358px);
    .engagements-details-box {
      height: 100%;
      display: flex;
      width: 100%;
    }
    .details-box {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex-grow: 1;
      width: 100%;
    }
    .open-details-box {
      max-width: calc(100% - 291px);
      .main-contant {
        overflow-x: hidden;
        overflow-y: auto;
        padding-inline-end: 0;
      }
      .cards-footer {
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }
}
.main-posts-footer {
  padding-inline-start: 20px;
  padding-inline-end: 15px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.1);
  &.bordered-reply {
    border-color: #475569;
    box-shadow: 0px 20px 56px 0px #0000001a;
  }
  &.no-reply-footer {
    height: auto;
    padding: 0;
    background-color: #fafafa;
    .main-no-reply {
      background-color: #fff;
      padding: 20px;
      a {
        display: inline-flex;
        align-items: center;
        gap: 5px;
      }
    }
    p {
      padding-top: 6px;
      font-size: 12px;
      color: #00000099;
      padding-inline-start: 40px;
    }
    .main-sent-reply {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 20px;
      svg {
        color: #3b873e;
        font-size: 18px;
      }
    }
  }
  &.chat {
    background-color: #fff;
    svg {
      color: #ed6c02 !important;
    }
  }
}

.publish-cont > .MuiBox-root {
  background-color: #fff;
}
.main-setup-loader {
  margin: 20px;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: #fff;
  border-radius: 1px;
  color: #1a174f;
  font-size: 18px;
  font-weight: 600;
  svg {
    width: 33px !important;
    height: 33px;
    color: #456eb2;
  }
  .main-setup-praf {
    width: 300px;
    text-align: center;
    color: #64748b;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    @include ar {
      width: 200px;
    }
  }
}

.translation-button {
  padding: 0px 10px 0px 10px;
  border-radius: 2px;
  display: inline-block;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  color: #456eb2;
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    background-color: rgba(230, 243, 252, 0.5);
  }
}
.card-translation-box {
  margin-top: 8px;
  padding: 12px;
  border-radius: 5px;
  border-inline-start: 4px solid #cbd5e1;
  background-color: #f8fafc;
  line-height: normal;
  .collapse-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .translation-loading {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }
  .translation-lang {
    color: rgba(0, 0, 0, 0.38);
    font-size: 10px;
    font-weight: 500;
    margin: 0;
    padding: 4px;
    border-radius: 2px;
    background: rgba(230, 243, 252, 0.8);
    span {
      color: #456eb2;
    }
  }
  .translation-text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }
  .arrow-icon-translation {
    cursor: pointer;
    font-size: 10px;
    color: #424242;
  }
  .MuiCollapse-wrapperInner.MuiCollapse-vertical {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.profile-no-data {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.soon-engagment-msg {
  padding: 0px 6px;
  background-color: #c77700;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 16px;
}
.engagement-step-switch-bool {
  .MuiTypography-root {
    color: #00000061 !important;
  }
}

a.engagement-chat-injected-link-item {
  font-weight: 500;
  color: #1b76cf;
  &:hover {
    text-decoration: underline;
  }
}

.engagement-chat-message-media-video-container {
  position: relative;
  .play-button-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    cursor: pointer;
    &:hover {
      .play-button {
        background: #00000099;
      }
    }
  }
  .play-button {
    width: 40px;
    height: 40px;
    background: #00000073;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: white;
    }
  }
}
.engagement-chat-message-media-video,
.engagement-chat-message-media-image {
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
}
.engagement-chat-meida-image,
.engagement-chat-meida-video {
  cursor: pointer;
}
.ai-agent-alert-wrapper {
  margin-top: 60px;
  margin-bottom: -60px;
}
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-2.5px);
    transform: translateY(-2.5px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.animated-number-bounce {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}
