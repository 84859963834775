@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.add-monitor-info {
  display: flex;
  justify-content: space-between;
  div {
    display: grid;
    margin-right: 20px;
    width: 475px;
  }
  fieldset {
    height: 40px;
  }
}
.social-media-fields {
  direction: ltr;
  @include ar {
    direction: rtl;
  }
  input {
    &.MuiOutlinedInput-input {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0f172a;
    @include ar {
      direction: rtl;
    }
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 10px;
  }
  .social-media-field {
    padding: 0px;
    position: static;
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    margin-top: 11px;
    &.Mui-focused {
      border: 2px solid #456eb2;
    }
    &.Mui-error {
      border: 2px solid #e31b0c;
    }
  }
  .language-dropdown {
    width: 97%;
    height: 38px;
    margin: 6px 0px;
    top: -2px;
  }
  .social-media-field-grid {
    margin-top: 10px;
    @include ar {
      direction: rtl;
    }
    .required {
      color: #e31b0c;
      margin-inline-start: 2px;
    }
  }
  .optional-create-text {
    color: #80868c;
    padding: 0px 5px;
    text-transform: lowercase;
  }
}
.social-media-tabs {
  border-bottom: 1px solid #cbd5e1;
  margin: 10px 0px 14.5px 0px;
  min-height: 0px !important;

  .twitter_tab {
    background: #f8fafc;
    width: 135px;
    height: 36px;
    color: #000000;
    &.selected {
      color: #fff;
      background: #000000;
      svg {
        color: #f8fafc;
      }
      img {
        filter: brightness(0) invert(1);
      }
    }
    svg {
      color: #1da1f2;
    }
  }
  .facebook_tab {
    background: #f8fafc;
    color: #000000;
    width: 135px;
    height: 36px;
    &:hover {
      color: #3b5998;
    }

    &.Mui-selected {
      color: #fff;
      background: #3b5998;
    }
    svg {
      color: #3b5998;
    }
    &.selected {
      color: #ffffff;
      background: #3b5998;
      svg {
        color: #ffffff;
      }
    }
  }

  .instagram_tab {
    background: #f8fafc;
    color: #000000;
    width: 135px;
    height: 36px;
    &:hover {
      color: #e4405f;
    }
    svg {
      color: #e4405f;
    }
    &.Mui-selected {
      color: #fff;
      background: #e4405f;
    }
    &.selected {
      color: #ffffff;
      background: #e1306c;
      svg {
        color: #ffffff;
      }
    }
  }
}

.add-source-txt {
  font-style: italic;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 15px;
  color: #64748b;
  margin: 14px 0px !important;
}
.account-card {
  width: auto;
  height: 64px;
  padding: 0px !important;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  cursor: pointer;
  span {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    color: #475569;
    @include ar {
      text-align: initial;
    }
  }
  &.selected {
    border: 2px solid #456eb2 !important;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 11%);
  }
}

.disable-monitor-type {
  pointer-events: none;
  opacity: 0.5;
}

// new STYLES for Refactor ------------
.monitor-error-name {
  &.MuiBox-root {
    color: #f44336;
  }
  margin-top: 5px;
}
.social-connect-head {
  margin: 25px 0px 15px 0px !important;
}
.monitor-page-name {
  display: block;
}
.tw-spam-keyword-icon {
  color: #bdbdbd;
  font-size: 15px !important;
  margin: 0px 5px !important;
  position: relative;
  top: 2px;
}
.media-input-label {
  border-bottom: 1px dashed rgba(100, 116, 139, 0.5);
  font-size: 14px;
  line-height: 16.8px;
  @include en {
    padding-bottom: 3px;
  }
}
.add-btn-with-plus {
  &.MuiButton-root {
    font-size: 14px;
    font-weight: 500;
    color: #456eb2;
    border-radius: 0;
    line-height: 2 !important;
    svg {
      font-size: 10px;
      padding: 0px 5px;
    }
    @include en {
      border-left: 2px solid #456eb2;
      margin-left: 10px;
    }
    @include ar {
      border-right: 2px solid #456eb2;
    }
  }
}
.spam-x-platform-public {
  .add-btn-with-plus {
    &.MuiButton-root {
      line-height: 1.85 !important;
    }
  }
}

.keyword-input-field {
  &.Mui-error {
    .add-btn-with-plus {
      color: #f44336;
      @include en {
        border-left: 2px solid #f44336;
      }
      @include ar {
        border-right: 2px solid #f44336;
      }
    }
  }
}

.choose-account-managed {
  font-size: 13px;
  color: #232b34;
  font-weight: 400;
}
.account-avatar-managed {
  padding: 12px 16px;
}

.optional-field {
  display: "block";
}

.keywords-inputs-fields {
  .keyword-input-field {
    @include ar {
      height: 40px;
      .MuiOutlinedInput-input {
        padding: 10px;
      }
    }
    @include ar {
      border-radius: 0px 4px 4px 0px;
    }
  }
}

.lang-direction-ar {
  .MuiOutlinedInput-input {
    direction: rtl !important;
  }
}
.lang-direction-en {
  .MuiOutlinedInput-input {
    direction: ltr !important;
  }
}

.keyword-input-field,
.social-media-field {
  input {
    &::placeholder {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.input-msg-error-contaier {
  margin-bottom: 0px;
}
.shared-customer-care-container {
  margin-bottom: 30px;
  h3 {
    color: #0f172a;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding-bottom: 3px;
    width: fit-content;
    border-bottom: 1px dashed rgba(100, 116, 139, 0.5);
    .tooltip-btn {
      display: flex;
    }
  }

  .customer-care-input {
    width: 100%;
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-color: rgba(0, 0, 0, 0.23);
      height: 40px;
      border-width: 1px;
    }
    p {
      text-align: start;
      width: 100%;
      @include ar {
        padding-inline-start: 12px;
        direction: rtl;
      }
    }
    .helper-msg {
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
    .error-msg {
      color: #f44336 !important;
      width: 100%;
      max-width: 425px;
      font-size: 12px;
      display: inline-block;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .customer-care-input-facebook {
    input {
      padding: 0 12px;
    }
  }
}
.social-media-field-grid.keywords-inputs-fields {
  .shared-customer-care-container {
    width: 100%;
    margin-bottom: 0;
    .MuiInputBase-root.MuiOutlinedInput-root {
      padding: 0;
    }
  }
}
#customer-care-info-icon {
  margin-inline-start: 6px;
  color: #bdbdbd;
  width: 18px;
  cursor: pointer;
}
#radio-buttons-box {
  .MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    padding: 8px 11px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    @include ar {
      font-size: 10px;
      line-height: 16px;
    }
  }
}
.error-snackbar-message {
  margin: 5px 0 0 0;
  max-width: 420px;
}
