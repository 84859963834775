@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.main-sutep-engagment {
  margin: 40px 200px;

  .main-sutep-comp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .main-steps-line {
      width: calc(100% / 2 - 232px);
      margin: 0 10px;
      @include ar {
        width: calc(50% - 261px);
      }
    }
    .main-steps-continer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .main-step {
      display: flex;
      align-items: center;
      gap: 6px;
      min-width: max-content;
      .main-step-icon {
        background-color: #9e9e9e;
        border-radius: 50px;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: #fff;
          height: 16px;
          width: 21px;
        }
      }
      .step-num {
        color: #9a9b9c;
        font-size: 10px;
        font-weight: 600;
      }
      .step-name {
        color: #9a9b9c;
        font-size: 14px;
        font-weight: 500;
        margin-top: 3px;
      }
      &.active-step {
        .main-step-icon {
          background-color: #456eb2;
        }
        .step-num {
          color: #636465;
        }
        .step-name {
          color: #1a174f;
        }
      }
      &.done-step {
        .main-step-icon {
          background-color: #4caf50;
        }
      }
    }
  }
  .main-step-contant {
    margin: 30px -20px;
    min-height: 400px;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px;
    .fetching-loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 13px;
      font-size: 15px;
      font-weight: 500;
      svg {
        width: 36px;
        height: 36px;
        color: #456eb2;
      }
    }
    .step-progres {
      font-size: 10px;
      font-weight: 600;
      color: #000000de;
    }
    .main-step-one {
      padding: 20px 0;
      .step1-error-msg {
        display: flex;
        margin-top: -35px;
        margin-bottom: 35px;
        font-size: 14px;
        &.inbox-error {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      .main-snackbar {
        max-width: 340px;
        .snackbar-title {
          font-size: 16px;
          font-weight: 500;
        }
        .snackbar-body {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .step-one-title {
        font-size: 20px;
        font-weight: 700;
        color: #1a174f;
        margin-bottom: 20px;
      }
      .step-one-pragragh {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #000000de;
      }
      .step-one-feture {
        display: flex;
        align-items: center;
        gap: 5px;
        &.publish-sec {
          border-top: 1px solid #cccccc;
          margin: 20px 0;
          padding: 20px 0;
        }
        .feture-title {
          color: #0f172a;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 7px;
        }
        .feture-pragragh {
          color: #00000061;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 4px;
        }
      }
      .step-one-products {
        .product-title {
          color: #1a174f;
          font-size: 16px;
          font-weight: 700;
        }
        .req-demo {
          margin: 10px 0;
          box-shadow: none;
          border: 1px solid #d1d1d1;
          border-radius: 6px;
          background-color: #fdfdfd;
          padding: 0 16px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          button {
            color: #456eb2;
            font-size: 14px;
            font-weight: 500;
            padding: 7px 10px;
          }
        }
        .main-product {
          margin: 10px 0;
          box-shadow: none;
          border: 1px solid #d1d1d1;
          border-radius: 6px;
          background-color: #fdfdfd;
          &.publ-sec {
            margin: 0;
            box-shadow: none;
            border: none;
            background-color: #fff;
            margin-top: -25px;
          }
          &::before {
            display: none;
          }
          &.opend {
            border: 1px solid #456eb2de;
            border-radius: 6px;
          }
          .MuiAccordionSummary-expandIconWrapper {
            svg {
              color: #64748b;
              font-size: 25px;
            }
          }
          .main-product-countainer {
            margin-top: -10px;
            .main-product-sec {
              padding: 15px 0;
              &.first-sec {
                border-bottom: 1px solid #d0d0d0;
              }
              &.border-top {
                border-top: 1px solid #d0d0d0;
              }
              &.cxm {
                margin: -20px 0;
              }
              .cxm-note {
                font-size: 12px;
                font-weight: 400;
                color: #334155;
                margin: 5px 0;
                a {
                  color: #456eb2;
                  font-weight: 600;
                }
              }
              .cards-empty {
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                color: #00000061;
              }
              .tabs-btns {
                button {
                  color: #00000099;
                  font-size: 14px;
                  font-weight: 600;
                  border-bottom: 1px solid #dddddd;
                  min-height: 0;
                  padding: 6px 15px;
                  &.Mui-selected {
                    font-weight: 700;
                    color: #000000de;
                    border-bottom: 3px solid #456eb2;
                  }
                }
              }
              .cards-link-auth {
                font-size: 14px;
                font-weight: 400;
                color: #00000099;
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;
                margin-bottom: -20px;
                .link-auth {
                  font-size: 14px;
                  font-weight: 500;
                  color: #456eb2;
                  display: flex;
                  padding: 8px 12px;
                  width: 200px;
                  height: 30px;
                  align-items: center;
                  border: 1px solid #456eb2;
                  border-radius: 2px;
                  &:hover {
                    background-color: #e6f3fc80;
                  }
                  .link-text {
                    margin-inline-end: 8px;
                  }
                }
              }

              .cards-container {
                padding: 10px 0;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                grid-gap: 10px;
                gap: 10px;

                .main-card {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 35px;
                  border: 1px solid #d2dae5;
                  border-radius: 5px;
                  padding: 12px 14px;
                  background-color: #fff;
                  cursor: pointer;
                  &:hover {
                    border-color: #456eb2;
                    background-color: #e1e1e180;
                  }
                  &.small {
                    padding: 7px 14px;
                  }
                  &.selected {
                    border: 1px solid #456eb2;
                    background-color: #f6fcff;
                  }
                  .main-card-name-icon {
                    display: flex;
                    gap: 5px;
                    color: #0f172a;
                    font-size: 14px;
                    font-weight: 600;
                    .main-card-icons {
                      display: flex;
                      gap: 5px;
                      img {
                        width: 14px;
                      }
                      &.twitter-cxm {
                        img {
                          width: 17px;
                        }
                      }
                    }
                  }
                  .main-card-details {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: 12px;
                    text-transform: capitalize;
                    .item-type {
                      color: #64748b;
                    }
                    .paused {
                      color: #1a174f;
                    }
                    .collecting {
                      color: #4caf50;
                    }
                    .dotted {
                      width: 4px;
                      height: 4px;
                      border-radius: 50px;
                      background-color: #bdbdbd;
                    }
                  }
                  .warning-icon-countainer {
                    width: 35px;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    svg {
                      margin: 0;
                    }
                    &:hover {
                      background-color: #eeeeee;
                    }
                  }
                }
                .cards-create-monitor {
                  // max-width: 255px;
                  // min-width: 240px;
                  // width: calc(100% / 3 - 40px);
                  // margin-inline-end: 40px;
                  a {
                    display: flex;
                    height: 35px;
                    // width: 100%;
                    border-radius: 5px;
                    padding: 12px 14px;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #f1f5f9;
                    font-size: 14px;
                    font-weight: 600;
                    color: #456eb2;
                    border: 1px solid #fff;
                    &:hover {
                      border: 1px solid #456eb2;
                    }
                    .link-text {
                      display: flex;
                      align-items: center;
                      .link-plus {
                        font-size: 30px;
                        font-weight: 400;
                      }
                      .link-create-monitor {
                        margin-top: 3px;
                        margin-inline-start: 5px;
                      }
                    }
                    svg {
                      margin-top: 5px;
                      font-size: 18px;
                      font-weight: 300;
                      color: #8794a6;
                    }
                  }

                  //cursor: pointer;
                }
                &.empty {
                  display: flex;
                  flex-direction: column;
                  .cards-create-monitor {
                    max-width: 255px;
                    min-width: 240px;
                    width: calc(100% / 3 - 40px);
                    margin-inline-end: 40px;
                    a {
                      width: 100%;
                    }
                  }
                }
              }
            }
            .prod-sec-header {
              font-size: 16px;
              font-weight: 600;
              color: #000000de;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .main-step-two {
      padding: 20px 0;
      .step-two-header {
        font-size: 15px;
        font-weight: 400;
        padding-bottom: 25px;
        border-bottom: 1px solid #d9d9d9;
        // margin-bottom: 25px;
        .main-step-header {
          color: #1a174f;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }
      .red-star-req {
        color: #ed1846;
      }
      .step-two-sec {
        padding: 25px 0;
        border-bottom: 1px solid #d9d9d9;
        .sec-header {
          .blue-header {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 18px;
            font-weight: 500;
            color: #3e63a0;
            margin-bottom: 10px;
            .option-header {
              font-size: 14px;
              font-weight: 400;
              color: #00000061;
            }
          }
          .sec-pragrgh {
            font-size: 14px;
            font-weight: 400;
            color: #00000099;
            margin-bottom: 15px;
          }
        }
        .main-step-inputs {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 1fr;
          gap: 15px;
          margin: 20px 0;
          .input-label {
            font-size: 14px;
            font-weight: 400;
            color: #000000de;
            margin-bottom: 10px;
            text-transform: capitalize;
          }
          .input-field {
            width: 100%;
            input {
              padding: 9px 14px;
            }
          }
        }
        .input-about-you {
          margin: 25px 0 0px;
          .input-label {
            font-size: 14px;
            font-weight: 400;
            color: #000000de;
            margin-bottom: 10px;
          }
          .input-field {
            width: 100%;
            input {
              padding: 19px 14px;
            }
          }
        }
        .step-boolean {
          .MuiFormControlLabel-label {
            font-size: 14px;
            font-weight: 600;
            color: #000000de;
          }
        }
        .main-file-upload {
          .main-upload-loader {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            height: 60px;
            .upload-loader-container {
              width: 100%;
              padding: 15px 10px;
              display: flex;
              align-items: center;
              gap: 10px;
              color: #00000061;
              font-size: 14px;
              font-weight: 500;
              border: 1px solid #e2e8f0;
              border-radius: 4px;
              svg {
                width: 18px;
                height: 18px;
                color: #456eb2;
              }
            }
          }
          .engagment-upload-file-container {
            padding: 12px 20px;
            background-color: #f8fafc;
            border: 1px dashed #0000003b;
            border-radius: 4px;
          }
          .upload-file-title {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            color: #000000;
          }
          .upload-file-data {
            display: flex;
            align-items: center;
            gap: 5px;
            svg {
              color: #64748b;
              width: 26px;
              height: 26px;
              margin-inline-end: 5px;
            }
            .drop-file-content {
              font-size: 14px;
              font-weight: 500;
              color: #00000099;
              display: flex;
              align-items: center;
              gap: 5px;
              .import-browse-file {
                cursor: pointer;
                color: #456eb2;
                padding: 2px 8px;
                &:hover {
                  background-color: #e6f3fc80;
                }
              }
            }
          }
          .word-file-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 20px;
            border: 1px solid #e2e8f0;
            border-radius: 4px;
            .close-upload-file {
              display: flex;
              align-items: center;
              gap: 5px;
              color: #456eb2;
              cursor: pointer;
              font-size: 14px;
              font-weight: 500;
              padding: 7px 13px;
              border-radius: 2px;
              direction: ltr;
              text-transform: capitalize;
              &:hover {
                background-color: #e6f3fc80;
              }
            }
            .uploaded-file-content {
              display: flex;
              align-items: center;
              gap: 5px;
            }
            .word-file-content {
              display: flex;
              align-items: center;
              gap: 10px;
              .word-file-name-title {
                font-size: 14px;
                font-weight: 500;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 185px;
              }
              .word-file-kb-num {
                color: #00000099;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
          .upload-file-error-msg {
            display: flex;
            margin-top: 6px;
          }
        }
      }
      .step-two-footer {
        font-size: 14px;
        font-weight: 600;
        color: #000000de;
        margin-top: 25px;
        div {
          display: inline-block;
        }
        .step-two-footer-blue {
          margin: 0 3px;
          color: #456eb2;
          cursor: pointer;
        }
      }
    }
    .main-step-three {
      .step-three-header {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 20px 0;
        .step-three-title {
          color: #1a174f;
          font-size: 20px;
          font-weight: 600;
        }
        .step-three-pargragh {
          color: #000000de;
          font-size: 15px;
          font-weight: 400;
        }
      }
      .reps-list {
        margin-top: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e3e3e3;
        .reps-list-header {
          display: flex;
          justify-content: space-between;
          color: #000000de;
          font-size: 16px;
          font-weight: 600;
          align-items: flex-end;
          button {
            border: 1px solid #456eb2;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            font-weight: 400;
            color: #456eb2;
            padding: 1px 15px;
            span {
              font-size: 20px;
              margin-bottom: 3px;
            }
          }
        }
        .reps-list-contant {
          font-size: 14px;
          color: #00000099;
          padding: 15px 0;
        }
      }
      .ai-list {
        margin-top: 20px;
        padding: 20px;
        background-color: #f9f9fc;
        border: 1px solid #e8eaf3;
        border-radius: 3px;
        .ai-list-header {
          .ai-header-title {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-weight: 600;
            color: #212121;
            gap: 7px;
            .header-title-sub {
              font-size: 14px;
              font-weight: 400;
              color: #00000099;
            }
          }
          button {
            border: 1px solid #626cab80;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #626cab;
            padding: 8px 16px;
            &:hover {
              background-color: #e8eaf3;
            }
            &.Mui-disabled {
              background-color: #0000001f;
              color: #00000042;
              cursor: not-allowed;
              display: flex;
              gap: 5px;
            }
          }
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          &.not-empty {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .ai-header-title {
              max-width: 60%;
            }
          }
        }
        .ai-list-contant {
          padding: 20px 0;
          margin-top: 20px;
          border-top: 1px solid #a1a8ce;
          color: #00000099;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
      .list-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        padding: 5px 0;
        .rep-card-countainer {
          padding: 12px 15px;
          border: 1px solid #0000001f;
          background-color: #fff;
          border-radius: 4px;
          width: calc(100% - 32px);
          overflow: hidden;
          .rep-card-header {
            color: #000000de;
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            svg {
              color: #00000099;
              cursor: pointer;
            }
          }
          .rep-card-contant {
            font-size: 14px;
            font-weight: 400;
            color: #000000de;
            overflow: hidden;
          }
          .rep-card-footer {
            margin-top: 10px;
            padding-top: 5px;
            border-top: 1px solid #e4e4e4;
            button {
              font-size: 12px;
              font-weight: 500;
              color: #00000099;
              display: flex;
              gap: 5px;
              padding: 2px 7px;
              &:hover {
                color: #000000de;
              }
            }
          }
        }
      }
      .reps-list-pagonation {
        &.new_reps {
          margin-top: 20px;
        }
        display: flex;
        justify-content: center;
        .MuiPaginationItem-root {
          background-color: #fff;
          &.Mui-selected {
            background-color: #efeff2;
          }
          svg {
            @include ar {
              rotate: 180deg;
            }
          }
        }
      }
    }
    .main-step-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .setup-btn {
        font-size: 14px;
        font-weight: 500;
        height: 42px;
        border-radius: 2px;
        &.btn-cancel {
          color: #696d72;
          border: 1px solid #696d72;
          width: 72px;
          &:hover {
            background-color: #e1e1e180;
            color: #1e293b;
          }
        }
        &.btn-next {
          color: #fff;
          width: 92px;
          background-color: #456eb2;
          padding: 0;
          &.Mui-disabled {
            color: #00000042;
            background-color: #0000001f;
          }
        }
      }
    }
  }
}
.main-step-two-popup {
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 462px;
  margin: 0 -4px;
  .step-two-popup-header {
    color: #000000de;
    font-size: 20px;
    font-weight: 500;
  }
  .step-two-popup-contant {
    color: #000000de;
    font-size: 15px;
    font-weight: 400;
  }
  .step-two-popup-btns {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    .move-step-btn {
      color: #0000008a;
      border-radius: 2px;
      border: 1px solid #0000008a;
      padding: 7px 15px;
      &:hover {
        background-color: #fff;
      }
    }
    .keep-editing-btn {
      color: #fff;
      background-color: #456eb2;
      border-radius: 2px;
      border: 1px solid #456eb2;
      padding: 7px 15px;
    }
  }
}
#tooltip-thought-starter {
  .main-thought-starter {
    font-size: 13px;
    font-weight: 700;
    width: 180px;
    .thought-starter-list {
      margin: 5px 0;
      padding-inline-start: 25px;
      font-size: 12px;
      font-weight: 500;
      li {
        margin: 5px 0;
      }
    }
  }
}

.main-new-rep-popup {
  .rep-popup-header {
    font-size: 20px;
    font-weight: 500;
    color: #000000de;
    margin-bottom: 15px;
  }
  .input-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    color: #000000de;
    margin-bottom: 5px;
    .input-label-nums {
      font-size: 14px;
      color: #00000099;
      font-weight: 400;
    }
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormHelperText-root.Mui-error {
    display: flex;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #f44336 !important;
    margin-inline-start: 12px !important;
  }
  .rep-popup-title {
    margin-bottom: 15px;
    input {
      height: 7px;
    }
  }
  .rep-popup-contant {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline {
      padding: 5px 12px;
      min-height: 100px;
      display: flex;
      align-items: flex-start;
    }
  }
  .rep-popup-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    button {
      font-size: 14px;
      font-weight: 500;
      padding: 5px 12px;
      border-radius: 2px;
      &.cancel-rep-btn {
        color: #00000099;
        &:hover {
          background-color: #e8e8e880;
        }
      }
      &.save-rep-btn {
        color: #fff;
        background-color: #456eb2;
        &.Mui-disabled {
          background-color: #0000001f;
          color: #00000042;
          cursor: not-allowed;
          display: flex;
          gap: 5px;
        }
      }
    }
  }
}

.main-del-popup {
  width: 460px;
  margin: 0 -4px;
  .del-popup-header {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    color: #000000de;
  }
  .del-popup-contant {
    font-size: 15px;
    font-weight: 500;
    color: #000000de;
    .about-del {
      display: flex;
      gap: 5px;
      .about-del-title {
        font-weight: 700;
      }
    }
  }
  .del-popup-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    gap: 5px;
    button {
      font-size: 14px;
      font-weight: 500;
      padding: 8px 16px;
      border-radius: 2px;
      &.cancel-rep-btn {
        color: #00000099;
        border: 1px solid #0000008a;
        &:hover {
          background-color: #e8e8e880;
        }
      }
      &.del-rep-btn {
        color: #fff;
        background-color: #f44336;
        &.Mui-disabled {
          display: flex;
          gap: 5px;
          background-color: #0000001f;
          color: #00000042;
        }
      }
    }
  }
}

.main-terms-popup {
  color: #000000de;
  width: 550px;
  .main-terms-header {
    font-size: 20px;
    font-weight: 600;
  }
  .main-term-card {
    font-size: 15px;
    font-weight: 400;
    margin: 15px;
    display: flex;
    gap: 10px;
    .term-card-dotted {
      font-size: 40px;
      margin-top: -30px;
    }
    a {
      text-decoration: underline;
    }
  }
  .main-terms-footer {
    display: flex;
    justify-content: flex-end;
    button {
      color: #fff;
      background-color: #456eb2;
      border-radius: 2px;
      padding: 8px 16px;
    }
  }
}
