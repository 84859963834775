@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.hashtag-table {
  thead {
    .MuiTableCell-root,
    .MuiTypography-root {
      font-weight: 500 !important;
    }
  }
  tbody {
    .delete-icon {
      font-size: 14px;
      font-weight: 900;
      line-height: 20.02px;
    }
  }

  .account-hashtag {
    display: flex;
    align-items: center;
    .instagram {
      width: 14px;
      height: 22px;
      margin-inline-end: 5px;
    }
  }

  #status-hashtag {
    height: 16px !important;
    line-height: 18px;
    padding: 7px 12px;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    &.active {
      color: #3b873e;
      border-color: rgba(90, 122, 91, 0.5);
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #4caf50;
    }
    &.inactive,
    &.not-found {
      color: #f44336;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #f44336;
      border: 1px solid rgba(244, 67, 54, 0.5);
    }
    &.de-registering {
      color: #616161;
      background: rgba(0, 0, 0, 0.09);
      border: 1px solid rgba(0, 0, 0, 0.38);
    }
    &.registering {
      color: #ed6c02;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #ed6c02;
      border: 1px solid rgba(237, 108, 2, 0.5);
    }
  }

  .monitor-link,
  .show-all-monitors {
    color: #456eb2;
    text-decoration: underline !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.02px;
  }

  .show-all-monitors {
    white-space: nowrap;
    margin-inline-start: 8px;
    font-weight: 700;
    &:hover {
      color: black;
      cursor: pointer;
    }
  }
}

.popup-show-all {
  width: 500px;
  position: relative;
  svg {
    font-size: 13px !important;
    font-weight: 400;
    margin: 0 !important;
  }
  .monitor-link-popup {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #456eb2;
    .MuiButtonBase-root {
      padding-inline-end: 10px;
      margin-inline-end: 8px;
      margin-bottom: 8px;
      &:hover {
        background-color: #e6f3fc;
      }
    }
  }

  .number-of-monitors {
    position: absolute;
    left: 25px;
    bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #80868c;

    @include ar {
      right: 25px;
    }
  }
}

.popupModal-delet {
  width: 500px;
}
#instagram-hash-delete-btn {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  padding: 0;
  &:hover {
    background-color: #f1f5f9;
  }
  .delete-icon {
    color: #00000099;
    font-size: 16px;
  }
  .delete-icon-disabled {
    color: #00000061;
  }
}
