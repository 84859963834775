@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

@mixin flexbox(
  $display: flex,
  $direction: row,
  $placeContent: null,
  $placeItems: null,
  $wrap: nowrap,
  $shrink: 0,
  $grow: 0,
  $alignContent: null,
  $justifyContent: null,
  $alignItems: null,
  $justifyItems: null
) {
  display: $display;
  flex-direction: $direction;

  @if $placeContent ==null {
    @if $alignContent {
      align-content: $alignContent;
    }

    @if $justifyContent {
      justify-content: $justifyContent;
    }
  } @else {
    place-content: $placeContent;
  }

  @if $placeItems ==null {
    @if $alignItems {
      align-items: $alignItems;
    }

    @if $justifyItems {
      justify-items: $justifyItems;
    }
  } @else {
    place-items: $placeItems;
  }

  flex-wrap: $wrap;
  flex-shrink: $shrink;
  flex-grow: $grow;
}

/////////////////////////////////////////////
.post-item {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  .edit-delete-buttons {
    opacity: 1;
    visibility: visible;
  }
}

.post-height-overlay {
  min-height: 240px;
}

.topposts_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  overflow: hidden;
}
.post-head {
  @include flexbox($justifyContent: space-between);
}

.post_content {
  @include flexbox($justifyContent: space-between);
}

.post-title-date {
  align-self: baseline;
  @include flexbox($justifyContent: center, $alignItems: center);
  .post-user-img {
    position: relative;
    .post-img {
      object-fit: cover;
      border: 1px solid #e2e8f0;
      display: block;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      box-sizing: border-box;
    }
    .post-icon {
      width: 20px;
      height: 20px;
      background-color: #000;
      border-radius: 50%;
      position: absolute;
      bottom: -2px;
      display: flex;
      right: -3px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include ar {
        right: auto;
        left: -3px !important;
      }
      .topics-twitter-icon {
        flex: 1;

        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      img {
        top: 0;
        width: 12px;
        height: 12px;
        margin: 0;
      }
    }
  }
  .question-post-img {
    border-radius: 50%;
    border: 1px solid #e2e8f0;
    position: relative;
    img {
      object-fit: cover;
      display: block;
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
    &.news-blogs-icon-post {
      .title-icon-img {
        padding: 2px;
      }
      .post-title-icon-end {
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        padding: 4px;
        position: absolute;
        right: -12px;
        @include ar {
          right: auto;
          left: -12px !important;
        }
      }
    }
    .post-title-icon-end {
      position: absolute;
      bottom: -5px;
      right: -8px;
      &.twitter-icon-end {
        padding: 1px;
        width: 16px;
        height: 16px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background-color: #000000;
        border: 2px solid transparent;
        .x-platform-img {
          width: 12px;
          height: 12px;
          border-radius: 0%;
        }
      }
      &.insta-icon-end {
        width: fit-content;
        height: fit-content;
        background-color: #fff !important;
        border: 2px solid #fff !important;
      }
      border-radius: 30.09px;
      @include ar {
        right: auto;
        left: -8px !important;
      }
      img {
        width: 14px;
        height: 14px;
      }
      .instagram-icon-img {
        width: 20px;
        height: 20px;
        border-radius: 0% !important;
        box-sizing: border-box;
        padding: 2px;
        border: 3px solid #fff !important ;
      }
      .title-icon-img {
        &.facebook_icon {
          width: 20px;
          height: 20px;
          color: #1877f2;
          border: 0px;
        }
        background-color: #ffffff;
        border: 2px solid #ffffff;
        border-radius: 30.09px;
      }
    }
  }
}
.post-top-icons {
  .chart-icon {
    font-size: 17px;
    margin: 0px;
    cursor: "pointer";
  }

  svg {
    color: #00000099;
  }
}
.post-title {
  margin: 0px 10px;

  .screen-name {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    margin: 0px 5px;
    text-decoration-line: underline;
  }

  svg {
    font-size: 15px;
    font-weight: 400;
    margin: 0px 3px;
  }

  .twitter_icon {
    color: #1da1f2;
  }
  .twitter_public_icon {
    color: #1da1f2;
  }

  .instagram_icon {
    color: #e1306c;
  }

  .facebook_icon {
    color: #4267b2;
  }

  span {
    color: #80868c;
    font-size: 13px;
    font-weight: 400;
    text-decoration-line: underline;
  }
  .x-platform-img {
    width: 15px;
    height: 15px;
    border-radius: unset;
    margin-inline-end: 5px;
  }
}

.post-time,
.post-date {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #334155;
  font-size: 13px;
  font-weight: 400;
  margin: 0px 3px;
}

.post-content {
  @include flexbox($justifyContent: space-between, $direction: column);
  color: #000;
  font-size: 14px;
  font-weight: 400;
  background: #ffffff;
  padding: 10px;
  margin: 10px 0px 20px 0px;
  height: auto;
  img {
    width: 130px;
    height: 95px;
    border-radius: 4px;
  }

  video {
    width: 250px;
    height: 100px;
    border-radius: 4px;
  }
}

.post-content-wrapper {
  @extend .post-content;
  padding-left: 0;
  padding-right: 0;
  height: auto;
}

.post-footer-icon {
  @include flexbox($justifyContent: space-between, $alignItems: center);
}

.main-tweet-img {
  @include flexbox($justifyContent: space-between, $alignItems: center);
}

.statistics-icons {
  color: #334155;
  font-size: 14px;
  font-weight: 400;

  div {
    &:nth-child(2) {
      margin: 0px 15px;
    }
  }

  @include flexbox($alignItems: center);

  svg {
    color: #9e9e9e;
    font-size: 14px;
    margin: 0;
    margin-inline-end: 5px;
  }

  &.authors-margin {
    bottom: unset;
    margin-top: 80px;
  }
}

.display-in-row {
  &.date-row {
    display: flex;
    align-items: center;
    color: #334155;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
  }
}
.top-posts-head {
  @include flexbox($justifyContent: space-between);
  margin-top: 60px;
  margin-bottom: 20px;
  color: #0c192a;
  font-size: 18px;
  font-weight: 500;
  .top-posts-all {
    min-width: 203px;
    height: 30px;
    border: 1px solid #456eb2;
    border-radius: 4px;
    color: #2aaae2;
    font-size: 13px;
    font-weight: 500;
    @include flexbox($justifyContent: center, $alignItems: center);
  }
}

.top-posts-container {
  position: relative;
}

.retweet-number {
  font-size: 12px;
  font-weight: 400;
}

.total-posts-head {
  color: #334155;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.nb-posts-wrapper {
  .topposts-wrapper {
    .post-title-date {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 350px;

      @media (min-width: 1400px) {
        width: 418px;
      }

      @media (min-width: 1500px) {
        width: 460px;
      }

      @media (min-width: 1600px) {
        width: 510px;
      }
    }
  }
}

.retweeted-quoted-wrapper {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 3px;
  padding: 10px;
  margin-top: 15px;
}

.retweeted-quoted-name-wrapper {
  @include flexbox($alignItems: center);
  margin-bottom: 10px;
}

.retweeted-quoted-screen-name {
  color: #64748b;
  font-weight: 400;
  margin: 0px 5px;
  font-size: 12px;
}

.retweeted-quoted-user-name {
  color: #475569;
  font-weight: 600;
}

.retweeted-quote-text {
  direction: rtl;
  display: flex;
  align-items: center;
  gap: 10px;
  .retweeted-quote-img {
    max-width: 120px;
    max-height: 85px;
    border-radius: 4px;
    overflow: hidden;
    flex-grow: 6;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      transition: transform 200ms;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.post-tweet-text-default {
  flex-shrink: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  span {
    word-break: break-word;
  }
}

.hybird-text {
  @include ar {
    unicode-bidi: embed;
    direction: ltr;
    text-align: right;
  }
}

.post-sentiment {
  display: flex;

  > .MuiBox-root {
    display: flex;
    align-items: center;
  }

  .sentiment-btn {
    svg {
      margin: 0 5px;
    }
  }
}

.gap-between-five {
  gap: 5px;
  &.gab-title {
    grid-gap: 3px !important;
    gap: 3px !important;
    @include ar {
      gap: 0px !important;
    }
    .screen-name {
      font-size: 14px;
      font-weight: 600;
      color: #000000de;
      .check-icon {
        margin: 0 5px;
      }
    }
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 14px;
    font-weight: 600;
    .user-at-name {
      direction: ltr;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      color: #00000099;
    }
  }
}
.post-item-footer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.post-image-box,
.post-image-box.one-img {
  display: flex;
  align-items: center;
  max-width: 184px;
  max-height: 105px;
  gap: 2px;
  img {
    max-width: 100%;
    cursor: pointer;
  }
}
.post-image-box {
  position: relative;
  .video-icon {
    cursor: pointer;
    color: #ffffff;
    width: 21.722px;
    height: 21.722px;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    border-radius: 50%;
    svg {
      font-size: 7.823px;
    }
  }
}
.post-image-box.three-img {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2px;
  max-width: 184px;
  height: 105px;
  max-height: 105px;
  img {
    height: 51.5px;
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
  }
  div {
    height: 51.5px;
    &:first-child {
      height: 105.5px;

      grid-area: 1 / 1 / 3 / 2;
      gap: 2px;
      img {
        height: 105px;
      }
    }
  }

  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    z-index: 1;
  }
  div.video-icon {
    cursor: pointer;
    color: #ffffff;
    width: 21.722px;
    height: 21.722px;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    border-radius: 50%;
    svg {
      font-size: 7.823px;
    }
  }
}
.post-image-box.two-img {
  .image-container-card-body {
    width: 50%;
    height: 105px;
  }
}
.post-image-box.four-img {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 187px;
  height: 106px;
  flex-shrink: 0;
  div {
    height: 51.5px;
  }
  img {
    max-height: 100%;
    cursor: pointer;
    max-width: 100%;
  }
}

.tweet-full-text-read-all-btn {
  color: #456eb2 !important;
  text-decoration: underline;
  font-size: 13px !important;
  font-weight: 500;
  margin-inline-start: 2px;
  cursor: pointer;
  display: inline-block;
}
