@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

$skeltonGradient: linear-gradient(
  90deg,
  rgba(0, 0, 0, 0.19) 0%,
  rgba(102, 102, 102, 0.076) 100%
);

#custom-dashboard-editor {
  display: flex;
  flex-direction: column;
  .editor-sidebar {
    margin-top: 70px;
    background: #ffffff;
    border-inline-end: 1px solid #94a3b8;
    overflow: auto;
    height: calc(100vh - 105px);
    .sidebar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 25px 15px 15px;
      margin: 10px 0px;
      .title {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.87);
      }
      .desc {
        font-weight: 500;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
      }
      .title-skelton {
        font-size: 14px;
        width: 80px;
        background: $skeltonGradient;
      }
      .desc-skelton {
        font-size: 12px;
        width: 120px;
        background: $skeltonGradient;
      }
    }
    .loading-container {
      margin-top: 10px;
      .skelton-box {
        padding: 15px 25px 15px 15px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .title-skelton {
          height: 14px;
          border-radius: 2px;
          background: $skeltonGradient;
        }
        .social-skeltons {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
          .item {
            width: 14px;
            height: 14px;
            margin: 0px;
            border-radius: 2px;
            background: $skeltonGradient;
          }
        }
      }
    }
  }
  .editor-content {
    margin-top: 70px;
    height: calc(100vh - 135px);
    overflow: auto;
    padding: 30px 60px 0px 30px;
    .widgets-content-top-header {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 20px;
    }
    .custom-dashboard-content-box {
      margin-bottom: 60px;
    }

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .empty-widgets {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      img {
        width: 216px;
        height: 216px;
      }
      .title {
        font-weight: 600;
        font-size: 20px;
      }
      .desc {
        font-weight: 400;
        font-size: 15px;
        color: #64748b;
        text-align: center;
      }
    }
  }
}

#custom-dashboard-manage-monitors-dialog {
  .dialog-title {
    font-weight: 500;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px;
    .counter {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      margin: 0 5px;
    }
  }
  .dialog-actions {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px;
    &.has-message {
      justify-content: space-between;
    }
    .message-box {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;
      font-weight: 500;
      font-size: 12px;
      color: rgba(100, 116, 139, 1);
      svg {
        margin-top: 3px;
      }
      .message-text {
        width: 300px;
        font-weight: 500;
        font-size: 12px;
      }
    }
    .action-btns-box {
      display: flex;
      gap: 10px;
    }
  }
  .dialog-content {
    height: 300px;
    padding: 15px;
    overflow: auto;
    &.loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > div {
        top: 0;
      }
    }
    .platform-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }
    .accounts-grid-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
    .platform-accounts-container {
      width: 100%;
      margin-inline-start: 10px;
      border-inline-start: 1px solid rgba(226, 232, 240, 1);
      .MuiGrid-container {
        padding-inline-start: 10px;
      }
    }
    .show-more-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 10px;
    }
  }
}

#custom-dashboard-monitor-card,
#custom-dashboard-account-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(226, 232, 240, 1);
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
  .selected-check {
    display: none;
    justify-content: center;
    align-items: center;
    min-width: 18px;
    min-height: 18px;
    max-width: 18px;
    max-height: 18px;
    font-size: 12px;
    color: #ffffff;
    border-radius: 100%;
  }
  &.selected {
    border-color: rgba(69, 110, 178, 1);
    .selected-check {
      display: flex;
      background-color: rgba(69, 110, 178, 1);
    }
  }
  &.unselected {
    border-color: rgba(148, 163, 184, 1);
    .selected-check {
      display: flex;
      background-color: rgba(203, 213, 225, 1);
    }
  }
}
#custom-dashboard-monitor-card {
  .monitor-info {
    .monitor-name {
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.87);
    }
    .info-list {
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      padding: 0px 20px;
      margin: 5px;
      & > ::marker {
        color: rgba(0, 0, 0, 0.6);
      }
      .monitor-data-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        margin: 5px 0px;
        span {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }
}
#custom-dashboard-account-card {
  > img {
    width: 30px;
    height: 30px;
  }
  .account-name {
    flex-grow: 1;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
    padding: 0px 5px;
  }
}

#custom-dashboard-sidebar-accordion {
  background-color: #ffffff;
  box-shadow: none;
  border-bottom: 1px solid rgba(226, 232, 240, 1);
  &::before {
    content: none;
  }
  &.Mui-expanded {
    box-shadow: none;
    margin: 0;
    > .custom-dashboard-accordion-header {
      background-color: #f1f5f9;
      border-bottom: 1px solid rgba(226, 232, 240, 1);
    }
  }
  .custom-dashboard-accordion-header {
    background-color: transparent;
    height: 64px;
    > .MuiAccordionSummary-content {
      gap: 10px;
      margin: 12px 0;
      .account-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-weight: 500;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87);
        > span {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: rgba(0, 0, 0, 0.6);
          > img {
            width: 16px;
            height: 16px;
            margin-inline-end: 4px;
          }
        }
      }
    }
  }
  .sidebar-widgets-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}

#sidebar-widget-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: rgba(241, 245, 249, 1);
  border: 1px solid rgb(241, 245, 249);
  border-radius: 2px;
  overflow: hidden;
  .widget-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
    background: #f1f5f9;
    padding: 4px 10px;
    width: 100%;
    box-sizing: border-box;
    > p {
      font-weight: 500;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.87);
    }
    .widgets-sidebar-add-widget-btn {
      background-color: rgba(248, 250, 252, 1);
      color: #000000;
      border-radius: 4px;
    }
    &.disabled {
      background: #ffffff;
      > p {
        opacity: 0.2;
      }
    }
  }
  .widgets-sidebar-widget-img {
    width: 100%;
    height: auto;
  }
}

.content-widget-container-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  user-select: none;
  .content-image-wrapper {
    position: relative;
    width: 100%;
    > .remove-widget-btn {
      position: absolute;
      content: "";
      color: rgba(244, 67, 54, 1);
      font-size: 22px;
      top: 45px;
      cursor: pointer;
      @include en {
        right: 30px;
      }
      @include ar {
        left: 30px;
      }
    }
  }
  > img {
    border: 1px solid rgb(226, 232, 240);
    border-radius: 5px;
  }
  > .move-icon {
    cursor: pointer;
    padding: 5px 7px;
    &:hover {
      background-color: rgba(226, 232, 240, 1);
      border-radius: 2px;
    }
  }
}
