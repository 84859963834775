@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.ai-agents-side-bar-container {
  width: 329px;
  background-color: #ffffff;
  border-inline-end: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
}

.ai-agents-side-bar-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  border-bottom: 1px solid #e2e8f0;

  .side-bar-search-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.38);
  }

  .MuiTextField-root {
    flex-grow: 1;

    .MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
      input {
        padding: 0;

        &::placeholder {
          color: rgba(0, 0, 0, 0.38);
          font-size: 14px;
          font-weight: 400;
        }
      }

      fieldset {
        border: none;

        &:focus,
        &:hover {
          border: none;
        }
      }
    }
  }
}

.ai-agents-side-bar-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-grow: 1;
  padding: 15px;
  align-self: center;

  .ai-agents-empty-description {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
  }
}

.ai-agents-side-bar-content {
  padding: 20px;
  flex-grow: 1;
  overflow: auto;
}

.ai-agent-side-bar-box {
  min-height: 110px;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;

  .ai-agent-box-name {
    font-size: 16px;
    font-weight: 600;
    color: #000000de;
    margin: 0;
  }

  .ai-agent-box-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .ai-agent-box-hint {
    margin: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
    &.agent-not-assigned {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }

  .ai-agent-box-status {
    padding: 6px 11px;
    border-radius: 4px;
    border: 1px solid;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.ai-agent-box-status.box-status-published,
.ai-agent-box-status.box-status-publishing {
  border-color: rgba(76, 175, 80, 0.5);
  background-color: rgba(76, 175, 80, 0.08);
  color: #4caf50;
}

.ai-agent-box-status.box-status-draft {
  border-color: rgba(0, 0, 0, 0.38);
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.6);
}

.ai-agent-box-status.box-status-paused {
  border-color: rgba(237, 108, 2, 0.5);
  background-color: rgba(237, 108, 2, 0.08);
  color: #ed6c02;
}
.ai-agent-box-status.box-status-down {
  border-color: rgba(244, 67, 54, 0.5);
  background-color: rgb(255, 238, 237);
  color: rgba(244, 67, 54, 1);
}

.ai-agent-side-bar-box.side-bar-box-active {
  border: 2px solid rgba(69, 110, 178, 1);
}

.ai-agent-name-tooltip-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
.ai-agent-name-tooltip-icon {
  font-size: 18px;
  color: rgba(244, 67, 54, 1);
}
