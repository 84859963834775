@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.luci-accordion-header {
  padding: 0 !important;
  margin-top: 20px !important;
  .header-container {
    display: flex;
    align-items: center;
    .prod-title {
      font-weight: 500 !important;
    }
    .utilities-new-badge {
      color: #ffffff;
      margin: 0 10px;
    }
  }
  &.MuiButtonBase-root {
    background: #fff;
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #ededed;
    padding: 20px 20px;
  }
  .MuiPaper-root.MuiPaper-elevation {
    margin: 0;
    margin-bottom: 15px;
  }
  &.Mui-expanded {
    background-color: #f7f7f7;
    border-bottom: transparent;
    padding: 0px 20px;
    margin: 0 !important;
    font-size: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  svg {
    color: #212121;
    font-size: 23px;
  }
  &:hover {
    background-color: #f6f6f6;
    transition: all 0.5s ease;
  }
}
.luci-accordion-summary {
  &.MuiAccordionDetails-root {
    background-color: #f7f7f7;
    width: calc(100% - 72px);
    margin: auto !important;
    border-bottom: 1px solid transparent;
    padding: 1px 16px 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    &.MuiAccordionDetails-root {
      .MuiTypography-root {
        color: #212121;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.7;
        ol {
          padding: 0;
          padding-inline-start: 18px !important;
        }
      }
    }
  }
  .luci-utilites-container {
    margin: 0 10px;
    &:last-child {
      padding-top: 8px;
      border-top: 1px solid #e4e4e4;
    }
    .type-title {
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #000 !important;
    }
  }
}

.luci-accordion-header.MuiButtonBase-root {
  width: calc(100% - 40px);
  padding: 0px 20px !important;
  text-align: center;
  margin: auto !important;
  height: 66px;
}
.luci-accordion-wrapper {
  &.MuiPaper-root {
    box-shadow: none;
    &::before {
      content: none;
    }
    &:last-of-type {
      box-shadow: none;
    }
  }
}
