@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.top-hashtags-view {
  border-radius: 4px;
  .top-hashtags-tab {
    .MuiTab-root {
      min-height: 48px;
      min-width: 124px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.4px;
      color: #233759;
      padding: 8px 12px;
    }
    .MuiTabs-indicator {
      display: unset;
      background-color: #456eb2;
      height: 3px;
    }
  }
  .top-hashtags-filter {
    background: #ffffff;
    border-radius: 4px;
    padding: 25px;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #0c192a;
      height: 24px;
      margin-bottom: 20px;
    }
    @include ar {
      fieldset {
        text-align: right;
      }
    }
    .top-hashtags-filter-field-box {
      display: flex;
      align-items: center;
      gap: 16px;
      div.search-input-top-trend-box {
        width: 100%;
        position: relative;
        @include ar {
          label {
            transform: translate(361px, 10px) scale(1);
            right: 375px;
            width: max-content;
            overflow: visible;
            text-overflow: inherit;
            &.Mui-focused,
            &.MuiFormLabel-filled {
              transform: translate(448px, -11px) scale(0.75);
              right: 419px;
              width: max-content;
              text-overflow: inherit;
              background: #ffffff;
              padding: 3px;
              display: block;
              font-size: 14px;
            }
          }
          fieldset {
            legend {
              span {
                display: none;
              }
            }
          }
        }
        @include en {
          label {
            transform: translate(9px, 10px) scale(1);
            &.Mui-focused,
            &.MuiFormLabel-filled {
              transform: translate(8px, -12px) scale(0.75);
              background: #ffffff;
              padding: 3px;
              display: block;
            }
          }
          fieldset {
            legend {
              span {
                display: none;
              }
            }
          }
        }
        input {
          height: 42px;
          padding: 0 12px;
        }
      }
      .dateRange.MuiFormControl-root.MuiTextField-root .MuiInputBase-root {
        margin: 0;
      }

      .select-location-form-control {
        width: 100%;
        .MuiInputLabel-root {
          top: -5px;
          @include ar {
            left: auto;
            right: 26px;
          }

          &.MuiInputLabel-shrink {
            font-weight: 400;
            font-size: 15px;
            line-height: 15px;
            letter-spacing: 0.15px;
            color: rgba(0, 0, 0, 0.6);
            top: 3px;
            @include ar {
              font-weight: 500;
            }
          }
        }

        .MuiSelect-select {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: rgba(0, 0, 0, 0.87);
          padding: 9px 44px 7px 12px;
          @include ar {
            padding: 9px 12px 7px 44px;
          }
        }
        @include ar {
          svg {
            left: 7px;
            right: auto;
          }
        }
      }
      .select-location-dual-label {
        .MuiInputLabel-root {
          @include ar {
            left: auto;
            right: 16px;
          }
        }
      }
      .top-hashtags-text-field-box {
        width: 435px;
        @include en {
          margin-right: 30px;
        }
        @include ar {
          margin-left: 30px;
        }
      }

      .top-hashtags-period-form-control {
        width: 100%;

        .MuiInputLabel-root {
          top: -5px;
          &.MuiInputLabel-shrink {
            top: 0px;
          }
        }
        .MuiSelect-select {
          padding: 9px 44px 7px 12px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      .top-hashtags-filter-results-btn {
        display: flex;
        justify-content: end;
      }
    }
    .top-hashtags-filter-value-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .top-hashtags-selected-box {
        margin: 0px 30px;
        max-width: 290px;
        .hashtag-value-btn {
          padding: 3px 10px;
          border: 1px solid rgba(33, 150, 243, 0.5);
          box-sizing: border-box;
          border-radius: 16px;
          margin-top: 8px;
          margin-right: 4px;
          p {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.16px;
            color: #456eb2;
          }

          .MuiButton-endIcon {
            @include ar {
              margin-right: 8px;
              margin-left: -4px;
            }
          }
        }
      }
    }
  }
}

.top-hashtags-location-option {
  padding: 9px 19px !important;
  margin: 0px !important;
  .MuiCheckbox-root {
    padding: 0px;
    @include en {
      margin-right: 11px !important;
    }
    @include ar {
      margin-left: 11px !important;
    }
  }
  .MuiListItemText-root {
    span {
      margin: 0px !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.top-hashtags-period-option {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  &.Mui-selected {
    background-color: unset !important;
    font-weight: 500;
  }
  ul {
    height: 200px;
  }
}

.top-hashtag-overall-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  width: 100%;
  gap: 40px;
  .top-hashtag-overall-table {
    background-color: #ffffff;
    min-width: 820px;
    box-sizing: border-box;
  }

  .top-hashtag-overall-view {
    width: 45%;
    box-sizing: border-box;
  }

  .top-hashtag-hourly-view {
    width: 48%;
    box-sizing: border-box;
    position: relative;
    top: -200px;
    @include en {
      padding: 0px 25px 0 65px;
    }
    @include ar {
      padding: 0px 65px 0 25px;
    }
  }
}

.top-hashtag-hourly-box {
  margin-top: 30px;
}

.top-hashtag-table {
  .MuiTableHead-root {
    border-bottom: 0;
  }
  .MuiTableRow-head {
    height: 56px !important;
    background: #f1f5f9;

    .MuiTableCell-head {
      background: rgba(255, 255, 255, 0.002);
      text-transform: unset;
      @include en {
        padding: 16px 0px 16px 16px;
      }
      @include ar {
        padding: 16px 16px 16px 0px;
      }
      .MuiBox-root {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.17px;
        color: #475569;
        @include ar {
          text-align: right;
        }
      }
    }
  }

  .MuiTableRow-root {
    height: 52px;
    .MuiTableCell-body {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      @include ar {
        text-align: right;
      }

      &:first-child {
        @include en {
          padding-left: 24px !important;
        }
        @include ar {
          padding-right: 24px !important;
        }
      }
    }
  }
  .MuiTableBody-root > :nth-of-type(odd) {
    background: rgba(255, 255, 255, 0.002);
  }
  .MuiTableBody-root > :nth-of-type(even) {
    background: #f1f5f9;
  }

  .no-data-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    padding: 0px;
    min-width: 10px !important;
    border-bottom: 1px dashed #64748b;
    border-radius: 0px;
    cursor: default;
    &:hover {
      background-color: inherit;
    }
  }
  .MuiTablePagination-root {
    background-color: #fff;
    .MuiToolbar-root {
      padding: 0px;
      height: 56px;
      background-color: #fff !important;
    }
    .MuiTablePagination-selectLabel {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.6);
      @include ar {
        font-weight: 500;
      }
    }
    .MuiSelect-root {
      margin-left: 7px;
      margin-right: 8px;

      .MuiTablePagination-select {
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.3px;
        color: rgba(0, 0, 0, 0.87);
        @include ar {
          padding-left: 22px;
          padding-right: 4px;
        }
      }
      @include ar {
        svg {
          left: 0;
          right: auto;
        }
      }
    }
    .MuiTablePagination-displayedRows {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: rgba(0, 0, 0, 0.87);
      margin: 0 26px;
    }
    .MuiTablePagination-actions {
      display: flex;
      gap: 24px;
      align-items: center;
      direction: ltr;
      margin: 0;
      @include ar {
        direction: rtl;
      }
      .MuiButtonBase-root {
        @include ar {
          transform: rotate(180deg);
        }
      }
    }
  }
  .circular-loading {
    top: 0;
  }
  .MuiTablePagination-root:last-child {
    padding: 0 14px;
  }
}

.top-hashtag-action-btn {
  padding: 0px !important;
  width: 30px;
  height: 30px;
  &.active {
    background: #80868c !important;
    border-radius: 4px;
    color: #fff;
  }
}

.top-hashtag-action-dropdown {
  .MuiPaper-root {
    border-radius: 6px;
    margin-top: 8px;
    color: rgb(55, 65, 81);
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

    .MuiMenu-list {
      padding: 8px 0;
    }

    .MuiMenuItem-root {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      line-height: 21px;
      letter-spacing: 0.15px;
      @include ar {
        font-weight: 500;
      }

      .MuiSvgIcon-root {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.54);
        margin-right: 12px;
        margin-left: 0;
        @include ar {
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }
  }
}

.hashtag-row {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: #456eb2 !important;
}

.hashtag-chip {
  margin: 3px !important;
  svg {
    @include ar {
      margin-left: 5px;
    }
  }
}
.hashtag-show-chip {
  background-color: rgba(42, 170, 226, 0.15) !important;
  color: #456eb2 !important;
  border: transparent !important;
}
.top-hashtags-text-field {
  .MuiInputLabel-root {
    top: -6px;
    @include ar {
      left: auto;
      right: 26px;
    }

    &.MuiInputLabel-shrink {
      @include ar {
        right: 15px;
      }
    }

    &.Mui-focused {
      color: #456eb2;
      border-color: #456eb2;
      background-color: #fff;
      padding-inline-end: 2px;
    }
  }
  input {
    height: 25px;
    padding: 8px 12px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  }
  .hashtags-btn-field {
    border-radius: 0px;
    @include ar {
      border-right: 1px solid;
    }
    @include en {
      left: 13px;
      border-left: 1px solid;
    }
    &:hover {
      background-color: rgba(42, 170, 226, 0.1);
    }
  }
  #filter-dropdown-add-user-btn {
    color: #f44336;
    border-color: #f44336;
  }
  .MuiFormHelperText-root {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
    display: none;
    &.Mui-focused {
      display: block;
      color: #456eb2;
    }
    @include ar {
      font-weight: 500;
      text-align: right;
    }
  }
}
.date-period-grid {
  display: inline-flex;
}
.tweets-strip-line {
  .MuiIconButton-sizeMedium {
    border-radius: 0 !important;
  }
}
.top-hashtags-cards {
  .MuiSelect-icon {
    display: none !important;
  }
}

.hash-name {
  text-wrap: nowrap;
  cursor: unset;
  span {
    cursor: pointer;
  }
  .report-name-container {
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
  }
}

#hashtag-error.hashtag-error-message {
  color: #f44336;
  margin: 0px;
  padding-left: 8px;
  display: block;
  padding-top: 5px;
}
.top-hashtag-overall-box-parant {
  display: flex;
  align-items: center;
  justify-content: center;
  .circular-loading {
    position: static;
    margin: 100px 0;
  }
  .empty-top-hashtag {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 25px 0;
    margin-top: 110px;
    .empty-data-search {
      padding-bottom: 0;
    }
    button#top-trend-clear-search {
      border-radius: 2px;
      border-color: #64748b;
      color: #64748b;
    }
    .chart-no-data {
      margin-bottom: 20px;
      span.try-adjust-date.MuiTypography-root {
        @include en {
          max-width: fit-content;
        }
        @include ar {
          max-width: 316px;
          text-align: center;
        }
      }
    }
  }
  #shared-table {
    .MuiTableRow-root.MuiTableRow-head {
      color: rgba(0, 0, 0, 0.6);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-transform: capitalize;
      font-weight: 500;
    }
  }
  @include ar {
    .top-trend-pagination {
      display: flex;
      justify-content: flex-end;
      direction: ltr;
      .MuiTablePagination-displayedRows {
        direction: rtl;
      }
    }
  }
}
.top-trend-header-container {
  display: flex;
  align-items: center;
  justify-content: start;
  .tooltip-top-trend {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-start: 9px;
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0.38);
    font-size: 10px;
    font-weight: 900;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    margin-top: 13px;
  }
  > img {
    margin-top: 13px;
    margin-inline-end: 9px;
    width: 18px;
    height: 18px;
  }
}
#top-trend-title-tooltip {
  div.MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    max-width: 170px;
    padding: 8px 10px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    @include en {
      max-width: 220px;
    }
    @include ar {
      max-width: 178px;
    }
  }
}
.top-hashtags-filter-field-box {
  #date-range-picker-id.date-field svg {
    left: 15px;
    @include ar {
      right: 15px !important;
    }
  }
  .dateRange {
    .MuiInputBase-formControl {
      input {
        padding: 10px;
        width: 182px;
        padding-inline-start: 40px;
      }
    }
  }
  button#top-trend-clear-search {
    color: #64748b;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-transform: capitalize;
  }
  .select-region-container,
  .date-period-grid {
    min-width: 236px;
  }
  #top-trend-search-input-label {
    background: #fff;
    padding-inline-end: 0px;
  }
  .dateRange {
    .MuiInputBase-formControl {
      fieldset {
        box-shadow: none;
        border-radius: 4px;
        border-color: rgba(0, 0, 0, 0.23);
      }
    }
  }
}
div#main-top-trend-header {
  h6#main-pages-header {
    font-size: 20px !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
#filter-dropdown-add-user-btn.add-plus-error {
  color: #f44336;
  border-color: #f44336;
}

.top-trends-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
