@import "App.scss";

@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.empty-img {
  margin: 20px;
}

.err-msg {
  font-size: 12px;
  font-weight: 400;
  margin-top: 3px;
  color: #e31b0c;
  letter-spacing: 0.15px;
}

.modal-field_style fieldset {
  border-color: #e31b0c !important;
}

.survey-card {
  position: relative;
  min-height: 178px;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  padding: 18px 24px 24px;
  overflow: hidden;
  &.clickable {
    &:hover {
      cursor: pointer;
      border: 1px solid #456eb2;
      box-shadow: 0px 4px 8px 0px rgba(69, 110, 178, 0.16);
      .survey-card-overlay {
        display: block;
      }
    }
  }
  .survey-card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
  }

  .survey-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-bottom: 5px;
    .survey-card-name {
      overflow: hidden;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }
    .survey-card-icons {
      flex-grow: 1;
    }
  }

  .section-title {
    margin-bottom: 10px;
  }

  hr {
    margin: 10px 0px 0px;
  }

  .survey-card-status {
    z-index: 1;
  }

  .header-status {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: normal;
    padding: 3px 4px;
    height: 24px;
    border-radius: 12px;
    font-weight: 500;
    border: none;
    span {
      overflow: inherit !important;
    }
  }

  .status-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    svg {
      font-size: 14px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  .header-icons {
    color: #424242;
    cursor: pointer;

    @include en {
      margin-right: 6px;
    }

    @include ar {
      margin-left: 6px;
    }
  }

  .onhover_class {
    @include en {
      margin-right: 0px;
    }

    @include ar {
      margin-left: 0px;
    }
  }

  .onhover_class:hover {
    color: #456eb2 !important;
  }

  .onhover-class-delete:hover {
    color: #f44336 !important;
  }

  .edit_icon {
    background-color: transparent;
    border-radius: 3px;
    padding: 4px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-width: 32px;
  }

  .edit_icon:hover {
    background-color: #f1f5f9;
  }

  .header-status-active {
    color: #367c3a;
    background: #edf7ed;
  }

  .header-status-archived {
    color: #9e5f00;
    background-color: #fdf0e5;
  }

  .header-status-drafted {
    color: rgba(0, 0, 0, 0.54);
    background-color: #efefef;
  }
  .header-status-draft {
    color: #666666;
    background-color: #efefef;
  }

  .publish-date {
    font-size: 12px;
    font-weight: 400;
    color: #00000099;
  }

  .empty-publish-date {
    height: 18px;
  }

  .drop-action-btn {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    transition: 0.3s;

    svg {
      width: 16px;
      height: 16px;
      color: #5f6368;
    }
    &:hover {
      background-color: #f1f5f9;
    }
    &.opened-drop-actions {
      background-color: #f1f5f9;
      svg {
        color: #456eb2;
      }
    }
  }
}

.menu-serveys {
  li {
    padding: 8px 16px;
    p {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }
    svg {
      font-size: 14px;
      padding: 0 !important;
      margin: 0;
      margin-inline-end: 8px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

.section-title {
  color: #00000099;
  font-weight: 500 !important;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  svg {
    font-size: 14px;
    z-index: 1;
  }
}
.card-data-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-top: 20px;
  > div {
    width: 100%;
    max-width: 173px;
    border-inline-end: 1px solid #f1f5f9;
  }
  > div:first-child {
    border: none;
  }
}
.help-tooltip {
  font-size: 12px;
  margin-inline-start: 5px;
}

.MuiGrid-root.MuiGrid-item.survey-card-body {
  padding-top: 20px;
}

.section-icon {
  color: #456eb2;
  background-color: #2196f314;
  border-radius: 50%;
  vertical-align: middle;
  font-size: 20px;
  margin: 0px;
  margin-inline-end: 10px;
  display: inline-flex;
  width: 32px;
  height: 32px;

  svg {
    font-size: 14px;
    margin: auto;
  }
}
.card-data-box {
  .section-value {
    font-size: 14px;
    font-weight: 500;
    color: #000000de;
  }
}

.empty-survey-page {
  text-align: center;
  margin: 20% auto;
  p {
    color: #696d72;
    font-weight: bold;
    margin-bottom: 15px;
  }

  button {
    padding: 0px 5px 0px 5px !important;
    font-weight: bold;
  }
}

.delete-survey-snackbar {
  font-size: 14px;
  font-weight: 500;
  vertical-align: baseline;
  color: brown;
  top: 46px !important;
  min-width: 320px;
  right: 99px !important;
  height: 48px;
}

.delete-survey-modal {
  min-width: 600px;
  overflow: hidden;
  div.MuiDialogContent-root {
    padding-bottom: 8px;
  }
  h2 {
    font-weight: 500;
    font-size: 18px;
  }

  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    line-height: 20px;
    span {
      display: block;
      font-weight: 600;
      color: #000000de;
    }
  }
  div.MuiDialogActions-root.MuiDialogActions-spacing {
    padding: 16px 24px 24px;
  }
}

.insight-survey-snackbar {
  font-size: 14px;
  font-weight: 400;

  color: rgba(0, 0, 0, 0.87) !important;
  top: 46px !important;
  min-width: 320px;
  right: 99px !important;
  height: 48px;

  .MuiAlert-icon {
    color: #4caf50;
  }
}

.survey-insights-datepicker {
  position: static;
  display: flex;
  align-items: center;
  gap: 20px;
  @include en {
    right: 0px;
  }

  @include ar {
    left: 0px;
  }
}
.insights-line {
  height: 1px;
  background-color: #cbd5e1;
}
.survey-insights-datepicker-parant {
  .date-field svg {
    top: 48%;

    @include en {
      left: 11px;
    }
    @include ar {
      right: 5% !important;
    }
  }

  .MuiFormControl-root.MuiTextField-root {
    div {
      margin: 0;
    }
  }
}
.survey-main-title {
  margin-top: 25px;
  padding-bottom: 10px;
}
#outlined-multiline-flexible.input-sersh-survey {
  .MuiInputBase-root.MuiOutlinedInput-root {
    width: 221px;
    height: 40px;
    padding: 0;
    padding-inline-start: 12px;
    padding-inline-end: 50px;
  }
}

.search-box {
  position: relative;
  height: 40px;
  width: 221px;
  display: flex;
  .search-icon-container {
    position: absolute;
    top: 0;
    width: 44px;
    height: 39px;
    inset-inline-end: 0;
    background-color: #7f7f7f;
    border-style: none;
    cursor: pointer;
  }
  .search-icon-container-blue {
    background-color: #456eb2;
  }
  .search-icon-container {
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #ffffff;
    }
  }
  .search-field {
    width: 100%;
    height: 100%;

    @include ar {
      label {
        transform: translate(90px, 7px) scale(1);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(110px, -9px) scale(0.75);
          background: #f8fafc;
          padding: 0 8px;
        }
      }
      fieldset {
        legend {
          span {
            display: none;
          }
        }
      }
    }
    @include en {
      label {
        transform: translate(14px, 9px) scale(1);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(14px, -9px) scale(0.75);
        }
      }
    }
    .MuiInputBase-formControl {
      &:focus {
        fieldset {
          border-color: #456eb2;
        }
      }
    }
    input {
      padding: 8.5px;
    }
  }
  .search-field-dashboard {
    width: 100%;
    height: 100%;

    @include ar {
      label {
        transform: translate(110px, 10px) scale(1);
        left: 50px;

        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(135px, -9px) scale(0.75);
          background: #f8fafc;
          padding: 0 8px;
          color: #456eb2;
        }
      }
      fieldset {
        legend {
          span {
            display: none;
          }
        }
      }
    }
    @include en {
      label {
        transform: translate(14px, 10px) scale(1);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          transform: translate(14px, -9px) scale(0.75);
        }
      }
    }
    .MuiInputBase-formControl {
      &:hover {
        fieldset {
          border-color: #456eb2;
        }
      }
    }
    input {
      padding: 8.5px;
    }
  }
  .search-field-width {
    width: 228px;
    @include ar {
      label {
        left: 22px;
      }
    }
  }
  .empty-error {
    background-color: #e50035;
  }
  .MuiFormHelperText-root.Mui-error {
    text-align: start;
    text-wrap: nowrap;
  }
}
.empty-item-survey {
  background-color: #f8fafc;
}
.search-box-parant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 5px;
  > button#clear-search {
    border-radius: 2px;
    display: flex;
    padding-inline: 12px;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.26);
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    &:hover {
      color: #456eb2;
      background-color: rgba(230, 243, 252, 0.5) !important;
    }
  }
}

.container-survey-header {
  margin-bottom: 60px;
  .empty-item-survey {
    border-top: 1px solid #cbd5e1;
    margin-top: 30px;
  }
}
.btn-box-syrvey-card {
  display: flex;
  align-items: center;
  gap: 5px;
  .syrvey-card-btn {
    width: 35px;
    height: 35px;
    cursor: pointer;
    z-index: 1;
  }
  button.syrvey-icon-card {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-width: 32px;
    max-height: 32px;
    color: #00000099;
    svg {
      font-size: 16px;
    }
  }
}
#survey-card-tooltip-insights {
  > div.MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementTop {
    max-width: 202px;
  }
}
#survey-card-tooltip-edit {
  > div.MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementTop {
    max-width: 164px;
  }
}
.checkBox-radioBtn-Box {
  label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    margin: 0;
    margin-inline-end: 16px;
  }
}

.survey-list-header {
  margin-bottom: 10px;
  .survey-filters-wrapper {
    gap: 16px;
  }

  .survey-filter-select {
    width: 160px;
    legend {
      @include ar {
        text-align: right;
      }
    }
  }

  .survey-search {
    margin-bottom: 0;
  }
}

label#survey-filter-types-label,
label#survey-filter-statuses-label {
  @include ar {
    right: 30px;
  }
  &.MuiInputLabel-shrink {
    @include ar {
      left: auto;
      right: 15px;
      text-align: right;
    }
  }
}

#create-survey-menu {
  margin-top: 5px;
}

.create-survey-menu-item {
  width: 280px;
  padding: 10px 16px;
  .survey-type-menu-item-content {
    gap: 10px;
    .survey-type-icon {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 1px;
    }
    .survey-type-title {
      font-size: 14px;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.87);
    }
    .survey-type-desc {
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.6);
      overflow: hidden;
      text-wrap: auto;
      margin: 0;
    }
  }
}

#survey-filter-menu-item {
  gap: 10px;
  padding: 2px 6px;
}

p#create-survey-name-field-helper-text {
  color: #f44336;
  font-size: 12px;
}

#sruvey-card-status-tooltip {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 6px 8px;
}
