@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.rating-review {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid #e2e8f0;

  .MuiLinearProgress-root {
    width: 260px !important;
  }
  @include en {
    padding: 43px 0px 43px 45px;
  }
  @include ar {
    padding: 43px 45px 43px 0px;
  }
}

.bottom-space {
  margin-bottom: 6px;
}

.min-space {
  min-width: 200px;
}

.display-row-start {
  display: flex;
  align-items: flex-start;
}

.review-name {
  color: #6d737a;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  margin-top: 15px;
}

.review-value {
  color: #0c192a;
  font-weight: 600;
  font-size: 23px;
  line-height: 25px;
}

.linear-progress-value {
  color: #6d737a;
  font-weight: 500;
  font-size: 12px;
  @include en {
    margin-right: 10px;
    margin-left: 50px;
  }
  @include ar {
    margin-left: 10px;
    margin-right: 50px;
  }
}

.display-in-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-space > span {
  border: 2px solid #fff;
}
.bottom-space:hover {
  span {
    border: 2px solid #ffba07;
  }
  .linear-progress-value {
    color: #ffba07;
  }
}

.rate-review-tooltip {
  font-weight: 400;
  @include en {
    margin-left: 3px;
  }
  @include ar {
    margin-right: 3px;
  }
}
