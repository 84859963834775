@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.app-footer {
  .login--footer--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.copyright-text {
  color: #b1b1b1;
  font-size: 14px;
  display: flex;
  .sa-flag{
    width: 20px;
    margin-inline-start: 5px;
  }
}

.footer-nav {
  a {
    color: #6d737a;
    font-size: 14px;
    margin: 0 12px;
  }
}
.montior-footer {
  background-color: #f1f5f9;
  .copyright-text {
    color: #64748b;
    font-size: 14px;
    font-weight: 400;
  }
  .footer-nav a {
    color: #475569;
    font-size: 14px;
    font-weight: 400;
  }
}
@media (min-width: 280px) and (max-width: 729px) {
  .app-footer {
    margin-bottom: 55px;
    .login--footer--content {
      flex-direction: column;
      justify-content: center;
      gap: 24px;
    }
  }
  .copyright-text {
    text-align: center;
    margin: 0;
  }
  .footer-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    a {
      text-align: center;
    }
  }
}
