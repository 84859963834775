@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
#user_management {
  .tab-content-container {
    > div {
      border-bottom: 1px solid #e2e8f0;
    }
    button.MuiButtonBase-root.MuiTab-root.Mui-selected {
      border-bottom: 3px solid #456eb2;
      color: #233759;
    }
    .tab-content-container {
      border-bottom: 1px solid #e2e8f0;
    }
    button.MuiButtonBase-root.MuiTab-root {
      color: #64748b;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      padding: 14px 12px;
      cursor: pointer;
    }
  }
  .tab-panel {
    padding: 0;
  }
  .activity-log-datepicker-filter {
    div.MuiOutlinedInput-root {
      @include ar {
        width: 180px;
      }
      @include en {
        width: 180px;
      }
    }
    .date-field {
      svg {
        left: 12px;
      }
    }
    input.MuiOutlinedInput-input {
      @include ar {
        padding-right: 40px;
      }
      @include en {
        padding-left: 35px;
      }
    }

    .dateRange {
      > div.MuiInputBase-root {
        margin: 0;
      }
    }
  }
}
.activity-log-container {
  position: relative;

  div.circular-loading {
    top: 10px;
  }
  .MuiPaper-elevation1 {
    margin-top: 16px;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    margin-top: 16px;
    &::after {
      content: "";
      position: absolute;
      bottom: -16px;
      display: inline-block;
      width: 1px;
      height: 16px;
      background-color: #e2e8f0;
      margin-inline-start: 16px;
      z-index: 1;
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  .user-img-wrapper {
    margin-inline-end: 6px;
  }

  .user-img {
    width: 32px;
    height: 32px;
    font-size: 14px;
    text-transform: uppercase;
    margin-inline-end: 6px;
    border: 1px solid #e2e8f0;
    border-radius: 50%;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }

  .msg-content {
    font-family: "Figtree";
    font-size: 16px;
    font-weight: 400;
    color: #00000099;
  }
  .accordion-content {
    content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #00000099;
      strong.user-name {
        font-size: 16px;
        font-family: "Figtree";
        display: inline-block;
        font-weight: 500;
        color: #000000de;
      }
    }

    .user-item {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      position: relative;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      color: hsla(0, 0%, 0%, 0.6);

      &::after {
        content: "";
        position: absolute;
        bottom: -12px;
        display: inline-block;
        width: 1px;
        height: 24px;
        background-color: #e2e8f0;
        margin-inline-start: 17px;
        z-index: 1;
      }

      > div {
        display: flex;
        align-items: center;
      }

      strong {
        font-family: "Figtree";
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #000000de;
        margin-inline-end: 6px;
      }
    }
  }
  > div {
    &:last-child {
      .user-item {
        &::after {
          display: none;
        }
      }
    }
  }
  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
  .MuiPaper-root.MuiAccordion-root {
    box-shadow: none;
    &::before {
      display: none;
    }
  }
}
div.tab-panel-container {
  padding: 0;
}

.view-team-activity-section,
.main-activity-user-card {
  > h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    @include en {
      letter-spacing: 0.085em;
    }
    color: #475569;
  }
  .activity-log-container {
    margin-top: 16px;
  }
}
.main-activity-user-card {
  margin-top: 30px;
  .user-item.user-single-page {
    &::after {
      height: 38px;
      bottom: -19px;
    }
    .view-user-embty-img {
      width: 10px;
      height: 10px;
      margin-inline: 12px;
      background: #d9d9d9;
      border-radius: 50%;
    }
    .user-img {
      opacity: 0;
      visibility: hidden;
    }
  }
}
.view-team-activity-section,
.tab-panel,
.main-activity-user-card {
  .pagination-user-activity-log {
    margin-top: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @include ar {
      ul {
        li {
          &:first-child {
            button {
              rotate: 180deg;
            }
          }
          &:last-child {
            button {
              rotate: 180deg;
            }
          }
        }
      }
    }
  }
  .activity-log-datepicker-filter {
    display: flex;
    align-items: center;
    gap: 15px;

    div.MuiOutlinedInput-root {
      @include ar {
        width: 180px;
      }
      @include en {
        width: 180px;
      }
    }
    .date-field {
      svg {
        left: 12px;
      }
    }
    input.MuiOutlinedInput-input {
      @include ar {
        padding-right: 40px;
      }
      @include en {
        padding-left: 35px;
      }
    }
    div.MuiFormControl-root.MuiTextField-root {
      max-width: 100%;
      > div {
        margin: 0;
      }
    }
    #date-range-picker-id {
      width: 180px;
    }
  }
  #user-activity-filter-clear-btn,
  #user-activity-filter-apply-btn {
    width: calc(50% - 5px);
  }
  .activity-log-container {
    padding: 24px;
    margin-top: 20px;
    border: 1px solid #e2e8f0;
    background-color: #ffffff;
    .activity-log-head {
      padding-bottom: 24px;
      margin-bottom: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e2e8f0;
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #475569;
        text-transform: uppercase;
      }
    }
    #empty-activity-log {
      h3.no-data-found.MuiTypography-root {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #475569 !important;
      }
      .empty-data-box {
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 22px !important;
        max-width: 352px !important;
      }
    }
  }
}
