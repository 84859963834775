@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.filter__head {
  color: #456eb2;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.footer__wrapper {
  position: fixed;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 15px 10px 10px 20px;
  @include en {
    margin-left: -20px !important;
    width: 328px !important;
  }
  @include ar {
    width: 332px !important;
    margin-right: -20px !important;
  }
}

.filterbody__wrapper {
  background-color: #fdfdfd;
  margin-bottom: 100px;
}

.filter__label {
  color: #525252 !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.filter__applybtn {
  margin: 0px 10px !important;
}

.new__channel__filter {
  border-radius: 0 !important;

  padding-left: 10px !important;
  padding-right: 14px !important;
  .MuiButton-startIcon {
    margin-top: 2px;
    margin-right: 5px;
    margin-left: 0px;
    @include ar {
      margin-left: -6px;
    }
  }
  svg {
    color: #456eb2 !important;
    padding-bottom: 5px;
  }
  &:hover {
    background-color: transparent !important;
  }
  &:disabled {
    color: #00000061 !important;
    font-weight: 600 !important;
    border-style: none;
    border-right: none !important;
    border-radius: 4px !important;
    svg {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
}
.new-channel-filter-disabled-hover {
  background: #0000001f !important;
  color: #00000061 !important;
  font-weight: 600 !important;
  border-style: none;
  border-right: none !important;
  border-radius: 4px !important;
  svg {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

html[lang="ar"] .new__channel__filter {
  border-right: 0 !important;
  padding-right: 1px;
  padding-left: 14px;
  svg {
    padding-left: 10px !important;
  }
}
html[lang="ar"] .apply__date {
  margin-right: 30px;
}
.filter_sort {
  color: #585858;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
}
.has-active-filter {
  background-color: #456eb2 !important;
  color: #ffffff !important;
  margin-inline-end: 2px;
}
.filterbody-checkbox {
  font-size: 14px;
  display: flex;
  align-items: center;
  .filterbody-msg {
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
  }
}

.main-inputs {
  .keword-label {
    line-height: unset !important;
    @include ar {
      right: 20px;
      transform-origin: top right;
    }
  }
  .MuiInputLabel-shrink {
    @include ar {
      transform: translate(6px, -7px) scale(0.8);
    }
  }
  .MuiOutlinedInput-notchedOutline {
    @include ar {
      text-align: right !important;
    }
  }
}
.disabled-btn-empty {
  background-color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: 1px solid rgba(112, 112, 112, 0.2) !important;
  box-shadow: 0px 2px 6px 0px rgb(129 129 129 / 14%) !important;
  border: 1px solid #e2e8f0 !important;
}

.disabled-btn-empty.calender-btn {
  margin: 0 40px 0 20px;
  @include ar {
    margin: 0 40px 0 30px;
  }
}

#channel-analytics-insights-filter-btn {
  @include ar {
    .MuiButton-startIcon {
      margin: 0 !important;
    }
  }
}

.monitor-list-filter-item {
  display: flex;
  align-items: center;
  span.MuiButtonBase-root.MuiCheckbox-root {
    @include en {
      padding-right: 1px;
    }
    @include ar {
      margin-left: 1px;
    }
  }
  .monitor-list-filter-item-img-icon {
    margin-right: 10px;
    margin-left: 5px;
    @include ar {
      margin-right: 5px;
      margin-left: 10px;
    }
    &.facebook_private {
      margin-right: 7px;
      margin-left: 4px;
      @include ar {
        margin-right: 4px;
        margin-left: 7px;
      }
    }
  }
  .social-media-icon {
    margin: 0;

    .instagram-prvate-icon {
      color: #000000;
    }
    img.facebook-dm-icon {
      width: 20px;
      height: 20px;
    }
  }
}
