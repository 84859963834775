//functions sass
@mixin display-flex-function($flex, $item-center, $justify) {
  display: $flex;
  align-items: $item-center;
  justify-content: $justify;
}
//@include display-flex-function(flex, center, flex-start);
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.business-details-parant {
  padding: 30px 40px 0;
  max-width: 406px;
  .title-details {
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #212121;
      margin-bottom: 10px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #212121;
      max-width: 400px;
      margin-bottom: 30px;
    }
    #demo-multiple-checkbox {
      @include ar {
        padding-inline-start: 12px;
      }
    }
  }
  .form-control.MuiBox-root {
    margin-bottom: 70px;
  }
  .MuiBox-root {
    width: 100%;
    margin-bottom: 20px;
    > div {
      width: 100%;
    }
    .input-requird {
      display: none;
    }
    .lable-input {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #0f172a;
      display: inline-block;
      &::after {
        content: "*";
        color: #ed1846;
        margin-inline-start: 3px;
      }
    }
    .validation-err {
      .MuiSelect-select.MuiSelect-outlined {
        color: #f44336;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #f44336;
      }
      .MuiInputBase-root.MuiOutlinedInput-root input {
        color: #f44336;
      }
      .select-input {
        border-color: #f44336 !important;
      }
    }
    input,
    .MuiOutlinedInput-input {
      height: 40px;
      padding: 0;
      padding-inline-start: 12px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      margin-inline-end: 40px;
      border: none !important;
      @include display-flex-function(flex, center, flex-start);
      &:hover {
        border-color: #456eb2 !important;
      }
      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
        opacity: 1;
      }
    }
    input:hover {
      border-color: #456eb2 !important;
    }
  }
  .business-details-next {
    height: 42px;
    width: 100%;
    background: #456eb2;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #ffffff;
    svg {
      margin-inline-start: 8px;
      @include ar {
        transform: rotate(180deg);
      }
    }
    &:hover {
      background: #456eb2;
    }
  }
  .save-back-step {
    @include display-flex-function(flex, center, space-between);
    .business-save,
    .business-back {
      width: calc(50% - 10px);
      height: 42px;
      border: 1px solid #456eb2;
      border-radius: 2px;
      border-radius: 2px;
    }
    .business-back {
      svg {
        margin-inline-end: 8px;
        @include ar {
          transform: rotate(180deg);
        }
      }
    }
    .business-save {
      background: #456eb2;
      color: #ffffff;
      svg {
        margin-inline-start: 8px;
        @include ar {
          transform: rotate(180deg);
        }
      }
    }
    .business-save-loading {
      background: rgba(0, 0, 0, 0.12);
      border: transparent;
    }
    .css-1ebcam-MuiCircularProgress-root {
      border-radius: 50%;
    }
    .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
      width: 25px !important;
      height: 25px !important;
      svg {
        margin: 0;
        color: #212121;
      }
    }
  }
}
.validation-err {
  .MuiInputBase-root.MuiOutlinedInput-root {
    // border: 2px solid #ed1846 !important;
    input {
      border: none;
      &:hover {
        border-color: transparent;
      }
      &::placeholder {
        color: #ed1846;
        opacity: 1;
      }
    }
  }
  color: #ed1846 !important;
}

#tooltip-business {
  z-index: 99999 !important;

  .MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    z-index: 99999 !important;
    padding: 13px;
    width: 220px !important;

    p {
      margin: 0;
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }
    div {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }
  }
}
.tooltip-hover {
  border-bottom: 1.5px dashed #cbd5e1 !important;
}
#luci-menu.MuiButtonBase-root.MuiMenuItem-root {
  max-width: 394px;
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 0.87);
  padding: 5px 20px;
}
#business-pop-up {
  position: relative !important;
  width: 486;
  #btn-close-business {
    position: absolute;
    padding: 2px 10px;
    min-width: 30px;
    height: 30px;
    @include en {
      right: 21px !important;
    }
    @include ar {
      left: 435px !important;
    }
    z-index: 99999 !important;
    top: -40px;
    z-index: 9999999;
    background: rgba(83, 83, 83, 0.8);
    border-radius: 2px;
    &:hover {
      background: rgba(83, 83, 83, 0.8);
    }
    svg {
      width: 10px;
      height: 26px;
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      color: #ffffff;
    }
  }
}
