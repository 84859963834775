@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.echarts-for-react {
  //  margin-top: 18px;
  svg * {
    direction: ltr;
  }
}

.chart,
.media_tw,
.media_fb {
  box-shadow: 0 4px 4px #e3e3e3;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  .chart__tab {
    margin-top: -50px;
    border-bottom: 1px solid #d9d9d9;
    @include en {
      float: right;
    }
    @include ar {
      float: left;
    }
  }

  .alert-secondary {
    background-color: #f7f7f7;
    border: 1px solid #d1d1d14b;
  }
}

.chart {
  height: 415px;
}

.media_tw {
  height: 620px;
  position: relative;
}

.media_fb {
  height: 760px;
  position: relative;
}

.sentiment_chart {
  box-shadow: 0 4px 4px #e3e3e3;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  height: 400px;
}
.sentiment-analysis-widget {
  height: 450px;
}

.chart-height-dynamic{
  min-height: 415px;
  height: 100%;
  box-sizing: border-box;
}

.horizontal-bar-chart {
  box-shadow: 0 4px 4px #e3e3e3;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  min-height: 415px;
  height: 100%;
  box-sizing: border-box;
}
.horizontal-bar-widget {
  box-shadow: 0 4px 4px #e3e3e3;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  min-height: 467px;
  height: 100%;
  box-sizing: border-box;
}
.interaction-chart {
  // box-shadow: 0 4px 4px #e3e3e3;
  // background-color: #fff;
  // padding: 30px;
  // border-radius: 5px;
  // width: 100% !important;
  // box-sizing: border-box;
  .chart__tab {
    margin-top: -50px;
    border-bottom: 1px solid #d9d9d9;
    @include en {
      float: right;
    }
    @include ar {
      float: left;
    }
  }

  .alert-secondary {
    background-color: #f7f7f7;
    border: 1px solid #d1d1d14b;
  }
}

.chart__header {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include ar {
    text-align: right;
  }
  h3 {
    color: #475569;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }

  p {
    color: #6d737a;
    font-size: 12px;
    margin-bottom: -20px;
  }
}

.select__container {
  display: flex;
  align-items: center;
  text-align: justify;
  margin-right: 10px;

  @include ar {
    margin-left: 10px;
  }

  .select {
    width: 150px;
    height: 40px;
    color: #9d9d9d !important;
    padding: 0px !important;

    #demo-simple-select {
      @include ar {
        padding-right: 10px;
      }
    }
    svg {
      @include ar {
        right: 110px;
      }
    }
  }
}

.nps-icon {
  text-align: center;
}
.nps-icon__text {
  text-align: center;
  font-size: 12px;
  color: #6d737a;
}
.nps-icon__value {
  text-align: center;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 0px;
  color: #343434;
}

.nps-icon__green {
  font-size: 80px;
  color: #89bb2a;
  margin: 10px;
}
.nps-icon__yellow {
  font-size: 80px;
  color: #f9a728;
  margin: 10px;
}
.nps-icon__red {
  font-size: 80px;
  color: #e50c35;
  margin: 10px;
}
.nps-table {
  height: 300px;
  padding: 85px 0px 85px 0px;
  table {
    width: 100%;
  }
  th {
    text-align: center;
  }
}
.empty-widget__text {
  text-align: left;
  @include ar {
    text-align: right;
  }
}

#followers_growth {
  height: auto;
}
.average-engagements-per-post {
  .chart {
    height: auto;
  }
}

.increase-size {
  height: auto;
}
