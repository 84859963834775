html body.rtl-dir {
  .complex-faq {
    th,
    td {
      text-align: right;
      font-family: "Dubai" !important;
    }
    th {
      font-weight: 600;
    }
    li img {
      margin: 10px -15px 0px 0px;
    }
  }
}
.complex-faq {
  .sub-complex-faq:first-child {
    margin-top: 18px;
  }

  .sub-complex-faq {
    margin-top: 30px;
  }

  .MuiSvgIcon-root[data-testid="CheckCircleIcon"] {
    color: #8cc63f;
    font-size: 15px;
    vertical-align: middle;
  }

  .MuiSvgIcon-root[data-testid="CancelIcon"] {
    color: #ed1846;
    font-size: 15px;
    vertical-align: middle;
  }

  .text-image {
    margin-top: 10px;
  }

  .gif_image {
    border: 1px solid #cbd5e1;
    border-radius: 6px;
  }

  ol {
    margin: 0px 20px;
    padding: 0;
  }

  ol li {
    margin: 5px 0px;
    color: #000;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.15px;
  }

  ul {
    margin: 0px 20px;
    padding: 0;
  }

  ul li {
    margin: 5px 0px;
    color: #000;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.15px;
  }
  li span {
    display: flex;
  }

  li img {
    margin: 10px 0px 0px -15px;
  }
  .li-number-text-pointer {
    margin: 0 5px !important;
    list-style-type: disc;
  }

  .MuiTableContainer-root {
    box-shadow: none;
    border-radius: 0px;
  }

  .MuiTableRow-head {
    height: 56px;
  }
  .MuiTableCell-body span {
    display: block;
  }

  table {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 10px;
    table-layout: fixed;
    thead {
      border-bottom: 0;
    }
  }

  td,
  th {
    border: 1px solid #cbd5e1;
    background-color: #ffffff;
    font-size: 14px;
    padding: 16px;
  }

  th {
    background-color: #f5f5f5;
    color: #475569;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  td {
    color: rgba(0, 0, 0, 0.87);
    line-height: 20px;
    letter-spacing: 0.15px;
  }
}

html[lang="ar"] {
  .faqs-list {
    .Mui-expanded.MuiAccordionSummary-contentGutters .MuiTypography-subtitle2 {
      font-weight: 700;
    }
  }
}
