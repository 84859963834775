@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@mixin flexbox(
  $display: flex,
  $direction: row,
  $placeContent: null,
  $placeItems: null,
  $wrap: nowrap,
  $shrink: 0,
  $grow: 0,
  $alignContent: null,
  $justifyContent: null,
  $alignItems: null,
  $justifyItems: null
) {
  display: $display;
  flex-direction: $direction;

  @if $placeContent == null {
    @if $alignContent {
      align-content: $alignContent;
    }
    @if $justifyContent {
      justify-content: $justifyContent;
    }
  } @else {
    place-content: $placeContent;
  }

  @if $placeItems == null {
    @if $alignItems {
      align-items: $alignItems;
    }
    @if $justifyItems {
      justify-items: $justifyItems;
    }
  } @else {
    place-items: $placeItems;
  }

  flex-wrap: $wrap;
  flex-shrink: $shrink;
  flex-grow: $grow;
}
/////////////////////////////////////////////
.monitor-sidebar {
  width: 230px;
  z-index: 10;
  @include ar {
    left: 0;
  }
  @include en {
    left: auto;
  }
}
.monitor-sidebar > div {
  overflow-x: hidden;
}
.monitor_name {
  @include flexbox($placeItems: center);
  margin-bottom: 3px;
  span {
    color: #0f172a;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    width: 140px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    @include ar {
      // direction: ltr;
    }
  }
  .setting_btn {
    &:hover {
      background-color: #f1f5f9;
    }
    svg {
      width: 16px;
      height: 16px;
    }
    border: none;
    @include flexbox($justifyContent: center);
    margin-left: 20px;
    @include ar {
      margin-left: 0px;
      margin-right: 20px;
    }
  }
  .monitor-settings-icon {
    color: #cbd5e1;
  }
  button {
    color: #00000099;
    padding: 0;
    min-height: 0;
    min-width: 0;
    @include flexbox($justifyContent: center);

    padding: 8px;
    &:hover {
      background-color: transparent;
    }
  }
}
.account_type {
  color: #696d72;
  font-size: 11px;
  font-weight: 500;
}

.monitor_details {
  background: #ffffff;
  padding: 13.5px 15px 13.5px 20px;
  border-bottom: 1px solid #e2e8f0;
}
.monitor_pages_head {
  @include flexbox($justifyContent: center, $alignItems: center);
}
.monitor_typename {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  flex-grow: 0;
  width: 115px;
  font-size: 14px;
  font-weight: 500;
  @include en {
    margin-left: 10px;
  }
  @include ar {
    margin-right: 10px;
  }
  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #64748b;
  &.active {
    color: #1e293b;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #64748b;
    margin-top: 5px;
  }
}

.monitor_icon_wrapper {
  background-color: #f1f5f9;
  height: 44px;
  width: 44px;
  @include flexbox($justifyContent: center, $alignItems: center);
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  img {
    width: 20px;
  }
}
.monitor_head_wrapper {
  @include flexbox($justifyContent: center, $alignItems: center);
  .icon {
    font-size: 20px;
    color: #64748b;
  }
  .twitter_icon {
    font-size: 20px;
    color: #1da1f2;
  }

  .facebook_icon {
    font-size: 20px;
    color: #4267b2;
  }

  .instagram_icon {
    font-size: 20px;
    color: #e1306c;
  }
  .news_and_blogs_icon {
    font-size: 20px;
    color: #000000;
  }
  .talkwalker-icon {
    font-size: 20px;
    color: #000000;
  }
}
// .angle_icon {
//   margin-left: 30px;
//   @include ar {
//     margin-right: 30px;
//     margin-left: auto;
//   }
// }
.accordion_icon {
  @include flexbox($direction: row, $alignItems: center);
  color: #64748b;
  text-transform: uppercase;
  margin-bottom: 10px;
  cursor: auto;
  span {
    font-size: 10px;
    font-weight: 600;
    padding: 0px 8px;
  }
  svg {
    font-size: 12px;
  }
}
.accordion_wrapper {
  @include flexbox($direction: column, $justifyContent: center);
  padding-inline-start: 10px;
  border-inline-start: 1px solid #e2e8f0;
  p {
    color: #475569;
    font-size: 12px;
    font-weight: 400;
    background-color: #fff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0;
    cursor: pointer;
    &:hover {
      background-color: #f1f5f9;
    }
  }
}
.active_tab {
  color: #1e293b !important;
  background-color: #f1f5f9 !important;
  font-weight: 500 !important;
}
.data-source-accordion {
  padding-top: 0px !important;
}
.live-dashboard-tab-icon {
  color: #64748b;
  margin-bottom: 3px;
  @include en {
    margin-left: 42px;
  }
  @include ar {
    margin-right: 42px;
  }
}
.list-sidebar {
  padding-top: 12px !important;
}
.accordion-details {
  padding: 10px 20px !important;
  margin: 5px 0;
}
.not-active-icon {
  filter: grayscale(1);
}
