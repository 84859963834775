@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
#export-menu-custom-dashboard {
    .MuiMenu-paper {
      margin-top: 5px ;
    }
  
  .tabs-container {
    min-width: 335px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    .export-options-title {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 600;
      line-height: 26px;
      padding: 20px 20px 10px;
    }
  }
  ul {
    padding: 0px;
  }
  .export-item {
    flex-direction: column;
    .export-label.type{  
        label {
          &:last-child {
            span.export-choose {
              @include ar {
                border-top-right-radius: 0px ;
                border-bottom-right-radius: 0px ;
              }
              @include en {           
                border-top-left-radius: 0px ;
                border-bottom-left-radius: 0px ;
              }
            }
          }
          &:first-child {
            span.export-choose {
              @include ar {
                border-top-left-radius: 0px ;
                border-bottom-left-radius: 0px ;
              }
              @include en {
                border-top-right-radius: 0px ;
                border-bottom-right-radius: 0px ;
              }
            
            }
          }
        
      }
      
    }
    .export-type {
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 15px;
    }
    .export-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 20px;
      &.language {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
    .export-choose {
      padding: 15px;
      width: auto;
      height: 15px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e2e8f0;
      color: rgba(0, 0, 0, 0.6);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
      width: 120px;
      font-size: 14px;
      min-width: 50%;
    }
    .radio-item {
      width: 100%;
      min-width: 50%;
    }
    .radio-item input:checked + span.export-choose {
      border: 1px solid #456eb2;
      color: #456eb2;
      font-weight: 600;
      width: 100%;
      min-width: 50%;
    }
    
  }
  .export-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    img {
      margin-inline-end: 10px;
    }
  }
  #export-file-btn {
    margin: 20px;
  }
}
