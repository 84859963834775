@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.survey-builder-settings-sidebar-wrapper {
  width: 300px;
  min-width: 300px;
  background-color: #ffffff;
  border-inline-start: 1px solid #cbd5e1;
  border-bottom: 1px solid #cbd5e1;
  height: calc(100vh - 159px);
}

#survey-settings-sidebar-tabs-list {
  border-bottom: 1px solid #e2e8f0;
  button.survey-settings-sidebar-tabs-btn {
    width: 50%;
    font-weight: 600;
    font-size: 16px;
    margin: 0px;
    text-transform: none;
    padding-inline: 0px;
    color: rgba(0, 0, 0, 0.6);
    border-bottom: 3px solid transparent;
    &.Mui-selected {
      font-weight: 700;
      color: #000000;
    }
    &[aria-selected="true"] {
      border-bottom: 3px solid #456eb2;
    }
  }
  &.not-embedded {
    button.survey-settings-sidebar-tabs-btn {
      width: 100%;
      padding-inline: 11px;
      justify-content: flex-start;
      &[aria-selected="true"] {
        border-bottom: none;
      }
    }
  }
}

#survey-settings-sidebar-content-wrapper {
  padding: 0px;
  height: calc(100vh - 207px);
}
