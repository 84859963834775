@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.engagements-reply {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px 0;
  .loading-style {
    height: 71px;
  }
  .reply-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .locked-engagement {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    button {
      text-wrap: nowrap;
    }
    .locked-engagement-content {
      display: flex;
      gap: 12px;
      align-items: flex-start;
      svg {
        color: rgba(0, 0, 0, 0.6);
        margin-top: 2px;
      }
      .locked-engagement-context {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        h6 {
          font-size: 14px;
          font-weight: 600;
        }
        .locked-engagement-desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
          text-align: start;
        }
      }
    }
  }
  .reply-textarea {
    .MuiOutlinedInput-root {
      padding: 0;
    }
    .MuiInputBase-input {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      height: 17px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.38);
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: unset;
    }
  }
  .reply-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    .reply-footer-box {
      #engagements-send-reply {
        svg {
          @include ar {
            transform: scaleX(-1);
          }
        }
      }
      display: flex;
      align-items: center;
      gap: 4px;
      .account-reply-loading {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .reply-word-breadcrumbs {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px !important;
    font-weight: 400;
    line-height: 17px;
    margin-inline-end: 6px;
    a {
      text-decoration: auto;
    }
    .MuiBreadcrumbs-separator {
      margin-left: 5px !important;
      margin-right: 5px !important;
      color: rgba(0, 0, 0, 0.38);
    }
    .MuiBreadcrumbs-ol {
      flex-wrap: nowrap;
    }
  }
  .reply-word-breadcrumbs.word-reached {
    color: #f44336;
    .MuiBreadcrumbs-separator {
      color: #f44336;
    }
  }
  .engagements-reply-divider {
    color: #cbd5e1;
    height: 21px !important;
  }
  .reply-footer-icon {
    display: flex;
    width: 33px;
    height: 33px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .reply-footer-icon-disabled {
    color: rgba(0, 0, 0, 0.26);
    cursor: unset;
    &:hover {
      background-color: transparent;
    }
    img {
      filter: invert(75%) sepia(63%) saturate(3%) hue-rotate(325deg)
        brightness(91%) contrast(87%);
    }
  }
  .reply-footer-dropdown {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .engagements-reply-select {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    .MuiSelect-icon {
      @include en {
        right: 0;
      }
      @include ar {
        left: 0;
      }
    }
    .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
      padding: 6px;
      border-radius: 4px;
      padding-inline-end: 25px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    &.disable-eng-reply-select-hover {
      .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
        &:hover {
          background-color: transparent;
        }
      }
    }
    .MuiSelect-select {
      padding: 0;
    }
    .MuiOutlinedInput-notchedOutline {
      border: unset;
    }
  }
  .emoji-icon {
    position: relative;
    .emoji-box {
      position: absolute;
      bottom: 40px;
      inset-inline-start: 0;
    }
    .emoji-box-hidden {
      display: none;
    }
  }
  .reply-attachments-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .reply-attachment {
      width: 111px;
      height: 45px;
      border-radius: 4px;
      border: 0.663px solid #cbd5e1;
      background-color: #f8fafc;
      display: flex;
      .attachments-img-box {
        height: 100%;
        width: 66px;
        border-inline-end: 0.663px solid #cbd5e1;
        position: relative;
        .attachments-img {
          width: 100%;
          height: 100%;
        }
      }
      .react-thumbnail-generator {
        width: 66px;
        height: 100%;
        border-inline-end: 0.663px solid #cbd5e1;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .remove-attachments-reply {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.38);
        cursor: pointer;
        .trash-wrap {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2.5px;
          border: 0.663px solid #cbd5e1;
          background-color: #ffffff;
        }
        &:hover {
          color: #f44336;
        }
      }
    }
  }
  .emoji-icon {
    position: relative;
    .emoji-box {
      position: absolute;
      bottom: 40px;
      inset-inline-start: 0;
    }
    .emoji-box-hidden {
      display: none;
    }
  }
}
.reply-option.Mui-disabled.MuiMenuItem-root {
  opacity: 1;
  background-color: transparent;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.account-box-avater {
  width: 33px;
  height: 33px;
  min-width: 33px;
  min-height: 33px;
  border-radius: 50%;
}
.account-reply-avater.MuiAvatar-root {
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 50%;
}
.account-reply-empty {
  border: 1px solid #cbd5e1;
  background-color: #e8eaf3;
}
.account-default-reply {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cbd5e1;
}
.no-accounts-to-reply-sla {
  width: 237px;
  text-wrap: wrap;
}
.account-dropdown-list {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 237px;
  .list-data-img {
    position: relative;
    .list-data-icon {
      position: absolute;
      bottom: -2.5px;
      inset-inline-end: -2px;
      display: flex;
      width: 18px;
      height: 18px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 10px;
      color: #ffffff;
    }
    .list-data-icon.facebook-icon {
      background-color: #4267b2;
    }
    .list-data-icon.twitter-icon {
      background-color: #000000;
      img {
        width: 10px;
        height: 10px;
      }
    }
    .list-data-icon.instagram-icon {
      background-color: #e1306c;
    }
    .list-data-icon.whatsapp-icon {
      background-color: rgb(37, 211, 102);
      svg {
        font-size: 12px;
      }
    }
  }
  .list-data-option {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    display: block;
  }
  .list-data-source {
    font-size: 12px;
    color: #696d72;
    display: block;
  }
}
.attachments-video-box {
  position: relative;
}
.attachments-icon-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  img {
    width: 18px;
    height: 16px;
  }
}
.reply-translation-container {
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding: 12px;
  border-inline-start: 4px solid #cbd5e1;
  background-color: #f1f5f9;
}
.reply-translation-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.reply-translation-button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  display: flex;
  padding: 2px 15px;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  min-width: 151px;
}
.reply-translation-button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.Mui-disabled {
  img {
    filter: invert(67%) sepia(13%) saturate(0%) hue-rotate(260deg)
      brightness(94%) contrast(83%);
  }
}
.reply-translation-close {
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.reply-translation-textarea {
  padding: 0;
  .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: unset;
  }
  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 22px;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.6);
  }
}
.reply-translation-textarea.reply-translated-textarea.MuiFormControl-root.MuiTextField-root {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
}
.circular-progress-translation.MuiCircularProgress-root.MuiCircularProgress-indeterminate {
  width: 18px !important;
  height: 18px !important;
  padding: 3px;
}
.reply-translated-hint {
  color: #3b873e;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
}
.translation-text-loading {
  min-height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1380px) {
  .open-details-box {
    .engagements-reply {
      .reply-footer-dropdown {
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .reply-footer {
        gap: 5px;
        .reply-footer-box {
          gap: 3px;
        }
      }
      .reply-footer-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.active-transelation-reply-icon {
  width: 22px;
}

.replay-footer-veiw-more-icon-root {
  display: flex;
  ul.MuiList-root {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    flex: 1;
    gap: 8px;
    justify-content: center;
  }
}
.reply-footer-box-ai-agent {
  gap: 15px;
  display: flex;
}
.ai-agent-modal-text {
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 0 16px;
  span {
    font-weight: 600;
    display: inline-block;
  }
}
.ai-agent-reply-container.MuiTypography-root {
  gap: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}
.ai-agent-response-time-reply-text-icon {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.ai-agent-response-time-reply-text.MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  .time-style.MuiTypography-root {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
  }
}
.ai-agent-response-time-reply-container {
  gap: 10px;
  display: flex;
  align-items: center;
  margin: 0;
}
.engagement-response-time-info-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-inline-start: 2px;
}

.engagement-response-time-tooltip-title {
  font-size: 12px;
  line-height: 22px;
  padding: 0 2px;
  display: block;
}

.engagement-response-time-tooltip-description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: block;
  max-width: 268px;
  padding: 7px 2px 11px;
}

div.reply-box-emoji-popover-root {
  z-index: 99999999;
  .reply-box-emoji-popover {
    border-radius: 10px;
    margin-top: -25px;
  }
}
#locked-eng-modal-assigns-selects {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    label {
      top: -6px;
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(14px, -3px) scale(0.75);
      }
    }
    input {
      height: 7px;
    }
  }
  @include ar {
    .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
      label {
        top: -6px;
        left: unset;
        right: 25px;
        &.Mui-focused,
        &.MuiFormLabel-filled {
          right: 185px;
          transform: translate(178px, -3px) scale(0.75);
        }
      }
    }
    .MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
      padding-right: 9px;
      padding-left: 39px;
      .MuiAutocomplete-endAdornment {
        right: unset;
        left: 9px;
      }
      fieldset {
        text-align: end;
      }
    }
  }
  .locked-eng-modal-label {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    span {
      color: rgba(227, 27, 12, 1);
      font-size: 16px;
    }
  }
}

.locked-eng-modal-option-label {
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      font-size: 12px;
    }
  }
  span {
    font-size: 12px;
  }
}
.status-circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  &.available {
    background-color: #4caf50;
  }
  &.not-available {
    background-color: rgba(0, 0, 0, 0.607);
  }
}

.locked-eng-modal-add-new-team-btn {
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  border: none;
  color: rgba(69, 110, 178, 1);
  padding-left: 16px;
  background: transparent;
  margin: 0;
  &.locked-eng-modal-btn-no-options {
    padding: 0;
    padding-left: 0;
  }
  span,
  svg {
    font-size: 14px;
  }
}
.locked-team-and-users-empty-list-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  svg {
    font-size: 20px;
    line-height: 27px;
    color: #00000099;
  }
}
.locked-eng-modal-add-new-team-button {
  width: 100%;
  li {
    padding: 6px 16px !important;
    cursor: pointer;
    &.locked-eng-modal-add-new-team-option {
      display: flex;
      align-items: center;
      gap: 10px;
      .add-new-team-option-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.15px;
        color: #456eb2;
      }
      svg {
        color: #456eb2;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
}

.locked-eng-modal-option-label-user {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  div {
    &.locked-eng-modal-option-label-user-avatar {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.locked-eng-modal-option-label-user-info {
      display: flex;
      flex-direction: column;
      gap: 0px;
      p,
      span {
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
      span {
        color: rgba(105, 109, 114, 1);
      }
    }
  }
}

.locked-eng-modal-empty-teams-list-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  svg {
    font-size: 20px;
    line-height: 27px;
    color: #00000099;
  }
}

.locked-eng-modal-empty-field-error {
  color: rgba(244, 67, 54, 1);
  font-size: 14px;
}

#locked-engagement-assign-submit-snackbar {
  text-align: left;
  width: 380px;
  @include ar {
    text-align: right;
    width: 320px;
  }
}
