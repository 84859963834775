@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.single-tweet-analysis-box {
  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: #0c192a;
      @include ar {
        font-weight: 700;
        line-height: 34px;
      }
      .beta-btn {
        background: #f5a741;
        border-radius: 20px;
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        color: #ffffff;
        padding: 3px 6px 3px 6px;
        margin-top: -20px !important;
        min-width: 42px;
        @include en {
          margin-left: 10px;
        }
        @include ar {
          margin-right: 10px;
          font-weight: 500;
          line-height: 19px;
          padding: 0px 6px;
        }
      }
    }
    .header-left {
      display: flex;
      .export-btn {
        > button {
          &:hover {
            background-color: transparent;
          }
        }
        font-weight: 500;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.46px;
        color: #456eb2;
        @include en {
          margin-right: 20px;
        }
        @include ar {
          margin-left: 15px;
        }
      }
      .update-analysis-btn {
        padding: 8px 22px;
        background: #456eb2;
        font-weight: 500;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.46px;
        @include ar {
          .MuiButton-startIcon {
            margin-right: 0px;
            margin-left: 5px;
          }
        }
        &.Mui-disabled {
          cursor: not-allowed !important;
          opacity: 0.55 !important;
          color: white !important;
        }
      }
    }
  }

  .time-text {
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
    font-weight: 400;
    @include en {
      margin-top: 20px;
      text-align: right;
      font-style: italic;
    }
    @include ar {
      margin-top: 17px;
      text-align: left;
    }
  }

  .overview-box {
    margin-top: 5px;
    .overview-box-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: #a4a9af;
      text-transform: uppercase;
      @include en {
        letter-spacing: 0.12em;
      }
      @include ar {
        font-weight: 700;
        line-height: 24px;
      }
    }
    .overview-card {
      background: #ffffff;
      border: 1px solid #e2e8f0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      margin-top: 10px;
      @include ar {
        margin-top: 12px;
      }
      .MuiCardHeader-root {
        padding: 25px 21px 19px 26px;
        @include ar {
          padding: 25px 26px 19px 21px;
        }
        .MuiCardHeader-avatar {
          @include ar {
            margin-left: 16px;
            margin-right: 0px;
          }
          .MuiAvatar-root {
            width: 50px;
            height: 50px;
          }
        }

        .MuiCardHeader-content {
          .MuiCardHeader-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            color: #000000;
          }
          .MuiCardHeader-subheader {
            font-weight: 500;
            font-size: 14px;
            line-height: 15px;
            color: #64748b;
            margin-top: 3px;
          }
        }
      }
      .MuiCardContent-root {
        padding: 0 21px 19px 92px;
        @include ar {
          padding: 0 92px 19px 21px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }

        .overview-content-img {
          margin-top: 20px;
          width: 253px;
          height: 139px;
        }
      }
      .MuiCardActions-root {
        padding: 0 21px 14px 90px;
        @include ar {
          padding: 0 90px 14px 21px;
        }
        display: flex;
        justify-content: start;
        align-items: center;
        .MuiBox-root {
          display: flex;
          @include en {
            margin-right: 14px;
            &.heart-box {
              margin-right: 88px;
            }
            &.time-box {
              margin-right: 20px;
            }
          }
          @include ar {
            margin-left: 14px;
            &.heart-box {
              margin-left: 88px;
            }
            &.time-box {
              margin-left: 20px;
            }
          }
          p {
            font-weight: 400;
            font-size: 13px;
            color: #334155;
            @include en {
              line-height: 14px;
              padding-left: 6px;
            }
            @include ar {
              line-height: 22px;
              padding-right: 6px;
            }
          }
        }
        button {
          border: none;
          padding: 9px;
          font-weight: 500;
          font-size: 12px;
          line-height: 13px;
          color: #f9a700;
          @include ar {
            line-height: 20px;
            .MuiButton-startIcon {
              margin-right: 0;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }

  .audience-analysis-box {
    margin-top: 40px;

    .audience-analysis-box-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: #a4a9af;
      text-transform: uppercase;
      @include en {
        letter-spacing: 0.12em;
      }
      @include ar {
        font-weight: 700;
        line-height: 24px;
      }
    }

    .audience-Analysis-card {
      background: #ffffff;
      border: 1px solid #e2e8f0;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      height: 85px;
      .MuiCardContent-root {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 18px 13px 25px;
        @include ar {
          padding: 13px 25px 13px 18px;
        }
        .MuiTypography-h6 {
          font-weight: 600;
          font-size: 24px;
          line-height: 26px;
          color: #000000;
          margin-bottom: 9px;
          @include ar {
            font-weight: 700;
            font-size: 24px;
            line-height: 41px;
            margin-bottom: 0px;
          }
        }
        .MuiTypography-body2 {
          font-weight: 400;
          font-size: 12px;
          line-height: 13px;
          color: #64748b;
          @include ar {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .table-card {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    height: auto;
    .MuiCardContent-root {
      padding: 0px;

      .table-title {
        padding: 24px 26px 20px 26px;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #334155;
        @include ar {
          line-height: 27px;
          padding: 19px 26px 16px 26px;
        }
      }

      table {
        // height: 490px;
        thead {
          border: 1px solid #e2e8f0 !important;
          border-left: none !important;
          border-right: none !important;
        }
        tr {
          &:first-child {
            td {
              padding-top: 23px;
            }
          }
          &:last-child {
            td {
              padding-bottom: 23px;
            }
          }
        }
        th,
        td {
          background-color: #ffffff;
          border: none;
          @include ar {
            text-align: right;
            font-family: "Dubai" !important;
          }
        }
        th {
          padding: 15px 15px;
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          color: #64748b;
          text-transform: none;
          &:first-child {
            @include en {
              padding-left: 30px;
            }
            @include ar {
              padding-right: 30px;
            }
          }
          &:last-child {
            @include en {
              padding-right: 30px;
            }
            @include ar {
              padding-left: 30px;
            }
          }
        }
        td {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #334155;
          padding: 10px 15px;
          &:first-child {
            @include en {
              padding-left: 30px;
            }
            @include ar {
              padding-right: 30px;
            }
          }
          &:last-child {
            @include en {
              padding-right: 30px;
            }
            @include ar {
              padding-left: 30px;
            }
          }
          .engager-td {
            display: flex;
            justify-content: start;
            align-items: center;
            .MuiAvatar-root {
              width: 50px;
              height: 50px;
              @include en {
                margin-right: 10px;
              }
              @include ar {
                margin-left: 10px;
              }
            }

            .MuiTypography-body1 {
              font-weight: 500;
              font-size: 18px;
              line-height: 20px;
              color: #000000;
              margin-bottom: 4px;
            }
            .MuiTypography-body2 {
              font-weight: 500;
              font-size: 14px;
              line-height: 15px;
              color: #64748b;
            }
          }
          &.interactions-type-td {
            img {
              @include ar {
                margin-right: 20px;
              }
            }
          }
          &.reply-td {
            display: flex;
            align-items: flex-end;
            @include ar {
              direction: ltr;
              float: right;
            }
            .reply-count {
              padding-left: 7px;
              font-weight: 500;
              font-size: 14px;
              line-height: 15px;
              text-decoration: underline;
              color: #456eb2;
              cursor: pointer;
            }
          }
          // &.followers-td {
          //   padding-left: 30px;
          // }
        }
        tr:last-child {
          td {
            padding-bottom: 25px;
          }
        }
      }
    }
  }

  .replies-box {
    .replies-card {
      background: #ffffff;
      border: 1px solid #e2e8f0;
      box-sizing: border-box;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      .MuiCardHeader-root {
        padding: 17px 18px 19px 28px;
        @include ar {
          padding: 17px 28px 19px 18px;
        }
        border-bottom: 1px solid #e2e8f0;
        .MuiCardHeader-content {
          .MuiCardHeader-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #334155;
            @include ar {
              line-height: 27px;
            }
          }
        }
        .MuiCardHeader-action {
          .MuiBox-root {
            display: flex;
            justify-content: end;
            align-items: center;
            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 15px;
              color: #64748b;
              @include en {
                margin-right: 10px;
              }
              @include ar {
                line-height: 24px;
                margin-left: 10px;
              }
            }
            .MuiFormControl-root {
              margin: 0;
              .MuiOutlinedInput-root {
                color: #64748b;
                font-weight: 400;
                font-size: 14px;
                background: #f1f5f9;
                color: #64748b;
                @include ar {
                  line-height: 24px;
                }
                .MuiSelect-select {
                  padding: 9px 15px;
                }
                fieldset {
                  border-color: #cbd5e1;
                }
                @include ar {
                  .MuiSvgIcon-root {
                    right: auto;
                    left: 7px;
                  }
                }
              }
            }
          }
        }
      }
      .MuiCardContent-root {
        padding: 0px;
        .replies-list-box {
          border-bottom: 1px solid #e2e8f0;
          .replies-list-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 17px 28px 5px 30px;

            .header-left {
              display: flex;
              align-items: center;
              .MuiAvatar-root {
                width: 40px;
                height: 40px;
                @include en {
                  margin-right: 12px;
                }
                @include ar {
                  margin-left: 12px;
                }
              }
              .MuiTypography-subtitle1 {
                font-weight: 500;
                font-size: 15px;
                line-height: 16px;
                color: #000000;
                @include en {
                  margin-right: 8px;
                }
                @include ar {
                  margin-left: 8px;
                }
              }
              .MuiTypography-subtitle2 {
                font-weight: 400;
                font-size: 14px;
                line-height: 15px;
                color: #9b9b9b;
              }
            }
            .header-right {
              display: flex;
              align-items: center;
              .neutral-btn {
                padding: 0;
                padding: 9px;
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                color: #f9a700;
                border: none;
              }
              .more-btn {
                color: #64748b;
                @include en {
                  margin-left: 10px;
                }
                @include ar {
                  margin-right: 10px;
                }
              }
            }
          }
          .replies-list-body {
            padding: 0px 31px 19px 84px;
            @include ar {
              padding: 0px 84px 19px 31px;
            }
            p {
              font-weight: 400;
              font-size: 15px;
              line-height: 23px;
              color: #475569;
            }
          }
          .replies-list-footer {
            padding: 0px 31px 21px 84px;
            @include ar {
              padding: 0px 84px 21px 31px;
            }
            display: flex;
            justify-content: start;
            align-items: center;
            .MuiBox-root {
              display: flex;
              @include en {
                margin-right: 14px;
                &.heart-box {
                  margin-right: 100px;
                }
                &.time-box {
                  margin-right: 35px;
                }
              }
              @include ar {
                margin-left: 14px;
                &.heart-box {
                  margin-left: 100px;
                }
                &.time-box {
                  margin-left: 35px;
                }
              }
              p {
                font-weight: 400;
                font-size: 13px;
                line-height: 14px;
                color: #334155;
                @include en {
                  padding-left: 5px;
                }
                @include ar {
                  padding-right: 5px;
                }
              }
            }
          }
        }
      }
      .MuiCardActions-root {
        padding: 19px 20px 21px 20px;
        align-items: center;
        justify-content: center;
        button {
          padding: 0;
          margin: 0;
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          color: #456eb2;
        }
      }
    }
    .chart__no-data {
      margin-bottom: 14px;
      margin-top: 11px;
      width: 40% !important;
    }
  }

  .box-card {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    height: 100%;
    .MuiCardHeader-root {
      padding: 21px 34px 0px 30px;
      .MuiCardHeader-content {
        .MuiCardHeader-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #334155;
          border-bottom: 1px dashed #cbd5e1;
          display: inline-block;
          @include ar {
            line-height: 22px;
          }
        }
      }
      .MuiCardHeader-action {
        .MuiBox-root {
          display: flex;
          align-items: center;
          .ai-btn {
            padding: 11px 11px;
            margin: 0;
          }
          // img {
          //   margin: 0px 0px 0px 11px;
          // }
          .download-btn {
            padding: 18px;
            svg {
              color: #334155;
            }
          }
        }
      }
    }
    .comments-analysis-body {
      padding: 21px;
      .progress-box {
        padding: 23px 32px 60px 25px;
        .progress-box-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 23px;
          .MuiTypography-subtitle1 {
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            color: #6d737a;
          }
          .MuiBox-root {
            display: flex;
            align-items: center;
            .MuiTypography-subtitle2 {
              font-weight: 400;
              font-size: 12px;
              line-height: 13px;
              text-align: right;
              color: #80868c;
              margin-right: 6px;
            }
            .MuiTypography-h6 {
              font-weight: 600;
              font-size: 16px;
              line-height: 18px;
              // color: #bad532;
            }
          }
        }
      }
    }
    .sub-dialects-checkbox {
      padding: 0px;
      margin-top: 5px;
      margin-left: 24px;
      .MuiCheckbox-root {
        padding: 0;
        margin: 0;
        width: 13px;
        height: 14px;
      }
      .MuiTypography-body1 {
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: #5c5c5c;
        margin-left: 9px;
      }
    }
    .piechart-body {
      padding: 0px 84px 35px 71px;
      .echarts-for-react {
        margin-top: 0px;
      }
    }
  }

  .comments-sentiments-volume-box {
    margin-top: 105px;
    .MuiPaper-root {
      border-bottom: 1px solid #e2e8f0;
      box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
      border-radius: 5px;
      // height: 445px;
    }
    .MuiCardHeader-root {
      padding: 13px 28px 14px 26px;
      border-bottom: 1px solid #e2e8f0;
      .MuiCardHeader-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #334155;
        border-bottom: 1px dashed #cbd5e1;
        display: inline-block;
        @include ar {
          line-height: 27px;
        }
      }
      .MuiCardHeader-action {
        .MuiBox-root {
          display: flex;
          align-items: center;
          .MuiFormControl-root {
            margin: 0px;
            border-radius: 5px;
            min-width: 120px;
            @include en {
              margin-right: 13px;
            }
            @include ar {
              margin-left: 13px;
            }
            .MuiInputLabel-root {
              color: rgba(0, 0, 0, 0.6);
              font-weight: 400;
              font-size: 14px;
              letter-spacing: 0.15px;
              color: rgba(0, 0, 0, 0.6);
              @include ar {
                left: auto;
                right: 10px;
                font-family: "Dubai" !important;
              }
            }
            .MuiOutlinedInput-root {
              .MuiSelect-select {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.87);
                padding: 9px 44px 7px 12px;
                @include ar {
                  padding: 9px 12px 7px 44px;
                }
              }
              fieldset {
                border-color: #e2e8f0;
                legend {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 12px;
                  letter-spacing: 0.15px;
                  color: rgba(0, 0, 0, 0.6);
                  @include ar {
                    text-align: right;
                  }
                }
              }
              @include ar {
                .MuiSvgIcon-root {
                  right: auto;
                  left: 7px;
                }
              }
            }
          }
        }
      }
    }
    .MuiCardContent-root {
      padding: 18px 31px 31px;
      .echarts-for-react {
        margin: 0;
        padding: 0;
      }
    }
  }
  .volume-overtime-box {
    margin-top: 20px;
    display: flex;
    //another
    background-color: white;
    .MuiPaper-root {
      background: #ffffff;
      border: 1px solid #e2e8f0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
    }
    .MuiCardHeader-root {
      padding: 24px 27px 20px 27px;
      border-bottom: 1px solid #e2e8f0;
      .MuiCardHeader-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #334155;
        @include ar {
          line-height: 27px;
        }
      }
    }
    .MuiCardContent-root {
      padding: 0;
      margin: 0;
      .volume-overtime-left {
        .MuiBox-root {
          display: flex;
          justify-content: end;
          align-items: center;
          padding: 17px 32px 0px 0px;
          p {
            font-weight: 500;
            font-size: 11px;
            line-height: 12px;
            color: #9d9d9d;
            margin-right: 10px;
          }
          .MuiFormControl-root {
            margin: 0;
            .MuiOutlinedInput-root {
              font-weight: 400;
              font-size: 11px;
              color: #9d9d9d;

              .MuiSelect-select {
                padding: 10px 12px;
              }
              fieldset {
                border-color: #cbd5e1;
              }
            }
          }
        }
        .volume-overtime-graph-box {
          padding: 0px 32px 17px 30px !important;
          .echarts-for-react {
            margin: 0px;
            padding: 0px;
          }
        }
      }
      .volume-overtime-right {
        height: 356px;
        @include en {
          border-left: 1px solid #e2e8f0;
        }
        @include ar {
          border-right: 1px solid #e2e8f0;
        }
        .MuiBox-root {
          padding: 20px 30px;
          border-bottom: 1px solid #e2e8f0;
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #64748b;
            padding: 0 0 3px;
            border-bottom: 1px dashed #cbd5e1;
            display: inline-block;
            cursor: pointer;
            &.active-text {
              color: #456eb2;
            }
            @include ar {
              padding: 0px;
              line-height: 27px;
            }
          }
        }
      }
    }
  }
  .reply-num {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    position: relative;
    top: -2px;
    @include en {
      left: 8px;
    }
    @include ar {
      right: 8px;
    }
  }
  .engager-list {
    .chart__no-data {
      top: 10px !important;
      margin-bottom: 30px !important;
      width: 40% !important;
    }
    .MuiPaper-root {
      height: 430px !important;
      overflow-y: auto !important;
    }
  }
}
.alert-ppt .common-snackbar {
  max-width: 510px;
}

#export-single-tweet-menu {
  @include en {
    .MuiMenu-paper {
      transform: translate(1%, 5px) !important;
    }
  }
  @include ar {
    .MuiMenu-paper {
      transform: translate(3%, 5px) !important;
    }
  }
}
