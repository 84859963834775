// Function to return color values
@function get-color($class-name) {
  @if $class-name == "negative" {
    @return #ed1846;
  } @else if $class-name == "neutral" {
    @return #f9a700;
  } @else if $class-name == "positive" {
    @return #8cc63f;
  }
}
@mixin text-sentiment-icons-article($class-name) {
  color: get-color($class-name);
  svg {
    color: get-color($class-name);
  }
}

.articles-container-cards {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .article-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    // header card style
    .header-article-card {
      display: flex;
      justify-content: space-between;
      .publisher-title-image-header-article {
        display: flex;
        align-items: center;
        gap: 12px;

        .publisher-image {
          width: 40px;
          height: 40px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 3px;
          }
        }
        .publisher-title {
          display: flex;
          flex-direction: column;
          gap: 5px;
          a {
            font-weight: 600;
            font-size: 16px;
            line-height: 19.2px;
            color: #0c192a;
            position: relative;
            display: inline-block;
            &::after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: -1px;
              height: 1px;
              background-color: #0c192a;
            }
          }

          .publisher-article-card {
            font-weight: 400;
            font-size: 14px;
            line-height: 16.8px;
            color: #00000099;
          }
        }
      }

      .external-media-link {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background-color: #f1f5f9;
          button {
            background-color: #f1f5f9;
          }
        }
        svg {
          color: #9e9e9e;
          font-size: 15px;
        }
      }
    }

    // body card style
    .body-article-card {
      display: flex;
      align-items: center;
      gap: 20px;

      .text-article-card {
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        color: #0c192a;
        &.text-arabic {
          direction: rtl;
        }
        &.text-English {
          direction: ltr;
        }
      }
      .image-container-card-body {
        width: 180px;
        height: 180px;
      }
    }
    // footer card style
    .footer-article-card {
      padding-top: 14px;
      border-top: 1px solid #00000014;
      display: flex;
      flex-wrap: wrap;
      row-gap: 14px;

      .footer-item {
        padding: 5px 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        color: #475569;

        &.sentiment-icons-article {
          display: flex;
          align-items: center;
          gap: 4px;
          &.positive {
            @include text-sentiment-icons-article("positive");
          }
          &.neutral {
            @include text-sentiment-icons-article("neutral");
          }
          &.negative {
            @include text-sentiment-icons-article("negative");
          }
        }

        .statistic-article-footer-card {
          padding-inline: 14px;
          span {
            margin-inline-start: 4px;
          }
        }

        .label-footer-item {
          margin-inline-end: 5px;
          color: #334155;
          font-weight: 500;
        }

        svg {
          color: #9e9e9e;
          font-size: 15px;
          font-weight: 900;
        }
      }

      .footer-article-divider {
        height: 27px;
        background-color: #cfcfcf;
      }
    }

    &.not-show-in-dom {
      position: absolute;
      top: -1000%;
    }
  }
}
.repport-empty-case {
  .empty-data-box {
    width: 300px;
  }
}
