@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.luci-settings-tab {
  position: relative;
  height: 100vh;
  width: 100%;
  .settings-header {
    &.header-border {
      border-bottom: 1px solid #e9e9e9;
    }
    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      .settings-title {
        font-weight: 700;
        font-size: 18px;
      }
    }
    .settings-progress {
      background-color: #e9e9e9;
      @include ar {
        transform: rotate(180deg);
      }
    }
  }
  .settings-footer {
    background-color: #ffffff;
    bottom: 0;
    padding: 20px;
    border-top: 1px solid #e9e9e9;
    .footer-btns-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }

  .luci-steps-container {
    padding: 20px;
    height: calc(100vh - 195px);
    overflow-y: auto;
    .luci-step-one-text {
      color: #212121;
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 20px;
      margin-bottom: 15px;
      > p {
        font-weight: 400;
        font-size: 14px;
      }
    }
    h3 {
      font-weight: 600;
      padding-bottom: 10px;
    }
    .main-step-inputs {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 10px 0;
      &.step3 {
        gap: 10px;
      }
      .input-label {
        font-size: 14px;
        font-weight: 400;
        color: #000000de;
        margin-bottom: 10px;
        text-transform: capitalize;
        .red-star-req {
          color: #ed1846;
        }
      }
      .input-field {
        width: 100%;
        input {
          padding: 9px 14px;
        }
      }
      .select-input {
        margin-bottom: -5px;
        > div {
          @include ar {
            padding-right: 15px;
          }
        }
      }
      .field-error-helper {
        padding: 0px 15px;
        margin: 0;
        color: #ed1846 !important;
        @include ar {
          text-align: right;
        }
      }
      .stack-fileds {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
      }
      .outlined-form-helper {
        color: red;
        padding: 0px 10px;
        margin: 0px;
        @include ar {
          text-align: right;
        }
      }
    }
  }
}

.item-row {
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    > span {
      width: fit-content;
    }
    .textarea-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
  textarea.luci-textarea {
    font: inherit;
    padding: 10px 10px;
    color: inherit;
    border: 1px solid #00000020;
    border-radius: 5px;
    &:focus {
      border: 2px solid #456eb2;
      outline: none !important;
    }
  }
  .chips-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    .MuiChip-deleteIcon {
      @include ar {
        margin: 0 0px 0 5px;
      }
      @include en {
        margin: 0 5px 0 -5px;
      }
    }
  }

  .chips-input-end-addorment {
    height: 100%;
    @include en {
      border-left: 2px solid #456eb2;
    }
    @include ar {
      border-right: 2px solid #456eb2;
    }
  }
  .chips-input {
    padding: 0;
    input {
      height: 40px;
      padding: 0px 15px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
        @include ar {
          text-align: right;
        }
      }
    }
  }
  .Mui-error {
    @include ar {
      text-align: right;
    }
  }
}

.red-star-req {
  color: #ed1846;
}
.select-placeholder {
  color: #a2a2a2d1;
  font-weight: 300;
}
.mcq-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px !important;
  .select-checkbox {
    padding: 0px 10px;
  }
}

.competitor-accordion {
  border: 1px solid #e4e4e4;
  border-radius: 5px 5px 0px 0px;
  box-shadow: none !important;
  .competitor-accordion-summary {
    display: flex;
    gap: 10px;
    height: 52px;
    > div {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }
    &.Mui-expanded {
      height: 52px;
    }
    .competitor-name {
      color: #0f172a;
      font-weight: 500;
    }
  }
  .competitor-accordion-details {
    padding-top: 20px;
    padding-bottom: 0px;
    border-top: 1px solid #e4e4e4;
    .competitor-input-field {
      .input-title {
        color: #0f172a;
        font-weight: 400;
        margin-bottom: 5px;
      }
      margin-bottom: 20px;
      .competitors-chips-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 10px;
        .MuiChip-deleteIcon {
          @include ar {
            margin: 0 0px 0 5px;
          }
          @include en {
            margin: 0 5px 0 -5px;
          }
        }
      }
    }
  }
}
