.inbound-routing-header {
  margin: 40px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .inbound-routing-title {
    font-size: 20px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .inbound-routing-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: rgba(0, 0, 0, 0.6);
  }
  .agent-capacity-button {
    margin-inline-end: 20px;
  }
}

.inbound-routing-container {
  .routing-table-priority-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-weight: 900;
      line-height: 20.02px;
      color: #757575;
    }
  }
  .routing-table {
    &-name,
    &-team,
    &-priority,
    &-sla-name,
    &-description {
      font-size: 14px;
      font-weight: 400;
      color: #000000de;
      white-space: nowrap;
      text-wrap: nowrap;
    }

    &-sla {
      display: flex;
      flex-direction: column;
      gap: 3px;
      &-values {
        font-size: 12px;
        font-weight: 400;
        line-height: 17.16px;
        color: #80868c;
      }
    }
  }
  .routing-cell-with-tooltip-title {
    border-bottom: 1px dashed #64748b;
    display: inline-block;
  }
  .empty-routing {
    &-case {
      width: 240px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    &-text {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #64748b;
    }
    &-subtext {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #64748b;
    }
  }
  #ordered-table .MuiTableBody-root > :nth-of-type(even) {
    #settings-dropdown-user-management-long-button {
      &:hover {
        -webkit-text-decoration: none;
        text-decoration: none;
        background-color: rgba(69, 110, 178, 0.04);
      }
    }
  }
}

.routing-options-menu {
  .option-btn {
    padding: 0;
    .options-link {
      padding: 6px 16px;
      width: 97px;
      height: 34px;
      grid-gap: 10px;
      gap: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .img-wrapper {
        width: 14px;
        height: auto;
        svg {
          font-size: 14px;
          color: #000000de;
        }
      }
      .option-title {
        flex: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.15px;
        color: #000000de;
      }
    }
  }
}

.routing-cell-with-tooltip-desc {
  max-width: 200px !important;
}

.inbound-routing-delete-modal {
  width: 600px;
  .delete-routing-modal-warn-message {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000de;
    b {
      font-weight: 600;
    }
  }
}

.inbound-routing-snackbar-message {
  max-width: 328px;
}
