@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.confirmation-pop-up-container {
  position: fixed;
  bottom: 0px;
  width: 480px;
  background: #ffffff;
  box-shadow: 0px 4px 130px -28px rgba(0, 0, 0, 0.15);
  border-radius: 15px 15px 0px 0px;
  z-index: 4;
}

#luci-confirm-dialog-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #212121;
}
.confirm-content-conainer {
  padding: 30px 40px;
}
.confirm-tokens-energy-level,
.confirm-tokens-request-energy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 3px 11px 3px;
}
#confirm-token-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}
#confirm-token-icon {
  position: relative;
  top: 3px;
  margin-inline: 5px;
}
#luci-confirm-request.MuiButtonBase-root {
  padding: 8px 12px;
  gap: 8px;
  color: #ffffff;
  width: 100%;
  background: #456eb2;
  border-radius: 2px;
  svg {
    font-size: 20px;
    margin-inline: -4px;
    @include ar {
      transform: rotate(180deg);
    }
  }
}

.luci-request-loaded.MuiButtonBase-root {
  padding: 20px !important;
  background: rgba(0, 0, 0, 0.12) !important;
  pointer-events: none !important;
  .MuiLoadingButton-loadingIndicator {
    color: #212121;
    width: 11px;
  }
}

#luci-applied-filters {
  display: flex;
  align-items: center;
  justify-content: center;
}

.luci-show-applied-filters.MuiTypography-root,
.luci-hide-applied-filters.MuiTypography-root {
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.46px;
  color: #456eb2;
  cursor: pointer;
  padding-bottom: 15px;
  svg {
    left: 2px;
    position: relative;
    top: 8px;
  }
}
.luci-hide-applied-filters.MuiTypography-root {
  svg {
    transform: rotate(180deg);
  }
}
.confirm-pop-up-divider.MuiDivider-root {
  border: 1px solid #dfdfdf;
  border-width: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.confirm-tokens-container {
  padding: 25px 10px 10px 10px;
}

.confirm-token-value.MuiTypography-root {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #676767;
}
.show-apply-filters-btn {
  #luci-confirm-request.MuiButtonBase-root {
    margin-top: 30px;
  }
}
.luci-energy-not-enough.MuiTypography-root {
  font-size: 20px !important;
}
.luci-energy-not-enough-desc.MuiTypography-root {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  margin-top: 12px;
}

#luci-recharge-energy.MuiButtonBase-root {
  padding: 8px 12px;
  gap: 8px;
  color: #456eb2;
  width: 100% !important;
  background: #ffffff;
  border-radius: 2px;
  &:hover {
    background: rgba(230, 243, 252, 0.5);
  }
  border: 1px solid #456eb2;
  margin-bottom: 10px;
  padding: 8px 12px;
  cursor: pointer;
}
