@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.background-footer-menu.MuiList-root{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  .MuiListItem-root.MuiListItem-gutters {
    display: inline-flex;
    padding: 0;
    width: auto;
  }
  a{
    color: rgba(255, 255, 255, 0.80);
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
  }
}
.background-header-wrapper{
  display: flex;
  justify-content: flex-end;
  p{
    margin: 0;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px; 
    cursor: pointer;
    @include ar {
      font-family: "Figtree", sans-serif!important;
    }
    @include en{
      font-family: "IBM Plex Sans Arabic", sans-serif!important;
    }
  }
}