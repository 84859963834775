@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.stats-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin: 0px 10px;
  padding: 35px;
  position: relative;
  @include ar {
    padding-top: 25px;
    height: 139px;
  }
  svg {
    font-size: 20px;
  }
}
.stats-page-wrapper {
  display: flex;
  justify-content: space-between;
}
.stats-wrapper:first-child {
  margin-left: 0;
}
.stats-wrapper:last-child {
  margin-right: 0;
}
.stats-body {
  color: #64748b;
  font-size: 14px;
  font-weight: 400;
  margin-top: 18px;
  @include ar {
    margin-bottom: 18px;
  }
}
.stats-head-number {
  color: #000;
  font-size: 24px;
  font-weight: 700;
}
.stats-head-title {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin: 0px 10px;
}
.icon-red {
  color: #e50035;
  font-size: 20px;
}
.negative-rate-head {
  color: #e50035;
  font-size: 24px;
}
.icon-green {
  color: #8cc63f;
  font-size: 20px;
}
.percentage-number {
  color: #456eb2;
  font-weight: 600;
  font-size: 24px;
}
.margin-right-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.alert-level-wrapper {
  color: #50af51;
  svg {
    margin-inline-end: 5px;
  }
}
.na-alert-box {
  color: #64748b;
}

.alert-empty-set {
  position: absolute;
  top: 20px;
  right: 20px;
  @include ar {
    right: unset;
    left: 20px;
  }
  svg {
    color: #cbd5e1;
  }
}
.na-alert-box {
  font-size: 24px;
  font-weight: 700;
}
.icon-wrapper {
  > div {
    display: flex;
    align-items: center;
    justify-content: start;
    div {
      font-size: 24px;
      font-weight: 700;
    }
  }
}
.main-header-alert {
  .stats-body {
    min-height: 34px;
  }
}
