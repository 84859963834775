#export-menu-newsletter-report {
  .MuiMenu-paper {
    margin-top: 5px;
  }

  .export-menu-newsletter-report-body {
    padding: 20px;
    min-width: 196px;

    .tabs-container-type {
      .export-options-title-type {
        font-size: 12px;
        font-weight: 600;
        line-height: 17.16px;
        color: #000000de;
        margin-bottom: 15px;
      }

      .export-item-types {
        .export-choose {
          border: 1px solid #456eb2;
          border-radius: 4px;
          padding-block: 8px;
          display: flex;
          justify-content: center;
          justify-content: center;
          gap: 10px;
          vertical-align: middle;
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          color: #00000099;
        }
      }
    }

    .divider-item {
      margin-block: 15px;
    }

    .language-radio-buttons {
      margin-bottom: 15px;
      .label-language {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        svg {
          margin-inline-start: 5px;
        }
      }
    }

    .export-current-page-only {
      font-size: 12px;
      line-height: 17.16px;
      margin-bottom: 16px;
    }

    #export-file-btn {
      width: 100%;
    }
  }
}
