@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.cardStyle.audience {
  @include ar {
    .MuiCardContent-root {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
    p {
      direction: ltr;
    }
    .MuiBox-root {
      width: 100%;
    }
    .nameTextFieldStyle.audience {
      margin-right: 0;
      margin-left: 25px;
    }
  }
  .no-data-survey {
    padding: 20px 0;
  }
}

.textFieldStyle.audience {
  @include ar {
    .phoneInput {
      left: 285px;
    }
    .intl-tel-input.allow-dropdown .flag-container {
      left: 185px;
    }
  }
}

.cardStyle .card-body .questionStylee {
  padding: 4px 0 5px !important;
}

.socail-media-survey.audience {
  display: flex;
  align-items: center;
  img {
    width: 34px;
  }
}

.social-input.audience {
  width: 90% !important;
}

.preview {
  text-align: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  height: 50px;
}

.preview .arrowIcon {
  position: absolute;
  left: 0;
  margin-left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.preview span {
  position: relative;
  top: 30%;
  font-size: 18px;
  color: #0c192a;
}

.mainStyle {
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.footerStyle {
  display: flex;
  align-items: center;
  margin-top: 62px;
  margin-bottom: 50px;
  justify-content: center;
}

.footerFontStyle {
  color: #0c192a;
  font-size: 18px;
  line-height: 22px;
  margin-right: 5px;
  font-weight: 400;
}

.cardStyle.audience {
  width: 100%;
  margin: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  margin-top: 20px;
  padding: 14px;
  padding-bottom: 6px;
  overflow: visible;
}

.dropdownStyle {
  min-width: 66% !important;
  margin-top: 10px !important;
}

.errorBorder {
  border: 1.3px solid #e31b0c;
}

.noError.audience {
  border: 1px solid #cbd5e1;
}

.buttonStyle {
  margin: auto;
  margin-top: 30px;
  text-align: right;
  width: 100%;
  margin-bottom: 30px;
}

.questionStyle.audience {
  font-size: 22px;
  font-weight: 400;
  color: #0c192a;
  text-transform: capitalize;
}

p.questionStyle.audience {
  @include en {
    direction: ltr;
  }
  @include ar {
    direction: rtl;
  }
}

.questionStyle.audience span {
  color: #2497ca;
  font-size: 22px;
  font-weight: 500;
  margin-inline-end: 10px;
}

.subtitle.audience {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-style: italic;
  margin-top: 25px;
}

.cardStyle.audience > div {
  position: relative;
}

.textFieldStyle.audience {
  width: 66%;
  margin-top: 25px;
}

.nameTextFieldStyle.audience input,
.textFieldStyle.audience input {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

.nameTextFieldStyle.audience {
  width: 31.1%;
  margin-top: 25px;
  margin-right: 25px;
}

.textFieldStyle.audience label {
  color: #80868c;
}

.widthClass label {
  color: rgba(0, 0, 0, 0.38);
  font-family: Roboto;
}

.nameTextFieldStyle.audience label {
  color: #80868c;
}

.impQuestion {
  color: red;
  margin-left: 5px;
}

.imgStyle {
  margin-right: 5px;
}

.cardStyle.audience > div {
  position: relative;
}

.errorStyle {
  color: #f44336;
  font-size: 12px;
  margin-top: 3px;
  position: absolute;
  bottom: 3px;
}

.mcqOptions {
  display: flex;
  border-radius: 3px;
  padding: 5px;
  width: 29.5%;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
}

.selected {
  border: 1px solid #456eb2;
  color: #456eb2;
}

.unSelected {
  border: 1px solid black;
}

.optionValue {
  font-weight: 300;
  margin-left: 8px;
}

.phoneInput {
  border: none;
  border-bottom: solid 1px #8c8b8b;
  padding-bottom: 12px;
  padding-top: 10px;
  outline: none;
  border-radius: 0px;
  width: calc(100% - 102px);
  padding-left: 0px;
  margin-left: 102px;
}

.eBorder {
  border-color: red !important;
}

.socialMediaIcons {
  margin-top: 30px;
  margin-right: 24px !important;
}

@media only screen and (orientation: portrait) {
  @media (width: 1024px) {
    .questionStyle.audience {
      font-size: 18px !important;
    }
    .textFieldStyle.audience label {
      font-size: 15px;
    }
    .nameTextFieldStyle.audience label {
      font-size: 15px;
    }
    .nameTextFieldStyle.audience {
      margin-right: 18px !important;
    }
    .subtitle.audience {
      font-size: 12px;
      margin-top: 16px;
    }
    .mcqOptions {
      width: 23%;
      font-size: 15px;
    }
  }
  @media (min-width: 730px) and (max-width: 1023px) {
    .mainStyle {
      max-width: 651px;
    }
    .questionStyle.audience {
      font-size: 18px !important;
    }
    .textFieldStyle.audience label {
      font-size: 15px;
    }
    .nameTextFieldStyle.audience label {
      font-size: 15px;
    }
    .nameTextFieldStyle.audience {
      margin-right: 18px !important;
    }
    .subtitle.audience {
      font-size: 12px;
      margin-top: 16px;
    }
    .mcqOptions {
      width: 23%;
      font-size: 15px;
    }
  }

  @media (min-width: 280px) and (max-width: 729px) {
    .cardStyle.audience {
      padding: 0px;
    }
    .cardStyle.audience > div {
      padding: 10px !important;
    }
    .questionStyle.audience {
      font-size: 14px !important;
    }
    .textFieldStyle.audience {
      width: 95%;
      margin-top: 0px !important;
    }
    .widthClass {
      width: 80% !important;
    }
    .textFieldStyle.audience label {
      font-size: 12px;
    }
    .nameTextFieldStyle.audience {
      width: 45%;
      margin-top: 0px !important;
      margin-right: 10px !important;
    }
    .nameTextFieldStyle.audience label {
      font-size: 12px;
    }
    .subtitle.audience {
      font-size: 10px;
      margin-top: 12px;
    }
    .cardStyle.audience > div {
      padding: 10px 10px 20px 10px !important;
    }
    .errorStyle {
      font-size: 10px !important;
    }
    .imgStyle {
      margin-right: 3px;
      width: 8px;
    }
    .mcqOptions {
      width: 44%;
      font-size: 12px;
    }
    .socialMediaIcons {
      margin-top: 21px;
      margin-right: 10px !important;
      width: 25px;
    }
  }
}

@media only screen and (orientation: landscape) {
  @media (min-width: 280px) and (max-width: 729px) {
    .cardStyle.audience {
      padding: 0px;
    }
    .mainStyle {
      max-width: 651px;
    }
    .questionStyle.audience {
      font-size: 14px !important;
    }
    .textFieldStyle.audience {
      width: 95%;
      margin-top: 0px !important;
    }
    .widthClass {
      width: 80% !important;
    }
    .textFieldStyle.audience label {
      font-size: 12px;
    }
    .nameTextFieldStyle.audience {
      width: 45%;
      margin-top: 0px !important;
      margin-right: 10px !important;
    }
    .nameTextFieldStyle.audience label {
      font-size: 12px;
    }
    .subtitle.audience {
      font-size: 10px;
      margin-top: 12px;
    }
    .mcqOptions {
      width: 26%;
      font-size: 12px;
    }
    .socialMediaIcons {
      margin-top: 21px;
      margin-right: 10px !important;
      width: 25px;
    }
  }
}

.react-tel-input {
  font-family: Graphik;
  width: 66%;
  padding-left: 10px;
  font-weight: 400;
  height: 40px;
  border-style: solid;
  border-color: #e5e5e5;
  box-shadow: none;
  font-size: 1rem;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  padding-left: 88px;
}

.iti__selected-flag {
  background-color: #fff !important;
  width: 96px !important;
  border-bottom: solid 1px #8c8b8b !important;
  margin-top: 1px !important;
}
.iti__selected-flag:before {
  background-color: #fff;
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0px;
  bottom: -2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.iti__flag {
  border-radius: 5px;
}

.iti__selected-flag,
.react-tel-input:focus {
  border-color: #27ace4;
  outline: 0;
  background-color: transparent;
}

.selected-dial-code {
  padding-left: 7px;
  font-size: 15px;
  color: #1e293b;
  pointer-events: none;
}

.selected-flag .arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}

.selected-flag .up,
.selected-flag .down {
  position: relative;
  top: 3px;
  right: 3px;
  font-size: 0px !important;
}

.selected-flag .selected-dial-code {
  padding-left: 0px !important;
}

.selected-flag .iti-flag {
  top: 12px;
  position: relative;
  border-radius: 5px;
}

.selected-flag .up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.selected-flag .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.phone-number-question {
  margin: 20px 0px;
  @include ar {
    left: 0 !important;
    position: relative;
  }
  .phone-form {
    width: 560px;
  }
  .react-tel-input {
    width: 66%;
    padding-left: 10px;
    font-weight: 400;
    height: 40px;
    border-style: solid;
    border-color: #e5e5e5;
    box-shadow: none;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding-left: 88px;
    background-color: transparent;
  }

  .selected-flag {
    background-color: #fff !important;
    width: 75px !important;
    border-bottom: 1px solid;
    top: 2px;
  }

  .selected-flag,
  .react-tel-input:focus {
    border-color: #1e293b;
    outline: 0;
    background-color: transparent;
    position: relative;
  }

  .selected-dial-code {
    padding-left: 7px;
    font-size: 15px;
    color: #1e293b;
    pointer-events: none;
  }

  .selected-flag .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
  }

  .selected-flag .up,
  .selected-flag .down {
    position: relative;
    top: 2px;
    right: 3px;
    font-size: 0px !important;
  }

  .selected-flag .up {
    top: 5px !important;
  }

  .selected-flag .selected-dial-code {
    padding-left: 0px !important;
  }

  .selected-flag .iti-flag {
    top: 10px;
    position: relative;
    border-radius: 5px;
  }

  .selected-flag .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .selected-flag .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  @media (min-width: 280px) and (max-width: 729px) {
    .react-tel-input {
      width: 100%;
    }
  }
}
.scaleBox {
  overflow-x: auto;
  padding: 15px 10px;
  margin-top: 10px;
  .scaleFormControl {
    min-width: 100% !important;
    justify-content: start;
    .scaleContainer {
      display: flex;
      justify-content: space-around;
      gap: 10px;
      .valuesText {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
.section-name-audience {
  padding: 30px;
  background-color: #ffffff;
  border-top: 5px solid #456eb2;
  border-radius: 4px;
  margin-top: 50px;
  color: #000000de;
  font-weight: 700;
  font-size: 18px;
  &:first-child {
    margin: 0;
  }
}
