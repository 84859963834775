@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#packages-quota-wrapper {
  #packages-quota-item-wrapper {
    display: flex;
    .packages-quota-item {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex-grow: 1;
      background: #ffffff;
      border: 1px solid #e1e2e4;
      border-radius: 8px;
      padding: 20px;
      .packages-quota-item-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .packages-quota-item-header-icon-title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;
          .packages-quota-item-header-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f8f8f9;
            width: 56px;
            height: 56px;
            border-radius: 12px;
            > img {
              width: 24px;
            }
          }
          > p {
            font-weight: 500;
            color: #0d0c28;
          }
        }
        .requested {
          color: #3b873e;
          opacity: 1;
        }
      }
      .subscription-type {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
      }
      .one-package-quota-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .one-package-quota-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .one-package-quota-item-title {
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.87);
          }
          .one-package-quota-item-usage {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 5px 10px;
            border-radius: 4px;
            background: #f1f5f9;
            color: rgba(0, 0, 0, 0.6);
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.create-historical-calendar {
  .dateRange,
  .MuiInputBase-formControl,
  input {
    width: 100% !important;
  }

  input {
    padding-inline-start: 12px !important;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
  }
  .MuiInputBase-formControl {
    margin: 0 !important;
  }
  .date-field svg {
    @include en {
      left: auto !important;
      right: 10px;
    }
    @include ar {
      right: auto !important;
      left: 10px;
    }
  }
  .date-field:hover .dateRange .MuiInputBase-formControl fieldset {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }
  .dateRange .MuiInputBase-formControl fieldset {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
}

#table-title-and-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  > .historical-request-table-title {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
  }
}

#create-historical-request-container {
  #historical-request-menu-item-wrapper {
    cursor: pointer;
  }
}

#create-historical-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .steps-counter {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    text-transform: none;
  }
}

.create-historical-request-popup {
  & > .MuiDialogTitle-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.122);
  }
  & > .MuiDialogActions-root {
    padding: 16px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.122);
  }
}

#create-historical-modal-body {
  max-height: 430px;
  padding-top: 10px;

  #historical-step-one,
  #historical-step-two {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .create-historical-select-input {
    > .MuiSelect-select {
      @include ar {
        padding-inline-start: 20px;
      }
    }
  }
  .create-historical-select-input-rendered-value {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
  }

  #create-historical-datasource-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
  }

  .time-filter-create-historical {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .time-fild-create-historical {
      width: 48%;
      .create-historical-timer-icon {
        padding: 0;
      }
    }
  }

  .historical-request-step-one-alert {
    margin: 10px 0px 20px 0px;
  }
}

.drop-down-btn-historical-request {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
  svg {
    @include en {
      margin-right: 5px;
    }
    @include ar {
      margin-left: 5px;
    }
  }
}

.question-stop-request {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.estimated-collected {
  margin-top: 8px;
  padding: 12px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-left: 4px solid #475569;
  background: rgba(248, 250, 252, 0.75);
  color: #475569;
  font-weight: 500;
  @include ar {
    border: 0px;
    border-right: 4px solid #475569;
  }
  .estimated-collected-text {
    font-size: 14px;
  }
  .estimated-collected-number {
    font-size: 16px;
  }
}

.delete-pop-body {
  font-size: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}
.icon-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.req-to {
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.create-historical-popup {
  width: 600px;
  .form-style {
    height: 400px;
    margin-bottom: 20px;
  }
  .time-filter-create-historical {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .time-fild-create-historical {
      width: 48%;
      .create-historical-timer-icon {
        padding: 0;
      }
    }
  }

  .historical-link-subscription {
    font-weight: 600;
    text-decoration: underline;
  }

  .create-historical-label {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 5px;
    &::after {
      content: "*";
      color: #f44336;
      position: relative;
      font-size: 16px;
    }
  }
  .create-historical-select {
    height: 38px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 15px;
    .MuiOutlinedInput-root {
      height: 38px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 15px;
      width: 276px;
    }
    > div.MuiSelect-select.MuiSelect-outlined {
      display: flex;
      align-items: center;
      padding-inline-start: 12px;
      padding-inline-end: 30px;
    }
  }
  .create-historical-select.create-historical-select-date {
    .MuiInputAdornment-root {
      margin: 0;
      @include ar {
        margin-inline-start: 12px;
      }
    }
    div.MuiSelect-select.MuiSelect-outlined {
      padding: 0;
      padding-inline-start: 8px;
    }
  }
  .create-historical-input {
    margin-bottom: 0;
    input {
      padding-inline-start: 17px;
    }
  }
}

p#post-limit-msg-content {
  margin: 10px 0 15px 0;
}
.remaining-posts {
  span,
  b {
    font-weight: 400;
    font-size: 16px;
    color: #000000de;
  }
  b {
    font-weight: 600;
  }
  margin-bottom: 15px;
}
.step-span {
  position: absolute;
  top: 20px;
  font-size: 14px;
  line-height: 24px;
  color: #00000099;
  font-weight: 400;
  @include en {
    right: 25px;
  }
  @include ar {
    left: 25px;
  }
}

.select-icon {
  width: 30px;
  position: relative;
  top: 3px;
  @include en {
    margin-right: 5px;
    float: left;
  }
  @include ar {
    margin-left: 5px;
    float: right;
  }
}

.single-tweet-input {
  padding: 0px 12px;
  width: 552px;
  input {
    border-radius: 4px;
    height: 32px;
    padding: 6px 12px !important;
  }
}
.single-tweet-loader-btn {
  padding: 8px 12px;
  width: 39px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-radius: 2px;
}
.single-tweet-quota {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  span {
    width: 30px !important;
    height: 30px !important;
  }
}
.single-tweet-note {
  strong {
    color: #456eb2;
  }
  margin: 8px 0;
}
.post-publish-date {
  font-weight: 400;
  font-size: 12px;
  color: #9b9b9b;
  margin-bottom: 5px;
  display: flex;
  .single-date {
    margin: 0 5px;
    @include ar {
      direction: ltr;
      text-align: end;
    }
  }
}
.box-statistics-historical {
  padding: 12px 16px;
  border-left: 4px solid #475569;
  background-color: rgba(248, 250, 252, 0.75);
  color: #475569;
  font-size: 14px;
  margin-top: 10px;

  @include ar {
    border: 0px;
    border-right: 4px solid #475569;
  }
  .statistics-historical-header {
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: max-content;
    margin: 0;
    padding-bottom: 1px;
  }

  ul {
    margin: 0;
    li {
      &:first-of-type {
        border-bottom: 1px solid #e2e8f0;
      }
    }
  }

  .item-statistics_historical {
    padding: 11px 11px 11px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    font-weight: 500;
    line-height: normal;
  }
}
.estimated-data-twitter {
  margin-top: 20px;
  padding: 12px;
  border-left: 4px solid var(--neutral-600, #475569);
  background: rgba(248, 250, 252, 0.75);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #475569;
  @include ar {
    border: 0px;
    border-right: 4px solid #475569;
  }
}
.snack-historical-link {
  color: #456eb2;
  font-weight: 400;
  text-decoration: underline;
}

#historical-request-menu-item-wrapper {
  cursor: pointer;
  > .disabled-grey-item {
    filter: grayscale(1);
  }
}

#monitor-options-hr-item-tooltip {
  width: 160px;
  font-size: 12px;
  line-height: 18px;
  #historical-request-menu-item-wrapper {
    cursor: pointer;
  }
  > div {
    padding: 13px;
  }
}

h6#historical-count-validation-msg {
  color: #f44336;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

#historical-request-snackbar-table-link {
  color: #456eb2;
  text-decoration: underline;
}
