@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.header-table {
  .search-box {
    position: relative;
    height: 40px;
    width: 257px;
    margin-inline-start: 12px;
    .search-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      font-size: 18px;
    }
    .search-icon-container {
      position: absolute;
      top: 1%;
      width: 44px;
      height: 39px;
      background-color: #7f7f7f;
      border-style: none;
      cursor: pointer;
    }
    .search-icon-container-blue {
      background-color: #456eb2;
    }
    @include ar {
      .search-icon-container {
        border-radius: 3px 0px 0px 3px;
        right: 82.8%;
      }
      .search-icon-position {
        right: 71.6%;
      }
      .search-icon {
        left: 13px;
      }
    }
    @include en {
      .search-icon-container {
        border-radius: 0px 3px 3px 0px;
        left: 82.8%;
      }
      .search-icon-position {
        left: 71.6%;
      }
      .search-icon {
        right: 13px;
      }
    }
    .search-field {
      width: 100%;
      height: 100%;

      @include ar {
        label {
          transform: translate(130px, 10px) scale(1);

          &.Mui-focused,
          &.MuiFormLabel-filled {
            transform: translate(160px, -9px) scale(0.75);
            background: #f8fafc;
            padding: 0 8px;
          }
        }
        fieldset {
          legend {
            span {
              display: none;
            }
          }
        }
      }
      @include en {
        label {
          transform: translate(14px, 10px) scale(1);
          &.Mui-focused,
          &.MuiFormLabel-filled {
            transform: translate(14px, -9px) scale(0.75);
          }
        }
      }
      .MuiInputBase-formControl {
        &:focus {
          fieldset {
            border-color: #456eb2;
          }
        }
      }
      input {
        padding: 8.5px;
      }
    }
    .search-field-dashboard {
      width: 100%;
      height: 100%;

      @include ar {
        label {
          transform: translate(110px, 10px) scale(1);
          left: 50px;

          &.Mui-focused,
          &.MuiFormLabel-filled {
            transform: translate(135px, -9px) scale(0.75);
            background: #f8fafc;
            padding: 0 8px;
            color: #456eb2;
          }
        }
        fieldset {
          legend {
            span {
              display: none;
            }
          }
        }
      }
      @include en {
        label {
          transform: translate(14px, 10px) scale(1);
          &.Mui-focused,
          &.MuiFormLabel-filled {
            transform: translate(14px, -9px) scale(0.75);
          }
        }
      }
      .MuiInputBase-formControl {
        &:hover {
          fieldset {
            border-color: #456eb2;
          }
        }
      }
      input {
        padding: 8.5px;
      }
    }
    .search-field-width {
      width: 228px;
      @include ar {
        label {
          left: 22px;
        }
      }
    }
  }

  .quick-search-btn.MuiButton-root {
    margin-inline: 20px;
  }

  .search-desblay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline-start: -8px;
  }
}
.search-company {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .search-input-validation-error {
    color: #f44336;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 10px 16px;
    width: 330px;
    &.manage-channels {
      padding: 10px 0px;
    }
  }
}
[aria-labelledby="controlled-company-select-label"] {
  max-height: 260px;
}
.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight.alert_expired_wrapper {
  @include en {
    left: auto;
    right: 24px;
  }
  @include ar {
    right: auto;
    left: 24px;
  }
}
