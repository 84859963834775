@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.profile-dropdown-box {
  min-width: 247px;
  max-width: 247px;
  padding: 20px;
  .profile-dropdown-heading {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .profile-dropdown-img {
    img {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      border: 0.5px solid #e2e8f0;
    }
  }
  .profile-dropdown-text {
    display: flex;
    flex-direction: column;
    .profile-sub-name {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: 0.15px;
      color: #00000099;
    }
  }

  .profile-name {
    color: #0f172a;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    @include ar {
      text-align: right;
    }
    &.arabic-name {
      direction: rtl;
      text-align: left;
      @include ar {
        text-align: right;
      }
    }
  }
  .profile-user-name {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0 0 0;
  }
  .profile-dropdown-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .content-box {
      flex-grow: 1;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
    }
    .title-box {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .value-box {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 500;
    }
    .show-total {
      text-decoration: underline;
      color: #456eb2;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
.total-tooltip {
  max-width: 240px;
  display: inline-block;
}
.profile-dropdown-loading {
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-card-loader {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .profile-card-heading {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .card-lines {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiSkeleton-pulse.MuiSkeleton-text {
    border-radius: 15px;
  }
}

.engagement-profile-dropdown-name-tooltip {
  bottom: -10px;
}
