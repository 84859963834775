.explore-matching-wrapper {
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  margin-top: 30px;
}
.explore-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #1a174f;
  position: relative;
  top: 250px;
}
.explore-wait-container {
  color: #1a174f;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  margin-top: 10px;
  text-align: center;
}
