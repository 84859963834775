@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.import-topics-window {
  width: 600px;
  padding-bottom: 15px;
  #alert-dialog-title {
    margin-bottom: 10px;
  }
}

.import-topics-actions {
  margin-right: 16px;
}

#import-platform-simple-select,
#import-menu-items-placeholder,
#import-platform-topics-select {
  color: #000000de;
  font-size: 14px;
  margin-bottom: -5px;
  padding: 14px 14px;
  display: flex;
  @include ar {
    font-family: "Dubai" !important;
  }
}
#import-platform-simple-select {
  .icons-before-monitors-list {
    display: none;
  }

  .topic-monitor-names-list {
    margin-left: 0px !important;
    margin-top: 0px !important;
    @include ar {
      margin-right: 0 !important;
    }
  }
}

.import-select-box {
  font-size: 8px;
  margin-top: 6px;
}
#import-body {
  max-width: 100%;
  margin-top: -5px;
}

#import-body-input-label {
  color: #000000de;
  font-size: 15px;
  font-weight: 400;
  margin-top: 25px;
}

.selected-counter-box {
  flex: auto;
  font-size: 13px;
  color: #80868c;
  @include en {
    margin-left: 22px;
  }
  @include ar {
    margin-right: 22px;
  }
}
#chip-style-inital,
#chip-style-selected {
  background-color: white;
  margin-right: 5px;
  margin-top: 12px;
  max-width: 370px;
  height: auto;
  line-height: 15px;
  padding-top: 6px;
  padding-bottom: 5px;
  font-size: 14px;
  span {
    white-space: break-spaces;
  }

  p {
    line-height: 15px;
  }

  svg {
    @include ar {
      left: 10px;
      position: relative;
    }
  }

  span {
    direction: initial;
  }
}

#chip-style-inital {
  color: #80868c;
  border: 1px solid #e2e8f0;
  svg {
    color: #80868c;
  }

  span {
    color: #80868c;
  }
}

#chip-style-selected {
  color: #456eb2;
  border: 1px solid #456eb2;
  span {
    color: #456eb2;
  }
}
.icons-before-monitors-list {
  margin-top: -7px;
  width: 20px;
  height: 20px;
  display: contents;
  @include en {
    padding-right: 5px;
  }
  @include ar {
    padding-left: 5px;
  }
}
.topic-monitor-names-list {
  margin-left: 10px;
  margin-top: 7px;
  @include ar {
    margin-right: 5px;
  }
}

.insta-social-icon {
  width: 24px !important;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.import-body-input-manage {
  margin-bottom: 20px;
}
.import-body-input {
  .MuiOutlinedInput-root {
    @include ar {
      .MuiSvgIcon-root {
        position: absolute;
        right: 515px;
      }
    }
  }
}
.manage-topics-remove-label {
  margin-bottom: 20px;
}
.MuiSelect-icon {
  @include en {
    right: 7px;
  }
  @include ar {
    left: 14px;
    right: auto;
  }
}
