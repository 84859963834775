@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.full-screen-loader {
  position: fixed;
  z-index: 9999999;
  background: rgba(255, 255, 255, 0.9);
  height: 100vh;
  width: 100%;
  left: 0;
  right: 0;
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }
}
.logout-btn {
  width: 100%;
}
.common-snackbar {
  margin-top: 50px !important;

  @include en {
    direction: "ltr" !important;
  }
  @include ar {
    direction: "rtl" !important;
  }
  .MuiAlert-action {
    @include ar {
      padding: 4px 0 0 0px !important;
      margin-right: 20px !important;
    }
  }
  .MuiAlert-icon {
    @include ar {
      padding: 4px 0px 0px 7px !important;
    }
  }
}

.truncated-title-wrapper {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: nowrap;
  direction: ltr;
}
