@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.accounts-list-tilte-header-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  .accounts-list-page-header {
    margin-bottom: 0px;
  }
  .account-list-page-header-sub-title {
    font-size: 16px;
    color: #646464;
    margin-bottom: 8px;
    margin-top: 0px;
    text-align: left;
  }
}
.accounts-list-list-search-field {
  width: 280px;

  input {
    padding: 9px 12px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  button {
    background: #7f7f7f;
    width: 43px;
    z-index: 1;
    height: 40px;
    &:hover {
      background-color: #7f7f7f;
    }
    @include ar {
      border-radius: 4px 0px 0px 4px;
      margin-left: -8px;
      margin-right: 0;
    }
    @include en {
      border-radius: 0px 4px 4px 0px;
      margin-right: -14px;
      margin-left: 0;
    }
    svg {
      width: 18px;
      height: 18px;
      color: #ffffff;
    }
  }
  .Mui-focused {
    button {
      background: #456eb2;
    }
  }
  .accounts-list-search-error-msg-container {
    .accounts-list-search-error-msg {
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.accounts-list-name-container {
  display: flex;
  p {
    font-size: 14px;
  }
  .MuiTypography-root,
  .MuiButton-root {
    font-size: 14px;
    font-weight: 400;
  }
  .MuiButton-root {
    &:hover {
      background-color: transparent !important;
    }
  }
  .accounts-list-title {
    padding: 0;
    margin: 0;
    border-radius: 0;
  }
}

.accounts-lists-table {
  margin-top: 30px;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 40px;

  .list-name,
  .created-by {
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .created-by-deleted-user {
      display: block;
    }
  }

  .username-span {
    @include ar {
      direction: ltr;
      margin-left: 5px;
    }
    @include en {
      margin-right: 5px;
    }
  }

  .username-span-num {
    display: inline-block;
    @include ar {
      direction: ltr;
      margin-left: 5px;
    }
    @include en {
      margin-right: 5px;
    }
  }

  .time-span {
    display: block;
    color: #475569;
  }
  .date-span {
    display: block;
  }
}

.accounts-list-action-dropdown {
  .MuiPaper-root {
    @include ar {
      transform: translate(55px, -4px) !important;
    }
    border-radius: 6px;
    margin-top: 8px;
    color: rgb(55, 65, 81);
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    .MuiMenu-list {
      padding: 4px 0;
    }
    .MuiMenuItem-root {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.87);
      line-height: 24px;
      letter-spacing: 0.15px;
      &:hover {
        background-color: #fafafa;
      }
      .MuiSvgIcon-root {
        font-size: 18px;
        color: #212121;
        margin-right: 12px;
        margin-left: 0;
        @include ar {
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }
  }
}
.accountlist-main-box {
  #title-button-box {
    min-width: fit-content;
  }
}
