@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
#search-engag-tooltip {
  margin-inline-start: -30px !important;
}
.heading-wrapper {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  @include en {
    padding: 5px 20px 5px 5px;
  }
  @include ar {
    padding: 2px 5px 4px 20px;
  }
  &.inbox-header {
    @include en {
      padding: 0;
    }
    @include ar {
      padding: 0;
    }
  }
  &.inbox-main-header {
    position: fixed;
    top: 60px;
    right: 0;
    left: 0;
    background-color: #fff;
    height: 60px;
    box-sizing: border-box;
    &.inbox-header {
      gap: 0;
    }
    .tabs-divider {
      background-color: #e2e8f0;
      height: 18px;
      width: 1px;
    }
    .engagement-navigation-header {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 357.6px;
      min-width: 357.6px;
      transition: all 0.1s ease-in;
      box-sizing: border-box;

      &.sidebar-menu-open {
        width: 512.6px;
        min-width: 512.6px;
        #engagements-filter-btn {
          .choosed-filter {
            color: #456eb2;
          }
        }
      }
      &:not(.sidebar-menu-open) {
        #engagements-filter-btn {
          height: 32px !important;
          width: 32px;
          .MuiButton-icon {
            margin: 0;
            .choosed-filter {
              color: #456eb2;
            }
          }
          @include ar {
            direction: ltr;
          }
        }
      }

      .filter-date-divider {
        visibility: visible;
        width: 1px;
        height: 18px;
        background-color: #e2e8f0;
      }

      .engagement-header-custom-date-picker {
        .MuiInputBase-root {
          width: 140px;
        }

        &.custom-date {
          &.custom-date-wide {
            .MuiInputBase-root {
              width: 205px;
            }
          }
        }
        svg {
          color: #64748b;
        }
        .MuiInputBase-formControl {
          border: none;
          color: #64748b;
          font-weight: 500;
          // transition: all 0.1s ease-in-out;
          &:hover {
            background-color: #f1f5f9;
          }
        }
      }

      @include en {
        border-right: 1px solid #e2e8f0;
        padding: 5px 20px 5px 5px;
      }
      @include ar {
        border-left: 1px solid #e2e8f0;
        padding: 5px 5px 5px 20px;
      }
      &-buttons {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .engagement-navigation-header-tabs {
      &-container {
        flex: 1;
        justify-content: space-between;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 70px;
        background: #f8fafc;
        height: 100%;
        &.with-settings {
          grid-template-columns: 1fr 116px;
        }
      }
      &-adjacent {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 1px;
        background: linear-gradient(
          270deg,
          #ffffff 70%,
          rgba(255, 255, 255, 0) 100%
        );
        .adjacent-btn {
          svg {
            width: 18px;
            height: 18px;
            color: #475569;
          }
        }
      }
    }
  }

  .engagement-heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    color: #0f172a;
    margin: 0;
  }
  .engagement-settings-icon {
    color: #64748b;
    padding: 8px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid #cbd5e1;
    &:hover {
      background-color: #f1f5f9;
    }
    &.publish-menu {
      color: #757575;
      padding: 7px;
      cursor: pointer;
      width: 24px;
      height: 27px;
      border: none;
      &:hover {
        background-color: #fff;
      }
    }
  }
}
.main-eng-header {
  .search-date-picker-container {
    padding: 14.5px 15px;
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    border-bottom: 1px solid #e2e8f0;
  }
  .search-paragraph {
    color: #00000099;
    font-size: 11px;
    font-weight: 400;
    margin-top: 7px;
  }
  .search-container {
    display: flex;
    align-items: center;
    svg {
      color: #64748b;
      &.is-focused {
        color: #456eb2;
      }
      font-size: 14px;
      cursor: pointer;
    }
    .search-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline-start: 5px;
      img {
        cursor: pointer;
        padding: 10px 10.5px;
        &:hover {
          border-radius: 2px;
          background-color: #f1f5f9;
        }
      }
      svg {
        transform: rotate(0deg) !important;
        font-size: 20px;
        font-weight: 900;
        padding: 7.5px;
        &:hover {
          border-radius: 2px;
          background-color: #f1f5f9;
          color: #456eb2;
        }
      }
      hr {
        height: 20px;
        margin-inline-start: 0px;
        margin: 0 5px;
      }
    }
    .MuiTextField-root {
      .MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
        height: 35px;
        padding: 0px;

        input {
          padding: 0;
          &::placeholder {
            color: #00000061;
            font-size: 14px;
            font-weight: 400;
          }
        }
        fieldset {
          border: none;
          &:focus,
          &:hover {
            border: none;
          }
        }
        button {
          color: #456eb2;
          font-size: 14px;
          font-weight: 500;
          padding: 4px 0;
          &:hover {
            background-color: inherit;
          }
        }
      }
    }
    .icon-container {
      width: 42px;
      height: 36px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        font-size: 22px;
        padding: 7.5px;
      }
      &:hover {
        background-color: #f1f5f9;
        svg {
          color: #456eb2;
        }
        img {
          filter: invert(32%) sepia(55%) saturate(630%) hue-rotate(178deg)
            brightness(70%) contrast(220%);
        }
      }
      &.active {
        border-color: #f1f5f9;
        svg {
          color: #456eb2;
        }
        img {
          filter: invert(32%) sepia(55%) saturate(630%) hue-rotate(178deg)
            brightness(70%) contrast(220%);
        }
      }
      &.main-sort-by {
        position: relative;
        width: auto;
        height: 36px;
        .sort-tooltip-wrapper {
          padding: 0 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        svg {
          padding: 0;
        }
        .sort-by-icon-wrapper-stack {
          span {
            font-size: 13px;
            font-weight: 600;
            color: rgba(69, 110, 178, 1);
          }
        }
        &.opened {
          background-color: #f1f5f9;
        }
        .sort-items-container {
          position: absolute;
          background-color: #ffff;
          z-index: 5;
          left: 0;
          top: 38px;
          display: flex;
          border-radius: 4px;
          flex-direction: column;
          color: #000000de;
          font-size: 14px;
          font-weight: 400;
          box-shadow: 0px 8px 10px 2px #00000024;
          width: max-content;
          justify-content: space-between;
          padding: 6.4px 0;
          @include ar {
            right: 0;
            left: unset;
          }
          .sort-item {
            width: 240px;
            height: 16.2px;
            padding: 14px 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
              background-color: #f8fafc;
            }
            svg {
              color: rgba(0, 0, 0, 0.87);
            }
            .sort-item-title {
              font-size: 14px;
              font-weight: 500;
            }
            .sort-item-icon-wrapper {
              width: 57px;
              height: 20px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              svg {
                padding: 0;
              }
            }
            .sort-new {
              color: #456eb2;
              background-color: #e8eaf3;
              margin: 0 0.2rem;
              padding: 0.1rem 0.2rem;
              border-radius: 3px;
            }
          }
          .sort-item.selected {
            border: none;
            border-bottom: 1px solid rgba(241, 245, 249, 1);
            .sort-item-title {
              color: #456eb2;
              font-weight: 600;
            }
            svg {
              color: rgba(69, 110, 178, 1);
            }
          }
          .sort-items-category {
            cursor: default;
            color: #00000061;
            &:hover {
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  .dateRange {
    fieldset {
      border: none;
      box-shadow: none;
    }
    .MuiInputBase-colorPrimary.MuiInputBase-formControl {
      border: 1px solid #cbd5e1;
      height: 36px;
      width: 146px;
      margin: 0;
      font-size: 14px;
      border-radius: 2px;
      input {
        width: 100%;
      }
    }
  }
  #date-range-picker-id {
    margin-top: 5px;
    svg {
      top: 44%;
      left: 10px;
      @include ar {
        right: 7% !important;
      }
    }
  }
  .filters-icons {
    display: flex;
    color: #00000069;
    gap: 5px;
  }
}
.engagement-custom-date-picker {
  .main-date-list-container {
    flex-direction: row-reverse;
  }
}
.engagement-filter-count {
  font-weight: 700;
  color: #456eb2;
  margin-inline-start: 3px;
}

.heading-refrsh {
  display: flex;
  gap: 5px;
}
.refresh-setting-container {
  display: flex;
  gap: 10px;
  align-items: center;
  .header-divider {
    background-color: #94a3b8;
    height: 19px;
    margin: 0 5px !important;
    width: 1px;
  }
}
.refrsh-icon {
  font-size: 21px !important;
  padding: 7.5px;
  cursor: pointer;
  color: #64748b !important;
  border-radius: 2px;
  &:hover {
    background-color: #f1f5f9;
    color: #456eb2;
  }
  &.publish-menu {
    border: 0.7px solid #fff;
    font-size: 23px !important;
    color: #757575;
    &:hover {
      border: 0.7px solid #0000003b;
      background-color: rgba(0, 0, 0, 0.04);
      color: #000000de !important;
    }
  }
}
.refresh-icon-container {
  position: relative;
  right: 5px;
  top: 2px;
  width: 33px;
  height: 33px;
  &:hover {
    border: 0.7px solid #0000003b;
  }
}

// define a mixin for the user-source icon
@mixin user-source-icon {
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  color: #ffffff;
  box-sizing: border-box;
  &.whatsapp-icon {
    bottom: -1px;
    inset-inline-end: 0px;
  }
  img {
    width: 10px;
    height: 10px;
  }
  &.facebook-icon {
    background-color: #1877f2;
  }
  &.twitter-icon {
    background-color: #000000;
  }
  &.instagram-icon {
    background: -webkit-radial-gradient(
      30% 107%,
      circle,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
  }
}

.engagement-tabs {
  &-root {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  &.hidden-tabs {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    height: 100%;
  }
  .custom-tabs-divider {
    max-width: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 8px;
    &.prev-divider {
      visibility: hidden !important;
    }
    &.next-divider {
      visibility: hidden !important;
    }
    .engagement-tab-divider {
      height: 18px;
      background: #e2e8f0;
      width: 1px;
    }
  }
  &-menu {
    width: 55px;
    height: 59px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover,
    &.active-menu {
      .engagement-tabs-menu-button {
        background: #e6f3fc;
      }
      & + .engagement-tabs-list-divider {
        visibility: hidden;
      }
    }
    @include ar {
      padding-left: 6px;
    }
    @include en {
      padding-right: 6px;
    }
    &-button {
      width: 55px;
      height: 32px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      gap: 10px;
      border-radius: 6px 6px 4px 4px;
      border: 1px 1px 0px 1px;
    }

    &-counter {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.8px;
      color: #00000099;
    }

    svg {
      width: 9px;
      height: 16px;
      color: #475569;
    }
  }
  &-list {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    flex: 1;
    @include en {
      padding-left: 15px;
    }
    @include ar {
      padding-right: 15px;
    }
    &-divider {
      width: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      .engagement-tab-divider {
        width: 1px;
        height: 18px;
        background: #e2e8f0;
      }
    }
    &-item {
      height: 61px;
      max-width: 160px;
      min-width: 120px;
      width: 100%;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      cursor: pointer;
      &:hover {
        .engagement-tabs-list-item-box {
          background: #e6f3fc;
          border-radius: 6px 6px 4px 4px;
          &.active-box {
            border-radius: 6px 6px 0px 0px;
          }
        }
      }
      .engagement-tabs-list-item-curve-left {
        position: absolute;
        bottom: 0;
        width: 13px;
        height: 13px;
        left: -12px;
        z-index: 1;
        display: none;
      }
      .engagement-tabs-list-item-curve-right {
        position: absolute;
        bottom: 0;
        width: 13px;
        height: 13px;
        right: -12px;
        z-index: 1;
        display: none;
      }

      &.active-tab {
        z-index: 4;

        div.engagement-tabs-list-item-box.active-box {
          background-color: #fff;
        }
        .engagement-tabs-list-item-curve-left,
        .engagement-tabs-list-item-curve-right {
          display: block;
        }
      }

      &-box {
        height: 44px;
        flex: 1;
        border-radius: 6px 6px 0px 0px;
        box-sizing: border-box;
        grid-template-columns: 18px 1fr 24px;
        display: grid;
        align-items: center;
        justify-content: space-between;
        position: relative;
        gap: 6px;
        padding: 0 10px;

        &-bottom {
          display: none;
        }
        &.active-box {
          background: white;
          border-width: 1px 1px 0px 1px;
          border-style: solid;
          border-color: #e2e8f0;
          border-radius: 6px 6px 0px 0px;
          box-shadow: 0px -4px 84px 0px #3d3bb040;
          .engagement-tabs-list-item-box-bottom {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 9px;
            background: white;
            display: block;
          }
        }
        &-name {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.8px;
          color: #00000099;
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: clip;
        }
        &-close {
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          border-radius: 50%;
          cursor: pointer;
          &:hover {
            background: #f1f5f9;
          }

          svg {
            width: 9px;
            height: 16px;
            color: #475569;
          }
        }
      }
    }
    .user-source {
      @include user-source-icon;
    }
  }
}

.engagement-tabs-menu-list-paper {
  &.MuiPaper-root {
    width: 290px;
    max-height: calc(100vh - 150px);
  }
  .MuiList-root {
    padding: 0;
  }
  .engagement-tabs {
    &-menu-item {
      padding: 10px 16px 10px 16px;
      gap: 10px;
      display: grid;
      grid-template-columns: 18px 1fr 24px;
      align-items: center;
      box-sizing: border-box;
      &:hover {
        .engagement-tabs-menu-item-close {
          visibility: visible;
        }
      }
      &-close {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        visibility: hidden;
        &:hover {
          background: #2c303b0d;
        }
        svg {
          width: 9px;
          height: 16px;
          color: #475569;
        }
      }
      &-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #000000de;
      }
      &-icon {
        .user-source {
          @include user-source-icon;
        }
      }
    }
  }
}

div.engagement-tabs-list-item-tooltip {
  top: -16px;
  padding: 13px;
  &.menu-item-tooltip {
    top: 0;
    @include en {
      left: 15px;
    }
    @include ar {
      right: 15px;
    }
  }
  &-menu {
    top: -16px;
    padding: 8px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: #ffffff;
    border-bottom: 1px solid #8c8c8c;
    padding-bottom: 11px;
  }
  &-viewed {
    display: flex;
    align-items: center;
    gap: 6px;
    span {
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }
    svg {
      color: #fff;
      width: 12px;
      height: 12px;
      @include ar {
        transform: rotate(270deg);
      }
      path {
        stroke: #fff !important;
      }
    }
  }
  &-info {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #ffffff;
  }
}
