@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.accounts-list-import-pop-up-container {
  margin-top: 70px;
  div.MuiPaper-root.MuiPaper-elevation {
    max-height: fit-content;
  }
  .accounts-list-import-pop-up {
    width: 600px;
    min-width: 600px;
    overflow: hidden;
    #accounts-list-import-dialog-title {
      font-weight: 500;
      font-size: 20px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
      line-height: 32px;
      padding: 24px 24px 15px;
    }
    .custom-alert-position {
      /* Example: Move the alert icon to the right */
      display: flex;
      align-items: center;
    }

    .custom-alert-position .MuiAlert-icon {
      padding-inline-end: 12px;
      margin: 0;
    }
    div.MuiAlert-message {
      font-size: 14px;
      font-weight: 400;
      color: rgba(13, 60, 97, 1);
    }
    #import-accounts-alert-msg {
      font-weight: 400;
      margin-bottom: 20px;
      line-height: 20.02px;
      letter-spacing: 0.15px;
    }

    #dialog-content-import-popup.MuiDialogContent-root {
      padding-top: 10px;
      padding-bottom: 15px;
      &.dialog-content-import-uploaded {
        height: fit-content;
        overflow-y: auto;
      }
      .loading-dialog-import-popup {
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .accounts-list-import-desc {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }
    .upload-file-title,
    .download-file-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.87);
    }
    .accounts-list-upload-file-container {
      margin-top: 20px;
      height: fit-content;
    }
    .upload-file-card {
      height: 121px;
      margin-top: 10px;
      box-sizing: border-box;
      align-items: center;
      gap: 5px;
      background: #f8fafc;
      border: 2px dashed rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      &:hover {
        border: 2px dashed #64748b;
        cursor: pointer;
      }
    }
    .upload-error-message {
      border-color: #f44336;
    }
    .upload-file-data {
      text-align: center;
      padding: 25px 20px;
    }
    .import-upload-icon {
      color: #64748b;
      font-size: 35px;
    }
    .import-browse-file {
      cursor: pointer;
      color: rgba(69, 110, 178, 1);
      font-size: 14px;
      font-weight: 400;
    }
    .import-cancel-pop-up {
      border: none;
      color: rgba(0, 0, 0, 0.6);
      margin-inline: 5px;
      &:hover {
        border: none;
      }
    }

    .upload-accepted-formats-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.15px;
      color: rgba(65, 64, 64, 0.87);
      margin-top: 4px;
      margin-bottom: -8px;
    }
    .import-divider {
      border: 1px solid #cbd5e1;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .enable-upload-box {
      @include ar {
        margin-right: -14px;
      }
      .MuiTypography-root {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    .upload-file-header {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 400;
      font-size: 14px;
      margin-inline-end: 3px;
    }
    .download-file-card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      padding: 0;
      padding-inline-start: 12px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      margin-top: 10px;
    }
    .import-download-icon {
      padding: 8px 12px;
      border-radius: 2px;
      &:hover {
        background-color: #f1f5f9;
      }
      svg {
        color: #00000099;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .download-file-header,
    .excel-file-name-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);
    }
    .manage-import-file-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      color: #456eb2;
    }
    .import-action-container {
      display: flex;
      justify-content: space-between;
      padding-inline: 20px;
    }
    .excel-file-container {
      background: #f8fafc;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      padding: 16px;
      margin-top: 15px;
    }
    .excel-file-container {
      display: flex;
      justify-content: space-between;
    }
    .upload-file-progress {
      font-weight: 400;
      font-size: 12px;
      line-height: 17.16px;
      color: #80868c;
      @include ar {
        text-align: right;
      }
    }
    .close-upload-file {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border: 1px solid rgba(244, 67, 54, 0.3);
      border-radius: 2px;
      &:hover {
        background: rgba(244, 67, 54, 0.1);
      }
      cursor: pointer;
      svg {
        color: #f44336;
        font-weight: 400;
        line-height: 26px;
        font-size: 18px;
        cursor: pointer;
      }
    }
    .drop-file-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .excel-file-content {
      display: flex;
      justify-content: center;
    }
    .uploaded-file-content {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      margin-inline-start: 15px;
    }
    .excel-file-icon {
      width: 35px;
    }
    .upload-file-error-msg {
      display: flex;
      flex-direction: column;
      @include en {
        align-items: flex-start;
        text-align: left;
      }

      @include ar {
        align-items: flex-start;
        text-align: right;
      }

      letter-spacing: 0.4px;
      .upload-file-error-msg-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
      }
    }
    .download-icon-fail {
      pointer-events: none;
    }
  }
}
