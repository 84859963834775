@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.main-themes {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 10px;
  .space {
    padding-inline-end: 5px;
  }
  .empty {
    width: 90px;
  }
  .subtheme {
    font-weight: 400;
    font-size: 14px;
    margin-inline-start: 0px;
  }
  .theme {
    font-weight: 500;
    font-size: 12px;
    padding: 0;
    margin: 0;
  }
}

.interaction-list__footer {
  @include ar {
    .white {
      margin-right: 0;
    }
  }
}

.white {
  background-color: #fff;
}

.divider {
  color: #cbd5e1;
  width: 1px;
  height: 20px;
  margin: 0px 10px;
}

.font {
  font-weight: 500;
}
