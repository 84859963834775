.preview {
  text-align: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  height: 50px;
}

.preview .arrowIcon {
  position: absolute;
  left: 0;
  margin-left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.preview span {
  position: relative;
  top: 30%;
  font-size: 18px;
  color: #0c192a;
}

.footerPagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 40px;
}
.footerPagination .ArrowBackIcon {
  font-size: 15px !important;
}
.footerPagination .boxBack {
  width: 70px;
}
.footerPagination .stackProgress {
  width: 200px;
  height: 10px;
  border-radius: 4px;
  background-color: #94a3b8;
}
span.linearProgress {
  height: 10px;
  border-radius: 4px;
  z-index: 1;
}
.mainStyle {
  max-width: 890px;

  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.footerStyle {
  display: flex;
  align-items: center;
  margin-top: 62px;
  margin-bottom: 50px;
  justify-content: space-between;
  flex-direction: column;
  gap: 105px;
}

.footerFontStyle {
  color: #0c192a;
  font-size: 18px;
  line-height: 22px;
  margin-right: 5px;
  font-weight: 400;
}

.cardStyle {
  width: 100% !important;
  margin: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  margin-top: 20px;
  padding: 14px;
  padding-bottom: 6px;
  font-family: Graphik !important;
  overflow: visible !important;
}

.dropdownStyle {
  min-width: 66% !important;
  margin-top: 10px !important;
}

.scaleBox {
  overflow-x: auto;
  padding: 15px 10px;
  margin-top: 10px;
}
.scaleFormControl {
  min-width: 100% !important;
  justify-content: start;
}
.scaleContainer {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}
.valuesText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.errorBorder {
  border: 1.3px solid #e31b0c;
}

.noError {
  border: 1px solid #cbd5e1;
}

.buttonStyle {
  margin: auto;
  margin-top: 30px;
  text-align: right;
  width: 100%;
  margin-bottom: 30px;
}

.questionStyle {
  font-size: 22px !important;
  font-family: Graphik !important;
  display: flex;
  align-items: center;
  gap: 5px;
}

.subtitle {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-style: italic;
  margin-top: 25px;
}

.cardStyle > div {
  position: relative;
}

.textFieldStyle {
  width: 66%;
  margin-top: 17.5px !important;
}

.nameTextFieldStyle {
  width: 31.1%;
  margin-top: 17.5px !important;
  margin-right: 25px !important;
}

.textFieldStyle label {
  color: #80868c !important;
}

.widthClass label {
  color: rgba(0, 0, 0, 0.38) !important;
  font-family: Roboto;
}

.nameTextFieldStyle label {
  color: #80868c !important;
}

.impQuestion {
  color: red;
  margin-left: 5px;
}

.imgStyle {
  margin-right: 5px;
  width: 34px;
  height: 34px;
}

.cardStyle > div {
  position: relative;
}

.errorStyle {
  color: #f44336;
  font-size: 12px !important;
  margin-top: 3px !important;
  position: absolute;
  bottom: 3px;
}

.mcqOptions {
  display: flex;
  border-radius: 3px;
  padding: 5px;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
}
.mcqOptions.mcqOptionOther {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.mcqOptions.mcqOptionOther .optionValue {
  margin: 0;
}
.mcqOptions.mcqOptionOther label {
  margin: 0;
  margin-left: 0px;
}
.selected {
  border: 1px solid #456eb2;
  color: #456eb2;
}
.radioGroupBox label {
  margin: 0;
  margin-left: 0px;
  margin-bottom: 10px;
}
.unSelected {
  border: 1px solid black;
}
.checkBoxRadioBtnBox {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}
.checkBoxRadioBtnBox label span {
  margin: 0;
}
.selected.mcqOptions {
  color: #456eb2;
  border: none !important;
}

.unSelected.unSelected {
  border: none !important;
}
.selected.mcqselected {
  border: none !important;
  color: #456eb2;
}
.unSelected.mcqunSelected {
  border: none;
}
.optionValue {
  font-weight: 300;
  margin-left: 8px;
  overflow: hidden;
}

.phoneInput {
  border: none !important;
  border-bottom: solid 1px #8c8b8b !important;
  padding-bottom: 12px;
  padding-top: 10px;
  outline: none !important;
  border-radius: 0px !important;
  width: calc(100% - 102px) !important;
  padding-left: 0px !important;
  margin-left: 102px !important;
}

.eBorder {
  border-color: red !important;
}

.socialMediaIcons {
  margin-top: 30px;
  margin-right: 24px !important;
}
.dropdownStyleInput > div {
  padding: 8.5px 12px;
}
.dropdownStyle label {
  top: -7px;
}
.submitBox {
  padding: 30px;
  border-radius: 4px;
  background: #fff;
  border-top: 1px solid #cbd5e1;
  margin-bottom: 148px;
}
.submitBox p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 13px;
}
@media only screen and (orientation: portrait) {
  @media (width: 1024px) {
    .questionStyle {
      font-size: 18px !important;
    }
    .textFieldStyle label {
      font-size: 15px;
    }
    .nameTextFieldStyle label {
      font-size: 15px;
    }
    .nameTextFieldStyle {
      margin-right: 18px !important;
    }
    .subtitle {
      font-size: 12px;
      margin-top: 16px;
    }
    .mcqOptions {
      width: 23%;
      font-size: 15px;
    }
    .cardStyle {
      width: calc(100% - 30px);
    }
  }
  @media (min-width: 730px) and (max-width: 1023px) {
    .mainStyle {
      max-width: 651px;
    }
    .questionStyle {
      font-size: 18px !important;
    }
    .textFieldStyle label {
      font-size: 15px;
    }
    .nameTextFieldStyle label {
      font-size: 15px;
    }
    .nameTextFieldStyle {
      margin-right: 18px !important;
    }
    .subtitle {
      font-size: 12px;
      margin-top: 16px;
    }
    .mcqOptions {
      width: 23%;
      font-size: 15px;
    }
  }

  @media (min-width: 280px) and (max-width: 729px) {
    .cardStyle {
      padding: 0px;
    }
    .cardStyle > div {
      padding: 10px !important;
    }
    .questionStyle {
      font-size: 14px !important;
    }
    .textFieldStyle {
      width: 95%;
      margin-top: 0px !important;
    }
    .widthClass {
      width: 80% !important;
    }
    .textFieldStyle label {
      font-size: 12px;
    }
    .nameTextFieldStyle {
      width: 100%;
      margin-top: 0px !important;
      margin-right: 10px !important;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.04);
      background: #fff;
      padding: 3px 12px;
    }
    .nameTextFieldStyle::after,
    .nameTextFieldStyle::before {
      display: none;
    }
    .nameTextFieldStyle:first-child {
      margin-bottom: 12px;
    }
    .nameTextFieldStyle label {
      font-size: 12px;
    }
    .nameTextFieldStyle input {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: 400;
    }
    .subtitle {
      font-size: 10px;
      margin-top: 12px;
    }
    .cardStyle > div {
      padding: 10px 10px 20px 10px !important;
    }
    .cardStyle {
      padding: 0 10px;
    }
    .errorStyle {
      font-size: 10px !important;
    }
    .imgStyle {
      margin-right: 3px;
      width: 8px;
    }
    .mcqOptions {
      width: 44%;
      font-size: 12px;
    }
    .socialMediaIcons {
      margin-top: 21px;
      margin-right: 10px !important;
      width: 25px;
    }
  }
  .mainStyle {
    max-width: calc(100% - 30px);
  }
  .footerPagination {
    flex-wrap: wrap;
    padding: 0;
    gap: 20px;
    width: 100%;
  }
  .stackProgress {
    order: 1;
  }
  .btnNext {
    order: 3;
  }
  .boxBack {
    order: 2;
  }
  .stackProgress {
    width: 100% !important;
  }
  .footerStyle {
    gap: 16px;
    margin: 20px 0 16px;
  }
  .footerStyle img {
    padding: 20px 0 16px;
  }
  .scaleContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
  }
  .dropdownStyle {
    max-width: 100%;
  }

  .textFieldStyle {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    background: #fff;
    padding: 3px 12px;
    width: 100%;
  }
  .textFieldStyle input {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
  }
  .textFieldStyle::after,
  .textFieldStyle::before {
    display: none;
  }
}

@media only screen and (orientation: landscape) {
  @media (min-width: 280px) and (max-width: 729px) {
    .cardStyle {
      padding: 0px;
    }
    .mainStyle {
      max-width: 651px;
    }
    .questionStyle {
      font-size: 14px !important;
    }
    .textFieldStyle {
      width: 95%;
      margin-top: 0px !important;
    }
    .widthClass {
      width: 80% !important;
    }
    .textFieldStyle label {
      font-size: 12px;
    }
    .nameTextFieldStyle {
      width: 100%;
      margin-top: 0px !important;
      margin-right: 10px !important;
    }
    .nameTextFieldStyle label {
      font-size: 12px;
    }
    .subtitle {
      font-size: 10px;
      margin-top: 12px;
    }
    .mcqOptions {
      width: 26%;
      font-size: 12px;
    }
    .socialMediaIcons {
      margin-top: 21px;
      margin-right: 10px !important;
      width: 25px !important;
    }
  }
}
