@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.publish-engagement-container {
  display: flex;
  overflow: hidden;
  height: calc(100vh - 60px);
  background-color: #fff;
}
.calender-section-container {
  max-width: calc(100% - 265px);
  width: calc(100% - 265px);
}

.pop-up-wrapper-publish {
  height: calc(100vh - 120px);
  width: 100vw;
  position: absolute;
  z-index: 99;
}

.publish-sucess-snackbar {
  display: flex;
  justify-content: space-between;
  text-align: center;
  .publish-post-error-msg {
    font-size: 14px;
  }
  .publish-sucess-snackbar-link {
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    @include en {
      margin-left: 50px;
    }
    @include ar {
      margin-right: 50px;
    }
  }
  .time-container {
    @include ar {
      display: inline-flex;
      flex-direction: row-reverse;
    }
    .publish-post-time-first {
      margin-inline: 2px;
    }
    span {
      font-size: 13px;
    }
  }
}
