@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.edit-inbound-routing-container {
  padding: 0 !important;
  .edit-inbound-routing-header {
    margin-bottom: 19px;
    #main-pages-header-with-breadcrumb {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
      color: #0c192a;
    }

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .edit-inbound-routing-header-breadcrumb {
      display: flex;
      flex-direction: column;
      gap: 9px;
    }

    .edit-inbound-routing-header-btn {
      padding: 8px 12px 8px 12px;
      border-radius: 2px;
      gap: 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      color: #64748b;
      cursor: pointer;
      svg {
        width: 20px;
        height: auto;
        fill: #64748b;

        // reverse in RTL
        @include ar {
          transform: rotate(180deg);
        }
      }
    }
  }
  .edit-inbound-routing-wrapper {
    margin-top: 30px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .edit-inbound-routing-section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #475569;
        text-transform: uppercase;
      }
      &-body {
        border: 1px solid #e2e8f0;
        background: #fff;
        box-shadow: 0px 4px 8px 0px #00000014;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }
  }
}

header.edit-inbound-routing-footer {
  position: fixed !important;
  top: auto !important;
  bottom: 0;
  background-color: #ffffff !important;
  height: 80px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  .edit-inbound-routing-footer-toolbar {
    .edit-inbound-routing-footer-counter {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #80868c;
    }
    padding: 0px 100px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-btns {
      display: flex;
      gap: 15px;
      align-items: center;
    }
  }
}

.inbound-routing-discard-modal {
  width: 600px;
  h2.MuiTypography-root {
    padding: 24px 24px 15px 24px;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: #000000de;
  }

  div.MuiDialogContent-root {
    padding: 0px 24px 10px 24px;
  }

  div.MuiDialogActions-root {
    padding: 15px 24px 24px 24px;
  }
  .inbound-routing-discard-modal-message {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000de;
    b {
      font-weight: 600;
    }
  }
}
