@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#custom-domain-container {
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #e0e0e0;
  border-radius: 4px;
  padding: 24px;

  .connected-domain-description {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    margin-bottom: 16px;
  }

  .add-custom-domain-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add-custom-domain-desc {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .custom-domain-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 10px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;

    .custom-domain-name-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.87);
      .custom-globle-icon {
        font-size: 18px;
        color: #456eb2;
      }
      .custom-domain-status {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        border-radius: 16px;
        padding: 0px 6px;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        &.pending {
          color: #c77700;
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.9) 0%,
              rgba(255, 255, 255, 0.9) 100%
            ),
            #ed6c02;
        }
        &.verified,
        &.connected {
          color: #3b873e;
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.9) 0%,
              rgba(255, 255, 255, 0.9) 100%
            ),
            #4caf50;
        }
        &.failed {
          color: #f44336;
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.9),
              rgba(255, 255, 255, 0.9)
            ),
            #f44336;
        }
      }
    }

    .custom-domain-actions-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      .custom-domain-action-btn-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        .MuiDivider-root {
          background: #e0e0e0;
          width: 1px;
          height: 22px;
          border-radius: 40px;
        }
      }
      button.custom-domain-action-btn {
        color: rgba(0, 0, 0, 0.87);
        &.active {
          color: #456eb2;
        }
      }
    }
  }
}

#custom-domain-copied-tooltip {
  .MuiTooltip-tooltip {
    background-color: #3b873e;
  }
  .MuiTooltip-arrow {
    color: #3b873e;
  }
}

#custom-domain-add-verify-wrapper {
  p.custom-domain-verify-desc {
    margin: 8px 0 20px;
  }

  .custom-domain-add-domain-wrapper {
    min-width: 550px;
    > label {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #000000de;
      .required {
        color: #e31b0c;
      }
    }
    .MuiOutlinedInput-input {
      padding-block: 10px;
      padding-inline-end: 10px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
      &::placeholder {
        color: rgba(0, 0, 0, 0.6);
      }
      @include ar {
        text-align: left;
      }
    }
    .MuiInputAdornment-root {
      span {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
      }
    }
    .MuiInputBase-root {
      font-size: 13px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
    .MuiFormControl-root {
      width: 100%;
      @include ar {
        direction: ltr;
      }
    }
    .MuiFormHelperText-root.Mui-error {
      color: #f44336;
    }
  }

  .custom-domain-verify-wrapper {
    margin-bottom: 20px;
    label {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      margin-bottom: 10px;
      font-weight: 500;
      line-height: 15px;
    }
    input {
      overflow: hidden;
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      padding-inline: 12px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
      padding: 0;
    }
    button {
      margin-inline-start: -12px;
      color: #64748b;
      max-height: 37px;
      border: 1px solid #64748b;
      border-radius: 2px;
    }
    div.MuiInputAdornment-root {
      margin: 0;
    }
  }
}
