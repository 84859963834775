.api-action-description {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 24px;
  margin: 0;
  &:last-of-type {
    margin-bottom: 10px;
  }
  span {
    font-weight: 600;
    display: block;
  }
}
