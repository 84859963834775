.item-slide-luc_suite {
  display: flex;
  height: 259px;
  padding: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid var(--Other-Divider, rgba(0, 0, 0, 0.12));
  background: #fff;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #f8f8f8;
  }
  .head-box {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    justify-content: space-between;
    > div {
      text-align: start;
    }
  }
  .head {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;

    .img-box {
      width: 30px;
      height: 30px;
    }
    img {
      max-width: 100%;
      width: 100%;
    }
    img.icon-lock {
      filter: invert(49%) sepia(8%) saturate(1200%) hue-rotate(176deg)
        brightness(91%) contrast(87%);
    }
    svg {
      color: #4caf50;
      font-size: 16px;
      margin-top: 2px;
    }
    svg.icon-lock {
      color: #64748b !important;
      font-size: 14px;
    }
  }
  .luc_suite-name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .luc_suite-message {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .luc_suite-btn {
    display: flex;
    min-width: 70px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    color: #456eb2;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: capitalize;
  }
}
.item-slide-luc_suite.is-disabled {
  background: #f8f8f8;
}
.lucidya-suite {
  .skeleton-head {
    width: 100%;
    flex-direction: column;
    align-items: start;
    &:first-child {
      margin-bottom: 10px;
    }
  }
}
