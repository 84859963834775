@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#report-branding-select-group {
  .branding-label {
    color: #0f172a;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
    @include ar {
      font-family: "Dubai";
      font-size: 16px;
    }
  }
  .branding-choice-input {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 10px 0;
    .radio-label {
      margin-top: 8px;
      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
        .updated-date {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.38);
        }
      }
      .description {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        .link {
          color: #456eb2;
          text-decoration: underline;
        }
      }
    }
  }
}
