.whatsapp-auth-modal {
  width: 600px;
  overflow: hidden;

  #alert-dialog-title {
    font-weight: 500;
    margin-bottom: 10px;
  }
  .publish-grid-content.MuiButton-root {
    &.Mui-disabled {
      .MuiBox-root {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }
}
.whats-auth-name {
  color: #334155;
  font-size: 15px;
  font-weight: 500;
}
.whats-auth-desc {
  color: #64748b;
  font-size: 13px;
  font-weight: 400;
  text-align: start;
  height: 45px;
}
.whatsapp-auth-step {
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-auto-flow: column;
  grid-auto-columns: minmax(270px, 1fr);
  .publish-step-btn {
    &:first-child {
      margin-inline-end: 18px;
    }
  }
}
.publish-grid-content {
  &.MuiButton-root {
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 15px 18px;
    width: 100%;
    &:hover {
      border-color: #e2e8f0;
      background: rgba(195, 225, 248, 0.08);
    }
    &.active {
      border: 1px solid #456eb2;
      box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.05);
    }
  }
}
.whatsapp-auth-buttons {
  margin-bottom: 20px;
  margin-inline: 15px;
}
.manage-domain-btn {
  position: absolute;
  bottom: 40px;
  color: #456eb2;
  font-size: 14px;
  font-weight: 500;
}

div.add-whatsapp-account-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .whatsapp-auth-error-label {
    color: #f44336;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 10px;
    margin-top: 5px;
  }
  .add-whatsapp-account-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000de;
    padding-bottom: 10px;
    span {
      color: #e31b0c;
      font-size: 16px;
    }
  }
  .add-whatsapp-account-phone {
    &.whatsapp-auth-error {
      .MuiOutlinedInput-root {
        border-color: #f44336 !important;
      }
      .phone-number-details {
        border-color: #f44336;
      }
    }
  }
  .whatsapp-new-acc-alert {
    align-items: center;
    span {
      font-size: 14px;
    }
  }
}
