@import "App.scss";

@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.teams {
  @include en {
    font-family: "Figtree", sans-serif;
  }

  @include ar {
    font-family: "IBM Plex Sans Arabic", sans-serif;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button.teams-header-btn {
      background-color: #456eb2 !important;
      color: $white-color !important;
      padding: 8px 12px 8px 12px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      span {
        @include ar {
          margin-right: 0;
          text-align: right;
        }
      }
      &:hover {
        background-color: $base-color !important;
      }
    }
  }

  &-search {
    &-input {
      .MuiOutlinedInput-root {
        padding: 0;
      }

      .MuiOutlinedInput-input {
        height: 40px;
        box-sizing: border-box;
        width: 282px;
        background-color: transparent;
        color: #64748b;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.15px;

        &::placeholder {
          color: #00000099;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.15px;
          margin: 10;
        }
        @include ar {
          padding-right: 12px;
        }
      }
      .MuiInputAdornment-root {
        margin: 0;
      }
      .MuiButtonBase-root {
        color: white;
        border-radius: 4px;
        box-shadow: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        padding: 0px 0px;
        color: #cbd5e1 !important;
        background-color: #7f7f7f;
        height: 40px;
        box-sizing: border-box;
        width: 40px;

        &:hover {
          background-color: #7f7f7f;
        }

        @include ar {
          left: 0;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &.Mui-disabled {
          color: rgba(0, 0, 0, 0.26);
          box-shadow: none;
          background-color: rgba(0, 0, 0, 0.12);

          .MuiSvgIcon-root {
            color: rgba(0, 0, 0, 0.26) !important;
          }
        }

        svg {
          color: #fff;
        }
      }
    }
  }

  .teams-not-found {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 450px;
    max-width: 458px;
    width: 100%;
    margin: auto;
    text-align: center;

    .teams-not-found-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      color: #456eb2;
      margin-top: 20px !important;
    }

    .teams-not-found-body {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      color: #64748b;
      margin-top: 16px !important;

      a {
        color: #456eb2;
        text-decoration: none;
      }
    }
  }
  .teams-table-no-members {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: #00000099;
  }

  .teams-table-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: #456eb2;
    &:hover {
      text-decoration: underline !important;
    }
  }

  .teams-table-created-at {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: #000000de;
  }

  .teams-table-created-at-time {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    color: #80868c;
  }

  .teams-table-products {
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .team-table-dots {
    width: 35px !important;
    height: 35px !important;
    svg path {
      width: 16px;
    }
  }

  #shared-table .MuiTableBody-root > :nth-of-type(even) {
    #team-settings-dropdown-user-management-long-button {
      &:hover {
        -webkit-text-decoration: none;
        text-decoration: none;
        background-color: rgba(69, 110, 178, 0.04);
      }
    }
    .teams-table-members {
      & > :first-child {
        &.teams-table-member {
          @include en {
            border-left: 0;
          }
          @include ar {
            border-right: 0;
          }
        }
      }
      .broken-image {
        border: 1px solid #f1f5f9;
      }
      & > :not(:first-child) {
        border: 5px solid #f1f5f9;
      }

      .teams-table-member,
      .teams-table-member-more {
        border: 5px solid #f1f5f9;
      }
    }
  }
  .teams-table-members {
    display: flex;
    // fist child
    & > :first-child {
      &.teams-table-member {
        @include en {
          border-left: 0;
        }
        @include ar {
          border-right: 0;
        }
      }
    }
    & > :not(:first-child) {
      border: 5px solid #ffffff;
      @include ar {
        margin-right: -12px;
      }

      @include en {
        margin-left: -12px;
      }
    }
    .teams-table-member-more {
      background: #e2e8f0 !important;
    }

    .teams-table-member,
    .teams-table-member-more {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: #f1f5f9;
      border: 5px solid #ffffff;
      .user-avatar-main-wrapper {
        position: static;
      }
      img {
        border: 1px solid #1018281a;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        &.broken-image {
          border: 1px solid #fff;
        }
      }
    }

    .teams-table-member-more {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #456eb2;
      cursor: default;
      direction: ltr;
    }
  }
}

.teams-options-menu {
  .option-btn {
    padding: 0;

    img {
      color: rgba(0, 0, 0, 0.87);

      &:hover {
        background-color: #fafafa;
      }
    }

    .options-link {
      padding: 6px 16px;
      font-size: 14px;
      font-weight: 400;
      color: #475569;
      width: 97px;
      height: 34px;
      gap: 10px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
    }

    .img-wrapper {
      width: 16px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .option-title {
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.15px;
      color: #000000de;
    }
  }
}

.delete-team-modal {
  width: 600px;
  h2.MuiTypography-root {
    padding: 24px 24px 15px 24px;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    color: #000000de;
  }
  &-body-message {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000de;
  }

  div.MuiDialogContent-root {
    padding: 0px 24px 10px 24px;
  }

  div.MuiDialogActions-root {
    padding: 15px 24px 24px 24px;
    button {
      padding: 8px 12px 8px 12px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.46px;
      &.MuiButton-outlined {
        border-color: transparent !important;
        color: #64748b !important;
      }
    }
  }
}
.teams-members-preview-tooltip {
  .MuiTooltip-tooltip {
    max-width: 200px;
    bottom: -5px;
  }
  &-more .MuiTooltip-tooltip {
    bottom: -10px;
  }
}
div.teams-max-width-tooltip .MuiTooltip-tooltip {
  max-width: 700px;
}

.team-snackbar-message {
  max-width: 328px;
}
