@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.colored-state {
  width: 12px;
  height: 12px;
  margin-inline: 8px;
  display: flex;
  align-items: center;
  &.Question {
    background-color: #58ace2;
  }
  &.Compliment,
  &.Complement,
  &.complement {
    background-color: #23a41a;
  }
  &.Complaint {
    background-color: rgb(232, 0, 46);
  }
  &.Other {
    background-color: #b6b6b6;
  }

  &.Products {
    background-color: #b6b6b6;
  }
  &.Delivery {
    background-color: #a6cde7;
  }
  &.Portal {
    background-color: #afe884;
  }
  &.Payment {
    background-color: #e9d6a5;
  }
  &.Customer {
    background-color: #d1b7dd;
  }
  &.Undefined {
    background-color: #e8a454;
  }
}

.themes-selected-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  .themes-selected-count {
    width: 22px;
    height: 15px;
    display: flex;
    background-color: #64748b;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    border-radius: 50px;
    margin-inline-start: 5px;
    padding: 1px;
  }
  .themes-selected-item-empathise {
    padding-inline-start: 5px;
  }
}

.main-engagment-posts {
  position: relative;
  flex-grow: 10;
  margin: 20px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;

  .main-posts-header {
    width: 100%;
    height: 6%;
    min-height: 45px;
    background-color: #fff;
  }

  .main-posts-contant {
    overflow-y: auto;
    padding: 20px 20px 35px 20px;
    background-color: rgba(255, 255, 255, 1);
    height: fit-content;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    &.no-rep {
      padding: 20px 20px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 0;
      box-shadow: none;
    }
  }

  .main-contant {
    .main-post-state {
      div {
        background-color: #f1f5f9;
        padding: 8px 16px;
        border: 1px solid #e2e8f0;
        font-size: 14px;
        font-weight: 400;
        color: #00000099;
        margin-block: 20px;
        margin-inline-start: 50px;
        border-radius: 4px;
      }
    }
    .posts-rep {
      .posts-main-img {
        position: relative;
        color: #000000de;
        font-size: 16px;
        font-weight: 600;
        height: 34px;
        .post-header-user {
          display: inline-flex;
          align-items: center;
          gap: 14px;
        }
        img {
          width: 34px;
          height: 34px;
          border: 1px solid #cbd5e1;
          border-radius: 50%;
        }
        .posts-main-date {
          margin-top: 3px;
          color: #737a84;
          font-size: 11px;
          font-weight: 500;
        }
        .posts-main-url {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          svg {
            color: #9e9e9e;
          }
          @include ar {
            left: 10px;
            right: unset;
            transform: scaleX(-1);
          }
        }
        .posts-main-user {
          color: #0f172a;
          font-size: 14px;
          font-weight: 600;
        }
        .post-date {
          margin-top: 4px;
          margin-left: 0;
          color: #696d72;
          font-size: 12px;
          font-weight: 400;
        }
      }
      .user-hover {
        .posts-main-user {
          text-decoration: underline;
        }
        img {
          box-shadow: 3px 1px 7px 3px rgba(0, 0, 0, 0.05);
        }
      }
      .main-post {
        margin-inline-start: 18px;
        padding: 0px 30px 15px;
        border-bottom: 2px solid #e0e0e0;
        border-inline-start: 1px solid #94a3b8;
        color: #0c192a;
        font-size: 14px;
        font-weight: 400;
        min-height: 25px;
        .card-text-media {
          margin-block: 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          overflow: hidden;
        }
        &.last-child {
          border-bottom: none;
          border-inline-start: 0;
        }
        &.no-rep {
          border-inline-start: none;
          padding-inline-start: 32px;
        }
        .post-ar {
          direction: rtl;
          flex: 1;
        }
        .post-en {
          direction: ltr;
          flex: 1;
        }
        .cards-media {
          width: 100%;
          order: 2;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 5px;
          direction: ltr;
          .custom-mui-grid {
            margin-left: -10px;
            & > .MuiGrid-item {
              padding-left: 10px;
            }
          }
          .post-video {
            width: 160px;
            height: 140px;
            overflow: hidden;
          }
          .engagement-chat-message-media-image-wrapper,
          .engagement-chat-message-media-video-container {
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            transition: all 0.8s ease-in-out;
            height: 200px;
            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 200px;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0);
              z-index: 1;
              transition: all 0.2s ease-in-out;
              opacity: 0;
              border-radius: 5px;
            }
            &:hover {
              &::before {
                opacity: 1;
                background-color: rgba(0, 0, 0, 0.2);
                cursor: pointer;
              }
            }
            .engagement-chat-message-media-max-reached {
              border-radius: 5px;
              position: absolute;
              width: 100%;
              height: 200px;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 3;
              display: flex;
              align-items: center;
              justify-content: center;
              color: rgba(255, 255, 255, 1);
              font-size: 24px;
              font-weight: 600;
              cursor: pointer;
            }
          }
        }
        .cards-footer {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .card-reacts {
            display: flex;
            align-items: center;
            gap: 4px;
            div {
              display: flex;
              align-items: center;
              gap: 5px;
              color: #334155;
              font-size: 12px;
              font-weight: 400;
              padding: 7px;
              img {
                width: 21px;
                height: 28px;
              }
              svg {
                width: 21px;
                height: 21px;
                color: #b2b2b2;
              }
            }
            .facebook {
              img {
                width: 50px;
                height: 25px;
              }
            }
          }
          .post-footer-replied-by {
            height: 30px;
            margin: 10px 0;
            border-inline-start: 1px solid #cbd5e1;
            margin-inline-start: 10px;
            padding-inline-start: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .card-sentiment {
            padding-block: 8px;
            margin-inline: 15px;
            .card-themes-select {
              padding-inline-start: 15px;
              border-inline: 1px solid #e2e8f0;
            }
          }
          fieldset {
            border: none;
          }
          .card-themes-select {
            display: flex;
            align-items: center;
            padding-inline-start: 10px;
            .card-themes-label {
              color: rgba(0, 0, 0, 0.87);
              font-size: 14px;
              font-weight: 500;
            }
            .MuiSelect-select {
              padding: 0;
              padding-inline-end: 30px;
            }
            .MuiSelect-icon {
              inset-inline-end: 10px;
            }
          }
        }

        .replied-by-email {
          display: flex;
          align-items: center;
          gap: 5px;
          p {
            font-size: 15px;
            font-weight: 500;
            &.card-themes-replied-by {
              color: #696d72;
            }
          }
        }
      }
    }
    .main-post-tree {
      margin-top: -2px;
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      // &:hover{
      //   .edit-delete-buttons{
      //     opacity: 1;
      //     visibility: visible;
      //     transition: all .5s ease;
      //   }
      // }
    }
    .main-border-tree {
      position: relative;
    }
    .border-tree {
      position: absolute;
      height: 100%;
      border-inline-start: 2px solid #e0e0e0;
      margin-inline-start: 20px;
    }

    .last-child-post {
      .main-post {
        border-bottom: none;
      }
    }
    .main-parent-post {
      .posts-rep {
        .main-post.no-rep {
          border-inline-start: 1px solid #94a3b8;
          padding-top: 14px;
        }
        .main-post {
          .card-text-media {
            margin-top: 0;
            padding-top: 6px;
          }
        }
      }
    }
    .main-middle-post {
      .posts-rep {
        .main-post.no-rep {
          padding-top: 14px;
        }
        .main-post {
          .card-text-media {
            margin-top: 0;
            padding-top: 6px;
          }
        }
      }
      &.middle-reply-style .main-post .card-text-media {
        padding-top: 20px;
      }
    }
  }
  .completion-reason-replies {
    margin-inline-start: 5px;
  }
}
.media-err {
  color: #f44336;
  font-size: 10px;
  letter-spacing: 0.15px;
  margin: 15px 0;
  margin-top: 15px;
  display: flex;
}
.main-posts-footer-parant {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding-top: 0px;
  background: linear-gradient(180deg, rgba(248, 250, 252, 0) 0%, #f8fafcc2);
  .ai-suggestions {
    position: absolute;
    top: -51px;
    left: 40%;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    border-radius: 5px;
    border: 1px solid #cbd5e1;
    background: #fff;
    box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.1);
    margin: 0 auto 10px;
    padding: 7px 13px;
    z-index: 999;
    img {
      width: 25px;
      height: 25px;
      margin-inline-end: 8px;
    }
  }
}
.delete-msg-wrapper {
  margin-top: 15px;
}
.delete-msg-reply-by {
  &.MuiBox-root {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    span {
      &.MuiBox-root {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
.delete-msg-comments {
  color: rgba(0, 0, 0, 0.38);
  font-style: italic;
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  margin-top: 15px;
  svg {
    margin-inline-end: 5px;
  }
}
//
.emoji-text-wrapper {
  display: flex;
  align-items: center;
}
.emoji-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.edit-reply-divider {
  &.MuiDivider-root {
    color: #cbd5e1;
    height: 20px;
    margin-inline: 10px;
  }
}
.reply-footer-icon {
  display: flex;
  width: 33px;
  height: 33px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.reply-footer-icon.reply-footer-icon-disabled {
  color: rgba(0, 0, 0, 0.26);
  cursor: unset;
  &:hover {
    background-color: transparent;
  }
  img {
    filter: invert(75%) sepia(63%) saturate(3%) hue-rotate(325deg)
      brightness(91%) contrast(87%);
  }
}
.reply-post-edit {
  width: 100%;
  border: 1px solid #cbd5e1;
  background-color: #fff;
  padding: 20px 15px 15px 20px;
  border-radius: 5px;
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-input {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    // color: rgba(0, 0, 0, 0.38);
  }
}
.reply-word-count {
  .MuiBreadcrumbs-li {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 400;
  }
}
.engagements-send-edit {
  &.MuiButtonBase-root {
    background-color: #456eb2;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    min-width: 50px;
    border-radius: 2px;
    height: 35px;
    padding: 0px;
    &:hover {
      background-color: #456eb2;
    }
  }
}
.engagements-edit-cancel {
  &.MuiButtonBase-root {
    color: rgba(0, 0, 0, 0.6);
    background-color: transparent;
    font-size: 13px;
    font-weight: 500;
    &:hover {
      background-color: unset;
      box-shadow: unset;
    }
  }
}
.edited-tooltip {
  color: rgba(0, 0, 0, 0.38);
  font-size: 13px;
  font-weight: 400;
  padding-inline-start: 5px;
}
.edit-reply-buttons {
  .engagements-edit-cancel {
    &.Mui-disabled {
      background-color: transparent;
    }
  }
}
.edit-date-tooltip {
  span {
    &:nth-child(2) {
      padding-inline: 2px;
    }
  }
}

.question-theme {
  padding-top: 12px;
  .single-header-options {
    display: flex;
    align-items: center;
  }
  .themes-dropdown-title {
    width: max-content;
    color: #334155;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input {
    padding-block: 0px;
    padding-inline-start: 8px !important;
  }
  .single-header-options {
    padding-inline-start: 12px;
    height: max-content;
    margin: 0 !important;
  }
  .single-header-options .MuiOutlinedInput-root {
    margin: 0;
    padding: 0;
  }
  .themes-selected-item,
  .themes-box {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    span {
      @extend .themes-box;
    }
  }
  .sub-themes-container.MuiBox-root {
    width: max-content;
    margin: 0;
  }
}
