@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.live-dashboard-wrapper {
  min-height: 100vh;
  background: linear-gradient(139.24deg, #1a174f 2.03%, #456eb2 103.27%);
  padding: 0 15px;
  .circular-loading {
    p {
      color: #ffffff;
    }
  }
  &.prevent-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .alert-error-closed-socket {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .MuiAlert-message {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .MuiButtonBase-root {
      color: rgb(96, 54, 20);
    }
  }
}
.live-dashboard-heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6px;
  margin: 25px 0 28px;
}
.live-dashboard-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  gap: 15px;
  .date-field {
    .dateRange {
      height: 36px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
      background-color: #1a174f;
      color: #e6f3fc;
      margin: 0;
      height: 36px;
      border-radius: 2px;
      fieldset {
        border: unset;
      }
    }
    svg {
      color: #e6f3fc;
      left: 10px;
      @include ar {
        right: 10px !important;
      }
    }
  }
}
.live-dashboard-heading {
  display: flex;
  align-items: center;
  gap: 10px;
  .live-dashboard-name {
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
    .live-dashboard-count-viewers {
      background-color: #456eb275;
      color: #e6f3fc;
      margin-inline-start: 7px;
      border-radius: 32px;
      display: inline-flex;
      gap: 5px;
      width: 52px;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 26px;
      font-weight: 700;
      svg {
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
  .live-dashboard-date {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    margin: 5px 0 0;
  }
  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-inline-end: 5px;
    @include ar {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}
.live-dashboard-data-source {
  background-color: #1a184f6b;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
  .data-source-heading {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .data-source-tab-name {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    margin: 15px 0;
  }
}
.dashboard-widget-wrapper-column.MuiGrid-root.MuiGrid-item {
  display: flex;
  flex-direction: column;
}
.live-dashboard-widget-container {
  margin-bottom: 8px;
}
.live-dashboard-widget-wrapper {
  background-color: #ffffff;
  border-radius: 8px;
  padding-top: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  .dashboard-widget-heading {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 0 15px;
  }
  .chart,
  .postsColumn_chart,
  .sentiment_chart,
  .chart_media,
  .chart-body {
    background-color: transparent;
    box-shadow: unset;
    flex-grow: 1;
    border: unset;
  }
  .sentiment-legends-container {
    padding: 0;
  }
  .stacked-line-widget-dashboard {
    width: 100%;
  }
  .question-card-title {
    width: fit-content;
    margin: 20px 0 0;
    margin-inline-start: 20px;
  }
  .topposts-wrapper {
    padding-top: 30px;
    margin: 0 20px 15px;
  }
  .sentiment-analysis-widget,
  .postsColumn_chart {
    display: flex;
    flex-direction: column;
    .chart-no-data {
      flex-grow: 1;
    }
  }
  .theme_header {
    margin-bottom: 52px;
  }
  .post-height-overlay {
    min-height: auto;
  }
  .sub-dialect-checkbox {
    top: 55px;
  }
}

.media-facebook-wrapper {
  .mediaitem-wrapper {
    width: auto;
  }
}
.live-dashboard-back {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e6f3fc;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    background-color: rgba(230, 243, 252, 0.2);
  }
}
.luc-Button.live-dashboard-button {
  color: #e6f3fc;
  &:hover {
    background-color: #1a174f;
  }
}
.MuiDivider-root.live-dashboard-button-divider {
  height: 36px;
  border-color: rgba(255, 255, 255, 0.1) !important;
}
@media (max-width: 1200px) {
  .live-dashboard-widget-wrapper {
    .topposts-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
@media (max-width: 767px) {
  .live-dashboard-widget-wrapper {
    .chart,
    .postsColumn_chart,
    .sentiment_chart {
      height: auto;
    }
    .widget-header-container-dashboard {
      display: block;
    }
    .widget-header-container-title {
      max-width: 100%;
    }
    .widget-header-container-filter {
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: end;
    }
    .posts-stats-wrapper,
    .interactions-stats-wrapper,
    .sentiment-legends-container {
      grid-template-columns: repeat(1, 1fr);
    }
    .statistics-icons {
      flex-wrap: wrap;
    }
    .topposts-wrapper {
      .question-card-footer {
        display: inline-block;
      }
    }
    .response_header {
      margin-bottom: 10px;
    }
  }
}
.drag-widget-icon {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 10px;
  inset-inline-start: 10px;
  z-index: 9;
}
.power-logo-wrapper {
  position: fixed;
  z-index: 9999;
  @include ar {
    bottom: -14px;
    inset-inline-end: -27px;
  }
  @include en {
    bottom: -11px;
    inset-inline-end: -7px;
  }
}
.save-order-wrapper {
  position: fixed;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  bottom: 20px;
  inset-inline-start: 20px;
  z-index: 9999;
  display: flex;
  align-items: center;
  gap: 20px;
}
.statistic-widgets {
  margin-block: 40px;

  .total-statistic-widget {
    color: #ffffff;
    background-color: #1a174f;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;

    .title-statistic-widget {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      color: #e6f3fc;
    }
    .count-statistic-widget {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
      color: #ffffff;
    }
    .icon-statistic-widget {
      font-size: 40px;
      font-weight: 300;
      line-height: 40px;
      color: #e6f3fc;
    }
  }
}

.live-dashboard-deleted-page,
.live-dashboard-not-access-page {
  height: calc(100vh - 50px);
  margin: 25px;
  background-color: #1a174f80;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 25px;
  color: #e6f3fc;

  &.live-dashboard-not-access-page {
    height: calc(100vh - 133px);
    margin-top: 0px;
  }
  svg {
    font-size: 70px;
    line-height: 70px;
    color: #e7ecf2;
  }
  .live-dashboard-deleted-title {
    font-size: 22px;
    line-height: 26px;
  }
  .live-dashboard-not-access-title {
    max-width: 436px;
    font-size: 26px;
    line-height: 31.2px;
  }
  .live-dashboard-not-access-description {
    font-size: 16px;
    line-height: 22px;
  }
  .btn-back-to-monitors-list {
    color: #e6f3fc;
    border-color: #e6f3fc;
    &:hover {
      color: #64748b;
    }
  }
}
