@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.single-tweet-analysis-box {
  .page-container {
    font-family: "Graphik";
  }
  #frown-o {
    color: #ff0047;
  }
  #circle-o {
    color: #f6b900;
  }
  #smile {
    color: #8dc63c;
  }
  .table-span {
    letter-spacing: 1.4px;
    color: rgb(71, 79, 87);
    opacity: 1;
    font-size: 16px;
    font-weight: bold;
  }
  .table-p {
    font-size: 9px;
    letter-spacing: 0px;
    color: rgb(66, 89, 104);
    text-transform: uppercase;
    opacity: 0.33;
  }

  .data-table-last {
    text-align: center !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .data-table {
    border-width: 2px;
    border-style: solid;
    border-color: transparent rgb(221, 221, 221) transparent transparent;
    text-align: center !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .number-table {
    display: block;
    margin-top: 18px;
    .fa {
      opacity: 1;
      font-size: 14px;
      color: rgb(27, 168, 227);
    }
  }

  .audience-table {
    p {
      color: #425968;
      text-transform: capitalize;
      opacity: 0.7;
    }
    strong {
      letter-spacing: 1.8px;
      font-size: 16px;
    }
  }
  #last-col {
    border-width: 2px;
    border-style: solid;
    border-color: transparent rgb(221, 221, 221) transparent transparent;
    text-align: center !important;
  }

  #page-widget-wrap {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  #page-widget-heading {
    background-color: #f8f8f8;
    border-bottom: none;
  }

  #row {
    border-bottom: 1px solid #f8f8f8;
    padding: 10px;
  }
  #btn {
    text-align: left;
    margin-left: 10px;
    color: #495f6c;
    border-color: transparent;
    height: 44px;
    width: 265px;

    &:focus {
      font-size: 14px;
      color: #1ba8e3;
      background-color: #ffffff;
      border-right-color: #1ba8e3;
      border-top-color: #1ba8e3;
      border-bottom-color: #1ba8e3;
    }
  }

  // New design
  .flex-row {
    display: flex;
  }
  .heading-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      margin: 0;
      font-size: 27px;
    }

    .update-analysis {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .btn {
        padding: 8px 25px;
        background-color: #27aae2;
        border-color: #27aae2;
        margin-bottom: 10px;
        transition: 0.3s ease;

        i {
          @include en {
            margin-right: 5px;
          }
          @include ar {
            margin-left: 5px;
          }
        }

        &[disabled] {
          i {
            animation: fa-spin 0.5s linear;
          }
        }

        &:hover,
        &:focus,
        &:active {
          background-color: #2ebcfc;
          color: #fff;
        }
      }
      span.last-updated {
        color: #969fa5;
        font-size: 13px;
        display: block;
        font-style: italic;
      }
    }
  }

  .panel-outer-title {
    font-size: 18px;
    font-weight: 500;
    color: #646b7465;
    margin: 0 0 15px;
  }
  .panel.panel-default--modified {
    border-radius: 7px;
    border-color: transparent;
    box-shadow: 0px 3px 20px #4259680d !important;

    .panel-title {
      color: #425968;
      font-size: 16px;
    }
  }
  .grid-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;

    &.mb-50 {
      margin-bottom: 50px;
    }

    .panel-card {
      margin-bottom: 0;
      position: relative;
      top: 62px !important;
      margin-bottom: 77px !important;
    }
  }
  .panel-card {
    background-color: #fff;
    box-shadow: 0px 3px 20px #4259680d;
    border-radius: 7px;
    padding: 20px 25px;
    margin-bottom: 20px;
    position: relative;
    top: 62px !important;
    margin-bottom: 77px !important;

    &.no-pad {
      padding: 0;
      .panel-card-title {
        padding: 0 20px;
      }
    }

    .panel-card-title {
      font-size: 16px;
      color: #334155;
      line-height: 50px;
      border-bottom: 1px solid #ebeff2;
      margin: 0;
      position: relative;
      font-weight: 500;

      .ai_icon {
        position: absolute;
        top: 0px;
        @include en {
          right: 12px;
        }
        @include ar {
          left: 12px;
        }
      }
    }
    .panel-card-body {
      position: relative;
    }

    &.flex-row {
      justify-content: space-between;
      align-items: center;
    }

    .stat-info {
      padding: 0 5px;
      line-height: 1;
      font-size: 13px;
      color: #969fa5;
      font-style: italic;

      .stat-num {
        display: block;
        font-size: 28px;
        font-weight: 500;
        font-style: normal;
        margin-bottom: 10px;
        color: #0e182a;
      }
    }
  }

  i.informativeIcon {
    background-color: #e2e7ea;
    color: #b5c2cb;
    font-size: 8px;
    display: inline-block;
    text-align: center !important;
    width: 16px;
    line-height: 16px;
    border-radius: 50%;
    vertical-align: middle;

    @include en {
      margin-left: 10px;
    }
    @include ar {
      margin-right: 10px;
    }
  }
  .page-widget--heading {
    background-color: #fff;
  }

  // Tweet overview
  .tweet_link {
    position: absolute;
    top: 0;
    color: #bfbfbf;
    font-size: 14px;
    z-index: 9;
    transition: 0.3s ease;

    @include en {
      right: 0;
    }
    @include ar {
      left: 0;
    }
  }

  .author-avatar {
    display: table-cell;
    @include en {
      padding-right: 20px;
    }
    @include ar {
      padding-left: 20px;
    }

    img.author-img {
      display: block;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      border: 1px solid #ebebeb;
      object-fit: cover;
    }

    &.sm {
      @include en {
        padding-right: 0;
      }
      @include ar {
        padding-left: 0;
      }
      a img {
        width: 40px;
        height: 40px;
        border: none;
      }
    }
  }

  .tweet-details {
    display: table-cell;
    vertical-align: middle;
    @include en {
      padding-left: 7px;
    }
    @include ar {
      padding-right: 7px;
    }
  }
  .tweet-author {
    margin: 0 0 10px;
    font-size: 14px;
    color: #353c43;

    .author-userName {
      @include en {
        margin-right: 3px;
      }
      @include ar {
        margin-left: 3px;
      }
    }

    .screen-name {
      color: #767676;
      font-size: 12px;

      @include en {
        margin-left: 3px;
      }
      @include ar {
        margin-right: 3px;
      }
    }
  }
  .tweet-text {
    color: #435966;
    font-size: 14px;
    max-width: 900px;
    margin-bottom: 15px;
    display: table;
  }

  .tweet-overview-media {
    border-radius: 7px;
    max-height: 250px;
  }

  .tweet-stats {
    display: flex;
    margin-top: 15px;

    span.tweet-stat {
      display: inline-block;
      @include en {
        margin-right: 15px;
      }
      @include ar {
        margin-left: 15px;
      }

      i {
        color: #9aadba;
        @include en {
          margin-right: 5px;
        }
        @include ar {
          margin-left: 5px;
        }

        &.fa-calendar:before {
          content: "\F133"; //To fix Fontawesome versions conflict
        }
      }

      span {
        color: #81878d;
      }
    }
  }

  .tweet-reations {
    @include en {
      margin-right: 75px;
    }
    @include ar {
      margin-left: 75px;
    }
  }

  // Chart over time
  .chart-data {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .flex-row .chart-data {
    flex: 1;
    @include en {
      padding-right: 90px;
    }
    @include ar {
      padding-left: 90px;
    }
  }
  .chart-nav-list {
    display: flex;
    justify-content: flex-end;

    .chart-nav-item {
      font-size: 13px !important;
      transition: color 0.3s ease !important;
      color: #27aae1 !important;
      cursor: pointer !important;
      &.active {
        font-weight: 500 !important;
        color: #27aae1 !important;
        cursor: default !important;
        &:hover {
          color: #27aae1 !important;
        }
      }
      &:not(:first-child):before {
        content: "\7c";
        margin: 0 10px !important;
        font-weight: normal !important;
        color: #959b9f !important;
        font-size: 13px;
      }
    }
  }
  .chart-tabs {
    flex-shrink: 0 !important;
    width: 268px !important;

    @include en {
      border-left: 1px solid #ebeff2 !important;
    }
    @include ar {
      border-right: 1px solid #ebeff2 !important;
    }

    .chart-tab {
      display: block;
      line-height: 60px;
      padding: 0 20px;
      font-size: 12px;
      color: #0c192a88;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #ebeff2;
      }

      &:hover {
        background-color: #f7f7f7;
      }

      &.active {
        color: #fff;
        background-color: #456EB2;
        font-weight: 500;
        cursor: default !important;

        &:hover {
          background-color: #456EB2;
        }

        i.informativeIcon {
          color: #ffffff90;
          background-color: #1693c7;
        }
      }
    }
  }

  // Chart tooltip
  .chart-tooltip-img {
    border-radius: 50%;
    overflow: hidden;
    align-self: flex-start;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
  }
  .chart-tooltip-content {
    padding: 0 5px;
    font-size: 10px;
    font-family: "Graphik";

    .chart-tooltip-user {
      color: #000;
      margin: 0;
      font-size: 10px;
      font-weight: 500;

      img {
        width: 13px;
        height: 13px;
      }
    }

    .chart-tooltip-follower {
      color: #707070;
      i {
        color: #c3c3c3;
      }
    }
    .chart-tooltip-reacts {
      color: #b4bdc3;
      span {
        @include en {
          margin-right: 8px;
        }
        @include ar {
          margin-left: 8px;
        }
        a {
          text-decoration: underline !important;
        }
      }
    }
  }

  // Panel table
  .panel-table-head {
    padding: 0 20px;
    line-height: 40px;
    color: #333333;
    border-bottom: 1px solid #ebeff2;
  }
  .panel-table-content {
    .panel-table-row {
      padding: 15px 20px;
      border-bottom: 1px solid #ebeff2;
    }

    .tweet-author {
      line-height: 1.4;
      margin-bottom: 0;

      .screen-name {
        display: block;
        margin: 0 !important;
        font-size: 13px;
      }
    }

    .user-interactions {
      color: #b4bdc3;
      font-size: 12px;
      span {
        @include en {
          margin-right: 4px;
          i {
            margin-right: 4px;
          }
        }
        @include ar {
          margin-left: 4px;
          i {
            margin-left: 4px;
          }
        }
        a {
          text-decoration: underline !important;
        }
      }
    }
  }
  .panel-table-footer {
    text-align: center !important;
    line-height: 65px;
    font-size: 14px;

    a,
    a:hover {
      text-decoration: underline !important;
      transition: 0.3s ease !important;
    }
    .showLessBtn {
      color: #aaaaaa;
    }
    .no-results {
      color: #333333;
      text-align: center !important;
    }
  }

  .replies-chart {
    @include en {
      padding-right: 20px;
      padding-left: 50px;
    }
    @include ar {
      padding-left: 20px;
      padding-right: 50px;
    }

    .chart-nav-list {
      position: absolute;
      top: 25px;
      z-index: 9;
      right: 40px;
    }
  }

  .panel-card.sentiment-panel {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sentiment-replies-tooltip {
    color: white !important;

    padding: 5px 7px;
    @include en {
      padding-right: 15px;
    }
    @include ar {
      padding-left: 15px;
    }
    .sentim-dateKey {
      direction: ltr;
      color: white !important;
    }
    .sentim-stats {
      display: flex;
      justify-content: space-between;
      color: white !important;

      span:first-child {
        @include en {
          margin-right: 20px;
        }
        @include ar {
          margin-left: 20px;
        }
        color: white !important;
      }
    }
  }

  .posts-list .post-list-panel .panel-heading {
    background-color: #f7f7f7;
    border-radius: 0;
    border: none;
    font-size: 16px;
  }

  .replies_filter_tags {
    display: inline-flex;

    @include en {
      padding-left: 15px;
    }
    @include ar {
      padding-right: 15px;
    }

    .replies_filter_tag {
      background-color: #ebebeb;
      color: #788b98;
      font-size: 12px;
      border-radius: 16px;
      line-height: 32px;
      margin: 0 5px 10px;
      position: relative;

      @include en {
        padding-left: 15px;
        padding-right: 35px;
      }
      @include ar {
        padding-right: 15px;
        padding-left: 35px;
      }

      .sentim_Positive {
        color: #8dc63c;
        font-weight: 500;
      }
      .sentim_Neutral {
        color: #f6b92b;
        font-weight: 500;
      }
      .sentim_Negative {
        color: #f10f41;
        font-weight: 500;
      }

      a {
        color: #b2bcc3 !important;
        position: absolute !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        @include en {
          right: 15px !important;
        }
        @include ar {
          left: 15px !important;
        }
      }
    }
  }

  .tweet-action-btn.singleTweetActionBtns {
    position: fixed;
    top: 10px;
    @include en {
      right: 10px;
    }
    @include ar {
      left: 10px;
    }
  }
  .verifiedLabel {
    margin: 0 5px;
  }
  .replies-list .page-widget--content.p0 {
    padding: 0;
  }
  .replies-list .tweet-wrap {
    border: none !important;
    padding: 0 !important;
  }
  .replies-list .single-post {
    padding: 20px 35px;
    border-bottom: 1px solid #ebeff2;

    .tweet-media-video {
      max-width: 330px;

      &:before,
      &:after {
        display: none;
      }
      video {
        max-height: 250px;
      }
    }

    .tweet-date {
      display: flex;

      .interaction {
        line-height: 1;
        &:not(:first-child) {
          @include en {
            margin-left: 20px;
          }
          @include ar {
            margin-right: 20px;
          }
        }
      }

      .date {
        @include en {
          margin-left: auto;
        }
        @include ar {
          margin-right: auto !important;
        }
      }

      i {
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;

        &.fa-calendar:before {
          content: "\F133";
        }

        &.fa-clock-o {
          content: "\F017";
        }
      }
    }
  }

  .day-slider {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      display: block;
      background-color: #bbbbbb;
      height: 1px;
      left: 40px;
      right: 40px;
      top: 8px;
    }

    .day-item {
      color: #898989;
      font-size: 10px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      position: relative;
      z-index: 9;

      .bullet {
        border: 1px solid #707070;
        background-color: #fff;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin: 3px;
      }
      &.active {
        color: #1ba8e3;
        .bullet {
          width: 16px;
          height: 16px;
          margin: 0;
          border: 3px solid #ffffff88;
          background-color: #1ba8e3;
        }
      }
    }

    button {
      background-color: transparent;
      border: none;
      font-size: 16px;
      color: #797878;
      line-height: 1;

      &[disabled] {
        opacity: 0.3;
      }
    }
  }

  .authorTopTweetTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .btn {
      padding: 5px 20px;
      @include en {
        padding-right: 7px;
      }
      @include ar {
        padding-left: 7px;
      }

      &[disabled] i {
        animation: fa-spin 2s infinite linear;
        &:before {
          content: "\F110";
        }
      }
    }
  }

  .post_menu .dropdown-menu .divider:first-child,
  .post_menu .dropdown-menu .divider + .divider {
    display: none;
  }

  .beta-flag {
    background: #f9a728;
    color: #fff;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1;
    display: inline-block;
    transform: translateY(-5px);

    &.sm {
      padding: 4px 7px;
      font-size: 10px;
      transform: none;
      @include en {
        margin-left: 7px;
      }
      @include ar {
        margin-right: 7px;
      }
    }
  }

  .upgrade-flag {
    background: #88d479;
    color: #fff;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1;
    display: inline-block;
    transform: translateY(-5px);

    &.sm {
      padding: 4px 7px;
      font-size: 10px;
      transform: none;
      @include en {
        margin-left: 7px;
      }
      @include ar {
        margin-right: 7px;
      }
    }
  }

  .mb-40 {
    margin-bottom: 40px;
  }
  .verifiedIcon {
    width: 13px;
  }
  .pieChartPanel {
    padding: 0 30px 30px;
  }

  .panel-card .__react_component_tooltip {
    max-width: 400px;
  }

  .no-donut-data {
    border: 1px solid #cad0d6;
    padding: 13px 20px;
    margin: 20px -10px;
    background-color: #f6f6f6;
    color: #626262;
    font-size: 13px;
    border-radius: 2px;
  }

  .Logout_single_tweet {
    color: #ad9a61 !important;
    font-weight: bold !important;
    text-decoration: underline !important;
    margin-left: 5px !important;
  }
  .Logout_single_tweet:hover {
    cursor: pointer !important;
  }
}
