@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.activites-header {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .audience-date .date-field {
    margin-inline-end: -20px;
  }
  .activites-title {
    font-weight: 500;
    font-size: 20px;
    color: #0c192a;
  }
}

.survey-nodata {
  background-color: #fff;
  padding: 50px 0;
}

.survey-adience .circular-loading {
  top: 0px;
}
.all-interactions-list {
  .audience-load-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 42px;
    border-radius: 4px;
    background-color: #456EB2 ;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    margin: 80px auto 65px;
  }
  .audience-load-more-btn:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 42px;
    border-radius: 4px;
    background-color: #456EB2 ;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    margin: 80px auto 65px;
  }
}
.all-interactions-list.audience {
  .widget-box {
    display: none;
  }
}
