@import "../../../surveyBuilder.scss";

@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.survey-section-header {
  margin: 20px 0;

  &.preview-mode {
    .survey-section-title {
      padding: 30px;
    }

    .preview-survey-section-title {
      color: var(--survey-font-color);
      line-height: 22px;
      font-size: 18px;
      font-weight: 700;
      @include popupSize("all") {
        font-weight: 500;
      }
    }
  }

  &.section-error {
    .section-number {
      background-color: #e31b0c;
    }
    .survey-section-title {
      border-color: #e31b0c;
    }
  }
  .section-number {
    background-color: #456eb2;
    color: #ffffff;
    width: fit-content;
    padding: 10px 10px;
    border-radius: 8px 8px 0px 0px;
    font-weight: 500;
  }
  .survey-section-title {
    background-color: #ffffff;
    padding: 20px 20px 25px 20px;
    border-top: 5px solid var(--survey-primary-color);
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 4px 8px 0px #0000000d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .survey-section-title-textfield {
      width: 90%;
    }
    input {
      color: var(--survey-font-color);
    }

    @include popupSize("all") {
      gap: 20px;
      ::before {
        border: none;
      }
    }
    @include popupSize("sm") {
      padding: 12px;
    }
    @include popupSize("md") {
      padding: 16px;
    }
    @include popupSize("lg") {
      padding: 20px;
    }
  }

  @include popupSize("all") {
    margin: 0;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #e2e8f0;
    border-radius: 0 0 4px 4px;
  }
}
.section-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  @include popupSize("all") {
    margin-bottom: 0;
  }
  @include popupSize("lg") {
    gap: 24px;
  }
  @include popupSize("md") {
    gap: 20px;
  }
  @include popupSize("sm") {
    gap: 16px;
  }

  .section-header {
    margin: 40px 0 20px 0;
    &.section-error {
      .section-number {
        background-color: #e31b0c;
      }
      .section-title {
        border-top: 2px solid #e31b0c;
      }
    }
    .section-number {
      background-color: #456eb2;
      color: #ffffff;
      width: fit-content;
      padding: 10px 10px;
      border-radius: 8px 8px 0px 0px;
      font-weight: 500;
    }
    .section-title {
      background-color: #ffffff;
      padding: 20px 20px 25px 20px;
      border-top: 2px solid #456eb2;
      border-radius: 0 0 4px 4px;
      box-shadow: 0px 4px 8px 0px #0000000d;
      justify-content: space-between;
      .section-title-textfield {
        width: 90%;
      }
    }
  }
  .empty-survey-section-error-msg {
    color: #e31b0c;
    font-weight: 400;
    @include popupSize("lg") {
      font-size: 18px;
    }
    @include popupSize("md") {
      font-size: 16px;
    }
    @include popupSize("sm") {
      font-size: 14px;
    }
  }
  .empty-survey {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-grow: 1;
    .empty-survey-icon {
      width: 75px;
    }
    .empty-survey-msg {
      text-align: center;
      @include en {
        width: 200px;
      }
      @include ar {
        width: 120px;
      }
    }
  }
  .section-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    @include en {
      direction: ltr;
    }
    @include ar {
      direction: rtl;
    }
    .after-section-title {
      width: auto;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
      @include popupSize("all") {
        font-size: 12px;
        gap: 5px;
        margin: 0;
      }
    }
    div {
      background: transparent;
      @include en {
        padding-right: 10px;
      }
      @include ar {
        padding-right: 5px;
      }
      @include popupSize("all") {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
      }
    }
    .after-section-select {
      font-weight: 500;
      width: 240px;
      padding: 10px;
      cursor: pointer;
      border: none;

      @include popupSize("all") {
        padding: 5px 10px;
      }

      &.no-error {
        color: #64748b;
        &:hover {
          background-color: #f1f5f9;
        }
        &::before,
        &::after,
        &:hover::before {
          border: none;
        }
      }
      svg {
        color: #64748b;
        @include ar {
          left: -3px;
        }
      }
    }
    .error-msg {
      @include ar {
        text-align: right;
      }
    }
  }
}

.empty-survey {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-grow: 1;
  .empty-survey-icon {
    width: 75px;
  }
  .empty-survey-msg {
    text-align: center;
    @include en {
      width: 200px;
    }
    @include ar {
      width: 120px;
    }
  }
}

.section-container.section-direction-rtl {
  direction: rtl;

  div.MuiBox-root.align-box {
    direction: ltr;
  }
  .question-title-container {
    textarea,
    input {
      direction: rtl;
    }
  }
  .cardStyle {
    .questionNoStyle {
      div.MuiInputBase-root.MuiInput-root {
        direction: rtl;
      }
    }
    textarea {
      direction: rtl;
    }
    input {
      direction: rtl;
    }
  }
  div.card-direction-container {
    direction: rtl;

    textarea.MuiInputBase-input.MuiInput-input {
      direction: rtl;
    }
    input.MuiInputBase-input.MuiOutlinedInput-input {
      direction: rtl;
    }
  }
  .phone-number-question {
    .phoneInput {
      left: -86px;
    }
  }
  .after-section-select {
    .MuiSelect-select.MuiSelect-standard {
      @include en {
        direction: ltr;
      }
      @include ar {
        direction: rtl;
      }
    }
  }
}

.section-container.section-direction-ltr {
  direction: ltr;
  div.MuiBox-root.align-box {
    direction: ltr;
  }
  .question-title-container {
    textarea,
    input {
      direction: ltr;
    }
  }
  .cardStyle {
    .questionNoStyle {
      div.MuiInputBase-root.MuiInput-root {
        direction: ltr;
      }
    }
    textarea {
      direction: ltr;
    }
    input {
      direction: ltr;
    }
  }
  div.card-direction-container {
    direction: ltr;

    textarea.MuiInputBase-input.MuiInput-input {
      direction: ltr;
    }
    input.MuiInputBase-input.MuiOutlinedInput-input {
      direction: ltr;
    }
  }
  .phone-number-question {
    .phoneInput {
      left: 86px;
    }
  }
  .after-section-select {
    .MuiSelect-select.MuiSelect-standard {
      @include en {
        direction: ltr;
      }
      @include ar {
        direction: rtl;
      }
    }
  }
}
