// ---  --- //

h3.no-data-found.MuiTypography-root {
  color: #000000de !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  margin: 20px 0 10px;
}
span.try-adjust-date.MuiTypography-root {
  color: #64748b !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  max-width: 375px;
}
.chart-no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  height: 100%;
}
.empty-data-box {
  text-align: center;
  a {
    margin: 0 4px;
    color: #456eb2;
    font-size: 15px;
    font-weight: 400 !important;
    text-decoration: underline;
  }
}
.no-data-date {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-topics-content {
  text-align: center;
  font-size: 13px;
  color: #64748b;
  line-height: 22px;
  width: 375px;
}

.topics-no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  border-radius: 5px;
  span.try-adjust-date.MuiTypography-root {
    text-align: center;
  }
}

.topics-no-data-found {
  color: #64748b;
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0px 10px 0px;
}

.upgrade-topics {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  background-color: #e9eff5;
  border-radius: 5px;
}

.topics-margin {
  margin-bottom: 30px;
  margin-top: 20px;
}

.upgrade-topics-content {
  text-align: center;
  font-size: 13px;
  color: #475569;
  line-height: 22px;
  margin-bottom: 15px;
}

.upgrade-topics-heading {
  color: #1e293b;
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0px 10px 0px;
}
.empty-data-search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  height: 100%;
}
.chart-no-data {
  h3.no-data-found.MuiTypography-root {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin: 10px 0 0px;
  }
  span.try-adjust-date.MuiTypography-root {
    font-size: 15px !important;
    font-weight: 400 !important;
  }
}
.chart-no-data.charts-no-data {
  h3.no-data-found.MuiTypography-root {
    font-size: 16px !important;
    font-weight: 500 !important;
    margin: 10px 0 0px;
    color: #334155 !important;
  }
  span.try-adjust-date.MuiTypography-root {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}
