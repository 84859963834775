@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.input-with-shrink-label {
  fieldset.MuiOutlinedInput-notchedOutline {
    @include ar {
      text-align: right;
    }
  }
  label.MuiFormLabel-root.MuiInputLabel-root,
  .MuiOutlinedInput-root input {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;

    @include ar {
      font-family: "IBM Plex Sans Arabic";
    }
  }
  label.MuiFormLabel-root.MuiInputLabel-root {
    color: #00000099;
    @include ar {
      text-align: right;
    }
    &.Mui-focused {
      @include ar {
        right: 0px;
        left: auto;
      }
    }
    // not focused and not shrinked
    &:not(.Mui-focused) {
      @include ar {
        right: 30px;
        left: auto;
      }
    }
    &.MuiInputLabel-shrink {
      @include ar {
        right: 17px !important;
        left: auto;
        transform: translate(5px, -9px) scale(0.75);
        transform-origin: top right;
      }
    }
  }
  .MuiOutlinedInput-root {
    input {
      direction: ltr;
      color: #000000de;
      height: 40px;
      box-sizing: border-box;
    }
  }
}
