//functions sass
@mixin display-flex-function($flex, $item-center, $justify) {
  display: $flex;
  align-items: $item-center;
  justify-content: $justify;
}
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate-effect {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.logo-luci {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.16), 0px 1px 6px rgba(0, 0, 0, 0.06);
  > button {
    @include display-flex-function(flex, center, center);
    border: none;
    background-color: transparent;
    margin: 0;
    animation: rotate-effect 30s linear infinite;
    height: 100%;
    width: 100%;
    &:hover {
      background-color: transparent;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    margin: 0;
    border-radius: 50%;
    height: 100%;
    &:hover {
      background-color: transparent !important;
    }
    &:focus {
      outline: 0;
    }
    img {
      width: 46px;
      height: 41px;
      pointer-events: none;
      svg {
        pointer-events: none;
      }
    }
    padding: 0;
    cursor: pointer;
  }

  position: fixed;
  z-index: 99;
  inset-inline-end: 42px;
  bottom: 45px;
  bottom: 45px;
  background-color: #fff;
  .disabled-btn {
    filter: grayscale(100%) !important;
    opacity: 75 !important;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .luci-processing {
    animation: rotate 1s linear infinite;

    position: absolute;
    top: 1;
    left: 1;
    z-index: 2222;
    width: 55px;
    z-index: -1;
    height: 55px;
  }
}

// side-bar
.side-bar {
  z-index: 9999 !important;
  @include ar {
    .MuiTypography-root,
    .MuiBox-root {
      font-family: "IBM Plex Sans Arabic" !important;
    }
  }
}

.side-bar-header {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    90deg,
    #1f2249 -12.87%,
    #262d56 9.11%,
    #394c7a 50.08%,
    #4e6d9f 87.05%
  );
  height: 220px;
  padding: 20px;
  @include en {
    direction: rtl;
  }
  @include ar {
    direction: ltr;
  }

  .luci-btns-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: auto;
    height: fit-content;
    .luci-settings-btn {
      height: 35px !important;
      width: 35px !important;
      color: #ffffff;
      background: rgba(255, 255, 255, 0.15);
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }
}
.side-bar-header-inactive {
  @extend .side-bar-header;
  height: 170px;
}
.btn-close-side-bar {
  &.MuiButton-root {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 2px;
    padding: 0;
    min-width: auto;
    margin-bottom: 8px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.luci-side-bar-wrapper {
  .circular-loading {
    top: 290 !important;
  }
}
.show-requests-thinking.MuiButton-root {
  position: fixed;
  bottom: 50px;
  @include en {
    margin-left: 148px;
  }
  @include ar {
    margin-right: 160px;
  }
}
.no-data-survey-parant {
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    color: #212121;
    font-weight: 700;
    margin-bottom: 5px;
    size: 20px;
    line-height: 24px;
  }
  P {
    color: #212121;
    font-weight: 400;
    size: 16px;
  }
}
