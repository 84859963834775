.header-wrapper {
  margin: 40px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  .request-heading {
    color: #000000de;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    margin: 0 0 9px;
  }
  .request-description {
    color: #80868c;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 9px;
  }
  .title-limit-reached {
    max-width: 152px;
    line-height: 14px;
    margin: 4px 2px;
  }
}
