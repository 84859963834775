@import "App.scss";
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.main-edit-user {
  padding: 30px 100px;
  margin-bottom: 40px;
  .main-edit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #94a3b8;
  }
  .edit-user-main-section-box {
    padding: 24px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    background-color: #fff;
    box-shadow: 0px 4px 8px 0px #00000014;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .back-to-link {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--neutral-500, #64748b);
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 185.714% */
    letter-spacing: 0.46px;
    text-transform: capitalize;
    .MuiSvgIcon-root {
      @include ar {
        rotate: 180deg;
      }
    }
  }
  .add-user-form {
    margin: 20px 0 0 0;
  }
  .main-edit-header-tittle {
    color: #0c192a;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 19px;
  }
  .main-edit-user-card {
    .edit-sec-tit {
      color: #475569;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .edit-sec-container {
      padding: 24px;
      border-radius: 5px;
      border: 1px solid #e2e8f0;
      background-color: #fff;
      box-shadow: 0px 4px 8px 0px #00000014;
      margin-bottom: 30px;
      .edit-sec-tit {
        margin-top: 0px;
      }
      .main-acc-table-countainer.engagement_features {
        padding: 0;
        border: none;
      }
      .main-perms-countainer {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 0px;
        .MuiPaper-elevation.MuiPaper-rounded {
          margin: 0px;
        }
      }
    }
  }
  .edit-user-avatar {
    width: 155px;
    height: 155px;
  }
  .edit-user-img {
    .img-title {
      margin-bottom: 6px;
      display: flex;
      color: var(--neutral-600, #475569);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    display: flex;
    #button {
      height: 0;
    }
    #button > .cameraIcon,
    #button > .MuiButton-root {
      position: relative;
      left: 68px;
      bottom: 55px;
      display: inline-block;
      fill: currentcolor;
      flex-shrink: 0;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-size: 0rem;
      margin: 6px;
      padding: 10px;
      border-radius: 4px;
      box-sizing: content-box;
      color: rgb(255, 255, 255);
      background-color: #456eb2;
      border: white solid;
      border-radius: 50px;
      @include ar {
        left: -68px;
      }
      .MuiSvgIcon-root {
        max-width: 14px;
        max-height: 14px;
        position: relative;
        @include en {
          right: 2px;
        }
        @include ar {
          left: 2px;
        }
        bottom: 2px;
      }
    }
    #button > .MuiButton-root {
      min-width: 10px !important;
      width: 10px;
      height: 9px;
    }
  }
}
