@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.card-style {
  width: 100% !important;
  margin: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08) !important;
  margin-top: 20px;
  padding: 14px;
  padding-bottom: 6px;
  overflow: visible !important;

  div {
    position: relative;
  }
}

.question-style {
  font-size: 22px !important;
  color: #0c192a;
  font-weight: 400;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
    align-items: center;
    color: #2497ca;
    margin-inline-end: 10px;
  }
}

.text-field-style {
  width: 66%;
  margin-top: 17.5px !important;

  label {
    color: #80868c !important;
  }
}

.name-text-field {
  width: 31.1%;
  margin-top: 17.5px !important;
  margin-right: 25px !important;

  label {
    color: #80868c !important;
  }
}

.imgStyle {
  margin-right: 5px;
}

.mcq-options {
  display: flex;
  border-radius: 3px;
  padding: 7px;
  width: 29.5%;
  margin-top: 20px;
  font-size: 16px;
  box-sizing: border-box;
  width: 240px;
  height: 36px;
  border: 1px solid #0c192a;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
}

.option-value {
  font-weight: 400;
  margin-left: 8px;
  color: #0c192a;
}
.card-style-individual {
  .option-value {
    padding-inline-start: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include ar {
      padding-inline-start: 5px;
    }
  }
}

.phone-input {
  left: 90px !important;
  top: 1px;
}

.social-media-icons {
  margin-top: 30px;
  width: 33.333px;
  height: 33.333px;
}
#menu-questions-dropdown {
  max-height: 286px;
  .MuiButtonBase-root.MuiMenuItem-root {
    overflow: hidden;
    padding: 6px 16px;
    max-width: 350px;

    .MuiListItemText-root span {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #000000de;
      overflow: hidden;
      margin: 0;
    }
  }
  .MuiButtonBase-root.MuiMenuItem-root.Mui-disabled {
    padding-inline-start: 10px !important;
  }
  .MuiButtonBase-root.MuiMenuItem-root.Mui-disabled {
    opacity: 1 !important;
    span {
      opacity: 1 !important;
      font-weight: 700;
      color: #000000de;
      font-size: 12px;
    }
  }
}
.social-media-account {
  display: flex;
  align-items: center;
  gap: 20px;
}

.submmitted-at {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #696d72;
}

.individual-pagination {
  @include ar {
    margin-right: 35px;
  }

  @include en {
    margin-left: 35px;
  }
}

.pagination-card {
  display: flex;
  justify-content: space-between;
  padding: 25px 15px;
  box-sizing: border-box;
}
.radio-item-individual {
  margin-top: 42px;
  .radio-item span.choose {
    margin: 0;
    margin-inline-end: 25px;
  }
  .most-like {
    margin: 0;
    p {
      margin: 15px 0;
    }
  }
}
.scaleBox {
  overflow-x: auto;
  padding: 15px 10px;
  margin-top: 10px;
  .scaleFormControl {
    min-width: 100% !important;
    justify-content: start;
    .scaleContainer {
      display: flex;
      justify-content: space-around;
      gap: 10px;
      .valuesText {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.individual-box {
  .section-name-insights {
    margin-bottom: 30px;
    margin-top: 50px;
  }
  &:first-child {
    .section-name-insights {
      margin-top: 0;
    }
  }
  .card-style-individual {
    box-shadow: 0px 4px 8px 0px #0000000d;
    width: auto;
    border-radius: 4px;
    padding: 30px;
    margin: 20px 0;
    background-color: #ffffff;
  }
}
