@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
#ordered-table {
  margin-top: 30px;
  margin-bottom: 40px;
  font-family: "Figtree" !important;
  text-align: left;
  box-shadow: none;
  position: relative;
  overflow-y: hidden;
  .dragging-row {
    height: 100%;
    border: 1px solid #cbd5e1;
    box-shadow: 0px 20px 56px 0px #0000001a;
    border-radius: 4px;
  }
  .dragging-cell {
    height: 63px !important;
  }
  .draggable-cell {
    cursor: grab !important;
  }
  &.ordered-table-loading {
    // add enough height to show the loader
    min-height: 536px;
    #ordered-table-body {
      opacity: 0;
    }
  }

  @include ar {
    text-align: right;
  }
  .created-by-tb {
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .status {
    padding: 7px 12px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    width: auto;
    font-size: 14px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    text-transform: capitalize;
    text-wrap: nowrap;
    &.collecting {
      color: #3b873e;
      border-color: rgba(76, 175, 80, 0.5);
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #4caf50;
    }
    &.paused {
      color: #616161;
      background: rgba(0, 0, 0, 0.09);
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
    &.unapproved {
      color: #ed6c02;
      background: rgba(237, 108, 2, 0.08);
      border: 1px solid rgba(237, 108, 2, 0.5);
    }
    &.reـauthenticated {
      color: #ed6c02;
      background: rgba(237, 108, 2, 0.08);
      border: 1px solid rgba(237, 108, 2, 0.5);
    }
    &.unapproved-channel {
      color: #616161;
      background: rgba(0, 0, 0, 0.09);
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
    &.active {
      color: #3b873e;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0.9) 100%
        ),
        #4caf50;
      border: 1px solid rgba(76, 175, 80, 0.5);
    }
    &.deactivated,
    &.locked,
    &.deActiveStatus {
      color: #f44336;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0.9) 100%
        ),
        #f44336;
      border: 1px solid rgba(244, 67, 54, 0.5);
    }
    &.deActiveStatus {
      text-transform: unset;
    }
    &.invited {
      color: #f9a700;
      background: rgba(249, 167, 0, 0.08);
      border: 1px solid rgba(237, 108, 2, 0.5);
    }
    &.Re-authenticate {
      color: #ed6c02;
      background: rgba(237, 108, 2, 0.08);
      border: 1px solid rgba(237, 108, 2, 0.5);
    }
    &.success {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #4caf50;
      border: 1px solid rgba(76, 175, 80, 0.5);
      letter-spacing: 0.16px;
      color: #3b873e;
    }
    &.failed {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #f44336;
      border: 1px solid rgba(244, 67, 54, 0.5);
      text-align: center;
      color: #f44336;
    }
    &.in_progress {
      background: rgba(237, 108, 2, 0.08);
      border: 1px solid rgba(237, 108, 2, 0.5);
      text-align: center;
      color: #ed6c02;
    }
  }
  tr {
    height: 63px !important;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
  }
  td {
    .menu-items {
      height: 24px;
      padding: 15px 8px;
      min-width: 24px;
      .ellipsis-icon {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.54);
      }
    }
    .open-button {
      background-color: #cbd5e1;
      border-radius: 4px;
      .ellipsis-icon {
        color: #757575;
      }
    }
  }

  .MuiTableHead-root {
    border-bottom: 0;
  }

  .MuiTableCell-head {
    font-family: "Figtree" !important;
    text-transform: capitalize !important;
    padding: 0px 16px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #475569 !important;
    background: #f1f5f9 !important;
    text-wrap: nowrap;
    @include ar {
      font-family: "IBM Plex Sans Arabic" !important;
      text-align: right;
    }
  }

  .MuiTableBody-root > :nth-of-type(odd) {
    background: #ffffff;
    &:hover {
      background: #f8fafc;
    }
  }

  .MuiTableBody-root > :nth-of-type(even) {
    background: #f1f5f9;
    &:hover {
      background: #e2e8f0;
    }
  }
  .warning {
    background: #fffbf5 !important;
  }
  .warning-icon-countainer {
    padding: 8px;
    display: flex;
    border-radius: 5px;
    margin: 0px 6px 0px -12px;
    cursor: default;
    @include ar {
      margin: 0 -12px 0px 6px;
    }

    &:hover {
      background-color: #f8e1cc;
    }
    .dashboard-warning-icon {
      margin: 0 !important;
    }
  }
  .MuiTableCell-body {
    padding: 0 16px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 0;
    @include ar {
      text-align: right;
    }
  }

  .empty-item {
    width: 100%;
    height: 441px;
    background: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    h2 {
      margin: 0;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #000000;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 14px;
      color: #80868c;
    }
  }
  .sortable-column-cell {
    display: inline-flex;
    gap: 5px;
    align-items: center;
    svg {
      width: 18px;
      height: auto;
      fill: #00000026;
    }
    &:hover {
      cursor: pointer;
      svg {
        fill: #00000099;
      }
    }
    .sortable-column-cell-active-icon {
      fill: #00000099;
    }
  }
  .ordered-table-loader-circular {
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
    max-height: 473px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    #circular-progress-component {
      color: #9b9b9b;
    }
  }
}
