@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.main-engagment-chat {
  position: relative;
  margin: 20px;
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
  box-shadow: inset -1px 8px 5px -7px rgba(0, 0, 0, 0.1);
  .main-chat-header {
    width: 100%;
    height: 6%;
    min-height: 45px;
    border-bottom: 1px solid #e2e8f0;
  }
  .main-chat-footer {
    width: 100%;
    height: 20%;
    min-height: 130px;
    border-top: 1px solid #e2e8f0;
  }
  .main-chat-contant {
    flex-grow: 1;
    &.no-rep {
      padding-block-end: 0px;
    }
    min-height: 10%;
    height: auto;
    .message-container {
      background: #ffffff;
      border: 1px solid #e2e8f0;
      border-radius: 3px;
      div.chat-message {
        .chat-message-media {
          .audio-media {
            &-container {
              display: flex;
              flex: 1;
              align-items: center;
            }
            &-time {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-size: 12px;
              font-weight: 400;
              line-height: 14.4px;
              color: #00000099;
            }
            &-controls {
              flex: 1;
              display: flex;
              align-items: center;
              gap: 10px;
              max-width: 170px;
              min-width: 170px;
              &-seek {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;
                cursor: pointer;
              }
              &-waveform-bar {
                width: 3px;
                background-color: #8fadcc;
                transition: background-color 0.1s linear;
                border-radius: 2px;
                &-progress {
                  height: 100%;
                  background-color: #334155;
                  border-radius: 2px;
                  transition: width 0.1s linear;
                }
              }
              &-waves-container {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                cursor: pointer;
              }
              &-play-pause {
                width: 33px;
              }
            }
          }
          .chat-location-message {
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;
            .leaflet-container {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              pointer-events: none;
            }
          }
          .chat-file-message {
            align-items: center;
            width: 100%;
            gap: 10px;
            display: grid;
            grid-template-columns: 30px 1fr 33px;

            @include ar {
              flex-direction: row-reverse;
            }
            &-image {
              min-width: 30px;
              display: flex;
              align-items: center;
              img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                box-sizing: border-box;
                border: 1px solid #94a3b8;
              }
            }
            &-icon {
              min-width: 30px;
              display: flex;
              align-items: center;
              img {
                width: 30px;
                height: 30px;
              }
            }
            &-info {
              display: flex;
              flex: 1;
              flex-direction: column;
              gap: 2px;
              overflow: hidden;
              &-name {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                color: #000000de;
              }
              &-type {
                font-size: 12px;
                font-weight: 400;
                line-height: 14.4px;
                color: #00000099;
              }
            }
            &-download {
              width: 33px;
              height: 33px !important;
              &:hover {
                background: #cee6f8;
              }
              svg {
                height: 17px;
                color: #334155;
              }
            }
          }
          &-whatsapp {
            &-contact {
              padding: 15px;
              width: 200px;
              box-sizing: border-box;
            }
            &-file {
              padding: 15px;
              width: 200px;
              box-sizing: border-box;
            }
            &-audio {
              width: 240px;
              padding: 15px;
              box-sizing: border-box;
            }
            &-location {
              width: 200px;
              padding: 10px;
              box-sizing: border-box;
              height: 130px;
              > div {
                height: 100%;
              }
            }
            p {
              padding: 0 !important;
            }
          }
        }
      }
      .pagination-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: #757575;
        font-size: 15px;
        font-weight: 500;
        margin: 20px 0;
      }
      .load-engagement-history-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin: 20px 0;
        .history-spinner-container {
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: #456eb2;
          }
        }
      }
      .main-massage-date {
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        z-index: 10;
        div {
          padding: 5px 10px;
          background-color: #f2f2f2;
          border-radius: 50px;
          color: #00000099;
          font-size: 12px;
          font-weight: 400;
          min-width: 108px;
          text-align: center;
          border: 1px solid #dedede;
        }
      }
      &.chat-main {
        border: none;
        border-radius: 0px;
        margin: 0;
        height: 100%;
        box-sizing: border-box;
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
          box-shadow: none;
        }
      }
      p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 400;
        color: #000000de;
        text-align: start;
        @include ar {
          text-align: end;
        }
      }
      &.message-container-text {
        width: auto;
        white-space: pre-line;
      }
      .cashed-replays {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border: 1px solid #e8eaf3;
        border-radius: 5px;
        margin: 10px;
        .cashed-replays-msg {
          max-width: 54%;
          color: #00000099;
          font-size: 14px;
        }
        .update-ai-cashed {
          svg {
            color: #f5b819;
          }
        }
      }
    }
    .message-container-private {
      padding: 0;
      .stats-value {
        span {
          span {
            font-weight: 600 !important;
          }
          display: inline-block !important;
        }
        @include ar {
          span {
            direction: ltr;
          }
        }
      }

      .posts-stats-value {
        p {
          text-transform: lowercase;
        }
        .stats-value-time {
          text-transform: capitalize;
        }
      }
    }
    .interaction-list-header {
      margin-bottom: 10px;

      @include ar {
        text-align: right;
      }
    }
    .chat-main-box {
      max-width: 100%;
      overflow: hidden;
      width: auto;
      margin: 20px;
      margin-inline-end: 60px;
      padding: 0;

      & + .main-post-state {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-block: 20px;
        flex-direction: column;
        gap: 15px;
        div {
          text-align: center;
          padding: 40px 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 400;
          color: #00000099;
        }
      }

      .interaction-list-user-data {
        .label-name {
          color: #000000de;
          font-weight: 600;
          font-size: 16px;
        }
        .label {
          color: #0c192a;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          border-bottom: none;
        }
        p {
          font-size: 11px;
        }
        .label-user-name {
          padding-top: 5px;
          padding-bottom: 10px;
          @include ar {
            direction: ltr;
          }
        }
      }
      .chat-message {
        // background: #e6f3fc;
        // border-radius: 6px 6px 6px 0px;
        // padding: 12px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        background: none;
        border-radius: none;
        padding: 0px;
        color: #1e293b;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        margin-block: 8px;
        @include ar {
          align-items: flex-end;
          direction: ltr;
          //border-radius: 6px 6px 0px 6px;
        }
        .card-translation-box {
          @include ar {
            direction: rtl;
            text-align: right;
          }
        }
        .chat-message-media {
          background: #e6f3fc;
          border-radius: 0px 12px 12px 12px;
          padding: 12px;
          &.temp-media {
            .temp-interactions-media {
              border-radius: 4px 0px 0px 0px;
              width: 175px;
              height: 130px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background: #e2e8f0;
              gap: 12px;
              .temp-media-text {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: #64748b;
              }
            }
          }
          @include ar {
            direction: ltr;
            border-radius: 12px 0px 12px 12px;
          }
        }

        p {
          background: #e6f3fc;
          border-radius: 0px 12px 12px 12px;
          padding: 12px;
          text-align: start;
          word-break: break-word;
          @include ar {
            text-align: end;
            border-radius: 12px 0px 12px 12px;
          }
        }
      }
      .date-chat {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #696d72;
        display: flex;
        align-items: center;
        gap: 5px;

        .replied-by-email {
          display: flex;
          align-items: center;
          gap: 5px;
          p {
            font-size: 12px;
            font-weight: 400;
            color: #696d72;
          }
          &::before {
            content: "";
            background-color: #696d72;
            width: 1px;
            height: 12px;
          }
        }
        @include ar {
          direction: ltr;
          justify-content: flex-end;
        }
        &.right {
          justify-content: flex-end;
          @include ar {
            justify-content: flex-start;
          }
        }
        .time-zone-space {
          margin-left: 0px;
          padding-bottom: 2px;
        }
      }
    }
    .chat-main-box-right {
      text-align: right;
      margin-left: auto;
      @include ar {
        text-align: left;
        margin-right: auto;
        margin-left: 20px;
        margin-inline-start: 60px;
      }
      .chat-message {
        text-align: left;
        align-items: flex-end;
        @include ar {
          align-items: flex-start;
        }
        .chat-message-media {
          background: #f1f5f9;
          border-radius: 12px 0px 12px 12px;
          padding: 12px;
          @include ar {
            direction: ltr;
            border-radius: 0px 12px 12px 12px;
          }
        }

        p {
          background: #f1f5f9;
          border-radius: 12px 0px 12px 12px;
          padding: 12px;
          text-align: end;
          word-break: break-word;
          @include ar {
            text-align: start;
            border-radius: 0px 12px 12px 12px;
          }
        }
      }
      &.chat-main-box {
        margin: 20px;
        margin-inline-start: 60px;
      }
    }

    .chat-main-box-right .interaction-list-user-data {
      p {
        @include ar {
          direction: ltr;
          text-align: left;
        }
      }
    }
    .latest-interaction-page,
    .interaction-page-list {
      .interaction-list-user-data {
        display: inline-block;
        vertical-align: middle;
        @include ar {
          text-align: right;
        }
        a {
          font-size: 15px;
          font-weight: 600;
          letter-spacing: 0;
          color: #000000;
        }
        p {
          font-size: 14px;
          letter-spacing: 0;
          color: #737a84;
          margin: 8px 0px 0px 0px;
          &.label {
            font-weight: 400;
            font-size: 15px;
            color: #000000;
            @include ar {
              direction: ltr;
            }
          }
          &.phoneNumber {
            font-size: 11px;
            direction: ltr;
          }
        }
        .sub-name {
          @include ar {
            direction: ltr;
            margin-top: 0px !important;
          }
        }
      }
    }
    .chat-message-media {
      margin-bottom: 10px;
    }
    .single-interactions-media {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      img {
        //max-width: 80px;
      }
    }
  }
  .closed-by-user {
    padding: 20px 15px;
    margin: 20px 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0px 20px 56px 0px #0000001a;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    svg {
      color: #4caf50;
      width: 22px;
    }
  }
  .closed-by-time {
    padding: 20px 15px;
    box-shadow: 0px 20px 56px 0px #0000001a;
    border-radius: 5px;
    margin: 15px 20px;
    .closed-by-time-main-warning {
      display: flex;
      gap: 10px;
      padding: 16px;
      border: 1px solid #fceac5;
      border-radius: 4px;
      background-color: #fdf0e6;
      font-size: 16px;
      font-weight: 500;
      svg {
        color: #ed6c02;
        width: 20px;
        height: 17px;
      }
      .closed-by-time-msg {
        font-size: 14px;
        font-weight: 400;
        margin-top: 8px;
        line-height: 20px;
      }
    }
  }
}
.completion-reason-span {
  margin-inline-start: 4px;
}

div.chat-media-tooltip-button {
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  top: -3px;
  &.copied {
    background: #3b873e;
    span::before {
      color: #3b873e;
    }
  }
}
