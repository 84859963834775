.explore-search-container {
  .MuiPaper-root {
    margin: 20px 0px;
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .MuiAlert-message {
    color: rgba(0, 0, 0, 0.6);
    span {
      font-weight: 700;
    }
  }
}
.explore-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #475569;
  font-size: 18px;
  font-weight: 700;
  margin-top: 40px;
}
.save-results-btn {
  &.MuiButton-root {
    padding: 7px 15px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #456eb2;
    border-radius: 2px;
    svg {
      font-size: 15px;
    }
  }
}
