@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
$error-color: #f44336;

.label-input-with-add-button.MuiTypography-root {
  font-size: 14px;
  line-height: 16.8px;
  color: #0f172a;
}

.input-label-underlined {
  border-bottom: 1px dashed rgba(100, 116, 139, 0.5);
  @include en {
    padding-bottom: 3px;
  }
}

.input-field-with-add-button.MuiOutlinedInput-root {
  width: 100% !important;
  height: 40px;
  margin-block-start: 10px;
  padding-right: 0px;
  input {
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    height: 38px;
    padding: 0px 15px;
    &::placeholder {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.38);
      @include ar {
        text-align: right;
      }
    }
  }
  @include ar {
    height: 40px;
    border-radius: 0px 4px 4px 0px;
    .MuiOutlinedInput-input {
      padding: 10px;
    }
  }
  &.Mui-error {
    .add-btn-with-plus {
      color: $error-color;
      @include en {
        border-left: 2px solid $error-color;
      }
      @include ar {
        border-right: 2px solid $error-color;
      }
    }
  }
  .add-btn-with-plus {
    display: none;
    line-height: 2 !important;
  }
  &.Mui-focused {
    .add-btn-with-plus {
      display: block;
    }
  }
}

.keyword-error-msg-form {
  color: $error-color;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-top: 5px;
  padding-inline-start: 12px;
  ul {
    list-style-type: disc;
  }
  @include ar {
    text-align: right;
  }
}

.copy-icon {
  color: #cbd5e1;
  position: relative;
  top: 5px;
  cursor: pointer;
  font-size: 20px !important;

  @include en {
    left: 7px;
  }

  &:hover {
    color: #64748b;
  }
}
