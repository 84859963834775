@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.ai-agents-header-wrapper {
  padding: 4px 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #e2e8f0;
  display: flex;

  .conainer-engagement-inbox {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }
}

.main-ai-agents-wrapper {
  height: calc(100vh - 121px);
  display: flex;
  &.main-ai-agents-wrapper-reduced-height-1 {
    height: calc(100vh - 169px);
  }
  &.main-ai-agents-wrapper-reduced-height-2 {
    height: calc(100vh - 217px);
  }
  &.main-ai-agents-wrapper-reduced-height-3 {
    height: calc(100vh - 265px);
  }
}

.ai-agents-analytics-header-switch-menu {
  top: 122px;
}

.ai-agents-Warning-wrapper {
  padding: 5px 16px;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid #fceac5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(237, 108, 2, 0.15);

  .ai-Warning-title-wrapper {
    gap: 12px;
    display: flex;
    align-items: center;
  }

  .ai-Warning-icon {
    color: #ed6c02;
  }

  .ai-Warning-title {
    font-size: 14px;
    line-height: 20px;
    color: #873e01;
    margin: 0;
  }

  .ai-Warning-button.luc-Button {
    text-transform: uppercase;
    color: #873e01;

    &:hover {
      background-color: transparent;
    }
  }
}

.ai-agent-snackBar-message {
  max-width: 240px;
}
.ai-agent-header-message.MuiTypography-root {
  font-weight: 400;
  font-size: 14px;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  color: #6d201a;
}
.ai-agent-down-support-team {
  text-decoration: underline;
  cursor: pointer;
}
.close-ai-agent-header-alert-icon {
  font-size: 11px;
  color: #6d201a;
}

.ai-agent-header-alert-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffeeed;
  padding: 6px 16px;
  min-height: 36px;
}
.ai-agent-header-alert-start-section-container {
  display: flex;
  align-items: center;
  gap: 12px;
  .MuiIconButton-root{
    padding: 0;
  }
}
.ai-agent-limit-reached-end-section-container {
  display: flex;
  align-items: center;
}
.ai-agent-limit-reached-button.luc-Button {
  text-transform: uppercase !important;
  color: #6d201a;
  &:hover {
    background-color: transparent;
  }
}
