@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.delete-popup-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 25px;
  strong {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
  }
  span {
    display: block;
    margin-top: 15px;
  }
}
.delete-ques-modal {
  .MuiDialogActions-root {
    padding: 15px 24px 24px;
  }
}
.delete-sec-modal {
  .MuiDialogActions-root {
    padding: 15px 14px 24px;
  }
}

.configs-tab-container {
  width: 100%;
  max-height: calc(100vh - 207px);
  overflow-y: auto;
}

.content-tab-container {
  width: 100%;
  max-height: calc(100vh - 207px);
  overflow-y: auto;
  .content-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 15px 20px;
    > .title {
      font-weight: 600;
      color: #000000;
    }
    > .add-question-btn {
      color: #456eb2;
    }
  }

  .content-tab-empty-questions {
    padding: 0px 20px;
    p {
      color: #00000060;
      text-transform: lowercase;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .nav-question-item {
    position: relative;
    overflow: hidden;
    text-align: initial;
    min-height: 38px;
    &.active::before {
      content: "";
      position: absolute;
      top: 0px;
      width: 10px;
      height: 100%;
      background-color: #94a3b8;
      @include en {
        left: 0px;
      }
      @include ar {
        right: 0px;
      }
    }
    &.active.question-error {
      background-color: #feeceb !important;
      color: #621b16;
      &::before {
        background-color: #e31b0c !important;
      }
    }

    .question-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      height: 38px;
      @include en {
        padding: 6px 20px 6px 40px;
      }
      @include ar {
        padding: 6px 40px 6px 20px;
      }

      .question-title {
        overflow: hidden;
        text-align: initial;
        span {
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          font-weight: 400;
          margin: 0px 3px;
        }
      }
      .question-more-btn {
        visibility: hidden;
        &.active {
          visibility: visible !important;
        }
      }
      &:hover .question-more-btn {
        visibility: visible !important;
      }
    }
  }

  .section-accordion {
    box-shadow: none !important;
    &::before {
      display: none !important;
    }
    &.Mui-expanded {
      margin: 0 !important;
      .btns-container .add-btn-box {
        display: inline !important;
      }
    }

    .sticky-summary {
      position: sticky;
      background: #ffffff;
      top: 0;
      z-index: 1;
    }
    .section-accordion-header {
      display: flex;
      flex-direction: row-reverse !important;
      min-height: 46px !important;
      @include en {
        padding: 5px 0 5px 25px;
      }
      @include ar {
        padding: 5px 25px 5px 0;
      }
      &:hover {
        background-color: #f8fafc;
        .btns-container .more-btn-box {
          display: inline !important;
        }
      }
      &.section-error {
        background-color: #feeceb;
        color: #621b16;
        svg {
          color: #621b16;
        }
      }
      .MuiAccordionSummary-expandIconWrapper {
        @include ar {
          transform: rotate(180deg) !important;
        }
        &.Mui-expanded {
          @include en {
            transform: rotate(90deg) !important;
          }
          @include ar {
            transform: rotate(90deg) !important;
          }
        }
      }
      .Mui-expanded.MuiAccordionSummary-contentGutters {
        margin: 0;
      }
      svg {
        color: #000000;
      }
      > div {
        margin: 0;
      }
      .accordion-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        width: -webkit-fill-available;
        padding: 0px 8px;
        .accordion-section-title {
          overflow: hidden;
          text-wrap: nowrap;
          font-weight: 600;
        }
        .question-title {
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          font-weight: 600;
        }
        .btns-container {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          min-width: 75px;
          .add-btn-box {
            display: none;
          }
          .more-btn-box {
            display: none;
            &.active {
              display: inline;
            }
          }
        }
      }
    }

    .section-accordion-details {
      padding: 0;
      margin: 0;

      .empty-section-title {
        color: #00000060;
        font-weight: 400;
        font-size: 14px;
        padding: 5px 40px 10px 40px;
      }
    }
  }
}
.MuiAccordionSummary-content {
  width: 90%;
  margin-left: 10px;
}
