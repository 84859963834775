@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.autocomplete-account-list {
  .tags-container {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .MuiAutocomplete-inputRoot {
    @include ar {
      padding-right: 10px;
      padding-left: 40px;
    }
  }

  .MuiAutocomplete-input {
    @include ar {
      padding-right: 0px;
      direction: rtl !important;
    }
  }

  .enable-input-field {
    input.MuiAutocomplete-input {
      display: block;
      direction: ltr;
    }
  }

  .disable-input-field {
    input.MuiAutocomplete-input {
      display: none;
      right: 0px;
    }
  }

  .MuiAutocomplete-endAdornment {
    @include ar {
      left: 12px;
      right: auto;
    }
    @include en {
      right: 7px;
    }
  }

  .MuiTextField-root {
    .MuiInputLabel-shrink {
      margin: -8px 0px;
    }
  }
}

.MuiAutocomplete-listbox {
  .MuiTypography-root {
    .searchable-checkbox-labels-item-container {
      display: flex;
      .options-with-count {
        display: inline-block;
        margin: 0 3px;
        color: #00000099;
        padding-right: 6px;
      }
      .options-with-count-label {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
