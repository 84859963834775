.form-footer-text{
  color: rgba(0, 0, 0, 0.60);
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  text-align: center;
}
.main-form-box{
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 40px 0;
  max-width: 436px;
  min-width: 436px;
  margin: auto;
  > div {
    flex-grow: 1;
  }
}
.form-title{
  color: rgba(0, 0, 0, 0.87);
  font-size: 38px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 20px;
}
.form-description{
  margin: 0;
  color: rgba(0, 0, 0, 0.60);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 25px;
  a{
    color: #456EB2;
  }
}
.form-login-text-box{
  margin-bottom: 80px;
}
.group-field{
  margin-bottom: 25px;
}
.label-field.MuiFormLabel-root.MuiInputLabel-root{ 
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  .forget-span{
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .label-field-required{
    color: #F44336;
  }
}
.text-field.MuiFormControl-root.MuiTextField-root{
  .MuiInputBase-input.MuiOutlinedInput-input{
    padding: 12.5px 12px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    line-height: 24px;
    &::placeholder{
      color: rgba(0, 0, 0, 0.38);
    }
  }
  .MuiInputBase-adornedEnd{
    padding-right: 0;
    padding-inline-end: 14px;
  }
}
.text-field.MuiFormControl-root.MuiTextField-root{
  .Mui-error{
    .MuiInputBase-input.MuiOutlinedInput-input{
      &::placeholder{
        color: #F44336;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 642px) {
  .main-form-box {
    max-width: 100%;
    min-width: 100%;
  }
}


.divider-wrapper{
  margin: 60px 0;
  display: flex;
  align-items: center;
  .box-or{
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    line-height: 24px;
    margin: 0 20px;
  }
  .box-line{
    flex-grow: 1;
    height: 1px;
  }
  .first-line{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.29) 100%);
  }
  .sec-line{
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.29) 100%);
  }
}

.show-pass-icon{
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.error-text-msg{
  color: #F44336;
  font-size: 12px;
  line-height: 20px; 
  margin-top: 5px;
  margin-bottom: 0;
  margin-inline-start: 12px;
}
.error-text-msg.main-error{
  margin: 0 0 25px 0;
}
.account-text{
  color: rgba(0, 0, 0, 0.60);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 60px 0 0;
  span{
    color: #456EB2;
    cursor: pointer;
  }
}
.password-errors-box{
  margin-bottom: 25px;
  .password-errors-heading{
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .password-error{
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 0;
    display: block;
    svg{
      margin-inline-end: 10px;
      color: rgba(0, 0, 0, 0.26);
    }
    .error-check{
      color: #4CAF50;
    }
    .error-fail{
      color: #F88078;
    }
    span{
      color: rgba(0, 0, 0, 0.60);
    }
  }
}
.half-input-wrapper{
  display: flex;
  gap: 20px;
  .half-wrapper{
    width: calc(50% - 10px);
  }
}
.form-new-user-text-box{
  margin-bottom: 50px;
}
.control-label-field.MuiFormControlLabel-root{
  margin: 0;
  .MuiFormControlLabel-label{
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
.form-reset-text-box{
  margin-bottom: 20px;
}
.cloudflare-wrapper{
  margin-bottom: 25px;
  .cloudflare-box{
    iframe{
      width: 100%!important;
    }
  }
}
@media (max-width: 1770px) {
  .main-form-box {
    padding: 20px 0;
  }
  .form-login-text-box {
    margin-bottom: 45px;
  }
  .form-title {
    font-size: 34px;
    margin-bottom: 10px;
  }
  .group-field {
    margin-bottom: 20px;
  }
  .cloudflare-wrapper{
    margin-bottom: 20px;
  }
  .text-field.MuiFormControl-root.MuiTextField-root{
    .MuiInputBase-input.MuiOutlinedInput-input{
      padding: 10.5px 12px;
    }
  }
  
  .divider-wrapper {
    margin: 20px 0;
  }
  .password-errors-box{
    margin-bottom: 20px;
    .password-errors-heading{
      margin-bottom: 15px;
    }
    .password-error{
      font-size: 15px;
      margin-bottom: 8px;
    }
  }
  .account-text {
    font-size: 14px;
    margin: 20px 0 0;
  }
  .form-new-user-text-box {
    margin-bottom: 20px;
  }
}