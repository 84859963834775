//variables colors
$primaryColor: #456EB2 ;
$mainWhite: #fff;
$mainBlack: #0c192a;
$monitorText: #009cdc;

$lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
$darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);

$statisticsBoxShadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

$mainBorderRadius: 2px;
$mainTransition: all 0.3s linear;

//btns-Colors
$blueColor: #199bdf;
$redColor: #ed1846;
$greyColor: #e2e8f0;

//btns-Status
$successColor: #ecf5da;
$neturalColor: #e2e8f0;
$errorColor: #ffd8e0;
$warningColor: #fceac5;

//fonts
$graphikReg: "Graphik-Regular";
$graphikBold: "Graphik-Bold";
$graphikLight: "Graphik-Light";
$dubaiReg: "Dubai-Regular";
$dubaiBold: "Dubai-Bold";
$dubaiLight: "Dubai-Light";

//sizes
$maxWidth: 40px;
$smallWidth: 85px;
$fullWidth: 100%;
