//calender
@mixin sm {
  @media only screen and (max-width: 991px) {
    @content;
  }
}
@mixin md {
  @media only screen and (max-width: 1002px) {
    @content;
  }
}
@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.publish-engagement-container {
  position: relative;
  @include md {
    width: 100%;
    overflow-x: scroll !important;
    &::-webkit-scrollbar {
      height: 8px !important;
    }

    .publish-sidebar-container {
      position: fixed;
      inset-inline-start: 0;
      background-color: #fff;
      z-index: 11;
      height: 92%;
    }
    .rbc-calendar {
      margin-inline-start: 266px;
    }
  }
}
.calendar-section-container {
  position: relative;
  max-width: calc(100% - 265px);
  width: calc(100% - 265px);
  min-width: 1102px;

  .rbc-toolbar {
    padding: 15px 20px 17px 20px;
  }
  .MuiToolbar-root {
    @include en {
      min-height: 66px;
    }
    @include ar {
      min-height: 65.5px;
    }
  }
  .toolbar-calender {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 9.5px;
    padding-top: 9.5px;
    padding-inline-start: 20px;
    border-bottom: 1px solid #e2e8f0;
  }
  .date-format-steps,
  .date-view-content {
    display: flex;
    align-items: center;
  }
  .date-format-title {
    font-weight: 700;
    font-size: 20px;
  }
  .navigate-steps-buttons {
    @include ar {
      rotate: 180deg;
      display: flex;
      flex-direction: row-reverse;
    }
    svg {
      color: #000000de;
    }
  }
  .today-btn-content.MuiButtonBase-root {
    color: #000000de;
    padding: 5px 8px 5px 8px;
    border-color: #0000003b;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .select-view-items-cale {
    .MuiSelect-select {
      padding: 6px 33px 6px 16px; //check arabic
      color: #000000de;
      font-size: 14px;
      font-weight: 500;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    @include ar {
      .MuiSelect-icon {
        left: 0 !important;
        right: 4px;
      }
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #0000003b !important;
      &:hover {
        border-color: #0000003b !important;
      }
    }
  }
  .publish-filters-content {
    z-index: 99;
    #add-publish-btn {
      margin-inline-start: 10px;
    }
    position: absolute;
    top: 15px;
    @include en {
      right: 25px;
    }
    @include ar {
      left: 25px;
    }
  }
  .date-picker-publish-header {
    &:hover {
      background-color: #f5f5f5;
    }
    &.open-picker {
      background-color: #f5f5f5;
    }
    margin-inline: 18px;
    .MuiFormControl-root {
      cursor: pointer;
    }
    .date-picker-calender {
      .MuiButtonBase-root {
        pointer-events: auto;
        cursor: pointer;
      }
      .MuiInputBase-input {
        padding: 7px 8px;
        pointer-events: none !important;
      }
      .MuiInputBase-root {
        width: 180px;
        pointer-events: none;
      }
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
  }
  .date-view-content {
    position: relative;
    @include en {
      right: 65px;
    }
    @include ar {
      left: 80px;
    }
  }
  .rbc-ellipsis,
  .rbc-show-more,
  .rbc-row-segment .rbc-event-content,
  .rbc-event-label {
    text-align: start;
  }
  .rbc-day-slot .rbc-event-label {
    padding: 0;
  }
  .rbc-month-row {
    .rbc-day-bg {
      @include ar {
        border-left: 1px solid #e2e8f0;
      }
    }
  }
  .rbc-allday-cell {
    .rbc-day-bg {
      @include ar {
        border-left: 1px solid #e2e8f0;
      }
    }
  }
  .rbc-button-link {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
  .rbc-month-row .rbc-day-bg,
  .rbc-month-row + .rbc-month-row,
  .rbc-day-bg + .rbc-day-bg {
    border-color: #e2e8f0;
  }
  .calendar-gmt {
    position: absolute;
    top: 79px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    inset-inline-start: 20px;
  }
  .tooltip-calender-btn {
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      .publish-name {
        color: rgba(0, 0, 0, 0.87);
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
      }
    }
    svg,
    img {
      margin-inline-end: 3px;
      font-size: 12px;
    }
    svg.fa-twitter {
      color: #1da1f2;
    }
    svg.fa-instagram {
      color: #e1306c;
    }
    svg.fa-facebook-f {
      color: #1877f2;
    }
  }
}

.calendar-section-container {
  .rbc-month-view {
    padding-inline-start: 20px;
    border: none;
  }
  .rbc-time-view .rbc-allday-cell {
    min-height: 60px;
  }
  .rbc-time-view {
    border: none;
    padding-inline-start: 20px;
  }
  .rbc-time-content {
    border-top: 0;
    padding-top: 4px;
    margin-top: -4px;
    .rbc-timeslot-group {
      min-height: 71px;
    }
  }
  .rbc-time-gutter {
    margin-top: -7px;
    .rbc-timeslot-group {
      border: none;
      display: flex;
      flex-direction: column;
      width: 72px;
      .rbc-time-slot {
        display: flex;
        align-items: start;
        @include ar {
          direction: ltr;
        }
      }
      .rbc-label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        padding: 0 9px;
      }
    }
  }
  .rbc-today {
    background: #fff;
  }
  .rbc-day-slot {
    .rbc-time-slot {
      border-top: none;
      margin-top: -10px;
    }
    .rbc-timeslot-group {
      border: 0.5px solid #e2e8f0;
      position: relative;
      z-index: 1;
      &:hover {
        background-color: #e6f3fc;
        cursor: pointer;
      }
    }
    .rbc-event-label {
      color: rgba(0, 0, 0, 0.87);
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 20px;
      line-height: normal;
      position: relative;
      padding-inline-start: 15px;
      &::after {
        content: "";
        top: 0;
        inset-inline-start: 0;
        background-image: url("./../../../../../../../images/engagements-images/hour.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 9px;
        height: 15px;
        position: absolute;
      }
    }
    .rbc-event-content {
      overflow: hidden;
      color: rgba(0, 0, 0, 0.87);
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 600;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      .tooltip-calender-btn {
        padding: 5px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        inset-inline-start: 0;
        display: flex;
        align-items: end;
        justify-content: start !important;
        text-align: start;
        span {
          display: flex;
          align-items: center;
          > p {
            font-style: normal;
            line-height: normal;
            color: rgba(0, 0, 0, 0.87);
            font-size: 12px;
            font-weight: 600;

            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            width: 95%;
            overflow: hidden;
          }
        }
      }
    }
  }
  .rbc-events-container {
    .rbc-event {
      height: 45px !important;
      padding: 5px;
      background: #e6f3fc;
      border-radius: 3px;
      border: 1px solid #5692d7;
      transition: 0.4s;
      text-align: start !important;
      z-index: 2;
      &:hover {
        background: #c3e1f8;
      }
    }
    .rbc-event.published-event {
      .rbc-event-label {
        &::after {
          background-image: url("../../../../../../../images/engagements-images/done.svg");
        }
      }
    }
    .rbc-event.failed-event {
      .rbc-event-label {
        color: #e50035;
        &::after {
          background-image: url("../../../../../../../images/engagements-images/fail.svg");
        }
      }
    }
  }
  .rbc-current-time-indicator {
    background: #e1306c;
    height: 2px;
    display: flex;
    align-items: center;
    margin-inline-start: -6px;
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #e1306c;
      border-radius: 50%;
    }
  }
  .tooltip-calender {
    h3 {
      background-color: red;
    }
  }
  .tooltip-calender-content {
    padding: 13px 13px 18px;
  }
  .rbc-time-header.rbc-overflowing {
    margin: 0 !important;
    border: 0 !important;
  }
  .rbc-time-content > * + * > * {
    border: 0;
  }
  .rbc-time-header-content {
    @include ar {
      border-left: 4px solid transparent;
    }
    @include en {
      border-left: 0px solid transparent;
      border-right: 6px solid transparent;
      font-weight: 900;
    }
  }
  .rbc-day-slot .rbc-events-container {
    margin: 0;
  }
  .rbc-row.rbc-time-header-cell {
    min-height: 40px;
  }
  .rbc-header {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 12px 8px;
    border-color: #e2e8f0;
  }
  .rbc-day-slot .rbc-background-event {
    margin-bottom: 3px !important;
    margin-inline-start: 5px;
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    &:focus {
      outline: 0;
    }
    &:hover {
      border-radius: 6px;
      background: #e6f3fc;
    }
  }
  .rbc-event-content {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .rbc-button-link.rbc-show-more {
    padding-inline-start: 5px !important;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    padding-top: 1px;
  }
}
#tooltip-calender-content {
  .MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    width: 220px;
    padding: 13px;
    .MuiTypography-root.MuiTypography-body1 {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
    }
    p.MuiBox-root {
      overflow: hidden;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-overflow: ellipsis;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.calendar-section-container.day-calendar-section {
  .rbc-day-slot {
    .rbc-event-content {
      .tooltip-calender-btn {
        span {
          > p {
            width: 100%;
          }
        }
      }
    }
  }
}

.MuiCalendarPicker-root {
  @include ar {
    direction: ltr;
  }
}
.rbc-day-bg {
  position: relative;
  z-index: 10;
  &:hover {
    background-color: #e6f3fc;
    cursor: pointer;
  }
}
.rbc-month-row {
  .rbc-day-bg {
    position: relative;
    z-index: 4;
  }
}
