@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.sla-details-side-bar-wrapper {
  .sla-details-header {
    border-bottom: 1px solid #e2e8f0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    color: #000000de;
    font-size: 16px;
    font-weight: 700;
    svg {
      cursor: pointer;
      width: 14px;
      height: 23px;
      color: #757575;
    }
  }
  .sla-details-main-content {
    max-height: calc(100vh - 185px);
    overflow: auto;
    .main-sla-loader {
      .sla-loader-sec {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        &.with-border {
          border-bottom: 1px solid #e2e8f0;
          border-top: 1px solid #e2e8f0;
          gap: 10px;
        }
        .sla-loader-sec-inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .main-sla-container {
      .sla-logs-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-top: 1px solid #e2e8f0;
        padding-bottom: 40px;
        .sla-logs-container-title {
          font-size: 14px;
          font-weight: 600;
          line-height: 16.8px;
          letter-spacing: 0.025em;
          color: #000000de;
          display: inline-block;
          border-bottom: 1px dashed #94a3b8;
        }
        .sla-logs-list {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .sla-logs-item-separator {
            border-bottom: 1px dashed #cbd5e1;
          }
          .sla-logs-item {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .sla-logs-item-date {
              display: flex;
              gap: 3px;
              @include ar {
                flex-direction: row-reverse;
                justify-content: flex-end;
              }
              span {
                font-size: 12px;
                font-weight: 600;
                line-height: 14.4px;
                color: #00000061;
                @include ar {
                  direction: ltr;
                }
              }
            }
            .sla-logs-item-details {
              font-size: 14px;
              font-weight: 500;
              line-height: 16.8px;
              color: #00000099;
            }
          }
        }
      }
      .main-sla-contant {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &.with-border {
          border-bottom: 1px solid #e2e8f0;
          border-top: 1px solid #e2e8f0;
        }
        .sla-contant-title {
          color: #000000de;
          font-size: 14px;
          font-weight: 600;
          span {
            border-bottom: 1px dashed #94a3b8;
          }
        }
        .main-sla-counter-empty {
          color: #00000061;
          font-size: 14px;
        }
        .sla-info-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 500;
          color: #00000099;
          .sla-info-tooltips {
            color: #000000de;
            display: flex;
            align-items: center;
            gap: 5px;
            .sla-engag-copy {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;
              border-radius: 4px;
              cursor: pointer;
              &:hover {
                background-color: #0000000a;
              }
              svg {
                color: #000000de;
              }
            }
          }
        }
        .main-sla-counter {
          gap: 20px;
          display: flex;
          flex-direction: column;

          .main-sla-counter-item {
            color: #00000099;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-bottom: 1px dashed #cbd5e1;
            padding-bottom: 20px;
            &.last-item {
              border: none;
            }
            .sla-counter-progress {
              display: flex;
              align-items: center;
              gap: 10px;
              span {
                width: 20px !important;
                height: 20px !important;
                margin-top: 5px;
              }
              .sla-counter-time {
                font-size: 12px;
                font-weight: 600;
                background-color: #0000000f;
                border-radius: 4px;
                padding: 4px;
                display: flex;
                .sla-counter-slash {
                  margin: 0 5px;
                }
              }
              .sla-counter-date {
                font-size: 12px;
                font-weight: 600;
                direction: ltr;
              }
              .sla-counter-progress-done {
                width: 20px;
                height: 20px;
                color: #00000061;
              }
              &.green {
                .sla-counter-time {
                  background-color: #ebf3ec;
                  color: #3b873e;
                }
                .sla-progress-live {
                  svg {
                    color: #3b873e;
                  }
                }
              }
              &.orange {
                .sla-counter-time {
                  background-color: #fff5e5;
                  color: #ff9900;
                }
                .sla-progress-live {
                  svg {
                    color: #ff9900;
                  }
                }
              }
              &.red {
                .sla-counter-time {
                  background-color: #feeceb;
                  color: #f44336;
                }
                .sla-progress-live {
                  svg {
                    color: #f44336;
                  }
                }
              }
            }
            .nrt-blocks-list {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-inline-start: 30px;
            }
          }
        }
      }
    }
  }
}

.assign-item-countainer-sla-sidebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .assign-item-profile {
    position: relative;
    img {
      width: 34px;
      height: 34px;
      border-radius: 50%;
    }
  }
  .assign-item-details {
    font-size: 14px;
    font-weight: 400;
    color: #000000de;
    .assign-item-state {
      color: #696d72;
      font-size: 12px;
      &.team-assign-item {
        display: flex;
        align-items: center;
        gap: 5px;
        div {
          background-color: #4caf50;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
    }
  }
}
#unassign-btn-sla {
  padding: 15px 16px;
  border-top: 1px solid #e2e8f0;
  font-size: 14px;
  color: #e31b0c;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  &.item-loading {
    background-color: #fff;
    cursor: progress;
  }
}
#empty-result-sla {
  padding: 0;
  margin: -14px -16px;
  .empty-msg-sla {
    padding: 15px 16px;
  }
}
#sla-assigns-selects {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    label {
      top: -6px;
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(14px, -3px) scale(0.75);
      }
    }
    input {
      height: 7px;
    }
  }
  @include ar {
    .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
      label {
        top: -6px;
        left: unset;
        right: 25px;
        &.Mui-focused,
        &.MuiFormLabel-filled {
          right: 175px;
          transform: translate(178px, -3px) scale(0.75);
        }
      }
    }
    .MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
      padding-right: 9px;
      padding-left: 39px;
      .MuiAutocomplete-endAdornment {
        right: unset;
        left: 9px;
      }
      fieldset {
        text-align: end;
      }
    }
  }
}

div.sidebar-sla-logs-tooltip {
  padding: 13px 13px 18px 13px;
}
