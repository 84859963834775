@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

#create-report-stepper-container {
  background-color: #ffffff;
  border-radius: 10px;
  filter: drop-shadow(0 4px 4px #e3e3e3);

  .create-report-stepper-header {
    box-shadow: 0 4px 8px #00000014;
    padding: 18px;
    .MuiStepper-root.MuiStepper-horizontal {
      width: auto;
      max-width: 1000px;
    }

    .create-report-stepper-header-item {
      display: flex;
      flex-direction: row;
      gap: 8px;
      font-weight: 400;
      font-size: 14px;
      @include ar {
        font-size: 15px;
      }

      .MuiStepLabel-iconContainer {
        padding: 0;
        &.Mui-disabled svg {
          color: rgba(0, 0, 0, 0.38);
          text {
            fill: #ffffff;
          }
        }
      }
      span.Mui-active,
      span.Mui-completed {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.87);
        @include ar {
          font-weight: 400;
        }
      }
    }
  }

  .create-report-stepper-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #efefef;
    padding: 25px;
    .create-report-stepper-footer-next-cancel-btns-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }
  }

  .create-report-stepper-content-wrapper {
    min-height: 250px;
    padding: 56px 96px;

    .circular-loading {
      top: -50%;
      transform: translateY(100%);
    }

    .create-report-section-title {
      color: #232b34;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 18px;
    }

    .create-report-input-wrapper {
      .create-report-input-label {
        color: #0f172a;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 8px;
        @include ar {
          font-family: "Dubai";
          font-size: 16px;
        }
      }

      .MuiSelect-select.MuiInputBase-input {
        @include ar {
          padding-inline: 14px;
        }
      }

      .MuiFormHelperText-root {
        @include ar {
          direction: rtl;
          text-align: start;
        }
      }
    }

    .create-report-information-step-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .create-report-omnichannel-report-settings-step-container {
      .create-report-omnichannel-category-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border: 1px solid #e2e8f0;
        border-radius: 4px;
        padding: 18px 23px;
        margin: 0;
        cursor: pointer;
        .MuiRadio-root {
          display: none;
        }

        .category-item-title-icon-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 15px;

          svg {
            color: #80868c;
          }
          p {
            color: #334155;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }

        &.category-item-selected {
          border: 2px solid #456eb2;
          box-shadow: 0 4px 8px #00000014;

          svg {
            color: #456eb2;
          }
        }

        &.category-item-disabled {
          cursor: default;

          svg,
          p {
            color: rgba(51, 65, 85, 0.5);
          }
        }
      }

      .create-report-omnichannel-select-channels-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        label {
          margin-inline: 0px 10px;
          @include ar {
            margin-inline: 0px 10px;
          }
        }
      }
    }

    .create-report-monitor-type-container {
      margin-bottom: 20px;
      .create-report-monitor-type-item {
        background-color: #ffffff;
        border: 2px solid #e2e8f0;
        border-radius: 4px;
        padding: 20px;
        min-height: 80px;
        h5.monitor-type-title-wrapper {
          color: #475569;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 12px;
          svg {
            margin-right: 12px;
          }
          @include ar {
            svg {
              margin-left: 12px;
              margin-right: 0;
            }
          }
        }
        p.monitor-type-desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #64748b;
          margin: 0;
          @include ar {
            font-weight: 500;
          }
        }
        &.clickable {
          cursor: pointer;
        }
        &.clickable:hover,
        &.active {
          border: 2px solid #456eb2;
          box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
          h5 {
            color: #334155;
            @include ar {
              font-weight: 600;
            }
            svg {
              color: #456eb2;
            }
          }
        }
      }
    }

    .MuiFormHelperText-root {
      margin-inline: 14px;
      @include ar {
        direction: rtl;
        text-align: start;
      }
    }
  }
}

#create-report-select-input-menu-item {
  @include ar {
    font-family: "Dubai";
  }

  &.create-report-multiple-select-input-menu-item {
    font-size: 14px;
    padding: 0px 16px;
  }
}

#create-report-channels-select-menu-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  @include ar {
    font-family: "Dubai";
  }

  .channel-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .channel-info-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-weight: 500;
      font-size: 14px;
      @include ar {
        font-family: "Dubai";
      }
    }
    .channel-info-paused-status {
      font-size: 14px;
      color: #696d72;
      &.collecting {
        color: #4caf50;
      }
    }
  }

  &.create-report-select-all-menu-item {
    font-size: 16px;
  }
}

// *********************** Old Shared Code ***********************
.select-all {
  .MuiCheckbox-root {
    margin-inline-end: 20px;
  }
}

.report-multi-menuItem {
  padding: 0 10px !important;
  .MuiListItemText-root {
    span {
      margin: 0;
      letter-spacing: 0.15px;
      @include ar {
        font-family: "Dubai" !important;
      }
    }
  }
}

.no-monitors-found {
  opacity: 1 !important;
}

.MuiCalendarPicker,
.MuiPickersArrowSwitcher {
  @include ar {
    direction: ltr;
  }
}
