@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.accounts-list-duplicate-dialog-container {
  .MuiDialog-paper {
    max-width: 700px;
    margin: auto;
  }

  .accounts-list-duplicate-dialog {
    min-width: 600px;
    .MuiPaper-root {
      width: 600px;
    }
    .MuiDialogContent-root {
      padding: 0px 24px;
      .MuiDialogContentText-root {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    .typography-message-bold {
      font-weight: 500;
    }
  }
}
