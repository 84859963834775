@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.single-intr {
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 20px #0b182a05;
  border-radius: 5px;
  padding: 30px 35px 30px 35px;
  margin-bottom: 30px;

  @include ar {
    text-align: right;
  }
}

.single-page-title {
  letter-spacing: 0;
  color: #0c192a;
  font-size: 20px;
  font-weight: bold;
  @include ar {
    text-align: right;
  }
}
.single-intr__header {
  margin-left: 0px !important;
  margin-right: 0px !important;
  p {
    font-size: 17px;
    letter-spacing: 0;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
.single-intr__icon {
  margin: 0 12px 0 0;
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
  font-size: 22px;
  display: block;
  width: 39px;
  height: 39px;
  align-items: center;
  display: flex;
  align-self: center;
  justify-content: space-around;
  border-radius: 3px;
  @include ar {
    margin: 0 0 0 12px;
  }

  .twitter {
    background: #009ff2 0% 0% no-repeat padding-box;
  }

  .googlemap {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .intercom {
    background: #0088f5 0% 0% no-repeat padding-box;
    width: 38px;
    height: 38px;
    border-radius: 5px;
    padding: 6px;
  }
  .facebook {
    background: #0a5bbd 0% 0% no-repeat padding-box;
  }
  .insta {
    img {
      width: 30px;
    }
  }
  .linkedin {
    background: #0a66c2;
  }
  .foursquare {
    background: #f82c76 0% 0% no-repeat padding-box;
  }
  &--gmail {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    font-size: 0;
  }

  &--twitter {
    background: #009ff2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 22px;
    height: 22px;
  }

  &--instagram {
    background: linear-gradient(
      32.85deg,
      #feda75 3.26%,
      #fa7e1e 20.98%,
      #d62976 45.42%,
      #962fbf 66.47%,
      #4f5bd5 84.19%
    );
    color: #ffffff;
  }

  &--facebook {
    background: #0866ff;
    color: #0a5bbd;
  }
  &--whatsapp {
    background: #25d366;
    color: #fff;
    border-radius: 4px;
  }
  &--linkedin {
    background-color: #0a66c2;
  }
  .outlook {
    background: #1194f7 0% 0% no-repeat padding-box;
  }
}
.single-intr__title {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #000000de;
  margin: 0px 10px;
}

.interaction-list__body--intercom {
  background: #0085f91a 0% 0% no-repeat padding-box !important;
  border-radius: 20px 20px 20px 0px !important;
}

.single-intr__meta {
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 10px;

  .theme-dropdown,
  .vertical-line,
  .sentiment-dropdown {
    display: inline-block;
    vertical-align: top;
  }
}

.single-intr__data {
  display: inline-block;
  float: right;
  margin-left: 0px !important;
  margin-right: 0px !important;

  p {
    font-size: 13px;
    letter-spacing: 0;
    color: #9b9b9b;
    margin: 15px;
  }

  p.positive {
    color: #89bb2a;
  }
  p.neutral {
    color: #f9a700;
  }
  p.negative {
    color: #e50035;
  }
  h5 {
    font-size: 13px;
    letter-spacing: 0;
    color: #626262;
    margin: 15px;
    font-weight: bold;
  }
  .verticalLine {
    width: 0px;
    height: 25px;
    border: 1px solid #d6d6d6;
    margin: 15px 10px 0px 10px;
  }
}

.single-intr__body {
  width: -webkit-fill-available;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #bebebe4b;
  border-radius: 5px;
  padding: 30px;
  display: inline-block;
  margin-top: 35px;
  display: block;
  p {
    font-size: 16px;
    line-height: 27px;

    color: #435966;
  }

  h5 {
    font-size: 15px;
    letter-spacing: 0;
    color: #969696;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  span {
    font-size: 13px;
    color: #ababab;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  h4 {
    color: #435966;
    font-family: "Graphik-Medium";
    margin-bottom: 18px;
  }
  a {
    text-decoration: underline;
    font-size: 16px;
    letter-spacing: 0;
    color: #000000;
    direction: ltr;
    display: block;
  }

  .user-profile {
    flex: 1;
    display: flex;

    img {
      border-radius: 50%;
      float: left;
      width: 46px;
      height: 46px;
      margin-right: 10px;
    }
    h2 {
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
    p {
      padding: 0;
      margin: 0;
    }
    h5 {
      margin-bottom: 20px;
    }

    @include ar {
      img {
        float: right;
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
}

.single-intr-page__title {
  p {
    font-size: 17px;
    color: #000000;
  }
  span {
    font-size: 20px;
    color: #0c192a;
  }
}

.single-intr__CstBlock {
  margin-bottom: 15px;
}
.single-intr__Agentblock {
  text-align: right;
  display: block;
  margin-bottom: 15px;
  @include ar {
    text-align: left;
  }
}
.single-intr__body--intercomAgent {
  width: fit-content;
  height: auto;
  background: #0000000b 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 0px 20px;
  padding: 5px 15px 5px 15px;
  width: auto;
  display: inline-grid;
  position: inherit;
}
.single-intr__name {
  text-decoration: underline;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
}
.single-intr__body--intercomCst {
  width: fit-content;
  height: auto;
  background: #0085f91a 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 20px 0px;
  padding: 5px 15px 5px 15px;
  @include ar {
    background: #0085f91a 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 20px 0px;
    display: inline-grid;
    position: inherit;
  }
}
.pull-right {
  float: right;
}
.single-intr__menu {
  margin: 0 0 0 52px;

  @include ar {
    margin: 0 52px 0 0;
  }
}
.single-intr__title {
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  margin-right: auto;
  @include ar {
    margin: 0;
    margin-left: auto;
    justify-content: flex-end;
  }
  &.tiktok-title {
    @include ar {
      direction: rtl;
      justify-content: flex-start;
    }
  }
}
.single-intr__userdata {
  margin-bottom: 40px;
}

.single-intr-page__header {
  margin: 0 0 10px 0;
  float: right;

  p {
    font-size: 12px;
    letter-spacing: 0;
    color: #737a84;
    margin: 5px;
    direction: ltr;
  }

  p.positive {
    color: #89bb2a;

    svg {
      margin-right: 7px;
    }
  }
  p.neutral {
    color: #f9a700;

    svg {
      margin-right: 7px;
    }
  }
  p.negative {
    color: #e50035;

    svg {
      margin-right: 7px;
    }
  }
  h5 {
    font-size: 13px;
    letter-spacing: 0;
    color: #626262;
    margin: 5px;
  }
  h4 {
    color: #435966;
    font-size: 19px;
    font-weight: 600;
    margin: 30px 0px 5px 0px;
  }

  .theme-item {
    text-transform: capitalize;
  }

  @include ar {
    float: left;

    p.neutral svg,
    p.negative svg,
    p.positive svg {
      margin-right: 0;
      margin-left: 7px;
    }
  }
}

.tweet-meta {
  margin-bottom: -15px;

  &-item {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-family: Figtree;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    &-icon {
      svg {
        color: #0000008a;
        height: 15px;
        width: auto !important;
      }
    }
    &-value {
      color: #000000 !important;
      font-size: 12px !important;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
    }
  }
  .tweet-date {
    font-size: 12px;
    letter-spacing: 0;
    color: #696d72;
    font-weight: 400;
    line-height: 14.4px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.tweet-media-photo {
  img {
    border-radius: 5px;
  }
}

.tweet-media {
  margin-bottom: 15px;

  video {
    border-radius: 5px;
  }
}

.tweet-media-1 {
  width: 350px;
  img {
    width: 100%;
    height: 197px;
  }
}

.tweet-media-2 {
  float: left;
  width: 175px;
  img {
    width: 100%;
    height: 197px;
  }
}

.tweet-media-3 {
  &:first-child {
    float: left;
    width: 175px;
    img {
      width: 100%;
      height: 198px;
    }
  }
  img {
    width: 100%;
    max-width: 175px;
    height: 99px;
  }
}

.tweet-media-4 {
  float: left;
  width: 175px;
  img {
    width: 100%;
    height: 99px;
  }
}
//single-intr Wrapper
.single-intr-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  .user-single-header {
    display: flex;
    &.hover-show {
      display: flex;
      outline: 8px solid transparent;
      &:hover {
        background: #0000000a;
        outline: 8px solid #0000000a;
        border-radius: 4px;
        cursor: pointer;
        .single-intr__title {
          text-decoration-line: underline;
        }
      }
    }
  }
  .interaction-brand-title {
    color: #000000de;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.015em;
  }
  .single-intr-type {
    border-inline-start: 1px solid #cbd5e1;
    padding: 2px 10px;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    margin: 0 10px;
    div {
      border: 1px solid #0000003b;
      padding: 5px;
      border-radius: 25px;
    }
  }
}
.interaction-list-instagram {
  background: #f1f5f9;
  border-radius: 5px;
  margin-right: 12px;
  height: 39px;
  width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg.svg-inline--fa.fa-w-14 {
    color: #fff;
    height: 21px;
    width: 16px;
  }
  img {
    width: 22px;
  }
}
.single-page-title .social-media-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.single-interaction-empty-data {
  height: 390px !important;
  .chart-no-data {
    margin-top: 15%;
  }
}

.gmb-single-intr-footer {
  margin-top: 25px;
  div {
    font-size: 12px;
    letter-spacing: 0;
    color: rgba(0, 0, 0, 0.6);
    direction: ltr;
  }
  span {
    font-size: 12px;
  }
}

.gmb-single-intr-body {
  border: 2px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  display: inline-block;
  margin-top: 35px;
  display: block;
}

.gmb-single-intr {
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 10px 20px 20px 20px;
  margin-bottom: 30px;

  @include ar {
    text-align: right;
  }
}

.gmb-single-intr-title {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  margin: 10px 10px 0 10px;
  width: 100%;
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include ar {
    justify-content: flex-end;
  }
}

.gmb-single-intr-icon {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  display: inline-block;
  font-size: 0;
  margin-top: 10px;
  padding: 7px 9px;
  border-radius: 3px;
  @include ar {
    padding: 1px 10px;
  }
}

.box-image {
  display: flex;
  justify-content: flex-start;
  .linkedin-reactions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-inline-end: 20px;
    > div {
      margin: 0;
      color: #80868c;
      font-size: 11px;
      font-weight: 400;
    }
  }
  svg {
    height: 18px;
  }
  justify-content: center;
}

.linkedin-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .hover-show {
    display: flex;
    outline: 8px solid transparent;
    &:hover {
      background: #0000000a;
      outline: 8px solid #0000000a;
      border-radius: 4px;
      cursor: pointer;
      .linkedin-created-by {
        text-decoration-line: underline;
      }
    }
  }
}

.linkedin-created-by {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #000000de;
}

.linkedin-external-link {
  color: #80868c;
  font-size: 17px;
}

.linkedin-text {
  color: #0c192a !important;
  font-weight: 400;
}

.linkedin-body {
  padding: 20px !important;
}
.tiktok-single-inter-body {
  border: 1px solid #efefef;
  border-radius: 4px;
  margin-top: 20px;
  padding: 20px;
  .tiktok-inter-name {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #0c192a;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 20px;
    &.hover-show {
      width: fit-content;
      outline: 8px solid transparent;
      &:hover {
        background: #0000000a;
        outline: 8px solid #0000000a;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .tiktok-single-video-body {
    display: flex;
    gap: 20px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 20px;
  }
  .tiktok-video-describtion {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
  .tiktok-video-craetor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #0f172a;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 15px;
    svg {
      color: #9e9e9e;
      width: 15px;
      height: 15px;
    }
  }
  .tiktok-main-desc {
    color: #80868c;
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    div {
      font-size: 16px;
      color: #0c192a;
    }
  }
  .tiktok-video-reactions {
    display: flex;
    border-top: 1px solid #0000000a;
    padding-top: 10px;
    color: #696d72;
    font-size: 12px;
    font-weight: 400;
    .tiktok-reactions-det {
      display: flex;
      gap: 10px;
      align-items: center;
      padding-inline-end: 10px;
      margin-inline-end: 10px;
      border-inline-end: 1px solid #cbd5e1;
      div {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #334155;
        svg {
          width: 15px;
          height: 15px;
          color: #9e9e9e;
        }
      }
    }
  }
  .main-tree {
    position: relative;
    overflow-x: hidden;
  }
  .main-tree-one-comment {
    margin-inline-start: 20px;
    padding-top: 20px;
    .border-top {
      border-inline-start: 2px solid #e0e0e0;
      height: 23px;
      position: absolute;
      top: -3px;
    }
  }
  .tiktok-main-reply {
    margin-top: -60px;
    @include ar {
      left: unset;
      right: 60px;
    }
  }
  .main-tiktok-tree {
    padding: 20px 35px;
    position: relative;
    .border-top {
      border-inline-start: 2px solid #e0e0e0;
      height: 23px;
      position: absolute;
      top: -3px;
    }
    .border-start {
      width: 10px;
      border-top: 2px solid #e0e0e0;
      position: absolute;
      left: 0;
      top: 39px;
      @include ar {
        left: unset;
        right: 0px;
      }
    }
    .tiktok-avater {
      position: absolute;
      top: 20px;
      left: 15px;
      z-index: 9;
      padding: 5px;
      background-color: #fff;
      @include ar {
        left: unset;
        right: 15px;
      }
      img {
        width: 34px;
        height: 34px;
      }
    }
    &.blue {
      background-color: #f8fafc;
      border-radius: 4px;
      .tiktok-avater {
        background-color: #f8fafc;
      }
    }
    .tiktok-comment-dets {
      padding-inline-start: 30px;
      border-inline-start: 2px solid #e0e0e0;
      font-size: 14px;
      color: #00000099;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-top: 5px;
      .tiktok-comment-name {
        font-weight: 600;
        color: #0f172a;
      }
      .tiktok-comment-date {
        font-size: 12px;
        color: #696d72;
        margin-top: 8px;
        direction: ltr;
      }
      .tiktok-comment-likes {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        color: #000000;
        svg {
          color: #9e9e9e;
          width: 15px;
          height: 15px;
        }
      }
    }
    &.reply {
      .tiktok-comment-dets {
        padding-bottom: 60px;
        border-bottom: 2px solid #e0e0e0;
      }
    }
  }
  .main-tree-one-comment {
    margin-inline-start: 20px;
    padding-top: 20px;
    .border-top {
      height: 45px;
      top: -20px;
    }
  }
}
