@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.create-api-wrapper {
  width: 600px;
}
.MuiDialogTitle-root.create-api-title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  padding: 24px 24px 16px;
  display: flex;
  justify-content: space-between;
}
.create-api-page {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
}
.MuiDialogContent-root.create-api-content {
  padding-bottom: 0;
}
.MuiDialogActions-root.create-api-actions {
  padding: 15px 24px 24px;
}

.create-api-input-wrapper {
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 20px;
  }
}
.MuiInputLabel-root.create-api-input-label {
  margin-bottom: 4px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
}
.span-require {
  color: #f44336;
}
.create-api-input {
  width: 100%;
  .MuiOutlinedInput-input {
    padding: 10px 12px;
    font-size: 14px;
  }
}
.add-btn-plus {
  &.MuiButton-root {
    font-size: 14px;
    font-weight: 500;
    color: #456eb2;
    border-radius: 0;
    line-height: 2 !important;
    border-inline-start: 2px solid #456eb2;
    svg {
      font-size: 10px;
      padding: 0px 5px;
    }
  }
}
.create-api-input {
  &.Mui-error {
    .add-btn-plus {
      color: #f44336;
      border-inline-start: 2px solid #f44336;
    }
  }
}
.MuiInputBase-adornedEnd.create-api-input {
  padding-right: unset;
}
.MuiSvgIcon-root.info-icon {
  color: #9e9e9e;
  width: 14px;
}
.generate-api-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 15px;
  span {
    font-weight: 600;
    color: #4caf50;
  }
  strong {
    color: rgba(0, 0, 0, 0.87);
  }
}
.generate-api-description {
  margin: 0 0 16px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.key-copy-btn.MuiButton-root {
  padding: 10px;
  min-width: auto;
}
.create-api-input-hint {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  margin: 6px 0 10px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.MuiSvgIcon-root.input-hint-icon {
  color: #6cade9;
  width: 17px;
}
.create-api-error {
  color: #f44336;
  font-size: 12px;
  line-height: 20px;
  margin: 4px 0 0;
}
.ip-chip-style {
  &.MuiButtonBase-root {
    color: #456eb2;
    border: 1px solid rgba(33, 150, 243, 0.5);
    background-color: white;
    font-size: 13px;
    font-weight: 400;
    max-height: 30px;
    svg {
      color: rgba(69, 110, 178, 1);
      opacity: 0.7;
      font-size: 18px;
      @include ar {
        left: 10px;
        position: relative;
        direction: rtl;
        right: unset;
      }
    }
  }
  &.MuiChip-label {
    color: #456eb2;
    direction: initial;
    font-size: 13px;
    font-weight: 400;
  }
}

.ip-chip-wrapper {
  margin-top: 10px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
}

.show-all-ip {
  &.MuiButton-root {
    background: rgba(42, 170, 226, 0.15);
    border-radius: 16px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
    color: #456eb2;
    border: none;
    line-height: 20px;
  }
  @include ar {
    margin-right: 6px;
  }
}
.circle-single-loading {
  min-height: 160px;
  .circular-loading {
    top: 25px;
  }
}

.api-type-form-control {
  width: 100%;
  .MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input {
    min-height: auto;
    height: 1.4375em;
  }
}
.api-type-menuItem {
  .MuiListItemText-root {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiListItemText-root span {
    margin: 0px;
    margin-left: 6px;
  }

  .MuiCheckbox-root {
    padding: 5px;
  }
}
.cancel-popup-btn {
  @include ar {
    margin-left: 10px;
  }
}
