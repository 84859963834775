@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.create-live-dashboard-heading {
  margin: 30px 0;
  h2 {
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }
  .MuiBreadcrumbs-root {
    margin-bottom: 7px;
    .MuiBreadcrumbs-ol {
      li {
        font-size: 12px;
        font-weight: 500;
        color: #64748b;
      }
    }
  }
}
.create-live-dashboard-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 6px 36px -3px #626cab12;
  margin-bottom: 80px;

  .live-dashboard-divider {
    height: 1px;
    background-color: #e2e8f0;
    margin-block: 24px;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
}
.create-live-dashboard-group {
  .social-media-field {
    border-width: 1px;
    max-width: 400px;
    width: 100%;
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
      }
    }
  }
  .social-media-field.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #456eb2;
    }
  }
}
.create-live-dashboard-label {
  font-weight: 600;
  font-size: 16px;
  color: #0f172a;
  margin: 0 0 5px;
}
.create-live-dashboard-hint {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 10px 0 13px;
}
.create-live-dashboard-tabs-btns {
  margin: 0 0 25px;
  .MuiTabs-flexContainer {
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .MuiButtonBase-root.MuiTab-root {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid #dddddd;
    min-height: 35px;
    padding: 4px 16px;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 3px solid #456eb2;
  }
}
.widget-dashboard-option {
  border-radius: 7px;
  padding: 15px;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  text-align: center;
  cursor: pointer;
  .widget-option-img {
    width: 95%;
    height: 130px;
    margin-bottom: 10px;
  }
  .widget-option-label {
    font-size: 14px;
    font-weight: 600;
    color: #0f172a;
    margin: 0;
  }
  &:hover {
    border: 2px solid #456eb2;
    background-color: #e6f3fc;
    box-shadow: 0px 4px 18px 0px #456eb233;
  }
}
.widget-dashboard-option.selected-widget-dashboard {
  border: 2px solid #456eb2;
}

.create-live-dashboard-list {
  .summary-data-source-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .summary-content-heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1a174f;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
    img,
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .summary-content-hint {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    margin-inline-end: 15px;
  }
  .Mui-expanded {
    margin: 0;
  }
  .MuiAccordion-rounded::before {
    display: none;
  }
  .MuiAccordion-root.MuiPaper-root.MuiPaper-elevation {
    margin-bottom: 10px;
    border-radius: 6px;
    background-color: #fcfcfc;
    border: 1px solid #d1d1d1;
    box-shadow: none;
  }
  .MuiAccordionSummary-root {
    height: 67px;
  }

  .MuiAccordionSummary-contentGutters .MuiTypography-subtitle2 {
    color: #334155;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.15px;
  }

  .Mui-expanded.MuiAccordionSummary-contentGutters .MuiTypography-subtitle2 {
    color: #456eb2;
  }

  .MuiAccordionDetails-root {
    padding: 0 16px 16px;
  }
}
.create-live-dashboard-button {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  #delete-live-dashboard-btn {
    margin-inline-end: auto;
  }
}
.selected-widgets-length {
  font-size: 9px;
  width: 17px;
  height: 17px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #456eb2;
  color: white;
  margin-inline-start: 6px;
}
.dashboard-group-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  .create-live-dashboard-group {
    flex-grow: 1;
  }
}
.create-live-dashboard-error {
  font-size: 12px;
  letter-spacing: 0.4px;
  margin: 0;
  margin-inline-start: 10px;
  color: #f44336;
  padding-top: 5px;
  max-width: 400px;
}

.dashboard-access-container {
  .create-live-dashboard-label {
    margin-bottom: 10px;
  }
  .dashboard-required {
    color: #f44336;
  }
  .form-control-live-dashboard-access {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;

    .dashboard-form-control-label {
      margin: 0;
      border: 1px solid #e2e8f0;
      border-radius: 4px;
      &.disabled {
        opacity: 0.5;
        cursor: default;
      }

      &:hover {
        transition: all 0.5 ease;
        background-color: rgba(230, 243, 252, 0.6);
      }
      .card-container {
        min-height: 60px;
        padding: 12px 16px;
        .card-live-dashboard-heading {
          display: flex;
          align-items: center;
          color: #000000de;
          font-size: 14px;
          font-weight: 400;
          line-height: 20.02px;
          margin-bottom: 6px;
          svg {
            color: #00000061;
            font-weight: 900;
            margin-inline-end: 8px;
          }
        }
        .card-live-dashboard-description {
          font-size: 12px;
          font-weight: 400;
          line-height: 17.16px;
          color: #00000099;
        }
      }

      &:has(span.MuiButtonBase-root.Mui-checked) {
        border: 2px solid #456eb2;
        svg {
          color: #456eb2;
        }
      }
    }

    span.MuiButtonBase-root.MuiRadio-root {
      display: none;
    }
  }
  .alert-live-dashboard-date-range {
    margin-top: 20px;
    width: 55%;
  }
  @media (max-width: 990px) {
    .form-control-live-dashboard-access {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 600px) {
    .form-control-live-dashboard-access {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.access-type-wrapper {
  .MuiTypography-root {
    width: 100%;
  }
}
