@import "../../../../surveyBuilder.scss";

@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.survey-question-title-subtitle-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  .survey-question-title-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    @include ar {
      line-height: 28px;
    }
    .survey-question-title {
      flex-grow: 1;
      .survey-question-title-input {
        padding: 0;
      }
      textarea {
        width: 100%;
        color: var(--survey-font-color);
        font-weight: 500;
        font-size: 22px;
        line-height: 25px;
        padding: 0;
        direction: ltr;
        @include ar {
          direction: rtl;
        }
        @include popupSize("lg") {
          font-size: 18px;
          font-weight: 500;
        }
        @include popupSize("md") {
          font-size: 16px;
          font-weight: 500;
        }
        @include popupSize("sm") {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .survey-builder-preview-question-title {
      width: 100%;
      color: var(--survey-font-color);
      font-weight: 500;
      font-size: 22px;
      line-height: 25px;
      padding: 0;
      @include popupSize("lg") {
        font-size: 18px;
        font-weight: 500;
      }
      @include popupSize("md") {
        font-size: 16px;
        font-weight: 500;
      }
      @include popupSize("sm") {
        font-size: 14px;
        font-weight: 500;
      }

      .required-question-title {
        color: #ef3636;
        font-size: inherit;
      }
    }

    .survey-question-number {
      color: var(--survey-font-color);
      font-weight: 500;
      font-size: 22px;
    }
  }
  .survey-question-subtitle-wrapper {
    width: 100%;
    .survey-question-subtitle {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      padding: 0px;
      width: 100%;
      input,
      input::placeholder {
        color: var(--survey-secondary-font-color);
        padding: 0px;
      }

      @include popupSize("lg") {
        font-size: 18px;
        font-weight: 300;
      }
      @include popupSize("md") {
        font-size: 16px;
        font-weight: 300;
      }
      @include popupSize("sm") {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
}

.section-direction-rtl {
  .survey-question-subtitle-wrapper .survey-question-subtitle input {
    direction: rtl;
  }
}
