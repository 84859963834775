.user-img {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: 600;
    font-size: 13px;
    color: #FFFFFF;
    border: 1px solid #94a3b8;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }