@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.keyword-tester-title {
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.test-keyword-content {
  .MuiOutlinedInput-input {
    font-size: 14px;
    font-weight: 400;
    color: #0c192a;
    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.hint-keyword-text {
  span {
    &:nth-of-type(1) {
      color: #0c192a;
    }
  }
  color: #80868c;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 30px;
}
.text-keyword-btns-actions {
  &.MuiDialogActions-root {
    margin: 0px 20px;
    padding-bottom: 20px;
  }
  &.keyword-popup {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.test-keyword-wrapper {
  p {
    font-size: 14px;
    font-weight: 400;
    color: #0c192a;
  }
}
.test-keyword-chip {
  &.MuiChip-root {
    color: #64748b;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #cbd5e1;
    border-radius: 16px;
    background-color: transparent;
    margin: 0px 3px;
    margin-bottom: 5px;
  }
}
.test-kewyord-chips {
  margin-bottom: 20px;
}
.keyword-loading {
  .MuiCircularProgress-root {
    color: #fff;
    background-color: #456EB2 ;
    width: 20px !important;
    height: 20px !important;
  }
}
.keyword-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.test-result-icon {
  width: 15px;
  height: 15px;
  font-size: 8px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  &.success {
    background-color: #16be63;
  }
  &.failed {
    background-color: #ef3f3f;
  }
}
.test-result-body {
  color: #0c192a;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  padding: 0px 10px;
  span {
    font-weight: 500;
  }
}
.test-result-explain {
  color: #636465;
  font-size: 14px;
  font-weight: 400;
  span {
    color: #456EB2 ;
    text-decoration: underline;
    font-weight: 400;
  }
}
.test-keyword-msg-wrapper {
  background: #f8fafc;
  border: 1px solid #f1f5f9;
  border-radius: 4px;
  padding: 10px 10px 10px 15px;
  margin-bottom: 30px;
  display: flex;
}
