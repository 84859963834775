@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.delete-pop {
  width: 540px;
  max-height: 85px;
}

.delete-popup {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.delete-question-msg {
  font-weight: normal;
}

.delete-pop-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 400;
  @include en {
    .question {
      transform: rotate(0) scaleX(1);
    }
  }

  @include ar {
    .question {
      transform: rotate(360deg) scaleX(-1);
    }
  }
}

.popup-user {
  margin: 0 5px;
}
