@mixin en {
  html[lang="en"] & {
    @content;
  }
}

@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.view-monitor-detail {
  margin-inline-start: 250px;
  margin-inline-end: 75px;

  .view-monitor-header {
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: space-between;
    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #64748b;
    }

    .MuiBreadcrumbs-separator {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #cbd5e1;
    }

    .MuiBox-root {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @include ar {
        margin-left: -20px;
      }
    }
    .export-monitor-box {
      margin-top: -10px;
    }
    .MuiDivider-root {
      margin-top: 19px;
      border-color: #cbd5e1;
      position: relative;
      left: 10px;
      bottom: 8px;
      height: 33px;
      @include ar {
        margin-right: 10px;
      }
    }

    .date-field {
      .dateRange {
        margin-right: -20px;
      }
      fieldset {
        box-shadow: none !important;
        border: 1px solid #cbd5e1 !important;
      }
    }
  }
  .MuiPaper-elevation8 {
    @include ar {
      left: 140px !important;
    }
  }
}

html[lang="ar"] {
  .view-monitor-header {
    .date-field svg {
      right: 4% !important;
    }
  }
}
.active-filters-box {
  margin-top: 12px;
  @include en {
    margin-left: 250px;
    margin-right: 75px;
  }
  @include ar {
    margin-right: 250px;
    margin-left: 100px;
  }
}

html[lang="ar"] {
  .view-monitor-header {
    .date-field svg {
      right: 4% !important;
    }
  }
}

html[lang="ar"] {
  .view-monitor-header {
    .date-field svg {
      right: 4% !important;
    }
  }
}
.monitor-header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiDivider-root {
  border-color: #cbd5e1 !important;
}
.export-monitor-box-container {
  height: 68px;
  position: relative;
}
.export-monitor-box {
  padding: 13.5px 55px;
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 60px;
  @include ar {
    padding: 14.75px 55px;
  }
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  .MuiDivider-vertical {
    height: 36px;
    margin-inline-start: 20px;
  }
  .dateRange .MuiInputBase-formControl fieldset {
    height: 44px;
    top: -6px;
  }
  .filter-header-button {
    margin: 0px !important;
    width: 110px !important;
    &:hover {
      background: #f1f5f9 !important;
      border: 0.5px solid #8fa8d1 !important;
      border-radius: 4px;
    }
  }
}

.analytics-header {
  position: fixed;
  width: 93%;
}

.channel-analytics-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 40px;
  .create-new-channel {
    #main-pages-header-with-breadcrumb {
      margin: 0px !important;
    }
  }
  .create-new-channel-empty {
    display: flex;
  }
  .create-channel-button {
    display: inline-flex;
    justify-content: flex-end;
    align-items: end;
    height: auto;
    margin-inline-start: 5px !important;
    button {
      color: #456eb2 !important;
    }
  }
  #main-pages-header {
    margin: 0px !important;
  }
  &.ca-export-header {
    justify-content: space-between !important;
  }
}

.create-new-channel {
  #channel-analytics-insights-add-new-channel-btn {
    &:hover {
      background-color: rgba(230, 243, 252, 0.5) !important;
      border-radius: 2px !important;
    }
  }
}

.btn-ca-breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider-margin {
  margin-bottom: 20px;
}

.monitor-inapplicable-filters {
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    color: #475569;
  }
  svg {
    color: #cbd5e1;
    width: 20px;
    height: 20px;
    margin-inline-end: 5px;
  }
  padding: 8px 11px !important;
  border: 1px dashed #cbd5e1;
  border-radius: 20px;
  margin-inline-end: 15px;
  width: fit-content;
}

.monitor-header-actions-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.monitor-header-actions-divider {
  &.MuiDivider-vertical {
    height: 36px;
    margin-inline: 20px;
  }
  &.divider-margin {
    margin-inline-end: 15px;
    margin-inline-start: 0px;
  }
}

.monitor-inapplicable-tooltip {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  padding: 8px;
  ul {
    padding-inline-start: 18px;
    margin: 0px;
  }
  li {
    padding: 5px;
  }
}
.luc-Button.MuiButton-root.create-new-live-dashboard-btn {
  margin-inline-end: 15px;
  color: #475569;
  &:hover {
    background-color: rgba(230, 243, 252, 0.5);
  }
}
.go-to-live-dashboard-tooltip {
  padding: 6px;
  font-size: 12px;
  font-weight: 500;
  max-width: 250px;
}

.live-dashboard-item-menu {
  width: 100%;
  padding-block: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  .title-create-live-dashboard-item {
    color: #456eb2;
    & ~ svg {
      font-size: 15px;
      font-weight: 400;
      line-height: 15px;
      color: #456eb2;
    }
  }
  .title-live-dashboard-item {
    color: #000000de;
    .created-by-live-dashboard-item {
      font-size: 12px;
      font-weight: 400;
      color: #696d72;
    }
    & ~ svg {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #0d0c28;
    }
  }
}

.live-dashboard-menu-empty.MuiTypography-root {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #00000099;
  padding: 6px 16px;
}

#live-dashboard-menu {
  .MuiPaper-root {
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    max-height: 160px;
    width: 312px;
    margin-top: 10px;
    .MuiMenu-list {
      padding: 0px;
      width: 100%;
    }
  }
}
