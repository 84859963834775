@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.publish-pop-up-events {
  background: #fff;
  height: 530px;
  position: absolute;
  z-index: 999;
  bottom: 19px;

  @include en {
    right: 17px;
  }
  @include ar {
    left: 18px;
  }
  width: 500px;
  border-radius: 8px;
  box-shadow: 0px 6px 28px 5px rgba(0, 0, 0, 0.12),
    0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 8px 9px -5px rgba(0, 0, 0, 0.2);
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}
.publish-pop-up-wrapper {
  height: 100%;
  .publish-new-post-title {
    color: #0f172a;
    font-weight: 700;
    font-size: 18px;
  }
}
.publish-new-post-divider {
  border-color: #e2e8f0 !important;
}
.publish-new-post-accounts-container {
  .publish-new-post-accounts-title.MuiTypography-root {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
  }
}

.publish-pop-up-main-content {
  padding: 20px;
}

.publish-pop-up-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 20px;
  .publish-close-icon {
    cursor: pointer;
  }
}

.accounts-select-publish-items-edit {
  .account-select-publish-container-edit.MuiBox-root {
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
}

.accounts-select-publish-items {
  .MuiSelect-select {
    padding: 5px 10px !important;
  }
  .MuiSelect-icon {
    top: 12px;
  }
  .Mui-disabled {
    cursor: not-allowed !important;
  }
}

.accounts-publish-avatar-popup {
  width: 24px;
  height: 24px;
  border-radius: 16px;
  position: relative;
  border: 0.55px solid #cbd5e1;
}
.account-name-content-title-popup.MuiTypography-root {
  color: #000000de;
  font-weight: 400;
  font-size: 14px;
  margin-inline: 6px;
}

.account-select-publish-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  .MuiSelect-icon {
    top: 12px;
  }
  .MuiChip-root {
    background-color: #eeeeee;
    .MuiChip-label {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
    }
  }
}

.account-select-item-publish {
  border-radius: 16px;
  background: #eeeeee;
  padding: 3px 4px;
  display: flex;
  align-items: center;
}
.account-content-close-icon {
  color: #00000099;
  position: relative;
  top: 4px;
  margin-left: 5px;
  svg {
    font-size: 22px;
  }
}

.accounts-select-publish-items-empty {
  .MuiSelect-select {
    color: #00000099;
    font-weight: 400;
    font-size: 14px;
    padding: 13px 10px !important;
  }
}
.publish-new-post-accounts-container {
  label {
    color: #00000099;
    font-weight: 400;
    font-size: 14px;
    &.Mui-focused,
    &.MuiFormLabel-filled {
      display: none;
    }
    @include ar {
      right: 25px !important;
    }
  }
}

.accounts-name-content-pop-up.MuiTypography-root {
  display: flex;
  align-items: center;
  margin-inline: 6px;
  .accounts-publish-avatar-inside {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    position: relative;
    border: 0.55px solid #cbd5e1;
  }
  .account-publish-name-pop-up {
    font-weight: 500;
    font-size: 14px;
  }
  .account-publish-name-pop-up-datasource {
    color: #696d72;
    font-weight: 400;
    font-size: 12px;
  }
  .accounts-datasource-icon {
    width: 18px;
    height: 18px;
    position: relative;
    @include en {
      top: 9px;
      left: -13px;
    }
    @include ar {
      top: 9px;
      right: -10px;
    }
  }

  .publish-verify-icon {
    margin-inline: 4px;
    margin-bottom: -2px;
  }
}
.scheule-publish-types {
  display: flex;
  padding: 0px 20px 15px 20px;
}
.publish-type-content {
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  &:first-child {
    @include en {
      border-radius: 4px 0px 0px 4px;
    }
    @include ar {
      border-radius: 0px 4px 4px 0;
    }
  }
  &:last-child {
    @include en {
      border-radius: 0px 4px 4px 0px;
    }
    @include ar {
      border-radius: 4px 0px 0px 4px;
    }
  }
  border: 1px solid rgba(0, 0, 0, 0.23);
  color: #000000de;
  width: 50%;
  padding: 14px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  &.selected {
    border: 1px solid #456eb2;
    font-weight: 600;
    background: rgba(69, 110, 178, 0.05);
    color: #456eb2;
  }
}

.schedule-date-publish-conntainer {
  padding: 0px 20px 20px 20px;

  .publish-schedule-title.MuiTypography-root {
    color: #000000de;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .MuiButtonBase-root {
    background-color: #fff;
    pointer-events: auto;
    svg {
      font-size: 20px;
    }
  }
  .MuiInputBase-root {
    pointer-events: none !important;
  }
  .MuiInputBase-input {
    padding: 10px 8px;
    font-size: 14px;
    font-weight: 400;
  }
  .MuiFormControl-root {
    width: 100%;
  }
}

.publish-account-wrapper-item {
  display: flex;
}

.publish-accounts-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .publish-warning-icon {
    font-size: 20px;
    color: #ec8623;
    padding: 6px;
    pointer-events: auto;
    &:hover {
      background: #0000000a;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
.accounts-publish-empty {
  font-size: 14px;
}
.publish-time-picker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  .publish-time-picker-content {
    width: 91%;
    cursor: pointer;
    .invalid-date-field {
      fieldset {
        border: 1px solid #f44336;
      }
      .MuiSvgIcon-root,
      .MuiInputBase-input {
        color: #f44336;
      }
    }
  }
  .publish-time-picker-content-empty {
    width: 100% !important;
  }
  .publish-time-picker-delete-icon {
    color: #f44336;
    font-size: 16px;
    padding: 8px;
    border-radius: 4px;
    &:hover {
      background-color: #f880781a;
      cursor: pointer;
    }
  }
}

.publish-add-time-picker.MuiButtonBase-root {
  padding: 5px 9px 5px 9px;
  font-size: 14px;
}
.publish-popup-content-container {
  height: calc(100% - 123px);
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background-color: #fff;
    box-shadow: none;
  }
}
.publish-ivalid-date-msg {
  color: #f44336;
  font-size: 12px;
  font-weight: 400;
  margin-inline: 10px;
  margin-bottom: 10px;
}

.publish-pop-up-footer {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 11px 20px 12px 20px;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid #e2e8f0;
  .publish-gmt-title {
    color: #00000099;
    font-size: 12px;
    font-weight: 500;
    margin-inline: 5px;
  }
  .time-zone-content {
    display: flex;
    align-items: center;
  }
  .public-gmt-icon {
    color: #00000099;
    font-size: 18px;
  }
}

.btn-create-scedule-popup.MuiButtonBase-root {
  margin-inline: 6px;
}

.publish-reply-content-container {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: #fff;
  margin: 6px 20px 20px 20px;
  &.focused {
    border: 2px solid #456eb2;
  }
  &.error {
    border: 1px solid #f44336;
  }
  .engagements-reply {
    padding: 14px;
  }
  .reply-footer-box {
    gap: 8px !important;
  }
  .reply-container {
    .MuiFormControl-root {
      min-height: 30px;
    }
  }
  .engagements-reply {
    .remove-attachments-reply {
      color: #f44336 !important;
      .trash-wrap {
        color: #f44336 !important;
      }
    }
  }
}

.publish-content-info {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  .publish-content-media-title {
    color: #000000de;
    font-size: 14px;
    font-weight: 500;
  }
  a {
    display: flex;
    color: #456eb2;
    font-size: 14px;
    font-weight: 600;
    .icon-link {
      color: #456eb2;
      @include en {
        margin-left: 5px;
      }
      @include ar {
        margin-right: 5px;
        margin-top: 3px;
      }
    }
  }
}
.time-field-container {
  .MuiInputBase-input {
    @include ar {
      direction: ltr;
      position: relative;
      left: 250px;
    }
  }
}
.time-field-container-more {
  .MuiInputBase-input {
    @include ar {
      direction: ltr;
      position: relative;
      left: 210px;
    }
  }
}

#add-publish-time-btn {
  .MuiSvgIcon-root {
    @include ar {
      margin-right: -10px;
    }
  }
}

.content-error-msg-media {
  color: #f44336;
  font-size: 12px;
  font-weight: 400;
  margin-inline: 10px;
  padding: 0px 20px 0px 20px;
  position: relative;
  bottom: 14px;
}

.publish-new-post-title-edit {
  display: flex;
  align-items: center;
  .arrow-publish-edit {
    cursor: pointer;
    @include en {
      rotate: 180deg;
    }
    margin-inline-end: 10px;
  }
}

#publish-content-guide {
  &:hover {
    text-decoration: underline;
  }
}
