@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.header-tokens {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 15px;
  cursor: pointer;
  img {
    padding-inline-end: 10px;
  }
}

.header-tokens-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-tokens-value {
  direction: ltr;
}
