@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}

.input-field-with-shrink-label {
  @include ar {
    label {
      padding-inline: 3px;
      &.Mui-focused,
      &.MuiFormLabel-filled {
        background-color: #ffffff;
      }
    }
    fieldset {
      legend {
        span {
          display: none;
        }
      }
    }
    .MuiFormHelperText-root {
      text-align: right;
    }
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    @include ar {
      text-align: right;
    }
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    legend {
      font-size: calc(14px * 0.75);
      font-weight: 400;
      letter-spacing: 0.15px;
      line-height: 24px;
    }
  }
  label.MuiFormLabel-root.MuiInputLabel-root,
  .MuiOutlinedInput-root input {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
  }
  label.MuiFormLabel-root.MuiInputLabel-root {
    color: #00000099;
    &.Mui-error {
      color: #f44336;
    }
    @include ar {
      text-align: right;
    }
    &.Mui-focused {
      @include ar {
        right: 0px;
        left: auto;
      }
    }
    // not focused and not shrinked
    &:not(.Mui-focused) {
      @include ar {
        right: 24px;
        left: auto;
      }
    }
    &.MuiInputLabel-shrink {
      @include ar {
        right: 17px !important;
        left: auto;
        transform: translate(5px, -9px) scale(0.75);
        transform-origin: top right;
      }
    }
  }
  .MuiOutlinedInput-root {
    input {
      direction: ltr;
      color: #000000de;
      height: 40px;
      box-sizing: border-box;
      @include ar {
        direction: rtl;
      }
    }
  }
}
