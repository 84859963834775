@mixin en {
  html[lang="en"] & {
    @content;
  }
}
@mixin ar {
  html[lang="ar"] & {
    @content;
  }
}
.interaction-brand {
  display: flex;
  gap: 12px;
  align-items: center;
  flex: 1;
  .interaction-brand-wrapper {
    display: flex;
    align-items: center;
    gap: 17px;
    img,
    svg {
      width: 40.09px;
      height: 39px;
    }
    &-title {
      font-family: Figtree;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      white-space: nowrap;
    }
    &.instagram-brand {
      div {
        width: 39px;
        height: 39px;
        border-radius: 5px;
        background: #f1f5f9;
      }
      img,
      svg {
        width: 22px;
      }
    }
    &.facebook-brand {
      div {
        background: #1877f2;
        width: 39px;
        height: 39px;
        border-radius: 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    &-title {
      font-family: Figtree;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      color: #000000de;
      white-space: nowrap;
    }
  }
}

.outlined.sentiment-dropdown .sentiment-item {
  svg {
    height: 18px;
    width: 18px;
  }
}
.interactions-themes-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: end;
  gap: 10px;
  .edit-sentiment-interactions {
    padding: 3px 10px !important;
  }

  .sentiment-box {
    font-weight: 500 !important;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      height: 18px;
      width: 18px;
    }

    text-decoration-line: none;
  }
  .single-header-options {
    display: flex;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
    #demo-multiple-checkbox {
      display: flex;
      align-items: center;
    }
    .themes-dropdown-title,
    .sub-themes-dropdown-title {
      font-size: 14px;
      text-align: center;
    }
    .themes-dropdown,
    .sub-themes-dropdown {
      > div {
        gap: 7px !important;
      }
    }
    .themes-box {
      display: flex;
      align-items: center;
      gap: 3px;
      @include en {
        margin-left: 0;
      }
      @include ar {
        margin-right: 0;
      }
    }
    .sub-themes-container {
      @include en {
        width: 88px;
      }
      @include ar {
        width: 95px;
      }
    }
  }
}

.interaction-content-render {
  a.interaction-forward-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    @include ar {
      transform: scaleX(-1);
    }
  }
  .interaction {
    &-row {
      display: flex;
      justify-content: space-between;
    }
    &-user-profile {
      display: flex;
      gap: 17px;
      &.hover-show {
        display: flex;
        outline: 8px solid transparent;
        &:hover {
          background: #0000000a;
          outline: 8px solid #0000000a;
          border-radius: 4px;
          cursor: pointer;
          .interaction-user-info-name {
            text-decoration-line: underline;
          }
        }
      }
    }
    &-img-placeholder {
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 19.2px;
        color: #00000061;
      }
    }
    &-user-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 19.2px;
        color: #000000;
        margin: 0;
      }
      &-handle {
        font-weight: 400;
        font-size: 14px;
        line-height: 16.8px;
        color: #00000061;
        margin: 0;
      }
    }
    &-content {
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      color: #0c192a;
      margin: 20px 0;
    }
  }
}
hr.interaction-divider {
  height: 18px;
  background-color: #cbd5e1;
  align-self: center;
}

.interaction-type {
  padding: 3px 10px 3px 10px;
  border-radius: 16px;
  border: 1px solid #0000003b;
  color: #000000;
  font-family: Figtree;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;
  white-space: nowrap;
}

div.tweet-meta-row {
  align-items: center;
  gap: 20px;
  flex-direction: row;
}

.delete-open-link {
  display: flex;
  align-items: center;
  justify-content: center;
  .delete-icon-wrapper {
    cursor: pointer;
    svg {
      color: #808080;
    }
  }
}
