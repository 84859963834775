.fetch_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
}
.fetch-data-interactions-list {
  .fetch_wrapper {
    position: relative !important;
  }
}
.fetch_title {
  background: -webkit-linear-gradient(#1d1d1d, #828282);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic;
}

@-webkit-keyframes dot-keyframes {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes dot-keyframes {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  50% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.loading-dots--dot {
  -webkit-animation: dot-keyframes 1.5s infinite ease-in-out;
  animation: dot-keyframes 1.5s infinite ease-in-out;
  display: inline-block;
  border-radius: 28.75px;
  width: 10px;
  height: 10px;
  background: linear-gradient(
    270deg,
    rgba(219, 219, 219, 0.05) 0%,
    #dbdbdb 50%
  );
}
.empty-data-search-Box{
  .fetching-image{
    width: 134px;
    height:91px;
  }
}
.loading-dots--dot:nth-child(2) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  background: linear-gradient(
    270deg,
    rgba(164, 164, 164, 0.05) 0%,
    #c2c2c2 50%
  );
  margin: 0px 8px;
}

.loading-dots--dot:nth-child(3) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  background: linear-gradient(270deg, rgba(35, 35, 35, 0.05) 0%, #9b9b9b 50%);
}

.empty-data-search-Box{
  h3.no-data-found.MuiTypography-root{
    font-size: 16px !important;
      font-weight: 500 !important;
  }
}